export const FEED_GAP = 8;

// TODO: after the State Management weblab is deprecated. Update the data-testids in this file to be labeled with names matching the component names. They are currently using the names under ContentTilesContainer to avoid churn with Integration Tests / Unit Tests.
export const FeedTestIds = {
  feedContainer: 'studio-content-tile-container',
  feedAsset: 'studio-feed-item-asset',
  feedAssetImage: 'studio-feed-item-image',
  feedAssetVideo: 'studio-feed-item-video',
  feedAssetLoadingPlaceholder: 'asset-content-card-loading',
  feedAssetNoHoverOverlay: {
    rootContainer: 'asset-content-card-no-overlay',
    uploadedBadge: 'feed-asset-no-hover-overlay-uploaded-badge',
    videoPlayIcon: 'feed-asset-no-hover-overlay-video-play-icon',
  },
  feedAssetOverlay: {
    rootContainer: 'asset-content-card-overlay',
    clickBox: 'asset-content-card-overlay-click-box',
    controls: {
      rootContainer: 'studio-feed-item-overlay',
      deleteButton: 'trash-can',
      editButton: 'overlay-edit-button',
      menu: {
        deleteButton: 'studio-feed-item-menu-delete-button',
        downloadButton: 'studio-feed-item-menu-download-button',
        editPreviewButton: 'studio-feed-item-menu-edit-button',
        reuseSettingsButton: 'studio-feed-item-menu-reuse-settings-button',
        saveToAssetLibraryButton: 'studio-feed-item-menu-save-button',
        startSponsoredBrandsCampainButton: 'studio-feed-item-menu-start-a-sb-campaign',
        toggleButton: 'studio-feed-item-menu-toggle',
      },
    },
    metadata: {
      contentTypeLabel: 'studio-feed-item-content-type-text',
      headlineText: 'studio-feed-item-headline-text',
    },
  },
  feedAssetGenerationPlaceholder: {
    rootContainer: 'studio-feed-item-non-asset',
    // TODO: remove after E2E tests use unique IDs for different types of placeholders
    rootContainerAlt: 'feed-asset-generation-placeholder',
    placeholderComponent: 'content-tile-placeholder',
  },
  feedEmptyPlaceholder: {
    rootContainer: 'studio-feed-item-non-asset',
    // TODO: remove after E2E tests use unique IDs for different types of placeholders
    rootContainerAlt: 'feed-empty-placeholder',
    placeholderComponent: 'asset-content-card-placeholder',
  },
  feedFetchPlaceholder: {
    rootContainer: 'studio-feed-item-non-asset',
    // TODO: remove after E2E tests use unique IDs for different types of placeholders
    rootContainerAlt: 'feed-fetch-placeholder',
    placeholderComponent: 'content-tile-placeholder',
  },
};
