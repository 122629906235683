import { Feedback } from '@amzn/genaihub-typescript-client';
import { Message } from '@amzn/red-carpet-service-client';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'src/components/QueryClient';
import { FeedbackSentimentEnum } from 'src/v2/types';

export default function useFeedback(message: Message, index: number) {
  // this is a bit of a hack now as we don't actually have a place to fetch the feedback from,
  //  but this will be useful once we store such data in a way we can query it back
  const feedbackQuery = useQuery<Feedback>({
    queryKey: ['feedback', message.messageId, index],
    initialData: FeedbackSentimentEnum.NEUTRAL,
  });

  const setFeedback = (feedback: Feedback) => {
    queryClient.setQueryData<Feedback>(['feedback', message.messageId, index], feedback);
  };

  return { feedback: feedbackQuery.data, setFeedback };
}
