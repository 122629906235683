import { FeedFetchState } from './feedSlice.types';

export function getInitialFeedFetchState(): FeedFetchState {
  return {
    entityId: undefined,
    error: undefined,
    isFetching: false,
    nextToken: undefined,
    placeholderCount: 0,
    requestGroupId: undefined,
  };
}
