import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import { uploadProductMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/ImageUpload.middleware';
import { generateTextPromptsForLifestyleImagesMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/TextPromptGeneration.middleware';
import { LifestyleImageGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { prepareGenerationResults } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { DeepPartial, LifestyleImageAsset } from 'src/v2/types';
import { handleLifestyleImageGenerationSubmission } from './handleLifestyleImageGenerationSubmission';

/**
 * The primary handler for generating a list of LifestyleImageAssets.
 */
export async function handleLifestyleImageGeneration({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: LifestyleImageGenerationConfig;
}): Promise<{
  generatedAssets: LifestyleImageAsset[];
  errors: Error[];
}> {
  const { outputCount, userInputs } = generationConfig;
  const { product, textPrompt } = userInputs;

  // STEP - Get product reference ID if product given
  const productImageReferenceId = product && (await uploadProductMiddleware({ backendDispatchContext, product }));

  // STEP - Get guided text prompts if textPrompt not given
  const guidedTextPrompts = await generateTextPromptsForLifestyleImagesMiddleware({
    backendDispatchContext,
    generationConfig,
    productImageReferenceId,
  });

  // STEP - Run parallel generations
  const generations = guidedTextPrompts ? guidedTextPrompts.map((textPrompt) => ({ outputCount: 1, textPrompt })) : [{ outputCount, textPrompt }];

  const generationConfigs: LifestyleImageGenerationConfig[] = generations.map(({ outputCount, textPrompt }) => {
    const generationConfigOverrides: DeepPartial<typeof generationConfig> = {
      outputCount,
      userInputs: {
        textPrompt,
      },
    };
    return _merge(_cloneDeep(generationConfig), generationConfigOverrides);
  });

  const generationResults = await Promise.allSettled(
    generationConfigs.map((generationConfig) =>
      handleLifestyleImageGenerationSubmission({ backendDispatchContext, generationConfig, productImageReferenceId }),
    ),
  );

  return prepareGenerationResults<LifestyleImageAsset>({ generationResults });
}
