import Genaihubbackend, { PublishAssetInput, PublishAssetOutput } from '@amzn/genaihub-typescript-client';
import { UseMutationResult } from '@tanstack/react-query';
import { BackendActionBase, BackendActionType, RequestsLookup } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { handleInit, handleError, handleSuccess } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type PublishAssetToFeedAction = BackendActionBase<BackendActionType.PUBLISH_ASSET_TO_FEED, PublishAssetInput, PublishAssetOutput['body']>;

interface PublishAssetToFeedMutationProps {
  backendClient: Genaihubbackend;
  payload: PublishAssetInput;
}

export const PublishAssetToFeedMutationConfig = {
  mutationFn: async ({ backendClient, payload }: PublishAssetToFeedMutationProps) => {
    const response = await backendClient.publishAsset(payload);
    // The backend doesn't currently conform to its own model
    // Taskei: https://taskei.amazon.dev/tasks/HB-1191
    if ('body' in response.body) {
      return response.body.body as PublishAssetOutput['body'];
    } else {
      return response.body;
    }
  },
};

interface HandlePublishAssetToFeedActionProps {
  action: PublishAssetToFeedAction;
  backendClient: Genaihubbackend;
  publishAssetToFeedMutation: UseMutationResult<PublishAssetOutput['body'], Error, PublishAssetToFeedMutationProps, unknown>;
  requestsLookup: RequestsLookup;
}

export const handlePublishAssetToFeedAction = async ({
  action,
  backendClient,
  publishAssetToFeedMutation,
  requestsLookup,
}: HandlePublishAssetToFeedActionProps) => {
  const id = handleInit({ action, requestsLookup });
  try {
    const response = await publishAssetToFeedMutation.mutateAsync({
      backendClient,
      payload: action.payload,
    });
    return handleSuccess({ response, action, id, requestsLookup });
  } catch (err) {
    return handleError({ err, action, id, requestsLookup });
  }
};
