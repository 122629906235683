import { WorkflowUserInputs } from 'src/v2/contexts/assetGeneration/types/WorkflowUserInputs.types';
import { AssetTypeEnum, DEFAULT_ASPECT_RATIO, FrontendAsset, WorkflowIdEnum } from 'src/v2/types';
import { getFeedContentTypeByWorkflowId } from 'src/v2/utils/utils';

// Utils that take in FrontendAsset
export function getAssetContentType({ asset }: { asset: FrontendAsset }) {
  return getFeedContentTypeByWorkflowId(asset.workflowId);
}

export function getAssetEffects({ asset }: { asset: FrontendAsset }) {
  return getEffectsFromUserInputs({ userInputs: asset.userInputs });
}

export function getAssetProduct({ asset }: { asset: FrontendAsset }) {
  return getProductFromUserInputs({ userInputs: asset.userInputs });
}

export function getAssetStyle({ asset }: { asset: FrontendAsset }) {
  return getStyleFromUserInputs({ userInputs: asset.userInputs });
}

export function getAssetTextPrompt({ asset }: { asset: FrontendAsset }) {
  return getTextPromptFromUserInputs({ userInputs: asset.userInputs });
}

export function isEditableAsset({ asset }: { asset: FrontendAsset }) {
  return isImageAsset({ asset });
}

export function isImportedAsset({ asset }: { asset: FrontendAsset }) {
  return asset.workflowId === WorkflowIdEnum.IMPORT_IMAGE;
}

export function isImageAsset({ asset }: { asset: FrontendAsset }) {
  return asset.type === AssetTypeEnum.IMAGE;
}

export function isVideoAsset({ asset }: { asset: FrontendAsset }) {
  return asset.type === AssetTypeEnum.VIDEO;
}

// Utils that take in WorkflowUserInputs
export function getAspectRatioFromUserInputs({
  userInputs,
  defaultValue = DEFAULT_ASPECT_RATIO,
}: {
  userInputs: WorkflowUserInputs | undefined;
  defaultValue?: string;
}) {
  if (!userInputs) return defaultValue;
  if ('aspectRatio' in userInputs) return userInputs.aspectRatio;
  if ('imageToImport' in userInputs) return userInputs.imageToImport.aspectRatio;
  return defaultValue;
}

export function getEffectsFromUserInputs({ userInputs }: { userInputs: WorkflowUserInputs | undefined }) {
  return userInputs && 'effects' in userInputs ? userInputs.effects : undefined;
}

export function getProductFromUserInputs({ userInputs }: { userInputs: WorkflowUserInputs | undefined }) {
  return userInputs && 'product' in userInputs ? userInputs.product : undefined;
}

export function getStyleFromUserInputs({ userInputs }: { userInputs: WorkflowUserInputs | undefined }) {
  return userInputs && 'style' in userInputs ? userInputs.style : undefined;
}

export function getTextPromptFromUserInputs({ userInputs }: { userInputs: WorkflowUserInputs | undefined }) {
  return userInputs && 'textPrompt' in userInputs ? (userInputs.textPrompt ?? '') : '';
}
