import { memo, ReactElement, useState } from 'react';
import { Placeholder } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderAnimation, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';
import { useAssetCache } from 'src/v2/hooks/useAssetCache/useAssetCache';
import { SimpleAsset } from 'src/v2/types';

interface ImageAssetGridItemProps {
  asset: SimpleAsset;
}

export const getImageAssetGridItemTestId = (assetId: string) => `image-asset-grid-item-${assetId}`;
export const getImageAssetGridItemPlaceholderTestId = (assetId: string) => `image-asset-grid-item-placeholder-${assetId}`;

export const ImageAssetGridItem = memo(({ asset }: ImageAssetGridItemProps): ReactElement => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const cachedAssetUrl = useAssetCache({ asset });

  return (
    <>
      <img
        data-testid={getImageAssetGridItemTestId(asset.id)}
        src={cachedAssetUrl}
        alt={'Image asset'}
        onLoad={() => setImageLoaded(true)}
        style={{ display: !imageLoaded ? 'none' : '' }}
      />
      <Placeholder
        data-testid={getImageAssetGridItemPlaceholderTestId(asset.id)}
        variant={PlaceholderVariant.ROUNDED}
        animation={PlaceholderAnimation.WAVE}
        style={{ display: imageLoaded ? 'none' : '', width: '100%', height: '100%' }}
      />
    </>
  );
});

ImageAssetGridItem.displayName = 'ImageAssetGridItem';
