import { Menu, MenuItemButton } from '@amzn/storm-ui';
import { ellipsisH } from '@amzn/storm-ui-icons';
import { HTMLAttributes, memo, useCallback, useState } from 'react';
import { FeedbackContext } from 'src/components/feedback/FeedbackContext';
import { FeedbackThumbsControl } from 'src/components/feedback/FeedbackThumbsControl';
import { isSponsoredCampaignLaunched } from 'src/util/weblab/weblab';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { FeedTestIds } from 'src/v2/components/studio/feed/Feed.constants';
import styles from 'src/v2/components/studio/feed/Feed.module.scss';
import { useOverlayActions } from 'src/v2/components/studio/feed/hooks/useOverlayActions';
import { FrontendAsset } from 'src/v2/types';
import { getAssetContentType, getAssetTextPrompt, isEditableAsset, isImportedAsset } from 'src/v2/utils/FrontendAssetUtils';
import { getNormalizedClassNames } from 'src/v2/utils/utils';

interface FeedAssetsGroupItemOverlayProps extends HTMLAttributes<HTMLDivElement> {
  asset: FrontendAsset;
}

export const FeedAssetsGroupItemOverlay = memo((props: FeedAssetsGroupItemOverlayProps) => {
  const { asset, className, ...restProps } = props;
  const overlayActions = useOverlayActions({ asset });

  // ASSET METADATA
  const contentTypeLabel = getAssetContentType({ asset });
  const headlineText = getAssetTextPrompt({ asset });

  // FEEDBACK DATA
  const areFeedbackControlsAvailable = !isImportedAsset({ asset });

  // CONTROLS
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const isDeleteButtonDisabled = overlayActions.deleteAssetHook.isDeletingAsset;
  const isEditButtonAvailable = isEditableAsset({ asset });
  const isSaveToAssetLibraryDisabled = !overlayActions.saveAssetHook.canSaveAsset || overlayActions.saveAssetHook.isSavingAsset;
  const isReuseSettingsButtonAvailable = !!asset.userInputs;

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen, setIsMenuOpen]);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);

  const onClickDeleteAsset = () => {
    closeMenu();
    overlayActions.deleteAsset();
  };

  const onClickDownloadAsset = () => {
    closeMenu();
    overlayActions.downloadAsset();
  };

  const onClickEditAsset = () => {
    closeMenu();
    overlayActions.editAsset();
  };

  const onClickPreviewAsset = () => {
    closeMenu();
    overlayActions.previewAsset();
  };

  const onClickReuseAssetSettings = () => {
    closeMenu();
    overlayActions.reuseAssetSettings();
  };

  const onClickSaveAssetToAssetLibrary = () => {
    closeMenu();
    overlayActions.saveAssetToAssetLibrary();
  };

  const onClickStartSponsoredBrandsCampaign = () => {
    closeMenu();
  };

  // DOM
  const rootClassNames = getNormalizedClassNames([
    styles.feedAssetOverlay,
    overlayActions.feedbackContext.isFeedbackPopoverOpen ? styles.feedbackPopoverOpen : undefined,
    isMenuOpen ? styles.menuOpen : undefined,
    className,
  ]);

  return (
    <div className={rootClassNames} data-testid={FeedTestIds.feedAssetOverlay.rootContainer} {...restProps}>
      <div className={styles.shadow} data-testid={FeedTestIds.feedAssetOverlay.clickBox} onClick={() => onClickPreviewAsset()} />
      <div className={styles.controls} data-testid={FeedTestIds.feedAssetOverlay.controls.rootContainer}>
        <div className={styles.leftContainer}>
          {areFeedbackControlsAvailable && (
            <FeedbackContext.Provider value={overlayActions.feedbackContext}>
              <FeedbackThumbsControl />
            </FeedbackContext.Provider>
          )}
          <Button
            className={styles.deleteButton}
            dataTestId={FeedTestIds.feedAssetOverlay.controls.deleteButton}
            icon={iconTypes.trashCan}
            iconOnly
            iconOnlyNoBackground
            iconOnlyColor="#ffffff" // intentionally force always white
            darkMode
            disabled={isDeleteButtonDisabled}
            onClick={() => onClickDeleteAsset()}
          />
        </div>
        <div className={styles.rightContainer}>
          {isEditButtonAvailable && (
            <Button
              className={styles.editButton}
              label="Edit"
              borderRadius="999px"
              colorOverride
              iconOnlyColor="#ffffff" // intentionally force always white
              iconOnlyBackgroundColor="transparent"
              dataTestId={FeedTestIds.feedAssetOverlay.controls.editButton}
              type={ButtonTypes.Secondary}
              icon={iconTypes.pencil}
              onClick={() => onClickEditAsset()}
            />
          )}
          <span className={styles.menu}>
            <Menu
              id="feed-asset-overlay-menu"
              onClick={toggleMenu}
              onClose={closeMenu}
              isOpen={isMenuOpen}
              trigger={
                <Button
                  className={styles.menuToggleButton}
                  dataTestId={FeedTestIds.feedAssetOverlay.controls.menu.toggleButton}
                  icon={ellipsisH}
                  iconOnly
                  iconOnlyNoBackground
                  iconOnlyColor="#ffffff" // intentionally force always white
                  darkMode
                  onClick={() => {}}
                />
              }
            >
              <MenuItemButton
                onClick={() => onClickSaveAssetToAssetLibrary()}
                disabled={isSaveToAssetLibraryDisabled}
                data-testid={FeedTestIds.feedAssetOverlay.controls.menu.saveToAssetLibraryButton}
              >
                Save to creative assets
              </MenuItemButton>
              <MenuItemButton onClick={() => onClickDownloadAsset()} data-testid={FeedTestIds.feedAssetOverlay.controls.menu.downloadButton}>
                Download
              </MenuItemButton>
              {isReuseSettingsButtonAvailable && (
                <MenuItemButton
                  onClick={() => onClickReuseAssetSettings()}
                  data-testid={FeedTestIds.feedAssetOverlay.controls.menu.reuseSettingsButton}
                >
                  Reuse settings
                </MenuItemButton>
              )}
              {isEditButtonAvailable ? (
                <MenuItemButton
                  onClick={() => onClickEditAsset()}
                  data-testid={FeedTestIds.feedAssetOverlay.controls.menu.editPreviewButton}
                  data-action="Edit"
                >
                  Edit
                </MenuItemButton>
              ) : (
                <MenuItemButton
                  onClick={() => onClickPreviewAsset()}
                  data-testid={FeedTestIds.feedAssetOverlay.controls.menu.editPreviewButton}
                  data-action="Preview"
                >
                  Preview
                </MenuItemButton>
              )}
              <MenuItemButton
                onClick={() => onClickDeleteAsset()}
                data-testid={FeedTestIds.feedAssetOverlay.controls.menu.deleteButton}
                disabled={isDeleteButtonDisabled}
              >
                Delete
              </MenuItemButton>
              {isSponsoredCampaignLaunched() && (
                <MenuItemButton
                  onClick={() => onClickStartSponsoredBrandsCampaign()}
                  data-testid={FeedTestIds.feedAssetOverlay.controls.menu.startSponsoredBrandsCampainButton}
                >
                  Start a Sponsored Brands campaign
                </MenuItemButton>
              )}
            </Menu>
          </span>
        </div>
      </div>
      <div className={styles.metadataContainer}>
        <div className={styles.contentTypeContainer}>
          <div className={styles.contentTypeBadge} data-testid={FeedTestIds.feedAssetOverlay.metadata.contentTypeLabel}>
            {contentTypeLabel}
          </div>
        </div>
        <div className={styles.headlineContainer} data-testid={FeedTestIds.feedAssetOverlay.metadata.headlineText}>
          {headlineText}
        </div>
      </div>
    </div>
  );
});

FeedAssetsGroupItemOverlay.displayName = 'FeedAssetsGroupItemOverlay';
