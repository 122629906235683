import { useState } from 'react';
import {
  SubmitAsinFailureNotification,
  SubmitBlankAsinNotification,
  SubmitRestrictedAsinNotification,
} from 'src/components/snackbar/notifications/ImageGenerationNotifications';
import { US_MARKETPLACE_ID } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { logger } from 'src/logger';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { moveProductToTopById, selectProductEntities, upsertProduct } from 'src/v2/redux/slices/product/productSlice';
import { AsinProduct } from 'src/v2/redux/slices/product/productSlice.types';
import { AsinCategoryEnum, ASINItem } from 'src/v2/types';
import { createAsinProduct } from 'src/v2/utils/Product.utils';
export const ASIN_ERROR_MESSAGES = {
  RESTRICTED: 'Restricted ASIN',
  INVALID: 'Invalid ASIN',
  NO_ASIN: 'No ASIN provided',
};

export const useASINLookup = ({
  postASINLookup,
}: {
  postASINLookup?: (product: AsinProduct) => void;
} = {}): {
  loading: boolean;
  searchASIN: (asin: string) => Promise<void>;
  errorMessage?: string | null;
} => {
  const [loading, setLoading] = useState<boolean>(false);
  const { addFailureNotification } = useNotificationActions();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const productEntities = useAppSelector(selectProductEntities);

  const dispatch = useAppDispatch();
  const genAIBackendClient = useAIBackendHubClient();

  const searchASIN = async (asin: string) => {
    try {
      if (!asin) throw new Error(ASIN_ERROR_MESSAGES.NO_ASIN);

      setErrorMessage('');
      setLoading(true);
      const product = productEntities[asin];
      if (product) {
        dispatch(moveProductToTopById(product.id));
        postASINLookup?.(product);
      } else {
        const fetchedAsin = (
          await genAIBackendClient.retrieveASINMetadataByASINId({
            asinId: asin,
            marketplaceId: US_MARKETPLACE_ID,
          })
        ).body;

        if (fetchedAsin.asinCategory === AsinCategoryEnum.RESTRICTED) {
          throw new Error(ASIN_ERROR_MESSAGES.RESTRICTED);
        } else {
          const newAsinItem: ASINItem = {
            asin,
            metadata: fetchedAsin,
          };
          const product = createAsinProduct(newAsinItem);
          dispatch(upsertProduct(product));
          postASINLookup?.(product);
        }
      }
    } catch (error) {
      logger.error(`Error: Fetching ASIN metadata`, { ASIN: asin, Error: error });

      const message = error instanceof Error ? error.message : '';
      switch (message) {
        case ASIN_ERROR_MESSAGES.NO_ASIN:
          addFailureNotification({
            SnackbarContent: SubmitBlankAsinNotification,
          });
          break;
        case ASIN_ERROR_MESSAGES.RESTRICTED:
          addFailureNotification({
            SnackbarContent: SubmitRestrictedAsinNotification,
          });
          break;
        default:
          setErrorMessage(ASIN_ERROR_MESSAGES.INVALID);
          addFailureNotification({ SnackbarContent: SubmitAsinFailureNotification });
          break;
      }
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    searchASIN,
    errorMessage,
  };
};
