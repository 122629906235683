import { Box, Flex, Heading, Text } from '@amzn/storm-ui';
import { useContext, useEffect } from 'react';
import { ImageModalProductPreview } from 'src/components/imageModal/components/ImageModalProductPreview';
import { ImageModalContext } from 'src/components/imageModal/index';
import { ContentItem, isImportedMediaWorkflow } from 'src/components/pages/studio/StudioContext';
import { isProd } from 'src/util/util';
import { AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { getImageFromProduct } from 'src/v2/components/studio/productSelector/productSelectorHelpers';
import { useReuseInputs } from 'src/v2/hooks/useInputSettingsReuse/useInputSettingsReuse';
import { useAppSelector } from 'src/v2/redux/hooks';
import { selectProductEntities } from 'src/v2/redux/slices/product/productSlice';
import { AsinImageDetails, AspectRatio, AssetTypeEnum, CustomImageDetails, ProductImageDetails } from 'src/v2/types';
import styled from 'styled-components';
import styles from './ImageModalPreviewTab.module.scss';
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const Section = styled.div`
  gap: 22px;
`;

export const ImageModalPreviewTab = ({ currentItem }: { currentItem?: ContentItem }) => {
  const {
    aspectRatio,
    assetType,
    imageType,
    imageUrl,
    prompt,
    productImageDetails,
    theme,
    savedEditsImageUrl,
    studioInputSettings,
    savedEditsAssetType,
    savedEditsWorkflowId,
    handleReuseSettings,
    handleSwitchToNextContentItem,
    handleSwitchToPrevContentItem,
  } = useContext(ImageModalContext);
  const { handleReuseInputs } = useReuseInputs();
  const isInputPanelRefresh = isWeblabInTreatment(AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment.T1);

  const productEntities = useAppSelector(selectProductEntities);
  const products = currentItem?.products || [];
  const multiProductDetails: ProductImageDetails[] = products
    .map((product) => {
      if (product.asin) {
        const asinProduct = productEntities[product.asin];
        if (asinProduct)
          return {
            asin: product.asin,
            url: getImageFromProduct(asinProduct),
            title: asinProduct.metadata.title,
          } as AsinImageDetails;
      } else {
        // TODO: this is not working. Need to get reference ID and retrieve asset from it.
        return {
          size: 0,
          url: product.inputImageUri,
          name: 'Custom Image',
        } as CustomImageDetails;
      }
    })
    .filter((detail): detail is ProductImageDetails => !!detail);
  // check for left an right arrow press to change asset details
  const navigationKeyListener = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        event.stopPropagation();
        handleSwitchToPrevContentItem();
        break;
      case 'ArrowRight':
        event.stopPropagation();
        handleSwitchToNextContentItem();
        break;
    }
  };

  // set key listeners so that arrow keys work
  useEffect(() => {
    window.addEventListener('keydown', navigationKeyListener);
    return () => {
      window.removeEventListener('keydown', navigationKeyListener);
    };
  }, [navigationKeyListener]);

  // lifestyle image modal doesn't currently support vertical 9:16 aspect ratio
  // this is tied to UserInputContainer and it also excluding 9:16 creations.
  const reuseSettingsDisabled = (isProd() && aspectRatio === AspectRatio.VERTICAL_9_TO_16) || !studioInputSettings;

  const isImportedMediaWorkflowResult = isImportedMediaWorkflow(savedEditsWorkflowId ?? currentItem?.workflowId);
  const isMediaOnly = isImportedMediaWorkflowResult || !studioInputSettings;

  const currentAssetType = savedEditsAssetType || assetType;
  const currentAssetUrl = savedEditsImageUrl || imageUrl;

  const asinProductDetails = multiProductDetails.filter((product) => 'asin' in product && product.asin);
  // TODO: custom image URL from feed is expired
  // const customImageProductDetails = multiProductDetails.filter((product) => !('asin' in product));

  return (
    <div className={`${styles.imagePreviewTab} ${isMediaOnly ? styles.mediaOnly : ''}`} role={'tabpanel'} data-testid="Preview">
      <Panel className={styles.leftPanel}>
        {currentAssetType === AssetTypeEnum.VIDEO ? (
          <video preload="auto" controls={true} autoPlay playsInline muted loop src={currentAssetUrl} data-testid={'studio-edit-main-video'} />
        ) : (
          <img src={currentAssetUrl} alt={'main image'} data-testid={'studio-edit-main-image'} />
        )}
      </Panel>

      {!isMediaOnly && (
        <Panel className={styles.rightPanel}>
          <div className={styles.rightPanelContent}>
            <Section className={styles.section}>
              {!savedEditsImageUrl && (
                <div className={styles.sectionHeader}>
                  <Heading renderAs="h3">Settings</Heading>
                  {!reuseSettingsDisabled && (
                    <Button
                      label="Reuse settings"
                      type={ButtonTypes.Tertiary}
                      onClick={() => {
                        // legacy for studio context
                        handleReuseSettings();
                        if (isInputPanelRefresh) {
                          // new way for redux state management
                          handleReuseInputs(studioInputSettings);
                        }
                      }}
                      dataTestId="studio-preview-reuse-settings-button"
                    />
                  )}
                </div>
              )}
              <div className={styles.subSection}>
                <div className={styles.sectionSubHeader}>
                  <Text>{savedEditsImageUrl ? 'Original Prompt' : 'Prompt'}</Text>
                </div>
                <CopyToClipboard textCopy={prompt}>
                  <Box className={styles.box}>
                    <Text color={'tertiary'} size={'base'}>
                      {prompt}
                    </Text>
                  </Box>
                </CopyToClipboard>
              </div>
              {productImageDetails && multiProductDetails.length === 0 && (
                <div className={styles.subSection}>
                  <div className={styles.sectionSubHeader}>
                    {'asin' in productImageDetails && <Text>ASIN</Text>}
                    {'name' in productImageDetails && <Text>Uploaded image</Text>}
                  </div>
                  <Box className={styles.box}>
                    <ImageModalProductPreview {...productImageDetails} />
                  </Box>
                </div>
              )}
              {asinProductDetails.length > 0 && (
                <div className={styles.subSection}>
                  <div className={styles.sectionSubHeader}>
                    <Text>ASIN</Text>
                  </div>
                  <Box className={styles.box}>
                    <Flex direction="column" rowGap="small">
                      {asinProductDetails.map((product, index) => (
                        <ImageModalProductPreview {...product} key={index} />
                      ))}
                    </Flex>
                  </Box>
                </div>
              )}
              {/* {customImageProductDetails.length > 0 && ( */}
              {/*   <div className={styles.subSection}> */}
              {/*     <div className={styles.sectionSubHeader}> */}
              {/*       <Text>Uploaded image</Text> */}
              {/*     </div> */}
              {/*     <Box className={styles.box}> */}
              {/*       <Flex direction="column" rowGap="small"> */}
              {/*         {customImageProductDetails.map((product, index) => ( */}
              {/*           <ImageModalProductPreview {...product} key={index} /> */}
              {/*         ))} */}
              {/*       </Flex> */}
              {/*     </Box> */}
              {/*   </div> */}
              {/* )} */}
              {!savedEditsImageUrl && (
                <div className={styles.subSection}>
                  <div className={styles.sectionSubHeader}>
                    <Text>Asset type</Text>
                  </div>
                  <Text color={'tertiary'}>{imageType}</Text>
                </div>
              )}
              {theme && !savedEditsImageUrl && (
                <div className={styles.subSection}>
                  <div className={styles.sectionSubHeader}>
                    <Text>Theme</Text>
                  </div>
                  <div className={styles.theme}>
                    <img src={theme.icon} alt={theme.label} />
                    <Text color={'tertiary'}>{theme.label}</Text>
                  </div>
                </div>
              )}
              {savedEditsImageUrl && (
                <div className={styles.subSection}>
                  <div className={styles.sectionSubHeader}>
                    <Text>Original Image</Text>
                  </div>
                  <img src={imageUrl} className={styles.originalImage} alt={'original image'} data-testid={'studio-edit-original-image'} />
                </div>
              )}
            </Section>
          </div>
        </Panel>
      )}
    </div>
  );
};
