import { getFilteredStyles, ThemeIcons } from 'src/components/utils/styleUtils';
import { handleGenerateAssetsAction } from 'src/v2/contexts/assetGeneration/actions/GenerateAssets.action';
import {
  AssetGenerationActionType,
  GenerateAssetsAction,
  GenerateStudioAssetsAction,
  GenerationConfig,
  LifestyleImageGenerationConfig,
  MultipleProductImageGenerationConfig,
  ProductImageGenerationConfig,
} from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { SnackbarDispatchContextType } from 'src/v2/contexts/snackbar/SnackbarContext';
import { SnackbarActionAddNotification, SnackbarActionType, SnackbarType } from 'src/v2/contexts/snackbar/types';
import { addFeedAssetGenerationId, getFeedFetchEntityId, prependFeedAssets, removeFeedAssetGenerationId } from 'src/v2/redux/slices/feed/feedSlice';
import {
  getAllFullProductLayout,
  getAspectRatio,
  getAutoLayoutEnabled,
  getEffectSelections,
  getIsPromptRewriteEnabled,
  getReferenceImages,
  getStyleSelection,
  getTextPrompt,
} from 'src/v2/redux/slices/userInput/userInputSlice';
import { AppStore } from 'src/v2/redux/store';
import { GenerateOptionType, WorkflowIdEnum } from 'src/v2/types';
import { v6 as uuidV6 } from 'uuid';

export async function handleGenerateStudioAssetsAction({
  action,
  appStore,
  backendDispatchContext,
  snackbarDispatchContext,
}: {
  action: GenerateStudioAssetsAction;
  appStore: AppStore;
  backendDispatchContext: BackendDispatchContextType;
  snackbarDispatchContext: SnackbarDispatchContextType;
}) {
  const requestId = uuidV6();
  action.onInit?.({ id: requestId });
  const { generateOptionOverride } = action;
  const entityId = action.entityId ?? '';
  const state = appStore.getState();
  const fullLayouts = getAllFullProductLayout(state);
  const autoLayoutEnabled = getAutoLayoutEnabled(state);
  const rewriteUserCustomPrompt = getIsPromptRewriteEnabled(state);
  const textPrompt = getTextPrompt(state);
  const aspectRatio = getAspectRatio(state);
  const effects = getEffectSelections(state);
  const style = getStyleSelection(state);
  const isProductSelected = fullLayouts.length > 0;
  const referenceImages = getReferenceImages(state);

  const inputs = {
    entityId,
    options: undefined,
    outputCount: 6,
    studioRequest: true,
    userInputs: {
      aspectRatio,
      effects,
      textPrompt: textPrompt ?? '',
      rewriteUserCustomPrompt,
      style,
    },
  };

  const generationConfigs: GenerationConfig[] = [];
  const generateMultipleProductImage = fullLayouts?.length >= 1 && !autoLayoutEnabled;

  if (generateOptionOverride) {
    if (generateOptionOverride === GenerateOptionType.GENERATE_PRODUCT_IMAGES) {
      if (generateMultipleProductImage) {
        const multipleImageGenerationConfig: MultipleProductImageGenerationConfig = {
          ...inputs,
          userInputs: {
            ...inputs.userInputs,
            products: fullLayouts,
          },
          workflowId: WorkflowIdEnum.MULTI_PRODUCT,
        };
        generationConfigs.push(multipleImageGenerationConfig);
      } else {
        const productImageGenerationConfig: ProductImageGenerationConfig = {
          ...inputs,
          userInputs: {
            ...inputs.userInputs,
            product: fullLayouts[0].product,
            referenceImages: referenceImages ?? undefined,
            referenceImagesStrength: referenceImages?.[0]?.strength ?? undefined,
          },
          workflowId: WorkflowIdEnum.TEXT_TO_IMAGE,
        };
        generationConfigs.push(productImageGenerationConfig);
      }
    } else {
      const lifestyleConfig: LifestyleImageGenerationConfig = {
        ...inputs,
        userInputs: {
          ...inputs.userInputs,
          product: fullLayouts.length > 0 ? fullLayouts[0].product : undefined,
        },
        workflowId: WorkflowIdEnum.LIFESTYLE_IMAGERY,
      };
      generationConfigs.push(lifestyleConfig);
    }
  } else if (isProductSelected && style) {
    if (generateMultipleProductImage) {
      const multipleImageGenerationConfig: MultipleProductImageGenerationConfig = {
        ...inputs,
        userInputs: {
          ...inputs.userInputs,
          products: fullLayouts,
        },
        workflowId: WorkflowIdEnum.MULTI_PRODUCT,
      };
      generationConfigs.push(multipleImageGenerationConfig);
    } else {
      const productImageGenerationConfig: ProductImageGenerationConfig = {
        ...inputs,
        userInputs: {
          ...inputs.userInputs,
          product: fullLayouts[0].product,
          referenceImages: referenceImages ?? undefined,
          referenceImagesStrength: referenceImages?.[0]?.strength ?? undefined,
        },
        workflowId: WorkflowIdEnum.TEXT_TO_IMAGE,
      };
      generationConfigs.push(productImageGenerationConfig);
    }
  } else if (isProductSelected && textPrompt) {
    if (generateMultipleProductImage) {
      const multipleImageGenerationConfig: MultipleProductImageGenerationConfig = {
        ...inputs,
        userInputs: {
          ...inputs.userInputs,
          products: fullLayouts,
        },
        outputCount: 3,
        workflowId: WorkflowIdEnum.MULTI_PRODUCT,
      };
      generationConfigs.push(multipleImageGenerationConfig);
    } else {
      const productImageGenerationConfig: ProductImageGenerationConfig = {
        ...inputs,
        userInputs: {
          ...inputs.userInputs,
          product: fullLayouts[0].product,
          referenceImages: referenceImages ?? undefined,
          referenceImagesStrength: referenceImages?.[0]?.strength ?? undefined,
        },
        outputCount: 3,
        workflowId: WorkflowIdEnum.TEXT_TO_IMAGE,
      };
      generationConfigs.push(productImageGenerationConfig);
    }
    const lifestyleConfig: LifestyleImageGenerationConfig = {
      ...inputs,
      outputCount: 3,
      userInputs: {
        ...inputs.userInputs,
        product: fullLayouts.length > 0 ? fullLayouts[0].product : undefined,
      },
      workflowId: WorkflowIdEnum.LIFESTYLE_IMAGERY,
    };
    generationConfigs.push(lifestyleConfig);
  } else if (isProductSelected) {
    const productImageGenerationConfig: LifestyleImageGenerationConfig = {
      ...inputs,
      outputCount: 3,
      userInputs: {
        ...inputs.userInputs,
        product: fullLayouts.length > 0 ? fullLayouts[0].product : undefined,
      },
      workflowId: WorkflowIdEnum.LIFESTYLE_IMAGERY,
    };
    generationConfigs.push(productImageGenerationConfig);

    const abstractTheme = getFilteredStyles('abstract')[0];

    if (generateMultipleProductImage) {
      const multipleImageGenerationConfig: MultipleProductImageGenerationConfig = {
        ...inputs,
        userInputs: {
          ...inputs.userInputs,
          products: fullLayouts,
        },
        outputCount: 2,
        workflowId: WorkflowIdEnum.MULTI_PRODUCT,
      };
      generationConfigs.push(multipleImageGenerationConfig);

      const abstractMultipleProductImageGenerationConfig: MultipleProductImageGenerationConfig = {
        ...inputs,
        outputCount: 1,
        userInputs: {
          ...inputs.userInputs,
          style: { icon: ThemeIcons[abstractTheme.src], label: abstractTheme.controlLabel, value: abstractTheme.controlValue },
          products: fullLayouts,
        },
        workflowId: WorkflowIdEnum.MULTI_PRODUCT,
      };
      generationConfigs.push(abstractMultipleProductImageGenerationConfig);
    } else {
      const productImageGenerationConfig: ProductImageGenerationConfig = {
        ...inputs,
        userInputs: {
          ...inputs.userInputs,
          product: fullLayouts[0].product,

          referenceImages: referenceImages ?? undefined,
          referenceImagesStrength: referenceImages?.[0]?.strength ?? undefined,
        },
        outputCount: 2,
        workflowId: WorkflowIdEnum.TEXT_TO_IMAGE,
      };
      generationConfigs.push(productImageGenerationConfig);

      const abstractProductImageGenerationConfig: ProductImageGenerationConfig = {
        ...inputs,
        outputCount: 1,
        userInputs: {
          ...inputs.userInputs,
          style: { icon: ThemeIcons[abstractTheme.src], label: abstractTheme.controlLabel, value: abstractTheme.controlValue },
          product: fullLayouts[0].product,

          referenceImages: referenceImages ?? undefined,
          referenceImagesStrength: referenceImages?.[0]?.strength ?? undefined,
        },
        workflowId: WorkflowIdEnum.TEXT_TO_IMAGE,
      };
      generationConfigs.push(abstractProductImageGenerationConfig);
    }
  } else {
    const lifestyleConfig: LifestyleImageGenerationConfig = {
      ...inputs,
      userInputs: {
        ...inputs.userInputs,
        product: undefined,
      },
      workflowId: WorkflowIdEnum.LIFESTYLE_IMAGERY,
    };
    generationConfigs.push(lifestyleConfig);
  }

  const handleAbort = () => {
    appStore.dispatch(removeFeedAssetGenerationId({ id: requestId }));
    action.onAbort?.();
  };

  const generateAssetsAction: GenerateAssetsAction = {
    type: AssetGenerationActionType.GENERATE_ASSETS,
    generationConfigs,
    onAbort: () => {
      handleAbort();
    },
    onInit: () => {
      appStore.dispatch(addFeedAssetGenerationId({ id: requestId, entityId }));
    },
    onComplete: (props) => {
      const currentFeedEntityId = getFeedFetchEntityId(appStore.getState()) ?? '';
      if (currentFeedEntityId != entityId) {
        // If the account changed between the generation trigger and now, then treat as an abort
        handleAbort();
        return;
      }

      if (props.generatedAssets.length) appStore.dispatch(prependFeedAssets({ assets: props.generatedAssets, entityId }));
      appStore.dispatch(removeFeedAssetGenerationId({ id: requestId }));

      // TODO: create a more user-friendly notification
      props.errors.forEach((error) => {
        const addNotificationAction: SnackbarActionAddNotification = {
          type: SnackbarActionType.ADD_NOTIFICATION,
          payload: {
            id: uuidV6(),
            type: SnackbarType.ERROR,
            SnackbarContent: () => <>{error.message}</>,
          },
        };
        snackbarDispatchContext(addNotificationAction);
      });
      action.onComplete?.(props);
    },
  };

  handleGenerateAssetsAction({ action: generateAssetsAction, appStore, backendDispatchContext, requestId });
}
