import Genaihubbackend, { ProductImageBackgroundRemovalInput, ProductImageBackgroundRemovalOutput } from '@amzn/genaihub-typescript-client';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';
import { JobStatusEnum } from 'src/v2/types';
import {
  BackendActionBase,
  BackendActionQueryResultBase,
  BackendActionType,
  BackendQueryActionOptions,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
} from '../types/BackendContextActions.types';

export type ProductBackgroundRemovalAction = BackendActionBase<
  BackendActionType.PRODUCT_BACKGROUND_REMOVAL,
  ProductImageBackgroundRemovalInput,
  ProductImageBackgroundRemovalOutput['body'],
  BackendQueryActionOptions
>;

export type ProductBackgroundRemovalActionQueryResult = BackendActionQueryResultBase<
  BackendActionType.PRODUCT_BACKGROUND_REMOVAL,
  ProductImageBackgroundRemovalOutput['body']
>;

interface ProductBackgroundRemovalQueryFnProps {
  id: Request['id'];
  action: ProductBackgroundRemovalAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const productBackgroundRemovalQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: ProductBackgroundRemovalQueryFnProps): Promise<ProductBackgroundRemovalActionQueryResult> => {
  const type = BackendActionType.PRODUCT_BACKGROUND_REMOVAL;
  const response = await backendClient.productImageBackgroundRemoval(action.payload);
  const request = getRequest({ id, requestsLookup });
  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }

  const job = response.body;

  if (job?.status === JobStatusEnum.RUNNING || job?.status === JobStatusEnum.PENDING) {
    return { type, response: job, requestStatus: RequestStatus.PENDING };
  }
  if (job?.status === JobStatusEnum.FAILED || job?.status === JobStatusEnum.HALTED) {
    throw new Error(`Workflow job failed with status '${job.status}'`);
  }
  if (job?.status === JobStatusEnum.COMPLETED) {
    if (job?.preprocessPredictions) return { type, response: job, requestStatus: RequestStatus.SUCCESS };
    else throw new Error(`Workflow job completed without results`);
  } else {
    throw new Error(`Unknown job status: '${job.status}'`);
  }
};

interface HandleProductBackgroundRemovalActionProps {
  action: ProductBackgroundRemovalAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}
export const handleProductBackgroundRemovalAction = ({
  action,
  backendClient,
  requestsLookup,
  setQueries,
}: HandleProductBackgroundRemovalActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.PRODUCT_BACKGROUND_REMOVAL,
    getQueryResult: ({ id }) => {
      return productBackgroundRemovalQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
