import CssBaseline from '@mui/material/CssBaseline';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'src/AppContext';
import Chat from 'src/components/pages/conversational/ChatPage';
import Header from 'src/components/pages/studio/Header';
import { SnackbarManager } from 'src/components/snackbar/SnackbarManager';
import { LOCAL_STORAGE_KEY_THEME_PREFERENCE, Metrics, STUDIO } from 'src/constants';
import Stack from 'src/customUIComponents/Stack';
import WithNavigationMetrics from 'src/metrics/WithNavigationMetrics';
import { AICS_WEBLAB_DARK_MODE, AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { ImportImageContextProvider } from 'src/v2/contexts/importImage/ImportImageContext';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive, setupThemeListener } from 'src/v2/redux/slices/theme/themeSlice';
import Editor from './editor/Editor';
import './App.css';
import AccountList from './layout/_storm/AccountList';
import Content from './layout/_storm/Content';
import Footer from './layout/_storm/Footer';
import CreativeGallery from './pages/CreativeGallery';
import errorpage from './pages/errorpage';
import InspirationsGallery from './pages/InspirationsGallery';
import Home from './pages/storm/Home';
import Playground from './pages/storm/Playground';
import Studio from './pages/studio/StudioPage';
import WithAuthentication from './WithAuthentication';

import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import '@amzn/meridian-tokens/base/font/amazon-ember-monospace.css';

export interface AppProps {
  page: string;
  editor?: boolean;
  // This is in refrence to the page being unauthenticated, not necessarily the user
  unAuthenticated?: boolean;
  // The path to redirect to if the user is authenticated already
  authenticatedPath?: string;
}

const pages: { [key: string]: React.FunctionComponent } = {
  home: Home,
  browse: InspirationsGallery,
  notfounderror: errorpage,
  chat: Chat,
  playground: Playground as React.FunctionComponent,
  studio: Studio,
  gallery: CreativeGallery,
};

const App = ({ page, editor, unAuthenticated, authenticatedPath }: AppProps) => {
  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();
  const linkTo = useNavigate();
  const { pathname } = window.location;

  useEffect(() => {
    appContext.setActivePage(page);
  }, []);

  // If the user is authenticated and they are currently on an unauthenticated page, then redirect them to the authenticated version of the page
  useEffect(() => {
    if (unAuthenticated && appContext.authenticated && authenticatedPath && pathname !== authenticatedPath) {
      console.info(`Redirecting authenticated user from '${pathname}' to '${authenticatedPath}'`);
      // The setTimeout allows React to complete the
      linkTo(authenticatedPath);
    }
  }, [appContext.authenticated, unAuthenticated, authenticatedPath]);

  const isInputPanelRefresh = isWeblabInTreatment(AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment.T1);
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = useAppSelector(isDarkModeActive);
  const themeMode = isDarkModeLaunched && isDarkMode ? 'themeDark' : 'themeLight';

  useEffect(() => {
    if (isDarkModeLaunched && !localStorage.getItem(LOCAL_STORAGE_KEY_THEME_PREFERENCE)) {
      return setupThemeListener(dispatch);
    }
  }, [dispatch]);

  // Listens for the dark mode (theme) weblab and configures the data property based on the selected theme
  // NOTE: falls back to setting the default 'main' theme if not applicable
  useEffect(() => {
    if (isDarkModeLaunched) {
      if (isDarkMode) {
        document.body.dataset.theme = 'dark';
      } else {
        document.body.dataset.theme = 'light';
      }
    } else {
      document.body.dataset.theme = 'main';
    }
  }, [isDarkModeLaunched, isDarkMode]);

  // Set page styles for editor
  useEffect(() => {
    appContext.setActivePage(page);
    appContext.setOpenEditor(!!editor);
    localStorage.setItem('activePage', page);
    localStorage.setItem('openEditor', JSON.stringify(!!editor));
    if (page.toLocaleLowerCase() === 'studio') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [page, editor]);

  const getContent = () => {
    const Content = pages[appContext.activePage];
    return appContext.openEditor ? <Editor type="editor" module={appContext.selectedTool} /> : <Content />;
  };

  const showFooter = !appContext.openEditor && ![STUDIO.toLowerCase(), 'chat'].includes(page);

  return (
    <div className={themeMode}>
      <ImportImageContextProvider>
        <SnackbarManager />
        <CssBaseline />
        <WithAuthentication
          unAuthenticated={unAuthenticated}
          unAuthenticatedCallback={() => {
            appContext.metrics.trackMetrics(Metrics.Methods.PageMetrics, {}, { [Metrics.Names.UserBounced]: 1 });
            window.location.replace('https://advertising.amazon.com');
          }}
        >
          <WithNavigationMetrics activePage={`${appContext.activePage}${appContext.openEditor ? '/editor' : ''}`}>
            <div id="wrapper" className={isInputPanelRefresh ? 'wrapperFullWidth' : 'wrapper'}>
              {appContext.accountType === 'external' && <AccountList />}
              <Header
                style={
                  isInputPanelRefresh
                    ? {
                        position: appContext.activePage === 'chat' ? 'sticky' : 'unset',
                        width: '100%',
                        top: 0,
                      }
                    : {
                        paddingLeft: 'var(--div-spacing-default)',
                        paddingRight: 'var(--div-spacing-default)',
                        position: appContext.activePage === 'chat' ? 'sticky' : 'unset',
                        width: '100%',
                        maxWidth: '2000px',
                        top: 0,
                      }
                }
              />
              <Stack>
                <Content>{getContent()}</Content>
                {showFooter && <Footer />}
              </Stack>
            </div>
          </WithNavigationMetrics>
        </WithAuthentication>
      </ImportImageContextProvider>
    </div>
  );
};

export default App;
