import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import { urlToFile } from 'src/components/utils/base64Encode';
import { getProcessedImageFromLayout } from 'src/v2/components/studio/productSelector/productSelectorHelpers';
import { uploadMaskImageMiddleware, uploadProductImageMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/ImageUpload.middleware';
import { generateTextPromptsForMultipleProductImagesMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/TextPromptGeneration.middleware';
import { MultipleProductImageGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { prepareGenerationResults } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { DeepPartial, MultipleProductImageAsset } from 'src/v2/types';
import { handleMultipleProductImageGenerationSubmission } from './handleMultipleProductImageGenerationSubmission';

/**
 * The primary handler for generating a list of MutlipleProductImageAssets.
 */
export async function handleMultipleProductImageGeneration({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: MultipleProductImageGenerationConfig;
}): Promise<{
  generatedAssets: MultipleProductImageAsset[];
  errors: Error[];
}> {
  const { outputCount, userInputs } = generationConfig;
  const { products, textPrompt } = userInputs;

  const uploadedAssets: Record<string, string> = {};

  // STEP - Get product reference ID
  const imageSet = new Set<string>();
  const maskSet = new Set<string>();
  for (const layout of products) {
    const { image, mask } = getProcessedImageFromLayout(layout) || {};
    if (!image || !mask) continue;
    imageSet.add(image);
    maskSet.add(mask);
  }

  const imagesUploadPromises = Array.from(imageSet.values()).map(async (image) => {
    uploadedAssets[image] = await uploadProductImageMiddleware({
      backendDispatchContext,
      urlOrFileOrRefId: await urlToFile(image, 'image/png'),
    });
  });
  const masksUploadPromises = Array.from(maskSet.values()).map(async (mask) => {
    uploadedAssets[mask] = await uploadMaskImageMiddleware({
      backendDispatchContext,
      urlOrFileOrRefId: await urlToFile(mask, 'image/png'),
    });
  });

  await Promise.all([...imagesUploadPromises, ...masksUploadPromises]);

  // STEP - Get guided text prompts if textPrompt not given
  const guidedTextPrompts = await generateTextPromptsForMultipleProductImagesMiddleware({
    backendDispatchContext,
    generationConfig,
    productImageReferenceId: uploadedAssets[Array.from(imageSet.values())[0]],
  });

  // STEP - Run parallel generations
  const generations = guidedTextPrompts ? guidedTextPrompts.map((textPrompt) => ({ outputCount: 1, textPrompt })) : [{ outputCount, textPrompt }];

  const generationConfigs: MultipleProductImageGenerationConfig[] = generations.map(({ outputCount, textPrompt }) => {
    const generationConfigOverrides: DeepPartial<typeof generationConfig> = {
      outputCount,
      userInputs: {
        textPrompt,
      },
    };
    return _merge(_cloneDeep(generationConfig), generationConfigOverrides);
  });

  const generationResults = await Promise.allSettled(
    generationConfigs.map((generationConfig) =>
      handleMultipleProductImageGenerationSubmission({ backendDispatchContext, generationConfig, uploadedAssets }),
    ),
  );

  return prepareGenerationResults<MultipleProductImageAsset>({ generationResults });
}
