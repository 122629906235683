/* istanbul ignore file */
import KatalLogger, { Level } from '@amzn/katal-logger';
import { Metrics } from 'src/constants';
import { STAGE } from 'src/util/env';

const makeKatalLogger = () => {
  const katalLoggerConfig = {
    url: '/monitoring/log',
    logThreshold: Level.INFO,
    maxLogLineSize: 50000,
    logToConsole: true,
    context: {
      [Metrics.Dimensions.Stage]: STAGE,
    },
  };

  const logger = new KatalLogger(katalLoggerConfig);
  logger.addErrorListener();
  logger.addPerformanceListener();

  return logger;
};

export const logger = makeKatalLogger();
