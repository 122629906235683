import { WorkflowId } from '@amzn/genaihub-typescript-client';
import { AssetTypeEnum, WorkflowIdEnum } from 'src/v2/types';

interface CopyOptions {
  usePlural?: boolean; // 'video' -> 'videos'
  useArticle?: boolean; // 'image' -> 'an image'
  useCapital?: boolean; // 'image' -> 'Image'
}

export function getAssetTypeText(assetType: AssetTypeEnum | undefined, options?: CopyOptions): string {
  let assetCopy;
  let article;
  switch (assetType) {
    case AssetTypeEnum.VIDEO:
      assetCopy = 'video';
      article = 'a';
      break;
    case AssetTypeEnum.IMAGE:
    default:
      assetCopy = 'image';
      article = 'an';
      break;
  }

  if (options?.useCapital) assetCopy = assetCopy.charAt(0).toUpperCase() + assetCopy.slice(1);
  if (options?.usePlural) assetCopy = `${assetCopy}s`;
  if (options?.useArticle) assetCopy = `${article} ${assetCopy}`;

  return assetCopy;
}

export function getAssetTypeFromWorkflowId(workflowId: WorkflowId): AssetTypeEnum {
  return workflowId === WorkflowIdEnum.PARALLAX_MOTION ? AssetTypeEnum.VIDEO : AssetTypeEnum.IMAGE;
}
