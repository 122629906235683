import { Controls } from 'src/components/editor/UiContols/uiGeneratorControls/controlComponentTypes';
import delay from './delay';

let workflowOptions: object = {};
let watch = false;
let pause: number;

export default function batchStateUpdate(controlData: Record<string, unknown>, callback: (options: Controls) => void, overrideDelay: number = 3000) {
  pause = overrideDelay;
  if (controlData) {
    workflowOptions = { ...workflowOptions, ...controlData };
  }

  if (!watch && Object.keys(workflowOptions).length > 0) {
    watch = true;
    updateWorkFlow(callback);
  }
}

const reset = () => {
  workflowOptions = {};
  watch = false;
};

const updateWorkFlow = async (callback: (options: Controls) => void) => {
  await delay(pause);
  callback(workflowOptions);

  reset();
};
