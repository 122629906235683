import { Asset, BatchResult } from '@amzn/genaihub-typescript-client';
import { PublishAssetToFeedAction } from 'src/v2/contexts/backend/actions/PublishAssetToFeed';
import { RetrieveAssetAction } from 'src/v2/contexts/backend/actions/RetrieveAsset';
import { RetrieveResultByWorkflowIdAndBatchIdAction } from 'src/v2/contexts/backend/actions/RetrieveResultByWorkflowIdAndBatchId';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { BackendActionType } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { AbortError } from 'src/v2/errors/CustomErrors';

/** Generic PublishAssetToFeed Handler */
interface HandlePublishAssetToFeed {
  backendDispatchContext: BackendDispatchContextType;
  // Force a decision on including the entityId so it doesn't get missed
  payload: PublishAssetToFeedAction['payload'] & { entityId: string | undefined };
}

export async function handlePublishAssetToFeed({ backendDispatchContext, payload }: HandlePublishAssetToFeed): Promise<string> {
  return new Promise((resolve, reject) => {
    backendDispatchContext({
      type: BackendActionType.PUBLISH_ASSET_TO_FEED,
      payload,
      onAbort: () => {
        return reject(new AbortError(`Aborted backend action: '${BackendActionType.PUBLISH_ASSET_TO_FEED}'`));
      },
      onError: (err) => {
        return reject(err);
      },
      onSuccess: ({ response }) => {
        const { assetId } = response;
        if (!assetId) {
          console.error(`Failed to publish asset, response contained invalid value for assetId: '${assetId}'`);
          return reject(new Error('Failed to publish asset'));
        }
        return resolve(assetId);
      },
    });
  });
}

/** Generic RetrieveAsset Handler */
interface HandleRetrieveAsset {
  backendDispatchContext: BackendDispatchContextType;
  payload: RetrieveAssetAction['payload'];
}

export async function handleRetrieveAsset({ backendDispatchContext, payload }: HandleRetrieveAsset): Promise<Asset> {
  return new Promise((resolve, reject) => {
    backendDispatchContext({
      type: BackendActionType.RETRIEVE_ASSET,
      payload,
      onAbort: () => {
        return reject(new AbortError(`Aborted backend action: '${BackendActionType.RETRIEVE_ASSET}'`));
      },
      onError: (err) => {
        return reject(err);
      },
      onSuccess: ({ response }) => {
        const { asset } = response;
        if (!asset) {
          console.error(`Failed to retrieve asset, response contained an undefined asset value.`);
          return reject(new Error('Failed to retrieve asset'));
        }
        return resolve(asset);
      },
    });
  });
}

/** Generic RetrieveResultByWorkflowIdAndBatchId Handler **/
interface HandleRetrieveResultByWorkflowIdAndBatchIdDispatch<TResult> {
  backendDispatchContext: BackendDispatchContextType;
  payload: RetrieveResultByWorkflowIdAndBatchIdAction['payload'];
  onSuccessHandler: (props: { response: BatchResult }) => TResult | Error;
}

export async function handleRetrieveResultByWorkflowIdAndBatchIdDispatch<TResult>({
  backendDispatchContext,
  payload,
  onSuccessHandler,
}: HandleRetrieveResultByWorkflowIdAndBatchIdDispatch<TResult>): Promise<TResult> {
  return new Promise((resolve, reject) => {
    backendDispatchContext({
      type: BackendActionType.RETRIEVE_RESULT_BY_WORKFLOW_ID_AND_BATCH_ID,
      payload,
      onAbort: () => {
        return reject(new AbortError(`Aborted backend action: '${BackendActionType.RETRIEVE_RESULT_BY_WORKFLOW_ID_AND_BATCH_ID}'`));
      },
      onError: (err) => {
        return reject(err);
      },
      onSuccess: ({ response }) => {
        const result = onSuccessHandler({ response });
        if (result instanceof Error) {
          return reject(result);
        }
        return resolve(result);
      },
    });
  });
}

/** Generic SubmitWorkflowById Handler **/
interface HandleSubmitWorkflowByIdDispatch {
  backendDispatchContext: BackendDispatchContextType;
  payload: StronglyTypedSubmitWorkflowByIdInput;
}

interface HandleSubmitWorkflowByIdDispatchResult {
  batchId: string;
}

export async function handleSubmitWorkflowByIdDispatch({
  backendDispatchContext,
  payload,
}: HandleSubmitWorkflowByIdDispatch): Promise<HandleSubmitWorkflowByIdDispatchResult> {
  return new Promise((resolve, reject) => {
    backendDispatchContext({
      type: BackendActionType.SUBMIT_WORKFLOW_BY_ID,
      payload,
      onAbort: () => {
        return reject(new AbortError(`Aborted backend action, '${BackendActionType.SUBMIT_WORKFLOW_BY_ID}', for workflow '${payload.workflowId}'`));
      },
      onError: (err) => {
        return reject(err);
      },
      onSuccess: ({ response }) => {
        const batchId = response.batchId;
        if (!batchId) {
          return reject(new Error(`${payload.workflowId} workflow submission failed to respond with batchId`));
        }
        return resolve({ batchId });
      },
    });
  });
}
