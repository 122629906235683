import { BoundingBox } from '@amzn/genaihub-typescript-client';
import type Konva from 'konva';
import { ComponentProps, forwardRef, RefObject, useEffect, useRef, useState } from 'react';
import { Image, Layer, Stage, Transformer } from 'react-konva';
import { adjustBoundingBox, boundProductImage } from 'src/components/imageModal/components/utils';
import styled from 'styled-components';
import useImage from 'use-image';
import rotationIcon from './rotationIcon.jpg';

export const TEST_ID_MULTI_IMAGE_CANVAS = 'studio-multi-image-canvas';
export type MultiImageCanvasProps = {
  images: { boundingBox: BoundingBox; zIndex: number; src: string; id: string }[];
  onReposition: (newImageProps: MultiImageCanvasProps['images'][number]) => void;
  canvasOptions: {
    width: number;
    height: number;
    deadZone?: number;
  };
  outputOptions: {
    width: number;
    height: number;
  };
  loading?: boolean;
  allowOutOfBounds?: boolean;
};
type ImageComponentProps = {
  stageRef: RefObject<Konva.Stage>;
  src: string;
  id: string;
  boundingBox: BoundingBox;
  zIndex: number;
  deadZone?: number;
  setRef: (node: Konva.Image) => void;
  allowOutOfBounds?: boolean;
} & Omit<ComponentProps<typeof Image>, 'image'>;

const ImageComponent = forwardRef<Konva.Image, ImageComponentProps>(
  ({ deadZone, id, stageRef, src, boundingBox, zIndex, setRef, allowOutOfBounds, ...otherProps }, ref) => {
    const [imageObj] = useImage(src);
    const internalRef = useRef<Konva.Image>(null);
    useEffect(() => {
      if (internalRef.current) {
        internalRef.current.zIndex(zIndex);
        internalRef.current.on('click', (e) => {
          setRef(e.target as Konva.Image);
        });
        internalRef.current.on('dragstart', () => internalRef.current && setRef(internalRef.current));
      }
    }, [internalRef]);

    useEffect(() => {
      if (typeof ref === 'function') {
        ref(internalRef.current);
      } else if (ref) {
        ref.current = internalRef.current;
      }
    }, [ref]);

    return (
      <Image
        {...otherProps}
        key={src}
        image={imageObj}
        id={id}
        ref={internalRef}
        width={boundingBox.width}
        height={boundingBox.height}
        x={boundingBox.left}
        y={boundingBox.top}
        rotation={boundingBox.rotateAngle}
        dragBoundFunc={(vec) => (allowOutOfBounds ? vec : boundProductImage(vec, stageRef.current, internalRef.current, deadZone))}
      />
    );
  },
);
ImageComponent.displayName = 'ImageComponent';

const anchorStyleFunc = (anchor: Konva.Rect, iconRef?: HTMLImageElement) => {
  if (anchor.hasName('rotater')) {
    anchor.cornerRadius(16);
    if (iconRef) {
      anchor.fillPatternImage(iconRef);
      anchor.fillPriority('pattern');
      anchor.fillPatternRepeat('no-repeat');
      anchor.fillPatternScale({
        x: 17 / (iconRef.naturalWidth + 4),
        y: 17 / (iconRef.naturalHeight + 4),
      });
      anchor.fillPatternOffset({
        x: -2,
        y: -2,
      });
    }
  }
};

const LoadingOverlay = styled.div<{ show: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  background: black;
  opacity: 50%;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  top: 0;
  color: white;
`;

const CanvasContainer = styled.div`
  position: relative;
  box-shadow: 0px 2px 4px 0px #232f3f26;
  border: 2px dashed darkgrey;
  border-radius: 8px;
  overflow: hidden;
`;

export const MultiImageCanvas = forwardRef<Konva.Stage, MultiImageCanvasProps>(
  ({ images, loading = false, canvasOptions, outputOptions, onReposition, allowOutOfBounds = false }, ref) => {
    const [selectedImage, setSelectedImage] = useState<{ imageObj: Konva.Image; id: string }>();
    const transformerRef = useRef<Konva.Transformer>(null);
    const [iconRef] = useImage(rotationIcon);
    const stageRef = useRef<Konva.Stage>(null);
    useEffect(() => {
      if (selectedImage && transformerRef.current) {
        transformerRef.current.nodes([selectedImage.imageObj]);
      } else {
        transformerRef.current?.nodes([]);
      }
    }, [selectedImage, transformerRef]);

    useEffect(() => {
      if (typeof ref === 'function') {
        ref(stageRef.current);
      } else {
        if (ref) ref.current = stageRef.current;
      }
      if (stageRef.current) {
        const style = stageRef.current.container().style;
        style.backgroundColor = 'var(--surface-tertiary, #FBFBFAFF)';
        style.borderRadius = '8px';
        stageRef.current.container().setAttribute('data-testid', TEST_ID_MULTI_IMAGE_CANVAS);
      }
    }, [ref, stageRef]);

    const multipler = canvasOptions.width / outputOptions.width;
    const updateImageProps = (target: Konva.Node) => {
      if (!selectedImage) return;
      const currentImage = images.find((image) => {
        return image.id === selectedImage.id;
      });
      if (!currentImage) return;
      const { width, height, scaleX, scaleY, rotation, x, y } = target.attrs;
      const output = {
        width: width * scaleX,
        height: height * scaleY,
        left: x,
        top: y,
        rotateAngle: Math.round(rotation || 0),
      };
      const adjusted = adjustBoundingBox(output, 1 / multipler);

      target.setAttrs({
        ...output,
        scaleY: 1,
        scaleX: 1,
      });
      onReposition({
        ...currentImage,
        boundingBox: adjusted,
      });
    };

    return (
      <CanvasContainer>
        <Stage
          width={canvasOptions.width}
          height={canvasOptions.height}
          ref={stageRef}
          onClick={(event) => {
            if (event.target === stageRef.current) setSelectedImage(undefined);
          }}
        >
          <Layer id="image-layer">
            {images.map((image, index) => {
              const { src, boundingBox } = image;
              const newBoundingBox = adjustBoundingBox(boundingBox, multipler);
              const imageId = `${image.id}-${src}`;
              return (
                <ImageComponent
                  allowOutOfBounds={allowOutOfBounds}
                  draggable
                  id={imageId}
                  src={src}
                  key={imageId}
                  boundingBox={newBoundingBox}
                  zIndex={index}
                  setRef={(node: Konva.Image) => {
                    setSelectedImage(() => {
                      return {
                        imageObj: node,
                        id: image.id,
                      };
                    });
                  }}
                  stageRef={stageRef}
                  deadZone={allowOutOfBounds ? 0 : canvasOptions.deadZone}
                  onDragEnd={({ target }: { target: Konva.Image }) => {
                    updateImageProps(target);
                  }}
                />
              );
            })}
          </Layer>
          <Layer id="transformer-layer">
            <Transformer
              enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
              ref={transformerRef}
              anchorCornerRadius={2}
              anchorSize={17}
              anchorStroke={'#9748FF'}
              borderStroke={'#9748FF'}
              borderDash={[5, 2]}
              anchorStyleFunc={(anchor) => anchorStyleFunc(anchor, iconRef)}
              rotateAnchorOffset={46}
              keepRatio
              flipEnabled={false}
              onTransformEnd={({ target }) => {
                updateImageProps(target);
              }}
              id="transformer"
            />
          </Layer>
        </Stage>
        <LoadingOverlay show={loading}> Loading ... </LoadingOverlay>
      </CanvasContainer>
    );
  },
);

MultiImageCanvas.displayName = 'MultiImageCanvas';
