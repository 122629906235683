import { ASINMetadata } from '@amzn/genaihub-typescript-client';
import { AsinProduct, ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { SelectedProductFull } from 'src/v2/redux/slices/userInput/userInputSlice.types';

export const createAsinProduct = ({ asin, metadata }: { asin: string; metadata: ASINMetadata }): AsinProduct => ({
  id: asin,
  asin,
  metadata,
  type: ProductType.ASIN,
});

export function getProductImageUrl({ product }: { product: SelectedProductFull }) {
  switch (product.type) {
    case ProductType.CUSTOM_IMAGE:
      return product.customImage.url;
    case ProductType.ASIN:
    default: {
      if (product.options?.asinProduct?.customProductImageUrl) {
        return product.options?.asinProduct?.customProductImageUrl;
      }
      const selectedImageIndex = product.options?.asinProduct?.selectedImageIndex ?? 0;
      const mediaCentralAssets = product.metadata.mediaCentralAssets;
      const selectedImage = mediaCentralAssets?.[selectedImageIndex];
      return selectedImage?.highResUri || selectedImage?.lowResUri;
    }
  }
}

export function getProductTitle({ product }: { product: SelectedProductFull }): string {
  switch (product.type) {
    case ProductType.CUSTOM_IMAGE:
      return product.customImage.name;
    case ProductType.ASIN:
    default: {
      return product.metadata.title ?? product.asin;
    }
  }
}
