import { useContext } from 'react';
import { AppContext } from 'src/AppContext';
import HeroGenerate from 'src/components/hero/HeroGenerate';
import HeroUnauthenticated from 'src/components/hero/HeroUnauthenticated';
import { AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import styles from './Hero.module.scss';

const Hero = () => {
  const { authenticated } = useContext(AppContext);
  const isInputPanelRefresh = isWeblabInTreatment(AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment.T1);
  return (
    <div className={`${isInputPanelRefresh ? styles.heroFullWidth : styles.hero} ${authenticated ? styles.authenticated : ''}`}>
      {authenticated ? <HeroGenerate /> : <HeroUnauthenticated />}
    </div>
  );
};
export default Hero;
