import { ASINMetadata } from '@amzn/genaihub-typescript-client';
import { CustomImageDetails } from 'src/v2/types';

export enum ProductType {
  ASIN = 'asin',
  CUSTOM_IMAGE = 'customImage',
}

interface ProductBase<TProductType extends ProductType> {
  id: string;
  type: TProductType;
}

export interface AsinProduct extends ProductBase<ProductType.ASIN> {
  asin: string;
  metadata: ASINMetadata;
  // TODO : should be removed. This two data should be stored as SelectedProduct | SelectedProductFull at userInputSlice.types.ts
  selectedImageIndex?: number;
  customProductImageUrl?: string;
}

export interface CustomImageProduct extends ProductBase<ProductType.CUSTOM_IMAGE> {
  customImage: CustomImageDetails;
}

export type Product = AsinProduct | CustomImageProduct;
