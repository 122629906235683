import { Text } from '@amzn/storm-ui';
import { useCallback, useState } from 'react';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { Icon } from 'src/v2/components/Icon/Icon';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import { getDataTestId, getNormalizedClassNames } from 'src/v2/utils/utils';
import { ButtonPopover, ButtonStylesWrapper, HiddenButtonOverlay } from './Button.styles';
import { getButtonIconColor } from './helpers';
import { ButtonStyleClasses, ButtonProps, ButtonSizes, ButtonTypes } from './types';

export const Button = (props: ButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    borderRadius = '8px',
    className,
    colorOverride,
    darkMode,
    dataTestId,
    disabled = false,
    fullWidth = false,
    icon,
    iconOnly = false,
    iconOnlyBackgroundColor,
    iconOnlyColor,
    iconOnlyNoBackground = false,
    iconOnlyPaddingPercent,
    iconOnlyRounded = false,
    iconSize,
    label,
    loading = false,
    onClick,
    size = ButtonSizes.Medium,
    style = undefined,
    type = ButtonTypes.Primary,
    popoverText,
    ...rest
  } = props;

  if (!label && !icon) {
    return <></>;
  }

  const showIconOnly = icon && iconOnly;
  const buttonFullWidth = fullWidth ? ButtonStyleClasses.fullWidth : '';
  const buttonSize = size?.toLowerCase();
  const buttonType = type?.toLowerCase();
  const disabledStyles = disabled ? ButtonStyleClasses.disabled : '';
  const iconOnlyRoundedStyle = !iconOnlyNoBackground && showIconOnly && iconOnlyRounded ? ButtonStyleClasses.iconOnlyRounded : '';
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = darkMode || (isDarkModeLaunched && useAppSelector(isDarkModeActive)) || false;

  const iconColor = getButtonIconColor({
    colorOverride,
    darkMode: isDarkMode,
    disabled,
    icon,
    iconOnly,
    iconOnlyNoBackground,
    loading,
    type,
  });
  const loadingStyles = loading ? ButtonStyleClasses.loading : '';
  const darkModeStyle = isDarkMode ? ButtonStyleClasses.darkMode : ''; // TODO: read users' system level dark mode setting and update this line

  const handleClick = onClick;

  const classNames = getNormalizedClassNames([
    buttonFullWidth,
    buttonSize,
    buttonType,
    className,
    darkModeStyle,
    disabledStyles,
    iconColor,
    iconOnlyRoundedStyle,
    loadingStyles,
  ]);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const buttonComponent = (
    <ButtonStylesWrapper
      borderRadius={borderRadius}
      className={classNames}
      data-testid={getDataTestId(dataTestId)}
      darkMode={isDarkMode}
      disabled={disabled}
      iconOnlyColor={iconOnlyColor}
      iconOnlyBackgroundColor={iconOnlyBackgroundColor}
      loading={loading}
      onClick={disabled || loading ? undefined : handleClick}
      size={size}
      small={true}
      style={style}
      colorOverride={colorOverride}
      onMouseOver={handleToggle}
      onMouseOut={handleClose}
      {...rest} // handles passing through other props like aria attributes etc
    >
      {popoverText && <HiddenButtonOverlay />}
      {!loading && icon && (
        <Icon
          backgroundColor={iconOnlyBackgroundColor}
          color={iconOnlyColor}
          dataTestId={`${getDataTestId(dataTestId)}-icon`}
          paddingPercent={iconOnlyPaddingPercent}
          showBackground={showIconOnly && iconOnlyRounded}
          size={iconSize}
          type={icon}
          darkMode={isDarkMode}
        />
      )}
      {!showIconOnly && <span data-testid={`${getDataTestId(dataTestId)}-label`}>{label}</span>}
    </ButtonStylesWrapper>
  );

  if (popoverText) {
    return (
      <ButtonPopover
        data-testid={`${getDataTestId(dataTestId)}-popover`}
        isOpen={isOpen}
        trigger={buttonComponent}
        withCloseButton={false}
        autoFocus={true}
        align="center"
        withArrow={true}
        transitionDuration={300}
        darkMode={isDarkMode}
        spacing={0}
      >
        <Text>{popoverText}</Text>
      </ButtonPopover>
    );
  }

  return buttonComponent;
};
