import { ProductEditGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { EditedImageAsset } from 'src/v2/types';
import { handleProductEditGenerationSubmission } from './handleProductEditGenerationSubmission';

/**
 * The primary handler for generating a list of edited images using the IMAGE_EDITOR workflow.
 */
export async function handleProductEditGeneration({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: ProductEditGenerationConfig;
}): Promise<{
  generatedAssets: EditedImageAsset[];
}> {
  const generatedAssets = await handleProductEditGenerationSubmission({
    backendDispatchContext,
    generationConfig,
  });

  return { generatedAssets };
}
