import { WorkflowIdEnum } from 'src/v2/types';

export const getWorkflowNameById = (workflowId?: string) => {
  switch (workflowId) {
    case WorkflowIdEnum.BRAND_IDENTITY_TEXT_TO_IMAGE:
      return 'Generate brand images';
    case WorkflowIdEnum.GENERATIVE_RESIZING:
      return 'Generative Resizing';
    case WorkflowIdEnum.IMAGE_EDITOR:
      return 'Edit Images';
    case WorkflowIdEnum.IMAGE_THEMING:
      return 'Image Theming';
    case WorkflowIdEnum.LIFESTYLE_IMAGERY:
    case WorkflowIdEnum.LIFESTYLE_IMAGERY_PROMPT_ONLY:
      return 'Lifestyle Imagery';
    case WorkflowIdEnum.PARALLAX_MOTION:
      return 'Parallax Motion';
    case WorkflowIdEnum.TEXT_TO_IMAGE:
    default:
      return 'Generate Images';
  }
};
