import { IconDefinition } from '@amzn/storm-ui-icons';
import { IconName } from './iconTypes';

export enum IconSize {
  xsmall = 'xsmall',
  small = 'small',
  base = 'base',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  '2x' = '2x',
  '3x' = '3x',
  '5x' = '5x',
  '7x' = '7x',
  '9x' = '9x',
  '10x' = '10x',
}

export interface IconProps {
  backgroundColor?: string;
  borderRadius?: number;
  color?: string;
  darkMode?: boolean;
  dataTestId?: string;
  matchFontSize?: boolean;
  paddingPercent?: number;
  showBackground?: boolean;
  backgroundSize?: {
    width: string;
    height: string;
  };
  size?: IconSize | string | undefined;
  type: IconDefinition | IconName;
}
