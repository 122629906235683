import type { ASINMetadata, BoundingBox, WorkflowId } from '@amzn/genaihub-typescript-client';
import type { AsinCategoryEnum, CameraPath, ImageLayout, WorkflowIdEnum } from 'src/v2/types';

export enum TaskType {
  INPAINTING = 'inpainting',
  REMOVAL = 'removal',
}

export enum PromptType {
  PRODUCT_LIFESTYLE = 'PRODUCT_LIFESTYLE',
  PRODUCT_LESS_LIFESTYLE = 'PRODUCT_LESS_LIFESTYLE',
}

interface WorkflowOptionsBase<TWorkflowId extends WorkflowId> {
  // Used to help distinguish interfaces by WorkflowId when receiving WorkflowOptions
  workflowId: TWorkflowId;
}

/** GENERATIVE_RESIZING **/
export interface GenerativeResizingWorkflowOptions extends WorkflowOptionsBase<WorkflowIdEnum.GENERATIVE_RESIZING> {
  // The new aspect ratio of the input image
  aspectRatio: string; // -> aspect_ratio
  imageCount: number | undefined;
  imageLayout: ImageLayout; // -> layout
  inputImageRef: string; // -> imageRef
  // NOTE: the backend lists maskRef as an input, but it's not actually necesary. Leaving this here in the event it becomes a usable property.
  // maskRef: string | undefined; // -> mask_ref
  seed: number | undefined;
}

/** GUIDED_TEXT_GENERATION **/
export interface GuidedTextGenerationWorkflowOptions extends WorkflowOptionsBase<WorkflowIdEnum.GUIDED_TEXT_GENERATION> {
  asin: string | undefined;
  featureBullets: string[] | undefined; // -> 'feature_bullets'
  productImageRef: string; // -> 'product_image'
  productType: ASINMetadata['type'];
  promptCount: number | undefined; // -> 'prompt_count'
  promptType: PromptType; // -> 'prompt_type'
  // TODO: create an enum for Style/Theme set of values, including 'no_theme'
  theme: string;
  title: string;
}

/** IMAGE_EDITOR **/
interface ImageEditorWorkflowOptionsBase<TTaskType extends TaskType> extends WorkflowOptionsBase<WorkflowIdEnum.IMAGE_EDITOR> {
  imageCount: number | undefined; // -> image_count
  inputImageRef: string; // -> image_ref
  maskRef: string; // -> mask_ref
  // NOTE: the backend lists productMaskRef as an input, but it's not actually necesary. Leaving this here in the event it becomes a usable property.
  // productMaskRef: string | undefined; // -> product_mask_ref
  seed: number | undefined;
  taskType: TTaskType; // -> task_type
  textPrompt: string | undefined; // -> prompt
}

export type ImageEditorAddActionWorkflowOptions = ImageEditorWorkflowOptionsBase<TaskType.INPAINTING>;

export type ImageEditorRemoveActionWorkflowOptions = ImageEditorWorkflowOptionsBase<TaskType.REMOVAL>;

export type ImageEditorWorkflowOptions = ImageEditorAddActionWorkflowOptions | ImageEditorRemoveActionWorkflowOptions;

/** IMAGE_THEMING **/
export interface ImageThemingWorkflowOptions extends WorkflowOptionsBase<WorkflowIdEnum.IMAGE_THEMING> {
  inputImageRef: string; // -> input_image
  seed: number | undefined;
  strength: number | undefined;
  // TODO: create an enum for Style/Theme set of values, including 'no_theme'
  theme: string;
}

/** LIFESTYLE_IMAGERY_PROMPT_ONLY and LIFESTYLE_IMAGERY **/
interface LifestyleImageryWorkflowOptionsBase<TWorkflowId extends WorkflowId> extends WorkflowOptionsBase<TWorkflowId> {
  aspectRatio: string | undefined; // -> aspect_ratio
  description: string | undefined;
  imageCount: number | undefined; // -> image_count
  rewriteUserCustomPrompt: boolean;
  // NOTE: there is a prompts parameter as well, but this can result in a batchId that exceeds Catwalk's 64 character limit for that parameter - the backend concatenates multiple batchId's together separated by commas.
  textPrompt: string; // -> prompt
  // Lifestyle Imagery doesn't support theme (even though the backend accepts the parameter)
  theme: undefined;
  title: string;
}

export type LifestyleImageryPromptOnlyWorkflowOptions = LifestyleImageryWorkflowOptionsBase<WorkflowIdEnum.LIFESTYLE_IMAGERY_PROMPT_ONLY>;

export interface LifestyleImageryWorkflowOptions extends LifestyleImageryWorkflowOptionsBase<WorkflowIdEnum.LIFESTYLE_IMAGERY> {
  asin: string | undefined;
  productImageRef: string | undefined; // -> product_image
}

/** PARALLAX_MOTION **/
export interface ParallaxMotionWorkflowOptions extends WorkflowOptionsBase<WorkflowIdEnum.PARALLAX_MOTION> {
  cameraPath: CameraPath | undefined; // -> camera_path
  height: number | undefined;
  inputImageRef: string; // -> image_ref
  maskRef: string | undefined; // -> mask_ref
  motionIntensity: number | undefined; // -> motion_intensity
  outDuration: number | undefined; // -> out_duration
  outFps: number | undefined; // -> out_fps
  width: number | undefined;
}

/** PRODUCT_EDIT **/
export interface ProductEditWorkflowOptions extends WorkflowOptionsBase<WorkflowIdEnum.PRODUCT_EDIT> {
  boundingBox: BoundingBox;
  // This is a Catwalk-managed image ID
  catwalkImageId: string; // -> imageId
  imageCount: number | undefined; // -> image_count
}

/** TEXT_TO_IMAGE and BRAND_IDENTITY_TEXT_TO_IMAGE **/
interface ProductLifestyleWorkflowOptionsBase<TWorkflowId extends WorkflowId> extends WorkflowOptionsBase<TWorkflowId> {
  asin: string | undefined;
  // Type-safety check to avoid using the wrong workflow options for Apparel
  asinCategory: Omit<AsinCategoryEnum, 'APPAREL'>; // EXCLUDE FROM REQUEST
  aspectRatio: string; // -> aspect_ratio
  imageCount: number | undefined; // -> num_of_images
  productImageRef: string; // -> product_<D-f>image
  referenceImageRef: string | undefined; // -> reference_image
  textPrompt: string; // -> text_prompt
  // TODO: create an enum for Style/Theme set of values, including 'no_theme'
  theme: string; // -> themes
  referenceImagesRef: string[] | undefined; // -> reference_images
  referenceImagesStrength: number | undefined; // -> reference_images_strength
}

export interface MultiProductWorkflowOptions extends WorkflowOptionsBase<WorkflowIdEnum.MULTI_PRODUCT> {
  products: {
    image: string;
    mask: string;
    prompt: string;
    boundingBox: BoundingBox;
    asin: string | undefined;
    title: string | undefined;
    description: string | undefined;
    features: string[] | undefined;
    theme: string | undefined;
  }[];
  numberOfImages: number | undefined;
  aspectRatio: string;
}

export type TextToImageWorkflowOptions = ProductLifestyleWorkflowOptionsBase<WorkflowIdEnum.TEXT_TO_IMAGE>;
export type BrandIdentityTextToImageWorkflowOptions = ProductLifestyleWorkflowOptionsBase<WorkflowIdEnum.BRAND_IDENTITY_TEXT_TO_IMAGE>;
export type ApparelWorkflowOptions = ProductLifestyleWorkflowOptionsBase<WorkflowIdEnum.APPAREL>;

// All parameters are normalized to strings
export type WorkflowOptions =
  | ApparelWorkflowOptions
  | BrandIdentityTextToImageWorkflowOptions
  | GenerativeResizingWorkflowOptions
  | GuidedTextGenerationWorkflowOptions
  | ImageEditorWorkflowOptions
  | ImageThemingWorkflowOptions
  | LifestyleImageryPromptOnlyWorkflowOptions
  | LifestyleImageryWorkflowOptions
  | ParallaxMotionWorkflowOptions
  | ProductEditWorkflowOptions
  | TextToImageWorkflowOptions
  | MultiProductWorkflowOptions;
