import { useMemo } from 'react';
import { FeedEmptyPlaceholdersGroupItem } from 'src/v2/components/studio/feed/_partials/FeedEmptyPlaceholdersGroupItem';
import { DEFAULT_PAGE_SIZE } from 'src/v2/contexts/feed/constants/FeedContext.constants';
import { useAppSelector } from 'src/v2/redux/hooks';
import { getAspectRatio } from 'src/v2/redux/slices/userInput/userInputSlice';

export const FeedEmptyPlaceholdersGroup = () => {
  const userInputsAspectRatio = useAppSelector(getAspectRatio);
  const feedEmptyPlaceholdersGroupItems = useMemo(() => {
    return Array(DEFAULT_PAGE_SIZE)
      .fill(null)
      .map((value, index) => {
        return <FeedEmptyPlaceholdersGroupItem key={`feed-fetch-placeholder-${index}`} aspectRatio={userInputsAspectRatio} />;
      });
  }, [userInputsAspectRatio]);

  return <>{feedEmptyPlaceholdersGroupItems}</>;
};
