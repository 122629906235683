import { Button, Text } from '@amzn/storm-ui';
import './GenerateButton.scss';
import { AnimatedStars } from 'src/animations/AnimatedStars';
import { GenerateOptionType } from 'src/v2/types';
import styled from 'styled-components';

export interface GenerateButtonProps {
  disabled: boolean;
  clickHandler: (options?: GenerateOptionType) => void;
  text: string;
  animate: boolean;
  className?: string;
  dataTestId?: string;
  width?: number;
  fullscreen?: boolean;
  style?: { [key: string]: string };
  hideLabel?: boolean;
}

const ButtonContentWrapper = styled.div<{ hideLabel: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 32px;
  overflow: hidden;
  left: ${(props) => (props.hideLabel ? 'auto' : '-10px')};
`;

export const GenerateButton = ({ disabled, clickHandler, text, dataTestId, width, fullscreen, style, animate, hideLabel }: GenerateButtonProps) => {
  const buttonContent = (
    <ButtonContentWrapper hideLabel={hideLabel || false}>
      <AnimatedStars width={50} height={50} animated={animate} />
      {!hideLabel && <Text style={{ fontSize: '15px', fontWeight: '500', color: disabled ? 'white' : 'inherit' }}>{text}</Text>}
    </ButtonContentWrapper>
  );

  return (
    <div className="animated-button-container" aria-disabled={disabled} style={{ ...style, width: fullscreen ? '100%' : width }}>
      <Button
        className={animate ? 'active-animated-button' : 'animated-button-inputrefresh'}
        variant="primary"
        onClick={() => clickHandler()}
        disabled={disabled}
        data-testid={dataTestId}
        style={{
          borderRadius: '8px',
          background: 'black',
        }}
        aria-label={'Generate button'}
      >
        {buttonContent}
      </Button>
    </div>
  );
};
