import Genaihubbackend, { PublishAssetFromUrlInput, PublishAssetFromUrlOutput } from '@amzn/genaihub-typescript-client';
import { UseMutationResult } from '@tanstack/react-query';
import { BackendActionBase, BackendActionType, RequestsLookup } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { handleInit, handleError, handleSuccess } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type PublishAssetToAssetLibraryAction = BackendActionBase<
  BackendActionType.PUBLISH_ASSET_TO_ASSET_LIBRARY,
  PublishAssetFromUrlInput,
  PublishAssetFromUrlOutput['body']
>;

interface PublishAssetToAssetLibraryMutationProps {
  backendClient: Genaihubbackend;
  payload: PublishAssetFromUrlInput;
}

export const PublishAssetToAssetLibraryMutationConfig = {
  mutationFn: async ({ backendClient, payload }: PublishAssetToAssetLibraryMutationProps) => {
    const response = await backendClient.publishAssetFromUrl(payload);
    // The backend doesn't currently conform to its own model
    // Taskei: https://taskei.amazon.dev/tasks/HB-1191
    if ('body' in response.body) {
      return response.body.body as PublishAssetFromUrlOutput['body'];
    } else {
      return response.body;
    }
  },
};

interface HandlePublishAssetToAssetLibraryActionProps {
  action: PublishAssetToAssetLibraryAction;
  backendClient: Genaihubbackend;
  publishAssetToAssetLibraryMutation: UseMutationResult<PublishAssetFromUrlOutput['body'], Error, PublishAssetToAssetLibraryMutationProps, unknown>;
  requestsLookup: RequestsLookup;
}

export const handlePublishAssetToAssetLibraryAction = async ({
  action,
  backendClient,
  publishAssetToAssetLibraryMutation,
  requestsLookup,
}: HandlePublishAssetToAssetLibraryActionProps) => {
  const id = handleInit({ action, requestsLookup });
  try {
    const response = await publishAssetToAssetLibraryMutation.mutateAsync({
      backendClient,
      payload: action.payload,
    });
    return handleSuccess({ response, action, id, requestsLookup });
  } catch (err) {
    return handleError({ err, action, id, requestsLookup });
  }
};
