import Genaihubbackend, { RetrieveAssetsInput, RetrieveAssetsOutput } from '@amzn/genaihub-typescript-client';
import {
  BackendActionBase,
  BackendActionQueryResultBase,
  BackendActionType,
  BackendQueryActionOptions,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type RetrieveAssetsAction = BackendActionBase<
  BackendActionType.RETRIEVE_ASSETS,
  RetrieveAssetsInput,
  RetrieveAssetsOutput['body'],
  BackendQueryActionOptions
>;

export type RetrieveAssetsActionQueryResult = BackendActionQueryResultBase<BackendActionType.RETRIEVE_ASSETS, RetrieveAssetsOutput['body']>;

interface RetrieveAssetsQueryFnProps {
  id: Request['id'];
  action: RetrieveAssetsAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const retrieveAssetsQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: RetrieveAssetsQueryFnProps): Promise<RetrieveAssetsActionQueryResult> => {
  const type = BackendActionType.RETRIEVE_ASSETS;
  const response = await backendClient.retrieveAssets(action.payload);
  const request = getRequest({ id, requestsLookup });
  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }
  return { type, response: response.body, requestStatus: RequestStatus.SUCCESS };
};

interface HandleRetrieveAssetsActionProps {
  action: RetrieveAssetsAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}

export const handleRetrieveAssetsAction = ({ action, backendClient, requestsLookup, setQueries }: HandleRetrieveAssetsActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.RETRIEVE_ASSETS,
    getQueryResult: ({ id }) => {
      return retrieveAssetsQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
