import { BatchResult } from '@amzn/genaihub-typescript-client';
import { handleRetrieveResultByWorkflowIdAndBatchIdDispatch } from 'src/v2/contexts/assetGeneration/utils/common/BackendDispatchContext.utils';
import { RetrieveResultByWorkflowIdAndBatchIdAction } from 'src/v2/contexts/backend/actions/RetrieveResultByWorkflowIdAndBatchId';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';

export async function retrieveWorkflowResultMiddleware<TResult>({
  backendDispatchContext,
  payload,
  onSuccessHandler,
}: {
  backendDispatchContext: BackendDispatchContextType;
  // Force a decision on including the entityId so it doesn't get missed
  payload: RetrieveResultByWorkflowIdAndBatchIdAction['payload'] & { entityId: string | undefined };
  onSuccessHandler: (props: { response: BatchResult }) => TResult | Error;
}) {
  try {
    return await handleRetrieveResultByWorkflowIdAndBatchIdDispatch<TResult>({
      backendDispatchContext,
      payload,
      onSuccessHandler,
    });
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }
    console.error(err);
    throw new Error('Asset generation failed due to error while retrieving workflow result.');
  }
}
