import { isWeblabInTreatment } from 'src/util/weblab/weblab';

export enum WeblabTreatment {
  C = 'C',
  T1 = 'T1',
  T2 = 'T2',
}

export interface Weblab {
  name: string;
  treatment: WeblabTreatment;
}

export const AICS_WEBLAB_FEEDS_DEACTIVATION = 'AICS_1075347';
export const isWeblabFeedsDeactivationInTreatment = () => isWeblabInTreatment(AICS_WEBLAB_FEEDS_DEACTIVATION, WeblabTreatment.T1);

export const AICS_WEBLAB_SELECTOR_FOR_SPONSOR_BRAND = 'AICS_1109632';
export const AICS_WEBLAB_STUDIO_INPUT_REFRESH = 'AICS_1172910';
export const AICS_WEBLAB_STUDIO_REFERENCE_IMAGES = 'AICS_1185405';

// Feature: Dark mode in UX 2.0
export const AICS_WEBLAB_DARK_MODE = 'AICS_1162818';

// Feature: multiple product in one image
export const AICS_WEBLAB_MULTIPLE_PRODUCT = 'AICS_1151185';

// Feature: State Management
const AICS_WEBLAB_STATE_MANAGEMENT = 'AICS_1181420';
export const isWeblabStateManagementInTreatment = () => isWeblabInTreatment(AICS_WEBLAB_STATE_MANAGEMENT, WeblabTreatment.T1);

export const CREATIVE_AGENT_ACCESS_CONTROL_LIST: Weblab[] = [{ name: 'RCS_MONS_AUTH_1072881', treatment: WeblabTreatment.T1 }];
