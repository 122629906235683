import { TabbedNavigation, TabbedNavigationItem } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { EffectSettings } from 'src/components/pages/studio/effectSettings/effectSettings';
import { Metrics } from 'src/constants';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { SelectionList } from 'src/v2/components/SelectionList/SelectionList';
import { ThumbnailSize } from 'src/v2/components/Thumbnail/types';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { getEffectSelections, setEffectSelection } from 'src/v2/redux/slices/userInput/userInputSlice';
import { EffectOption, EffectType } from 'src/v2/types';
import styled from 'styled-components';
import { WidgetBodyContent, WidgetHeaderContent } from '../../WidgetContainer.styles';
import { EffectsWidgetProps, WidgetViewHeaderLabels } from '../types';
import { WidgetHeader } from '../WidgetHeader';

type CategoriesType = {
  type: EffectType;
  label: string;
  effects: EffectOption[];
};

type SelectionItem = {
  title: string;
  subtitle: string;
  thumbnail: IconDefinition | string | undefined;
};

type EffectStateItem = { type: EffectType; value?: EffectOption };
type EffectStateItems = EffectStateItem[];

type SelectionListType = SelectionItem[];

const categories: CategoriesType[] = [
  {
    type: EffectType.LIGHTING,
    label: 'Lighting',
    effects: EffectSettings.Lighting.options,
  },
  {
    type: EffectType.CAMERA,
    label: 'Camera angle',
    effects: EffectSettings.Camera.options,
  },
  {
    type: EffectType.COLOR,
    label: 'Tone and color',
    effects: EffectSettings.Color.options,
  },
];

const TabbedNavWrapper = styled.div`
  margin-bottom: 10px;

  & li::after,
  li:hover::after {
    background: var(--interactive-primary, 'unset');
  }

  & button,
  button:hover {
    color: var(--text-primary, 'unset');
  }
`;

// get sorted list view for render
const getTransformedEffectsList = (list: EffectOption[]) => {
  let transformedList: SelectionListType = [];

  list.forEach((effectsType) => {
    if (effectsType.label.includes('None')) {
      // skip 'none' if present in data. Default prepended just below instead (afterwards)
      return;
    }
    transformedList.push({
      title: effectsType.label,
      subtitle: '', // intentionally blank for list view
      thumbnail: effectsType.icon || undefined,
    });
  });

  const defaultItem = {
    title: 'AI Auto (Default)',
    subtitle: '',
    thumbnail: iconTypes.sparkle,
  };

  const sortedList = transformedList.sort((a, b) => a.title.localeCompare(b.title));
  sortedList.unshift(defaultItem);
  return sortedList;
};

export const EffectsWidget = ({ closeWidget, dataTestId, height }: EffectsWidgetProps) => {
  const dispatch = useAppDispatch();
  const savedEffects = useAppSelector(getEffectSelections);

  const [selectedCategory, setSelectedCategory] = useState<CategoriesType>(categories[0]);
  const [selectedItem, setSelectedItem] = useState<SelectionItem>();
  const [selectedItems, setSelectedItems] = useState<EffectStateItems>();
  const [list, setList] = useState<SelectionListType | undefined>(getTransformedEffectsList(categories[0].effects));
  const [selectedIndex, setSelectedIndex] = useState<number[]>([0]);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const widgetHeaderRef = useRef<HTMLDivElement>(null);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (savedEffects && list) {
      const savedItemIndex = list.findIndex((item) => item.title === savedEffects[selectedCategory.type]?.label);
      if (savedItemIndex >= 0) {
        setSelectedIndex([savedItemIndex]);
      } else {
        setSelectedIndex([0]);
      }
    }
  }, [savedEffects, list]);

  useEffect(() => {
    if (height && widgetHeaderRef.current) {
      setContentHeight(height - widgetHeaderRef?.current?.clientHeight);
    }
  }, [height]);

  const categoryClickHandler = (category: CategoriesType) => {
    setSelectedCategory(category);
    setList(getTransformedEffectsList(category.effects));
  };

  const handleItemSelection = (item: SelectionItem) => {
    if (list && item) {
      const index = list.findIndex((listItem) => listItem.title === item.title);
      setSelectedIndex([index]);
      setSelectedItem(item);
      const selectedCategoryType = selectedCategory?.type;
      const selectionData = categories
        .find((category) => category.type === selectedCategoryType)
        ?.effects.find((effect) => effect.label === item.title);
      setSelectedItems([
        ...(selectedItems?.filter((item) => item.type !== selectedCategoryType) || []),
        { type: selectedCategoryType, value: selectionData },
      ]);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryType = selectedCategory?.type;
      const existingSelection = selectedItems?.find((item) => item.type === selectedCategoryType)?.value;
      if (list && existingSelection) {
        const index = list.findIndex((item) => item.title === existingSelection.label);
        if (index >= 0) {
          setSelectedIndex([index]);
        }
      }
    }
  }, [selectedCategory]);

  return (
    <div data-testid={dataTestId}>
      <WidgetHeaderContent ref={widgetHeaderRef}>
        <WidgetHeader
          dataTestId={dataTestId}
          label={WidgetViewHeaderLabels.Effects}
          icon={iconTypes.effects}
          closeWidget={(arg) => {
            if (list && selectedItem && !arg) {
              // save selected item to redux store
              selectedItems?.forEach((item) => {
                dispatch(setEffectSelection(item));

                // handle metrics tracking
                const savedEffectLabel = savedEffects[item.type]?.label;
                if (savedEffects === undefined || (item && savedEffects && savedEffectLabel !== selectedItem?.title)) {
                  const metricCounter = selectedItem.title.includes('Default')
                    ? Metrics.Counters.RemoveEffect
                    : `${Metrics.Counters.AddEffect}-${item.value?.label.replace(/ /gi, '-').replace(/'/gi, '')}`;
                  appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [metricCounter]: 1 });
                }
              });
            }
            closeWidget();
          }}
        />
        <TabbedNavWrapper>
          <TabbedNavigation>
            {categories.map((category) => (
              <TabbedNavigationItem
                key={category.label}
                data-testid={`effects-tab-${category.label.toLowerCase().replace(/ /gi, '-')}`}
                active={selectedCategory === category}
                label={category.label}
                onClick={() => categoryClickHandler(category)}
              />
            ))}
          </TabbedNavigation>
        </TabbedNavWrapper>
      </WidgetHeaderContent>
      <WidgetBodyContent height={contentHeight}>
        {list && (
          <SelectionList
            dataTestId="selection-list-effects"
            doubleColumns
            list={list}
            type="radio"
            onClick={(item) => handleItemSelection(item as SelectionItem)}
            thumbnailOptions={{ size: ThumbnailSize.Medium }}
            selectedIndex={selectedIndex}
          />
        )}
      </WidgetBodyContent>
    </div>
  );
};
