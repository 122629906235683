import { GenerateAssetsAction } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { FrontendAsset } from 'src/v2/types';

export enum AssetGenerationStatus {
  ABORTED = 'aborted',
  COMPLETE = 'complete',
  PENDING = 'pending',
}

export interface AssetGenerationBase<TStatus extends AssetGenerationStatus> {
  id: string;
  action: Pick<GenerateAssetsAction, 'generationConfigs' | 'options' | 'type'>;
  status: TStatus;
  progress?: number;
}

export type AssetGeneration =
  | AssetGenerationBase<AssetGenerationStatus.ABORTED>
  | AssetGenerationBase<AssetGenerationStatus.PENDING>
  // errors must be string since Error is non-serializable
  | (AssetGenerationBase<AssetGenerationStatus.COMPLETE> & { generatedAssets: FrontendAsset[]; errors: string[] });
