import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { AnimatedButton } from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import ImageEditingCanvas from 'src/components/imageModal/components/inputs/ImageEditingCanvas';
import RadioGroup from 'src/components/imageModal/components/inputs/RadioGroup';
import RangeSlider from 'src/components/imageModal/components/inputs/RangeSlider';
import TileSelector from 'src/components/imageModal/components/inputs/TileSelector';
import {
  LIVE_IMAGE_CAMERA_PATH_CONFIG,
  LIVE_IMAGE_DURATION_CONFIG,
  LIVE_IMAGE_EDITOR_CONFIG,
  LIVE_IMAGE_MOTION_CONFIG,
} from 'src/components/imageModal/components/LiveImageControls/LiveImageConfigs';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { LiveImageAction } from 'src/components/imageModal/types';
import { WorkflowSubmission } from 'src/components/utils/WorkflowSubmission';
import { useWorkflow } from 'src/hooks/useWorkflow';
import { CategoryEnum, WorkflowIdEnum } from 'src/v2/types';
import styles from './LiveImageControls.module.scss';

export const LiveImageControls = () => {
  const [baseImage, setBaseImage] = useState<string>();
  const [imageMask, setImageMask] = useState<File>();
  const [duration, setDuration] = useState<number>(LIVE_IMAGE_DURATION_CONFIG.defaultValue!);
  const [motionStrength, setMotionStrength] = useState<number>(LIVE_IMAGE_MOTION_CONFIG.defaultValue!);
  const [cameraPath, setCameraPath] = useState<string>(LIVE_IMAGE_CAMERA_PATH_CONFIG.defaultValue!);

  const [workflowState, setWorkflowState] = useState<string>(WorkflowSubmission.IDLE);
  const { activePage, userDetails } = useContext(AppContext);
  const { clearActiveEdit, setActiveEditResults, setActiveEditErrorMessage, setPendingGeneration } = useContext(ImageModalContext);

  const {
    submissionQuery: { data: result },
    updateWorkflowOptions,
    submitWorkflow,
    isPending,
    isError,
    error,
  } = useWorkflow<LiveImageAction>({
    workflowId: WorkflowIdEnum.PARALLAX_MOTION,
    userAlias: userDetails?.alias,
    pageName: activePage,
    defaultWorkflowOptions: {
      image_count: 4,
      theme: 'no_theme',
      image_ref: '',
      mask_ref: '',
      camera_path: cameraPath,
      motion_intensity: motionStrength,
      out_duration: duration,
    },
    setPendingGeneration,
  });

  // prepare for workflow submission
  useEffect(() => {
    updateWorkflowOptions({
      camera_path: cameraPath,
      motion_intensity: motionStrength,
      out_duration: duration,
    });
  }, [duration, motionStrength, cameraPath]);
  const handleGenerate = () => {
    if (canSubmitWorkflow) {
      clearActiveEdit();
      submitWorkflow({
        ...(imageMask && {
          mask_ref: {
            urlOrFile: imageMask,
            contentCategory: CategoryEnum.MASK_IMAGE,
          },
        }),
        image_ref: {
          urlOrFile: baseImage!,
          contentCategory: CategoryEnum.REFERENCE_IMAGE,
          fileTypeOverride: 'image/png',
        },
      });
    }
  };

  useEffect(() => {
    if (isError) {
      setWorkflowState(WorkflowSubmission.FAILED);
    } else if (isPending) {
      setWorkflowState(WorkflowSubmission.RUNNING);
    } else {
      setWorkflowState(WorkflowSubmission.IDLE);
    }
  }, [isError, isPending]);

  useEffect(() => {
    if (result) {
      setActiveEditResults(result);
    } else {
      setActiveEditResults(undefined);
    }
  }, [result]);

  useEffect(() => {
    if (isError) {
      setActiveEditErrorMessage(error?.message);
      console.error('Error while generating results', error);
    }
  }, [isError]);

  const canSubmitWorkflow = !isPending;

  return (
    <div className={styles.liveImageControlWrapper}>
      <ControlLabel
        title={LIVE_IMAGE_EDITOR_CONFIG.controlLabel}
        subTitle={LIVE_IMAGE_EDITOR_CONFIG.description}
        titleSize={LIVE_IMAGE_EDITOR_CONFIG.titleSize}
      />
      <div className={styles.scrollContainer}>
        <div className={styles.scrollInputs}>
          <ImageEditingCanvas setImageMask={setImageMask} setBaseImage={setBaseImage} />
          <RadioGroup {...LIVE_IMAGE_DURATION_CONFIG} onChange={setDuration} disabled={isPending} />
          <RangeSlider {...LIVE_IMAGE_MOTION_CONFIG} onChange={setMotionStrength} disabled={isPending} />
          <TileSelector {...LIVE_IMAGE_CAMERA_PATH_CONFIG} onChange={setCameraPath} disabled={isPending} />
        </div>
      </div>
      <div className={styles.generateButtonWrapper}>
        <AnimatedButton
          dataTestId="studio-edit-generate-button"
          className={styles.generateButton}
          animate={workflowState === WorkflowSubmission.RUNNING}
          disabled={!canSubmitWorkflow}
          clickHandler={handleGenerate}
          text={workflowState === WorkflowSubmission.RUNNING ? 'Generating' : 'Generate'}
          fullscreen={false}
        ></AnimatedButton>
      </div>
    </div>
  );
};
