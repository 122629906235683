import React, { MouseEventHandler, ReactElement } from 'react';
import { Icon } from 'src/v2/components/Icon/Icon';
import styles from 'src/v2/components/Thumbnail/Thumbnail.module.scss';
import { ThumbnailProps, IconPositions, ThumbnailType, THUMBNAIL_SIZES, ThumbnailSize } from 'src/v2/components/Thumbnail/types';
import styled from 'styled-components';

export const ThumbnailContainer = styled.div``;

export const ThumbnailIcon = styled(Icon)``;

export const Thumbnail = ({
  thumbnailType = ThumbnailType.Basic,
  src,
  alt,
  count,
  onClick,
  isSelected = false,
  onSelectedStateChange,
  thumbnailOptions = { size: ThumbnailSize.Small },
  overlayIconOptions = { iconPosition: IconPositions.TopRight },
}: ThumbnailProps): ReactElement => {
  const thumbnailTypeClass = styles[thumbnailType.toLowerCase()];
  const selectedClass = isSelected ? styles.selected : '';
  const iconPos = overlayIconOptions.iconPosition ? styles[overlayIconOptions.iconPosition] : '';

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelectedStateChange?.(event.target.checked);
  };

  const renderThumbnailIcon = () => {
    switch (thumbnailType) {
      case ThumbnailType.Blank:
        return null;
      case ThumbnailType.Edit:
      case ThumbnailType.Remove:
      case ThumbnailType.Play:
        return overlayIconOptions.iconType ? (
          <ThumbnailIcon type={overlayIconOptions.iconType} showBackground={true} size={overlayIconOptions.iconSize || '18px'} aria-hidden="true" />
        ) : null;
      case ThumbnailType.Select:
        return <input className={styles.radioButton} type="radio" aria-label={`Select ${alt}`} checked={isSelected} onChange={handleSelect} />;
      case ThumbnailType.Count:
        return (
          count !== undefined && (
            <span className={styles.countBadge} aria-label={`Contains ${count} items`} role={'status'}>
              {count}
            </span>
          )
        );
      default:
        return null;
    }
  };

  const getDimensions = () => {
    if (thumbnailOptions.width && thumbnailOptions.height) {
      return {
        width: thumbnailOptions.width,
        height: thumbnailOptions.height,
      };
    }

    return THUMBNAIL_SIZES[thumbnailOptions.size || ThumbnailSize.Small];
  };

  const dimensions = getDimensions();
  return (
    <ThumbnailContainer
      className={[styles.thumbnailContainer, thumbnailTypeClass, selectedClass].join(' ')}
      onClick={handleClick}
      data-testid={'thumbnail-container'}
      role={onClick ? 'button' : undefined}
      style={dimensions}
      tabIndex={0}
    >
      <div
        data-testid={'thumbnail-overlay'}
        className={[styles.thumbnailOverlay, iconPos].join(' ')}
        data-position={overlayIconOptions?.iconPosition}
      >
        {renderThumbnailIcon()}
      </div>
      {thumbnailType !== ThumbnailType.Blank && <img className={styles.thumbnailImage} data-testid={'thumbnail-image'} src={src} alt={alt} />}
    </ThumbnailContainer>
  );
};
