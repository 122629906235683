import { Flex, Heading, RadioButton, Text } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { MouseEvent } from 'react';
import { Button } from 'src/v2/components/Button/Button';
import { Icon, IconWrapper } from 'src/v2/components/Icon/Icon';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { Thumbnail, ThumbnailContainer } from 'src/v2/components/Thumbnail/Thumbnail';
import { THUMBNAIL_SIZES, ThumbnailOptions, ThumbnailProps, ThumbnailType } from 'src/v2/components/Thumbnail/types';
import styled from 'styled-components';

export type SelectionItemProps = {
  title?: string;
  subtitle: string;
  thumbnail?: string | IconDefinition;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  type: 'radio' | 'multiple';
  selected?: boolean;
  thumbnailType?: ThumbnailType;
  thumbnailOptions?: ThumbnailOptions;
  thumbnailIconOptions?: ThumbnailProps['overlayIconOptions'];
  onClickThumbnail?: (e: MouseEvent) => void;
  showEmptyThumbnails?: boolean;
  selectionId?: number;
  disabled?: boolean;
};

const MainContent = styled(Flex)`
  width: 100%;
  max-height: 75px;
  height: auto;

  word-wrap: break-word; /* Allows long words to break and wrap */
  > img {
    max-height: 75px;
  }
`;

const Title = styled(Heading)`
  overflow: hidden;
  color: var(--text-ui-primary, #161d26);
  text-overflow: ellipsis;

  /* Desktop/Subhead 3 */
  font-family: 'Amazon Ember';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word; /* Allows long words to break and wrap */
  max-height: 260%;
`;

const SubTitle = styled(Text)`
  color: var(--text-secondary, #454a4f);
  /* Text/Mini */
  font-family: 'Amazon Ember';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 145.455% */
`;

const ProductThumbnail = styled.div`
  ${ThumbnailContainer} {
    background-color: #f5f3ef;
    border-radius: 8px;
    cursor: pointer;
  }
  ${IconWrapper} i {
    background-color: #161d26b2 !important;

    & path {
      fill: white;
    }
  }
`;

const StyledRadioInputWrapper = styled.div`
  && input {
    outline: solid 1px black;
  }
`;

export const getSelectionItemThumbnailSizeToNumber = (size: string | undefined) => {
  switch (size) {
    case 'small':
      return {
        width: '44px',
        height: '44px',
      };
    case 'medium':
      return {
        width: '60px',
        height: '60px',
      };
    case 'large':
      return {
        width: '75px',
        height: '75px',
      };
    case 'xlarge':
      return {
        width: '85px',
        height: '85px',
      };
    default:
      return {
        width: '44px',
        height: '44px',
      };
  }
};

export const SelectionItem = ({
  title,
  subtitle,
  thumbnail,
  onClick,
  type = 'radio',
  selected = false,
  thumbnailType,
  onClickThumbnail,
  thumbnailOptions,
  thumbnailIconOptions,
  showEmptyThumbnails,
  selectionId,
  disabled = false,
}: SelectionItemProps) => {
  const isIconDefinition = typeof thumbnail !== 'undefined' && typeof thumbnail !== 'string';
  return (
    <MainContent
      grow={1}
      columnGap="base"
      alignItems="center"
      justifyContent="space-between"
      role={'listitem'}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        type === 'radio' && onClick?.(e);
      }}
      data-testId={'selection-list-item'}
    >
      {thumbnail && isIconDefinition && (
        <Icon
          type={thumbnail}
          showBackground
          borderRadius={8}
          backgroundSize={getSelectionItemThumbnailSizeToNumber(thumbnailOptions?.size)}
          color={thumbnail === iconTypes.sparkle ? '#ffffff' : undefined}
          backgroundColor={thumbnail === iconTypes.sparkle ? '#e123c2' : undefined}
        />
      )}
      {!isIconDefinition && (thumbnail || showEmptyThumbnails) && (
        <ProductThumbnail>
          <Thumbnail
            src={thumbnail}
            alt={`Thumbnail pic for ${title}`}
            thumbnailOptions={thumbnailOptions || THUMBNAIL_SIZES['large']}
            overlayIconOptions={thumbnailIconOptions}
            thumbnailType={showEmptyThumbnails || typeof thumbnail === 'undefined' ? ThumbnailType.Blank : thumbnailType}
            onClick={onClickThumbnail}
          />
        </ProductThumbnail>
      )}
      <Flex grow={1} direction="column" rowGap="xxxsmall">
        {title && <Title>{title}</Title>}
        <SubTitle>{subtitle}</SubTitle>
      </Flex>

      <Flex alignItems="center">
        {type === 'multiple' && (
          <Button
            dataTestId={`selection-list-item-${selectionId}`}
            aria-checked={selected}
            icon={selected ? iconTypes.close : iconTypes.plus}
            onClick={(e) => onClick?.(e)}
            aria-role="checkbox"
            iconOnlyRounded
            iconOnlyNoBackground
            iconOnly
            iconOnlyColor="var(--text-primary, #000000)"
            disabled={disabled}
          />
        )}

        {type === 'radio' && (
          <StyledRadioInputWrapper role="radio">
            <RadioButton
              name={title}
              isChecked={selected}
              aria-checked={selected}
              disabled={disabled}
              data-testid={`selection-list-item-${selectionId}`}
            />
          </StyledRadioInputWrapper>
        )}
      </Flex>
    </MainContent>
  );
};
