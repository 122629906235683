import { ControlValue } from '@amzn/genaihub-typescript-client';
import { Button } from '@amzn/storm-ui';
import { useState, useContext, useEffect } from 'react';
import { EditorContextP1 as EditorContext } from 'src/components/editor/EditorContext';
import { ControlComponentProps } from 'src/components/editor/UiContols/uiGeneratorControls/controlComponentTypes';
import Stack from 'src/customUIComponents/Stack';
import ControlLabel from './UI/ControlLabel';

const BrandStyleRadioGroup = ({ control }: ControlComponentProps) => {
  const editorContext = useContext(EditorContext);
  const controlValues = editorContext.workflowOptions;
  const linkingString = ' - Adhere to the following style guidelines: ';
  const brandGuidelines: Record<string, string> = {
    ESPN: 'dynamic, energetic, data-driven, sports photography, color scheme includes red accents with hex code DE0000 and black navigation with hex code 2A2C2D, visuals are clean with content separated with beveled corners, pill-shaped buttons in a shade of blue with hex code 4670D8 and white text, fonts used include Roboto and Helvetica Neu',
    Apple: 'revolutionary, premium, minimalist, empowering, individualistic, forward-thinking, monochromatic, grayscale, sleek aesthetics',
    LEGO: 'fun, imaginative, creative, playful, innovative, inclusive, bold, primary colors, playful imagery',
    Nike: 'ambitious, inspiring, athletic, motivating, aspirational, perseverant, sleek, modern design with heavy emphasis on athletics',
    CocaCola: 'consistent, positive, universal, happy, sharing, universally connecting, red and white color scheme, classic bottle design',
    Starbucks:
      'comforting, consistent, warm, welcoming, community-oriented, warm color palette, consistent cafe design, green and white color scheme',
    McDonalds: 'simple, fun, consistent, cheerful, approachable, universally enjoyable, vibrant red and yellow color scheme, iconic golden arches',
    IKEA: 'affordable, functional, for everyone, friendly and simple, welcoming and relatable, Swedish, blue and yellow color scheme, visuals are clean and minimalistic',
  };

  useEffect(() => {
    setSelected(control.defaultValue ?? '');
    const cv = control.controlValues?.reduce<{ value?: string; index?: number }>((acc, cv: ControlValue, index: number) => {
      if (cv.value?.toLowerCase() === (control.defaultValue ?? '').toLowerCase()) {
        acc = { value: cv.value, index };
      }
      return acc;
    }, {});
    const { value, index } = cv ?? {};
    if (value !== undefined) {
      const controlName = control.controlName;
      const controlData = { [controlName]: { value, index } };
      editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
    }
  }, [editorContext.disableControl]);

  useEffect(() => {
    if (!editorContext.workflowOptions.text_prompt?.value.includes(linkingString)) {
      setSelected('');
    }
  }, [editorContext.workflowOptions.text_prompt]);
  const [selected, setSelected] = useState<string>('');

  const handleClick = (value?: string, index?: number, label?: string) => {
    const prompt = editorContext.workflowOptions.text_prompt;
    if (prompt === undefined || value === undefined || label === undefined || index === undefined) {
      return;
    }

    const promptValue = prompt.value.split(linkingString)[0];
    const modifiedPromptValue = promptValue + linkingString + brandGuidelines[label];

    const controlName = control.controlName;
    const controlData = { [controlName]: { value: 'no_theme', index }, text_prompt: { value: modifiedPromptValue, type: 'text' } };
    setSelected(value);
    editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
  };

  const displayButton = control?.controlValues?.map((controlValue: ControlValue, index: number) => {
    return (
      <Button
        onClick={() => handleClick(controlValue.value, index, controlValue.label)}
        key={'button' + index}
        style={{
          border: 1,
          borderColor: !editorContext.disableControl && controlValue.value?.toLowerCase() === selected.toLowerCase() ? '#6236FF' : '#f1f3f5',
          borderStyle: 'solid',
          backgroundColor: 'white',
        }}
        disabled={editorContext.disableControl}
      >
        {controlValue.label}
      </Button>
    );
  });

  return (
    <div>
      <ControlLabel title={control.controlLabel} />
      <Stack direction="row" wrap={(control?.controlValues?.length ?? 0 <= 3) ? 'nowrap' : 'wrap'} gap="5px">
        {displayButton}
      </Stack>
    </div>
  );
};

export default BrandStyleRadioGroup;
