var endpointMap = {
  en: {
    'pages.alerts': 'translation-en.json',
  },
  'en-US': {
    'pages.alerts': 'translation-en.json',
  },
  und: {
    'pages.alerts': 'translation.json',
  },
};
var arbManifest = {
  endpointMap: endpointMap,
};

export { arbManifest as default, endpointMap };
