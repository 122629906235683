import { Button } from '@amzn/storm-ui';
import { useContext } from 'react';
import { AppContext } from 'src/AppContext';
import ChatListIcon from 'src/components/assistant/assets/chat-list.svg';
import { CHAT_THEME } from 'src/components/assistant/theme';
import getPersonaImageSource from 'src/components/assistant/utils/getPersonaImageSource';
import useConversation from 'src/components/assistant/utils/useConversation';
import { ConversationContext } from 'src/components/pages/conversational/ChatPage';
import styled from 'styled-components';
import useConversationId from '../../utils/useConversationId';

const Controls = styled.div`
  display: flex;
  gap: ${CHAT_THEME.spacing.miniPlus};
`;
const Header = styled.div<{ navHeaderHeight: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 44px);
  background: transparent;
  top: 14px;
  left: 22px;
  position: absolute;
`;
const PhotosWrapper = styled.div`
  display: flex;
  gap: 6px;
  pointer-events: auto;
  cursor: pointer;
`;

const PreviewImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
`;

const OpenNavButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  border-radius: 999px;

  &&,
  &&:visited,
  &&:active,
  &&:link,
  &&:hover {
    background-color: ${CHAT_THEME.color.aiPurple};
    color: #ffffff;
  }
`;

const NewChatButton = styled(Button)`
  pointer-events: auto;
  display: none;
  &&,
  &&:visited,
  &&:active,
  &&:link,
  &&:hover {
    background-color: #f2f4f6;
    color: ${CHAT_THEME.color.aiPurple};
  }
`;

export default function ChatHeader() {
  const conversation = useConversation();
  const { isSideNavOpen, setIsSideNavOpen } = useContext(ConversationContext);
  const { headerDimensions } = useContext(AppContext);
  const { setConversationId } = useConversationId();

  return (
    <Header navHeaderHeight={headerDimensions.navHeaderHeight}>
      <Controls>
        {!isSideNavOpen && (
          <>
            <OpenNavButton data-testid="open-nav-button" onClick={() => setIsSideNavOpen(true)}>
              <ChatListIcon />
            </OpenNavButton>
            <NewChatButton data-testid="header-new-chat" onClick={() => setConversationId()}>
              New Chat
            </NewChatButton>
          </>
        )}
      </Controls>
      <PhotosWrapper>
        {conversation?.personas?.map((persona) => <PreviewImage key={persona} src={getPersonaImageSource(persona).image} alt={persona} />)}
        <PreviewImage src={getPersonaImageSource().image} alt="preview" />
      </PhotosWrapper>
    </Header>
  );
}
