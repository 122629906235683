import { BoundingBox } from '@amzn/genaihub-typescript-client';
import type Konva from 'konva';
import { FC, useContext, useEffect, useRef } from 'react';
import { Image as KonvaImage, Layer, Stage, Transformer } from 'react-konva';
import { adjustBoundingBox, boundProductImage, getSizeWithAspectRatio } from 'src/components/imageModal/components/utils';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { AspectRatio } from 'src/v2/types';
import useImage from 'use-image';

export const ProductPositionCanvas: FC<{
  backgroundImageURL: string;
  productImageURL: string;
  boundingBox: BoundingBox;
  canvasWidth: number;
  ready: boolean;
  onReposition: (newPosition: BoundingBox) => void;
}> = ({ onReposition, ready, canvasWidth, backgroundImageURL, boundingBox, productImageURL }) => {
  const [backgroundImage] = useImage(backgroundImageURL);
  const [productImage] = useImage(productImageURL);
  const imageRef = useRef<Konva.Image>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const imageModalContext = useContext(ImageModalContext);
  const aspectRatio = (imageModalContext.savedEditsImageAspectRatio || imageModalContext.aspectRatio || AspectRatio.SQUARE_1_TO_1) as AspectRatio;
  const canvasHeight = getSizeWithAspectRatio({
    width: canvasWidth,
    aspectRatio,
  }).height;

  const multiplier = canvasWidth / (backgroundImage?.naturalWidth || canvasWidth);
  const adjustedBoundingbox = adjustBoundingBox(boundingBox, multiplier);

  useEffect(() => {
    if (imageRef.current && transformerRef.current) {
      transformerRef.current.nodes([imageRef.current]);
    }
  }, [imageRef.current, transformerRef]);

  const getAttributesFromDragEvent = (currentTarget: Konva.Node) => {
    const {
      attrs: { x, y },
    } = currentTarget;
    return onReposition(
      adjustBoundingBox(
        {
          width: adjustedBoundingbox.width,
          height: adjustedBoundingbox.height,
          left: x,
          top: y,
          rotateAngle: Math.round(imageRef?.current?.rotation() || 0),
        },
        1 / multiplier,
      ),
    );
  };

  const getAttributesFromTransformEvent = (currentTarget: Konva.Node) => {
    const {
      attrs: { x, y, width, height, scaleX, scaleY },
    } = currentTarget;

    return onReposition(
      adjustBoundingBox(
        {
          width: width * scaleX,
          height: height * scaleY,
          left: x,
          top: y,
          rotateAngle: Math.round(imageRef?.current?.rotation() || 0),
        },
        1 / multiplier,
      ),
    );
  };

  useEffect(() => {
    imageRef.current?.scaleX(1);
    imageRef.current?.scaleY(1);
    imageRef.current?.rotation(Number(boundingBox.rotateAngle || 0));
  }, [boundingBox]);

  useEffect(() => {
    if (stageRef.current) {
      (window as unknown as { __konvaStage: Konva.Stage }).__konvaStage = stageRef.current;
    }
  }, []);

  const stageRef = useRef<Konva.Stage>(null);
  return (
    <Stage ref={stageRef} width={canvasWidth} height={canvasHeight} x={0} y={0}>
      <Layer>
        {backgroundImage && <KonvaImage width={canvasWidth} height={canvasHeight} image={backgroundImage} cornerRadius={10} />}
        {ready && (
          <>
            <KonvaImage
              draggable
              x={adjustedBoundingbox.left}
              y={adjustedBoundingbox.top}
              width={adjustedBoundingbox.width}
              height={adjustedBoundingbox.height}
              rotation={Number(adjustedBoundingbox.rotateAngle)}
              image={productImage}
              ref={imageRef}
              onDragEnd={(e) => {
                getAttributesFromDragEvent(e.currentTarget);
              }}
              onTransformEnd={(e) => {
                getAttributesFromTransformEvent(e.currentTarget);
              }}
              dragBoundFunc={(vec: Konva.Vector2d) => boundProductImage(vec, stageRef.current, imageRef.current)}
              id="product-image"
            />
            <Transformer
              keepRatio
              enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
              ref={transformerRef}
              rotateEnabled
              rotateAnchorCursor="auto"
              rotation={Number(adjustedBoundingbox.rotateAngle)}
              flipEnabled={false}
              anchorStroke="#30C1FF"
              borderStroke="#30C1FF"
              boundBoxFunc={(oldBox, newBox) => {
                if (!stageRef.current) return oldBox;
                const { x, y, width, height } = newBox;
                const isOut = x < 0 || y < 0 || x + width > stageRef.current.width() || y + height > stageRef.current.height();
                if (isOut) {
                  return oldBox;
                }
                return newBox;
              }}
              anchorStyleFunc={(anchor) => {
                anchor.cornerRadius(1);
                if (anchor.hasName('rotater')) {
                  anchor.cornerRadius(anchor.width() / 2);
                }
              }}
              onTransformEnd={(e) => {
                getAttributesFromTransformEvent(e.currentTarget);
              }}
            />
          </>
        )}
      </Layer>
    </Stage>
  );
};
