import { retrieveWorkflowResultMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/RetrieveWorkflowResult.middleware';
import { submitWorkflowMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/SubmitWorkflow.middleware';
import { LiveImageVideoGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { buildAssetsFromBatchResult } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { ParallaxMotionWorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { AssetTypeEnum, LiveImageVideoAsset } from 'src/v2/types';

/**
 * A helper function for the primary handler, handleLiveImageVideoGeneration. This function handles the workflow submission and result.
 */
export async function handleLiveImageVideoGenerationSubmission({
  backendDispatchContext,
  generationConfig,
  inputImageReferenceId,
  maskImageReferenceId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: LiveImageVideoGenerationConfig;
  inputImageReferenceId: string;
  maskImageReferenceId: string | undefined;
}): Promise<LiveImageVideoAsset[]> {
  const { entityId, studioRequest, userInputs, workflowId } = generationConfig;
  const { cameraPath, inputImageAsset, motionIntensity, outDuration } = userInputs;

  // STEP - Submit workflow for PARALLAX_MOTION
  const workflowOptions: ParallaxMotionWorkflowOptions = {
    cameraPath,
    height: undefined,
    inputImageRef: inputImageReferenceId,
    maskRef: maskImageReferenceId,
    motionIntensity,
    outDuration,
    // Allow the backend to decide a default value
    outFps: undefined,
    width: undefined,
    workflowId,
  };
  const submitWorkflowPayload: StronglyTypedSubmitWorkflowByIdInput = {
    body: {
      workflowOptions,
    },
    studioRequest,
    workflowId,
  };
  const batchId = await submitWorkflowMiddleware({ backendDispatchContext, payload: submitWorkflowPayload });

  // STEP - Retrieve result for PARALLAX_MOTION
  return await retrieveWorkflowResultMiddleware<LiveImageVideoAsset[]>({
    backendDispatchContext,
    payload: {
      batchId,
      entityId,
      studioRequest,
      workflowId,
    },
    onSuccessHandler: ({ response }) => {
      return buildAssetsFromBatchResult<LiveImageVideoAsset, LiveImageVideoGenerationConfig['userInputs'], ParallaxMotionWorkflowOptions>({
        aspectRatio: inputImageAsset.aspectRatio,
        assetType: AssetTypeEnum.VIDEO,
        batchResult: response,
        userInputs,
        workflowOptions,
      });
    },
  });
}
