import { ASINMetadata } from '@amzn/genaihub-typescript-client';
import { FileUploadDropZone, Icon, Heading, Text } from '@amzn/storm-ui';
import { check } from '@amzn/storm-ui-icons';
import { ChangeEvent, MouseEvent, SyntheticEvent, forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { StudioContext } from 'src/components/pages/studio/StudioContext';
import {
  SubmitBlankAsinNotification,
  SubmitAsinFailureNotification,
  SubmitRestrictedAsinNotification,
} from 'src/components/snackbar/notifications/ImageGenerationNotifications';
import { formatBytes } from 'src/components/utils/formatBytes';
import { US_MARKETPLACE_ID } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import usePrevious from 'src/hooks/usePrevious';
import SpinnerIcon from 'src/icons/spinnerIcon';
import { logger } from 'src/logger';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { BLOCK_STUDIO_FILE_UPLOAD_DROPZONE_CLASSNAME } from 'src/v2/components/studio/fileUploadDropzone';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { getProducts, setProducts, upsertProduct } from 'src/v2/redux/slices/product/productSlice';
import { AsinCategoryEnum, ASINItem } from 'src/v2/types';
import { createAsinProduct } from 'src/v2/utils/Product.utils';
import style from './ProductSelector.module.scss';

interface ProductSelectorItemProps {
  product: ASINItem;
  showChangeImagePicker: boolean;
  closeChangeImagePicker: () => void;
  openChangeImagePicker: () => void;
  setProduct: (product: ASINItem) => void;
}

enum ActiveTab {
  AddAsin,
  UploadProductImage,
}

const asinErrorMessages = {
  RESTRICTED: 'Restricted ASIN',
  INVALID: 'Invalid ASIN',
};

const ProductSelectorItem = forwardRef<HTMLDivElement, ProductSelectorItemProps>(
  ({ product, showChangeImagePicker, closeChangeImagePicker, openChangeImagePicker, setProduct }, ref) => {
    if (!product || !product.metadata) return null;

    const studioContext = useContext(StudioContext);
    const [checked, setChecked] = useState<boolean>();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const toggleOnClickHandler = () => {
      studioContext.setAsin(product.asin == studioContext.asin?.asin ? undefined : product);
    };

    const changeImageClickHandler = (event: MouseEvent) => {
      event.stopPropagation();
      if (showChangeImagePicker) {
        closeChangeImagePicker();
      } else {
        openChangeImagePicker();
      }
    };

    const customProductImageOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const files = (event.target as HTMLInputElement).files;

      if (files && files.length > 0) {
        const imageUrl = URL.createObjectURL(files[0]);
        const newAsin = { ...product, customProductImageUrl: imageUrl, selectedImageIndex: -1 } as ASINItem;
        studioContext.setAsin(newAsin);
        setProduct(newAsin);
      }
    };

    const selectDifferentProductImageClickHandler = (imageIndex: number) => {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      const newAsin = { ...product, selectedImageIndex: imageIndex } as ASINItem;
      studioContext.setAsin(newAsin);
      setProduct(newAsin);
    };

    useEffect(() => {
      setChecked(studioContext.asin?.asin == product.asin);
    }, [studioContext.asin]);

    const selectedImageIndex = product.selectedImageIndex ?? 0;
    let customProductImageUrl = product.customProductImageUrl;

    if (studioContext.asin?.asin === product.asin) {
      customProductImageUrl = studioContext.asin?.customProductImageUrl;
    }

    const asinImage =
      selectedImageIndex != -1 &&
      product.metadata?.mediaCentralAssets &&
      product.metadata?.mediaCentralAssets.length > selectedImageIndex &&
      (product.metadata?.mediaCentralAssets[selectedImageIndex].highResUri || product.metadata?.mediaCentralAssets[selectedImageIndex].lowResUri);

    return (
      <div ref={ref} id={`product-${product.asin}`} className={style.productWrapper} data-testid="product-wrapper">
        <div onClick={toggleOnClickHandler} className={style.productContainer}>
          <div className={style.imageContainer}>
            <div
              className={style.productImage}
              style={{ backgroundImage: `url(${customProductImageUrl && selectedImageIndex == -1 ? customProductImageUrl : asinImage})` }}
            ></div>
            <div className={`${style.productCheckbox} ${checked ? style.checked : ''}`}>
              <Icon type={check} color={'white'} />
            </div>
          </div>
          <div className={style.productDetails}>
            <div className={style.productDescription}>{product.metadata?.title}</div>
            <div className={style.productAsin}>ASIN: {product.asin}</div>
            <div onClick={changeImageClickHandler} className={style.changeProductImage}>
              {showChangeImagePicker ? 'Close' : 'Change Image'}
            </div>
          </div>
        </div>
        {showChangeImagePicker && (
          <div className={style.selectImageContainer}>
            <Heading className={style.selectImageTitle} size="small" renderAs={'h6'}>
              CHANGE IMAGE
            </Heading>
            <div className={style.selectImageDescription}>Choose an image that shows your product against a white background.</div>
            <div className={style.selectImageImageList}>
              {(product.metadata?.mediaCentralAssets?.length || 0) > 0 &&
                product.metadata?.mediaCentralAssets?.map((item, index) => (
                  <div
                    key={`ProductSelector_MediaCentralAssetes_${index}`}
                    onClick={() => selectDifferentProductImageClickHandler(index)}
                    className={`${style.selectImageImageListItemContainer} ${selectedImageIndex == index ? style.imageSelected : ''}`}
                  >
                    <div className={style.selectImageImageListItem} style={{ backgroundImage: `url(${item.highResUri || item.lowResUri})` }}></div>
                  </div>
                ))}
            </div>
            <div className={style.selectImageCustomInput}>
              <label className={style.selectImageCustomLabelClickable} htmlFor={`file-upload-${product.asin}`}>
                Add another image
              </label>
              <input
                ref={fileInputRef}
                accept={'image/png,image/jpeg'}
                onChange={customProductImageOnChangeHandler}
                id={`file-upload-${product.asin}`}
                type="file"
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);

ProductSelectorItem.displayName = 'ProductSelectorItem';

const ProductSelector = (props: { closeProductSelector: () => void; isVisible?: boolean }) => {
  const genAIBackendClient = useAIBackendHubClient();
  const dispatch = useAppDispatch();
  const products = useAppSelector(getProducts);

  const studioContext = useContext(StudioContext);
  const [searchAsin, setSearchAsin] = useState<string>();
  const [asinLoading, setAsinLoading] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [asinLookupSet, setAsinLookupSet] = useState(new Set<string>());
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.AddAsin);
  const [asinOfItemWithActiveChangeItemPicker, setAsinOfItemWithActiveChangeItemPicker] = useState<string>();
  const productListRef = useRef<HTMLDivElement>(null);
  const productListItemsRef = useRef<{ [key: string]: HTMLDivElement }>({});
  const prevIsVisible = usePrevious(props.isVisible);
  const { addFailureNotification } = useNotificationActions();

  const searchBarOnChangeHandler = async (value: string) => {
    setSearchAsin(value.toUpperCase().trim());
  };

  const checkEnterKey = (key: string) => {
    if (key === 'Enter') {
      searchClickHandler();
    }
  };

  useEffect(() => {
    const asinList = products.map((item) => (Object.keys(item.metadata as ASINMetadata).length > 0 ? item.asin : '')).filter((item) => item != '');
    const newSet = new Set(asinList);
    setAsinLookupSet(newSet);
  }, [products]);

  const selectedAsin = studioContext.asin;
  const customImage = selectedAsin?.customImage;

  useEffect(() => {
    if (activeTab === ActiveTab.UploadProductImage) {
      setAsinOfItemWithActiveChangeItemPicker(undefined);
    }
  }, [activeTab]);

  useEffect(() => {
    // Ensures the active tab aligns with the current product (an ASIN or an upload)
    if (!selectedAsin) return;
    if (selectedAsin.customImage && activeTab !== ActiveTab.UploadProductImage) {
      setActiveTab(ActiveTab.UploadProductImage);
    } else if (!selectedAsin.customImage && activeTab !== ActiveTab.AddAsin) {
      setActiveTab(ActiveTab.AddAsin);
    }
  }, [selectedAsin]);

  useEffect(() => {
    // Adds the product if not in the list yet, or updates the product to make sure the currently selected image is used
    if (!selectedAsin || !selectedAsin.asin) return;
    dispatch(upsertProduct(createAsinProduct(selectedAsin)));
  }, [selectedAsin]);

  useEffect(() => {
    setAsinOfItemWithActiveChangeItemPicker(undefined);
    scrollToAsin();
  }, [selectedAsin]);

  useEffect(() => {
    // Moves the selected Asin to the top of the list when the product selector is opened
    if (!selectedAsin || prevIsVisible) return;
    scrollToAsin();
  }, [props.isVisible]);

  const moveAsinToTopOfProductsList = (asinIndex: number) => {
    if (asinIndex < 1) return;
    const newProducts = [...products];
    newProducts.splice(asinIndex, 1);
    newProducts.unshift(products[asinIndex]);
    dispatch(setProducts(newProducts));
  };

  const scrollToAsin = (_asinItem?: ASINItem) => {
    const asinItem = _asinItem || selectedAsin;
    const itemEl = asinItem?.asin ? productListItemsRef.current[asinItem.asin] : null;
    const listEl = productListRef.current;
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        if (itemEl && listEl) {
          scrollToItemInList({ itemEl, listEl });
        }
      });
    });
  };

  const scrollToItemInList = ({ itemEl, listEl }: { itemEl: HTMLElement; listEl: HTMLElement }) => {
    if (itemEl && listEl) {
      const listElTop = listEl.offsetTop;
      const listElHeight = listEl.offsetHeight;
      const listElVisibleTop = listEl.scrollTop;
      const listElVisibleBottom = listElVisibleTop + listElHeight;
      const itemElHeight = itemEl.offsetHeight;
      const itemElTop = itemEl.offsetTop - listElTop;
      const itemElMiddle = itemElTop + itemElHeight * 0.5;
      const itemElBottom = itemElTop + itemElHeight;
      if (itemElTop < listElVisibleTop || itemElBottom > listElVisibleBottom) {
        listEl.scrollTo({
          top: itemElMiddle - listElTop,
          behavior: 'smooth',
        });
      }
    }
  };

  const searchClickHandler = async () => {
    if (!searchAsin) {
      addFailureNotification({ SnackbarContent: SubmitBlankAsinNotification });
      return;
    }

    if (errorMessages.length > 0) {
      setErrorMessages([]);
    }

    setAsinLoading(true);
    try {
      if (asinLookupSet.has(searchAsin)) {
        const selectedAsinIndex = products.findIndex((item) => item.asin === searchAsin);
        studioContext.setAsin(products[selectedAsinIndex]);
        if (selectedAsinIndex > 0) {
          moveAsinToTopOfProductsList(selectedAsinIndex);
        }
        return;
      }

      const fetchedAsin = (
        await genAIBackendClient.retrieveASINMetadataByASINId({
          asinId: searchAsin,
          marketplaceId: US_MARKETPLACE_ID,
        })
      ).body;

      if (fetchedAsin.asinCategory === AsinCategoryEnum.RESTRICTED) {
        setErrorMessages([asinErrorMessages.RESTRICTED]);
        addFailureNotification({ SnackbarContent: SubmitRestrictedAsinNotification });
      } else {
        const newAsinItem: ASINItem = {
          asin: searchAsin,
          metadata: fetchedAsin,
        };
        dispatch(upsertProduct(createAsinProduct(newAsinItem)));
        studioContext.setAsin(newAsinItem);
      }
    } catch (error) {
      logger.error(`Error: Fetching ASIN metadata`, { ASIN: searchAsin, Error: error });
      setErrorMessages([asinErrorMessages.INVALID]);
      addFailureNotification({ SnackbarContent: SubmitAsinFailureNotification });
    } finally {
      setAsinLoading(false);
    }
  };

  const onChangeHandler = (e: SyntheticEvent<Element, Event>, files: File[]) => {
    if (files && files.length > 0) {
      const imageUrl = URL.createObjectURL(files[0]);
      const newAsin = {
        customImage: {
          url: imageUrl,
          name: files[0].name,
          size: files[0].size,
        },
      } as ASINItem;
      studioContext.setAsin(newAsin);
      props.closeProductSelector();
    }
  };

  const onCancelCustomImage = () => {
    studioContext.setAsin(undefined);
  };

  const handleOpenChangeImagePicker = (asinItem: ASINItem) => {
    setAsinOfItemWithActiveChangeItemPicker(asinItem.asin);
    scrollToAsin(asinItem);
  };

  const handleCloseChangeImagePicker = () => {
    setAsinOfItemWithActiveChangeItemPicker(undefined);
  };

  return (
    <div className={`${style.container} ${BLOCK_STUDIO_FILE_UPLOAD_DROPZONE_CLASSNAME}`}>
      <div className={style.header}>
        <div
          onClick={() => setActiveTab(ActiveTab.AddAsin)}
          className={`${style.headerButton} ${activeTab == ActiveTab.AddAsin ? style.selected : ''}`}
        >
          Add an ASIN
        </div>
        <div
          onClick={() => setActiveTab(ActiveTab.UploadProductImage)}
          className={`${style.headerButton} ${activeTab == ActiveTab.UploadProductImage ? style.selected : ''}`}
        >
          Upload product image
        </div>
      </div>
      <div style={{ display: activeTab == ActiveTab.AddAsin ? 'flex' : 'none' }} className={style.flexGap}>
        <div className={style.searchBar}>
          <input
            placeholder="Enter a specific product ASIN"
            maxLength={14}
            onChange={(event) => searchBarOnChangeHandler(event.target.value)}
            onKeyDown={(event) => checkEnterKey(event.key)}
            data-testid="asin-input"
          />
          <Icon onClick={searchClickHandler} className={style.searchIcon} type={iconTypes.search} data-testid="asin-search-button" />
        </div>
        <div className={style.productList} ref={productListRef} data-testid="product-wrapper-list">
          {asinLoading && <SpinnerIcon style={{ margin: '0 auto', width: '32px', height: '32px' }} />}
          {errorMessages &&
            errorMessages.map((message, index) => {
              switch (message) {
                case asinErrorMessages.RESTRICTED:
                  return (
                    <div
                      data-testid={'ProductSelector-restrictedAsinError'}
                      key={`ProductSelectorItem_ERROR_${index}_${message}`}
                      className={style.errorMessage}
                    >
                      This ASIN product type is not supported in
                      <br />
                      AI creative studio at this time.
                    </div>
                  );
                case asinErrorMessages.INVALID:
                default:
                  return (
                    <div
                      data-testid={'ProductSelector-invalidAsinError'}
                      key={`ProductSelectorItem_ERROR_${index}_${message}`}
                      className={style.errorMessage}
                    >
                      Invalid ASIN. Please enter a different product
                      <br />
                      and try again.
                    </div>
                  );
              }
            })}
          {products.map(
            (item, index) =>
              item.asin &&
              Object.keys(item.metadata as ASINMetadata).length > 0 && (
                <ProductSelectorItem
                  key={`ProductSelectorItem_${index}_${item.asin}`}
                  ref={(element: HTMLDivElement) => (productListItemsRef.current[item.asin] = element)}
                  product={item}
                  closeChangeImagePicker={() => handleCloseChangeImagePicker()}
                  openChangeImagePicker={() => handleOpenChangeImagePicker(item)}
                  showChangeImagePicker={asinOfItemWithActiveChangeItemPicker === item.asin}
                  setProduct={(product: ASINItem) => dispatch(upsertProduct(createAsinProduct(product)))}
                />
              ),
          )}
        </div>
      </div>
      <div
        style={{ display: activeTab == ActiveTab.UploadProductImage ? 'block' : 'none' }}
        className={`${style.uploadProductImage} ${style.tabContent}`}
      >
        {customImage ? (
          <div className={style.uploadedProductImageTile}>
            <div className={style.uploadedProductImageTileImagePreview} style={{ backgroundImage: `url(${customImage.url})` }}></div>
            <div className={style.imageDescriptionContainer}>
              <div className={style.imageDescriptionContainerImageName}>{customImage.name}</div>
              <div className={style.imageDescriptionContainerImageSize}>{formatBytes(customImage.size)}</div>
            </div>
            <div onClick={onCancelCustomImage} className={style.uploadProductImageCancelIcon}>
              <Icon type={iconTypes.close} />
            </div>
          </div>
        ) : (
          <FileUploadDropZone
            accept={['image/png', 'image/jpeg']}
            dropzoneId="drop-zone"
            inputId="file-uploader"
            heading={
              <Text renderAs="span" color="default">
                Choose file or drag and drop
              </Text>
            }
            onChange={onChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ProductSelector;
