import { Query, QueryKey, UseQueryOptions } from '@tanstack/react-query';
import React from 'react';
import type { AbortAction, AbortByGroupIdAction } from 'src/v2/contexts/backend/actions/Abort';
import type { DeleteAssetAction } from 'src/v2/contexts/backend/actions/DeleteAsset';
import type { FileUploadGetPresignAction } from 'src/v2/contexts/backend/actions/FileUploadGetPresign';
import type { GetUserDetailsAction, GetUserDetailsActionQueryResult } from 'src/v2/contexts/backend/actions/GetUserDetails';
import type { GetUserPermissionsAction, GetUserPermissionsActionQueryResult } from 'src/v2/contexts/backend/actions/GetUserPermissions';
import { ProductBackgroundRemovalAction, ProductBackgroundRemovalActionQueryResult } from 'src/v2/contexts/backend/actions/ProductBackgroundRemoval';
import type { PublishAssetToAssetLibraryAction } from 'src/v2/contexts/backend/actions/PublishAssetToAssetLibrary';
import type { PublishAssetToFeedAction } from 'src/v2/contexts/backend/actions/PublishAssetToFeed';
import type {
  QueryAdvertisingAccountsAction,
  QueryAdvertisingAccountsActionQueryResult,
} from 'src/v2/contexts/backend/actions/QueryAdvertisingAccounts';
import type {
  RetrieveAsinMetadataByAsinIdAction,
  RetrieveAsinMetadataByAsinIdActionQueryResult,
} from 'src/v2/contexts/backend/actions/RetrieveAsinMetadataByAsinId';
import type { RetrieveAssetAction, RetrieveAssetActionQueryResult } from 'src/v2/contexts/backend/actions/RetrieveAsset';
import type { RetrieveAssetsAction, RetrieveAssetsActionQueryResult } from 'src/v2/contexts/backend/actions/RetrieveAssets';
import type {
  RetrieveInspirationsListAction,
  RetrieveInspirationsListActionQueryResult,
} from 'src/v2/contexts/backend/actions/RetrieveInspirationsList';
import type {
  RetrieveResultByWorkflowIdAndBatchIdAction,
  RetrieveResultByWorkflowIdAndBatchIdActionQueryResult,
} from 'src/v2/contexts/backend/actions/RetrieveResultByWorkflowIdAndBatchId';
import type { RetrieveToolsListAction, RetrieveToolsListActionQueryResult } from 'src/v2/contexts/backend/actions/RetrieveToolsList';
import type { RetrieveWorkflowsListAction, RetrieveWorkflowsListActionQueryResult } from 'src/v2/contexts/backend/actions/RetrieveWorkflowsList';
import type { SubmitFeedbackSentimentAction } from 'src/v2/contexts/backend/actions/SubmitFeedbackSentiment';
import type { SubmitReportingReportAction } from 'src/v2/contexts/backend/actions/SubmitReportingReport';
import type { SubmitWorkflowByIdAction } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';

export enum HttpStatusCode {
  OK = 200,
  NO_CONTENT = 204,
}

export enum BackendActionType {
  ABORT = 'Abort',
  ABORT_BY_GROUP_ID = 'AbortByGroupId',
  DELETE_ASSET = 'DeleteAsset',
  FILE_UPLOAD_GET_PRESIGN = 'FileUploadGetPresign',
  GET_USER_DETAILS = 'GetUserDetails',
  GET_USER_PERMISSIONS = 'GetUserPermissions',
  PRODUCT_BACKGROUND_REMOVAL = 'ProductImageBackgroundRemoval',
  PUBLISH_ASSET_TO_FEED = 'PublishAssetToFeed',
  PUBLISH_ASSET_TO_ASSET_LIBRARY = 'PublishAssetToAssetLibrary',
  QUERY_ADVERTISING_ACCOUNTS = 'QueryAdvertisingAccounts',
  RETRIEVE_ASIN_METADATA_BY_ASIN_ID = 'RetrieveAsinMetadataByAsinId',
  RETRIEVE_ASSET = 'RetrieveAsset',
  RETRIEVE_ASSETS = 'RetrieveAssets',
  RETRIEVE_INSPIRATIONS_LIST = 'RetrieveInspirationsList',
  RETRIEVE_RESULT_BY_WORKFLOW_ID_AND_BATCH_ID = 'RetrieveResultByWorkflowIdAndBatchId',
  RETRIEVE_TOOLS_LIST = 'RetrieveToolsList',
  RETRIEVE_WORKFLOWS_LIST = 'RetrieveWorkflowsList',
  SUBMIT_FEEDBACK_SENTIMENT = 'SubmitFeedbackSentiment',
  SUBMIT_REPORTING_REPORT = 'SubmitReportingReport',
  SUBMIT_WORKFLOW_BY_ID = 'SubmitWorkflowById',
}

export enum RequestStatus {
  ABORTED = 'aborted',
  ERROR = 'error',
  PENDING = 'pending',
  SUCCESS = 'success',
}

export interface Request {
  id: string;
  action: BackendAction;
  status: RequestStatus;
  groupId?: string;
}

export interface RequestsLookup {
  byGroup: {
    [groupId: string]: Request['id'][];
  };
  byId: {
    [id: Request['id']]: Request;
  };
}

export interface Requests {
  lookup: RequestsLookup;
  requestIdsByGroup: {
    [groupId: string]: Request['id'][];
  };
}

interface BackendActionOptionsBase {
  requestGroupId?: string;
}

export interface BackendQueryActionOptions extends BackendActionOptionsBase {
  refetchInterval?: number;
  timeout?: number;
}

export type BackendActionOptions = BackendActionOptionsBase | BackendQueryActionOptions;

// Allowing 'any' as a fallback type to enable generic handlers. Actions are strongly typed when type is known.
export interface BackendActionBase<
  TBackendActionType extends BackendActionType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TPayload = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TSuccessResponse = any,
  TOptions extends BackendActionOptions = BackendActionOptionsBase,
> {
  type: TBackendActionType;
  payload: TPayload;
  options?: TOptions;
  onInit?: (props: { id: string }) => void;
  onAbort?: () => void;
  onSuccess?: (props: { response: TSuccessResponse }) => void;
  onError?: (err: Error) => void;
}

export interface BackendActionQueryResultBase<TBackendActionType extends BackendActionType, TResponse> {
  type: TBackendActionType;
  requestStatus: RequestStatus;
  response: TResponse | Error | undefined;
}

export type BackendAction =
  | AbortAction
  | AbortByGroupIdAction
  | DeleteAssetAction
  | FileUploadGetPresignAction
  | GetUserDetailsAction
  | GetUserPermissionsAction
  | PublishAssetToFeedAction
  | QueryAdvertisingAccountsAction
  | PublishAssetToAssetLibraryAction
  | RetrieveAsinMetadataByAsinIdAction
  | RetrieveAssetAction
  | RetrieveAssetsAction
  | RetrieveInspirationsListAction
  | RetrieveResultByWorkflowIdAndBatchIdAction
  | RetrieveToolsListAction
  | RetrieveWorkflowsListAction
  | SubmitFeedbackSentimentAction
  | SubmitReportingReportAction
  | SubmitWorkflowByIdAction
  | ProductBackgroundRemovalAction;

export type BackendQueryAction =
  | GetUserDetailsAction
  | GetUserPermissionsAction
  | QueryAdvertisingAccountsAction
  | RetrieveAsinMetadataByAsinIdAction
  | RetrieveAssetAction
  | RetrieveAssetsAction
  | RetrieveInspirationsListAction
  | RetrieveResultByWorkflowIdAndBatchIdAction
  | RetrieveToolsListAction
  | RetrieveWorkflowsListAction
  | ProductBackgroundRemovalAction;

export type BackendActionQueryResult =
  | GetUserDetailsActionQueryResult
  | GetUserPermissionsActionQueryResult
  | QueryAdvertisingAccountsActionQueryResult
  | RetrieveAsinMetadataByAsinIdActionQueryResult
  | RetrieveAssetActionQueryResult
  | RetrieveAssetsActionQueryResult
  | RetrieveInspirationsListActionQueryResult
  | RetrieveResultByWorkflowIdAndBatchIdActionQueryResult
  | RetrieveToolsListActionQueryResult
  | RetrieveWorkflowsListActionQueryResult
  | ProductBackgroundRemovalActionQueryResult;

export type BackendQueryOptionsType = UseQueryOptions<BackendActionQueryResult>;

export type BackendQuery = Query<BackendActionQueryResult, Error, BackendActionQueryResult, QueryKey>;

export type SetQueriesType = React.Dispatch<React.SetStateAction<BackendQueryOptionsType[]>>;
