import Genaihubbackend, { SubmitWorkflowByIdInput, SubmitWorkflowByIdOutput } from '@amzn/genaihub-typescript-client';
import { UseMutationResult } from '@tanstack/react-query';
import { BackendActionBase, BackendActionType, RequestsLookup } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { WorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { handleInit, handleError, handleSuccess } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';
import { normalizePayloadForSubmitWorkflowById } from 'src/v2/contexts/backend/utils/WorkflowOptions.utils';

export interface StronglyTypedSubmitWorkflowByIdInput extends SubmitWorkflowByIdInput {
  body: {
    workflowOptions: WorkflowOptions;
  };
}

export type SubmitWorkflowByIdAction = BackendActionBase<
  BackendActionType.SUBMIT_WORKFLOW_BY_ID,
  StronglyTypedSubmitWorkflowByIdInput,
  SubmitWorkflowByIdOutput['body']
>;

interface SubmitWorkflowByIdMutationProps {
  backendClient: Genaihubbackend;
  payload: SubmitWorkflowByIdInput;
}

export const SubmitWorkflowByIdMutationConfig = {
  mutationFn: async ({ backendClient, payload }: SubmitWorkflowByIdMutationProps) => {
    const response = await backendClient.submitWorkflowById(payload);
    return response.body;
  },
};

interface HandleSubmitWorkflowByIdActionProps {
  action: SubmitWorkflowByIdAction;
  backendClient: Genaihubbackend;
  submitWorkflowByIdMutation: UseMutationResult<SubmitWorkflowByIdOutput['body'], Error, SubmitWorkflowByIdMutationProps, unknown>;
  requestsLookup: RequestsLookup;
}

export const handleSubmitWorkflowByIdAction = async ({
  action,
  backendClient,
  submitWorkflowByIdMutation,
  requestsLookup,
}: HandleSubmitWorkflowByIdActionProps) => {
  const id = handleInit({ action, requestsLookup });
  try {
    const response = await submitWorkflowByIdMutation.mutateAsync({
      backendClient,
      payload: normalizePayloadForSubmitWorkflowById({ payload: action.payload }),
    });
    return handleSuccess({ response, action, id, requestsLookup });
  } catch (err) {
    return handleError({ err, action, id, requestsLookup });
  }
};
