import { useContext, useEffect } from 'react';
import { AppContext } from 'src/AppContext';
import { FeedDispatchContext } from 'src/v2/contexts/feed/FeedContext';
import { FeedActionType } from 'src/v2/contexts/feed/FeedContext.types';
import { useAppSelector } from 'src/v2/redux/hooks';
import {
  getFeedAssetGenerations,
  getFeedAssetsByDateGroup,
  getFeedAssetsSortedByDate,
  getFeedFetchEntityId,
  getFeedFetchPlaceholderCount,
  getIsFeedFetchingAssets,
} from 'src/v2/redux/slices/feed/feedSlice';

export const useFeed = (props?: { includeFeedAssetsByDateGroup?: boolean }) => {
  const appContext = useContext(AppContext);
  const feedDispatchContext = useContext(FeedDispatchContext);
  const feedAssets = useAppSelector(getFeedAssetsSortedByDate);
  const feedAssetGenerations = useAppSelector(getFeedAssetGenerations);
  const feedAssetsByDateGroup = props?.includeFeedAssetsByDateGroup ? useAppSelector(getFeedAssetsByDateGroup) : [];
  const feedFetchPlaceholderCount = useAppSelector(getFeedFetchPlaceholderCount);
  const isFeedFetchingAssets = useAppSelector(getIsFeedFetchingAssets);
  const lastFeedFetchEntityId = useAppSelector(getFeedFetchEntityId);
  const entityId = appContext.getEntityId();
  const accountType = appContext.accountType;

  useEffect(() => {
    if (!feedAssets.length || entityId != lastFeedFetchEntityId) {
      fetchAssets();
    }
  }, [accountType, entityId, feedAssets]);

  const fetchAssets = () => {
    feedDispatchContext({
      type: FeedActionType.FETCH_FEED,
      payload: {
        accountType,
        entityId,
      },
    });
  };

  return {
    /**
     * Sorted by timestamp (newest first)
     */
    feedAssets,
    feedAssetsByDateGroup,
    feedAssetGenerations,
    feedFetchPlaceholderCount,
    isFeedFetchingAssets,
    fetchAssets,
  };
};
