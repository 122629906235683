import { Asset as BackendAsset } from '@amzn/genaihub-typescript-client';
import { useContext, useState } from 'react';
import { AppContext } from 'src/AppContext';
import {
  AddToAssetLibraryFailureNotification,
  AddToAssetLibraryProcessingNotification,
  AddToAssetLibrarySuccessNotification,
} from 'src/components/snackbar/notifications/AddToAssetLibraryNotifications';
import { Metrics } from 'src/constants';
import type { PublishAssetToAssetLibraryAction } from 'src/v2/contexts/backend/actions/PublishAssetToAssetLibrary';
import type { RetrieveAssetAction } from 'src/v2/contexts/backend/actions/RetrieveAsset';
import { BackendDispatchContext } from 'src/v2/contexts/backend/BackendContext';
import { BackendActionType } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { AbortError } from 'src/v2/errors/CustomErrors';
import { SimpleAsset } from 'src/v2/types';
import { v6 as uuidV6 } from 'uuid';

export const RCS_FAILED_TO_REGISTER = 'Failed to register';
export const PROCESSING_TIMEOUT_OVERRIDE = 20000;

export const useSaveAsset = ({ asset }: { asset: SimpleAsset }) => {
  const appContext = useContext(AppContext);
  const backendDispatchContext = useContext(BackendDispatchContext);
  const { addProcessingNotification, addFailureNotification, addSuccessNotification } = useNotificationActions();
  const [saveAssetGroupRequestId, setSaveAssetGroupRequestId] = useState<string | undefined>(undefined);
  const canSaveAsset = appContext.hasWritePermissionToAssetLibrary;

  const retrieveAsset = ({ payload, requestGroupId }: { payload: RetrieveAssetAction['payload']; requestGroupId: string }): Promise<BackendAsset> => {
    return new Promise((resolve, reject) => {
      backendDispatchContext({
        type: BackendActionType.RETRIEVE_ASSET,
        payload,
        options: {
          requestGroupId,
        },
        onAbort: () => reject(new AbortError()),
        onError: (err) => reject(err),
        onSuccess: ({ response }) => {
          if (!response.asset) return reject(new Error('Asset not found'));
          return resolve(response.asset);
        },
      });
    });
  };

  const saveAssetToAssetLibrary = ({
    payload,
    requestGroupId,
  }: {
    payload: PublishAssetToAssetLibraryAction['payload'];
    requestGroupId: string;
  }): Promise<string> => {
    return new Promise((resolve, reject) => {
      backendDispatchContext({
        type: BackendActionType.PUBLISH_ASSET_TO_ASSET_LIBRARY,
        payload,
        options: {
          requestGroupId,
        },
        onAbort: () => reject(new AbortError()),
        onError: (err) => reject(err),
        onSuccess: ({ response }) => {
          if (!response.assetId || response.assetId === RCS_FAILED_TO_REGISTER) {
            return reject(new Error('Failed to save asset to asset library'));
          }
          return resolve(response.assetId);
        },
      });
    });
  };

  const saveAsset = async (options?: { processingTimeoutOverride?: number }) => {
    if (!canSaveAsset) return console.warn(`User doesn't have permission to save assets to Asset Library`);
    if (saveAssetGroupRequestId) return console.warn('A save asset request is already in progress');

    const adsAccountId = appContext.getAdsAccountId();
    const entityId = appContext.getEntityId();
    const assetId = asset.id;
    const requestGroupId = uuidV6();

    // Prepare a processing notification in case the publish asset request takes longer than expected.
    const cleanupProcessingNotification = addProcessingNotification({
      SnackbarContent: AddToAssetLibraryProcessingNotification,
      processingTimeoutOverride: options?.processingTimeoutOverride ?? PROCESSING_TIMEOUT_OVERRIDE,
    });

    try {
      if (!entityId) throw new Error('Entity ID not available');
      setSaveAssetGroupRequestId(requestGroupId);
      const backendAsset = await retrieveAsset({
        payload: {
          id: assetId,
          entityId,
        },
        requestGroupId,
      });
      const assetUrl = backendAsset.uri;
      const asin = backendAsset.asin;
      if (!assetUrl) throw new Error('Asset url not available');
      await saveAssetToAssetLibrary({
        payload: {
          assetUrl,
          profileId: entityId,
          // Must use the ACI-formatted entity ID
          // https://w.amazon.com/bin/view/AmazonLive/AmazonCommonIdentifier/
          adsAccountId,
          ...(asin ? { asinList: [asin] } : {}),
        },
        requestGroupId,
      });
      // Track assets saved to Amazon Library
      appContext.metrics.trackMetrics(Metrics.Methods.SaveAsset, {}, { [Metrics.Counters.Success]: 1 });
      // Send a success notification to the user
      addSuccessNotification({ SnackbarContent: AddToAssetLibrarySuccessNotification });
    } catch (err) {
      if (err instanceof AbortError) return;
      console.error(`Error while saving asset '${assetId}' to asset library:`, err);
      // Track assets saved to Amazon Library failure
      appContext.metrics.trackMetrics(Metrics.Methods.SaveAsset, {}, { [Metrics.Counters.Failure]: 1 });
      // Send a failure notification to the user
      addFailureNotification({ SnackbarContent: AddToAssetLibraryFailureNotification });
    } finally {
      setSaveAssetGroupRequestId(undefined);
      // Clean up the processing notification
      cleanupProcessingNotification();
    }
  };

  const abortSaveAsset = () => {
    if (!saveAssetGroupRequestId) return;
    backendDispatchContext({
      type: BackendActionType.ABORT_BY_GROUP_ID,
      payload: {
        groupId: saveAssetGroupRequestId,
      },
    });
  };

  return {
    canSaveAsset,
    isSavingAsset: !!saveAssetGroupRequestId,
    abortSaveAsset,
    saveAsset,
  };
};
