import Genaihubbackend, { RetrieveASINMetadataByASINIdInput, RetrieveASINMetadataByASINIdOutput } from '@amzn/genaihub-typescript-client';
import {
  BackendActionBase,
  BackendActionQueryResultBase,
  BackendActionType,
  BackendQueryActionOptions,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type RetrieveAsinMetadataByAsinIdAction = BackendActionBase<
  BackendActionType.RETRIEVE_ASIN_METADATA_BY_ASIN_ID,
  RetrieveASINMetadataByASINIdInput,
  RetrieveASINMetadataByASINIdOutput['body'],
  BackendQueryActionOptions
>;

export type RetrieveAsinMetadataByAsinIdActionQueryResult = BackendActionQueryResultBase<
  BackendActionType.RETRIEVE_ASIN_METADATA_BY_ASIN_ID,
  RetrieveASINMetadataByASINIdOutput['body']
>;

interface RetrieveAsinMetadataByAsinIdQueryFnProps {
  id: Request['id'];
  action: RetrieveAsinMetadataByAsinIdAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const retrieveAsinMetadataByAsinIdQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: RetrieveAsinMetadataByAsinIdQueryFnProps): Promise<RetrieveAsinMetadataByAsinIdActionQueryResult> => {
  const type = BackendActionType.RETRIEVE_ASIN_METADATA_BY_ASIN_ID;
  const response = await backendClient.retrieveASINMetadataByASINId(action.payload);
  const request = getRequest({ id, requestsLookup });

  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }

  return { type, response: response.body, requestStatus: RequestStatus.SUCCESS };
};

interface HandleRetrieveAsinMetadataByAsinIdActionProps {
  action: RetrieveAsinMetadataByAsinIdAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}

export const handleRetrieveAsinMetadataByAsinIdAction = ({
  action,
  backendClient,
  requestsLookup,
  setQueries,
}: HandleRetrieveAsinMetadataByAsinIdActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.RETRIEVE_ASIN_METADATA_BY_ASIN_ID,
    getQueryResult: ({ id }) => {
      return retrieveAsinMetadataByAsinIdQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
