import { PublishAssetFromUrlInput } from '@amzn/genaihub-typescript-client';
import { useContext } from 'react';
import { useAIBackendHubClient } from 'src/api/useApi';
import { AppContext } from 'src/AppContext';
import {
  AddToAssetLibraryFailureNotification,
  AddToAssetLibraryProcessingNotification,
  AddToAssetLibrarySuccessNotification,
} from 'src/components/snackbar/notifications/AddToAssetLibraryNotifications';
import { Metrics } from 'src/constants';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';

export const PROCESSING_TIMEOUT_OVERRIDE = 20000;

interface SaveAssetProps {
  assetUrl: string;
  asin?: string;
}

const useAssetLibrary = (options?: { processingTimeoutOverride?: number }) => {
  const client = useAIBackendHubClient();
  const appContext = useContext(AppContext);
  const { addProcessingNotification, addFailureNotification, addSuccessNotification } = useNotificationActions();

  const saveAsset = async (props: SaveAssetProps): Promise<Error | void> => {
    const { assetUrl, asin } = props;

    if (!props.assetUrl) return;

    // Prepare a processing notification in case the publish asset request takes longer than expected.
    const cleanupProcessingNotification = addProcessingNotification({
      SnackbarContent: AddToAssetLibraryProcessingNotification,
      processingTimeoutOverride: options?.processingTimeoutOverride ?? PROCESSING_TIMEOUT_OVERRIDE,
    });

    // Must use the ACI-formatted entity ID
    // https://w.amazon.com/bin/view/AmazonLive/AmazonCommonIdentifier/
    const adsAccountId = appContext.selectedAdvertisingAccount?.adsAccountId ?? '';
    const profileId = appContext.selectedAdvertisingAccount?.alternateIds?.[0] ?? '';
    // Publish the asset
    const requestPayload = {
      adsAccountId,
      profileId,
      assetUrl,
      ...(asin && { asinList: [asin] }),
    };

    let saveResult: Error | undefined;

    try {
      await client.publishAssetFromUrl(requestPayload as PublishAssetFromUrlInput);
    } catch (err) {
      console.error(err);
      saveResult = new Error('Failed to save asset to Asset Library.');
    }

    if (saveResult instanceof Error) {
      // Track assets saved to Amazon Library failure
      appContext.metrics.trackMetrics(Metrics.Methods.SaveAsset, {}, { [Metrics.Counters.Failure]: 1 });
      // Send a failure notification to the user
      addFailureNotification({ SnackbarContent: AddToAssetLibraryFailureNotification });
    } else {
      // Track assets saved to Amazon Library
      appContext.metrics.trackMetrics(Metrics.Methods.SaveAsset, {}, { [Metrics.Counters.Success]: 1 });
      // Send a success notification to the user
      addSuccessNotification({ SnackbarContent: AddToAssetLibrarySuccessNotification });
    }

    // Clean up the processing notification
    cleanupProcessingNotification();

    return saveResult;
  };

  return {
    saveAsset,
  };
};

export default useAssetLibrary;
