import { ResizeDirection } from 'src/v2/types';

export const getResizeDirection = (direction: string | undefined) => {
  switch (direction?.toUpperCase()) {
    case 'HORIZONTAL_LEFT':
      return ResizeDirection.HORIZONTAL_LEFT;
    case 'HORIZONTAL_MIDDLE':
      return ResizeDirection.HORIZONTAL_MIDDLE;
    case 'HORIZONTAL_RIGHT':
      return ResizeDirection.HORIZONTAL_RIGHT;
    case 'VERTICAL_DOWN':
      return ResizeDirection.VERTICAL_DOWN;
    case 'VERTICAL_MIDDLE':
      return ResizeDirection.VERTICAL_MIDDLE;
    case 'VERTICAL_UP':
      return ResizeDirection.VERTICAL_UP;
    default:
      return ResizeDirection.HORIZONTAL_MIDDLE;
  }
};
