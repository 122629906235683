import { HTMLAttributes, memo, useMemo } from 'react';
import { GenerationPlaceholder, PLACEHOLDER_HEADER_GENERATING } from 'src/v2/components/placeholder/GenerationPlaceholder';
import { ImportImagePlaceholder } from 'src/v2/components/placeholder/ImportImagePlaceholder';
import { FeedTestIds } from 'src/v2/components/studio/feed/Feed.constants';
import styles from 'src/v2/components/studio/feed/Feed.module.scss';
import { AssetGeneration } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice.types';
import { WorkflowIdEnum } from 'src/v2/types';
import { getFeedContentTypeByWorkflowId, getNormalizedClassNames } from 'src/v2/utils/utils';

export type FeedAssetGenerationPlaceholder =
  | {
      id: string;
      aspectRatio: string;
      workflowId: Exclude<WorkflowIdEnum, WorkflowIdEnum.IMPORT_IMAGE>;
    }
  | {
      id: string;
      aspectRatio: string;
      assetGenerationId: AssetGeneration['id'];
      url: string;
      workflowId: WorkflowIdEnum.IMPORT_IMAGE;
    };

interface FeedAssetGenerationPlaceholdersGroupItemProps extends HTMLAttributes<HTMLDivElement> {
  placeholder: FeedAssetGenerationPlaceholder;
}

export const FeedAssetGenerationPlaceholdersGroupItem = memo((props: FeedAssetGenerationPlaceholdersGroupItemProps) => {
  const { placeholder, ...restProps } = props;
  const rootClassNames = getNormalizedClassNames([styles.feedItemContainer, styles.feedAssetGenerationPlaceholder]);
  const placeholderClassNames = getNormalizedClassNames([styles.placeholder]);
  const { workflowId } = placeholder;
  const contentType = useMemo(() => getFeedContentTypeByWorkflowId(workflowId), [workflowId]);

  return (
    <div
      className={rootClassNames}
      data-testid={FeedTestIds.feedAssetGenerationPlaceholder.rootContainer}
      data-alt-testid={FeedTestIds.feedAssetGenerationPlaceholder.rootContainerAlt}
      {...restProps}
    >
      {placeholder.workflowId === WorkflowIdEnum.IMPORT_IMAGE ? (
        <ImportImagePlaceholder key={placeholder.id} assetGenerationId={placeholder.assetGenerationId} url={placeholder.url} />
      ) : (
        <GenerationPlaceholder
          className={placeholderClassNames}
          data-testid={FeedTestIds.feedAssetGenerationPlaceholder.placeholderComponent}
          header={PLACEHOLDER_HEADER_GENERATING}
          message={contentType}
        />
      )}
    </div>
  );
});
FeedAssetGenerationPlaceholdersGroupItem.displayName = 'FeedAssetGenerationPlaceholdersGroupItem';
