import Genaihubbackend, { QueryAdvertisingAccountsInput, QueryAdvertisingAccountsOutput } from '@amzn/genaihub-typescript-client';
import {
  BackendActionBase,
  BackendActionQueryResultBase,
  BackendActionType,
  BackendQueryActionOptions,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type QueryAdvertisingAccountsAction = BackendActionBase<
  BackendActionType.QUERY_ADVERTISING_ACCOUNTS,
  QueryAdvertisingAccountsInput,
  QueryAdvertisingAccountsOutput['body'],
  BackendQueryActionOptions
>;

export type QueryAdvertisingAccountsActionQueryResult = BackendActionQueryResultBase<
  BackendActionType.QUERY_ADVERTISING_ACCOUNTS,
  QueryAdvertisingAccountsOutput['body']
>;

interface QueryAdvertisingAccountsQueryFnProps {
  id: Request['id'];
  action: QueryAdvertisingAccountsAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const queryAdvertisingAccountsQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: QueryAdvertisingAccountsQueryFnProps): Promise<QueryAdvertisingAccountsActionQueryResult> => {
  const type = BackendActionType.QUERY_ADVERTISING_ACCOUNTS;
  const response = await backendClient.queryAdvertisingAccounts(action.payload);
  const request = getRequest({ id, requestsLookup });

  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }

  return { type, response: response.body, requestStatus: RequestStatus.SUCCESS };
};

interface HandleQueryAdvertisingAccountsActionProps {
  action: QueryAdvertisingAccountsAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}

export const handleQueryAdvertisingAccountsAction = ({
  action,
  backendClient,
  requestsLookup,
  setQueries,
}: HandleQueryAdvertisingAccountsActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.QUERY_ADVERTISING_ACCOUNTS,
    getQueryResult: ({ id }) => {
      return queryAdvertisingAccountsQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
