import { Sha256 } from '@aws-crypto/sha256-js';
import axios, { AxiosProgressEvent } from 'axios';

/** COVERT FILE TO BASE64 **/
interface ConvertFileToBase64 {
  file: File;
}

export const CONVERT_FILE_TO_BASE_64_ONLOAD_ERROR_MESSAGE =
  'Failed to convert file due to error while extracting base64 string representing the input file.';
export const CONVERT_FILE_TO_BASE_64_ONERROR_ERROR_MESSAGE = 'Failed to convert file due to error while reading the file.';

export function convertFileToBase64({ file }: ConvertFileToBase64): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      try {
        const result = (reader.result as string).split(',')[1];
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(new Error(CONVERT_FILE_TO_BASE_64_ONLOAD_ERROR_MESSAGE));
      }
    };
    reader.onerror = function () {
      console.error(reader.error);
      reject(new Error(CONVERT_FILE_TO_BASE_64_ONERROR_ERROR_MESSAGE));
    };
  });
}

/** DOWNLOAD FILE **/
interface DownloadFile {
  url: string;
}

export const FALLBACK_DOWNLOAD_FILENAME = 'download';

export async function downloadFile({ url }: DownloadFile) {
  const urlObj = new URL(url);
  const filename = urlObj.pathname.split('/').pop() || FALLBACK_DOWNLOAD_FILENAME;
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: blob.type });
}

/** FILE HASH **/
interface GetFileHash {
  file: File;
}

export async function getFileHash({ file }: GetFileHash) {
  try {
    const fileAsBase64 = await convertFileToBase64({ file });
    const sha256 = new Sha256();
    sha256.update(fileAsBase64);
    const uint8Array = await sha256.digest();
    const hashHex = Array.from(uint8Array)
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''); // convert bytes to hex string
    return hashHex;
  } catch (err) {
    console.error(err);
    throw new Error('Failed to get file hash');
  }
}

/** UPLOAD FILE **/
interface UploadFileToPresignedUrl {
  file: File;
  presignedUrl: string;
  /**
   * Progress callback for the upload
   * @param value - the ratio of uploaded bytes / total bytes
   * @returns
   */
  progressCallback?: (value: number) => void;
}

export async function uploadFileToPresignedUrl({ file, presignedUrl, progressCallback }: UploadFileToPresignedUrl): Promise<void> {
  const headers = {
    'Content-Type': file.type,
    'Content-Length': file.size.toString(),
  };

  const onUploadProgress = progressCallback
    ? (event: AxiosProgressEvent) => {
        if (event.total) {
          progressCallback(event.loaded / event.total);
        }
      }
    : undefined;

  await axios.put(presignedUrl, file, {
    headers,
    ...(onUploadProgress ? { onUploadProgress } : {}),
  });
}
