import React, { memo } from 'react';
import { AICS_WEBLAB_DARK_MODE, AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { PlaceholderAnimation, PlaceholderColorMode, PlaceholderType, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import styles from './Placeholder.module.scss';

interface PlaceholderProps extends React.HTMLAttributes<HTMLSpanElement> {
  animation?: PlaceholderAnimation;
  colorMode?: PlaceholderColorMode;
  type?: PlaceholderType.ASSET | PlaceholderType.EMPTY;
  variant?: PlaceholderVariant;
}

export const DEFAULT_PLACEHOLDER_ANIMATION = PlaceholderAnimation.NONE;
export const DEFAULT_PLACEHOLDER_COLORMODE = isWeblabInTreatment(AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment.T1)
  ? PlaceholderColorMode.WARM
  : PlaceholderColorMode.COOL;
export const DEFAULT_PLACEHOLDER_TYPE = PlaceholderType.ASSET;
export const DEFAULT_PLACEHOLDER_VARIANT = PlaceholderVariant.ROUNDED;

export const getPlaceholderAnimationClassName = (animation: PlaceholderAnimation) => styles[`animation-${animation}`];
export const getPlaceholderClassName = () => styles.placeholder;
export const getPlaceholderTypeClassName = (type: PlaceholderType) => styles[`type-${type}`];
export const getPlaceholderVariantClassName = (variant: PlaceholderVariant) => styles[`variant-${variant}`];
export const getPlaceholderColorModeClassName = (colorMode?: PlaceholderColorMode) => styles[`colorMode-${colorMode}`];

export const Placeholder = memo((props: PlaceholderProps) => {
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = props.colorMode === PlaceholderColorMode.DARKMODE || (isDarkModeLaunched && useAppSelector(isDarkModeActive)) || false;
  const {
    animation = DEFAULT_PLACEHOLDER_ANIMATION,
    className,
    colorMode = isDarkMode ? PlaceholderColorMode.DARKMODE : DEFAULT_PLACEHOLDER_COLORMODE,
    type = DEFAULT_PLACEHOLDER_TYPE,
    variant = DEFAULT_PLACEHOLDER_VARIANT,
    ...restProps
  } = props;
  const combinedClassNames = [
    getPlaceholderClassName(),
    getPlaceholderTypeClassName(type),
    getPlaceholderColorModeClassName(colorMode),
    getPlaceholderVariantClassName(variant),
    getPlaceholderAnimationClassName(animation),
    className,
  ];

  return <span className={getNormalizedClassNames(combinedClassNames)} {...restProps} />;
});

Placeholder.displayName = 'Placeholder';
