import { useContext } from 'react';
import { AppContext } from 'src/AppContext';
import { useConversationsQuery } from 'src/components/assistant/queries/conversationsList';
import useConversationId from 'src/components/assistant/utils/useConversationId';

export default function useConversation() {
  const { conversationId } = useConversationId();
  const { userDetails } = useContext(AppContext);
  const conversationsListQuery = useConversationsQuery(userDetails?.alias);
  return conversationsListQuery.data?.find((conversation) => conversation.conversationId === conversationId);
}
