import { Guide, Type } from '@amzn/genaihub-typescript-client';
import React from 'react';
import { AssetTypeEnum, JobStatusEnum } from 'src/v2/types';
import ImageViewer from './ImageViewer';
import VideoViewer from './VideoViewer';
import WorkflowGuide from './WorkflowGuide';
import CardLoader from '../common/storm/CardLoader';

export interface WorkflowResult {
  status?: string;
  ids?: string[];
  urls?: string[];
  type?: Type;
  message?: string;
}

export interface TextResults {
  status?: string;
  urls?: string[];
  type?: string;
  message?: string;
}

const WorkflowResults = (props: { result?: WorkflowResult; guide?: Guide; children?: React.ReactNode; textResults?: TextResults }) => {
  const { result, guide, children, textResults } = props;
  return (
    <>
      {!result?.status ? (
        <WorkflowGuide guide={guide} />
      ) : result.status === JobStatusEnum.COMPLETED && (result?.urls?.length || 0) > 0 ? (
        result.type === AssetTypeEnum.VIDEO ? (
          <VideoViewer urls={result.urls || []} />
        ) : (
          <ImageViewer ids={result.ids || []} urls={result.urls || []} textUrls={textResults?.urls || []}>
            {children}
          </ImageViewer>
        )
      ) : (
        <CardLoader isError={result.status !== JobStatusEnum.RUNNING} />
      )}
    </>
  );
};

export default WorkflowResults;
