import { ASINMetadata, SubmitWorkflowByIdOutput } from '@amzn/genaihub-typescript-client';
import React, { createContext } from 'react';
import { FileUploadEventType } from 'src/components/common/types';

export type EditorContextStateP1 = {
  asinMetaData: ASINMetadata;
  setAsinMetaData: React.Dispatch<React.SetStateAction<ASINMetadata>>;
  // Change workflow option to the workflow option types in V2 once state management is done
  // types: src/v2/contexts/backend/types/WorkflowOptions.types.ts
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workflowOptions: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setWorkFlowOptions: React.Dispatch<React.SetStateAction<any>>;
  fileUploadEvent: FileUploadEventType | undefined;
  setFileUploadEvent: React.Dispatch<React.SetStateAction<FileUploadEventType | undefined>>;
  disableControl: boolean;
  setDisableControl: (disableControl: boolean) => void;
  promptUpdateEvent: string | undefined;
  setPromptUpdateEvent: React.Dispatch<React.SetStateAction<string | undefined>>;
  activateGuide: boolean;
  setActivateGuide: React.Dispatch<React.SetStateAction<boolean>>;
  rawJobResponse?: SubmitWorkflowByIdOutput;
  setRawJobResponse: (rawJobResponse?: SubmitWorkflowByIdOutput) => void;
};

export const EditorContextP1 = createContext({} as EditorContextStateP1);
