import { Text } from '@amzn/storm-ui';
import { CSSProperties } from 'react';

interface TitleProps {
  title: string;
  className: string;
  style: CSSProperties;
  dataTestId: string;
}

const Title = (props: TitleProps) => {
  const { title, style, className, dataTestId } = props;
  return (
    <div style={{ ...style }} className={className} data-testid={dataTestId}>
      <Text
        style={{
          color: 'var(--text-primary, #232F3F)',
          fontFamily: 'Ember Modern Display Standard',
          fontSize: '44px',
          letterSpacing: '1px',
          fontWeight: '700',
          lineHeight: '42px',
        }}
      >
        {title}
      </Text>
    </div>
  );
};

export default Title;
