import { LOCAL_STORAGE_KEY_THEME_PREFERENCE } from 'src/constants';
import { Themes } from 'src/v2/redux/slices/theme/themeSlice.types';
import { ContentType, WorkflowIdEnum } from 'src/v2/types';

// removes empty or undefined classes based on re-usable component props data and keeps attribute cleaner
export const getNormalizedClassNames = (classNames: (string | undefined)[]) => {
  return classNames.filter((item) => item !== undefined && item.length > 0).join(' ');
};

// verifies proper color string format and returns a default if invalid
const styleTest = new Option().style;
export const checkColorStringFormat = (color: string) => {
  // Unset becomes '' when the browser parses it.
  if (color === 'unset') return color;
  // Uses the browser's style parser to validate CSS color values
  // Reset before testing the color
  styleTest.color = '';
  styleTest.color = color;
  if (styleTest.color !== '') {
    return color;
  } else {
    console.warn('Invalid color string format. Please provide a valid color string. Defaulting to black #000000');
    return '#000000';
  }
};

// replaces spaces if present and returns test id
export const getDataTestId = (testId: string | undefined): string => {
  return testId && testId?.length > 0 ? testId.replace(/ /g, '-') : '';
};

export const getFeedContentTypeByWorkflowId = (workflowId?: string) => {
  switch (workflowId) {
    case WorkflowIdEnum.LIFESTYLE_IMAGERY:
    case WorkflowIdEnum.LIFESTYLE_IMAGERY_PROMPT_ONLY:
      return ContentType.LIFESTYLE_IMAGE;
    case WorkflowIdEnum.APPAREL:
    case WorkflowIdEnum.BRAND_IDENTITY_TEXT_TO_IMAGE:
    case WorkflowIdEnum.TEXT_TO_IMAGE:
    case WorkflowIdEnum.MULTI_PRODUCT:
      return ContentType.PRODUCT_IMAGE;
    case WorkflowIdEnum.PARALLAX_MOTION:
      return ContentType.LIVE_IMAGE;
    case WorkflowIdEnum.IMPORT_IMAGE:
      return ContentType.UPLOADED_IMAGE;
    case WorkflowIdEnum.GENERATIVE_RESIZING:
    case WorkflowIdEnum.IMAGE_EDITOR:
    case WorkflowIdEnum.IMAGE_THEMING:
    case WorkflowIdEnum.PRODUCT_EDIT:
      return ContentType.EDITED_IMAGE;
    default:
      return '';
  }
};

// Get user's OS Theme system preference
export const getThemeFromSystemPreference = (): Themes => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? Themes.DARK : Themes.LIGHT;
  }
  return Themes.LIGHT;
};

// Get user's theme preference from local storage
export const getThemeFromLocalStorage = (): Themes | undefined => {
  const theme = localStorage.getItem(LOCAL_STORAGE_KEY_THEME_PREFERENCE);
  if (theme && Object.values(Themes).includes(theme as Themes)) {
    return theme as Themes;
  }
};
