import { BoundingBox } from '@amzn/genaihub-typescript-client';
import { defaultThemeType, Icon, ThemeProvider } from '@amzn/storm-ui';
import type Kovna from 'konva';
import Konva from 'konva';
import { useRef, useState, useEffect, useContext, useImperativeHandle, forwardRef, CSSProperties } from 'react';
import { Layer, Stage, Image as KonvaImage, Rect } from 'react-konva';
import BoundedBoxSelection from 'src/components/editor/UiContols/uiGeneratorControls/imageEditingControls/BoundedBoxSelection';
import { ImageModalContext } from 'src/components/imageModal';
import FreeEditSelection from 'src/components/imageModal/components/FreeEditSelection/FreeEditSelection';
import { EditOverlapNotification } from 'src/components/snackbar/notifications/EditorCanvasNotifications';
import Stack from 'src/customUIComponents/Stack';
import usePrevious from 'src/hooks/usePrevious';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { IconSize } from 'src/v2/components/Icon/types';
import { Slider } from 'src/v2/components/slider/Slider';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { WorkflowIdEnum } from 'src/v2/types';
import useImage from 'use-image';
import styles from './ImageEditingCanvas.module.scss';

type KonvaTouchMouseEvent = Kovna.KonvaEventObject<MouseEvent> | Kovna.KonvaEventObject<TouchEvent>;
export const overrideMouseLeaveTimeout = (theme: defaultThemeType) => {
  return {
    ...theme,
    tooltip: {
      ...theme.tooltip,
      mouseLeaveTimeout: 0,
    },
  };
};

interface LinesType {
  tool: string;
  points: number[];
  toolSize: number;
}

interface ImageEditingCanvasProps {
  setBaseImage: (base: string | undefined) => void;
  setImageMask: (mask: File | undefined) => void;
}

const loadProductMask = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], 'product-mask.png', { type: 'image/png' });
};

const createCanvas = (width: number, height: number): HTMLCanvasElement => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
};

const invertProductMask = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
  const imageData = ctx.getImageData(0, 0, width, height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i += 4) {
    if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0) {
      data[i] = data[i + 1] = data[i + 2] = 255;
      data[i + 3] = 255;
    } else {
      data[i + 3] = 0;
    }
  }

  ctx.putImageData(imageData, 0, 0);
};

const positionMask = (ctx: CanvasRenderingContext2D, image: HTMLCanvasElement, boundingBox: BoundingBox) => {
  // Convert rotation angle to radians, rotation canvas around top left, draw image and reset canvas
  const angle = ((boundingBox.rotateAngle || 0) * Math.PI) / 180;

  ctx.save();
  ctx.translate(boundingBox.left, boundingBox.top);
  ctx.rotate(angle);
  ctx.drawImage(image, 0, 0, boundingBox.width, boundingBox.height);

  ctx.restore();
};

const checkMaskOverlap = async (
  drawnMaskCanvas: HTMLCanvasElement,
  productMaskUrl: string,
  savedEditProductBoundingBox: BoundingBox | undefined,
): Promise<boolean> => {
  const productMaskFile = await loadProductMask(productMaskUrl);
  const productMaskImage = new Image();
  productMaskImage.src = URL.createObjectURL(productMaskFile);

  return new Promise((resolve) => {
    productMaskImage.onload = () => {
      const invertedMaskCanvas = createCanvas(productMaskImage.width, productMaskImage.height);
      const invertedMaskCtx = invertedMaskCanvas.getContext('2d');

      if (invertedMaskCtx) {
        invertedMaskCtx.drawImage(productMaskImage, 0, 0);
        invertProductMask(invertedMaskCtx, invertedMaskCanvas.width, invertedMaskCanvas.height);
      }

      const productMaskCanvas = createCanvas(drawnMaskCanvas.width, drawnMaskCanvas.height);
      const productMaskCtx = productMaskCanvas.getContext('2d');

      if (productMaskCtx && savedEditProductBoundingBox) {
        positionMask(productMaskCtx, invertedMaskCanvas, savedEditProductBoundingBox);
      }

      const drawnMaskCtx = drawnMaskCanvas.getContext('2d');
      if (drawnMaskCtx && productMaskCtx) {
        const drawnMaskImageData = drawnMaskCtx.getImageData(0, 0, drawnMaskCanvas.width, drawnMaskCanvas.height);
        const productMaskImageData = productMaskCtx.getImageData(0, 0, productMaskCanvas.width, productMaskCanvas.height);

        let overlap = false;
        for (let i = 0; i < drawnMaskImageData.data.length; i += 4) {
          // Check if the drawn mask pixel is not black (mask area) and the product mask pixel is white (product area)
          if (
            !(drawnMaskImageData.data[i] === 0 && drawnMaskImageData.data[i + 1] === 0 && drawnMaskImageData.data[i + 2] === 0) &&
            productMaskImageData.data[i] === 255 &&
            productMaskImageData.data[i + 1] === 255 &&
            productMaskImageData.data[i + 2] === 255
          ) {
            overlap = true;
            break;
          }
        }
        resolve(overlap);
      }

      URL.revokeObjectURL(productMaskImage.src);
      resolve(false);
    };
  });
};

const ImageEditingCanvas = forwardRef((props: ImageEditingCanvasProps, forwardRef) => {
  const devTestImage = 'static/media/src/components/imageModal/dev-test-image.png';
  const defaultToolSize = 30;
  const minToolSize = 5;
  const maxToolSize = 50;
  const [tool, setTool] = useState<string>('brush');
  const [toolSize, setToolSize] = useState<number>(defaultToolSize);
  const [lines, setLines] = useState<LinesType[]>([]);
  const [shadowLines, setShadowLines] = useState<LinesType[]>([]);
  const showBrushSlider = tool === 'brush';
  const showEraserSlider = tool === 'eraser';
  const [disabled, setDisabled] = useState<boolean>(false);
  const [img, setImg] = useState<string>();
  const [image] = useImage(img || '');
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({ width: 400, height: 200 });
  const [actualDimensions, setActualDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [scale, setScale] = useState<{ scaleX: number; scaleY: number }>({ scaleX: 0, scaleY: 0 });
  const [boundedBox, setBoundedBox] = useState({
    width: 0,
    height: 0,
    fill: 'rgba(255, 250, 250, 0.1)',
    // opacity: 0.08,
    stroke: 'black',
    strokeWidth: 2,
    x: 0,
    y: 0,
  });
  const [shadowBoundedBox, setShadowBoundedBox] = useState({
    width: 0,
    height: 0,
    fill: 'white',
    stroke: 'white',
    strokeWidth: 2,
    x: 0,
    y: 0,
  });

  const shadowRef = useRef<Konva.Stage | null>(null);
  const isDrawing = useRef(false);
  const divRef = useRef<HTMLDivElement>(null);

  const editToolActiveColor = 'var(--interactive-primary, #4305F4)';
  const editToolInactiveColor = '#A0AABA';
  const editToolBackgroundColor = '#fbfbfc';
  const sliderIconColors = tool === 'brush' || tool === 'eraser' ? { color: editToolActiveColor } : { color: editToolInactiveColor };

  const { activeEditsWorkflowId, imageUrl, imageReferenceId, savedEditsImageUrl, savedEditProductMask, savedEditProductBoundingBox } =
    useContext(ImageModalContext);
  const activeImageUrl = savedEditsImageUrl || imageUrl;
  const prevActiveImageUrl = usePrevious(activeImageUrl);
  const { addFailureNotification } = useNotificationActions();
  const preventOverlap = activeEditsWorkflowId !== WorkflowIdEnum.PARALLAX_MOTION;

  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setImageDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (img === undefined && divRef.current) {
      setImageDimensions({
        width: divRef.current.offsetWidth,
        height: 200,
      });
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [img, divRef]);

  useEffect(() => {
    if (!activeImageUrl) {
      setImg(undefined);
      clearCanvas();
      return;
    }

    if (prevActiveImageUrl === activeImageUrl) {
      return;
    }

    const img = document.createElement('img');
    img.id = 'canvasImage';
    img.onload = (event) => {
      if (event.target === null) {
        return;
      }
      const eventTarget = event.target as HTMLImageElement;
      updateImage(activeImageUrl, eventTarget.width, eventTarget.height);
    };
    img.src = activeImageUrl;
    clearCanvas();
  }, [activeImageUrl]);

  // used for unit testing
  useImperativeHandle(forwardRef, () => ({
    updateImage,
    setDisabled,
    getLines: () => lines,
    getShadowLines: () => shadowLines,
    shadowRef: () => shadowRef,
    getBoundedBox: () => boundedBox,
    getShadowBoundedBox: () => shadowBoundedBox,
  }));

  const updateImage = (url: string, width: number, height: number) => {
    setImg(url);
    const newHeight = (imageDimensions.width * height) / width;
    setImageDimensions({ width: imageDimensions.width, height: newHeight });
    setActualDimensions({ width, height });
    setScale({ scaleX: width / imageDimensions.width, scaleY: height / newHeight });
  };

  const srcToFile = (src: string, fileName: string, mimeType: string) => {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      })
      .catch(function (error) {
        console.error('ERROR (srcToFile)', error.message);
      });
  };

  const convertImageUrlToFile = async ({
    label,
    format,
    canvasRef,
    urlRef,
  }: {
    label: string;
    format: 'jpg' | 'png';
    canvasRef?: HTMLCanvasElement;
    urlRef?: string;
  }) => {
    let dataUrl;
    if (canvasRef) {
      dataUrl = canvasRef.toDataURL();
    }
    if (urlRef) {
      dataUrl = urlRef;
    }
    if (!dataUrl) {
      return;
    }
    return await srcToFile(dataUrl, `${label}.${format}`, `image/${format}`);
  };

  const updateMask = async () => {
    if (shadowRef.current) {
      const stage = shadowRef.current as Konva.Stage;
      const dataURL = stage.toDataURL();
      const img = new Image();
      img.src = dataURL;

      await new Promise((resolve) => {
        img.onload = resolve;
      });

      const drawnMaskCanvas = document.createElement('canvas');
      drawnMaskCanvas.width = actualDimensions.width;
      drawnMaskCanvas.height = actualDimensions.height;
      const drawnMaskCtx = drawnMaskCanvas.getContext('2d');
      drawnMaskCtx?.drawImage(img, 0, 0);

      const overlap = savedEditProductMask ? await checkMaskOverlap(drawnMaskCanvas, savedEditProductMask, savedEditProductBoundingBox) : false;
      if (preventOverlap && overlap) {
        addFailureNotification({ SnackbarContent: EditOverlapNotification });
        clearCanvas();
      } else {
        const maskFile = await convertImageUrlToFile({
          canvasRef: drawnMaskCanvas,
          label: 'image_mask',
          format: 'png',
        });
        props.setImageMask(maskFile as File);
      }
    }
  };

  const getBaseImageRef = async (baseArg: string) => {
    const baseImageResult = baseArg || (await convertImageUrlToFile({ urlRef: imageUrl, label: 'image_ref', format: 'png' }));
    if (!baseImageResult) {
      console.error('Error: base image not available');
      return;
    }
    props.setBaseImage(baseImageResult as string);
  };

  useEffect(() => {
    if (!activeImageUrl || activeImageUrl === devTestImage) {
      return;
    }
    const baseRef = activeImageUrl === '' && !imageUrl.includes('catwalk-generated-assets') ? imageReferenceId : activeImageUrl;
    getBaseImageRef(baseRef);
  }, [activeImageUrl, imageReferenceId, savedEditsImageUrl]);

  const handleMouseUpBrush = () => {
    isDrawing.current = false;
    updateMask();
  };

  const handleMouseMoveBrush = (e: KonvaTouchMouseEvent) => {
    if (!isDrawing.current) {
      // no drawing - skipping
      return;
    }
    const stage = e.target?.getStage();
    if (!stage) return;
    const point = stage.getPointerPosition();
    if (!point) return;
    const lastLine = lines[lines.length - 1];
    const shadowLastLine = shadowLines[shadowLines.length - 1];
    // add point
    shadowLastLine.points = shadowLastLine.points.concat([point.x, point.y]);
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    shadowLines.splice(shadowLines.length - 1, 1, shadowLastLine);

    setLines(lines.concat());
    setShadowLines(shadowLines.concat());
  };

  const handleMouseDownBrush = (e: KonvaTouchMouseEvent) => {
    isDrawing.current = true;
    const pos = e.target?.getStage()?.getPointerPosition();
    if (!pos) return;
    if (tool === 'brush') {
      setLines([{ tool, points: [pos.x, pos.y], toolSize }]);
      setShadowLines([{ tool, points: [pos.x, pos.y], toolSize }]);
    } else {
      setLines([...lines, { tool, points: [pos.x, pos.y], toolSize }]);
      setShadowLines([...shadowLines, { tool, points: [pos.x, pos.y], toolSize }]);
    }
  };

  const handleMouseUpBox = () => {
    isDrawing.current = false;
    updateMask();
  };

  const handleMouseMoveBox = (e: KonvaTouchMouseEvent) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    if (!stage) return;
    const point = stage.getPointerPosition();
    if (!point) return;
    const x = point.x - boundedBox.x;
    const y = point.y - boundedBox.y;

    setBoundedBox({ ...boundedBox, width: x, height: y });
    setShadowBoundedBox({ ...shadowBoundedBox, width: x, height: y });
  };

  const handleMouseDownBox = (e: KonvaTouchMouseEvent) => {
    isDrawing.current = true;
    const pos = e.target?.getStage()?.getPointerPosition();
    if (!pos) return;
    setBoundedBox({ ...boundedBox, x: pos.x, y: pos.y, width: 5, height: 5 });
    setShadowBoundedBox({ ...shadowBoundedBox, x: pos.x, y: pos.y, width: 5, height: 5 });
  };

  const clearCanvas = () => {
    setLines([]);
    setShadowLines([]);
    setBoundedBox({ ...boundedBox, width: 0, height: 0 });
    setShadowBoundedBox({ ...shadowBoundedBox, width: 0, height: 0 });
    props.setImageMask(undefined);
    setToolSize(defaultToolSize);
  };

  const handleToolChange = (tool: string) => {
    if (tool === 'clear') {
      clearCanvas();
      setTool('brush');
      return;
    }
    setTool(tool);
  };

  // precise icon styling needed depending on tool and due to some being FA icons and others SVGs.
  const getSelectedToolStyle = (toolName: string) => {
    const boundedBoxPadding = {
      paddingTop: '0px',
      paddingRight: '6px',
      paddingBottom: '0px',
      paddingLeft: '6px',
    };

    const sliderPadding = {
      paddingTop: '6px',
      paddingRight: '8px',
      paddingBottom: '6px',
      paddingLeft: '8px',
    };

    const faIconPadding = {
      padding: '8px',
    };

    let toolIconPadding;
    if (toolName === 'boundedBox') {
      toolIconPadding = boundedBoxPadding;
    } else if (toolName === 'slider') {
      toolIconPadding = sliderPadding;
    } else {
      toolIconPadding = faIconPadding;
    }

    const style: CSSProperties = {
      width: toolName === 'slider' ? '162px' : 'auto',
      ...toolIconPadding,
      borderRadius: '8px',
      boxShadow: 'none',
      border:
        (toolName === tool || (toolName === 'slider' && tool === 'brush') || (toolName === 'slider' && tool === 'eraser')) && !disabled
          ? `2px solid var(--interactive-primary, #6236FF)`
          : `2px solid var(--text-primary, #000000)`,
      color: editToolInactiveColor,
      backgroundColor: `var(--surface-primary, ${editToolBackgroundColor})`,
    };

    if (toolName === tool && !disabled) {
      style.color = editToolActiveColor;
      style.border = '1px';
      style.borderStyle = 'solid';
      style.borderColor = editToolActiveColor;
    }
    return style;
  };

  const handleMouseDown = (e: KonvaTouchMouseEvent) => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') {
        handleMouseDownBrush(e);
      }

      if (tool === 'boundedBox') {
        handleMouseDownBox(e);
      }
    }
  };

  const handleMouseUp = () => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') {
        handleMouseUpBrush();
      }

      if (tool === 'boundedBox') {
        handleMouseUpBox();
      }
    }
  };

  const handleMouseMove = (e: KonvaTouchMouseEvent) => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') {
        handleMouseMoveBrush(e);
      }

      if (tool === 'boundedBox') {
        handleMouseMoveBox(e);
      }
    }
  };

  return (
    <div className={styles.ImageEditingCanvasWrapper}>
      <div className={styles.editCanvasArea}>
        <div ref={divRef} style={{ width: '100%', height: imageDimensions.height }} data-testid={'studio-edit-image-canvas'}>
          <Stage
            width={imageDimensions.width}
            height={imageDimensions.height}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchStart={handleMouseDown}
            onTouchMove={handleMouseMove}
            onTouchEnd={handleMouseUp}
          >
            <Layer>
              <KonvaImage image={image} width={imageDimensions.width} height={imageDimensions.height} cornerRadius={10} />
            </Layer>
            {tool === 'brush' && <FreeEditSelection lines={lines} />}
            {tool === 'boundedBox' && <BoundedBoxSelection boundedBox={boundedBox} />}
            {tool === 'eraser' && <FreeEditSelection lines={lines} />}
          </Stage>
          {/* This object below is for the image mask being generated */}
          <Stage
            ref={shadowRef}
            width={actualDimensions.width}
            height={actualDimensions.height}
            style={{ border: '1px solid grey', marginBottom: '5px', display: 'none' }}
            scaleX={scale.scaleX}
            scaleY={scale.scaleY}
          >
            <Layer>
              <Rect x={0} y={0} width={imageDimensions.width} height={imageDimensions.height} fill="black"></Rect>
            </Layer>
            {tool === 'eraser' && <FreeEditSelection scale={scale.scaleX} opacity={1} lines={lines} stroke={'white'} />}
            {tool === 'brush' && <FreeEditSelection scale={scale.scaleX} opacity={1} lines={lines} stroke={'white'} />}
            {tool === 'boundedBox' && <BoundedBoxSelection boundedBox={shadowBoundedBox} />}
          </Stage>
        </div>
        <div className={styles.toolIconsWrapper}>
          <ThemeProvider theme={overrideMouseLeaveTimeout}>
            <Stack className={styles.toolIconsRow}>
              <Button
                data-testid="studio-style-container-toggle-button"
                type={ButtonTypes.Secondary}
                icon={iconTypes.boundingBox}
                iconOnly
                disabled={disabled}
                popoverText="Use a bounding box to select the area"
                onClick={() => {
                  handleToolChange('boundedBox');
                }}
                style={{ border: tool === 'boundedBox' && !disabled ? 'solid 2px var(--interactive-primary, #6236FF)' : '' }}
              />
              {!showBrushSlider && (
                <Button
                  dataTestId="image-modal-editing-draw"
                  type={ButtonTypes.Secondary}
                  icon={iconTypes.draw}
                  iconOnly
                  disabled={disabled}
                  popoverText="Use the drawing tool to select the area"
                  onClick={() => {
                    handleToolChange('brush');
                  }}
                  style={{ border: tool === 'brush' && !disabled ? 'solid 2px #6236FF' : '' }}
                />
              )}
              {showBrushSlider && (
                <div className={styles.sliderTool} style={getSelectedToolStyle('slider')}>
                  <Icon
                    data-testid="image-modal-editing-draw"
                    size={IconSize.medium}
                    type={iconTypes.draw}
                    style={sliderIconColors}
                    onClick={() => handleToolChange('brush')}
                  />
                  <Slider
                    value={toolSize || defaultToolSize}
                    min={minToolSize}
                    max={maxToolSize}
                    onChange={(value: number) => setToolSize(value)}
                    ariaLabelForHandle={'slider'}
                  />
                  <Icon
                    data-testid="image-modal-editing-draw"
                    size={IconSize.large}
                    type={iconTypes.draw}
                    style={sliderIconColors}
                    onClick={() => handleToolChange('brush')}
                  />
                </div>
              )}
              {!showEraserSlider && (
                <Button
                  dataTestId="image-modal-editing-erase"
                  aria-disabled={disabled}
                  type={ButtonTypes.Secondary}
                  icon={iconTypes.erase}
                  iconOnly
                  popoverText="Refine or remove a selected area"
                  disabled={disabled || (lines && lines.length === 0)}
                  onClick={() => {
                    handleToolChange('eraser');
                  }}
                  style={{ border: tool === 'eraser' && !disabled ? 'solid 2px #6236FF' : '' }}
                />
              )}
              {showEraserSlider && (
                <div className={styles.sliderTool} style={getSelectedToolStyle('slider')}>
                  <Icon
                    data-testid="image-modal-editing-erase"
                    aria-disabled={disabled}
                    size={IconSize.medium}
                    type={iconTypes.erase}
                    style={sliderIconColors}
                    onClick={() => handleToolChange('eraser')}
                  />
                  <Slider
                    value={toolSize || defaultToolSize}
                    min={minToolSize}
                    max={maxToolSize}
                    onChange={(value: number) => setToolSize(value)}
                    ariaLabelForHandle={'slider'}
                  />
                  <Icon size={IconSize.large} type={iconTypes.erase} style={sliderIconColors} onClick={() => handleToolChange('eraser')} />
                </div>
              )}
              <Button
                dataTestId="image-modal-editing-undo"
                type={ButtonTypes.Secondary}
                aria-disabled={disabled}
                icon={iconTypes.undo}
                iconOnly
                popoverText="Reset the selection area"
                disabled={disabled}
                onClick={() => {
                  handleToolChange('clear');
                }}
                style={{ border: tool === 'clear' && !disabled ? 'solid 2px #6236FF' : '' }}
              />
            </Stack>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
});
ImageEditingCanvas.displayName = 'ImageEditingCanvas';
export default ImageEditingCanvas;
