import { WorkflowId } from '@amzn/genaihub-typescript-client';
import type {
  ApparelWorkflowUserInputs,
  GenerativeResizingWorkflowUserInputs,
  GuidedTextGenerationWorkflowUserInputs,
  ImageEditorWorkflowUserInputs,
  ImageThemingWorkflowUserInputs,
  ImageToImport,
  ImportImageWorkflowUserInputs,
  LifestyleImageryWorkflowUserInputs,
  MultipleProductWorkflowUserInputs,
  ParallaxMotionWorkflowUserInputs,
  ProductEditWorkflowUserInputs,
  TextToImageWorkflowUserInputs,
  WorkflowUserInputs,
} from 'src/v2/contexts/assetGeneration/types/WorkflowUserInputs.types';
import type { PromptType } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { FrontendAsset, GenerateOptionType, WorkflowIdEnum } from 'src/v2/types';

/** ASSET GENERATION CONTEXT TYPES */
export interface AssetGenerationState {}

export enum AssetGenerationActionType {
  ABORT = 'ABORT',
  GENERATE_ASSETS = 'GENERATE_ASSETS',
  GENERATE_STUDIO_ASSETS = 'GENERATE_STUDIO_ASSETS',
  IMPORT_STUDIO_ASSET = 'IMPORT_STUDIO_ASSET',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AssetGenerationActionBase<TAssetGenerationActionType extends AssetGenerationActionType, TCompleteResponse = any, TOptions = any> {
  type: TAssetGenerationActionType;
  options?: TOptions;
  onInit?: (props: { id: string }) => void;
  onAbort?: () => void;
  onComplete?: (props: TCompleteResponse) => void;
  onError?: (err: Error) => void;
}

type BasicOnCompleteResponse = {
  generatedAssets: FrontendAsset[];
  errors: Error[];
};

export type AbortGenerationAction = AssetGenerationActionBase<AssetGenerationActionType.ABORT> & {
  generationRequestId: string;
};

export type GenerateAssetsAction = AssetGenerationActionBase<AssetGenerationActionType.GENERATE_ASSETS, BasicOnCompleteResponse> & {
  generationConfigs: GenerationConfig[];
};

export type GenerateStudioAssetsAction = AssetGenerationActionBase<AssetGenerationActionType.GENERATE_STUDIO_ASSETS, BasicOnCompleteResponse> & {
  accountType: AccountType | undefined;
  entityId: string | undefined;
  generateOptionOverride?: GenerateOptionType;
};

export type ImportStudioAssetAction = AssetGenerationActionBase<AssetGenerationActionType.IMPORT_STUDIO_ASSET, BasicOnCompleteResponse> & {
  accountType: AccountType | undefined;
  entityId: string | undefined;
  imageToImport: ImageToImport;
};

export type AssetGenerationAction = AbortGenerationAction | GenerateAssetsAction | GenerateStudioAssetsAction | ImportStudioAssetAction;

/** GENERATION CONFIG TYPES */
interface GenerationConfigBase<
  TWorkflowId extends WorkflowId,
  TWorkflowUserInputs extends WorkflowUserInputs,
  TOptions = undefined,
  TOutputCount = number,
> {
  entityId: string | undefined;
  options: TOptions;
  outputCount: TOutputCount;
  studioRequest: boolean;
  userInputs: TWorkflowUserInputs;
  workflowId: TWorkflowId;
}

/** EDIT GENERATION > GENERATIVE RESIZING **/
export type GenerativeResizingGenerationConfig = GenerationConfigBase<WorkflowIdEnum.GENERATIVE_RESIZING, GenerativeResizingWorkflowUserInputs>;

/** EDIT GENERATION > IMAGE EDITOR **/
export type ImageEditorGenerationConfig = GenerationConfigBase<WorkflowIdEnum.IMAGE_EDITOR, ImageEditorWorkflowUserInputs>;

/** EDIT GENERATION > IMAGE THEMING **/
// NOTE: outputCount is statically set to 4 in backend for IMAGE_THEMING workflow
export type ImageThemingGenerationConfig = GenerationConfigBase<WorkflowIdEnum.IMAGE_THEMING, ImageThemingWorkflowUserInputs, undefined, 4>;

/** EDIT GENERATION > PRODUCT EDIT **/
export type ProductEditGenerationConfig = GenerationConfigBase<WorkflowIdEnum.PRODUCT_EDIT, ProductEditWorkflowUserInputs>;

/** EDIT GENERATION > LIVE IMAGE (PARALLAX_MOTION) **/
export type LiveImageVideoGenerationConfig = GenerationConfigBase<WorkflowIdEnum.PARALLAX_MOTION, ParallaxMotionWorkflowUserInputs, undefined, 1>;

/** IMPORT_IMAGE GENERATION **/
interface UploadedImageGenerationConfigOptions {
  progressCallback?: (progress: number) => void;
}

export type UploadedImageGenerationConfig = GenerationConfigBase<
  WorkflowIdEnum.IMPORT_IMAGE,
  ImportImageWorkflowUserInputs,
  UploadedImageGenerationConfigOptions,
  undefined
>;

/** LIFESTYLE_IMAGE GENERATION **/
export type LifestyleImageGenerationConfig = GenerationConfigBase<WorkflowIdEnum.LIFESTYLE_IMAGERY, LifestyleImageryWorkflowUserInputs>;

/** PRODUCT_IMAGE GENERATION **/
export type ProductImageGenerationConfig =
  | GenerationConfigBase<WorkflowIdEnum.TEXT_TO_IMAGE, TextToImageWorkflowUserInputs>
  | GenerationConfigBase<WorkflowIdEnum.APPAREL, ApparelWorkflowUserInputs>;

export type MultipleProductImageGenerationConfig = GenerationConfigBase<WorkflowIdEnum.MULTI_PRODUCT, MultipleProductWorkflowUserInputs>;

/** TEXT_PROMPT GENERATION **/
interface TextPromptGenerationConfigOptions {
  promptType: PromptType;
  // The workflow using Text Prompt generation (ie: LIFESTYLE_IMAGERY, TEXT_TO_IMAGE)
  // - the related workflow influences the 'theme' WorkflowOption for GUIDED_TEXT_GENERATION
  relatedWorkflowId: WorkflowId | undefined;
  // The image reference ID for the given product. If not provided, the given product will be uploaded to get one.
  productImageReferenceId?: string;
}

export type TextPromptGenerationConfig = GenerationConfigBase<
  WorkflowIdEnum.GUIDED_TEXT_GENERATION,
  GuidedTextGenerationWorkflowUserInputs,
  TextPromptGenerationConfigOptions
>;

export type GenerationConfig =
  | GenerativeResizingGenerationConfig
  | ImageEditorGenerationConfig
  | ImageThemingGenerationConfig
  | ProductEditGenerationConfig
  | LiveImageVideoGenerationConfig
  | LifestyleImageGenerationConfig
  | ProductImageGenerationConfig
  | UploadedImageGenerationConfig
  | MultipleProductImageGenerationConfig;
