import { Text } from '@amzn/storm-ui';
import { HTMLAttributes } from 'react';
import PlayIcon from 'src/images/icons/playIcon.svg';
import { FeedTestIds } from 'src/v2/components/studio/feed/Feed.constants';
import styles from 'src/v2/components/studio/feed/Feed.module.scss';
import { FrontendAsset } from 'src/v2/types';
import { isImportedAsset, isVideoAsset } from 'src/v2/utils/FrontendAssetUtils';
import { getNormalizedClassNames } from 'src/v2/utils/utils';

interface FeedAssetsGroupItemNoHoverOverlayProps extends HTMLAttributes<HTMLDivElement> {
  asset: FrontendAsset;
}

export const FeedAssetsGroupItemNoHoverOverlay = (props: FeedAssetsGroupItemNoHoverOverlayProps) => {
  const { asset, className, ...restProps } = props;

  const rootClassNames = getNormalizedClassNames([styles.feedAssetNoHoverOverlay, className]);

  return (
    <div className={rootClassNames} data-testid={FeedTestIds.feedAssetNoHoverOverlay.rootContainer} {...restProps}>
      {isImportedAsset({ asset }) && (
        <div className={styles.uploadedBadge} data-testid={FeedTestIds.feedAssetNoHoverOverlay.uploadedBadge}>
          <Text renderAs="span">Uploaded</Text>
        </div>
      )}
      {isVideoAsset({ asset }) && (
        <div className={styles.videoPlayIcon} data-testid={FeedTestIds.feedAssetNoHoverOverlay.videoPlayIcon}>
          <PlayIcon />
        </div>
      )}
    </div>
  );
};
