import { BoundingBox } from '@amzn/genaihub-typescript-client';
import { AssetGeneration } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice.types';
import { AspectRatio, FrontendAsset, CameraPath, EditMode, EditSelection, ResizeDirection, StyleOption } from 'src/v2/types';

export interface EditSliceState {
  // The latest Asset Generation's ID, links to item in assetGeneration slice
  assetGenerationId: AssetGeneration['id'] | undefined;
  assetGenerationSelectedAssetIndex: number;
  assetModalView: AssetModalView;
  // The base Asset ID for editing, links to an asset in feed slice
  baseAssetId: FrontendAsset['id'] | undefined;
  // The currently selected EditMode in the modal
  editMode: EditMode;
  // The backend request ID for a publishToAssetLibrary backend context action
  publishToAssetLibraryBackendRequestId: string | undefined;
  // The backend request ID for a publishToFeed backend context action
  publishToFeedBackendRequestId: string | undefined;
  // Captures the currently selected settings for each EditMode
  settingsByEditMode: SettingsByEditMode;
}

export enum AssetModalView {
  EDIT = 'edit',
  PREVIEW = 'preview',
}

export type EditModeSettingsBase<TEditMode extends EditMode> = {
  editMode: TEditMode;
};

export type AddEditModeSettings = EditModeSettingsBase<EditMode.ADD> & {
  editSelection: EditSelection | undefined;
  textPrompt: string | undefined;
};

export type LiveEditModeSettings = EditModeSettingsBase<EditMode.LIVE> & {
  cameraPath: CameraPath;
  editSelection: EditSelection | undefined;
  motionIntensity: number;
  outDuration: number;
};

export type ReframeEditModeSettings = EditModeSettingsBase<EditMode.REFRAME> & {
  aspectRatio: AspectRatio | undefined;
  resizeDirection: ResizeDirection | undefined;
};

export type RemixEditModeSettings = EditModeSettingsBase<EditMode.REMIX> & {
  textPrompt: string | undefined;
};

export type RemoveEditModeSettings = EditModeSettingsBase<EditMode.REMOVE> & {
  editSelection: EditSelection | undefined;
};

export type RestyleEditModeSettings = EditModeSettingsBase<EditMode.RESTYLE> & {
  style: StyleOption | undefined;
  styleStrength: number;
};

export type ScaleEditModeSettings = EditModeSettingsBase<EditMode.SCALE> & {
  boundingBox: BoundingBox | undefined;
  originalBoundingBox: BoundingBox | undefined;
};

export type EditModeSettings =
  | AddEditModeSettings
  | LiveEditModeSettings
  | ReframeEditModeSettings
  | RemixEditModeSettings
  | RemoveEditModeSettings
  | RestyleEditModeSettings
  | ScaleEditModeSettings;

export type SettingsByEditMode = {
  [EditMode.ADD]: AddEditModeSettings;
  [EditMode.LIVE]: LiveEditModeSettings;
  [EditMode.REFRAME]: ReframeEditModeSettings;
  [EditMode.REMIX]: RemixEditModeSettings;
  [EditMode.REMOVE]: RemoveEditModeSettings;
  [EditMode.RESTYLE]: RestyleEditModeSettings;
  [EditMode.SCALE]: ScaleEditModeSettings;
};
