import { Asset, AssetList } from '@amzn/genaihub-typescript-client';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { Metrics } from 'src/constants';
import Arrow from 'src/images/icons/arrow.svg';
import styled, { css } from 'styled-components';
import AssetContentCard from './AssetContentCard';
import AssetDetails from './AssetDetails';
import './AssetGallery.scss';

export const TEST_ID_ASSET_GALLERY = 'asset-gallery';
export const TEST_ID_ASSET_GALLERY_LEFT_ARROW = 'asset-gallery-left-arrow';
export const TEST_ID_ASSET_GALLERY_RIGHT_ARROW = 'asset-gallery-right-arrow';

const SPACING_COEFFICIENT = 8;

const GridItem = styled.div<{ width?: number }>`
  box-sizing: border-box;
  margin: 0;
  aspect-ratio: 1;
  flex-grow: 0;

  ${({ width }) => css`
    max-width: ${width ? 100 / width : 100}%;
    flex-basis: ${width ? 100 / width : 100}%;
  `}
`;

const Grid = styled.div<{ spacing?: number }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;

  ${({ spacing }) =>
    spacing
      ? css`
          margin-top: -${spacing * SPACING_COEFFICIENT}px;
          width: calc(100% + ${spacing * SPACING_COEFFICIENT}px);
          margin-left: -${spacing * SPACING_COEFFICIENT}px;

          & > ${GridItem} {
            padding-top: ${spacing * SPACING_COEFFICIENT}px;
            padding-left: ${spacing * SPACING_COEFFICIENT}px;
          }
        `
      : ''}
`;

export interface AssetGalleryProps {
  assets: AssetList;
  columns: number;
  loading?: boolean;
  spacing?: number;
  detailsClickHandler?: (asset: Asset) => void;
}

export default function AssetGallery(props: AssetGalleryProps) {
  const { assets, columns, loading, spacing, detailsClickHandler } = props;
  const { metrics } = useContext(AppContext);
  const [currentAsset, setCurrentAsset] = useState<Asset>();
  const [showDetails, setShowDetails] = useState<boolean>(false);

  // open the asset details modal
  const assetClick = (asset: Asset, index: number) => {
    metrics.trackMetrics(
      Metrics.Methods.InperationPreview,
      { [Metrics.Names.Action]: Metrics.Actions.Open, index: index },
      { [Metrics.Counters.Clicks]: 1 },
    );
    setCurrentAsset(asset);
    setShowDetails(true);
  };

  // change the current asset details being shown
  const navigationCallback = (direction: string) => {
    if (currentAsset) {
      let index = assets.indexOf(currentAsset);
      switch (direction) {
        case 'left':
          index = index > 0 ? index - 1 : assets.length - 1;
          break;
        case 'right':
          index = index < assets.length - 1 ? index + 1 : 0;
          break;
      }
      setCurrentAsset(assets[index]);
    }
  };

  // check for left an right arrow press to change asset details
  const navigationKeyListener = (event: KeyboardEvent) => {
    if (navigationCallback) {
      switch (event.key) {
        case 'ArrowLeft':
          navigationCallback('left');
          break;
        case 'ArrowRight':
          navigationCallback('right');
          break;
      }
    }
  };

  // set key listeners so that arrow keys work
  useEffect(() => {
    window.addEventListener('keydown', navigationKeyListener);
    return () => {
      window.removeEventListener('keydown', navigationKeyListener);
    };
  }, [navigationKeyListener]);

  return (
    <div id="Asset-gallery" data-testid={TEST_ID_ASSET_GALLERY}>
      <Grid id="gallery" spacing={spacing}>
        {assets.map((asset, index) => (
          <GridItem key={asset.uri} width={columns}>
            <AssetContentCard asset={asset} clickHandler={() => assetClick(asset, index)} hover overlay />
          </GridItem>
        ))}
        {loading
          ? Array(columns * 2 + (assets.length % columns === 0 ? 0 : columns - (assets.length % columns)))
              .fill(<AssetContentCard />)
              .map((content, i) => (
                <GridItem key={i} width={columns}>
                  {content}
                </GridItem>
              ))
          : ''}
      </Grid>
      {showDetails && currentAsset && (
        <>
          <AssetDetails asset={currentAsset} showDetails={showDetails} setShowDetails={setShowDetails} clickHandler={detailsClickHandler} />
          {assets.length > 1 && (
            <>
              <div data-testid={TEST_ID_ASSET_GALLERY_LEFT_ARROW} className={`navigation left`} onClick={() => navigationCallback('left')}>
                <Arrow />
              </div>
              <div data-testid={TEST_ID_ASSET_GALLERY_RIGHT_ARROW} className={`navigation right`} onClick={() => navigationCallback('right')}>
                <Arrow />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
