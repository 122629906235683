import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { Metrics } from 'src/constants';
import DwellTimePublisher from 'src/metrics/DwellTimePublisher';

const BEFORE_UNLOAD_EVENT_NAME = 'beforeunload';
const VISIBILITY_CHANGE_EVENT_NAME = 'visibilitychange';
const VISIBLE_STATE_NAME = 'visible';
const HIDDEN_STATE_NAME = 'hidden';

/**
 * Input properties required for adding a WithNavigationMetrics wrapper
 */
interface WithNavigationMetricsProps {
  children: ReactNode;
  activePage: string;
}

/**
 * This is a wrapper class around the actual container that publishes the following common metrics:
 *  - Page dwell time
 *  - Page glance views
 */
export default function WithNavigationMetrics(props: WithNavigationMetricsProps) {
  const { activePage, children } = props;
  const { userDetails, accountType, selectedAdvertisingAccount, metrics } = useContext(AppContext);
  const [metricsPublisher, setMetricsPublisherState] = useState<KatalMetricsPublisher>(
    metrics.getMetrics().newChildActionPublisherForMethod(Metrics.Methods.PageMetrics),
  );
  const [dwellTimePublisher, setDwellTimePublisherState] = useState<DwellTimePublisher>();

  const setMetricsPublisher = () => {
    // Create a new metric action publisher
    const newPublisher = metrics.getMetrics().newChildActionPublisherForMethod(Metrics.Methods.PageMetrics);
    // Set the state
    setMetricsPublisherState(newPublisher);
    return newPublisher;
  };

  // Sets the a new dwell time publisher and new metric publisher
  const setDwellTimePublisher = () => {
    const newPublisher = setMetricsPublisher();
    // Create a new dwell time publisher
    const publisher = new DwellTimePublisher(newPublisher, Metrics.Names.UserDwellTime);
    // Set the state
    setDwellTimePublisherState(publisher);
  };

  // When a user views the page
  const publishPageHits = (): void => {
    metrics.trackMetrics(
      Metrics.Methods.PageMetrics,
      {},
      {
        [Metrics.Names.UserViewCount]: 1,
      },
    );
  };

  // When a user leaves the tab and returns
  const publishGlanceCount = (): void => {
    metrics.trackMetrics(
      Metrics.Methods.PageMetrics,
      {},
      {
        [Metrics.Names.UserGlanceCount]: 1,
      },
    );
  };

  // Publish dwell timer
  const publishDwellTime = (): void => {
    // pass in the current metricsPublisher just in case the app context changed
    dwellTimePublisher?.withMetrics(metricsPublisher).publish();
  };

  // Clean up listeners and publish
  const beforeUnloadHandler = (): void => {
    cleanupEventListeners();
    publishDwellTime();
  };

  // Pauses and resumes timer based on visibility
  const visibilityChangeHandler = (): void => {
    if (document.visibilityState === HIDDEN_STATE_NAME) {
      dwellTimePublisher?.pause();
    } else if (document.visibilityState === VISIBLE_STATE_NAME) {
      dwellTimePublisher?.resume();
      publishGlanceCount();
    }
  };

  // Clean up our listeners
  const cleanupEventListeners = (): void => {
    document.removeEventListener(VISIBILITY_CHANGE_EVENT_NAME, visibilityChangeHandler);
  };

  // runs when component is mounted; init listeners
  useEffect(() => {
    // Activate the event listener
    setupBeforeUnloadListener();
    document.addEventListener(VISIBILITY_CHANGE_EVENT_NAME, visibilityChangeHandler);

    // runs when component is unmounted; cleanup listeners
    return () => {
      beforeUnloadHandler();
    };
  }, []);

  // runs when the page or user changes
  useEffect(() => {
    // If there is an older dwell timer, publish it
    if (dwellTimePublisher) {
      publishDwellTime();
    }

    // Set a Dwell Time Publisher to be used based on page and user
    setDwellTimePublisher();
  }, [activePage]);

  // runs when the app context details change and updates metrics publisher
  useEffect(() => {
    setMetricsPublisher();
  }, [userDetails?.alias, accountType, selectedAdvertisingAccount]);

  // runs when the dwell publisher changes
  useEffect(() => {
    // Start new Dwell timer and record glance
    if (dwellTimePublisher) {
      dwellTimePublisher?.start();
      publishPageHits();
    }
  }, [dwellTimePublisher]);

  // Setup the `beforeunload` event listener to track metrics
  const setupBeforeUnloadListener = () => {
    window.addEventListener(BEFORE_UNLOAD_EVENT_NAME, () => {
      beforeUnloadHandler();
    });
  };

  // Just render the children
  return <>{children}</>;
}
