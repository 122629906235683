import { HTMLAttributes, memo, useCallback, useRef, useState } from 'react';
import { AssetProps } from 'src/v2/components/common/asset/Asset.types';
import { AssetImage } from 'src/v2/components/common/asset/AssetImage';
import { AssetVideo } from 'src/v2/components/common/asset/AssetVideo';
import { FeedAssetsGroupItemNoHoverOverlay } from 'src/v2/components/studio/feed/_partials/FeedAssetsGroupItemNoHoverOverlay';
import { FeedAssetsGroupItemOverlay } from 'src/v2/components/studio/feed/_partials/FeedAssetsGroupItemOverlay';
import { FeedTestIds } from 'src/v2/components/studio/feed/Feed.constants';
import styles from 'src/v2/components/studio/feed/Feed.module.scss';
import { FrontendAsset } from 'src/v2/types';
import { isImageAsset, isVideoAsset } from 'src/v2/utils/FrontendAssetUtils';
import { getNormalizedClassNames } from 'src/v2/utils/utils';

interface FeedAssetsGroupItemProps extends HTMLAttributes<HTMLDivElement> {
  asset: FrontendAsset;
}

const useFeedAssetsGroupItem = (props: FeedAssetsGroupItemProps) => {
  const { asset, className, ...restProps } = props;
  const [assetLoaded, setAssetLoaded] = useState(false);
  const rootClassNames = getNormalizedClassNames([
    styles.feedItemContainer,
    styles.feedAssetContainer,
    !assetLoaded ? styles.assetLoading : undefined,
    className,
  ]);

  const onAssetLoaded = useCallback(() => {
    setAssetLoaded(true);
  }, [setAssetLoaded]);

  return {
    asset,
    rootClassNames,
    restProps,
    onAssetLoaded,
  };
};

const FeedAssetsGroupItemImage = memo((props: FeedAssetsGroupItemProps) => {
  const { asset, rootClassNames, restProps, onAssetLoaded } = useFeedAssetsGroupItem(props);
  const assetImageOptions: AssetProps['options'] = {
    testId: FeedTestIds.feedAssetImage,
    loadingPlaceholder: {
      testId: FeedTestIds.feedAssetLoadingPlaceholder,
    },
  };

  return (
    <div className={rootClassNames} data-testid={FeedTestIds.feedAsset} data-test-aspect-ratio={asset.aspectRatio} {...restProps}>
      <FeedAssetsGroupItemOverlay asset={asset} />
      <FeedAssetsGroupItemNoHoverOverlay asset={asset} />
      <AssetImage asset={asset} className={styles.feedAsset} onLoad={onAssetLoaded} options={assetImageOptions} />
    </div>
  );
});
FeedAssetsGroupItemImage.displayName = 'FeedAssetsGroupItemImage';

const FeedAssetsGroupItemVideo = memo((props: FeedAssetsGroupItemProps) => {
  const { asset, rootClassNames, restProps, onAssetLoaded } = useFeedAssetsGroupItem(props);
  const assetVideoOptions: AssetProps['options'] = {
    testId: FeedTestIds.feedAssetVideo,
    loadingPlaceholder: {
      testId: FeedTestIds.feedAssetLoadingPlaceholder,
    },
  };

  const videoAssetRef = useRef<HTMLVideoElement>(null);

  const onMouseEnter = useCallback(() => {
    if (videoAssetRef.current) {
      videoAssetRef.current.play().catch((error) => console.error(`Error while playing video asset '': `, error));
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    if (videoAssetRef.current) {
      videoAssetRef.current.pause();
    }
  }, []);

  return (
    <div
      className={rootClassNames}
      data-testid={FeedTestIds.feedAsset}
      data-test-aspect-ratio={asset.aspectRatio}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...restProps}
    >
      <FeedAssetsGroupItemOverlay asset={asset} />
      <FeedAssetsGroupItemNoHoverOverlay asset={asset} />
      <AssetVideo ref={videoAssetRef} asset={asset} className={styles.feedAsset} onLoad={onAssetLoaded} options={assetVideoOptions} />
    </div>
  );
});
FeedAssetsGroupItemVideo.displayName = 'FeedAssetsGroupItemVideo';

export const FeedAssetsGroupItem = memo((props: FeedAssetsGroupItemProps) => {
  const { asset } = props;
  return (
    <>
      {isImageAsset({ asset }) && <FeedAssetsGroupItemImage {...props} />}
      {isVideoAsset({ asset }) && <FeedAssetsGroupItemVideo {...props} />}
    </>
  );
});
FeedAssetsGroupItem.displayName = 'FeedAssetsGroupItem';
