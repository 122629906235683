import { useMutation, useQueries } from '@tanstack/react-query';
import { produce } from 'immer';
import React, { createContext, useReducer, useRef, useState } from 'react';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { handleRetrieveInspirationsListAction } from 'src/v2/contexts/backend/actions/RetrieveInspirationsList';
import { handleRetrieveToolsListAction } from 'src/v2/contexts/backend/actions/RetrieveToolsList';
import { handleRetrieveWorkflowsListAction } from 'src/v2/contexts/backend/actions/RetrieveWorkflowsList';
import { handleAbortAction, handleAbortByGroupIdAction } from './actions/Abort';
import { DeleteAssetMutationConfig, handleDeleteAssetAction } from './actions/DeleteAsset';
import { FileUploadGetPresignMutationConfig, handleFileUploadGetPresignAction } from './actions/FileUploadGetPresign';
import { handleGetUserDetailsAction } from './actions/GetUserDetails';
import { handleGetUserPermissionsAction } from './actions/GetUserPermissions';
import { handlePublishAssetToAssetLibraryAction, PublishAssetToAssetLibraryMutationConfig } from './actions/PublishAssetToAssetLibrary';
import { handlePublishAssetToFeedAction, PublishAssetToFeedMutationConfig } from './actions/PublishAssetToFeed';
import { handleQueryAdvertisingAccountsAction } from './actions/QueryAdvertisingAccounts';
import { handleRetrieveAsinMetadataByAsinIdAction } from './actions/RetrieveAsinMetadataByAsinId';
import { handleRetrieveAssetAction } from './actions/RetrieveAsset';
import { handleRetrieveAssetsAction } from './actions/RetrieveAssets';
import { handleRetrieveResultByWorkflowIdAndBatchIdAction } from './actions/RetrieveResultByWorkflowIdAndBatchId';
import { handleSubmitFeedbackSentimentAction, SubmitFeedbackSentimentMutationConfig } from './actions/SubmitFeedbackSentiment';
import { handleSubmitReportingReportAction, SubmitReportingReportMutationConfig } from './actions/SubmitReportingReport';
import { SubmitWorkflowByIdMutationConfig, handleSubmitWorkflowByIdAction } from './actions/SubmitWorkflowById';
import { BackendState } from './types/BackendContext.types';
import { BackendAction, BackendActionType, BackendQueryOptionsType, RequestsLookup } from './types/BackendContextActions.types';

export const useBackendContext = () => {
  const backendClient = useAIBackendHubClient();
  const [queries, setQueries] = useState<BackendQueryOptionsType[]>([]);
  const deleteAssetMutation = useMutation(DeleteAssetMutationConfig);
  const publishAssetToAssetLibraryMutation = useMutation(PublishAssetToAssetLibraryMutationConfig);
  const publishAssetToFeedMutation = useMutation(PublishAssetToFeedMutationConfig);
  const submitFeedbackSentimentMutation = useMutation(SubmitFeedbackSentimentMutationConfig);
  const submitReportingReportMutation = useMutation(SubmitReportingReportMutationConfig);
  const submitWorkflowByIdMutation = useMutation(SubmitWorkflowByIdMutationConfig);
  const fileUploadGetPresignMutation = useMutation(FileUploadGetPresignMutationConfig);
  const requestsLookup = useRef<RequestsLookup>({ byGroup: {}, byId: {} });

  useQueries({ queries });

  const [, dispatch] = useReducer(
    produce((state: BackendState, action: BackendAction) => {
      switch (action.type) {
        case BackendActionType.ABORT:
          handleAbortAction({
            action,
            requestsLookup: requestsLookup.current,
          });
          break;
        case BackendActionType.ABORT_BY_GROUP_ID:
          handleAbortByGroupIdAction({
            action,
            requestsLookup: requestsLookup.current,
          });
          break;
        case BackendActionType.DELETE_ASSET:
          handleDeleteAssetAction({
            action,
            backendClient,
            deleteAssetMutation,
            requestsLookup: requestsLookup.current,
          });
          break;
        case BackendActionType.FILE_UPLOAD_GET_PRESIGN:
          handleFileUploadGetPresignAction({
            action,
            backendClient,
            fileUploadGetPresignMutation,
            requestsLookup: requestsLookup.current,
          });
          break;
        case BackendActionType.GET_USER_DETAILS:
          handleGetUserDetailsAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.GET_USER_PERMISSIONS:
          handleGetUserPermissionsAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.PUBLISH_ASSET_TO_ASSET_LIBRARY:
          handlePublishAssetToAssetLibraryAction({
            action,
            backendClient,
            publishAssetToAssetLibraryMutation,
            requestsLookup: requestsLookup.current,
          });
          break;
        case BackendActionType.PUBLISH_ASSET_TO_FEED:
          handlePublishAssetToFeedAction({
            action,
            backendClient,
            publishAssetToFeedMutation,
            requestsLookup: requestsLookup.current,
          });
          break;
        case BackendActionType.QUERY_ADVERTISING_ACCOUNTS:
          handleQueryAdvertisingAccountsAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.RETRIEVE_ASIN_METADATA_BY_ASIN_ID:
          handleRetrieveAsinMetadataByAsinIdAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.RETRIEVE_ASSET:
          handleRetrieveAssetAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.RETRIEVE_ASSETS:
          handleRetrieveAssetsAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.RETRIEVE_INSPIRATIONS_LIST:
          handleRetrieveInspirationsListAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.RETRIEVE_RESULT_BY_WORKFLOW_ID_AND_BATCH_ID:
          handleRetrieveResultByWorkflowIdAndBatchIdAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.RETRIEVE_TOOLS_LIST:
          handleRetrieveToolsListAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.RETRIEVE_WORKFLOWS_LIST:
          handleRetrieveWorkflowsListAction({
            action,
            backendClient,
            requestsLookup: requestsLookup.current,
            setQueries,
          });
          break;
        case BackendActionType.SUBMIT_FEEDBACK_SENTIMENT:
          handleSubmitFeedbackSentimentAction({
            action,
            backendClient,
            submitFeedbackSentimentMutation,
            requestsLookup: requestsLookup.current,
          });
          break;
        case BackendActionType.SUBMIT_REPORTING_REPORT:
          handleSubmitReportingReportAction({
            action,
            backendClient,
            submitReportingReportMutation,
            requestsLookup: requestsLookup.current,
          });
          break;
        case BackendActionType.SUBMIT_WORKFLOW_BY_ID:
          handleSubmitWorkflowByIdAction({
            action,
            backendClient,
            submitWorkflowByIdMutation,
            requestsLookup: requestsLookup.current,
          });
          break;
        default:
          console.error(new Error(`Unsupported backend action: '${action}'`));
      }
    }),
    {},
  );

  return React.useMemo(() => ({ dispatch }), []);
};

export type BackendDispatchContextType = ReturnType<typeof useBackendContext>['dispatch'];
export const BackendDispatchContext = createContext({} as BackendDispatchContextType);

export const BackendContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { dispatch } = useBackendContext();
  return <BackendDispatchContext.Provider value={dispatch}>{children}</BackendDispatchContext.Provider>;
};
