import { ControlValue } from '@amzn/genaihub-typescript-client';
import { useContext, useEffect } from 'react';
import { EditorContextP1, EditorContextStateP1 } from 'src/components/editor/EditorContext';
import { ControlComponentProps } from 'src/components/editor/UiContols/uiGeneratorControls/controlComponentTypes';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import guideHelper from 'src/components/utils/guideHelper';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import styled from 'styled-components';

interface TileListProps {
  disableControl?: boolean;
  showBorder?: boolean;
  darkMode?: boolean;
}

const TileListContainer = styled.div<Partial<TileListProps>>`
  color: var(--text-primary, #000000);
  background-color: var(--surface-secondary, #f2f4f6);
  border-radius: 12px;
  height: 140px;
  width: 100%;
  cursor: ${(props) => (props.disableControl ? 'not-allowed' : 'pointer')};
  border: ${(props) => (props.showBorder ? (props.darkMode ? '2px solid var(--interactive-primary)' : '2px solid #4305F4') : 'none')};
  overflow: hidden;
`;

const TileList = (props: ControlComponentProps) => {
  const { control, bulkUpdateCallback } = props;
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = isDarkModeLaunched && useAppSelector(isDarkModeActive);

  const editorContext: EditorContextStateP1 = useContext(EditorContextP1);
  let controlValues = editorContext.workflowOptions;

  useEffect(() => {
    guideHelper(control.controlValues, editorContext.activateGuide, guideCallback);
  }, [editorContext.activateGuide]);

  useEffect(() => {
    // set default
    const controlName = control.controlName;
    if (!controlValues[controlName]) {
      const controlData = { controlName: { value: control.defaultValue } };
      editorContext.setWorkFlowOptions({ ...controlData });
    }
  }, [control]);

  const guideCallback = (config: ControlValue | undefined) => {
    if (config) {
      batchStateUpdate({ [control.controlName]: { value: config.value } }, bulkUpdateCallback);
    }
  };

  const clickHandler = (value: string) => {
    if (!editorContext.disableControl) {
      const controlData = { [control.controlName]: { value: value } };

      editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
    }
  };

  const controlValue = controlValues[control.controlName]?.value || control?.defaultValue;

  const Tile = ({ thumbnail, label, value }: { thumbnail: string; label: string; value: string }) => {
    return (
      <TileListContainer
        data-testid="TileList"
        onClick={() => clickHandler(value)}
        disableControl={editorContext.disableControl}
        showBorder={controlValue === value && !editorContext.disableControl}
        darkMode={isDarkMode}
      >
        <svg width={'100%'} height={'80%'}>
          <image xlinkHref={thumbnail} width="100%" height="100%" style={{ objectFit: 'contain', height: 'auto' }} />
        </svg>
        <div
          style={{
            fontFamily: 'Amazon Ember',
            fontSize: '13px',
            fontWeight: '500',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {label}
        </div>
      </TileListContainer>
    );
  };
  return (
    <>
      <ControlLabel title={control.controlLabel} />
      <div style={{ display: 'grid', gridTemplateColumns: '31% 31% 31%', width: '100%', gridColumnGap: '4%', gridRowGap: '15px' }}>
        {control.controlValues &&
          control.controlValues.map((cv: ControlValue, index: number) => (
            <Tile key={`tile${index}`} thumbnail={cv.thumbnail ?? ''} label={cv.label ?? ''} value={cv.value ?? ''} />
          ))}
      </div>
    </>
  );
};

export default TileList;
