import { Text } from '@amzn/storm-ui';
import React, { useEffect, useRef } from 'react';
import { PrimaryButton } from 'src/components/_common/buttons/PrimaryButton';
import aiLabTheme from 'src/config/themes/aiLabThemeV2';

import image2 from 'src/images/hero/editing.webp';
import Pointer from 'src/images/hero/pointer.svg';
import Rocket from 'src/images/hero/rocket.svg';
import Stars from 'src/images/hero/stars.svg';
import image1 from 'src/images/hero/styles.webp';
import image3 from 'src/images/hero/unlimited.webp';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import styled, { css, keyframes } from 'styled-components';
import { SIGNIN_TEXT, signinClickHandler } from '../common/SignInButton';

const Container = styled.div`
  font-family: 'Ember Modern Display Standard';
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const Lockup = styled.div`
  max-width: 700px;
  margin-top: 23px;
  margin-left: 0px;
  margin-right: 50px;
  flex-grow: 1;
  font-size: 88px;
  @media (max-width: 700px) {
    font-size: 72px;
    margin: 0;
    min-width: unset;
  }

  @media (max-width: 600px) {
    font-size: 54px;
  }
`;

const Headline = styled.div`
  font-size: 1em;
  line-height: 1em;
  font-weight: 700;
  margin-bottom: 30px;
`;

const Gradient = styled.span<{ gradient?: string; darkMode: boolean }>`
  background: linear-gradient(${({ gradient }) => (gradient ? gradient : '105.82deg, #6236FF 11.04%, #F74222 72.96%, #FFEF42 100%')});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: ${({ darkMode }) => (darkMode ? 'none' : 'transparent')};
  text-fill-color: ${({ darkMode }) => (darkMode ? 'none' : 'transparent')};
  color: rgba(0, 0, 0, 0);
`;

const Icons = styled.div`
  min-height: 102px;
  margin-bottom: 22px;
  display: flex;
  flex-wrap: wrap;
`;

const Icon = styled.div`
  --margin: 40px;
  position: relative;
  width: calc(33% - var(--margin));
  max-width: 150px;
  min-width: 70px;
  margin-right: var(--margin);
  padding-top: 15px;
  padding-left: 3px;
  cursor: pointer;

  & > svg {
    display: block;
    width: 24px;
    height: 24px;

    & path {
      fill: var(--text-primary, rgb(67, 5, 244));
      stroke: var(--text-primary, rgb(67, 5, 244));
    }
  }

  @media (max-width: 600px) {
    --margin: 15px;
  }
`;

const IconText = styled(Text)<{ darkMode: boolean }>`
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  text-align: left;
  margin-top: 12px;
  margin-bottom: 20px;

  && span {
    background: ${({ darkMode }) => (darkMode ? 'transparent' : '')};
    color: ${({ darkMode }) => (darkMode ? 'var(--text-primary)' : '')};
  }

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const PillFill = keyframes`
  to {
    width: 100%;
  }
`;

const Pill = styled.span<{ active?: boolean; delay: number; darkMode?: boolean }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
  background-color: #e6e9ed;
  margin: 0;
  display: inline-block;
  overflow: hidden;
  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 0;
    clear: both;
  }
  ${({ active, delay, darkMode }) =>
    active &&
    css`
      &&:after {
        background-color: ${darkMode ? 'var(--interactive-primary)' : aiLabTheme.title.secondary};
        animation: ${PillFill} ${delay}s linear;
      }
    `}
`;

const SignInButton = styled(PrimaryButton)<{ showAtBreakpoint?: boolean; hideAtBreakpoint?: boolean }>`
  && {
    font-size: 16px;
    background: linear-gradient(90deg, #4305f4 0%, #8631ee 100%);
    padding: 12px 41px;
    border-radius: 50px;
    margin-top: 53px;
    margin-bottom: 20px;

    ${({ showAtBreakpoint }) =>
      showAtBreakpoint &&
      css`
        display: none;
      `}

    @media (max-width: 1328px) {
      width: 100%;
      margin-bottom: 0;

      ${({ hideAtBreakpoint }) =>
        hideAtBreakpoint &&
        css`
          display: none;
        `}

      ${({ showAtBreakpoint }) =>
        showAtBreakpoint &&
        css`
          display: flex;
        `}
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  min-width: 50%;
  max-width: 750px;
  width: 50%;
  aspect-ratio: 664 / 486;
  flex-grow: 1;
  border-radius: 18px;
  overflow: hidden;
  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 700px) {
    width: 100%;
    max-width: 100%;
    min-width: unset;
    min-height: unset;
  }
`;

const AnimatePrev = keyframes`
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
`;

const AnimateNext = keyframes`
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
`;

const Item = styled.div<{ placement?: string }>`
  position: absolute;
  left: -100%;
  top: 0;
  ${({ placement }) => css`
    animation: ${placement === 'active' ? AnimateNext : AnimatePrev} 0.5s both ease-in-out;
  `}%;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;

const items = [image1, image2, image3];

const DEFAULT_DELAY = 5000;

const HeroUnauthenticated = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  /* eslint-disable-next-line no-undef */
  const interval = useRef<NodeJS.Timeout>();
  const delay = DEFAULT_DELAY;
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = isDarkModeLaunched && useAppSelector(isDarkModeActive);

  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % items.length);
    }, delay || DEFAULT_DELAY);
    return () => clearInterval(interval.current);
  }, []);

  function changeIndex(index: number) {
    clearInterval(interval.current);
    setCurrentIndex(index);
    interval.current = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % items.length);
    }, delay || DEFAULT_DELAY);
  }

  return (
    <Container>
      <Lockup>
        <Headline>
          Make standout <br />
          <Gradient darkMode={isDarkMode}>creative assets</Gradient>
        </Headline>
        <Icons>
          <Icon onClick={() => changeIndex(0)}>
            <Stars />
            <IconText darkMode={isDarkMode}>
              <Gradient gradient="287.45deg, #4305F4 25.51%, #1F0078 134.69%" darkMode={isDarkMode}>
                Supercharged inspiration
              </Gradient>
            </IconText>
            <Pill active={currentIndex === 0} delay={(delay || DEFAULT_DELAY) / 1000} darkMode={isDarkMode} />
          </Icon>
          <Icon onClick={() => changeIndex(1)}>
            <Pointer />
            <IconText darkMode={isDarkMode}>
              <Gradient gradient="287.45deg, #4305F4 25.51%, #1F0078 134.69%" darkMode={isDarkMode}>
                Powerful editing tools
              </Gradient>
            </IconText>
            <Pill active={currentIndex === 1} delay={(delay || DEFAULT_DELAY) / 1000} darkMode={isDarkMode} />
          </Icon>
          <Icon onClick={() => changeIndex(2)}>
            <Rocket />
            <IconText darkMode={isDarkMode}>
              <Gradient gradient="287.45deg, #4305F4 25.51%, #1F0078 134.69%" darkMode={isDarkMode}>
                Unlimited asset generation
              </Gradient>
            </IconText>
            <Pill active={currentIndex === 2} delay={(delay || DEFAULT_DELAY) / 1000} darkMode={isDarkMode} />
          </Icon>
        </Icons>
        <SignInButton data-testid="hero-login-button-large-viewport" onClick={signinClickHandler} hideAtBreakpoint={true}>
          {SIGNIN_TEXT}
        </SignInButton>
      </Lockup>
      <ImageContainer data-testid="hero-image-slide">
        {items.map((item, index) => (
          <Item key={index} placement={index === currentIndex ? 'active' : 'prev'}>
            <img src={item} alt={`Home Page Hero Image ${index + 1}`} />
          </Item>
        ))}
      </ImageContainer>
      <SignInButton
        data-testid="hero-login-button-small-viewport"
        style={{ maxWidth: '750px', marginTop: '34px' }}
        onClick={signinClickHandler}
        showAtBreakpoint={true}
      >
        {SIGNIN_TEXT}
      </SignInButton>
    </Container>
  );
};

export default HeroUnauthenticated;
