import { IconDefinition } from '@amzn/storm-ui-icons';
import { IconName } from 'src/v2/components/Icon/iconTypes';

export enum WidgetViews {
  Collapsed = 'collapsed',
  Expanded = 'expanded',
  Full = 'full',
}

export interface WidgetCommonProps {
  closeWidget: (arg?: 'cancel') => void;
  openWidget?: (height: number) => void;
  dataTestId: string;
  height: number;
}

export enum WidgetTypes {
  AspectRatio = 'aspectRatio',
  Effects = 'effects',
  Reference = 'reference',
  Product = 'product',
  Styling = 'styling',
}

export enum WidgetViewHeaderLabels {
  AspectRatio = 'Aspect ratio',
  Effects = 'Effects',
  ReferenceImages = 'Reference images',
  Product = 'Products',
  Styling = 'Styles',
}

export interface WidgetHeaderProps extends Omit<WidgetCommonProps, 'height'> {
  label: WidgetViewHeaderLabels;
  icon?: IconDefinition | IconName;
  disabled?: boolean;
}

export interface ProductWidgetProps extends WidgetCommonProps {
  // widget specific props
  loading?: boolean;
}

export interface CreativeBriefWidgetProps extends WidgetCommonProps {
  // widget specific props
}

export interface ReferenceWidgetProps extends WidgetCommonProps {
  // widget specific props
}

export interface EffectsWidgetProps extends WidgetCommonProps {
  // widget specific props
}

export interface StylingWidgetProps extends WidgetCommonProps {
  // widget specific props
}

export interface AspectRatioWidgetProps extends WidgetCommonProps {
  // widget specific props
}
