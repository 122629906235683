import { Breakpoints } from 'src/hooks/useBreakpoints';
import { FEED_GAP } from 'src/v2/components/studio/feed/Feed.constants';
import styled from 'styled-components';

export const FeedContainer = styled.div`
  height: 100%;
  max-width: 100vw;
  padding: 20px;
  padding-bottom: 190px;

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: ${FEED_GAP}px;

  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;

  @media (max-width: ${Breakpoints.md}px) {
    padding-bottom: 240px;
  }
`;
