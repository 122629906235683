import { Icon } from '@amzn/storm-ui';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { AnimatedButton } from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import themesConfig from 'src/components/editor/UiContols/uiGeneratorControls/themes.json';
import style from 'src/components/imageModal/components/ImageModalStyleSetting.module.scss';
import { ImageModalContext, ImageModalContextType } from 'src/components/imageModal/ImageModalContext';
import { StyleAction } from 'src/components/imageModal/types';
import { ALL_STYLES, getFilteredStyles } from 'src/components/utils/styleUtils';
import themes from 'src/config/themes/themesDropdownList';
import { HEADING_TEXT_SIZE } from 'src/constants';
import { useWorkflow } from 'src/hooks/useWorkflow';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import { CategoryEnum, WorkflowIdEnum } from 'src/v2/types';
import RangeSlider, { RangeSliderProps } from './inputs/RangeSlider';

const ImageModalStyleSetting = () => {
  const STYLE_MOTION_CONFIG: RangeSliderProps = {
    title: 'Strength',
    min: 0.5,
    minLabel: 'Less',
    max: 1.0,
    maxLabel: 'More',
    step: 0.01,
    defaultValue: 0.85,
  };

  const [list, setList] = useState(themesConfig);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const { activePage, userDetails } = useContext(AppContext);
  const [theme, setTheme] = useState<ImageModalContextType['theme']>();
  const [themeStrength, setThemeStrength] = useState<number>(STYLE_MOTION_CONFIG.defaultValue!);
  const categories = ['Popular', 'Holidays', 'Location', 'Abstract'];
  const icons: Record<string, string> = { ...themes };
  const imageModalContext = useContext(ImageModalContext);
  const { imageReferenceId, imageUrl, savedEditsImageUrl, savedEditsImageId, setActiveEditResults, setActiveEditErrorMessage, setPendingGeneration } =
    useContext(ImageModalContext);
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = isDarkModeLaunched && useAppSelector(isDarkModeActive);

  const imageRef = savedEditsImageUrl === '' && !imageUrl.includes('catwalk-generated-assets') ? imageReferenceId : savedEditsImageUrl || imageUrl;

  const {
    updateWorkflowOptions,
    submissionQuery: { data: result },
    submitWorkflow,
    isPending,
    error,
    isError,
  } = useWorkflow<StyleAction>({
    workflowId: WorkflowIdEnum.IMAGE_THEMING,
    userAlias: userDetails?.alias,
    pageName: activePage,
    defaultWorkflowOptions: {
      theme: theme?.value || 'no_theme',
      input_image: '2770e255-1253-46a0-8524-32fc98a757a3', // needed for testing purposes only on local environment
      imageId: savedEditsImageId,
      strength: themeStrength,
    },
    setPendingGeneration,
  });

  useEffect(() => {
    if (result) {
      setActiveEditResults(result);
    } else {
      setActiveEditResults(undefined);
    }
  }, [result?.jobs?.[0].status]);

  useEffect(() => {
    updateWorkflowOptions({ imageId: savedEditsImageId });
  }, [savedEditsImageId]);

  useEffect(() => {
    if (isError) {
      setActiveEditErrorMessage(error?.message);
      console.error('Error while generating results', error);
    }
  }, [isError]);

  const handleStyleSelection = (id: string) => {
    const selectedStyle = list.find((item) => item.controlValue === id);
    if (selectedStyle) {
      setTheme({
        value: selectedStyle.controlValue,
        label: selectedStyle.controlLabel,
        icon: icons[selectedStyle.src],
      });

      updateWorkflowOptions({ theme: selectedStyle.controlValue });
    }
  };

  const handleStrengthSelection = (value: number) => {
    setThemeStrength(value);
    updateWorkflowOptions({ strength: value });
  };

  const searchHandler = (value: string) => {
    setList(getFilteredStyles(value));
  };

  const inputChangeHandler = (value: string) => {
    searchHandler(value);
    setSelectedCategory(ALL_STYLES);
  };

  const categoryClickHandler = (category: string) => {
    if (category === selectedCategory) {
      setSelectedCategory(ALL_STYLES);
      searchHandler(ALL_STYLES);
    } else {
      setSelectedCategory(category);
      searchHandler(category);
    }
  };

  const generateResults = async () => {
    imageModalContext.clearActiveEdit();
    await submitWorkflow({
      input_image: {
        urlOrFile: imageRef,
        contentCategory: CategoryEnum.PRODUCT_IMAGE,
        fileTypeOverride: 'image/png',
      },
    });
  };

  return (
    <div className={style.container}>
      <ControlLabel
        title="Preset Styles"
        subTitle="To update your image with new locations, studio backgrounds, or holiday themes, choose a style and generate."
        titleSize={HEADING_TEXT_SIZE.SMALL}
      />
      <div className={style.scrollContainer}>
        <div className={style.scrollInputs}>
          <div className={style.searchBar}>
            <input
              placeholder="Search by keyword"
              onChange={(event) => inputChangeHandler(event.target.value)}
              data-testid="studio-edit-restyle-search-input"
            />
            <Icon type={iconTypes.search} />
          </div>
          <div className={style.pills}>
            {categories.map((category) => (
              <div
                key={category}
                onClick={() => categoryClickHandler(category)}
                className={`${style.pillButton} ${selectedCategory == category ? style.selected : ''}`}
              >
                {category}
              </div>
            ))}
          </div>
          <div className={style.tiles}>
            {list.map((item) => {
              return (
                <div
                  key={item.controlValue}
                  onClick={() => handleStyleSelection(item.controlValue)}
                  className={style.tileItem}
                  data-testid={`studio-edit-restyle-style-${item.controlValue}`}
                >
                  <div
                    className={`${style.tileItemIcon} ${theme?.value == item.controlValue ? (isDarkMode ? style.selected_ux2 : style.selected) : ''}`}
                    style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.05) 51%, rgba(0, 0, 0, 0.5) 82%), url(${icons[item.src]})` }}
                  ></div>
                  <div className={style.tileItemLabel}>{item.controlLabel}</div>
                </div>
              );
            })}
          </div>
          <RangeSlider {...STYLE_MOTION_CONFIG} onChange={handleStrengthSelection} disabled={!theme?.value || isPending} />
        </div>
      </div>
      <div className={style.generateButtonWrapper}>
        <AnimatedButton
          dataTestId="studio-edit-generate-button"
          animate={isPending}
          disabled={!theme?.value || isPending}
          clickHandler={() => generateResults()}
          text={isPending ? 'Generating' : 'Generate'}
          fullscreen
        ></AnimatedButton>
      </div>
    </div>
  );
};

export default ImageModalStyleSetting;
