import { BoundingBox, ProductAsset } from '@amzn/genaihub-typescript-client';
import { ThemeProvider } from '@amzn/storm-ui';
import { UseQueryResult } from '@tanstack/react-query';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { AnimatedButton } from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import { overrideMouseLeaveTimeout } from 'src/components/imageModal/components/inputs/ImageEditingCanvas';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { ProductEditAction } from 'src/components/imageModal/types';
import { HEADING_TEXT_SIZE } from 'src/constants';
import { useWorkflow } from 'src/hooks/useWorkflow';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { WorkflowIdEnum } from 'src/v2/types';
import styles from './PositionControls.modules.scss';
import { ProductPositionCanvas } from './ProductPositionCanvas';

export const POSITION_CONTROL_RESET_BTN_TEST_ID = 'studio-edit-reset-btn';
export const PositionControls: FC<{ query: UseQueryResult<ProductAsset | undefined> }> = ({ query }) => {
  const { imageUrl, savedEditsImageUrl } = useContext(ImageModalContext);
  const { activePage, userDetails } = useContext(AppContext);
  const { setPendingGeneration, setActiveEditResults } = useContext(ImageModalContext);
  const { data: asset, isPending, isLoading } = query;

  const {
    isPending: isPendingSubmit,
    submitWorkflow,
    updateWorkflowOptions,
    submissionQuery: { data: result },
    workflowOptions,
  } = useWorkflow<ProductEditAction>({
    workflowId: WorkflowIdEnum.PRODUCT_EDIT,
    userAlias: userDetails?.alias,
    pageName: activePage,
  });

  const [boundingBox, setBoundingBox] = useState<BoundingBox>({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    rotateAngle: 0,
    ...asset?.boundingBox,
  });

  const [originalBoundingBox, setOriginalBoundingBox] = useState<BoundingBox>();

  useEffect(() => {
    if (asset && asset.boundingBox) {
      setBoundingBox(asset.boundingBox);
      if (!originalBoundingBox) setOriginalBoundingBox(asset.boundingBox);
    }
  }, [asset]);

  useEffect(() => {
    setPendingGeneration(isPendingSubmit);
  }, [isPendingSubmit]);
  useEffect(() => {
    if (result) {
      setActiveEditResults(result);
    } else {
      setActiveEditResults(undefined);
    }
  }, [result?.jobs?.[0].status]);
  return (
    <div className={styles.container}>
      <ControlLabel
        title="Change size and position"
        subTitle="To resize your product, drag the corners of the frame. To reposition your product, move the frame."
        titleSize={HEADING_TEXT_SIZE.SMALL}
      />
      <ProductPositionCanvas
        onReposition={(adjustedBoundingBox) => {
          updateWorkflowOptions({
            imageId: asset?.imageId,
            boundingBox: adjustedBoundingBox,
            image_count: 4,
          });
          setBoundingBox(adjustedBoundingBox);
        }}
        ready={!isPending && !isLoading && !!asset}
        boundingBox={boundingBox}
        canvasWidth={400}
        backgroundImageURL={savedEditsImageUrl || imageUrl}
        productImageURL={asset?.productImage || 'https://placehold.co/50x30'}
      />
      <div>
        <ThemeProvider theme={overrideMouseLeaveTimeout}>
          <Button
            type={ButtonTypes.Secondary}
            icon={iconTypes.undo}
            iconOnly
            popoverText="Reset scaling and position"
            onClick={() => {
              originalBoundingBox && setBoundingBox({ ...originalBoundingBox });
            }}
          />
        </ThemeProvider>
      </div>
      <div className={styles.controlSection}>
        <AnimatedButton
          dataTestId="studio-edit-generate-button"
          animate={isPendingSubmit}
          style={{ minWidth: '150px' }}
          disabled={isPendingSubmit || !asset || !workflowOptions}
          fullscreen={false}
          text={isPendingSubmit ? 'Generating' : 'Generate'}
          clickHandler={() => {
            if (!asset?.imageId) return;
            submitWorkflow();
          }}
        ></AnimatedButton>
      </div>
    </div>
  );
};
