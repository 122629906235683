import { Text } from '@amzn/storm-ui';
import { HTMLAttributes, memo } from 'react';
import { Progress } from 'src/v2/components/common/progress/Progress';
import { useAppSelector } from 'src/v2/redux/hooks';
import { getAssetGenerationById } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice';
import { AssetGeneration } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice.types';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import styles from './ImportImagePlaceholder.module.scss';

export const TEST_ID_UPLOAD_PLACEHOLDER_ROOT = 'upload-placehodler-root';
export const TEST_ID_UPLOAD_PLACEHOLDER_IMAGE = 'upload-placeholder-image';
export const TEST_ID_UPLOAD_PLACEHOLDER_OVERLAY = 'upload-placeholder-overlay';
export const TEST_ID_UPLOAD_PLACEHOLDER_BADGE = 'upload-placeholder-badge';

interface ImportImagePlaceholderProps extends HTMLAttributes<HTMLSpanElement> {
  assetGenerationId: AssetGeneration['id'];
  url: string;
}

export const IMPORTING_BADGE_LABEL = 'Importing Image';

export const ImportImagePlaceholder = memo((props: ImportImagePlaceholderProps) => {
  const { assetGenerationId, url, ...restProps } = props;
  const assetGeneration = useAppSelector((state) => getAssetGenerationById(state, assetGenerationId));
  const rootClassNames = getNormalizedClassNames([styles.feedItemContainer, styles.feedAssetGenerationPlaceholder]);
  return (
    <span className={rootClassNames} data-testid={TEST_ID_UPLOAD_PLACEHOLDER_ROOT} {...restProps}>
      <img className={styles.uploadPlaceholderImage} src={url} data-testid={TEST_ID_UPLOAD_PLACEHOLDER_IMAGE} />
      <div className={styles.uploadPlaceholderOverlay} data-testid={TEST_ID_UPLOAD_PLACEHOLDER_OVERLAY}>
        <div className={styles.uploadBadge} data-testid={TEST_ID_UPLOAD_PLACEHOLDER_BADGE}>
          <Text renderAs="span">{IMPORTING_BADGE_LABEL}</Text>
        </div>
        <Progress progress={assetGeneration?.progress} />
      </div>
    </span>
  );
});
ImportImagePlaceholder.displayName = 'ImportImagePlaceholder';
