import Genaihubbackend, { RetrieveAssetInput, RetrieveAssetOutput } from '@amzn/genaihub-typescript-client';
import {
  BackendActionBase,
  BackendActionQueryResultBase,
  BackendActionType,
  BackendQueryActionOptions,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type RetrieveAssetAction = BackendActionBase<
  BackendActionType.RETRIEVE_ASSET,
  RetrieveAssetInput,
  RetrieveAssetOutput['body'],
  BackendQueryActionOptions
>;

export type RetrieveAssetActionQueryResult = BackendActionQueryResultBase<BackendActionType.RETRIEVE_ASSET, RetrieveAssetOutput['body']>;

interface RetrieveAssetQueryFnProps {
  id: Request['id'];
  action: RetrieveAssetAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const retrieveAssetQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: RetrieveAssetQueryFnProps): Promise<RetrieveAssetActionQueryResult> => {
  const type = BackendActionType.RETRIEVE_ASSET;
  const response = await backendClient.retrieveAsset(action.payload);
  const request = getRequest({ id, requestsLookup });

  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }

  return { type, response: response.body, requestStatus: RequestStatus.SUCCESS };
};

interface HandleRetrieveAssetActionProps {
  action: RetrieveAssetAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}

export const handleRetrieveAssetAction = ({ action, backendClient, requestsLookup, setQueries }: HandleRetrieveAssetActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.RETRIEVE_ASSET,
    getQueryResult: ({ id }) => {
      return retrieveAssetQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
