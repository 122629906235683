import { Button as StormButton } from '@amzn/storm-ui';
import { useState } from 'react';

import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import Stack from 'src/customUIComponents/Stack';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import styles from './RadioGroup.module.scss';

interface RadioGroupOption {
  label: string;
  value: number;
}
export interface RadioGroupProps {
  title: string;
  options: RadioGroupOption[];
  onChange?: (value: number) => void;
  defaultValue?: number;
  disabled?: boolean;
}

const RadioGroup = (props: RadioGroupProps) => {
  const [selected, setSelected] = useState<number | undefined>(props.defaultValue);
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = isDarkModeLaunched && useAppSelector(isDarkModeActive);
  const handleClick = (value: number) => {
    setSelected(value);
    props.onChange && props.onChange(value);
  };

  const options = props.options.map((option: RadioGroupOption, index: number) => {
    return isDarkMode ? (
      <Button
        key={'button' + index}
        label={option.label}
        type={ButtonTypes.Secondary}
        disabled={props.disabled}
        dataTestId={'button' + index}
        onClick={() => handleClick(option.value)}
        style={{ borderColor: !props.disabled && option.value === selected ? (isDarkMode ? 'var(--interactive-primary)' : '#6236FF') : 'unset' }}
      />
    ) : (
      <StormButton
        className={styles.button}
        onClick={() => handleClick(option.value)}
        key={'button' + index}
        style={{
          borderColor: option.value === selected ? '#6236FF' : '#f1f3f5',
        }}
        disabled={props.disabled}
        data-testid={'button' + index}
      >
        {option.label}
      </StormButton>
    );
  });

  return (
    <div className={styles.radioGroupWrapper}>
      <ControlLabel title={props.title} />
      <Stack className={styles.buttonGroup}>{options}</Stack>
    </div>
  );
};

export default RadioGroup;
