import { Product, Products, WorkflowId } from '@amzn/genaihub-typescript-client';
import _cloneDeep from 'lodash/cloneDeep';
import { useRef, useState } from 'react';
import { createContext } from 'react';
import { Feedback } from 'src/components/feedback/FeedbackContext';
import { DEFAULT_FORMAT, UserInputContainerRef, UserSelectableAspectRatios } from 'src/components/pages/studio/UserInputContainer';
import { PlaceholderType } from 'src/v2/components/placeholder/Placeholder.types';
import { ASINItem, AspectRatio, AssetTypeEnum, ProductImageDetails, SelectedEffectsMap, StyleOption } from 'src/v2/types';

export interface GenerationJob {
  status: GenerationJobStatusEnum;
  message?: string;
  results?: ContentItem[];
}

export enum GenerationJobStatusEnum {
  READY = 'ready',
  FAILED = 'failed',
  RUNNING = 'running',
  COMPLETED = 'completed',
  LOADING_ASIN = 'loadingAsin',
}

// NOTE: this will be deprecated as part of State Management and replaced with strong WorkflowUserInputs typing
export interface StudioInputSettings {
  asin?: ASINItem;
  effects?: SelectedEffectsMap;
  enablePromptRewriting?: boolean;
  format?: string;
  prompt?: string;
  style?: StyleOption;
  advanced?: {
    negativePrompt?: string;
    seed?: string;
    temperature?: number;
  };
  multiProduct?: Product[];
}

// NOTE: this will be deprecated/modified as part of State Management and replaced with stronger typing (ie: use Asset)
export interface ContentItem {
  batchId?: string;
  loading?: boolean;
  placeholderId?: string;
  placeholderType?: PlaceholderType;
  placeholderUrl?: string;
  originalUri?: string;
  content?: string;
  message?: string;
  aspectRatio?: string;
  width?: string;
  contentType?: string;
  contentHeadline?: string;
  studioInputSettings?: StudioInputSettings;
  referenceId?: string;
  workflowId?: WorkflowId;
  feedback?: Feedback;
  isFeedsCallRunning?: boolean;
  imageId?: string;
  assetType?: AssetTypeEnum;
  transitionOut?: boolean;
  timestamp?: string;
  products?: Products;
}

export const useStudioContext = () => {
  const [creativeTypes, setCreativeTypes] = useState<string[]>();
  const [format, setFormat] = useState<string>(DEFAULT_FORMAT.id);
  const [style, setStyle] = useState<StyleOption>();
  const [effects, setEffects] = useState<SelectedEffectsMap>();
  const [asin, setAsin] = useState<ASINItem>();
  const [textPrompt, setTextPrompt] = useState<string>();
  const [generationJob, setGenerationJob] = useState<GenerationJob>();
  const [results, setResults] = useState<ContentItem[]>([]);
  const [placeholders, setPlaceholders] = useState<ContentItem[]>();
  const [autoGenerate, setAutoGenerate] = useState<boolean>(false);
  const [negativePrompt, setNegativePrompt] = useState<string>();
  const [seed, setSeed] = useState<string>();
  const [temperature, setTemperature] = useState<number>();
  const [enablePromptRewriting, setEnablePromptRewriting] = useState(false);
  const [enableAdvancedMode, setEnableAdvancedMode] = useState(false);
  const userInputContainerRef = useRef<UserInputContainerRef>(null);

  // TOOD: when state management is added, improve the process for adding and managing placeholders across the lifecycle of asset generation.
  // The newer approach with state management should factor in multiple generations running concurrently and how those
  // generations manage their placeholders.
  const addUploadPlaceholders = (placeholders: ContentItem[]) => {
    setPlaceholders((currentPlaceholders) => [
      ...placeholders.map((placeholder) => ({ ...placeholder, placeholderType: PlaceholderType.UPLOAD })),
      ...(currentPlaceholders ?? []),
    ]);
  };

  const transitionOutPlaceholders = (placeholderIds: string[]) => {
    const placeholderIdsLookup = placeholderIds.reduce((map: { [key: string]: boolean }, placeholderId) => {
      map[placeholderId] = true;
      return map;
    }, {});
    setPlaceholders((placeholders) =>
      placeholders?.map((placeholder) => {
        const isMatch = placeholder.placeholderId && placeholderIdsLookup[placeholder.placeholderId];
        return isMatch ? { ...placeholder, transitionOut: true } : placeholder;
      }),
    );
  };

  const clearGenerationPlaceholders = () => {
    setPlaceholders((placeholders) => placeholders?.filter((placeholder) => placeholder.placeholderType !== PlaceholderType.GENERATION));
  };

  const setGenerationPlaceholders = (newPlaceholders: ContentItem[]) => {
    setPlaceholders((currentPlaceholders) => {
      const firstIndex = currentPlaceholders?.findIndex((placeholder) => placeholder.placeholderType === PlaceholderType.GENERATION) ?? -1;
      const _newPlaceholders = newPlaceholders.map((placeholder) => ({ ...placeholder, placeholderType: PlaceholderType.GENERATION }));
      if (!currentPlaceholders) {
        return _newPlaceholders;
      } else if (firstIndex >= 0) {
        // Inject the new placeholders list where the previous generation placeholders were started.
        const updatedPlaceholders = [];
        for (let i = 0; i < currentPlaceholders.length; i++) {
          const placeholder = currentPlaceholders[i];
          if (i === firstIndex) {
            updatedPlaceholders.push(..._newPlaceholders);
          } else if (placeholder.placeholderType !== PlaceholderType.GENERATION) {
            updatedPlaceholders.push(placeholder);
          }
        }
        return updatedPlaceholders;
      } else {
        return [..._newPlaceholders, ...currentPlaceholders];
      }
    });
  };

  const removePlaceholders = (placeholderIds: string[]) => {
    const placeholderIdsLookup = placeholderIds.reduce((map: { [key: string]: boolean }, placeholderId) => {
      map[placeholderId] = true;
      return map;
    }, {});
    setPlaceholders((placeholders) =>
      placeholders?.filter((placeholder) => !(placeholder.placeholderId && placeholderIdsLookup[placeholder.placeholderId])),
    );
  };

  const clearResults = () => {
    setResults([]);
  };

  const prependResults = (newResults: ContentItem[]) => {
    setResults((prevResults: ContentItem[]) => [...(newResults || []), ...(prevResults || [])]);
  };

  const appendResults = (newResults: ContentItem[]) => {
    setResults((prevResults: ContentItem[]) => [...(prevResults || []), ...(newResults || [])]);
  };

  const removeResult = (assetToDelete: string) => {
    setResults((prevResults: ContentItem[]) => [...(prevResults || []).filter((item) => item.referenceId !== assetToDelete)]);
  };

  const updateResult = (updatedResult: ContentItem) => {
    setResults((prevResults: ContentItem[]) => [
      ...(prevResults || []).map((result) => (result.referenceId === updatedResult.referenceId ? updatedResult : result)),
    ]);
  };

  const handleReuseSettings = (props: { studioInputSettings: StudioInputSettings }) => {
    /* eslint-disable-next-line react/prop-types */
    const { studioInputSettings } = props;

    /* eslint-disable-next-line react/prop-types */
    setAsin(_cloneDeep(studioInputSettings.asin));
    /* eslint-disable-next-line react/prop-types */
    setEffects(_cloneDeep(studioInputSettings.effects));
    /* eslint-disable-next-line react/prop-types */
    setFormat(studioInputSettings.format || DEFAULT_FORMAT.id);
    /* eslint-disable-next-line react/prop-types */
    setStyle(_cloneDeep(studioInputSettings.style));
    /* eslint-disable-next-line react/prop-types */
    setTextPrompt(studioInputSettings.prompt);
    /* eslint-disable-next-line react/prop-types */
    userInputContainerRef.current?.setPrompt(studioInputSettings.prompt);

    /* eslint-disable-next-line react/prop-types */
    if (studioInputSettings.advanced) {
      /* eslint-disable-next-line react/prop-types */
      setNegativePrompt(studioInputSettings.advanced.negativePrompt);
      /* eslint-disable-next-line react/prop-types */
      setSeed(studioInputSettings.advanced.seed);
      /* eslint-disable-next-line react/prop-types */
      setTemperature(studioInputSettings.advanced.temperature);
      setEnableAdvancedMode(true);
    } else {
      setNegativePrompt(undefined);
      setSeed(undefined);
      setTemperature(undefined);
      setEnableAdvancedMode(false);
    }

    /* eslint-disable-next-line react/prop-types */
    if (studioInputSettings.enablePromptRewriting) {
      setEnablePromptRewriting(true);
    } else {
      setEnablePromptRewriting(false);
    }
  };

  return {
    placeholders,
    addUploadPlaceholders,
    transitionOutPlaceholders,
    clearGenerationPlaceholders,
    removePlaceholders,
    setGenerationPlaceholders,
    creativeTypes,
    setCreativeTypes,
    format,
    setFormat,
    style,
    setStyle,
    effects,
    setEffects,
    asin,
    setAsin,
    textPrompt,
    setTextPrompt,
    generationJob,
    setGenerationJob,
    results,
    prependResults,
    appendResults,
    clearResults,
    removeResult,
    updateResult,
    autoGenerate,
    setAutoGenerate,
    negativePrompt,
    setNegativePrompt,
    seed,
    setSeed,
    temperature,
    setTemperature,
    enableAdvancedMode,
    setEnableAdvancedMode,
    enablePromptRewriting,
    setEnablePromptRewriting,
    handleReuseSettings,
    userInputContainerRef,
  };
};

export type StudioContextType = ReturnType<typeof useStudioContext>;
export const StudioContext = createContext({} as StudioContextType);

export const getProductImageDetails = (asinItem: ASINItem): ProductImageDetails => {
  const selectedImageIndex = asinItem.selectedImageIndex || 0;
  const mediaCentralAssets = asinItem.metadata?.mediaCentralAssets;
  let asinImage =
    mediaCentralAssets &&
    selectedImageIndex !== -1 &&
    mediaCentralAssets.length > selectedImageIndex &&
    (mediaCentralAssets[selectedImageIndex].highResUri || mediaCentralAssets[selectedImageIndex].lowResUri);

  if (!asinImage && asinItem?.customProductImageUrl) {
    return {
      asin: asinItem.asin,
      title: asinItem.metadata?.title || '',
      url: asinItem.customProductImageUrl,
    };
  } else if (!asinImage && asinItem?.customImage) {
    return _cloneDeep(asinItem.customImage);
  } else {
    return {
      asin: asinItem.asin,
      title: asinItem.metadata?.title || '',
      url: asinImage as string,
    };
  }
};

export const isImportedMediaWorkflow = (workflowId: string | undefined) => {
  return workflowId?.startsWith('IMPORT_');
};

export const getValidFormatForStudioInputSettings = (aspectRatio: string | undefined) => {
  return aspectRatio && UserSelectableAspectRatios.includes(aspectRatio as AspectRatio) ? aspectRatio : AspectRatio.HORIZONTAL_191_TO_1;
};
