import { ASINMetadata } from '@amzn/genaihub-typescript-client';
import { US_MARKETPLACE_ID } from 'src/constants';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { BackendActionType } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { AbortError } from 'src/v2/errors/CustomErrors';

export interface HandleRetrieveASINMetadataByASINIdResult {
  asinMetadata: ASINMetadata;
}

export const handleRetrieveASINMetadataByASINId = ({
  asin,
  backendDispatchContext,
  requestGroupId,
}: {
  asin: string;
  backendDispatchContext: BackendDispatchContextType;
  requestGroupId: string;
}): Promise<HandleRetrieveASINMetadataByASINIdResult> => {
  return new Promise((resolve, reject) => {
    backendDispatchContext({
      type: BackendActionType.RETRIEVE_ASIN_METADATA_BY_ASIN_ID,
      payload: {
        asinId: asin,
        marketplaceId: US_MARKETPLACE_ID,
      },
      options: {
        requestGroupId,
      },
      onAbort: () => {
        return reject(new AbortError(`Aborted backend action: '${BackendActionType.RETRIEVE_ASIN_METADATA_BY_ASIN_ID}'`));
      },
      onError: (err) => {
        return reject(err);
      },
      onSuccess: async ({ response }) => {
        return resolve({
          asinMetadata: response,
        });
      },
    });
  });
};
