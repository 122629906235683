import { CSSProperties } from 'react';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import styles from './Progress.module.scss';

interface ProgressProps {
  className?: string;
  /**
   * A number between 0 and 1
   */
  progress?: number;
  style?: CSSProperties;
}

export const TEST_ID_PROGRESS = 'creative-studio-progress';
export const TEST_ID_PROGRESS_TRACK = 'creative-studio-progress-track';
export const TEST_ID_PROGRESS_BAR = 'creative-studio-progress-bar';

export const Progress = ({ className, progress, style }: ProgressProps) => {
  const indeterminate = !progress || progress < 0 || progress > 1;

  return (
    <div
      className={getNormalizedClassNames([styles.progress, className])}
      data-testid={TEST_ID_PROGRESS}
      data-test-progress={progress}
      data-test-indeterminate={indeterminate}
      style={style}
    >
      <div className={`${styles.track} ${indeterminate ? styles.indeterminate : ''}`} data-testid={TEST_ID_PROGRESS_TRACK}>
        <div
          className={styles.bar}
          style={{
            ...(!indeterminate ? { width: `${(progress * 100).toFixed(0)}%` } : {}),
          }}
          data-testid={TEST_ID_PROGRESS_BAR}
        />
      </div>
    </div>
  );
};
