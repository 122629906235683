import { useContext } from 'react';
import { AppContext } from 'src/AppContext';
import { downloadFromUrl, getFileExtension } from 'src/components/utils/LinkUtils';
import { Metrics } from 'src/constants';
import { useAssetCache } from 'src/v2/hooks/useAssetCache/useAssetCache';
import { SimpleAsset } from 'src/v2/types';

export const useDownloadAsset = ({ asset }: { asset: SimpleAsset }) => {
  const appContext = useContext(AppContext);
  const cachedAssetUrl = useAssetCache({ asset });

  const downloadAsset = () => {
    const url = cachedAssetUrl || asset.url;
    const fileExtension = getFileExtension({ url: asset.url, assetType: asset.type });
    const fileName = `ailab-${crypto.randomUUID()}${fileExtension ? '.' + fileExtension : ''}`;
    downloadFromUrl({ url, fileName });
    appContext.metrics.trackMetrics(Metrics.Methods.ButtonClick, {}, { [Metrics.Counters.Download]: 1 });
  };

  return {
    downloadAsset,
  };
};
