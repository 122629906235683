import { Button, Checkbox } from '@amzn/storm-ui';
import { KeyboardEvent, ReactElement, SyntheticEvent } from 'react';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import { AssetTypeEnum } from 'src/v2/types';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import { Image } from './Image';
import styles from './ImageGrid.module.scss';

interface MultiSelectionProps {
  disabled: (assetUrl: string | undefined) => boolean;
  setCheck: (assetUrl: string | undefined) => boolean;
  turnMaxWidthOff?: boolean;
}

interface ImageGridProps {
  componentLabel: string;
  images: ContentItem[];
  onFeedSelect: (e: SyntheticEvent, image: ContentItem) => void;
  scrollHandler?: (e: SyntheticEvent) => void;
  multiSelection?: MultiSelectionProps;
}

export const getImageGridCheckBoxWithImageTestId = (index: number) => `checkbox-with-image-${index}`;

export const getImageGridCheckBoxTestId = (index: number) => `checkbox-${index}`;

export const ImageGrid = ({ componentLabel, images, onFeedSelect, scrollHandler, multiSelection }: ImageGridProps): ReactElement => {
  const handleMouseDown = (e: SyntheticEvent, item: ContentItem) => {
    onFeedSelect(e, item);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement> | KeyboardEvent<HTMLAnchorElement>, item: ContentItem) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onFeedSelect(e, item);
    }
  };

  const rootClassNames = getNormalizedClassNames([styles.imageGrid, multiSelection?.turnMaxWidthOff ? styles.turnoffMaxWidth : undefined]);

  return (
    <div className={rootClassNames} data-testid={`image-grid-component-${componentLabel}`}>
      <div onScroll={scrollHandler} className={styles.images}>
        {images.map((item, index) => {
          if (item.assetType === AssetTypeEnum.VIDEO) return null;
          return multiSelection ? (
            <div className={styles.buttonWrapper}>
              <div data-testid={getImageGridCheckBoxWithImageTestId(index)}>
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    data-testid={getImageGridCheckBoxTestId(index)}
                    checked={multiSelection.setCheck(item.referenceId)}
                    onChange={(e) => onFeedSelect(e, item)}
                    disabled={multiSelection.disabled(item.referenceId)}
                  />
                </div>
                <Image item={item} componentLabel={componentLabel} />
              </div>
            </div>
          ) : (
            <Button
              data-testid={`image-grid-button-${index}`}
              key={`image-grid-button-${index}`}
              className={styles.buttonWrapper}
              onKeyDownCapture={(e) => handleKeyDown(e, item)}
              onMouseDown={(e) => handleMouseDown(e, item)}
            >
              <Image item={item} componentLabel={componentLabel} />
            </Button>
          );
        })}
      </div>
    </div>
  );
};
