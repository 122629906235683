import { ASINMetadata } from '@amzn/genaihub-typescript-client';

export enum FeedActionType {
  FETCH_FEED = 'FETCH_FEED',
}

export interface FeedContextState {}

export type FetchFeedAction = {
  type: FeedActionType.FETCH_FEED;
  payload: {
    accountType: AccountType | undefined;
    entityId: string;
  };
};

export type FeedAction = FetchFeedAction;

export type ASINMetadataListItem = {
  asin: string;
  metadata: ASINMetadata;
};

export type ASINMetadataList = ASINMetadataListItem[];

export type ASINMetadataMap = { [asin: string]: ASINMetadata };
