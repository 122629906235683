import { Modal, ModalProps, Heading, Text, TextAreaFormGroup } from '@amzn/storm-ui';
import React, { useContext } from 'react';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { AssetTypeEnum, FeedbackScopeEnum } from 'src/v2/types';
import styles from './FeedbackCommentModal.module.scss';
import { AssetFeedbackMetadata, FeedbackContext } from './FeedbackContext';

const Footer = () => {
  const feedbackContext = useContext(FeedbackContext);

  const handleCancel = () => {
    feedbackContext.setFeedbackComment('');
    feedbackContext.submitFeedback();
  };

  const handleSubmit = () => {
    feedbackContext.submitFeedback({ includeComment: true });
  };

  return (
    <>
      <Button label="Cancel" type={ButtonTypes.Tertiary} onClick={handleCancel} />
      <Button label="Submit" type={ButtonTypes.Primary} onClick={handleSubmit} />
    </>
  );
};

export interface FeedbackCommentModalProps {
  isOpen: boolean;
  toggleEl: ModalProps['toggleEl'];
}

const InstructionsHeading = {
  [FeedbackScopeEnum.ASSET]: 'Tell us what you thought of this asset',
  [FeedbackScopeEnum.MODULE]: 'Tell us what you thought of this tool',
  [FeedbackScopeEnum.OTHER]: 'Tell us about your experience',
};

export const FEEDBACK_COMMENT_MODAL_ID = 'feedback-comment-modal';

export const FeedbackCommentModal = (props: FeedbackCommentModalProps) => {
  const feedbackContext = useContext(FeedbackContext);

  const handleTextAreaChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    feedbackContext.setFeedbackComment(e.target.value);
  };

  const handleTextAreaKeyDown: React.KeyboardEventHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      feedbackContext.submitFeedback({ includeComment: true });
    }
  };

  const instructionsHeading = InstructionsHeading[feedbackContext.feedbackScope as FeedbackScopeEnum];
  const instructionsText =
    'Your feedback is important to help us continually improve our AI models to help you generate better ads. Please do not include personal information.';

  return (
    <Modal
      id={FEEDBACK_COMMENT_MODAL_ID}
      className={styles.feedbackCommentModal}
      contentClassName={styles.modalContent}
      isOpen={props.isOpen}
      hasCloseButton={false}
      footer={<Footer />}
      toggleEl={props.toggleEl}
    >
      <div className={styles.topSection}>
        {feedbackContext.feedbackScope === FeedbackScopeEnum.ASSET &&
          (feedbackContext.feedbackMetadata as AssetFeedbackMetadata)?.assetType === AssetTypeEnum.VIDEO && (
            <video className={styles.imageBox} preload={'metadata'}>
              <source src={feedbackContext.feedbackMetadata?.cachedAssetUrl ?? feedbackContext.feedbackMetadata?.assetUrl} type="video/mp4" />
            </video>
          )}
        {feedbackContext.feedbackScope === FeedbackScopeEnum.ASSET &&
          (feedbackContext.feedbackMetadata as AssetFeedbackMetadata)?.assetType === AssetTypeEnum.IMAGE && (
            <img
              className={styles.imageBox}
              alt="&nbsp;thumbnail of image"
              src={feedbackContext.feedbackMetadata?.cachedAssetUrl ?? feedbackContext.feedbackMetadata?.assetUrl}
            />
          )}
        <div className={styles.instructions}>
          <Heading className={styles.instructionsHeading} color="default" renderAs="h2">
            {instructionsHeading}
          </Heading>
          <Text className={styles.instructionsText} color="tertiary" renderAs="p">
            <i>{instructionsText}</i>
          </Text>
        </div>
      </div>
      <TextAreaFormGroup
        id="feedback-comment-modal-textarea"
        className={styles.commentTextArea}
        label=""
        fullWidth={true}
        hideLabel={true}
        onChange={handleTextAreaChange}
        onKeyDown={handleTextAreaKeyDown}
        style={{ border: 'none' }}
      ></TextAreaFormGroup>
    </Modal>
  );
};
