import { Icon as StormIconComponent } from '@amzn/storm-ui';
import { useEffect, useRef, useState } from 'react';
import { checkColorStringFormat, getDataTestId } from 'src/v2/utils/utils';
import styled from 'styled-components';
import { iconTypes } from './iconTypes';
import { IconProps } from './types';

export const IconWrapper = styled.div<{ color?: string; size?: string; matchFontSize?: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${({ size, matchFontSize }) => {
    if (matchFontSize) {
      return 'inherit';
    }
    return size ?? '24px';
  }};

  & path {
    fill: ${({ color }) => (color && checkColorStringFormat(color)) || 'inherit)'};
  }
`;

export const Icon = (props: IconProps) => {
  const {
    backgroundColor,
    borderRadius = 999,
    color,
    darkMode,
    dataTestId,
    matchFontSize,
    paddingPercent = 30,
    showBackground,
    backgroundSize,
    size,
    type,
  } = props;
  const iconName = typeof type === 'string' ? type : type.iconName;
  const stringAsIcon = typeof type === 'string' ? Object.values(iconTypes).find((value) => value.iconName === type) : null;
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const showBackgroundStyle = {
    color: (color && checkColorStringFormat(color)) || 'var(--surface-primary',
    backgroundColor:
      (backgroundColor && checkColorStringFormat(backgroundColor)) || (darkMode ? 'var(--surface-tertiary)' : 'var(--surface-tertiary)'),
    padding: `${(paddingPercent / 100) * height}px`,
    borderRadius: `${borderRadius}px`,
  };

  const showBackgroundWithSizeOveride = {
    color: (color && checkColorStringFormat(color)) || 'var(--surface-primary',
    backgroundColor:
      (backgroundColor && checkColorStringFormat(backgroundColor)) || (darkMode ? 'var(--surface-tertiary)' : 'var(--surface-tertiary)'),
    width: backgroundSize?.width || 'unset',
    height: backgroundSize?.height || 'unset',
    borderRadius: `${borderRadius}px`,
  };

  const noBackgroundStyle = {
    color: 'var(--text-primary)',
    background: 'none',
  };

  const style = {
    ...(showBackground && showBackgroundStyle),
    ...(showBackground && backgroundSize && showBackgroundWithSizeOveride),
    ...(!showBackground && noBackgroundStyle),
  };

  const otherIconProps = {
    size,
    type: stringAsIcon || type,
    dataTestId,
    style,
  };

  // gets the SVG icon element size for calculating the proper padding percent overrides
  useEffect(() => {
    if (ref.current) {
      const hasDataTestId = dataTestId && dataTestId.length > 0;
      const iconElement = document.querySelector(`[data-testid='${hasDataTestId ? dataTestId : `storm-ui-icon-${iconName}`}']`)?.firstElementChild;
      const iconElementSize = iconElement?.nodeName === 'svg' && iconElement?.clientWidth > 0 ? iconElement?.clientWidth : 0;
      setHeight(iconElementSize);
    }
  }, [size, type, paddingPercent, borderRadius]);

  return (
    <IconWrapper size={size} matchFontSize={matchFontSize} color={color} ref={ref}>
      <StormIconComponent data-testid={getDataTestId(dataTestId) || undefined} {...otherIconProps} />
    </IconWrapper>
  );
};
