import { Asset } from '@amzn/genaihub-typescript-client';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { ASINMetadataList, ASINMetadataMap } from 'src/v2/contexts/feed/FeedContext.types';
import { handleRetrieveASINMetadataByASINId } from 'src/v2/contexts/feed/util/backend/RetrieveASINMetadataByASINId';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { SelectedProductFull } from 'src/v2/redux/slices/userInput/userInputSlice.types';
import { downloadFile } from 'src/v2/utils/File.utils';
import { v6 as uuidV6 } from 'uuid';

/**
 * Converts a list of ASINMetadata into a map of key-value pairs (asin -> ASINMetadata)
 */
export function convertASINMetadataListToMap({ asinMetadataList }: { asinMetadataList: ASINMetadataList }) {
  const asinMetadataMap: ASINMetadataMap = {};
  asinMetadataList.forEach((asinMetadataListItem) => {
    asinMetadataMap[asinMetadataListItem.asin] = asinMetadataListItem.metadata;
  });
  return asinMetadataMap;
}

/**
 * Processes a list of backend Assets by extracting the unique ASINs, then fetches and returns their ASINMetadata
 */
export async function getASINMetadataListFromBackendAssets({
  backendAssets,
  backendDispatchContext,
  requestGroupId,
}: {
  backendAssets: Asset[];
  backendDispatchContext: BackendDispatchContextType;
  requestGroupId: string;
}) {
  const asins = backendAssets
    .map((item) => item.asin?.trim())
    .filter((asin): asin is string => typeof asin === 'string' && !!asin.length) satisfies string[];
  const uniqueAsins = Array.from(new Set<string>(asins));
  const results = await Promise.allSettled(
    uniqueAsins.map(async (asin) => {
      try {
        const { asinMetadata: metadata } = await handleRetrieveASINMetadataByASINId({ asin, backendDispatchContext, requestGroupId });
        return {
          asin,
          metadata,
        };
      } catch (err) {
        console.error(`Error while fetching metadata for ASIN '${asin}':`, err);
        throw err;
      }
    }),
  );

  const asinMetadataList: ASINMetadataList = [];
  results.forEach((result) => {
    if (result.status === 'fulfilled') {
      asinMetadataList.push(result.value);
    }
  });

  return asinMetadataList;
}

/**
 * Builds a selected product (full) using data from a backend asset.
 */
export async function getProductFromBackendAsset({
  asinMetadataMap,
  backendAsset,
}: {
  asinMetadataMap: ASINMetadataMap;
  backendAsset: Asset;
}): Promise<SelectedProductFull | undefined> {
  const asin = backendAsset.asin?.trim();
  let customProductImageFile, customProductImageUrl;
  if (backendAsset.inputImageUri) {
    try {
      customProductImageFile = await downloadFile({ url: backendAsset.inputImageUri });
      customProductImageUrl = URL.createObjectURL(customProductImageFile);
    } catch (err) {
      console.error(`Error while fetching image '${backendAsset.inputImageUri}': ${err}`);
    }
  }

  if (asin) {
    // Build SelectedAsinProductFull
    return {
      id: asin,
      type: ProductType.ASIN,
      asin,
      metadata: asinMetadataMap[asin],
      options: {
        asinProduct: {
          customProductImageUrl,
          selectedImageIndex: -1,
        },
      },
    };
  } else if (customProductImageFile && customProductImageUrl) {
    // Build SelectedCustomImageProductFull
    return {
      id: uuidV6(),
      type: ProductType.CUSTOM_IMAGE,
      customImage: {
        url: customProductImageUrl,
        name: customProductImageFile.name,
        size: customProductImageFile.size,
      },
    };
  }

  return undefined;
}
