import { Card, Text } from '@amzn/storm-ui';
import { CSSProperties, useRef } from 'react';
import styled from 'styled-components';
import componentStyles from './PlaygroundCard.module.scss';
import * as styles from './playgroundStyles';

const CardView = styled(Card)`
  background-color: var(--surface-primary, '#ffffff');
`;

interface PlaygroundCardProps {
  callback: () => void;
  cardStyle: CSSProperties;
  title?: string;
  thumbnail?: string;
  description?: string;
  videoPreview?: string;
}

const PlaygroundCard = (props: PlaygroundCardProps) => {
  const { title, thumbnail, description, callback, cardStyle, videoPreview, ...rest } = props;
  const disabled = description?.toLowerCase().indexOf('coming soon') !== -1;
  const videoRef = useRef<HTMLVideoElement>(null);
  const cardDescription = description?.split('|')[0] || '';

  const handleVideoMouseEnter = () => {
    videoRef.current?.play().catch((e) => console.error(e));
  };

  const handleVideoMouseLeave = () => {
    videoRef.current?.pause();
    videoRef.current!.currentTime = 0;
  };

  return (
    <CardView
      {...rest}
      onClick={!disabled ? callback : undefined}
      paddingBlockEnd="none"
      paddingBlockStart="none"
      paddingInlineEnd="none"
      paddingInlineStart="none"
      className={componentStyles.card}
      style={{ ...styles.card, ...cardStyle, position: 'relative', border: 'none' }}
      data-testid="sandbox-card"
    >
      {videoPreview !== '' ? (
        <div style={{ position: 'relative', width: '100%', height: '100%', borderRadius: '20px', overflow: 'hidden' }}>
          <video
            poster={thumbnail}
            onPause={(event) => event.currentTarget.load()}
            ref={videoRef}
            muted
            loop
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              height: '100%',
              width: '100%',
              transform: 'translate(-50%, -50%)',
              objectFit: 'cover',
            }}
            onMouseEnter={handleVideoMouseEnter}
            onMouseLeave={handleVideoMouseLeave}
          >
            <source src={videoPreview} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div style={{ height: '100%' }}>
          <img src={thumbnail} style={{ height: '100%', width: '100%', display: 'block', borderRadius: '12px', objectFit: 'cover' }} />
        </div>
      )}

      <div className={componentStyles.cardTextBlockWrapper}>
        <div style={styles.cardTitleContainer}>
          <Text style={styles.cardTitle}>{title}</Text>
        </div>
        <div style={styles.cardDescription}>{cardDescription}</div>
      </div>
    </CardView>
  );
};

export default PlaygroundCard;
