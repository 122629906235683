import { Icon } from '@amzn/storm-ui';

const categories = ['Popular', 'Holidays', 'Location', 'Abstract'];
import { useState } from 'react';
import themesConfig from 'src/components/editor/UiContols/uiGeneratorControls/themes.json';
import { ALL_STYLES, getFilteredStyles } from 'src/components/utils/styleUtils';
import themes from 'src/config/themes/themesDropdownList';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import { StyleOption } from 'src/v2/types';
import style from './StyleSetting.module.scss';

const icons: { [key: string]: string } = { ...themes };

type StyleSettingPlaygroundProps = {
  onThemeSelected: (_: string) => void;
  style?: Record<string, string>;
};

export const StyleSettingPlayground = (props: StyleSettingPlaygroundProps) => {
  const [list, setList] = useState(themesConfig);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedStyleOption, setSelectedStyleOption] = useState<StyleOption>();
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = isDarkModeLaunched && useAppSelector(isDarkModeActive);

  const handleClick = (id: string) => {
    const selectedStyle = list.find((item) => item.controlValue === id);
    if (selectedStyle) {
      setSelectedStyleOption({
        icon: icons[selectedStyle.src],
        value: selectedStyle.controlValue,
        label: selectedStyle.controlLabel,
      });

      props.onThemeSelected(selectedStyle.controlValue);
    }
  };

  const searchHandler = (value: string) => {
    setList(getFilteredStyles(value));
  };

  const inputChangeHandler = (value: string) => {
    searchHandler(value);
    setSelectedCategory(ALL_STYLES);
  };

  const categoryClickHandler = (category: string) => {
    if (category === selectedCategory) {
      setSelectedCategory(ALL_STYLES);
      searchHandler(ALL_STYLES);
    } else {
      setSelectedCategory(category);
      searchHandler(category);
    }
  };
  return (
    <div className={style.container} data-testid={'style-setting'}>
      <div className={style.header} style={{ ...props.style }}>
        Style
      </div>
      <div className={style.searchBar}>
        <input placeholder="Search by keyword" onChange={(event) => inputChangeHandler(event.target.value)} />
        <Icon type={iconTypes.search} />
      </div>
      <div className={style.pills}>
        {categories.map((category) => (
          <div
            key={category}
            onClick={() => categoryClickHandler(category)}
            className={`${style.pillButton} ${selectedCategory == category ? (isDarkMode ? style.selected_ux2 : style.selected) : ''}`}
          >
            {category}
          </div>
        ))}
      </div>
      <div className={style.tiles}>
        {list.map((item) => {
          return (
            <div
              data-testid={'sandbox-style-setting-tile'}
              key={item.controlValue}
              onClick={() => handleClick(item.controlValue)}
              className={style.tileItem}
            >
              <div
                className={`${style.tileItemIcon} ${selectedStyleOption?.value == item.controlValue ? (isDarkMode ? style.selected_ux2 : style.selected) : ''}`}
                style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.05) 51%, rgba(0, 0, 0, 0.5) 82%), url(${icons[item.src]})` }}
              ></div>
              <div className={style.tileItemLabel}>{item.controlLabel}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
