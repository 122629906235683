import { handleImageUpload, isUrl } from 'src/v2/contexts/assetGeneration/utils/common/ImageUpload.utils';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { getAssetCacheInstance } from 'src/v2/hooks/useAssetCache/useAssetCache';
import { Product } from 'src/v2/redux/slices/product/productSlice.types';
import { CategoryEnum, ImageAsset } from 'src/v2/types';
import { getProductImageUrl } from 'src/v2/utils/Product.utils';

/**
 * A convenience function for handling upload of a MASK_IMAGE
 */
export async function uploadMaskImageMiddleware({
  backendDispatchContext,
  urlOrFileOrRefId,
  progressCallback,
}: {
  backendDispatchContext: BackendDispatchContextType;
  urlOrFileOrRefId: string | File;
  progressCallback?: (value: number) => void;
}): Promise<string> {
  try {
    if (urlOrFileOrRefId instanceof File || isUrl({ input: urlOrFileOrRefId })) {
      const { referenceId } = await handleImageUpload({
        backendDispatchContext,
        contentCategory: CategoryEnum.MASK_IMAGE,
        urlOrFile: urlOrFileOrRefId,
        progressCallback,
      });
      return referenceId;
    } else {
      return urlOrFileOrRefId;
    }
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }
    console.error(err);
    throw new Error('Asset generation failed due to error while uploading mask.');
  }
}

export const HandleProductUploadErrorMessages = {
  NO_PRODUCT_URL: 'Failed to upload product due to missing image URL.',
};

/**
 * A convenience function for handling upload of a PRODUCT_IMAGE
 */
export async function uploadProductMiddleware({
  backendDispatchContext,
  product,
  progressCallback,
}: {
  backendDispatchContext: BackendDispatchContextType;
  product: Product;
  progressCallback?: (value: number) => void;
}): Promise<string> {
  try {
    const url = getProductImageUrl({ product });
    if (!url) {
      throw new Error(HandleProductUploadErrorMessages.NO_PRODUCT_URL);
    }
    const { referenceId } = await handleImageUpload({
      backendDispatchContext,
      contentCategory: CategoryEnum.PRODUCT_IMAGE,
      urlOrFile: url,
      progressCallback,
    });
    return referenceId;
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }
    console.error(err);
    throw new Error('Asset generation failed due to error while uploading product.');
  }
}

/**
 * A convenience function for handling upload of a PRODUCT_IMAGE
 */
export async function uploadProductImageMiddleware({
  backendDispatchContext,
  urlOrFileOrRefId,
  progressCallback,
}: {
  backendDispatchContext: BackendDispatchContextType;
  urlOrFileOrRefId: string | File;
  progressCallback?: (value: number) => void;
}): Promise<string> {
  try {
    if (urlOrFileOrRefId instanceof File || isUrl({ input: urlOrFileOrRefId })) {
      const { referenceId } = await handleImageUpload({
        backendDispatchContext,
        contentCategory: CategoryEnum.PRODUCT_IMAGE,
        urlOrFile: urlOrFileOrRefId,
        progressCallback,
      });
      return referenceId;
    } else {
      return urlOrFileOrRefId;
    }
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }
    console.error(err);
    throw new Error('Asset generation failed due to error while uploading mask.');
  }
}

/**
 * A convenience function for handling upload of a REFERENCE_IMAGE
 */
export async function uploadReferenceImageMiddleware({
  backendDispatchContext,
  urlOrFileOrRefId,
  progressCallback,
}: {
  backendDispatchContext: BackendDispatchContextType;
  urlOrFileOrRefId: string | File;
  progressCallback?: (value: number) => void;
}): Promise<string> {
  try {
    if (urlOrFileOrRefId instanceof File || isUrl({ input: urlOrFileOrRefId })) {
      const { referenceId } = await handleImageUpload({
        backendDispatchContext,
        contentCategory: CategoryEnum.REFERENCE_IMAGE,
        urlOrFile: urlOrFileOrRefId,
        progressCallback,
      });
      return referenceId;
    } else {
      return urlOrFileOrRefId;
    }
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }
    console.error(err);
    throw new Error('Asset generation failed due to error while uploading reference image.');
  }
}

/**
 * A convenience function for handling upload of a REFERENCE_IMAGE sourced from an ImageAsset
 */
export async function uploadInputImageAssetMiddleware({
  backendDispatchContext,
  inputImageAsset,
  progressCallback,
}: {
  backendDispatchContext: BackendDispatchContextType;
  inputImageAsset: ImageAsset;
  progressCallback?: (value: number) => void;
}): Promise<string> {
  try {
    const assetCacheInstance = getAssetCacheInstance();
    const cachedAssetUrl = await assetCacheInstance.getCachedAssetUrl({ asset: inputImageAsset });
    const { referenceId } = await handleImageUpload({
      backendDispatchContext,
      contentCategory: CategoryEnum.REFERENCE_IMAGE,
      urlOrFile: cachedAssetUrl,
      progressCallback,
    });
    return referenceId;
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }
    console.error(err);
    throw new Error('Asset generation failed due to error while uploading reference image.');
  }
}
