import { produce } from 'immer';
import React, { createContext, useCallback, useContext, useReducer } from 'react';
import { handleAbortGenerationAction } from 'src/v2/contexts/assetGeneration/actions/Abort.action';
import { handleGenerateAssetsAction } from 'src/v2/contexts/assetGeneration/actions/GenerateAssets.action';
import { handleGenerateStudioAssetsAction } from 'src/v2/contexts/assetGeneration/actions/GenerateStudioAssets.action';
import { handleImportStudioAssetAction } from 'src/v2/contexts/assetGeneration/actions/ImportStudioAsset.action';
import {
  AssetGenerationAction,
  AssetGenerationActionType,
  AssetGenerationState,
} from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { BackendDispatchContext } from 'src/v2/contexts/backend/BackendContext';
import { useAppStore } from 'src/v2/redux/hooks';
import { SnackbarDispatchContext } from '../snackbar/SnackbarContext';

export const useAssetGenerationContext = () => {
  const appStore = useAppStore();
  const backendDispatchContext = useContext(BackendDispatchContext);
  const snackbarDispatchContext = useContext(SnackbarDispatchContext);

  const [state, dispatch] = useReducer(
    produce((state: AssetGenerationState, action: AssetGenerationAction) => {
      switch (action.type) {
        case AssetGenerationActionType.ABORT:
          handleAbortGenerationAction({ action, appStore, backendDispatchContext });
          break;
        case AssetGenerationActionType.GENERATE_ASSETS:
          handleGenerateAssetsAction({ action, appStore, backendDispatchContext });
          break;
        case AssetGenerationActionType.GENERATE_STUDIO_ASSETS:
          handleGenerateStudioAssetsAction({ action, appStore, backendDispatchContext, snackbarDispatchContext });
          break;
        case AssetGenerationActionType.IMPORT_STUDIO_ASSET:
          handleImportStudioAssetAction({ action, appStore, backendDispatchContext, snackbarDispatchContext });
          break;
        default:
          console.error(new Error(`Unsupported asset generation action: '${action}'`));
      }
    }),
    {},
  );

  const memoDispatch = useCallback(dispatch, []);

  return {
    state,
    dispatch: memoDispatch,
  };
};

export type AssetGenerationStateContextType = ReturnType<typeof useAssetGenerationContext>['state'];
export type AssetGenerationDispatchContextType = ReturnType<typeof useAssetGenerationContext>['dispatch'];

export const AssetGenerationStateContext = createContext({} as AssetGenerationStateContextType);
export const AssetGenerationDispatchContext = createContext({} as AssetGenerationDispatchContextType);

export const AssetGenerationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { state, dispatch } = useAssetGenerationContext();
  return (
    <AssetGenerationStateContext.Provider value={state}>
      <AssetGenerationDispatchContext.Provider value={dispatch}>{children}</AssetGenerationDispatchContext.Provider>
    </AssetGenerationStateContext.Provider>
  );
};
