import { DEFAULT_REFETCH_INTERVAL, DEFAULT_TIMEOUT, QUERY_KEY_BACKEND_CONTEXT } from 'src/v2/contexts/backend/constants/BackendContext.constants';
import {
  BackendAction,
  BackendActionQueryResult,
  BackendQuery,
  BackendQueryAction,
  Request,
  RequestStatus,
  RequestsLookup,
  SetQueriesType,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { TimeoutError } from 'src/v2/errors/CustomErrors';
import { v6 as uuidV6 } from 'uuid';

export const getRequest = ({ id, requestsLookup }: { id: Request['id']; requestsLookup: RequestsLookup }) => {
  const request = requestsLookup.byId[id];
  if (!request) {
    throw new Error(`Request with id '${id}' not found`);
  }
  return request;
};

export const handleInit = ({ action, requestsLookup }: { action: BackendAction; requestsLookup: RequestsLookup }): Request['id'] => {
  const id = uuidV6();
  const groupId = action.options?.requestGroupId;
  requestsLookup.byId[id] = {
    id,
    action,
    status: RequestStatus.PENDING,
    groupId,
  };
  if (groupId) {
    if (requestsLookup.byGroup[groupId]) {
      requestsLookup.byGroup[groupId].push(id);
    } else {
      requestsLookup.byGroup[groupId] = [id];
    }
  }
  action.onInit?.({ id });
  return id;
};

export const handleError = ({
  err,
  action,
  id,
  requestsLookup,
}: {
  err: unknown;
  action: BackendAction;
  id: Request['id'];
  requestsLookup: RequestsLookup;
}) => {
  if (requestsLookup.byId[id].status === RequestStatus.ABORTED) return;
  if (requestsLookup.byId[id].status !== RequestStatus.PENDING) {
    console.error(`Expected request status to be pending while handling '${action.type}' action with request ID '${id}'`);
    return;
  }
  requestsLookup.byId[id].status = RequestStatus.ERROR;

  const errResponse =
    err instanceof Error
      ? err
      : typeof err === 'string'
        ? new Error(err)
        : new Error(`Error while handling '${action.type}' action with request ID '${id}'`);

  action.onError?.(errResponse);
  return errResponse;
};

export const handleSuccess = ({
  response,
  action,
  id,
  requestsLookup,
}: {
  // Allowing 'any' for response since this is a generic handler.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any;
  action: BackendAction;
  id: Request['id'];
  requestsLookup: RequestsLookup;
}) => {
  if (requestsLookup.byId[id].status === RequestStatus.ABORTED) return;
  if (requestsLookup.byId[id].status !== RequestStatus.PENDING) {
    throw new Error('Expected request status to be pending');
  }
  requestsLookup.byId[id].status = RequestStatus.SUCCESS;
  action.onSuccess?.({ response });
  return response;
};

/**
 * General handler function for react-query query.enabled function
 */
export const handleQueryEnabled = (query: BackendQuery) => {
  const queryResult = query.state.data;
  return !queryResult || queryResult?.requestStatus === RequestStatus.PENDING;
};

/**
 * General handler function for query-type backend actions
 */
export const handleQueryAction = async ({
  action,
  requestsLookup,
  type,
  getQueryResult,
  setQueries,
  options,
}: {
  action: BackendQueryAction;
  requestsLookup: RequestsLookup;
  type: BackendActionQueryResult['type'];
  getQueryResult: (props: { id: string }) => Promise<BackendActionQueryResult>;
  setQueries: SetQueriesType;
  options?: {
    noRefetchInterval: boolean;
  };
}) => {
  const id = handleInit({ action, requestsLookup });
  const refetchInterval = action.options?.refetchInterval ?? DEFAULT_REFETCH_INTERVAL;
  const timeout = action.options?.timeout ?? DEFAULT_TIMEOUT;
  const requestStartTime = Date.now();
  const queryKey = [QUERY_KEY_BACKEND_CONTEXT, type, id];
  setQueries((queries) => {
    return [
      ...queries,
      {
        queryKey,
        queryFn: async () => {
          try {
            const totalRequestTime = Date.now() - requestStartTime;
            if (totalRequestTime >= timeout) {
              throw new TimeoutError(`Action, '${type}', with request ID, '${id}', timed out after ${timeout}ms`);
            }
            const result = await getQueryResult({ id });
            if (result.requestStatus === RequestStatus.SUCCESS) {
              handleSuccess({ response: result.response, action, id, requestsLookup });
            }
            return result;
          } catch (err) {
            const errResponse = handleError({ err, action, id, requestsLookup });
            return { type, response: errResponse, requestStatus: RequestStatus.ERROR };
          } finally {
            const request = getRequest({ id, requestsLookup });
            // clear out the query after it's complete
            if (request.status !== RequestStatus.PENDING) {
              setQueries((queries) => queries.filter((query) => query.queryKey !== queryKey));
            }
          }
        },
        enabled: handleQueryEnabled,
        refetchInterval: !options?.noRefetchInterval && refetchInterval,
        // make sure it refetches in the background (ie: after changing tab), otherwise the queries will timeout
        refetchIntervalInBackground: true,
      },
    ];
  });
};
