import { WorkflowId } from '@amzn/genaihub-typescript-client';
import themeConfigs from 'src/components/editor/UiContols/uiGeneratorControls/themes.json';
import { EffectLookupByValue } from 'src/components/pages/studio/effectSettings/effectSettings';
import themeIcons from 'src/config/themes/themesDropdownList';
import { NO_THEME } from 'src/v2/contexts/backend/constants/BackendContext.constants';
import { AspectRatio, SelectedEffects, StyleOption, WorkflowIdEnum } from 'src/v2/types';

export function constructTextPrompt({ effects, textPrompt }: { effects: SelectedEffects | undefined; textPrompt: string | undefined }) {
  const effectsStr = convertSelectedEffectsToString({ effects });
  if (textPrompt && effectsStr) {
    return [effectsStr, textPrompt].join(', ');
  } else if (textPrompt) {
    return textPrompt;
  } else if (effectsStr) {
    return effectsStr;
  } else return '';
}

export function convertSelectedEffectsToString({ effects }: { effects: SelectedEffects | undefined }) {
  if (!effects) return '';
  return Array.from(Object.values(effects))
    .map((opt) => opt.value)
    .filter((value) => !!value)
    .join(', ');
}

export function getAspectRatioEnumFromString({ aspectRatio }: { aspectRatio: string }): AspectRatio | undefined {
  return Object.values(AspectRatio).find((aspectRatioEnum) => aspectRatioEnum === aspectRatio);
}

export function getEffectsAndTextPromptFromConstructedTextPrompt({ constructedTextPrompt }: { constructedTextPrompt: string | undefined }): {
  effects: SelectedEffects;
  textPrompt: string;
} {
  const effects: SelectedEffects = {};

  if (!constructedTextPrompt) {
    return {
      effects,
      textPrompt: '',
    };
  }

  let textPrompt = constructedTextPrompt;
  for (const [key, value] of Object.entries(EffectLookupByValue)) {
    const sanitizedKey = sanitizeText({ text: key });
    if (textPrompt.includes(sanitizedKey)) {
      // Legacy - effects were added after the textPrompt
      textPrompt = textPrompt.replace(`, ${sanitizedKey}`, '');
      // Current - effects are added before the textPrompt
      textPrompt = textPrompt.replace(`${sanitizedKey}, `, '');
      textPrompt = textPrompt.replace(`${sanitizedKey}`, '');
      effects[value.effectType] = value.effectOption;
    }
  }
  textPrompt = textPrompt.trim();

  return { effects, textPrompt };
}

export const getStyleOptionFromTheme = ({ theme }: { theme: string | undefined }): StyleOption | undefined => {
  if (!theme) return undefined;
  const matchingTheme = themeConfigs.find((themeConfig) => themeConfig.controlValue === theme || themeConfig.customPrompt === theme);
  if (matchingTheme) {
    return {
      icon: themeIcons[matchingTheme.src as keyof typeof themeIcons],
      value: matchingTheme.controlValue,
      label: matchingTheme.controlLabel,
      customThemePrompt: matchingTheme.customPrompt,
    };
  }

  return undefined;
};

export function getThemeFromStyleOption({ style, workflowId }: { style: StyleOption | undefined; workflowId: WorkflowId }) {
  return style ? (workflowId === WorkflowIdEnum.TEXT_TO_IMAGE ? (style.customThemePrompt ?? style.value) : style.value) : NO_THEME;
}

export function sanitizeText({ text }: { text: string | undefined }) {
  if (!text) return '';
  return text.replace(/[^\p{L}\p{N} ,.]/gu, '');
}
