import { Divider, Flex, Heading, Switch } from '@amzn/storm-ui';
import Konva from 'konva';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { SelectionList, SelectionListProps } from 'src/v2/components/SelectionList/SelectionList';
import { MultiImageCanvasProps } from 'src/v2/components/studio/MultiImageCanvas/MultiImageCanvas';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { getProducts } from 'src/v2/redux/slices/product/productSlice';
import { CustomImageProduct, Product, ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import {
  addCustomImageProducts,
  appendProductLayout,
  getAllFullProductLayout,
  getAspectRatio,
  getAutoLayoutEnabled,
  getCustomImageProducts,
  getReferenceImages,
  removeProductLayout,
  setAutoLayout,
  updateProductLayout,
} from 'src/v2/redux/slices/userInput/userInputSlice';
import { ProductLayoutFull, SelectedProductFull } from 'src/v2/redux/slices/userInput/userInputSlice.types';
import styled from 'styled-components';
import { ImportProductCard } from './ImportProductCard';
import { MultiImageCanvas } from '../MultiImageCanvas';
import { ProductDisplay } from './ProductDisplay';
import {
  CanvasSize,
  createNewLayout,
  getAllImagesFromFullProduct,
  getCanvasSize,
  getImageFromProduct,
  getProcessedImageFromLayout,
} from './productSelectorHelpers';
import { useASINLookup } from './useASINLookup';

const CanvasContainer = styled.div<{ display: boolean }>`
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: 400px;
  overflow: hidden;
  background: var(--surface-tertiary);
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.display ? 'flex' : 'none')};
`;

// Add offset to each new layout to avoid overlap;
const NEW_LAYOUT_OFFSET = 20;
const ProductSelectorWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
  width: 100%;
  overflow-x: hidden;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label {
    color: var(--text-primary, 'unset');
  }
`;

const RecentlyUsedProductContainer = styled(Flex)``;
const layoutMapper = (layout: ProductLayoutFull): ProductLayoutFull & SelectionListProps['list'][number] => {
  const { product } = layout;
  if (product.type === ProductType.ASIN) {
    const { metadata, asin } = product;
    return {
      title: metadata.title || '',
      subtitle: `asin ${asin}`,
      thumbnail: getImageFromProduct(product),
      ...layout,
    };
  } else {
    const { customImage } = product;
    return {
      title: 'Uploaded product image',
      subtitle: customImage.name,
      thumbnail: customImage.url,
      ...layout,
    };
  }
};
const AutoLayoutSwitchWrapper = styled(Flex)`
  && button {
    background: var(--background-level-1-reverse, #161d26);
    &:hover {
      background: var(--background-level-1-reverse, #161d26);
    }
  }
  && > div {
    flex-direction: row-reverse;
    gap: 6px;
  }
`;
const deadZone = 20;

export const ProductSelector = ({ setLoading }: { setLoading?: (state: boolean) => void }) => {
  const aspectRatio = useAppSelector(getAspectRatio);
  const fullLayouts = useAppSelector(getAllFullProductLayout);
  const products = useAppSelector(getProducts);
  const customImageProducts = useAppSelector(getCustomImageProducts);
  const canvasRef = useRef<Konva.Stage>(null);
  const transformer = canvasRef?.current?.findOne(`#transformer`) as Konva.Transformer | undefined;
  const cleanCanvasSelection = () => {
    transformer?.nodes([]);
  };

  const autoLayoutEnabled = useAppSelector(getAutoLayoutEnabled);
  const backendClient = useAIBackendHubClient();
  const dispatch = useAppDispatch();

  const [loadingImage, setImageLoading] = useState<boolean>(false);

  // make canvas responsive
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        const { width, height } = ref.current.getBoundingClientRect();
        setSize({ width, height });
      }
    };
    // Initial width setup
    updateWidth();
    // Add event listener using proper method
    window.addEventListener('resize', updateWidth);
    // Cleanup event listener
    return () => window.removeEventListener('resize', updateWidth);
  }, [ref, autoLayoutEnabled, aspectRatio]);

  const selectedImages = fullLayouts
    .map((layout) => {
      const { boundingBox, zIndex, id, product, processedImages } = layout;
      const originalImage = getImageFromProduct(product);
      if (!originalImage || !processedImages) return;
      const { image } = processedImages?.[originalImage] || {};
      return {
        boundingBox,
        zIndex,
        src: image,
        id: id,
      };
    })
    .filter((image): image is MultiImageCanvasProps['images'][number] => image !== undefined);
  const selectedProductList = fullLayouts.map((layout) => layoutMapper(layout));
  const recentlyUsedProducts = useMemo(
    () =>
      products.map((product) => {
        const { metadata, asin } = product;
        return {
          title: metadata.title,
          subtitle: `Asin: ${asin}`,
          thumbnail: getImageFromProduct(product),
          ...product,
        };
      }),
    [products],
  );
  const recentlyUsedCustomImageProducts = useMemo(
    () =>
      customImageProducts.map((product) => {
        const { customImage } = product;
        return {
          title: customImage.name,
          subtitle: `File name: ${customImage.name}`,
          thumbnail: customImage.url,
          ...product,
        };
      }),
    [customImageProducts],
  );

  const saveNewLayout = (product: Product) => {
    const offset = fullLayouts.length * NEW_LAYOUT_OFFSET;
    createNewLayout({ offset, product, deadZone, aspectRatio, setLoading: setImageLoading, backendClient }).then(
      (layout) => layout && dispatch(appendProductLayout(layout)),
    );
  };

  const updateLayoutWithNewProductSettings = (currentLayout: ProductLayoutFull, product: SelectedProductFull) => {
    createNewLayout({
      product: product,
      deadZone,
      aspectRatio,
      setLoading: setImageLoading,
      backendClient,
    }).then((layout) => {
      if (!layout) return;
      const { boundingBox, product, processedImages } = layout;
      dispatch(
        updateProductLayout({
          id: currentLayout.id,
          changes: {
            boundingBox: {
              ...currentLayout.boundingBox,
              width: boundingBox.width,
              height: boundingBox.height,
            },
            product,
            processedImages: {
              ...processedImages,
              ...layout.processedImages,
            },
          },
        }),
      );
    });
  };

  const { loading: loadingASIN, searchASIN } = useASINLookup({
    postASINLookup: saveNewLayout,
  });

  const reference = useAppSelector(getReferenceImages);

  // if no product is selected, adding new product should be disabled only when loadingImage or loadingASIN is true.
  // if one product is selected, adding should be disabled when there is any reference images
  const disableAddNewProduct = loadingImage || loadingASIN || (fullLayouts.length > 0 && reference.length > 0) || fullLayouts.length >= 10;
  const layoutsWithProductBackground = useMemo(() => fullLayouts.filter((layout) => !getProcessedImageFromLayout(layout)).length > 0, [fullLayouts]);

  useEffect(() => {
    setLoading?.(loadingImage || loadingASIN || layoutsWithProductBackground);
  }, [loadingImage, loadingASIN, layoutsWithProductBackground]);
  return (
    <ProductSelectorWrapper>
      <Flex direction="column" rowGap="medium" grow={1} alignItems="stretch">
        <ImportProductCard
          disabled={disableAddNewProduct}
          onSelectFiles={({ files }) => {
            const products = files.map((file) => {
              const url = URL.createObjectURL(file);
              const product: CustomImageProduct = {
                type: ProductType.CUSTOM_IMAGE,
                customImage: {
                  name: file.name,
                  size: file.size,
                  url,
                },
                id: url,
              };
              return product;
            });

            dispatch(addCustomImageProducts(products));
            products.forEach((product) => saveNewLayout(product));
          }}
          searchClickHandler={searchASIN}
          loading={loadingASIN}
        />

        {(fullLayouts.length > 0 || loadingImage) && (
          <>
            <Flex direction="column" rowGap="xsmall" alignItems="stretch">
              <Flex>
                <Flex grow={1}>
                  <Heading size="small" renderAs="h6">
                    Selected products
                  </Heading>
                </Flex>
                <AutoLayoutSwitchWrapper>
                  <Switch
                    disabled={fullLayouts.length > 1}
                    id={'auto-layout-switch'}
                    toggled={autoLayoutEnabled}
                    onChange={(toggled) => dispatch(setAutoLayout(toggled))}
                    label={'Auto Layout'}
                  />
                </AutoLayoutSwitchWrapper>
              </Flex>

              <CanvasContainer display={!autoLayoutEnabled} ref={ref}>
                <MultiImageCanvas
                  ref={canvasRef}
                  allowOutOfBounds
                  images={selectedImages}
                  loading={loadingImage || layoutsWithProductBackground}
                  onReposition={({ boundingBox, zIndex, id }) => {
                    dispatch(
                      updateProductLayout({
                        id,
                        changes: {
                          boundingBox,
                          zIndex,
                        },
                      }),
                    );
                  }}
                  canvasOptions={{
                    ...getCanvasSize({
                      ...size,
                      aspectRatio,
                      padding: 10,
                    }),
                  }}
                  outputOptions={CanvasSize[aspectRatio]}
                />
              </CanvasContainer>

              {(loadingImage || layoutsWithProductBackground) && 'Loading Product...'}
              {selectedProductList.map((item) => {
                return (
                  <ProductDisplay
                    key={item.id}
                    {...item}
                    onClick={() => {
                      dispatch(removeProductLayout(item.id));
                      cleanCanvasSelection();
                    }}
                    type="multiple"
                    selected
                    onSave={(newProduct) => {
                      updateLayoutWithNewProductSettings(item, newProduct);
                      cleanCanvasSelection();
                    }}
                    onUpload={({ file }) => {
                      const url = URL.createObjectURL(file);
                      const product = item.product;
                      const hasCustomImage = product.options?.asinProduct?.customProductImageUrl;
                      const currentImages = getAllImagesFromFullProduct(product);
                      const newProduct: SelectedProductFull = {
                        ...item.product,
                        options: {
                          asinProduct: {
                            customProductImageUrl: url,
                            selectedImageIndex: hasCustomImage ? currentImages.length - 1 : currentImages.length,
                          },
                        },
                      };
                      updateLayoutWithNewProductSettings(item, newProduct);
                      cleanCanvasSelection();
                    }}
                  />
                );
              })}
            </Flex>
            <Divider />
          </>
        )}
        <RecentlyUsedProductContainer direction="column" alignItems={'stretch'} rowGap={'small'}>
          <Heading size="small" renderAs="h6">
            Recently used
          </Heading>
          <SelectionList<Product>
            list={[...recentlyUsedCustomImageProducts, ...recentlyUsedProducts]}
            type={'multiple'}
            onClick={saveNewLayout}
            mode="add"
            disabled={disableAddNewProduct}
          />
        </RecentlyUsedProductContainer>
      </Flex>
    </ProductSelectorWrapper>
  );
};
