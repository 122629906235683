import { ControlValue } from '@amzn/genaihub-typescript-client';
import { Button as StormButton } from '@amzn/storm-ui';
import { useState, useContext, useEffect } from 'react';
import { EditorContextP1, EditorContextStateP1 } from 'src/components/editor/EditorContext';
import { ControlComponentProps } from 'src/components/editor/UiContols/uiGeneratorControls/controlComponentTypes';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import guideHelper from 'src/components/utils/guideHelper';
import Stack from 'src/customUIComponents/Stack';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import ControlLabel from './UI/ControlLabel';

const RadioGroup = (props: ControlComponentProps) => {
  const { control, bulkUpdateCallback } = props;
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = isDarkModeLaunched && useAppSelector(isDarkModeActive);

  const editorContext: EditorContextStateP1 = useContext(EditorContextP1);
  const controlValues = editorContext.workflowOptions;
  const [selected, setSelected] = useState<number>();

  useEffect(() => {
    if (editorContext.activateGuide) {
      guideHelper(control.controlValues, editorContext.activateGuide, guideCallback);
    }
  }, [editorContext.activateGuide]);

  const guideCallback = async (config: ControlValue | undefined) => {
    if (config) {
      batchStateUpdate({ [control.controlName]: { value: config.value } }, bulkUpdateCallback);
      setSelected(control.controlValues?.findIndex((controlValue: ControlValue) => controlValue.guide));
    }
  };

  useEffect(() => {
    if (!editorContext.activateGuide) {
      setSelectedOnEnable();
    }
  }, [editorContext.disableControl]);

  const setSelectedOnEnable = () => {
    let value;
    let selectedControlIndex: number = -1;
    if (control.defaultValue) {
      selectedControlIndex = control.controlValues?.findIndex((controlValue: ControlValue) => controlValue.value == control.defaultValue) ?? -1;
    } else {
      selectedControlIndex = control.controlValues?.findIndex((controlValue: ControlValue) => controlValue.defaultValue) ?? -1;
    }

    if (selectedControlIndex < 0) {
      return;
    }

    setSelected(selectedControlIndex);
    value = control.controlValues?.[selectedControlIndex]?.value;

    if (value) {
      const controlName = control.controlName;
      const controlData = { [controlName]: { value } };
      editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
    }
  };

  const handleClick = (index: number, value: string) => {
    const controlName = control.controlName;
    const controlData = { [controlName]: { value } };
    editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
    setSelected(index);
  };

  const displayButton = control.controlValues?.map((controlValue: ControlValue, index: number) => {
    return isDarkMode ? (
      <Button
        key={'button' + index}
        label={controlValue.label}
        type={ButtonTypes.Secondary}
        disabled={editorContext.disableControl}
        dataTestId={'button' + index}
        onClick={() => handleClick(index, controlValue.value ?? '')}
        style={{ borderColor: !editorContext.disableControl && index === selected ? 'var(--interactive-primary)' : 'unset' }}
      />
    ) : (
      <StormButton
        onClick={() => handleClick(index, controlValue.value ?? '')}
        key={'button' + index}
        style={{
          padding: '6px 12px',
          border: 1,
          borderColor: !editorContext.disableControl && index === selected ? '#6236FF' : '#f1f3f5',
          borderStyle: 'solid',
          backgroundColor: 'white',
          fontSize: '14px',
        }}
        disabled={editorContext.disableControl}
        data-testid={'button' + index}
      >
        {controlValue.label}
      </StormButton>
    );
  });

  return (
    <div>
      <ControlLabel title={control.controlLabel} />
      <Stack style={{ justifyContent: 'left', flexDirection: 'row', flexWrap: 'wrap', gap: '2px' }}>{displayButton}</Stack>
    </div>
  );
};

export default RadioGroup;
