import { BannerAlert, Input, TextArea, Divider, Tooltip } from '@amzn/storm-ui';
import { FadingDiv } from 'src/v2/utils/animation';
import styled from 'styled-components';
import { StylingWidgetProps } from './Widgets/types';

export const InputFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  border-radius: 8px;

  & input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & :focus-within,
  textarea:focus-within {
    outline: none;
  }
`;

export const InputField = styled(Input)`
  input {
    color: var(--text-primary, 'unset') !important;
  }

  input::placeholder {
    color: var(--text-disabled, 'unset') !important;
  }

  & > div {
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0 10px;
    margin: 0;
    margin-left: -10px;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
`;

export const InputFieldArea = styled(TextArea)`
  textarea {
    color: var(--text-primary, 'unset');
    background: none;
    resize: none;
    background-color: var(--surface-tertiary);
    border: none;
    outline: none;
    font-size: var(--size-text-lg);
    font-weight: var(--weight-regular);
    line-height: var(--line-text-lg);
  }

  textarea::placeholder {
    color: var(--text-disabled, 'unset') !important;
  }

  & > div {
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0 10px;
    margin: 0;
    margin-left: -10px;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
`;

export const WidgetContainer = styled.div<{ isDarkMode: boolean }>`
  max-width: 740px;
  margin: auto;
  background-color: var(--surface-secondary, #ffffff);
  color: var(--text-primary);
  border: none;
  border-radius: 16px;
  margin-bottom: 50px;
  box-sizing: border-box;
  font-size: 16px;
  transition: background-color 0.3s ease;
  user-select: none;
  z-index: 4;
  pointer-events: auto;
  position: relative;
  overflow: hidden;
  box-shadow:
    0px 13px 20px 0px rgba(22, 29, 38, 0.06),
    0px 10px 70px -19px rgba(22, 29, 38, 0.2);
  backdrop-filter: blur(10px);

  *:focus {
    outline: none;
  }

  input {
    color: var(--textColor-unavailable, #676b6f);
    font-family: 'Amazon Ember';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
    opacity: 0.95;
    border: none;
    outline: none;
    box-shadow: none !important;
  }

  [role='separator'] {
    padding: 0;
    margin: 0;
    margin-block: unset;
  }

  @media (max-width: 1100px) {
    max-width: 100%;
    margin-bottom: auto;
  }
`;

export const WidgetContainerBody = styled.div`
  padding: 12px 16px;
`;

export const WidgetContainerFooter = styled.div`
  padding: 12px 16px;
`;

export const WidgetHeaderContent = styled.div`
  width: 100%;
`;

export const WidgetBodyContent = styled.div<Partial<StylingWidgetProps>>`
  overflow-y: auto;
  height: ${({ height }) => height}px;

  && [data-testid='thumbnail-container'] {
    background-color: var(--surface-tertiary, 'unset');
    border-color: var(--border-strong, 'unset');
    border-radius: 8px;
  }
`;

export const BannerAlertView = styled(BannerAlert)`
  background: transparent;
  padding: 5px 0;

  p {
    color: var(--text-primary);
  }

  && path {
    fill: #ab561e;
  }
`;

export const DividerView = styled(Divider)`
  div {
    background-color: var(--border-medium);
  }
`;

export const DividerVertical = styled.span<{ height?: string; padding?: string }>`
  width: 1px;
  height: ${({ height }) => height ?? '30px'};
  border-right: solid 1px var(--border-medium);
  padding-right: ${({ padding }) => padding ?? 'none'};
  box-sizing: border-box;
`;

export const WidgetControlWrapper = styled(FadingDiv)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const WidgetControlAspectRatioText = styled.span`
  font-size: var(--size-text-xs);
  font-style: normal;
  font-weight: var(--weight-bold);
  line-height: var(--line-text-sm);
  cursor: pointer;
`;

export const TooltipWrapper = styled(Tooltip)<{ darkMode: boolean }>`
  border: none;
  outline: none;
  border-radius: 8px;
  && div,
  span {
    border-radius: 8px;
    border: none;
    outline: none;

    background-color: var(--button-default-background);
    color: var(--button-default-text);
  }
`;
