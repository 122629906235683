import { SubmitWorkflowByIdInput } from '@amzn/genaihub-typescript-client';
import _cloneDeep from 'lodash/cloneDeep';
import type { SubmitWorkflowByIdAction } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import {
  ApparelWorkflowOptions,
  BrandIdentityTextToImageWorkflowOptions,
  GenerativeResizingWorkflowOptions,
  GuidedTextGenerationWorkflowOptions,
  ImageEditorAddActionWorkflowOptions,
  ImageEditorRemoveActionWorkflowOptions,
  ImageEditorWorkflowOptions,
  ImageThemingWorkflowOptions,
  LifestyleImageryPromptOnlyWorkflowOptions,
  LifestyleImageryWorkflowOptions,
  MultiProductWorkflowOptions,
  ParallaxMotionWorkflowOptions,
  ProductEditWorkflowOptions,
  TaskType,
  TextToImageWorkflowOptions,
  WorkflowOptions,
} from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { WorkflowIdEnum } from 'src/v2/types';

export function normalizePayloadForSubmitWorkflowById({ payload }: { payload: SubmitWorkflowByIdAction['payload'] }) {
  const normalizedPayload = _cloneDeep(payload) as SubmitWorkflowByIdInput;
  const normalizedWorkflowOptions = normalizeWorkflowOptions({
    workflowOptions: payload.body.workflowOptions,
  });
  normalizedPayload.body = { workflowOptions: normalizedWorkflowOptions };
  return normalizedPayload;
}

export function normalizeWorkflowOptions({ workflowOptions }: { workflowOptions: WorkflowOptions }) {
  switch (workflowOptions.workflowId) {
    case WorkflowIdEnum.APPAREL:
      return normalizeApparelWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.BRAND_IDENTITY_TEXT_TO_IMAGE:
      return normalizeBrandIdentityTextToImageWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.GENERATIVE_RESIZING:
      return normalizeGenerativeResizingWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.GUIDED_TEXT_GENERATION:
      return normalizeGuidedTextGenerationWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.IMAGE_EDITOR:
      if (workflowOptions.taskType === TaskType.INPAINTING) {
        return normalizeImageEditorAddActionWorkflowOptions({ workflowOptions });
      } else if (workflowOptions.taskType === TaskType.REMOVAL) {
        return normalizeImageEditorRemoveActionWorkflowOptions({ workflowOptions });
      } else {
        console.warn('Unknown task type. Sending workflow options back without normalizing');
        return workflowOptions;
      }
    case WorkflowIdEnum.IMAGE_THEMING:
      return normalizeImageThemingWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.LIFESTYLE_IMAGERY_PROMPT_ONLY:
      return normalizeLifestyleImageryPromptOnlyWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.LIFESTYLE_IMAGERY:
      return normalizeLifestyleImageryWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.PARALLAX_MOTION:
      return normalizeParallaxMotionWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.PRODUCT_EDIT:
      return normalizeProductEditWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.TEXT_TO_IMAGE:
      return normalizeTextToImageWorkflowOptions({ workflowOptions });
    case WorkflowIdEnum.MULTI_PRODUCT:
      return normalizeMultiProductWorkflowOptionsHelper({ workflowOptions });
    default:
      console.warn('Unknown workflow ID. Sending workflow options back without normalizing');
      return workflowOptions;
  }
}

// HELPER FUNCTIONS

/** APPAREL */
function normalizeApparelWorkflowOptions({ workflowOptions }: { workflowOptions: ApparelWorkflowOptions }) {
  return {
    asin: workflowOptions.asin,
    aspect_ratio: workflowOptions.aspectRatio,
    num_of_images: workflowOptions.imageCount,
    product_image: workflowOptions.productImageRef,
    reference_image: workflowOptions.referenceImageRef,
    text_prompt: workflowOptions.textPrompt,
    themes: workflowOptions.theme,
    reference_images: workflowOptions.referenceImagesRef,
    reference_images_strength: workflowOptions.referenceImagesStrength,
  };
}

/** GENERATIVE_RESIZING **/
function normalizeGenerativeResizingWorkflowOptions({ workflowOptions }: { workflowOptions: GenerativeResizingWorkflowOptions }) {
  return {
    aspect_ratio: workflowOptions.aspectRatio,
    imageCount: workflowOptions.imageCount,
    imageRef: workflowOptions.inputImageRef,
    layout: workflowOptions.imageLayout,
    mask_ref: undefined,
    seed: workflowOptions.seed,
  };
}

/** GUIDED_TEXT_GENERATION **/
function normalizeGuidedTextGenerationWorkflowOptions({ workflowOptions }: { workflowOptions: GuidedTextGenerationWorkflowOptions }) {
  return {
    asin: workflowOptions.asin,
    feature_bullets: workflowOptions.featureBullets,
    product_image: workflowOptions.productImageRef,
    productType: workflowOptions.productType,
    prompt_count: workflowOptions.promptCount,
    prompt_type: workflowOptions.promptType,
    theme: workflowOptions.theme,
    title: workflowOptions.title,
  };
}

/** IMAGE_EDITOR **/
function normalizeImageEditorWorkflowOptions({ workflowOptions }: { workflowOptions: ImageEditorWorkflowOptions }) {
  return {
    image_count: workflowOptions.imageCount,
    image_ref: workflowOptions.inputImageRef,
    mask_ref: workflowOptions.maskRef,
    product_mask_ref: undefined,
    seed: workflowOptions.seed,
    task_type: workflowOptions.taskType,
    prompt: workflowOptions.textPrompt,
  };
}

function normalizeImageEditorAddActionWorkflowOptions({ workflowOptions }: { workflowOptions: ImageEditorAddActionWorkflowOptions }) {
  return {
    ...normalizeImageEditorWorkflowOptions({ workflowOptions }),
  };
}

function normalizeImageEditorRemoveActionWorkflowOptions({ workflowOptions }: { workflowOptions: ImageEditorRemoveActionWorkflowOptions }) {
  return {
    ...normalizeImageEditorWorkflowOptions({ workflowOptions }),
  };
}

/** IMAGE_THEMING **/
function normalizeImageThemingWorkflowOptions({ workflowOptions }: { workflowOptions: ImageThemingWorkflowOptions }) {
  return {
    input_image: workflowOptions.inputImageRef,
    seed: workflowOptions.seed,
    strength: workflowOptions.strength,
    theme: workflowOptions.theme,
  };
}

/** LIFESTYLE_IMAGERY_PROMPT_ONLY and LIFESTYLE_IMAGERY **/
function normalizeLifestyleImageryWorkflowOptionsHelper({
  workflowOptions,
}: {
  workflowOptions: LifestyleImageryPromptOnlyWorkflowOptions | LifestyleImageryWorkflowOptions;
}) {
  return {
    aspect_ratio: workflowOptions.aspectRatio,
    description: workflowOptions.description,
    image_count: workflowOptions.imageCount,
    prompt: workflowOptions.textPrompt,
    rewriteUserCustomPrompt: workflowOptions.rewriteUserCustomPrompt.toString(),
    theme: workflowOptions.theme,
    title: workflowOptions.title,
  };
}

function normalizeLifestyleImageryPromptOnlyWorkflowOptions({ workflowOptions }: { workflowOptions: LifestyleImageryPromptOnlyWorkflowOptions }) {
  return {
    ...normalizeLifestyleImageryWorkflowOptionsHelper({ workflowOptions }),
  };
}

function normalizeLifestyleImageryWorkflowOptions({ workflowOptions }: { workflowOptions: LifestyleImageryWorkflowOptions }) {
  return {
    ...normalizeLifestyleImageryWorkflowOptionsHelper({ workflowOptions }),
    asin: workflowOptions.asin,
    product_image: workflowOptions.productImageRef,
  };
}

/** PARALLAX_MOTION **/
function normalizeParallaxMotionWorkflowOptions({ workflowOptions }: { workflowOptions: ParallaxMotionWorkflowOptions }) {
  return {
    camera_path: workflowOptions.cameraPath,
    height: workflowOptions.height,
    image_ref: workflowOptions.inputImageRef,
    mask_ref: workflowOptions.maskRef,
    motion_intensity: workflowOptions.motionIntensity,
    out_duration: workflowOptions.outDuration,
    out_fps: workflowOptions.outFps,
    width: workflowOptions.width,
  };
}

/** PRODUCT_EDIT **/
function normalizeProductEditWorkflowOptions({ workflowOptions }: { workflowOptions: ProductEditWorkflowOptions }) {
  return {
    boundingBox: workflowOptions.boundingBox,
    imageId: workflowOptions.catwalkImageId,
    image_count: workflowOptions.imageCount,
  };
}

/** TEXT_TO_IMAGE and BRAND_IDENTITY_TEXT_TO_IMAGE **/
function normalizeTextToImageWorkflowOptionsHelper({
  workflowOptions,
}: {
  workflowOptions: TextToImageWorkflowOptions | BrandIdentityTextToImageWorkflowOptions;
}) {
  return {
    asin: workflowOptions.asin,
    aspect_ratio: workflowOptions.aspectRatio,
    num_of_images: workflowOptions.imageCount,
    product_image: workflowOptions.productImageRef,
    reference_image: workflowOptions.referenceImageRef,
    text_prompt: workflowOptions.textPrompt,
    themes: workflowOptions.theme,
    reference_images: workflowOptions.referenceImagesRef,
    reference_images_strength: workflowOptions.referenceImagesStrength,
  };
}

/** MULTI_PRODUCT **/
function normalizeMultiProductWorkflowOptionsHelper({ workflowOptions }: { workflowOptions: MultiProductWorkflowOptions }) {
  return {
    num_of_images: workflowOptions.numberOfImages,
    aspect_ratio: workflowOptions.aspectRatio,
    products: workflowOptions.products.map((product) => ({
      asin: product.asin,
      product_image: product.image,
      mask_image: product.mask,
      prompt: product.prompt,
      theme: product.theme,
      feature_bullets: product.features,
      boundingBox: product.boundingBox,
      title: product.title,
    })),
  };
}

function normalizeTextToImageWorkflowOptions({ workflowOptions }: { workflowOptions: TextToImageWorkflowOptions }) {
  return {
    ...normalizeTextToImageWorkflowOptionsHelper({ workflowOptions }),
  };
}

function normalizeBrandIdentityTextToImageWorkflowOptions({ workflowOptions }: { workflowOptions: BrandIdentityTextToImageWorkflowOptions }) {
  return {
    ...normalizeTextToImageWorkflowOptionsHelper({ workflowOptions }),
  };
}
