import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import { AssetGenerationActionType } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { AssetGeneration } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice.types';
import type { RootState } from 'src/v2/redux/store';
import { WorkflowIdEnum } from 'src/v2/types';

const assetGenerationsAdapter = createEntityAdapter<AssetGeneration>({});
const initialState = assetGenerationsAdapter.getInitialState({});
const assetGenerationsSlice = createSlice({
  name: 'assetGenerations',
  initialState,
  reducers: (create) => ({
    addOneAssetGeneration: create.preparedReducer((assetGeneration: AssetGeneration) => {
      const serializableAssetGeneration = prepareSerializableAssetGeneration({ assetGeneration });
      return { payload: serializableAssetGeneration };
    }, assetGenerationsAdapter.addOne),
    updateOneAssetGeneration: assetGenerationsAdapter.updateOne,
  }),
});

export const { addOneAssetGeneration, updateOneAssetGeneration } = assetGenerationsSlice.actions;

export const { selectById: getAssetGenerationById, selectEntities: getAssetGenerationEntities } = assetGenerationsAdapter.getSelectors(
  (state: RootState) => state.assetGenerations,
);

export default assetGenerationsSlice.reducer;

// HELPERS
function prepareSerializableAssetGeneration({ assetGeneration }: { assetGeneration: AssetGeneration }): AssetGeneration {
  const serializedAssetGeneration = _cloneDeep(assetGeneration);
  serializedAssetGeneration.action = _pick(serializedAssetGeneration.action, 'generationConfigs', 'options', 'type');
  if (serializedAssetGeneration.action.type === AssetGenerationActionType.GENERATE_ASSETS) {
    serializedAssetGeneration.action.generationConfigs.forEach((generationConfig) => {
      switch (generationConfig.workflowId) {
        case WorkflowIdEnum.IMPORT_IMAGE: {
          generationConfig.options.progressCallback = undefined;
          break;
        }
      }
    });
  }
  return serializedAssetGeneration;
}
