import { spinner } from '@amzn/storm-ui-icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { Metrics } from 'src/constants';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { Icon } from 'src/v2/components/Icon/Icon';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { IconSize } from 'src/v2/components/Icon/types';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import {
  clearEffectSelection,
  clearStyleSelection,
  getAllFullProductLayout,
  removeProductLayout,
  removeReferenceImage,
} from 'src/v2/redux/slices/userInput/userInputSlice';
import { EffectType, SelectedEffects, StyleOption } from 'src/v2/types';
import styled from 'styled-components';

interface Products {
  products: ProductItems[];
}

interface ProductItems {
  id: string;
  src: string;
  title: string;
}

interface Styles {
  style: StyleOption;
}

interface Effects {
  effects: SelectedEffects;
}

interface ReferenceImages {
  referenceImages: ReferenceImageItem[];
}

interface ReferenceImageItem {
  id: string;
  src: string;
  strength: string;
}

export type SelectedItemTypes = Products | Styles | Effects | ReferenceImages;

interface SelectedInputsListType {
  items: SelectedItemTypes[];
  height: number;
  isMobile: boolean;
  setSelectedInputsHeight: (value: number) => void;
  onClick: () => void;
}

interface SelectedItems {
  type: 'none' | 'product' | 'style' | 'effect' | 'reference';
  order: number;
  data: SelectedItem;
}
interface SelectedItem {
  id?: string;
  icon?: string;
  label?: string;
  value: string;
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  pointer-events: auto;

  &&:hover {
    cursor: pointer;
  }
`;

const ItemContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  background: var(--surface-tertiary);
  border-radius: 8px;
  padding: 3px;
  border: solid 1px var(--border-medium);
  cursor: pointer;
  position: relative;

  &:hover {
    border: ${({ isMobile }) => (isMobile ? 'solid 1px var(--border-medium)' : 'solid 1px var(--border-strong)')};
  }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5px;
`;

const Title = styled.span`
  color: var(--text-tertiary, #676b6f);
  font-family: 'Amazon Ember';
  font-size: var(--size-text-xs, 12px);
  font-style: normal;
  font-weight: var(--weight-regular, 400);
  line-height: var(--line-text-sm, 18px);
`;

const SubTitle = styled.span`
  color: var(--text-primary, #161d26);
  font-family: 'Amazon Ember';
  font-size: var(--size-text-sm, 13px);
  font-style: normal;
  font-weight: var(--weight-regular, 400);
  line-height: var(--line-text-sm, 18px);
`;

const RemoveButton = styled.button`
  display: block;
  position: relative;
  background: transparent;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
  color: var(--button-default-background);
`;

const RemoveButtonDesktop = styled.button`
  display: none;
  position: absolute;
  top: -14px;
  right: -12px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: var(--button-default-background);
  background: none;

  ${ItemContainer}:hover & {
    display: block;
  }

  /* Ensure keyboard focus also shows the button for accessibility */
  ${ItemContainer}:focus-within & {
    display: block;
  }
`;

export const SelectedInputsList = ({ items, isMobile, setSelectedInputsHeight, onClick }: SelectedInputsListType) => {
  const [selectedItems, setSelectedItems] = useState<SelectedItems[]>();
  const dispatch = useAppDispatch();
  const selectedItemsContainerRef = useRef<HTMLDivElement>(null);
  const appContext = useContext(AppContext);
  const fullLayouts = useAppSelector(getAllFullProductLayout);

  // converts the various selected inputs for render
  const transformSelectedItems = () => {
    const transformedItems: SelectedItems[] = [];

    // adds empty product placeholder
    if (items.length === 0 || fullLayouts.length === 0) {
      transformedItems.push({
        type: 'none',
        order: 0,
        data: {
          label: 'Add a product',
          value: '',
        },
      });
    }

    items.forEach((item: SelectedItemTypes) => {
      if ('products' in item && item.products) {
        item.products.forEach((product) => {
          transformedItems.push({
            type: 'product',
            order: 1,
            data: {
              icon: product.src,
              label: 'Product',
              value: product.title,
              id: product.id,
            },
          });
        });
      }
      if ('style' in item && item.style) {
        transformedItems.push({
          type: 'style',
          order: 3,
          data: {
            icon: item.style.icon,
            label: 'Style',
            value: item.style.label,
          },
        });
      }
      if ('effects' in item && item.effects) {
        Object.entries(item.effects).forEach(([effectCategory, effectOptions]) => {
          transformedItems.push({
            type: 'effect',
            order: 4,
            data: {
              label: effectCategory,
              value: effectOptions.label,
              icon: effectOptions.icon,
            },
          });
        });
      }
      if ('referenceImages' in item && item.referenceImages) {
        item.referenceImages.forEach((referenceImage) => {
          transformedItems.push({
            type: 'reference',
            order: 2,
            data: {
              icon: referenceImage.src,
              label: 'Reference',
              value: `Strength: ${referenceImage.strength}`,
              id: referenceImage.id,
            },
          });
        });
      }
    });
    return transformedItems.sort(({ order: a }, { order: b }) => a - b);
  };

  // listens for updates to items and applies the transformation above
  useEffect(() => {
    if (items) {
      setSelectedItems(transformSelectedItems());
    }
  }, [items]);

  // invokes the callback function with the selected items row height
  useEffect(() => {
    const itemsContainerheight = selectedItemsContainerRef?.current?.clientHeight;
    if (itemsContainerheight) {
      setSelectedInputsHeight(itemsContainerheight);
    }
  }, [selectedItems, selectedItemsContainerRef.current?.clientHeight]);

  const handleItemClick = (item: SelectedItems) => {
    if (item.type === 'none') {
      onClick();
      return;
    }

    // clear reference image
    if (item.type === 'reference' && item.data.id) {
      appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.RemoveReferenceImage]: 1 });
      dispatch(removeReferenceImage(item.data.id));
      return;
    }

    // clear product
    if (item.type === 'product' && item.data.id) {
      appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.RemoveProduct]: 1 });
      dispatch(removeProductLayout(item.data.id));
      return;
    }

    // clear style
    if (item.type === 'style') {
      appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.RemoveStyle]: 1 });
      dispatch(clearStyleSelection());
      return;
    }

    // clear effect
    if (item.type === 'effect') {
      appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.RemoveEffect]: 1 });
      dispatch(clearEffectSelection(item.data.label as EffectType));
    }
  };

  return (
    <RowContainer ref={selectedItemsContainerRef}>
      {selectedItems &&
        selectedItems.map((item, index) => {
          const testId = item.data.label ? `studio-${item.data.label.toLowerCase()}-applied` : `studio-asin-applied`;
          return (
            <ItemContainer key={index} onClick={() => handleItemClick(item)} data-testid={testId} isMobile={isMobile}>
              <IconWrapper>
                {item.type !== 'none' && items && items.length > 0 ? (
                  item.data.icon === 'loading' ? (
                    <Icon type={spinner} />
                  ) : (
                    <img src={item.data.icon} alt={item.data.label} />
                  )
                ) : (
                  <Icon type={iconTypes.addProduct} />
                )}
              </IconWrapper>
              {item.data.label && (
                <TextContainer>
                  <Title>{item.data.label}</Title>
                  <SubTitle>{item.data.value}</SubTitle>
                </TextContainer>
              )}
              {items && items.length > 0 && (
                <>
                  {isMobile && item.type !== 'none' && (
                    <RemoveButton aria-label={`Remove ${item.data.label}`}>
                      <Icon type={iconTypes.close} size={IconSize.medium} />
                    </RemoveButton>
                  )}
                  {!isMobile && item.type !== 'none' && (
                    <RemoveButtonDesktop aria-label={`Remove ${item.data.label}`}>
                      <Button
                        type={ButtonTypes.Primary}
                        icon={iconTypes.close}
                        iconSize="20px"
                        iconOnly
                        iconOnlyRounded
                        iconOnlyBackgroundColor="var(--button-default-background)"
                        iconOnlyColor="var(--button-default-text)"
                        onClick={() => {}}
                      />
                    </RemoveButtonDesktop>
                  )}
                </>
              )}
            </ItemContainer>
          );
        })}
    </RowContainer>
  );
};
