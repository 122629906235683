import { Asset } from '@amzn/genaihub-typescript-client';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { DEFAULT_PAGE_SIZE } from 'src/v2/contexts/feed/constants/FeedContext.constants';
import { convertBackendAssetsToFrontendAssets } from 'src/v2/contexts/feed/util/asset/Asset.utils';
import { handleRetrieveAssets } from 'src/v2/contexts/feed/util/backend/RetrieveAssets';
import { convertASINMetadataListToMap, getASINMetadataListFromBackendAssets } from 'src/v2/contexts/feed/util/product/Product.utils';
import { appendFeedAssets, setFeedFetchStartState, setFeedFetchSuccessState } from 'src/v2/redux/slices/feed/feedSlice';
import { addMissingProducts } from 'src/v2/redux/slices/product/productSlice';
import { AppStore } from 'src/v2/redux/store';
import { v6 as uuidV6 } from 'uuid';

export async function handleFetchFeedActionHelper({
  appStore,
  backendDispatchContext,
  entityId,
}: {
  appStore: AppStore;
  backendDispatchContext: BackendDispatchContextType;
  entityId: string;
}): Promise<void> {
  const appState = appStore.getState();
  const currentFetchState = appState.feed.fetchState;
  const pageSize = appState.feed.assets.ids.length ? DEFAULT_PAGE_SIZE : DEFAULT_PAGE_SIZE * 2;
  const requestGroupId = uuidV6();

  // STEP - Start Fetching Assets
  appStore.dispatch(
    setFeedFetchStartState({
      entityId,
      placeholderCount: pageSize,
      requestGroupId,
    }),
  );

  // STEP - Retrieve Backend Assets
  const result = await handleRetrieveAssets({
    backendDispatchContext,
    entityId,
    nextToken: currentFetchState.nextToken || '',
    pageSize,
    requestGroupId,
  });

  // STEP - Exit Early if Fetch was Aborted (ie: Entity changed)
  if (aborted({ appStore, requestGroupId })) return;

  // STEP - Exit Early if No Assets Returned
  if (!result.assets.length) {
    console.info(`No more assets available to fetch for entity: '${entityId}'`);
    appStore.dispatch(setFeedFetchSuccessState({ nextToken: result.nextToken }));
    return;
  }

  // STEP - Handle Backend Assets
  const backendAssets = result.assets.filter((item: Asset) => !!item);
  const asinMetadataList = await getASINMetadataListFromBackendAssets({ backendDispatchContext, backendAssets, requestGroupId });
  const asinMetadataMap = convertASINMetadataListToMap({ asinMetadataList });
  const conversionResult = await convertBackendAssetsToFrontendAssets({ backendAssets, asinMetadataMap });

  // STEP - Exit Early if Fetch was Aborted (ie: Entity changed)
  if (aborted({ appStore, requestGroupId })) return;

  // STEP - Update Store State
  if (conversionResult.errors.length) {
    console.error('Errors while converting backend assets to frontend assets:', conversionResult.errors);
  }
  // Add missing products to the product slice
  appStore.dispatch(addMissingProducts({ asinMetadataList }));
  // Add assets to feed slice
  appStore.dispatch(appendFeedAssets({ assets: conversionResult.assets, entityId }));
  // Finalize fetch state
  appStore.dispatch(setFeedFetchSuccessState({ nextToken: result.nextToken }));
}

function aborted({ appStore, requestGroupId }: { appStore: AppStore; requestGroupId: string }) {
  const currentFetchState = appStore.getState().feed.fetchState;
  return currentFetchState.requestGroupId !== requestGroupId;
}
