import { RetrieveAssetsOutput } from '@amzn/genaihub-typescript-client';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { convertFeed, fetchAsins } from 'src/components/pages/studio/contentTile/convertFeed';
import { ContentItem, StudioContext } from 'src/components/pages/studio/StudioContext';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { isWeblabFeedsDeactivationInTreatment, isWeblabStateManagementInTreatment } from 'src/util/weblab/config';
import { PlaceholderType } from 'src/v2/components/placeholder/Placeholder.types';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { getProducts } from 'src/v2/redux/slices/product/productSlice';
import { AssetTypeEnum } from 'src/v2/types';

const INITIAL_PAGE_SIZE = 12;
const SUBSEQUENT_PAGE_SIZE = 12;

export const useFeedImages = () => {
  const dispatch = useAppDispatch();
  const [assetsNextToken, setAssetsNextToken] = useState<string | undefined>('');
  const [callFeeds, setCallFeeds] = useState<boolean>(false);
  const [emptyPlaceholders, setEmptyPlaceholders] = useState<ContentItem[] | undefined>([]);
  const [isFeedsCallRunning, setIsFeedsCallRunning] = useState(false);
  const [secondFeedsCall, setSecondFeedsCall] = useState(false);
  const [feedImages, setFeedImages] = useState<ContentItem[]>([]);

  const studioContext = useContext(StudioContext);
  const appContext = useContext(AppContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const genAIBackendClient = useAIBackendHubClient();
  const products = useAppSelector(getProducts);

  // Asset Feed Effects
  const generateEmptyPlaceholders = (aspectRatio: string | undefined, num: number) => {
    return Array<ContentItem>(num).fill({ loading: false, aspectRatio, placeholderType: PlaceholderType.EMPTY });
  };

  const getNextAsset = async () => {
    if (!isFeedsCallRunning && secondFeedsCall && assetsNextToken) {
      getAssets(assetsNextToken);
    }
  };

  const getAssets = async (nextToken?: string) => {
    if (isWeblabFeedsDeactivationInTreatment() || isWeblabStateManagementInTreatment() || appContext.isManagerAccount) {
      return setCallFeeds(false);
    }

    if (isFeedsCallRunning || nextToken === undefined) return;

    let response: RetrieveAssetsOutput;
    setIsFeedsCallRunning(true);

    try {
      if (nextToken != '') {
        setEmptyPlaceholders(generateEmptyPlaceholders(studioContext.format, SUBSEQUENT_PAGE_SIZE));
        response = await genAIBackendClient.retrieveAssets({
          pageSize: SUBSEQUENT_PAGE_SIZE,
          cursor: nextToken,
          entityId: appContext.selectedAdvertisingAccount?.alternateIds?.[0],
        });
        setSecondFeedsCall(false);
      } else {
        setEmptyPlaceholders(generateEmptyPlaceholders(studioContext.format, INITIAL_PAGE_SIZE));
        response = await genAIBackendClient.retrieveAssets({
          pageSize: INITIAL_PAGE_SIZE,
          entityId: appContext.selectedAdvertisingAccount?.alternateIds?.[0],
        });
        setSecondFeedsCall(true);
      }

      // filter out unexpected nulls in response
      const results = response.body.assets?.filter((item) => !!item) || [];
      const isPlayground = appContext.activePage === 'playground';
      const asinMap = await fetchAsins(genAIBackendClient, dispatch, products, results);
      const convertedContents = await convertFeed(genAIBackendClient, results, asinMap);

      /* Ignoring video assets when page is playground since
      uploading video assets from feed as a source isn't currently supported on playground */
      const feedAssets = isPlayground ? convertedContents.filter((asset) => asset.assetType !== AssetTypeEnum.VIDEO) : convertedContents;

      setAssetsNextToken(response.body.nextToken);
      setFeedImages(feedImages.concat(feedAssets));

      // Uncomment below if needed later when implementing this hook in studio context
      // studioContext.appendResults(convertedContents);
    } catch (error) {
      console.error('Feeds: Error while fetching assets for feed', error);
    } finally {
      setIsFeedsCallRunning(false);
      // Scroll up by 1 px to prevent an edge case where user reach the end of scollable area
      // while there are more content to load
      const container = containerRef.current;
      if (container && Math.ceil(container.scrollTop + container.clientHeight) >= container.scrollHeight) {
        container.scrollTop -= 1;
      }
    }
  };

  useEffect(() => {
    // update the aspect ratio of the tile whenever user change the selection
    setEmptyPlaceholders(generateEmptyPlaceholders(studioContext.format, SUBSEQUENT_PAGE_SIZE));
  }, [studioContext.format]);

  // call feeds only if account is non-manager type and/or there's already a local account saved
  useEffect(() => {
    if (appContext.isManagerAccount) return;
    setCallFeeds(true);
  }, [appContext.isManagerAccount]);

  useEffect(() => {
    getNextAsset();
  }, [isFeedsCallRunning]);

  useEffect(() => {
    if (callFeeds) getAssets('');
  }, [callFeeds]);

  return {
    assetsNextToken,
    emptyPlaceholders,
    feedImages,
    getAssets,
    isFeedsCallRunning,
  };
};
