export enum PlaceholderType {
  ASSET = 'asset',
  GENERATION = 'generation',
  UPLOAD = 'upload',
  EMPTY = 'empty',
}

export enum PlaceholderAnimation {
  PULSATE = 'pulsate',
  WAVE = 'wave',
  NONE = 'none',
}

export enum PlaceholderVariant {
  ROUNDED = 'rounded',
  DOTTED = 'dotted',
}

export enum PlaceholderColorMode {
  COOL = 'cool',
  DARKMODE = 'darkMode',
  WARM = 'warm',
}
