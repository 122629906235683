import Genaihubbackend, { RetrieveToolsListInput, RetrieveToolsListOutput } from '@amzn/genaihub-typescript-client';
import {
  BackendActionBase,
  BackendActionQueryResultBase,
  BackendActionType,
  BackendQueryActionOptions,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type RetrieveToolsListAction = BackendActionBase<
  BackendActionType.RETRIEVE_TOOLS_LIST,
  RetrieveToolsListInput,
  RetrieveToolsListOutput['body'],
  BackendQueryActionOptions
>;

export type RetrieveToolsListActionQueryResult = BackendActionQueryResultBase<BackendActionType.RETRIEVE_TOOLS_LIST, RetrieveToolsListOutput['body']>;

interface RetrieveToolsListQueryFnProps {
  id: Request['id'];
  action: RetrieveToolsListAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const retrieveToolsListQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: RetrieveToolsListQueryFnProps): Promise<RetrieveToolsListActionQueryResult> => {
  const type = BackendActionType.RETRIEVE_TOOLS_LIST;
  const response = await backendClient.retrieveToolsList(action.payload);
  const request = getRequest({ id, requestsLookup });

  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }

  return { type, response: response.body, requestStatus: RequestStatus.SUCCESS };
};

interface HandleRetrieveToolsListActionProps {
  action: RetrieveToolsListAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}

export const handleRetrieveToolsListAction = ({ action, backendClient, requestsLookup, setQueries }: HandleRetrieveToolsListActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.RETRIEVE_TOOLS_LIST,
    getQueryResult: ({ id }) => {
      return retrieveToolsListQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
