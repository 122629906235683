import { useRef, useState } from 'react';
import ImageViewerOverlay from 'src/components/editor/ImageViewerOverlay';
import { getAspectRatio } from 'src/v2/utils/ImageUtils';

const VideoViewer = ({ urls }: { urls: string[] }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [computedAspectRatio, setComputedAspectRatio] = useState<string>('');

  const onLoadedMetadata = () => {
    const width = videoRef.current?.videoWidth;
    const height = videoRef.current?.videoHeight;
    if (width && height) {
      const aspectRatio = getAspectRatio({ width, height });
      setComputedAspectRatio(aspectRatio);
    }
  };

  return (
    <>
      {urls?.length === 1 && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 'auto', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>
            <div style={{ width: '720px', marginTop: '20px' }}>
              <video data-testid="sandbox-generated-video" ref={videoRef} width="720" height="408" controls onLoadedMetadata={onLoadedMetadata}>
                <source src={urls[0]} type="video/mp4" />
              </video>
              <canvas ref={canvasRef} width="720" height="408" style={{ display: 'none' }}></canvas>

              <ImageViewerOverlay url={urls[0]} type="video" computedAspectRatio={computedAspectRatio} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VideoViewer;
