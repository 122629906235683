import type { BoundingBox } from '@amzn/genaihub-typescript-client';
import type { EntityState } from '@reduxjs/toolkit';
import { AsinProduct, CustomImageProduct, Product } from 'src/v2/redux/slices/product/productSlice.types';
import { AspectRatio, StyleOption, SelectedEffects } from 'src/v2/types';

export interface SelectedProductBase {
  options?: {
    asinProduct?: {
      selectedImageIndex?: number;
      customProductImageUrl?: string;
    };
  };
}

export type SelectedProduct = SelectedProductBase & { id: string };
export type SelectedProductFull = SelectedProductBase & Product;
export type SelectedAsinProductFull = SelectedProductBase & AsinProduct;
export type SelectedCustomImageProductFull = SelectedProductBase & CustomImageProduct;

export interface ProductLayoutBase {
  boundingBox: BoundingBox;
  zIndex: number;
  id: string;
  processedImages?: Record<
    string,
    {
      image: string;
      mask: string;
    }
  >;
}

export interface ProductLayout extends ProductLayoutBase {
  product: SelectedProduct;
}

export interface ProductLayoutFull extends ProductLayoutBase {
  product: SelectedProductFull;
}

export enum ReferenceImageType {
  ASSET = 'asset',
  USER_UPLOAD = 'userUpload',
}

interface ReferenceImageBase {
  id: string; // SimpleAsset.id or ContentItem.referenceId when type is asset, uuid when type is userUpload
  type: ReferenceImageType;
  strength: number | undefined;
  url: string;
}

export interface AssetReferenceImage extends ReferenceImageBase {
  type: ReferenceImageType.ASSET;
}

export interface UserUploadReferenceImage extends ReferenceImageBase {
  type: ReferenceImageType.USER_UPLOAD;
}

export type ReferenceImage = AssetReferenceImage | UserUploadReferenceImage;

export interface AdvancedSettings {
  seed: string | undefined;
  temperature: number | undefined;
  negativePrompt: string | undefined;
}

export interface UserInputState {
  aspectRatio: AspectRatio;
  // Single-product
  selectedProduct: SelectedProduct | undefined;
  // Multi-product
  productLayouts: EntityState<ProductLayout, string>;
  textPrompt: string | undefined;
  isAdvancedMode: boolean;
  isPromptRewriteEnabled: boolean;
  style: StyleOption | undefined;
  effects: SelectedEffects;
  referenceImages: EntityState<ReferenceImage, string>;
  advancedSettings: AdvancedSettings;
  autoLayoutEnabled: boolean;
  customImageProducts: EntityState<CustomImageProduct, string>;
}
