import { Input, TabbedNavigation, TabbedNavigationItem } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/AppContext';
import themesConfig from 'src/components/editor/UiContols/uiGeneratorControls/themes.json';
import { ALL_STYLES, getFilteredStyles, ThemeIcons } from 'src/components/utils/styleUtils';
import { Metrics } from 'src/constants';
import { Icon } from 'src/v2/components/Icon/Icon';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { SelectionList } from 'src/v2/components/SelectionList/SelectionList';
import { ThumbnailSize } from 'src/v2/components/Thumbnail/types';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { getStyleSelection, setStyleSelection, clearStyleSelection } from 'src/v2/redux/slices/userInput/userInputSlice';
import styled from 'styled-components';
import { WidgetBodyContent, WidgetHeaderContent } from '../../WidgetContainer.styles';
import { StylingWidgetProps, WidgetViewHeaderLabels } from '../types';
import { WidgetHeader } from '../WidgetHeader';

const categories = ['All', 'Holidays', 'Location', 'Abstract'];

const WidgetWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const TabbedNavWrapper = styled.div`
  margin-bottom: 10px;

  & li::after,
  li:hover::after {
    background: var(--interactive-primary, 'unset');
  }

  & button,
  button:hover {
    color: var(--text-primary, 'unset');
  }
`;

const SearchBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  color: var(--text-primary, 'unset');
  background-color: var(--surface-tertiary, #f5f5f5);
  gap: 20px;

  && i,
  path,
  input {
    fill: var(--text-primary, 'unset');
    color: var(--text-primary, 'unset');
  }

  /* && input::placeholder {
    color: var(--text-disabled, 'unset') !important;
  } */

  & div {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    max-width: 100%;

    :hover {
      box-shadow: none;
    }
  }

  & input {
    margin-right: 20px;
  }

  & input:hover {
    box-shadow: none;
  }
`;

type StyleItem = {
  title: string;
  subtitle: string;
  thumbnail: string | IconDefinition;
  value: string;
};

type StylesList = StyleItem[];

export const StylingWidget = ({ closeWidget, dataTestId, height }: StylingWidgetProps) => {
  const dispatch = useAppDispatch();
  const savedStyle = useAppSelector(getStyleSelection);
  const appContext = useContext(AppContext);

  const [selectedCategory, setSelectedCategory] = useState<string>(categories[0]);
  const [selectedItem, setSelectedItem] = useState<StyleItem>();
  const [list, setList] = useState<StylesList | undefined>();
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number[]>([0]);
  const widgetHeaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (list && list[0] === selectedItem) {
      setSelectedIndex([0]);
      return;
    }
    if (savedStyle && list) {
      const savedItemIndex = list.findIndex((item) => item.title === savedStyle.label);
      if (savedItemIndex >= 0) {
        setSelectedIndex([savedItemIndex]);
      } else {
        setSelectedIndex([]);
      }
    }
  }, [savedStyle, list]);

  const getTransformedStylesList = (list: typeof themesConfig) => {
    let transformedStylesList: StylesList = [];

    list.forEach((theme) => {
      transformedStylesList.push({
        title: theme.controlLabel,
        subtitle: '',
        thumbnail: ThemeIcons[theme.src],
        value: theme.controlValue,
      });
    });

    const defaultItem = {
      title: 'AI Auto (Default)',
      subtitle: '',
      thumbnail: iconTypes.sparkle,
      value: '',
    };

    const sortedList = transformedStylesList.sort((a, b) => a.title.localeCompare(b.title));
    sortedList.unshift(defaultItem);
    return sortedList;
  };

  useEffect(() => {
    if (!list) {
      setList(getTransformedStylesList(themesConfig));
    }
  }, [list]);

  const filterHandler = (value: string) => {
    const filteredStyles = getFilteredStyles(value);
    setList(getTransformedStylesList(filteredStyles));
  };

  const categoryClickHandler = (category: string) => {
    if (category === selectedCategory) {
      setSelectedCategory(ALL_STYLES);
      filterHandler(ALL_STYLES);
    } else {
      setSelectedCategory(category);
      filterHandler(category);
    }
  };

  const inputChangeHandler = (value: string) => {
    setSelectedCategory(ALL_STYLES);
    filterHandler(value);
  };

  useEffect(() => {
    if (height && widgetHeaderRef.current) {
      setContentHeight(height - widgetHeaderRef?.current?.clientHeight);
    }
  }, [height]);

  const handleStyleSelection = (item: StyleItem) => {
    if (list && item) {
      const index = list.findIndex((listItem) => listItem.title === item.title);
      setSelectedIndex([index]);
      setSelectedItem(item);
    }
  };

  const handleSelectedIndex = () => {
    if (list && selectedItem) {
      const index = list.findIndex((listItem) => listItem.title === selectedItem.title);
      if (index === -1) {
        return [];
      }
      return [index];
    }
    return selectedIndex;
  };

  return (
    <WidgetWrapper data-testid={dataTestId}>
      <WidgetHeaderContent ref={widgetHeaderRef}>
        <WidgetHeader
          dataTestId={dataTestId}
          label={WidgetViewHeaderLabels.Styling}
          icon={iconTypes.styles}
          closeWidget={(arg) => {
            const styleExistsAlready = savedStyle?.label === selectedItem?.title;
            if (list && !arg && !styleExistsAlready) {
              const selectionData = {
                icon: savedStyle && !selectedItem ? savedStyle.icon : (selectedItem?.thumbnail as string),
                label: savedStyle && !selectedItem ? savedStyle.label : (selectedItem?.title as string),
                value: savedStyle && !selectedItem ? savedStyle.value : (selectedItem?.value as string),
              };

              if (selectedItem?.title.includes('Default')) {
                dispatch(clearStyleSelection());
              } else {
                // save selected item to redux store
                dispatch(setStyleSelection(selectionData));
              }

              // handle metrics tracking
              if (savedStyle === undefined || (selectedItem && savedStyle?.value !== selectedItem?.value)) {
                const metricCounter =
                  savedStyle?.label && selectionData.label.includes('Default')
                    ? Metrics.Counters.RemoveStyle
                    : `${Metrics.Counters.AddStyle}-${selectionData.value}`;
                appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [metricCounter]: 1 });
              }
            }
            closeWidget();
          }}
        />
        <SearchBar>
          <Input id="styles-search" placeholder="Search by keyword" onChange={(event) => inputChangeHandler(event.target.value)} />
          <Icon type={iconTypes.search} />
        </SearchBar>
        <TabbedNavWrapper>
          <TabbedNavigation>
            {categories.map((category) => (
              <TabbedNavigationItem
                key={category}
                data-testid={`styling-tab-${category.toLowerCase()}`}
                active={selectedCategory === category}
                label={category}
                onClick={() => categoryClickHandler(category)}
              />
            ))}
          </TabbedNavigation>
        </TabbedNavWrapper>
      </WidgetHeaderContent>
      <WidgetBodyContent height={contentHeight}>
        {list && (
          <SelectionList
            dataTestId="selection-list-styles"
            doubleColumns
            list={list}
            type="radio"
            thumbnailOptions={{ size: ThumbnailSize.Medium }}
            onClick={(item) => handleStyleSelection(item as StyleItem)}
            selectedIndex={handleSelectedIndex()}
            onSelectionChange={() => {}}
          />
        )}
      </WidgetBodyContent>
    </WidgetWrapper>
  );
};
