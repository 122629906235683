export type DateGroupType = { id: string; name: string; date: number };

const dayInMilliseconds = 86400000;
const today = new Date(new Date().setHours(0, 0, 0, 0));
const yesterday = new Date(today.getTime() - dayInMilliseconds);
const lastWeek = new Date(today.getTime() - 7 * dayInMilliseconds);
const lastMonth = new Date(new Date(today).setMonth(today.getMonth() - 1));

export const FeedDateGroups: DateGroupType[] = [
  { id: 'current', name: 'Current', date: Date.now() },
  { id: 'today', name: 'Today', date: today.getTime() },
  { id: 'yesterday', name: 'Yesterday', date: yesterday.getTime() },
  { id: 'lastWeek', name: 'Last Week', date: lastWeek.getTime() },
  { id: 'lastMonth', name: 'Last Month', date: lastMonth.getTime() },
  { id: 'longAgo', name: 'A Long Time Ago...', date: new Date(0).getTime() },
];
