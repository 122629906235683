import { forwardRef, Ref, SyntheticEvent, useCallback, useState } from 'react';
import { AssetComponentTestIds } from 'src/v2/components/common/asset/Asset.constant';
import { AssetProps } from 'src/v2/components/common/asset/Asset.types';
import { Placeholder } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderAnimation, PlaceholderType, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';
import { useAssetCache } from 'src/v2/hooks/useAssetCache/useAssetCache';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import styles from './Asset.module.scss';

export const AssetVideo = forwardRef<HTMLVideoElement, AssetProps>((props, forwardedRef: Ref<HTMLVideoElement>) => {
  const { asset, className, options, onLoad, ...restProps } = props;

  const [videoLoaded, setVideoLoaded] = useState(false);
  const cachedAssetUrl = useAssetCache({ asset });

  const onLoadedVideoData = useCallback(
    (event: SyntheticEvent<HTMLVideoElement>) => {
      setVideoLoaded(true);
      onLoad?.(event);
    },
    [cachedAssetUrl, setVideoLoaded],
  );

  const rootClassNames = getNormalizedClassNames([styles.assetVideo, videoLoaded ? styles.assetLoaded : undefined, className]);
  const loadingPlaceholderClassNames = getNormalizedClassNames([
    styles.assetLoadingPlaceholder,
    videoLoaded ? styles.assetLoaded : undefined,
    options?.loadingPlaceholder?.className,
  ]);

  return (
    <>
      <video
        data-testid={options?.testId ?? AssetComponentTestIds.videoEl(asset)}
        ref={forwardedRef}
        className={rootClassNames}
        controls={options?.video?.showControls}
        loop={true}
        muted={true}
        onLoadedData={onLoadedVideoData}
        playsInline={!options?.video?.showControls}
        preload="preload"
        {...restProps}
      >
        {cachedAssetUrl && <source src={cachedAssetUrl} type="video/mp4" />}
      </video>
      <Placeholder
        data-testid={options?.loadingPlaceholder?.testId ?? AssetComponentTestIds.loadingPlaceholder}
        className={loadingPlaceholderClassNames}
        animation={PlaceholderAnimation.PULSATE}
        type={PlaceholderType.ASSET}
        variant={PlaceholderVariant.ROUNDED}
      />
    </>
  );
});

AssetVideo.displayName = 'AssetVideo';
