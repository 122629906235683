import Genaihubbackend from '@amzn/genaihub-typescript-client';
import { useContext } from 'react';
import { AppContext } from 'src/AppContext';
import { GenAIHubClientProvider } from 'src/client/GenAIHubClientProvider';

export const useAIBackendHubClient = (): Genaihubbackend => {
  const { metrics } = useContext(AppContext);

  return GenAIHubClientProvider.getInstance(metrics).getGenAIHubBackendClient();
};
