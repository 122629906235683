import { IconDefinition } from '@amzn/storm-ui-icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { AspectRatioType, Metrics, SUPPORTED_ASPECT_RATIOS } from 'src/constants';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { SelectionList } from 'src/v2/components/SelectionList/SelectionList';
import { ThumbnailSize } from 'src/v2/components/Thumbnail/types';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { getAspectRatio, resetProductLayouts, setUserInputAspectRatio } from 'src/v2/redux/slices/userInput/userInputSlice';
import { AspectRatio } from 'src/v2/types';
import styled from 'styled-components';
import { WidgetBodyContent, WidgetHeaderContent } from '../../WidgetContainer.styles';
import { AspectRatioWidgetProps, WidgetViewHeaderLabels } from '../types';
import { WidgetHeader } from '../WidgetHeader';

type SelectionItem = {
  id: string;
  title: string;
  subtitle: string;
  thumbnail: IconDefinition;
};

type SelectionListType = SelectionItem[];

export const getIconThumbnail = (icon?: string): IconDefinition => {
  if (!icon) {
    return iconTypes.sparkle;
  }
  if (icon.includes(AspectRatioType.horizontal)) {
    return iconTypes.horizontal;
  }
  if (icon.includes(AspectRatioType.vertical)) {
    return iconTypes.vertical;
  }
  return iconTypes.square;
};

const AspectRatioWrapper = styled.div`
  && path {
    fill: var(--text-primary, 'unset');
  }
`;

export const AspectRatioWidget = ({ closeWidget, dataTestId, height }: AspectRatioWidgetProps) => {
  const dispatch = useAppDispatch();
  const savedAspectRatio = useAppSelector(getAspectRatio);
  const [selectedIndex, setSelectedIndex] = useState<number[]>([0]);
  const [selectedItem, setSelectedItem] = useState<SelectionItem>();
  const [list, setList] = useState<SelectionListType | undefined>();
  const [contentHeight, setContentHeight] = useState<number>(0);
  const widgetHeaderRef = useRef<HTMLDivElement>(null);
  const appContext = useContext(AppContext);

  const getTransformedList = (list: typeof SUPPORTED_ASPECT_RATIOS) => {
    let transformedList: SelectionListType = [];

    list.forEach((item) => {
      const aspectRatio = SUPPORTED_ASPECT_RATIOS.find((ar) => ar.title === item.title);
      let aspectRatioId: AspectRatio = AspectRatio.HORIZONTAL_191_TO_1;
      if (aspectRatio) {
        aspectRatioId = aspectRatio.id;
      }
      transformedList.push({
        id: aspectRatioId,
        title: item.title,
        subtitle: item.subText,
        thumbnail: getIconThumbnail(item.icon),
      });
    });

    const sortedList = transformedList.sort((a, b) => a.title.localeCompare(b.title));
    return sortedList;
  };

  useEffect(() => {
    if (!list) {
      const transformedList = getTransformedList(SUPPORTED_ASPECT_RATIOS);
      setList(transformedList);
    }
  }, [list]);

  useEffect(() => {
    if (savedAspectRatio && list) {
      const savedItemIndex = list.findIndex((item) => item.title.split(' ')[1] === savedAspectRatio);
      if (savedItemIndex >= 0) {
        setSelectedIndex([savedItemIndex]);
      } else {
        setSelectedIndex([]);
      }
    }
  }, [savedAspectRatio, list]);

  const handleAspectRatioSelection = (item: SelectionItem | null) => {
    if (list && item) {
      const index = list.findIndex((listItem) => listItem.title === item.title);
      setSelectedIndex([index]);
      setSelectedItem(item);
    }
  };

  useEffect(() => {
    if (height && widgetHeaderRef.current) {
      setContentHeight(height - widgetHeaderRef?.current?.clientHeight);
    }
  }, [height]);

  return (
    <div data-testid={dataTestId}>
      <WidgetHeaderContent ref={widgetHeaderRef}>
        <WidgetHeader
          dataTestId={dataTestId}
          label={WidgetViewHeaderLabels.AspectRatio}
          icon={iconTypes.horizontal}
          closeWidget={(arg) => {
            if (list && !arg) {
              const newAspectRatio = list[selectedIndex[0]].id as AspectRatio;
              if (savedAspectRatio !== newAspectRatio) {
                dispatch(setUserInputAspectRatio(newAspectRatio));
                dispatch(resetProductLayouts());
                // handle metrics tracking
                if (selectedItem && selectedItem.id !== savedAspectRatio) {
                  const metricCounter = `${Metrics.Counters.AddAspectRatio}-${selectedItem?.title.replace(/ /gi, '-').replace(/'/gi, '')}`;
                  appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [metricCounter]: 1 });
                }
              }
            }
            closeWidget();
          }}
        />
      </WidgetHeaderContent>
      <WidgetBodyContent height={contentHeight}>
        {list && (
          <AspectRatioWrapper>
            <SelectionList
              dataTestId="selection-list-aspect-ratios"
              doubleColumns
              list={list}
              type="radio"
              onClick={(item) => handleAspectRatioSelection(item as SelectionItem)}
              selectedIndex={selectedIndex}
              thumbnailOptions={{ size: ThumbnailSize.Medium }}
              onSelectionChange={() => {}}
            />
          </AspectRatioWrapper>
        )}
      </WidgetBodyContent>
    </div>
  );
};
