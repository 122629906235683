import { ApparelProductImageAsset, FrontendAsset, LifestyleImageAsset, TextToImageProductImageAsset, WorkflowIdEnum } from 'src/v2/types';
import { getAspectRatioEnumFromString } from 'src/v2/utils/UserInputs.utils';
import { UserInputState } from './userInputSlice.types';

export function getUserInputStateFromFrontendAsset({ asset }: { asset: FrontendAsset }): Partial<UserInputState> | undefined {
  switch (asset.workflowId) {
    case WorkflowIdEnum.APPAREL:
      return getUserInputStateFromApparelAsset({ asset });
    case WorkflowIdEnum.LIFESTYLE_IMAGERY:
      return getUserInputStateFromLifestyleImageAsset({ asset });
    case WorkflowIdEnum.TEXT_TO_IMAGE:
      return getUserInputStateFromTextToImageAsset({ asset });
    default:
      return undefined;
  }
}

function getUserInputStateFromApparelAsset({ asset }: { asset: ApparelProductImageAsset }): Partial<UserInputState> | undefined {
  if (!asset.userInputs) return undefined;
  const userInputState: Partial<UserInputState> = {
    aspectRatio: getAspectRatioEnumFromString({ aspectRatio: asset.userInputs.aspectRatio }),
    effects: asset.userInputs.effects,
    selectedProduct: asset.userInputs.product,
    style: asset.userInputs.style,
    textPrompt: asset.userInputs.textPrompt,
  };
  return userInputState;
}

function getUserInputStateFromLifestyleImageAsset({ asset }: { asset: LifestyleImageAsset }): Partial<UserInputState> | undefined {
  if (!asset.userInputs) return undefined;
  const userInputState: Partial<UserInputState> = {
    aspectRatio: getAspectRatioEnumFromString({ aspectRatio: asset.userInputs.aspectRatio }),
    effects: asset.userInputs.effects,
    isPromptRewriteEnabled: asset.userInputs.rewriteUserCustomPrompt,
    selectedProduct: asset.userInputs.product,
    textPrompt: asset.userInputs.textPrompt,
  };
  return userInputState;
}

function getUserInputStateFromTextToImageAsset({ asset }: { asset: TextToImageProductImageAsset }): Partial<UserInputState> | undefined {
  if (!asset.userInputs) return undefined;
  const userInputState: Partial<UserInputState> = {
    aspectRatio: getAspectRatioEnumFromString({ aspectRatio: asset.userInputs.aspectRatio }),
    effects: asset.userInputs.effects,
    selectedProduct: asset.userInputs.product,
    style: asset.userInputs.style,
    textPrompt: asset.userInputs.textPrompt,
  };
  return userInputState;
}
