import { getProcessedImageFromLayout } from 'src/v2/components/studio/productSelector/productSelectorHelpers';
import { retrieveWorkflowResultMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/RetrieveWorkflowResult.middleware';
import { submitWorkflowMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/SubmitWorkflow.middleware';
import { MultipleProductImageGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { buildAssetsFromBatchResult } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { MultiProductWorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { AsinProduct, ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { AssetTypeEnum, MultipleProductImageAsset } from 'src/v2/types';
import { constructTextPrompt, getThemeFromStyleOption, sanitizeText } from 'src/v2/utils/UserInputs.utils';

/**
 * A helper function for the primary handler, handleMultipleProductImageGeneration. This function handles the workflow submission and result.
 */
export async function handleMultipleProductImageGenerationSubmission({
  backendDispatchContext,
  generationConfig,
  uploadedAssets,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: MultipleProductImageGenerationConfig;
  uploadedAssets: {
    [id: string]: string;
  };
}): Promise<MultipleProductImageAsset[]> {
  const { entityId, outputCount, studioRequest, userInputs, workflowId } = generationConfig;
  const { aspectRatio, products, style, textPrompt, effects } = userInputs;
  const theme = getThemeFromStyleOption({ style, workflowId });
  type ProductInput = MultiProductWorkflowOptions['products'][number];

  const prompt = constructTextPrompt({
    effects,
    textPrompt: sanitizeText({ text: textPrompt }),
  });
  const productInputs = (
    await Promise.all(
      products.map(async (layout) => {
        const { image, mask } = getProcessedImageFromLayout(layout) || {};
        if (!image || !mask) return;
        const imageId = uploadedAssets[image];
        const maskId = uploadedAssets[mask];
        if (!imageId || !maskId) return;
        if (layout.product.type === ProductType.ASIN) {
          const product = layout.product as AsinProduct;
          return {
            asin: product.asin,
            image: imageId,
            mask: maskId,
            title: product.metadata.title || '',
            boundingBox: {
              ...layout.boundingBox,
              layer: layout.zIndex,
            },
            prompt: prompt || '',
            features: product.metadata.featureBullets,
            theme,
            description: product.metadata.description,
          } satisfies ProductInput;
        } else {
          return {
            asin: '',
            image: imageId,
            mask: maskId,
            boundingBox: {
              ...layout.boundingBox,
              layer: layout.zIndex,
            },
            theme,
            prompt: prompt || '',
            features: undefined,
            title: undefined,
            description: undefined,
          } satisfies ProductInput;
        }
      }),
    )
  ).filter((value: ProductInput | undefined) => !!value) as ProductInput[];
  if (products.length === 0) {
    throw new Error('No products found');
  }

  const workflowOptions: MultiProductWorkflowOptions = {
    products: productInputs,
    aspectRatio,
    numberOfImages: outputCount,
    workflowId,
  };
  const submitWorkflowPayload: StronglyTypedSubmitWorkflowByIdInput = {
    body: {
      workflowOptions,
    },
    studioRequest,
    workflowId,
  };
  const batchId = await submitWorkflowMiddleware({ backendDispatchContext, payload: submitWorkflowPayload });

  return await retrieveWorkflowResultMiddleware<MultipleProductImageAsset[]>({
    backendDispatchContext,
    payload: {
      batchId,
      entityId,
      studioRequest,
      workflowId,
    },
    onSuccessHandler: ({ response }) => {
      return buildAssetsFromBatchResult<MultipleProductImageAsset, MultipleProductImageGenerationConfig['userInputs'], MultiProductWorkflowOptions>({
        aspectRatio,
        assetType: AssetTypeEnum.IMAGE,
        batchResult: response,
        userInputs,
        workflowOptions,
      });
    },
  });
}
