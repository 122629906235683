import { FileUploadGetPresignAction } from 'src/v2/contexts/backend/actions/FileUploadGetPresign';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { BackendActionType } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { AbortError } from 'src/v2/errors/CustomErrors';
import { CategoryEnum } from 'src/v2/types';
import { downloadFile, getFileHash, uploadFileToPresignedUrl } from 'src/v2/utils/File.utils';

export const HandleImageUploadErrorMessages = {
  FILE_PROCESSING_ERROR: 'Failed to upload image due to error while processing image file',
  UPLOAD_ERROR: 'Failed to upload image due to error while uploading file',
};

/**
 * Primary upload handler for images from File or URL sources.
 */
export async function handleImageUpload({
  backendDispatchContext,
  contentCategory,
  urlOrFile,
  progressCallback,
}: {
  backendDispatchContext: BackendDispatchContextType;
  contentCategory: CategoryEnum;
  urlOrFile: string | File;
  progressCallback?: (value: number) => void;
}): Promise<{ referenceId: string }> {
  let file: File;
  let contentHash: string;

  try {
    file = urlOrFile instanceof File ? urlOrFile : await downloadFile({ url: urlOrFile });
    contentHash = await getFileHash({ file });
  } catch (err) {
    console.error(err);
    throw new Error(HandleImageUploadErrorMessages.FILE_PROCESSING_ERROR);
  }

  const payload: FileUploadGetPresignAction['payload'] = {
    body: {
      contentSize: file.size,
      contentType: file.type,
      contentCategory,
      contentHash,
    },
  };

  const doBackendRequest = (): Promise<{ referenceId: string }> => {
    return new Promise((resolve, reject) => {
      backendDispatchContext({
        type: BackendActionType.FILE_UPLOAD_GET_PRESIGN,
        payload,
        onAbort: () => {
          return reject(new AbortError(`Aborted backend action: '${BackendActionType.FILE_UPLOAD_GET_PRESIGN}'`));
        },
        onError: (err) => {
          return reject(err);
        },
        onSuccess: async ({ response }) => {
          // When the response includes a URL then the file requires uploading;
          // otherwise, the file already exists in the backend.
          if (response.url) {
            try {
              await uploadFileToPresignedUrl({ file, presignedUrl: response.url, progressCallback });
            } catch (err) {
              console.error('Error while uploading image file to presigned URL: ', err);
              return reject(new Error(HandleImageUploadErrorMessages.UPLOAD_ERROR));
            }
          }
          return resolve({ referenceId: response.referenceId });
        },
      });
    });
  };

  const result = await doBackendRequest();
  return result;
}

export function isUrl({ input }: { input: string }): boolean {
  return input.startsWith('https://') || input.startsWith('http://') || input.startsWith('blob:');
}
