import { RetrieveAssetsOutput } from '@amzn/genaihub-typescript-client';
import React, { ReactNode, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { ImageModal, ImageModalContext, useImageModal } from 'src/components/imageModal';
import ContentTile from 'src/components/pages/studio/contentTile/ContentTile';
import { convertFeed, fetchAsins } from 'src/components/pages/studio/contentTile/convertFeed';
import { ContentItem, GenerationJobStatusEnum, StudioContext } from 'src/components/pages/studio/StudioContext';
import { chunkIntoGroups } from 'src/components/utils/chunkIntoGroups';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { Breakpoints, useBreakpoints } from 'src/hooks/useBreakpoints';
import usePreview from 'src/hooks/usePreview';
import { isWeblabFeedsDeactivationInTreatment, isWeblabStateManagementInTreatment } from 'src/util/weblab/config';
import { PlaceholderType } from 'src/v2/components/placeholder/Placeholder.types';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { getProducts } from 'src/v2/redux/slices/product/productSlice';
import { AspectRatio } from 'src/v2/types';
import styled from 'styled-components';

/**
 * DEPRECATION NOTICE:
 * After State Managmeent becomes the control, the logic in this file will be deprecated by the components and logic in the following:
 * - src/v2/components/studio/feed/
 * - src/v2/contexts/feed/
 * - src/v2/redux/slices/feed/
 */

export const INITIAL_PAGE_SIZE = 12;
export const SUBSEQUENT_PAGE_SIZE = 12;
export const FEED_GAP = 8;

const dayInMilliseconds = 86400000;
const today = new Date(new Date().setHours(0, 0, 0, 0));
const yesterday = new Date(today.getTime() - dayInMilliseconds);
const lastWeek = new Date(today.getTime() - 7 * dayInMilliseconds);
const lastMonth = new Date(new Date(today).setMonth(today.getMonth() - 1));

export const DATE_GROUPS: { name: string; date: Date }[] = [
  { name: 'Current', date: new Date() },
  { name: 'Today', date: today },
  { name: 'Yesterday', date: yesterday },
  { name: 'Last Week', date: lastWeek },
  { name: 'Last Month', date: lastMonth },
  { name: 'A Long Time Ago...', date: new Date(0) },
];

const Container = styled.div`
  height: 100%;
  max-width: 100vw;
  padding: 20px;
  padding-bottom: 190px;

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: ${FEED_GAP}px;

  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;

  @media (max-width: ${Breakpoints.md}px) {
    padding-bottom: 240px;
  }
`;

const FeedSeparator = styled.div`
  width: 100%;
  height: 45px;
  flex-basis: 100%;

  font-family: 'Amazon Ember Monospace';
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child,
  &:has(+ &) {
    display: none;
  }
`;

const ContentTilesContainer = () => {
  const studioContext = useContext(StudioContext);
  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const genAIBackendClient = useAIBackendHubClient();
  const products = useAppSelector(getProducts);

  // Asset Feed States
  const [assetsNextToken, setAssetsNextToken] = useState<string | undefined>('');
  const [callFeeds, setCallFeeds] = useState<boolean>(false);
  const [emptyPlaceholders, setEmptyPlaceholders] = useState<ContentItem[]>([]);
  const [feedPlaceholders, setFeedPlaceholders] = useState<ContentItem[]>([]);
  const [fetchingAssets, setFetchingAssets] = useState(false);
  const [secondFeedsCall, setSecondFeedsCall] = useState(false);
  const [lg, md] = useBreakpoints(Breakpoints.lg, Breakpoints.md);
  const chunks = lg ? 3 : md ? 2 : 1; // Smaller than md is sm

  // Preview Modal States
  const [currentResultInPreview, setCurrentResultInPreview] = useState<ContentItem>();
  const [indexOfCurrentResultInPreview, setIndexOfCurrentResultInPreview] = useState<number>(-1);
  const { isOpen, closeModal, openModal } = useImageModal();
  const [currentTab, setCurrentTab] = useState<number>(0);

  // Asset Feed Helper Functions
  const generatePlaceholders = (num: number, aspectRatio?: string, placeholderType: PlaceholderType = PlaceholderType.ASSET) => {
    return Array<ContentItem>(num).fill({ loading: false, aspectRatio, placeholderType });
  };

  const getAssets = async (nextToken?: string) => {
    if (isWeblabFeedsDeactivationInTreatment() || isWeblabStateManagementInTreatment() || appContext.isManagerAccount) {
      return setCallFeeds(false);
    }

    if (fetchingAssets || nextToken === undefined) {
      return;
    }

    setFetchingAssets(true);

    try {
      let response: RetrieveAssetsOutput;
      if (nextToken != '') {
        setFeedPlaceholders(generatePlaceholders(SUBSEQUENT_PAGE_SIZE, AspectRatio.HORIZONTAL_191_TO_1));
        response = await genAIBackendClient.retrieveAssets({
          pageSize: SUBSEQUENT_PAGE_SIZE,
          cursor: nextToken,
          entityId: appContext.selectedAdvertisingAccount?.alternateIds?.[0],
        });
        setSecondFeedsCall(false);
      } else {
        setFeedPlaceholders(generatePlaceholders(INITIAL_PAGE_SIZE, AspectRatio.HORIZONTAL_191_TO_1));
        response = await genAIBackendClient.retrieveAssets({
          pageSize: INITIAL_PAGE_SIZE,
          entityId: appContext.selectedAdvertisingAccount?.alternateIds?.[0],
        });
        setSecondFeedsCall(true);
      }
      // filter out unexpected nulls in response
      const results = response.body.assets?.filter((item) => !!item) || [];
      const asinMap = await fetchAsins(genAIBackendClient, dispatch, products, results);
      const convertedContents = await convertFeed(genAIBackendClient, results, asinMap);

      setAssetsNextToken(response.body.nextToken);
      studioContext.appendResults(convertedContents);
    } catch (error) {
      console.error('Feeds: Error while fetching assets for feed', error);
    } finally {
      setFetchingAssets(false);
      // Scroll up by 1 px to prevent an edge case where user reach the end of scollable area
      // while there are more content to load
      const container = containerRef.current;
      if (container && Math.ceil(container.scrollTop + container.clientHeight) >= container.scrollHeight) {
        container.scrollTop -= 1;
      }
    }
  };

  const getNextAsset = async () => {
    if (!fetchingAssets && secondFeedsCall && assetsNextToken) {
      getAssets(assetsNextToken);
    }
  };

  // Asset Feed Effects
  useEffect(() => {
    // update the aspect ratio of the tile whenever user change the selection
    setEmptyPlaceholders(generatePlaceholders(SUBSEQUENT_PAGE_SIZE, studioContext.format, PlaceholderType.EMPTY));
  }, [studioContext.format]);

  // call feeds only if account is non-manager type and/or there's already a local account saved
  useEffect(() => {
    if (appContext.isManagerAccount) return;
    setCallFeeds(true);
  }, [appContext.isManagerAccount]);

  useEffect(() => {
    getNextAsset();
  }, [fetchingAssets]);

  useEffect(() => {
    if (
      studioContext.generationJob?.status == GenerationJobStatusEnum.RUNNING ||
      studioContext.generationJob?.status == GenerationJobStatusEnum.READY
    ) {
      containerRef.current?.scrollTo(0, 0);
    } else if (
      studioContext.generationJob?.status == GenerationJobStatusEnum.COMPLETED ||
      studioContext.generationJob?.status == GenerationJobStatusEnum.FAILED
    ) {
      setCallFeeds(true);
    }
  }, [studioContext.generationJob]);

  useEffect(() => {
    if (callFeeds) getAssets('');
  }, [callFeeds]);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (fetchingAssets) {
        return;
      }

      const { currentTarget } = event;
      const distance = (currentTarget.scrollTop + currentTarget.clientHeight) / currentTarget.scrollHeight;
      const limit = 1 - SUBSEQUENT_PAGE_SIZE / studioContext.results.length;
      if (distance > limit) {
        getAssets(assetsNextToken);
      }
    },
    [studioContext.results, fetchingAssets, assetsNextToken],
  );

  // Previw Modal Effects
  const setContentResultInPreview = (index: number, openPreviewModal?: true) => {
    setIndexOfCurrentResultInPreview(index);

    if (sortedContentItems && index >= 0 && index < sortedContentItems.length) {
      setCurrentResultInPreview(sortedContentItems[index]);
    } else {
      setCurrentResultInPreview(undefined);
      closeModal();
    }

    if (openPreviewModal) {
      openModal();
    }
  };

  const handleOnCloseModal = () => {
    setIndexOfCurrentResultInPreview(-1);
  };

  const { previewContext } = usePreview({
    contentItem: currentResultInPreview,
    handleReuseSettings: (props) => {
      studioContext.handleReuseSettings?.(props);
      closeModal();
    },
    handleSwitchToPrevContentItem: () => {
      const prevIndex = indexOfCurrentResultInPreview;
      const index = prevIndex > 0 ? prevIndex - 1 : (studioContext.results?.length ?? 0) - 1;
      setContentResultInPreview(index, true);
    },
    handleSwitchToNextContentItem: () => {
      const prevIndex = indexOfCurrentResultInPreview;
      const index = prevIndex < studioContext.results.length - 1 ? prevIndex + 1 : studioContext.results?.length ? 0 : -1;
      setContentResultInPreview(index, true);
    },
  });

  const contentItemClick = useCallback(
    (index: number, currentTab?: number) => {
      setContentResultInPreview(index, true);
      setCurrentTab(currentTab ?? 0);
    },
    [setContentResultInPreview, setCurrentTab],
  );

  // Asset Processing
  const getContentTileGroups = (items: ContentItem[]): ReactNode[][] => {
    // Break results into groups by date
    return groupItemsByDate(items).map((group, groupIndex, groups) => {
      // Calculate the items in the previous date groups
      const groupOffset = groups.reduce((sum, group, index) => {
        return index < groupIndex ? sum + group.length : sum;
      }, 0);

      // Group into rows
      return calculateRows(group, chunks, groupOffset);
    });
  };

  const sortItemsByDate = (items: ContentItem[]): ContentItem[] => {
    return items.sort((a, b) => {
      const dateA = a.timestamp ? new Date(parseInt(a.timestamp)) : DATE_GROUPS[0].date;
      const dateB = b.timestamp ? new Date(parseInt(b.timestamp)) : DATE_GROUPS[0].date;
      return dateB.getTime() - dateA.getTime();
    });
  };

  const groupItemsByDate = (items: ContentItem[]): ContentItem[][] => {
    const dateGroups: ContentItem[][] = [];
    for (let item of items) {
      // TODO add timestamp to generation request results
      // Defaults to the first group
      const date = item.timestamp ? new Date(parseInt(item.timestamp)) : DATE_GROUPS[0].date;
      const added = DATE_GROUPS.some((group, index) => {
        if (date >= group.date) {
          if (!dateGroups[index]) dateGroups[index] = [];
          dateGroups[index].push(item);
          return true;
        }
        return false;
      });

      // If it doesn't fit into any group then add it to the begining
      if (!added) {
        if (!dateGroups[0]) dateGroups[0] = [];
        dateGroups[0].push(item);
      }
    }
    return dateGroups;
  };

  const calculateRows = (group: ContentItem[], rowLength: number = chunks, indexOffset: number = 0) => {
    return chunkIntoGroups<ContentItem>(group, rowLength).map((row, rowIndex) => {
      // Calculate total ratio for the row
      const ratioDenominator = row.reduce((sum, item) => {
        const [height, width] = (item.aspectRatio || AspectRatio.SQUARE_1_TO_1).split(':');
        return sum + parseFloat(height) / parseFloat(width);
      }, 0);

      // Create tiles for this row with calculated flex basis
      return row.map((item, colIndex) => {
        const index = rowIndex * rowLength + colIndex + indexOffset;
        const [height, width] = (item.aspectRatio || AspectRatio.SQUARE_1_TO_1).split(':');
        const ratio = parseFloat(height) / parseFloat(width);
        const flexBasis = `calc(${((ratio / ratioDenominator) * 100) / (rowLength / row.length)}% - ${(FEED_GAP * (rowLength - 1)) / rowLength}px)`;
        const key = item.referenceId || index;

        return <ContentTile key={key} style={{ maxHeight: '100%', flexBasis }} index={index} contentItem={item} onClick={contentItemClick} />;
      });
    });
  };

  const sortedContentItems = useMemo(() => sortItemsByDate(studioContext.results), [studioContext.results]);
  const memoizedContentTileGroups = useMemo(() => getContentTileGroups(sortedContentItems), [sortedContentItems, chunks]);
  const showEmptyFeed = !studioContext.results?.length && !fetchingAssets && !studioContext.placeholders?.length;

  return (
    <>
      <Container onScroll={handleScroll} ref={containerRef} data-testid="studio-content-tile-container">
        {studioContext.placeholders && calculateRows(studioContext.placeholders)}
        {memoizedContentTileGroups.map((group, index) => (
          <ContentTilesGroup key={`group_${index}`} label={DATE_GROUPS[index].name} content={group} />
        ))}
        {fetchingAssets && calculateRows(feedPlaceholders)}
        {showEmptyFeed && calculateRows(emptyPlaceholders)}
      </Container>
      <ImageModalContext.Provider value={previewContext}>
        <ImageModal
          currentItem={currentResultInPreview}
          isOpen={isOpen}
          currentTab={currentTab}
          closeModal={closeModal}
          onCloseModal={handleOnCloseModal}
        />
      </ImageModalContext.Provider>
    </>
  );
};

interface ContentTilesGroupProps {
  label: string;
  content: ReactNode[];
}

const ContentTilesGroup = memo(({ label, content }: ContentTilesGroupProps) => {
  return (
    <>
      <FeedSeparator> {label} </FeedSeparator>
      {content}
    </>
  );
});
ContentTilesGroup.displayName = 'ContentTilesGroup';

export default memo(ContentTilesContainer);
