import { Topic } from '@amzn/red-carpet-service-client';
import { Text } from '@amzn/storm-ui';
import { useState } from 'react';
import { ImageCarousel } from 'src/components/assistant/components/welcomePage/imagesCarousel';
import { CHAT_THEME } from 'src/components/assistant/theme';
import SpinnerIcon from 'src/icons/spinnerIcon';
import styled from 'styled-components';
import assistantImage from '../../assets/personas/default.png';
import conceptsImage from '../../assets/welcomePage/concepts.webp';
import headlinesImage from '../../assets/welcomePage/headlines.webp';
import lifestyleImage from '../../assets/welcomePage/lifestyle-image.webp';
import productImageAbstract from '../../assets/welcomePage/product-image-abstract.jpg';
import productImage from '../../assets/welcomePage/product-image.webp';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  padding: 0 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const HeaderTitle = styled.div`
  display: flex;
  gap: 17px;
  align-items: center;
`;

const HeaderTitleText = styled(Text)`
  font-size: 36px;
  font-weight: 300;
  line-height: 43px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${CHAT_THEME.color.assistant};
`;

const HeaderMessage = styled(Text)`
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
`;

const CardMetadata = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding-inline: 10px;
`;

const CardTitle = styled(Text)`
  font-size: 22px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #5c687c;
  text-transform: uppercase;
`;

const CardDescription = styled(Text)`
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #232f3f;
  max-width: ${CHAT_THEME.compactScreenBreakPoint}px;
  height: 40px;
`;

const HeaderImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

interface CardConfig {
  imageSrc: string;
  title: string;
  description: string;
  headline?: string;
  caption?: string;
  topic: Topic;
}

const CARDS_CONFIG: CardConfig[] = [
  {
    imageSrc: productImage,
    title: 'Generate product images',
    description: 'I’d like to create some images for an advertising campaign that I’m running on Amazon',
    topic: Topic.PRODUCT_IMAGES,
  },
  {
    imageSrc: productImageAbstract,
    title: 'Generate abstract product images',
    description: 'I’d like to create some images with an abstract style for an advertising campaign that I’m running on Amazon',
    topic: Topic.PRODUCT_IMAGES_ABSTRACT,
  },
  {
    imageSrc: lifestyleImage,
    title: 'Generate lifestyle images',
    description: 'I’d like to create some lifestyle images for an advertising campaign that I’m running on Amazon',
    topic: Topic.LIFESTYLE_IMAGES,
  },
  {
    imageSrc: headlinesImage,
    title: 'Generate headlines',
    description: 'I need some headlines that highlight my product’s unique selling points',
    headline: 'Savor the craft of pour over coffee',
    topic: Topic.HEADLINES,
  },
  {
    imageSrc: conceptsImage,
    title: 'Generate concepts',
    description: 'I need some ideas that I can use to create a new advertising campaign for my product. ',
    caption: 'A close-up image of a vibrant, dew-covered flower petal, with intricate water droplets glistening in the soft morning light',
    topic: Topic.CONCEPTS,
  },
];

interface Props {
  startConversation: (topic: Topic) => void;
  loading?: boolean;
}

export default function WelcomePage({ startConversation, loading }: Props) {
  const [activeIndex, setActiveIndex] = useState(2);

  return (
    <PageWrapper>
      <Header>
        <HeaderTitle>
          {loading ? <SpinnerIcon style={{ width: '60px', height: '60px' }} strokeWidth={15} /> : <HeaderImage src={assistantImage} />}
          <HeaderTitleText>Hi, I’m your creative agent!</HeaderTitleText>
        </HeaderTitle>
        <HeaderMessage>Here are some ideas to get started:</HeaderMessage>
      </Header>
      <ImageCarousel
        images={CARDS_CONFIG.map((item) => ({
          source: item.imageSrc,
          caption: item.caption,
          headline: item.headline,
        }))}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        onImageSelect={(index: number) => startConversation(CARDS_CONFIG[index].topic)}
        loading={loading}
      />
      <CardMetadata>
        <CardTitle>{CARDS_CONFIG[activeIndex].title}</CardTitle>
        <CardDescription>{CARDS_CONFIG[activeIndex].description}</CardDescription>
      </CardMetadata>
    </PageWrapper>
  );
}
