import { Text } from '@amzn/storm-ui';
import { AnimatedStars } from 'src/animations/AnimatedStars';
import Stack from 'src/customUIComponents/Stack';
import styled from 'styled-components';

const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9000;
`;

const GridBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  color: white;
`;

const GeneratingText = styled(Text)`
  color: inherit;
  font-size: 21px;
  font-weight: 800;
  padding-right: 50px;
`;

const MessageText = styled(Text)`
  color: inherit;
  font-size: 17px;
  font-weight: 100;
  text-align: center;
`;

const GeneratingCard = () => {
  return (
    <GridContainer>
      <GridBox>
        <Stack style={{ height: '100%', justifyContent: 'center', paddingBottom: '20px' }}>
          <Stack style={{ flexDirection: 'row', justifyContent: 'center', gap: '0px' }}>
            <AnimatedStars width={60} height={60} />
            <div>
              <GeneratingText>Generating</GeneratingText>
            </div>
          </Stack>
          <MessageText>This will take several seconds.</MessageText>
        </Stack>
      </GridBox>
    </GridContainer>
  );
};

export default GeneratingCard;
