import { retrieveWorkflowResultMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/RetrieveWorkflowResult.middleware';
import { submitWorkflowMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/SubmitWorkflow.middleware';
import { GenerativeResizingGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { buildAssetsFromBatchResult } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { GenerativeResizingWorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { AssetTypeEnum, EditedImageAsset } from 'src/v2/types';

/**
 * A helper function for the primary handler, handleGenerativeResizingGeneration. This function handles the workflow submission and result.
 */
export async function handleGenerativeResizingGenerationSubmission({
  backendDispatchContext,
  generationConfig,
  inputImageReferenceId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: GenerativeResizingGenerationConfig;
  inputImageReferenceId: string;
}): Promise<EditedImageAsset[]> {
  const { entityId, outputCount, studioRequest, userInputs, workflowId } = generationConfig;
  const { aspectRatio, imageLayout, inputImageAsset } = userInputs;

  // STEP - Submit workflow for GENERATIVE_RESIZING
  const workflowOptions: GenerativeResizingWorkflowOptions = {
    aspectRatio,
    imageCount: outputCount,
    imageLayout,
    inputImageRef: inputImageReferenceId,
    seed: undefined,
    workflowId,
  };
  const submitWorkflowPayload: StronglyTypedSubmitWorkflowByIdInput = {
    body: {
      workflowOptions,
    },
    studioRequest,
    workflowId,
  };
  const batchId = await submitWorkflowMiddleware({ backendDispatchContext, payload: submitWorkflowPayload });

  // STEP - Retrieve result for GENERATIVE_RESIZING
  return await retrieveWorkflowResultMiddleware<EditedImageAsset[]>({
    backendDispatchContext,
    payload: {
      batchId,
      entityId,
      studioRequest,
      workflowId,
    },
    onSuccessHandler: ({ response }) => {
      return buildAssetsFromBatchResult<EditedImageAsset, GenerativeResizingGenerationConfig['userInputs'], GenerativeResizingWorkflowOptions>({
        aspectRatio: inputImageAsset.aspectRatio,
        assetType: AssetTypeEnum.IMAGE,
        batchResult: response,
        userInputs,
        workflowOptions,
      });
    },
  });
}
