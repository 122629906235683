import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedDateGroups } from 'src/v2/redux/slices/feed/feedSlice.constants';
import type { RootState } from 'src/v2/redux/store';
import { FrontendAsset } from 'src/v2/types';
import { FeedAssetsDateGroupType, FeedState } from './feedSlice.types';
import { getInitialFeedFetchState } from './feedSlice.utils';

export const assetsAdapter = createEntityAdapter<FrontendAsset>();

const initialState: FeedState = {
  assets: assetsAdapter.getInitialState(),
  assetGenerationIds: [],
  fetchState: getInitialFeedFetchState(),
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    // Assets Reducers
    prependFeedAssets: (
      state,
      action: PayloadAction<{
        assets: FrontendAsset[];
        entityId: string;
      }>,
    ) => {
      // only add assets if the account hasn't changed between producing the assets and prepending them
      const currentEntityId = state.fetchState.entityId ?? '';
      if (currentEntityId == action.payload.entityId) {
        upsertFeedAssetsHelper(state, action.payload.assets, (currentfeedAssets, newFeedAssets) => [...newFeedAssets, ...currentfeedAssets]);
      }
    },
    appendFeedAssets: (
      state,
      action: PayloadAction<{
        assets: FrontendAsset[];
        entityId: string;
      }>,
    ) => {
      // only add assets if the account hasn't changed between producing the assets and appending them
      const currentEntityId = state.fetchState.entityId ?? '';
      if (currentEntityId == action.payload.entityId) {
        upsertFeedAssetsHelper(state, action.payload.assets, (currentfeedAssets, newFeedAssets) => [...currentfeedAssets, ...newFeedAssets]);
      }
    },
    removeFeedAssets: (
      state,
      action: PayloadAction<{
        assetIds: string[];
      }>,
    ) => {
      assetsAdapter.removeMany(state.assets, action.payload.assetIds);
    },
    // Asset Generation Request Reducers
    addFeedAssetGenerationId: (
      state,
      action: PayloadAction<{
        id: string;
        entityId: string;
      }>,
    ) => {
      // only add asset generations if the account hasn't changed between producing the asset generation and appending them
      const currentEntityId = state.fetchState.entityId ?? '';
      if (currentEntityId == action.payload.entityId && !state.assetGenerationIds.includes(action.payload.id)) {
        state.assetGenerationIds.push(action.payload.id);
      }
    },
    removeFeedAssetGenerationId: (
      state,
      action: PayloadAction<{
        id: string;
      }>,
    ) => {
      state.assetGenerationIds = state.assetGenerationIds.filter((id) => id !== action.payload.id);
    },
    clearFeedAssetGenerationIds: (state) => {
      state.assetGenerationIds = [];
    },
    // Feed Fetch State Reducers
    resetFeed: (state) => {
      assetsAdapter.setAll(state.assets, []);
      // TODO: make sure the caller of reset feed aborts all generation requests
      state.assetGenerationIds = [];
      state.fetchState = getInitialFeedFetchState();
    },
    setFeedFetchStartState: (
      state,
      action: PayloadAction<{
        entityId: string;
        placeholderCount: number;
        requestGroupId: string;
      }>,
    ) => {
      const { entityId, placeholderCount, requestGroupId } = action.payload;
      state.fetchState = {
        ...state.fetchState,
        entityId,
        error: undefined,
        isFetching: true,
        placeholderCount,
        requestGroupId,
      };
    },
    setFeedFetchErrorState: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      state.fetchState = {
        ...state.fetchState,
        error,
        isFetching: false,
        placeholderCount: 0,
        requestGroupId: undefined,
      };
    },
    setFeedFetchSuccessState: (state, action: PayloadAction<{ nextToken: string }>) => {
      const { nextToken } = action.payload;
      state.fetchState = {
        ...state.fetchState,
        error: undefined,
        isFetching: false,
        nextToken,
        placeholderCount: 0,
        requestGroupId: undefined,
      };
    },
  },
  selectors: {
    // Asset Generation Request Selectors
    getFeedAssetGenerationIds: (state) => state.assetGenerationIds,
    // Feed Fetch State Selectors
    getFeedFetchEntityId: (state) => state.fetchState.entityId,
    getFeedFetchError: (state) => state.fetchState.error,
    getFeedFetchPlaceholderCount: (state) => state.fetchState.placeholderCount,
    getIsFeedFetchingAssets: (state) => state.fetchState.isFetching,
  },
});

export const {
  // Assets Actions
  prependFeedAssets,
  appendFeedAssets,
  removeFeedAssets,
  // Asset Generation Request Actions
  addFeedAssetGenerationId,
  removeFeedAssetGenerationId,
  clearFeedAssetGenerationIds,
  // Feed Fetch State Action
  resetFeed,
  setFeedFetchStartState,
  setFeedFetchErrorState,
  setFeedFetchSuccessState,
} = feedSlice.actions;

// Export entity adapter selectors
export const {
  selectById: getFeedAssetById,
  selectAll: getFeedAssets,
  selectIds: getFeedAssetIds,
} = assetsAdapter.getSelectors((state: RootState) => state.feed.assets);

export const {
  // Asset Generation Request Selectors
  getFeedAssetGenerationIds,
  // Feed Fetch State Selectors
  getFeedFetchEntityId,
  getFeedFetchError,
  getFeedFetchPlaceholderCount,
  getIsFeedFetchingAssets,
} = feedSlice.selectors;

// Assets Selectors
export const getFeedAssetsSortedByDate = createSelector([(state: RootState) => [...getFeedAssets(state)]], (feedAssets) => {
  return sortFeedAssets({ feedAssets });
});

export function sortFeedAssets({ feedAssets }: { feedAssets: FrontendAsset[] }) {
  return feedAssets.sort((a, b) => b.timestamp - a.timestamp);
}

export const getFeedAssetsByDateGroup = createSelector([(state: RootState) => getFeedAssetsSortedByDate(state)], (feedAssetsSortedByDate) => {
  return groupSortedFeedAssetsByDateGroup({ feedAssetsSortedByDate });
});

export function groupSortedFeedAssetsByDateGroup({ feedAssetsSortedByDate }: { feedAssetsSortedByDate: FrontendAsset[] }) {
  const result: FeedAssetsDateGroupType[] = [];
  let feedAssetsIndex = 0;
  for (let i = 0; i < FeedDateGroups.length; i++) {
    const feedDateGroup = FeedDateGroups[i];
    const feedAssetsDateGroup: FeedAssetsDateGroupType = {
      ...feedDateGroup,
      assets: [],
    };
    let useNextDateGroup = false;
    while (feedAssetsIndex < feedAssetsSortedByDate.length && !useNextDateGroup) {
      const feedAsset = feedAssetsSortedByDate[feedAssetsIndex];
      if (feedAsset.timestamp >= feedAssetsDateGroup.date) {
        feedAssetsDateGroup.assets.push(feedAsset);
        feedAssetsIndex++;
      } else {
        useNextDateGroup = true;
      }
    }
    if (feedAssetsDateGroup.assets.length) result.push(feedAssetsDateGroup);
  }
  return result;
}

// Asset Generation Request Selectors
export const getFeedAssetGenerations = createSelector(
  [(state: RootState) => state.assetGenerations.entities, getFeedAssetGenerationIds],
  (assetGenerations, assetGenerationIds) => {
    return assetGenerationIds.map((id) => assetGenerations[id]).reverse();
  },
);

export default feedSlice.reducer;

// HELPER FUNCTIONS
function upsertFeedAssetsHelper(
  state: FeedState,
  feedAssets: FrontendAsset[],
  insertNewAssets: (currentfeedAssets: FrontendAsset[], newFeedAssets: FrontendAsset[]) => FrontendAsset[],
) {
  const existingfeedAssets = feedAssets.filter((asset) => state.assets.ids.includes(asset.id));
  const newFeedAssets = feedAssets.filter((asset) => !state.assets.ids.includes(asset.id));

  if (existingfeedAssets.length > 0) {
    assetsAdapter.upsertMany(state.assets, existingfeedAssets);
  }

  if (newFeedAssets.length > 0) {
    const currentfeedAssets = Object.values(state.assets.entities);
    assetsAdapter.setAll(state.assets, insertNewAssets(currentfeedAssets, newFeedAssets));
  }
}
