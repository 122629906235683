/**
 * Declare window variable for Cypress
 */

declare global {
  interface Window {
    Cypress?: unknown;
  }
}

/**
 * A function to detect E2E test env
 */

export const isE2eTesting = () => {
  // Check for Cypress
  // https://docs.cypress.io/app/faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
  // Check parent just in case we're in an iFrame
  if (window?.Cypress || window?.parent?.Cypress) {
    return true;
  }
  // Check for Playwright
  if (navigator.webdriver) {
    return true;
  }
  return false;
};
