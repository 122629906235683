import { useEffect, useState } from 'react';
import delay from 'src/components/utils/delay';
import { SimpleAsset } from 'src/v2/types';
import { downloadFile } from 'src/v2/utils/File.utils';

const MAX_RETRIES = 3;

class AssetCache {
  private cachedAssetUrls: Record<string, string>;
  private isCachingAsset: Record<string, boolean>;

  constructor() {
    this.cachedAssetUrls = {};
    this.isCachingAsset = {};
  }

  async cacheAsset({ asset }: { asset: SimpleAsset }) {
    const { id, url } = asset;
    let cachedAssetUrl;

    this.isCachingAsset[asset.id] = true;

    for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
      try {
        const file = await downloadFile({ url });
        cachedAssetUrl = URL.createObjectURL(file);
        this.cachedAssetUrls[id] = cachedAssetUrl;
        break;
      } catch (err) {
        console.error(`Error while caching asset (id: ${id} url: ${url}). Attempt ${attempt + 1} of ${MAX_RETRIES}.`, err);
      }
    }

    delete this.isCachingAsset[asset.id];

    if (!cachedAssetUrl) {
      throw new Error(`Failed to cache asset (id: ${id} url: ${url})`);
    }

    return cachedAssetUrl;
  }

  /**
   * Gets a cached asset URL. If the cached URL is not available then it will be cached first before returning.
   */
  async getCachedAssetUrl({ asset }: { asset: SimpleAsset }) {
    const { id } = asset;
    const cachedAssetUrl = this.cachedAssetUrls[id];
    if (!cachedAssetUrl) {
      const waitPeriod = 250;
      let timeWaited = 0;
      while (this.isCachingAsset[asset.id]) {
        await delay(waitPeriod);
        timeWaited += waitPeriod;
        if (timeWaited > 30000) break;
      }
      // Asset may have cached while it waited earlier
      if (this.cachedAssetUrls[asset.id]) return this.cachedAssetUrls[asset.id];
      return await this.cacheAsset({ asset });
    } else {
      return cachedAssetUrl;
    }
  }

  /**
   * Gets a cached URL using the given ID. If the cached URL is not available then undefined will be returned.
   */
  getCachedAssetUrlById({ id }: { id: string }) {
    return this.cachedAssetUrls[id];
  }

  // for testing
  reset() {
    this.cachedAssetUrls = {};
  }
}

const assetCacheInstance = new AssetCache();
export const getAssetCacheInstance = () => assetCacheInstance;

export const useAssetCache = ({ asset }: { asset?: SimpleAsset }) => {
  const [cachedAssetUrl, setCachedAssetUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    let unMounted = false;
    if (asset) {
      assetCacheInstance.getCachedAssetUrl({ asset }).then((cachedAssetUrl) => {
        if (!unMounted && cachedAssetUrl) {
          setCachedAssetUrl(cachedAssetUrl);
        }
      });
    }
    return () => {
      unMounted = true;
    };
  }, [asset?.url]);

  return cachedAssetUrl;
};
