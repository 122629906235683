import { ButtonProps, ButtonTypes, ButtonStyleClasses } from './types';

export const getButtonIconColor = ({
  colorOverride,
  darkMode,
  disabled,
  icon,
  iconOnly,
  iconOnlyNoBackground,
  loading,
  type,
}: Partial<ButtonProps>) => {
  if (colorOverride) {
    return ButtonStyleClasses.colorOverride;
  }
  if (icon && iconOnly && iconOnlyNoBackground) {
    return ButtonStyleClasses.iconOnlyNoBackground;
  }
  if (loading || (disabled && type !== ButtonTypes.Primary)) {
    return ButtonStyleClasses.iconDisabled;
  }
  if (!loading && !disabled && !darkMode && type === ButtonTypes.Primary) {
    return ButtonStyleClasses.iconLight;
  }
  if (!iconOnly && !loading && !disabled && darkMode && type === ButtonTypes.Tertiary) {
    return ButtonStyleClasses.iconAccent;
  }
  if (!loading && !disabled && darkMode && type !== ButtonTypes.Primary) {
    return ButtonStyleClasses.iconLight;
  }
  if (darkMode && iconOnly && disabled && type === ButtonTypes.Primary) {
    return ButtonStyleClasses.iconLight;
  }
  return ButtonStyleClasses.iconDark;
};

export const buttonTestLabel = 'Click Me';
export const buttonTestId = 'some-custom-test-id';
export const buttonIconTestId = 'some-custom-test-id-icon';
export const buttonPopoverTestId = 'some-custom-test-id-popover';
