import landScapeIcon from 'src/icons/aspectRatioFrames/landscape.png';
import portraitIcon from 'src/icons/aspectRatioFrames/portrait.png';
import squareIcon from 'src/icons/aspectRatioFrames/square.png';
import { AspectRatio } from 'src/v2/types';

export const DEFAULT_LOCALE = 'en-US';
export const ALERTS_MESSAGE_BUNDLE_NAME = 'pages.alerts';
export const AUTH_PORTAL_ASSOCIATION_HANDLER = 'amzn_genaihub_us';
export const SIGN_OUT_PATH = '/sign-out';

export const enum AlertTargets {
  workFlowPage = 'workFlowPage',
  textPrompt = 'textPrompt',
  workflowPageBottomBar = 'workflowPageBottomBar',
}

export const productImageControlName = 'product_image';
export const referenceImageControlName = 'reference_image';
export const themesControlName = 'themes';

export const EVENT_LOCAL_STORAGE = 'local-storage';
export const LOCAL_STORAGE_KEY_PRODUCTS = 'products';

export const LOCAL_STORAGE_KEY_THEME_PREFERENCE = 'theme';

declare global {
  interface WindowEventMap {
    [EVENT_LOCAL_STORAGE]: StorageEvent;
  }
}

export const METRICS_DOMAINS = {
  'prod': 'test',
  beta: 'test',
  gamma: 'test',
  prod: 'prod',
};
export const METRICS_REALM = 'USAmazon';
export const METRICS_SITE = 'GenAIHub-Frontendwebsite';
export const METRICS_SERVICE_NAME = 'GenAIHub';
export const Metrics = {
  Actions: {
    Open: 'Open',
    Close: 'Close',
  },
  Buttons: {
    AuthChange: 'AuthChange',
    GoBack: 'GoBack',
    Logo: 'Logo',
    Scheme: 'Scheme',
    Theme: 'Theme',
    Try: 'Try',
    ViewAll: 'ViewAll',
  },
  Counters: {
    Clicks: 'Clicks',
    Conversations: 'Conversations',
    Count: 'Count',
    Dislike: 'Dislike',
    Download: 'Download',
    Failure: 'Failure',
    Like: 'Like',
    UserMessages: 'UserMessages',
    AgentMessages: 'AgentMessages',
    Save: 'Save',
    Success: 'Success',
    Timeout: 'Timeout',
    ImagesGenerated: 'ImagesGenerated',
    ApiError: 'ApiError',
    UiError: 'UiError',
    // Input container metrics
    CustomizeSettings: 'CustomizeSettings',
    OpenProductView: 'OpenProductView',
    CloseProductView: 'CloseProductView',
    AddProduct: 'AddProduct',
    RemoveProduct: 'RemoveProduct',
    UploadProduct: 'UploadProduct',
    OpenStyleView: 'OpenStyleView',
    CloseStyleView: 'CloseStyleView',
    AddStyle: 'AddStyle',
    RemoveStyle: 'RemoveStyle',
    OpenEffectsView: 'OpenEffectsView',
    CloseEffectsView: 'CloseEffectsView',
    AddEffect: 'AddEffect',
    RemoveEffect: 'RemoveEffect',
    OpenAspectRatioView: 'OpenAspectRatioView',
    CloseAspectRatioView: 'CloseAspectRatioView',
    AddAspectRatio: 'AddAspectRatio',
    RemoveAspectRatio: 'RemoveAspectRatio',
    OpenReferenceImageView: 'OpenReferenceImageView',
    CloseReferenceImageView: 'CloseReferenceImageView',
    AddReferenceImage: 'AddReferenceImage',
    RemoveReferenceImage: 'RemoveReferenceImage',
  },
  Dimensions: {
    AccountType: 'AccountType',
    ActionId: 'ActionId',
    ConversationId: 'ConversationId',
    EntityId: 'EntityId',
    MarketplaceId: 'MarketplaceId',
    PageName: 'PageName',
    SessionId: 'SessionId',
    Stage: 'Stage',
    ToolName: 'ToolName',
    UserAlias: 'UserAlias',
  },
  Methods: {
    ButtonClick: 'ButtonClick',
    DropDown: 'DropDown',
    ChatFeedback: 'ChatFeedback',
    ChatMessages: 'ChatMessages',
    ChatImage: 'ChatImage',
    CreationTime: 'CreationTime',
    EnterTextPrompt: 'EnterTextPrompt',
    ErrorPage: 'ErrorPage',
    FileSelected: 'FileSelected',
    FileSelector: 'FileSelector',
    FooterClick: 'FooterClick',
    GenerateButton: 'GenerateButton',
    HomePage: 'HomePage',
    ImportImage: 'ImportImage',
    InputContainer: 'InputContainer',
    InperationPreview: 'InperationPreview',
    LogoClick: 'LogoClick',
    NavBarClick: 'NavBarClick',
    PageMetrics: 'PageMetrics',
    SaveAsset: 'SaveAsset',
    SettingsDrawer: 'SettingsDrawer',
    StudioWorkflow: 'StudioWorkflow',
    ToggleGroup: 'ToggleGroup',
    ToolCard: 'ToolCard',
    UserIcon: 'UserIcon',
    UserMenuClick: 'UserMenuClick',
    WorkflowMetrics: 'WorkflowMetrics',
    Fatal: 'Fatal',
  },
  Names: {
    Action: 'Action',
    AsinToProductImage: 'AsinToProductImage',
    AsinToLifestyleImage: 'AsinToLifestyleImage',
    Authenticated: 'Authenticated',
    ButtonId: 'ButtonId',
    BatchId: 'BatchId',
    Destination: 'Destination',
    Effects: 'Effects',
    Error: 'Error',
    Feedback: 'Feedback',
    FromCache: 'FromCache',
    FromFetch: 'FromFetch',
    GenerationType: 'GenerationType',
    Scheme: 'Scheme',
    Selected: 'Selected',
    TextToLifestyleImage: 'TextToLifestyleImage',
    Theme: 'Theme',
    Time: 'Time',
    Timeout: 'Timeout',
    ToolName: 'ToolName',
    Unselected: 'Unselected',
    UserBounced: 'UserBounced',
    UserDwellTime: 'UserDwellTime',
    UserGlanceCount: 'UserGlanceCount',
    UserViewCount: 'UserViewCount',
    Value: 'Value',
    WorkflowId: 'WorkflowId',
  },
  Values: {
    Abandoned: 'Abandoned',
    Dislike: 'Dislike',
    Home: 'Home',
    Like: 'Like',
    NotSelected: 'NotSelected',
    Selected: 'Selected',
    Unknown: 'Unknown',
  },
  Timers: {
    TextInput: 'TextInput',
  },
};

export const CSRF_META_NAME = 'anti-csrftoken-a2z';

export const STUDIO = 'STUDIO';
export const US_MARKETPLACE_ID = 'ATVPDKIKX0DER';

export const enum HEADING_TEXT_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const selectedEntityLocalStorageKey = 'selectedEntity';

export const restrictedJobStatusMessage = 'Restricted ASIN';

export const AspectRatioType = {
  horizontal: landScapeIcon,
  vertical: portraitIcon,
  square: squareIcon,
};

export const SUPPORTED_ASPECT_RATIOS = [
  {
    id: AspectRatio.HORIZONTAL_3_TO_1,
    title: 'Horizontal 3:1',
    subText: 'Stores, A+ Basic, A+ Premium',
    icon: landScapeIcon,
  },
  {
    id: AspectRatio.HORIZONTAL_191_TO_1,
    title: 'Horizontal 1.91:1',
    subText: 'Sponsored Brands',
    icon: landScapeIcon,
  },
  {
    id: AspectRatio.HORIZONTAL_12_TO_5,
    title: 'Horizontal 12:5',
    subText: 'A+ Basic, A+ Premium, A+ Brand Story',
    icon: landScapeIcon,
  },
  {
    id: AspectRatio.SQUARE_1_TO_1,
    title: 'Square 1:1',
    subText: 'Posts, Stores, A+ Basic, A+ Premium',
    icon: squareIcon,
  },
  {
    id: AspectRatio.VERTICAL_4_TO_5,
    title: 'Vertical 4:5',
    subText: 'Posts, A+ Basic, A+ Premium',
    icon: portraitIcon,
  },
  {
    id: AspectRatio.VERTICAL_9_TO_16,
    title: 'Vertical 9:16',
    subText: 'Posts, Sponsored Display',
    icon: portraitIcon,
  },
];
