import { memo, useCallback, UIEvent } from 'react';
import { Breakpoints, useBreakpoints } from 'src/hooks/useBreakpoints';
import { FeedAssetGenerationPlaceholdersGroup } from 'src/v2/components/studio/feed/_partials/FeedAssetGenerationPlaceholdersGroup';
import { FeedAssetsGroup } from 'src/v2/components/studio/feed/_partials/FeedAssetsGroup';
import { FeedContainer } from 'src/v2/components/studio/feed/_partials/FeedContainer';
import { FeedEmptyPlaceholdersGroup } from 'src/v2/components/studio/feed/_partials/FeedEmptyPlaceholdersGroup';
import { FeedFetchPlaceholdersGroup } from 'src/v2/components/studio/feed/_partials/FeedFetchPlaceholdersGroup';
import { FeedTestIds } from 'src/v2/components/studio/feed/Feed.constants';
import styles from 'src/v2/components/studio/feed/Feed.module.scss';
import { DEFAULT_PAGE_SIZE } from 'src/v2/contexts/feed/constants/FeedContext.constants';
import { useFeed } from 'src/v2/hooks/useFeed/useFeed';

export const Feed = memo(() => {
  const [isLG, isMD] = useBreakpoints(Breakpoints.lg, Breakpoints.md);
  const numFeedItemsPerRow = isLG ? 3 : isMD ? 2 : 1; // Smaller than md is sm

  const { feedAssets, feedAssetsByDateGroup, feedAssetGenerations, feedFetchPlaceholderCount, isFeedFetchingAssets, fetchAssets } = useFeed({
    includeFeedAssetsByDateGroup: true,
  });

  const handleScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const { currentTarget } = event;
      const distance = (currentTarget.scrollTop + currentTarget.clientHeight) / currentTarget.scrollHeight;
      const limit = 1 - DEFAULT_PAGE_SIZE / feedAssets.length;
      if (distance > limit) {
        fetchAssets();
      }
    },
    [feedAssets],
  );

  const shouldShowEmptyPlaceholders = !isFeedFetchingAssets && !feedAssetGenerations.length && !feedAssetsByDateGroup.length;

  return (
    <FeedContainer onScroll={handleScroll} data-testid={FeedTestIds.feedContainer}>
      {shouldShowEmptyPlaceholders && <FeedEmptyPlaceholdersGroup />}
      {<FeedAssetGenerationPlaceholdersGroup feedAssetGenerations={feedAssetGenerations} numFeedItemsPerRow={numFeedItemsPerRow} />}
      {feedAssetsByDateGroup.map((feedAssetsDateGroup) => {
        return (
          <FeedAssetsGroup
            key={`feed-assets-date-group-${feedAssetsDateGroup.id}`}
            feedAssetsDateGroup={feedAssetsDateGroup}
            numFeedItemsPerRow={numFeedItemsPerRow}
          />
        );
      })}
      {isFeedFetchingAssets && <FeedFetchPlaceholdersGroup feedFetchPlaceholderCount={feedFetchPlaceholderCount} />}
      {!isFeedFetchingAssets && <div className={styles.notFetchingBottom} />}
    </FeedContainer>
  );
});

Feed.displayName = 'Feed';
