import { retrieveWorkflowResultMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/RetrieveWorkflowResult.middleware';
import { submitWorkflowMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/SubmitWorkflow.middleware';
import { LifestyleImageGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { buildAssetsFromBatchResult } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { LifestyleImageryWorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { AssetTypeEnum, LifestyleImageAsset, WorkflowIdEnum } from 'src/v2/types';
import { constructTextPrompt, sanitizeText } from 'src/v2/utils/UserInputs.utils';

/**
 * A helper function for the primary handler, handleLifestyleImageGeneration. This function handles the workflow submission and result.
 */
export async function handleLifestyleImageGenerationSubmission({
  backendDispatchContext,
  generationConfig,
  productImageReferenceId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: LifestyleImageGenerationConfig;
  productImageReferenceId: string | undefined;
}): Promise<LifestyleImageAsset[]> {
  const workflowId = WorkflowIdEnum.LIFESTYLE_IMAGERY;
  const { entityId, outputCount, studioRequest, userInputs } = generationConfig;
  const { aspectRatio, effects, product, rewriteUserCustomPrompt } = userInputs;
  const textPrompt = constructTextPrompt({
    effects,
    textPrompt: sanitizeText({ text: userInputs.textPrompt }),
  });
  const asin = product?.type === ProductType.ASIN ? product.asin : undefined;
  const title = product?.type === ProductType.ASIN ? sanitizeText({ text: product.metadata.title }) : ' ';

  // STEP - Submit workflow for LIFESTYLE_IMAGERY
  const workflowOptions: LifestyleImageryWorkflowOptions = {
    asin,
    aspectRatio,
    description: undefined,
    imageCount: outputCount,
    productImageRef: productImageReferenceId,
    rewriteUserCustomPrompt,
    textPrompt,
    theme: undefined,
    title,
    workflowId,
  };
  const submitWorkflowPayload: StronglyTypedSubmitWorkflowByIdInput = {
    body: {
      workflowOptions,
    },
    studioRequest,
    workflowId,
  };
  const batchId = await submitWorkflowMiddleware({ backendDispatchContext, payload: submitWorkflowPayload });

  // STEP - Retrieve result for LIFESTYLE_IMAGERY
  return await retrieveWorkflowResultMiddleware<LifestyleImageAsset[]>({
    backendDispatchContext,
    payload: {
      batchId,
      entityId,
      studioRequest,
      workflowId,
    },
    onSuccessHandler: ({ response }) => {
      return buildAssetsFromBatchResult<LifestyleImageAsset, LifestyleImageGenerationConfig['userInputs'], LifestyleImageryWorkflowOptions>({
        aspectRatio,
        assetType: AssetTypeEnum.IMAGE,
        batchResult: response,
        userInputs,
        workflowOptions,
      });
    },
  });
}
