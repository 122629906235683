import { AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { Progress } from 'src/v2/components/common/progress/Progress';
import { Icon } from 'src/v2/components/Icon/Icon';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { getPlaceholderColorModeClassName, getPlaceholderTypeClassName } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderColorMode, PlaceholderType } from 'src/v2/components/placeholder/Placeholder.types';
import { formatDate } from 'src/v2/utils/formatDate';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import styled, { css } from 'styled-components';
import styles from './GenerationPlaceholder.module.scss';

interface GenerationPlaceholderProps extends React.HTMLAttributes<HTMLSpanElement> {
  colorMode?: PlaceholderColorMode;
  date?: number;
  header?: string;
  message?: string;
  progress?: number;
  wide?: boolean;
}

const Headline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  font-family: Amazon Ember;
  font-weight: 700;
  font-size: 16px;
  line-height: 20.8px;
  letter-spacing: 0.2px;
`;

const DateContainer = styled.div`
  display: none;
  font-family: Amazon Ember;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0%;
`;

const SubHeadline = styled.div`
  font-family: Amazon Ember;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: 0%;
  text-align: center;
  margin-bottom: 20px;
`;

const Header = styled.div<{ colorMode?: PlaceholderColorMode; wide?: boolean }>`
  display: flex;
  flex-direction: ${({ wide }) => (wide ? 'row' : 'column')};
  justify-content: center;
  align-items: center;

  ${({ colorMode, wide }) =>
    colorMode === PlaceholderColorMode.DARKMODE
      ? css`
          color: #ffffff;

          ${!wide
            ? css`
                & ${Headline} {
                  color: #161d26;
                }
              `
            : ''}
        `
      : ''}

  ${({ wide }) =>
    wide
      ? css`
          & {
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
          }
          & ${Headline} {
            flex-direction: row;
            gap: 5px;
            text-align: left;
          }
          & ${DateContainer} {
            display: block;
          }
          & ${SubHeadline} {
            width: 100%;
            text-align: left;
          }
        `
      : ''}
`;

export const PLACEHOLDER_HEADER_GENERATING = 'Generating';
export const PLACEHOLDER_HEADER_LOADING = 'Loading';

export const DEFAULT_PLACEHOLDER_COLORMODE = isWeblabInTreatment(AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment.T1)
  ? PlaceholderColorMode.WARM
  : PlaceholderColorMode.COOL;

export const getGenerationPlaceholderClassName = () => styles.placeholder;
export const getPlaceholderWideClassName = (wide?: boolean) => styles[`variant-${wide ? 'wide' : 'normal'}`];

export const GenerationPlaceholder = (props: GenerationPlaceholderProps) => {
  const { className, colorMode = DEFAULT_PLACEHOLDER_COLORMODE, date, header, message, progress, wide, ...restProps } = props;
  const combinedClassNames = [
    getGenerationPlaceholderClassName(),
    getPlaceholderTypeClassName(PlaceholderType.GENERATION),
    getPlaceholderColorModeClassName(colorMode),
    getPlaceholderWideClassName(wide),
    className,
  ];

  return (
    <span className={getNormalizedClassNames(combinedClassNames)} {...restProps}>
      <Header wide={wide} colorMode={colorMode}>
        <Headline>
          <Icon type={iconTypes.sparkle} />
          <span>{header ? header : PLACEHOLDER_HEADER_GENERATING}</span>
        </Headline>
        <DateContainer>{formatDate(date)}</DateContainer>
        <SubHeadline>{message}</SubHeadline>
      </Header>
      <Progress className={styles.progress} progress={progress} />
    </span>
  );
};
