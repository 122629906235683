import styled, { keyframes } from 'styled-components';

const easing = {
  enter: 'cubic-bezier(0.55, 0.00, 0.00, 1.00)',
  exit: 'cubic-bezier(1.00, 0.00, 0.45, 1.00)',
  persistent: 'cubic-bezier(0.33, 0.00, 0.67, 1.00)',
};

export const duration = {
  fast: '167ms',
  quick: '250ms',
  speedy: '333ms',
  moderate: '417ms',
  deliberate: '667ms',
  slow: '833ms',
};

const keyframesDivBouncing = (initH: number, destH: number) => keyframes`
  0% {
    height: ${initH}px;
  }
  62.5% {
    height: ${destH * (destH > initH ? 1.02 : 0.99)}px;
  }
  87.5% {
    height: ${destH * (destH > initH ? 0.99 : 1.02)}px;
  }
  100% {
    height: ${destH}px;
  }
`;

const keyframesDivLinear = (initH: number, destH: number) => keyframes`
  0% {
    height: ${initH}px;
  }
  100% {
    height: ${destH}px;
  }
`;

const keyframesDivFadeIn = (isExpanding: boolean) => keyframes`
  0% {
    opacity: ${isExpanding ? 0 : 1};
  }
  100% {
    opacity: ${isExpanding ? 1 : 0};
  }
`;

const keyframesDivSlideIn = (isExpanding: boolean) => keyframes`
  0% {
    margin-top: ${isExpanding ? '-41px' : '0px'};
  }
  100% {
    margin-top: ${isExpanding ? '0px' : '-41px'};
  }
`;

const keyframesMediaOptionFadeIn = (isExpanding: boolean) => keyframes`
  0% {
    opacity: ${isExpanding ? 0 : 1};
    margin-top: ${isExpanding ? '12px' : '0px'};
    display: flex;
  }
  95% {
    opacity: ${isExpanding ? 1 : 0};
    margin-top: ${isExpanding ? '0px' : '12px'};
    display: flex;
  }
  100% {
    opacity: ${isExpanding ? 1 : 0};
    margin-top: ${isExpanding ? '0px' : '12px'};
    display: ${isExpanding ? 'flex' : 'none'};
  }
`;

const keyframesThumbnailExpanding = (isExpanding: boolean) => keyframes`
  0% {
    display: ${isExpanding ? 'none' : 'flex'};
    opacity: ${isExpanding ? 0 : 1};
    margin-left: ${isExpanding ? '-44px' : '0'};
  }
  5% {
    display: flex;
    opacity: ${isExpanding ? 0 : 1};
    margin-left: ${isExpanding ? '-44px' : '0'};
  }
  95% {
    display: flex;
    opacity: ${isExpanding ? 1 : 0};
    margin-left: ${isExpanding ? '0' : '-44px'};
  }
  100% {
    display: ${isExpanding ? 'flex' : 'none'};
    opacity: ${isExpanding ? 1 : 0};
    margin-left: ${isExpanding ? '0' : '-44px'};
  }
`;

const keyframesTileCollapsing = (isCollapsing: boolean, originalRatio: string | undefined) => keyframes`
  0% {
    opacity: 1;
    aspect-ratio: ${originalRatio};
  }
  100% {
    opacity: 0;
    aspect-ratio: 20/1;
  }
`;

export const AnimatedPanel = styled.div<{ initH: number; destH: number }>`
  background-color: #fff;
  border-radius: 16px;
  pointer-events: none;
  width: 100%;
  left: 0;
  bottom: 0;
  height: ${({ initH }) => initH}px;
  transition:
    opacity ${duration.fast},
    height ${duration.deliberate};
  animation-name: ${keyframesDivFadeIn(true)},
    ${({ initH, destH }) => (Math.abs(initH - destH) < 50 ? keyframesDivLinear(initH, destH) : keyframesDivBouncing(initH, destH))},
    ${keyframesDivFadeIn(false)};
  animation-duration: ${duration.fast}, ${({ initH, destH }) => (Math.abs(initH - destH) < 50 ? duration.fast : duration.deliberate)},
    ${duration.moderate};
  animation-timing-function: ${easing.enter}, ${easing.persistent}, ${easing.exit};
  animation-delay: 0s, ${duration.fast}, ${({ initH, destH }) => (Math.abs(initH - destH) < 50 ? '334ms' : duration.slow)};
  animation-fill-mode: forwards;

  @media (min-width: 480px) and (min-height: 940px) {
    bottom: 80px;
  }

  div[id='wrapper']:has(&) {
    overflow: hidden;
    height: 100vh;
  }
`;

export const BouncingDiv = styled.div<{ initH: number; destH: number; speed: string }>`
  height: ${({ initH }) => initH}px;
  transition: height ${duration.fast};
  animation-name: ${({ initH, destH }) => keyframesDivBouncing(initH, destH)};
  animation-duration: ${({ speed }) => speed};
  animation-timing-function: ${easing.persistent};
  animation-fill-mode: forwards;
`;

export const FadingDiv = styled.div<{ isExpanding: boolean; speed: string }>`
  opacity: ${({ isExpanding }) => (isExpanding ? 0 : 1)};
  transition:
    opacity ${({ speed }) => speed},
    display 0s ${({ isExpanding, speed }) => (isExpanding ? speed : duration.fast)};
  animation-name: ${({ isExpanding }) => keyframesDivFadeIn(isExpanding)};
  animation-timing-function: ${({ isExpanding }) => (isExpanding ? easing.enter : easing.exit)};
  animation-duration: ${duration.fast};
  animation-delay: ${({ isExpanding, speed }) => (isExpanding ? speed : '0s')};
  animation-fill-mode: forwards;
  pointer-events: ${({ isExpanding }) => (isExpanding ? 'auto' : 'none')};
`;

export const MediaSelectorDiv = styled.div<{ isExpanding: boolean }>`
  opacity: ${({ isExpanding }) => (isExpanding ? 0 : 1)};
  margin-top: ${({ isExpanding }) => (isExpanding ? '41px' : '0px')};
  transition:
    opacity ${duration.fast},
    margin-top ${duration.moderate};
  animation-name: ${({ isExpanding }) => (isExpanding ? keyframesDivSlideIn(isExpanding) : keyframesDivFadeIn(isExpanding))},
    ${({ isExpanding }) => (isExpanding ? keyframesDivFadeIn(isExpanding) : keyframesDivSlideIn(isExpanding))};
  animation-timing-function: ${({ isExpanding }) => (isExpanding ? easing.enter : easing.exit)};
  animation-duration: ${({ isExpanding }) => (isExpanding ? `${duration.moderate}, ${duration.fast}` : `${duration.fast}, ${duration.moderate}`)};
  animation-delay: ${({ isExpanding }) => (isExpanding ? '0s' : `0s, ${duration.moderate}`)};
  animation-fill-mode: forwards;
  pointer-events: ${({ isExpanding }) => (isExpanding ? 'auto' : 'none')};
`;

export const EmptyShellDiv = styled.div<{ isExpanding: boolean }>`
  border-radius: ${({ isExpanding }) => (isExpanding ? '10px' : '22px')} !important;
  @media (min-width: 720px) {
    height: ${({ isExpanding }) => (isExpanding ? '75px' : '44px')} !important;
    border-radius: ${({ isExpanding }) => (isExpanding ? '10px' : '22px')} !important;
    transition-duration: ${duration.moderate};
    transition-property: height, border-radius;
    transition-delay: ${({ isExpanding }) => (isExpanding ? '0s' : '333ms')};

    div:last-child {
      opacity: ${({ isExpanding }) => (isExpanding ? '1' : '0')} !important;
      height: ${({ isExpanding }) => (isExpanding ? '15px' : '1px')} !important;
      transition-duration: ${duration.moderate};
      transition-property: height, opacity;
      transition-delay: ${({ isExpanding }) => (isExpanding ? '0s' : '333ms')};
    }
  }
`;

export const MediaShellDiv = styled.div<{ isExpanding: boolean }>`
  @media (min-width: 720px) {
    width: ${({ isExpanding }) => (isExpanding ? '69px' : '38px')} !important;
    height: ${({ isExpanding }) => (isExpanding ? '69px' : '38px')} !important;
    transition-duration: ${duration.moderate};
    transition-property: height, width;
    transition-delay: ${({ isExpanding }) => (isExpanding ? '0s' : '333ms')};
  }
`;

export const MediaSibilingDiv = styled.div<{ isExpanding: boolean; index: number }>`
  animation-timing-function: ${({ isExpanding }) => (isExpanding ? easing.enter : easing.exit)};
  animation-duration: ${duration.moderate};
  animation-delay: ${({ isExpanding }) => (isExpanding ? '0s' : duration.moderate)};
  animation-name: ${({ isExpanding, index }) => index > 0 && keyframesThumbnailExpanding(isExpanding)};
  animation-fill-mode: forwards;
`;

export const MediaOptionDiv = styled.div<{ isExpanding: boolean; inx: number }>`
  margin-top: ${({ isExpanding }) => (isExpanding ? '12px' : '0px')};
  opacity: ${({ isExpanding }) => (isExpanding ? 0 : 1)};
  transition: all ${duration.moderate};
  animation-name: ${({ isExpanding }) => keyframesMediaOptionFadeIn(isExpanding)};
  animation-timing-function: ${({ isExpanding }) => (isExpanding ? easing.enter : easing.exit)};
  animation-duration: ${duration.moderate};
  animation-delay: ${({ inx }) => `${inx * 100}ms`};
  animation-fill-mode: forwards;
  pointer-events: ${({ isExpanding }) => (isExpanding ? 'auto' : 'none')};
`;

export const EmptyProductShellDiv = styled.div<{ isExpanding: boolean }>`
  gap: ${({ isExpanding }) => (isExpanding ? '6px' : '0px')} !important;
  width: ${({ isExpanding }) => (isExpanding ? '100%' : '44px')} !important;
  height: ${({ isExpanding }) => (isExpanding ? '60px' : '44px')} !important;
  border-radius: ${({ isExpanding }) => (isExpanding ? '8px' : '22px')} !important;
  background-color: ${({ isExpanding }) => (isExpanding ? '#FFF' : '#f5f3ef')} !important;
  border-color: ${({ isExpanding }) => (isExpanding ? '#AAABAC' : '#f5f3ef')} !important;
  transition-duration: ${duration.moderate};
  transition-property: all;
  transition-delay: ${({ isExpanding }) => (isExpanding ? '0s' : '333ms')};

  div:last-child {
    opacity: ${({ isExpanding }) => (isExpanding ? '1' : '0')} !important;
    width: ${({ isExpanding }) => (isExpanding ? '96px' : '0px')} !important;
    transition-duration: ${duration.moderate};
    transition-property: all;
    transition-delay: ${({ isExpanding }) => (isExpanding ? '0s' : '333ms')};
  }
`;

export const ProductShellDiv = styled.div<{ isExpanding: boolean }>`
  gap: ${({ isExpanding }) => (isExpanding ? '8px' : '0px')};
  padding: ${({ isExpanding }) => (isExpanding ? '8px' : '0px')};
  background: ${({ isExpanding }) => (isExpanding ? '#F5F3EF' : '#FFF')};
  min-width: ${({ isExpanding }) => (isExpanding ? 'calc(33.3% - 2px)' : '44px')};
  max-width: ${({ isExpanding }) => (isExpanding ? '40%' : '44px')};
  height: ${({ isExpanding }) => (isExpanding ? '64px' : '44px')};
  transition-duration: ${duration.moderate};
  transition-property: all;
  transition-delay: ${({ isExpanding }) => (isExpanding ? duration.moderate : '0s')};
`;

export const CollapsingTile = styled.div<{ isCollapsing: boolean; originalRatio: string | undefined }>`
  transition-property: opacity, aspect-ratio;
  opacity: 1;
  aspect-ratio: ${({ originalRatio }) => (originalRatio === '1.91:1' || originalRatio === '3:1' ? '1.8/1' : '1/1.8')};
  height: ${({ isCollapsing }) => (isCollapsing ? '1%' : 'auto')};
  animation-name: ${({ isCollapsing, originalRatio }) => (isCollapsing ? keyframesTileCollapsing(isCollapsing, originalRatio || '1/1.8') : '')};
  animation-timing-function: ${easing.exit};
  animation-duration: ${duration.moderate};
  animation-delay: 3000ms;
  animation-fill-mode: forwards;
`;

export const FloatingPreviewContainer = styled.div<{ isFadeIn: boolean }>`
  opacity: ${({ isFadeIn }) => (isFadeIn ? 0 : 1)};
  transition: opacity ${duration.moderate};
  animation-name: ${({ isFadeIn }) => keyframesDivFadeIn(isFadeIn)};
  animation-timing-function: ${({ isFadeIn }) => (isFadeIn ? easing.enter : easing.exit)};
  animation-delay: ${({ isFadeIn }) => (isFadeIn ? '0s' : duration.fast)};
  animation-duration: ${duration.fast};
  animation-fill-mode: forwards;
  pointer-events: ${({ isFadeIn }) => (isFadeIn ? 'auto' : 'none')};
  cursor: pointer;

  & + div {
    opacity: ${({ isFadeIn }) => (isFadeIn ? 0 : 1)};
    transition: opacity ${duration.moderate};
    animation-name: ${({ isFadeIn }) => keyframesDivFadeIn(isFadeIn)};
    animation-timing-function: ${({ isFadeIn }) => (isFadeIn ? easing.enter : easing.exit)};
    animation-delay: ${({ isFadeIn }) => (isFadeIn ? duration.fast : '0s')};
    animation-duration: ${duration.moderate};
    animation-fill-mode: forwards;
  }
`;
