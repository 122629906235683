import { createEntityAdapter, createSelector, createSlice, Selector, Update } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import _merge from 'lodash/merge';
import { selectProductEntities } from 'src/v2/redux/slices/product/productSlice';
import { Product, CustomImageProduct } from 'src/v2/redux/slices/product/productSlice.types';
import {
  ProductLayout,
  ProductLayoutFull,
  ReferenceImage,
  SelectedProduct,
  SelectedProductFull,
} from 'src/v2/redux/slices/userInput/userInputSlice.types';
import { getUserInputStateFromFrontendAsset } from 'src/v2/redux/slices/userInput/userInputSlice.utils';
import { RootState } from 'src/v2/redux/store';
import { AspectRatio, EffectType, EffectOption, FrontendAsset, StyleOption } from 'src/v2/types';
import { UserInputState, AdvancedSettings } from './userInputSlice.types';

enableMapSet();

export const productLayoutAdapter = createEntityAdapter<ProductLayout>();
export const referenceImagesAdapter = createEntityAdapter<ReferenceImage>();
export const customImageProductsAdapter = createEntityAdapter<CustomImageProduct>();
const { selectAll: selectAllCustomImageProducts } = customImageProductsAdapter.getSelectors();
const { selectById, selectAll } = productLayoutAdapter.getSelectors();

const initialState: UserInputState = getInitialUserInputState();

export const userInputSlice = createSlice({
  name: 'userInput',
  initialState,
  reducers: (create) => ({
    setUserInputAspectRatio: create.reducer<AspectRatio>((state, action) => {
      state.aspectRatio = action.payload;
    }),
    appendProductLayout: create.reducer<ProductLayout>((state, action) => {
      if (state.productLayouts.ids.length >= 1) {
        state.autoLayoutEnabled = false;
      }
      productLayoutAdapter.addOne(state.productLayouts, action.payload);
    }),
    appendManyProductLayout: create.reducer<ProductLayout[]>((state, action) => {
      if (state.productLayouts.ids.length >= 1) {
        state.autoLayoutEnabled = false;
      }
      productLayoutAdapter.addMany(state.productLayouts, action.payload);
    }),
    removeProductLayout: create.reducer<ProductLayout['id']>((state, action) => {
      productLayoutAdapter.removeOne(state.productLayouts, action.payload);
    }),
    removeAllProductLayouts: create.reducer((state) => {
      productLayoutAdapter.removeAll(state.productLayouts);
    }),
    updateProductLayout: create.reducer<Update<ProductLayout, ProductLayout['id']>>((state, action) => {
      productLayoutAdapter.updateOne(state.productLayouts, action.payload);
    }),
    updateManyProductLayout: create.reducer<Update<ProductLayout, ProductLayout['id']>[]>((state, action) => {
      if (action.payload.length === 0) return;
      if (state.productLayouts.ids.length >= 1) {
        state.autoLayoutEnabled = false;
      }
      productLayoutAdapter.updateMany(state.productLayouts, action.payload);
    }),
    resetProductLayouts: create.reducer<void>((state) => {
      const { ids, entities } = state.productLayouts;
      // reset all layout's position to the top left
      ids.forEach((id, index) => {
        entities[id].boundingBox = {
          ...entities[id].boundingBox,
          top: index * 20,
          left: index * 20,
        };
      });
    }),
    setSelectedProduct: create.reducer<SelectedProduct | undefined>((state, action) => {
      state.selectedProduct = action.payload;
    }),
    setTextPrompt: create.reducer<string>((state, action) => {
      state.textPrompt = action.payload;
    }),
    setAdvancedSettings: create.reducer<Partial<AdvancedSettings>>((state, action) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        ...action.payload,
      };
    }),
    setAdvancedMode: create.reducer<boolean>((state, action) => {
      state.isAdvancedMode = action.payload;
    }),
    setPromptRewrite: create.reducer<boolean>((state, action) => {
      state.isPromptRewriteEnabled = action.payload;
    }),
    setAutoLayout: create.reducer<boolean>((state, action) => {
      state.autoLayoutEnabled = action.payload;
    }),
    setStyleSelection: create.reducer<StyleOption | undefined>((state, action) => {
      state.style = action.payload;
    }),
    clearStyleSelection: create.reducer((state) => {
      delete state.style;
    }),
    setEffectSelection: create.reducer<{ type: EffectType; value?: EffectOption }>((state, action) => {
      const { type, value } = action.payload;
      if (value) {
        state.effects[type] = value;
      } else {
        delete state.effects[type];
      }
    }),
    clearEffectSelection: create.reducer<EffectType>((state, action) => {
      delete state.effects[action.payload];
    }),
    addReferenceImage: create.reducer<ReferenceImage>((state, action) => {
      if (referenceImagesAdapter.getSelectors().selectTotal(state.referenceImages) < 3) {
        referenceImagesAdapter.addOne(state.referenceImages, action.payload);
      }
    }),
    removeReferenceImage: create.reducer<ReferenceImage['id']>((state, action) => {
      referenceImagesAdapter.removeOne(state.referenceImages, action.payload);
    }),
    clearReferenceImages: create.reducer((state) => {
      referenceImagesAdapter.removeAll(state.referenceImages);
    }),
    setReferenceImageStrength: create.reducer<{ id: string; strength: number }>((state, action) => {
      const { id, strength } = action.payload;
      referenceImagesAdapter.updateOne(state.referenceImages, {
        id,
        changes: { strength },
      });
    }),
    reuseSettingsFromAsset: create.reducer<{ asset: FrontendAsset }>((state, action) => {
      return _merge(
        // Use initial state as a base
        getInitialUserInputState(),
        // Apply settings from given asset
        getUserInputStateFromFrontendAsset({ asset: action.payload.asset }),
      );
    }),
    addCustomImageProducts: create.reducer<CustomImageProduct[]>((state, action) => {
      customImageProductsAdapter.addMany(state.customImageProducts, action.payload);
    }),
    removeCustomImageProduct: create.reducer<CustomImageProduct['id']>((state, action) => {
      customImageProductsAdapter.removeOne(state.customImageProducts, action.payload);
    }),
  }),
  selectors: {
    getAspectRatio: (state) => state.aspectRatio,
    getAllProductLayouts: (state) => selectAll(state.productLayouts),
    getProductLayoutById: (state, id: string) => selectById(state.productLayouts, id),
    getSelectedProduct: (state) => state.selectedProduct,
    getTextPrompt: (state) => state.textPrompt,
    getStyleSelection: (state) => state.style,
    getEffectSelections: (state) => state.effects,
    getEffectSelection: (state, type: EffectType): EffectOption | undefined => state.effects[type],
    getReferenceImages: (state) => Object.values(state.referenceImages.entities),
    getAdvancedSettings: (state) => state.advancedSettings,
    getNegativePrompt: (state) => state.advancedSettings.negativePrompt,
    getSeed: (state) => state.advancedSettings.seed,
    getTemperature: (state) => state.advancedSettings.temperature,
    getIsAdvancedMode: (state) => state.isAdvancedMode,
    getIsPromptRewriteEnabled: (state) => state.isPromptRewriteEnabled,
    getAutoLayoutEnabled: (state) => state.autoLayoutEnabled,
    getCustomImageProducts: (state) => selectAllCustomImageProducts(state.customImageProducts),
    getCustomImageProductEntities: (state) => state.customImageProducts.entities,
  },
});

export const {
  setUserInputAspectRatio,
  removeAllProductLayouts,
  appendProductLayout,
  appendManyProductLayout,
  removeProductLayout,
  updateProductLayout,
  updateManyProductLayout,
  setSelectedProduct,
  setTextPrompt,
  setAdvancedSettings,
  setAdvancedMode,
  setPromptRewrite,
  setStyleSelection,
  setAutoLayout,
  clearStyleSelection,
  setEffectSelection,
  addReferenceImage,
  removeReferenceImage,
  clearReferenceImages,
  setReferenceImageStrength,
  clearEffectSelection,
  reuseSettingsFromAsset,
  addCustomImageProducts,
  resetProductLayouts,
} = userInputSlice.actions;
export const userInputReducer = userInputSlice.reducer;

export const {
  getAspectRatio,
  getAllProductLayouts,
  getProductLayoutById,
  getSelectedProduct,
  getTextPrompt,
  getNegativePrompt,
  getSeed,
  getTemperature,
  getIsAdvancedMode,
  getIsPromptRewriteEnabled,
  getStyleSelection,
  getEffectSelections,
  getEffectSelection,
  getReferenceImages,
  getAdvancedSettings,
  getAutoLayoutEnabled,
  getCustomImageProducts,
  getCustomImageProductEntities,
} = userInputSlice.selectors;

const getFullProductLayoutWithEntities = (layout: ProductLayout, entities: Record<string, Product>): ProductLayoutFull | undefined => {
  const product = entities[layout.product.id];
  if (!product) return undefined;
  return {
    ...layout,
    product: {
      ...layout.product,
      ...product,
    },
  };
};

export const getAllFullProductLayout: Selector<RootState, ProductLayoutFull[]> = createSelector(
  [getAllProductLayouts, selectProductEntities, getCustomImageProductEntities],
  (layouts, asinProductEntities, customProductEntities) => {
    return layouts
      .map((layout) => getFullProductLayoutWithEntities(layout, { ...asinProductEntities, ...customProductEntities }))
      .filter((value): value is ProductLayoutFull => !!value) satisfies ProductLayoutFull[];
  },
);

export const getFullProductLayout = (id: string): Selector<RootState, ProductLayoutFull | undefined> => {
  const selectLayout = (state: RootState): ProductLayout => getProductLayoutById(state, id);
  return createSelector([selectLayout, selectProductEntities], getFullProductLayoutWithEntities);
};

export const getSelectedProductFull = createSelector(
  [(state: RootState) => state.userInput.selectedProduct, (state: RootState) => state.product.entities],
  (selectedProduct, productEntities) => {
    if (!selectedProduct) return;
    const productEntity = productEntities[selectedProduct.id];
    if (!productEntity) return;
    const selectedProductFull: SelectedProductFull = {
      ...productEntity,
      ...selectedProduct,
    };
    return selectedProductFull;
  },
);

function getInitialUserInputState(): UserInputState {
  return {
    aspectRatio: AspectRatio.HORIZONTAL_191_TO_1,
    productLayouts: productLayoutAdapter.getInitialState(),
    selectedProduct: undefined,
    textPrompt: undefined,
    isAdvancedMode: false,
    isPromptRewriteEnabled: false,
    style: undefined,
    effects: {},
    referenceImages: referenceImagesAdapter.getInitialState(),
    advancedSettings: {
      seed: undefined,
      temperature: undefined,
      negativePrompt: undefined,
    },
    autoLayoutEnabled: true,
    customImageProducts: customImageProductsAdapter.getInitialState(),
  };
}
