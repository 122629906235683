import { uploadInputImageAssetMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/ImageUpload.middleware';
import { GenerativeResizingGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { EditedImageAsset } from 'src/v2/types';
import { handleGenerativeResizingGenerationSubmission } from './handleGenerativeResizingGenerationSubmission';

/**
 * The primary handler for generating a list of edited images using the GENERATIVE_RESIZING workflow.
 */
export async function handleGenerativeResizingGeneration({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: GenerativeResizingGenerationConfig;
}): Promise<{
  generatedAssets: EditedImageAsset[];
}> {
  const { userInputs } = generationConfig;
  const { inputImageAsset } = userInputs;

  // STEP - Get input image reference ID
  const inputImageReferenceId = await uploadInputImageAssetMiddleware({ backendDispatchContext, inputImageAsset });

  const generatedAssets = await handleGenerativeResizingGenerationSubmission({
    backendDispatchContext,
    generationConfig,
    inputImageReferenceId,
  });

  return { generatedAssets };
}
