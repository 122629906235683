import { BatchResult } from '@amzn/genaihub-typescript-client';
import { uploadProductMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/ImageUpload.middleware';
import { retrieveWorkflowResultMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/RetrieveWorkflowResult.middleware';
import { submitWorkflowMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/SubmitWorkflow.middleware';
import { TextPromptGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { GuidedTextGenerationWorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { WorkflowIdEnum } from 'src/v2/types';
import { getThemeFromStyleOption, sanitizeText } from 'src/v2/utils/UserInputs.utils';

/**
 * The primary handler for generating a list of guided text prompts.
 */
export async function handleTextPromptGeneration({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: TextPromptGenerationConfig;
}): Promise<string[]> {
  const workflowId = WorkflowIdEnum.GUIDED_TEXT_GENERATION;
  const {
    entityId,
    options: { promptType, relatedWorkflowId },
    outputCount,
    studioRequest,
    userInputs: { product, style },
  } = generationConfig;
  const theme = getThemeFromStyleOption({ style, workflowId: relatedWorkflowId ?? workflowId });

  // STEP - Get product reference ID
  let productImageReferenceId = generationConfig.options.productImageReferenceId;
  if (!productImageReferenceId) {
    productImageReferenceId = await uploadProductMiddleware({ backendDispatchContext, product });
  }

  // STEP - Submit workflow for GUIDED_TEXT_GENERATION
  const title = sanitizeText({ text: product.metadata.title });
  const workflowOptions: GuidedTextGenerationWorkflowOptions = {
    asin: product.asin,
    featureBullets: product.metadata.featureBullets,
    productImageRef: productImageReferenceId,
    productType: product.metadata.type,
    promptCount: outputCount,
    promptType,
    theme,
    title,
    workflowId,
  };
  const batchId = await submitWorkflowMiddleware({
    backendDispatchContext,
    payload: {
      body: {
        workflowOptions,
      },
      studioRequest,
      workflowId,
    },
  });

  // STEP - Retrieve result for GUIDED_TEXT_GENERATION
  return await retrieveWorkflowResultMiddleware<string[]>({
    backendDispatchContext,
    payload: {
      batchId,
      entityId,
      studioRequest,
      workflowId,
    },
    onSuccessHandler: getGuidedTextPromptsFromBatchResult,
  });
}

/**
 * A helper function to get a list of guided text prompts from a BatchResult
 */
export function getGuidedTextPromptsFromBatchResult({ response }: { response: BatchResult }): string[] | Error {
  const guidedTextPrompts = response.jobs?.[0]?.urls;
  if (!guidedTextPrompts?.length) {
    return new Error('Guided text generation failed due to no prompts in batch result');
  }
  return guidedTextPrompts;
}
