import { Inspirations as InspirationsType, InspirationalContent } from '@amzn/genaihub-typescript-client';
import { Text } from '@amzn/storm-ui';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'src/AppContext';
import AssetGallery from 'src/components/common/assetGallery/AssetGallery';
import Title from 'src/components/common/storm/Title';
import { getPageName } from 'src/components/utils/getPageName';
import { Metrics } from 'src/constants';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { MUIBreakpoints as Breakpoints, useBreakpoints } from 'src/hooks/useBreakpoints';
import { AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonSizes, ButtonTypes } from 'src/v2/components/Button/types';
import styled from 'styled-components';
import componentStyles from './Inspirations.module.scss';

const isInputPanelRefresh = isWeblabInTreatment(AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment.T1);

export interface InspirationsProps {
  showButton?: boolean;
  width?: string;
  maxItems?: number;
}

const defaultWidth = '100%';

const Container = styled(Stack)`
  flex-direction: column;
`;

const Header = styled(Stack)<{ width?: string; isInputPanelRefresh: boolean }>`
  flex-direction: row;
  width: ${({ width }) => width || defaultWidth};
  align-self: flex-start;
  max-width: ${({ isInputPanelRefresh }) => (isInputPanelRefresh ? '100%' : '2000px')};
  margin-bottom: 20px;
  padding-right: var(--div-spacing-default);
  padding-left: var(--div-spacing-default);
  margin-bottom: var(--tools-description-container-margin-bottom);
`;

const GalleryContainer = styled.div<{ width?: string; isInputPanelRefresh: boolean }>`
  flex-direction: row;
  width: ${({ width }) => width || defaultWidth};
  align-self: flex-start;
  max-width: ${({ isInputPanelRefresh }) => (isInputPanelRefresh ? '100%' : '2000px')};
  margin-bottom: 20px;
  padding-right: var(--div-spacing-default);
  padding-left: var(--div-spacing-default);
`;

const Inspirations = (props: InspirationsProps) => {
  const { showButton, width, maxItems } = props;
  const { authenticated, metrics } = useContext(AppContext);
  const [assets, setAssets] = useState<InspirationsType>([]);
  const genAIBackendClient = useAIBackendHubClient();
  const [xl, lg, md, sm] = useBreakpoints(Breakpoints.xl, Breakpoints.lg, Breakpoints.md, Breakpoints.sm);
  const columns = xl ? 3 : lg ? 3 : md ? 3 : sm ? 2 : 1;
  const linkTo = useNavigate();
  const title = 'Get inspired',
    defaultWidth = '100%';

  const handleClickEvent = (page: string) => {
    metrics.trackMetrics(
      Metrics.Methods.HomePage,
      { [Metrics.Names.ButtonId]: Metrics.Buttons.ViewAll, [Metrics.Names.Destination]: getPageName(page) },
      { [Metrics.Counters.Clicks]: 1 },
    );
    linkTo(page);
  };

  const assetClickHandler = (asset: InspirationalContent) => {
    linkTo('/studio' + (asset.textPrompt ? '?prompt=' + asset.textPrompt + '&generate=true' : ''));
  };

  useEffect(() => {
    authenticated ? getInspirations() : getCreativeContent();
  }, []);

  const getInspirations = async (startKey?: string) => {
    try {
      const response = await genAIBackendClient.retrieveInspirationsList({
        startKey,
      });

      setAssets([...assets, ...(response.body.inspirations ?? [])].splice(0, maxItems));
    } catch (error) {
      console.error('Error while getting inspirations list', error);
    }
  };

  const getCreativeContent = async () => {
    const response = await import('src/config/unauthenticated/gallery-content.json');
    setAssets(response.default.slice(0, 6));
  };

  return (
    <Container>
      <Header style={{ width: width || defaultWidth }} isInputPanelRefresh={isInputPanelRefresh}>
        <Text
          style={{
            color: 'var(--text-primary)',
            width: '100%',
            paddingBottom: 'var(--tools-title-padding-bottom)',
            fontFamily: 'AmazonEmberMono-Bold',
            letterSpacing: '3px',
          }}
          data-testid="gallery-section-header-title"
        >
          GALLERY
        </Text>
        {showButton && (
          <Title className={componentStyles.description} title={title} style={{ flex: 1 }} dataTestId="gallery-section-header-description" />
        )}
        {showButton && (
          <Button
            label="Explore the gallery"
            size={ButtonSizes.Large}
            type={ButtonTypes.Secondary}
            borderRadius="999px"
            onClick={() => handleClickEvent(authenticated ? '/browse' : '/gallery')}
            dataTestId="gallery-section-header-page-link"
          />
        )}
      </Header>
      <GalleryContainer width={width || defaultWidth} isInputPanelRefresh={isInputPanelRefresh}>
        <AssetGallery assets={assets} columns={columns} spacing={2} detailsClickHandler={assetClickHandler} />
      </GalleryContainer>
    </Container>
  );
};

export default Inspirations;
