export const formatDate = (date?: Date | number | string): string => {
  if (!date) return '';

  const convertedDate = new Date(date);

  if (isNaN(convertedDate.getTime())) {
    return '';
  }

  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday = convertedDate.toDateString() === now.toDateString();
  const isYesterday = convertedDate.toDateString() === yesterday.toDateString();

  const hours = convertedDate.getHours();
  const minutes = convertedDate.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const timeString = `${formattedHours}:${formattedMinutes}${ampm}`;

  if (isToday) {
    return `Today at ${timeString}`;
  }
  if (isYesterday) {
    return `Yesterday at ${timeString}`;
  }

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    weekday: 'short',
    day: 'numeric',
  };

  return `${convertedDate.toLocaleDateString('en-US', options)} at ${timeString}`;
};
