import { Asset as BackendAsset } from '@amzn/genaihub-typescript-client';
import { LifestyleImageryWorkflowUserInputs } from 'src/v2/contexts/assetGeneration/types/WorkflowUserInputs.types';
import { ASINMetadataMap } from 'src/v2/contexts/feed/FeedContext.types';
import { getProductFromBackendAsset } from 'src/v2/contexts/feed/util/product/Product.utils';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { AssetTypeEnum, BasicAssetProperties, DEFAULT_ASPECT_RATIO, LifestyleImageAsset, WorkflowIdEnum } from 'src/v2/types';
import { getAspectRatioEnumFromString, getEffectsAndTextPromptFromConstructedTextPrompt } from 'src/v2/utils/UserInputs.utils';

export async function buildLifestyleImageAsset({
  asinMetadataMap,
  backendAsset,
  basicAssetProperties,
}: {
  asinMetadataMap: ASINMetadataMap;
  backendAsset: BackendAsset;
  basicAssetProperties: BasicAssetProperties;
}): Promise<LifestyleImageAsset> {
  const userInputs = await buildLifestyleImageryWorkflowUserInputs({ asinMetadataMap, backendAsset, basicAssetProperties });
  return {
    ...basicAssetProperties,
    type: AssetTypeEnum.IMAGE,
    userInputs,
    workflowId: WorkflowIdEnum.LIFESTYLE_IMAGERY,
    workflowOptions: undefined,
    productData: undefined,
  };
}

export async function buildLifestyleImageryWorkflowUserInputs({
  asinMetadataMap,
  backendAsset,
  basicAssetProperties,
}: {
  asinMetadataMap: ASINMetadataMap;
  backendAsset: BackendAsset;
  basicAssetProperties: BasicAssetProperties;
}): Promise<LifestyleImageryWorkflowUserInputs | undefined> {
  const aspectRatio = getAspectRatioEnumFromString({ aspectRatio: basicAssetProperties.aspectRatio }) ?? DEFAULT_ASPECT_RATIO;
  const { effects, textPrompt } = getEffectsAndTextPromptFromConstructedTextPrompt({ constructedTextPrompt: backendAsset.inputPrompt });
  const rewriteUserCustomPrompt = false;
  const product = await getProductFromBackendAsset({ asinMetadataMap, backendAsset });
  // Type guarding
  if (product) {
    if (textPrompt) {
      // With a text prompt, the product can be any type
      return { aspectRatio, effects, product, rewriteUserCustomPrompt, textPrompt };
    } else if (product.type === ProductType.ASIN) {
      // Without a text prompt, the product must be an AsinProduct
      return { aspectRatio, effects, product, rewriteUserCustomPrompt, style: undefined, textPrompt: undefined };
    } else {
      console.error('LIFESTYLE_IMAGERY workflow expects text prompt to be defined when using custom product image');
    }
  } else if (textPrompt) {
    return { aspectRatio, effects, product: undefined, rewriteUserCustomPrompt, textPrompt };
  } else {
    console.error('LIFESTYLE_IMAGERY workflow expects at least a product and/or text prompt to be defined');
  }

  console.error(`Failed to build valid LIFESTYLE_IMAGERY workflow user inputs for asset: '${basicAssetProperties.id}'`);
  return undefined;
}
