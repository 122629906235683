import {
  BackendActionBase,
  BackendActionType,
  Request,
  RequestStatus,
  RequestsLookup,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';

interface AbortActionPayload {
  id: string;
}

export type AbortAction = BackendActionBase<BackendActionType.ABORT, AbortActionPayload>;

export const handleAbortAction = ({ action, requestsLookup }: { action: AbortAction; requestsLookup: RequestsLookup }) => {
  markRequestAsAborted({ requestId: action.payload.id, requestsLookup });
};

interface AbortByGroupIdActionPayload {
  groupId: string;
}

export type AbortByGroupIdAction = BackendActionBase<BackendActionType.ABORT_BY_GROUP_ID, AbortByGroupIdActionPayload>;

export const handleAbortByGroupIdAction = ({ action, requestsLookup }: { action: AbortByGroupIdAction; requestsLookup: RequestsLookup }) => {
  const requestIdsOfGroup = requestsLookup.byGroup[action.payload.groupId];
  if (!requestIdsOfGroup) {
    console.warn(`Abort skipped. Request group with id '${action.payload.groupId}' not found`);
    return;
  }
  requestIdsOfGroup.forEach((requestId) => {
    markRequestAsAborted({ requestId, requestsLookup });
  });
};

function markRequestAsAborted({ requestId, requestsLookup }: { requestId: Request['id']; requestsLookup: RequestsLookup }) {
  const request = requestsLookup.byId[requestId];
  if (!request) {
    console.warn(`Abort skipped. Request with id '${requestId}' not found`);
    return;
  }
  if (request.status === RequestStatus.PENDING) {
    console.info(`Abort request with id ${requestId}`);
    request.status = RequestStatus.ABORTED;
    request.action.onAbort?.();
  }
}
