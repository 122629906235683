import { Asset as BackendAsset } from '@amzn/genaihub-typescript-client';
import { uploadReferenceImageMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/ImageUpload.middleware';
import { ImageToImport } from 'src/v2/contexts/assetGeneration/types/WorkflowUserInputs.types';
import { handlePublishAssetToFeed, handleRetrieveAsset } from 'src/v2/contexts/assetGeneration/utils/common/BackendDispatchContext.utils';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { getBackendAssetTimestampAsNumber } from 'src/v2/contexts/feed/util/asset/Asset.utils';
import { AssetTypeEnum, UploadedImageAsset, WorkflowIdEnum } from 'src/v2/types';

const ProgressThresholds = {
  FILE_UPLOAD_COMPLETE: 0.7,
  PUBLISH_ASSET_COMPLETE: 0.8,
  RETRIEVE_ASSET_COMPLETE: 0.9,
  DONE: 1.0,
};

export async function handleUploadedImageGenerationSubmission({
  backendDispatchContext,
  entityId,
  imageToImport,
  progressCallback,
}: {
  backendDispatchContext: BackendDispatchContextType;
  entityId: string | undefined;
  imageToImport: ImageToImport;
  progressCallback?: (progress: number) => void;
}): Promise<UploadedImageAsset> {
  // STEP - upload image to backend and get reference ID
  const inputImageReferenceId = await uploadReferenceImageMiddleware({
    backendDispatchContext,
    urlOrFileOrRefId: imageToImport.url,
    progressCallback: (progress) => {
      progressCallback?.(progress * ProgressThresholds.FILE_UPLOAD_COMPLETE);
    },
  });

  // STEP - publish image to backend feed
  const assetId = await handlePublishAssetToFeed({
    backendDispatchContext,
    payload: {
      body: {
        feedOptions: {
          aspectRatio: imageToImport.aspectRatio,
          input_image: inputImageReferenceId,
        },
      },
      entityId,
      workflowId: WorkflowIdEnum.IMPORT_IMAGE,
    },
  });

  progressCallback?.(ProgressThresholds.PUBLISH_ASSET_COMPLETE);

  // STEP - retrieve asset for uploaded image
  const asset = await handleRetrieveAsset({
    backendDispatchContext,
    payload: {
      id: assetId,
      entityId,
    },
  });

  progressCallback?.(ProgressThresholds.RETRIEVE_ASSET_COMPLETE);

  const uploadedImageAsset = buildUploadedImageAssetFromAsset({ asset });

  progressCallback?.(ProgressThresholds.DONE);

  return uploadedImageAsset;
}

export const BuildUploadedImageAssetFromAssetErrorMessages = {
  NO_ASPECT_RATIO: 'Aspect ratio not available on asset',
  NO_BATCH_ID: 'Batch ID not available on asset',
  NO_ID: 'ID not available on asset',
  NO_JOB_ID: 'Job ID not available on asset',
};

export function buildUploadedImageAssetFromAsset({ asset }: { asset: BackendAsset }): UploadedImageAsset {
  if (!asset.id) throw new Error(BuildUploadedImageAssetFromAssetErrorMessages.NO_ID);
  if (!asset.aspectRatio) throw new Error(BuildUploadedImageAssetFromAssetErrorMessages.NO_ASPECT_RATIO);
  if (!asset.batchId) throw new Error(BuildUploadedImageAssetFromAssetErrorMessages.NO_BATCH_ID);
  if (!asset.jobId) throw new Error(BuildUploadedImageAssetFromAssetErrorMessages.NO_JOB_ID);

  const timestamp = getBackendAssetTimestampAsNumber({ timestamp: asset.timestamp });

  return {
    aspectRatio: asset.aspectRatio,
    batchId: asset.batchId,
    catwalkImageId: asset.imageId,
    id: asset.id,
    jobId: asset.jobId,
    timestamp,
    type: AssetTypeEnum.IMAGE,
    url: asset.uri,
    userInputs: undefined,
    workflowId: WorkflowIdEnum.IMPORT_IMAGE,
    workflowOptions: undefined,
    productData: undefined,
  };
}
