// Camera effects
import effectCameraBirdsEye from 'src/images/effects/camera/birds-eye.png';
import effectCameraFishEye from 'src/images/effects/camera/fish-eye.png';
import effectCameraHeroView from 'src/images/effects/camera/hero-view.png';
import effectCameraSlightlyAbove from 'src/images/effects/camera/slightly-above.png';
import effectCameraStraightOn from 'src/images/effects/camera/straight-on.png';
import effectCameraWideAngle from 'src/images/effects/camera/wide-angle.png';
import effectCameraWormsEye from 'src/images/effects/camera/worms-eye.png';
import effectCameraZoom from 'src/images/effects/camera/zoom.png';

// Color effects
import effectColorMutedWinter from 'src/images/effects/color/muted-winter.png';
import effectColorNaturalPastel from 'src/images/effects/color/natural-pastel.png';
import effectColorVividGreen from 'src/images/effects/color/vivid-green.png';
import effectColorWarmAutumn from 'src/images/effects/color/warm-autumn.png';

// Lighting effects
import effectLightingDimlyLit from 'src/images/effects/lighting/dimly-lit.png';
import effectLightingEerie from 'src/images/effects/lighting/eerie.png';
import effectLightingMoonlight from 'src/images/effects/lighting/moonlight.png';
import effectLightingNaturalFront from 'src/images/effects/lighting/natural-front.png';
import effectLightingNatural from 'src/images/effects/lighting/natural.png';
import effectLightingNeon from 'src/images/effects/lighting/neon.png';
import EffectLightingNighttime from 'src/images/effects/lighting/nighttime.png';
import effectLightingNostalgic from 'src/images/effects/lighting/nostalgic.png';
import effectLightingSharpLight from 'src/images/effects/lighting/sharp-light.png';
import effectLightingSoft from 'src/images/effects/lighting/soft.png';
import effectLightingStudio from 'src/images/effects/lighting/studio.png';
import effectLightingSunlight from 'src/images/effects/lighting/sunlight.png';
import effectLightingSunset from 'src/images/effects/lighting/sunset.png';
import { EffectOption, EffectType } from 'src/v2/types';

export interface EffectTypeConfig {
  label: string;
  options: EffectOption[];
}

export interface EffectLookup {
  effectType: EffectType;
  effectOption: EffectOption;
}

export const NoEffect = 'None';

const lightingAndShadowEffects: EffectOption[] = [
  {
    label: NoEffect,
    value: NoEffect,
  },
  {
    label: 'Natural lighting',
    value: 'natural light fills the scene, casting soft shadows',
    icon: effectLightingNatural,
  },
  {
    label: 'Sunlight',
    value: 'intense sunlight fills the scene, casting deep shadows',
    icon: effectLightingSunlight,
  },
  {
    label: 'Studio lighting',
    value: 'neutral studio lighting, casting balanced shadows',
    icon: effectLightingStudio,
  },
  {
    label: 'Soft lighting',
    value: 'diffused studio lighting',
    icon: effectLightingSoft,
  },
  {
    label: 'Dimly lit',
    value: 'dimly lit scene, dark shadows',
    icon: effectLightingDimlyLit,
  },
  {
    label: 'Sunset',
    value: 'at sunset',
    icon: effectLightingSunset,
  },
  {
    label: 'Sharp light and shadows',
    value: 'dramatic sharp lighting with long shadows',
    icon: effectLightingSharpLight,
  },
  {
    label: 'Natural front lighting',
    value: 'front lighting',
    icon: effectLightingNaturalFront,
  },
  {
    label: 'Moonlight',
    value: 'blue hued moonlight, dark shadows',
    icon: effectLightingMoonlight,
  },
  {
    label: 'Nostalgic lighting',
    value: 'strong amber light fills the space',
    icon: effectLightingNostalgic,
  },
  {
    label: 'Neon lighting',
    value: 'colorful neon light fills the space',
    icon: effectLightingNeon,
  },
  {
    label: 'Eerie lighting',
    value: 'dark eerie lighting',
    icon: effectLightingEerie,
  },
  {
    label: 'Nighttime lighting',
    value: 'nighttime lighting',
    icon: EffectLightingNighttime,
  },
];

const cameraPositionEffects: EffectOption[] = [
  {
    label: NoEffect,
    value: NoEffect,
  },
  {
    label: 'Straight on',
    value: 'straight on view',
    icon: effectCameraStraightOn,
  },
  {
    label: 'Slightly above',
    value: 'slightly above view',
    icon: effectCameraSlightlyAbove,
  },
  {
    label: "Bird's eye view",
    value: "bird's eye view",
    icon: effectCameraBirdsEye,
  },
  {
    label: 'Hero view',
    value: 'hero view',
    icon: effectCameraHeroView,
  },
  {
    label: "Worm's eye view",
    value: "worm's eye view",
    icon: effectCameraWormsEye,
  },
  {
    label: 'Fish-eye lens',
    value: 'fish eye lens',
    icon: effectCameraFishEye,
  },
  {
    label: 'Wide-angle lens',
    value: 'wide angle lens',
    icon: effectCameraWideAngle,
  },
  {
    label: 'Zoom lens',
    value: 'zoom lens',
    icon: effectCameraZoom,
  },
];

const toneAndColorEffects: EffectOption[] = [
  {
    label: NoEffect,
    value: NoEffect,
  },
  {
    label: 'Natural pastel colors',
    value: 'with a soft pastel vibe and colors',
    icon: effectColorNaturalPastel,
  },
  {
    label: 'Vivid green colors',
    value: 'with a bright summer vibe and colors',
    icon: effectColorVividGreen,
  },
  {
    label: 'Muted winter colors',
    value: 'with a winter vibe and colors',
    icon: effectColorMutedWinter,
  },
  {
    label: 'Warm autumn colors',
    value: 'with an autumn vibe and colors',
    icon: effectColorWarmAutumn,
  },
];

export const EffectSettings: Record<EffectType, EffectTypeConfig> = {
  [EffectType.LIGHTING]: {
    label: 'Lighting & Shadow',
    options: lightingAndShadowEffects,
  },
  [EffectType.CAMERA]: {
    label: 'Camera Position',
    options: cameraPositionEffects,
  },
  [EffectType.COLOR]: {
    label: 'Color Palette',
    options: toneAndColorEffects,
  },
};

export const EffectLookupByValue: Record<string, EffectLookup> = (() => {
  const effectLookupByValue: Record<string, EffectLookup> = {};
  for (let [effectType, effectTypeConfig] of Object.entries(EffectSettings)) {
    for (const effectOption of effectTypeConfig.options) {
      effectLookupByValue[effectOption.value] = {
        effectType: effectType as EffectType,
        effectOption,
      };
    }
  }
  return effectLookupByValue;
})();
