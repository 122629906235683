import { combineReducers, configureStore } from '@reduxjs/toolkit';
import assetGenerationsReducer from './slices/assetGenerations/assetGenerationsSlice';
import editReducer from './slices/edit/editSlice';
import feedReducer from './slices/feed/feedSlice';
import productReducer, { productListenerMiddleware } from './slices/product/productSlice';
import { themeReducer } from './slices/theme/themeSlice';
import { userInputReducer } from './slices/userInput/userInputSlice';

const rootReducer = combineReducers({
  assetGenerations: assetGenerationsReducer,
  edit: editReducer,
  product: productReducer,
  feed: feedReducer,
  userInput: userInputReducer,
  theme: themeReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(productListenerMiddleware.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
