import { memo, useEffect, useState } from 'react';
import { ImageModal, ImageModalContext, useImageModal } from 'src/components/imageModal';
import { ContentItem, getValidFormatForStudioInputSettings, StudioInputSettings } from 'src/components/pages/studio/StudioContext';
import usePreview from 'src/hooks/usePreview';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { getAssetModalView, getEditBaseAsset, setEditBaseAssetId } from 'src/v2/redux/slices/edit/editSlice';
import { AssetModalView } from 'src/v2/redux/slices/edit/editSlice.types';
import { getFeedAssetsSortedByDate } from 'src/v2/redux/slices/feed/feedSlice';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { reuseSettingsFromAsset } from 'src/v2/redux/slices/userInput/userInputSlice';
import { SelectedProductFull } from 'src/v2/redux/slices/userInput/userInputSlice.types';
import { ASINItem, EffectOption, EffectType, FrontendAsset, SelectedEffects, SelectedEffectsMap } from 'src/v2/types';
import { getAssetEffects, getAssetProduct, getAssetStyle, getAssetTextPrompt } from 'src/v2/utils/FrontendAssetUtils';
import { getFeedContentTypeByWorkflowId } from 'src/v2/utils/utils';

export const AssetModalWrapper = memo(() => {
  const appDispatch = useAppDispatch();
  const editBaseAsset = useAppSelector(getEditBaseAsset);
  const assetModalView = useAppSelector(getAssetModalView);
  // NOTE: for UX 2.0 Edit/Preview modal, this state may make more sense in the Edit Slice
  const { isOpen, closeModal, openModal } = useImageModal();
  const [currentContentItemInPreview, setCurrentContentItemInPreview] = useState<ContentItem>();
  const [indexOfAssetInPreview, setIndexOfAssetInPreview] = useState<number>(-1);
  const feedAssets = useAppSelector(getFeedAssetsSortedByDate);

  useEffect(() => {
    if (editBaseAsset) {
      setCurrentContentItemInPreview(convertFrontendAssetToContentItem({ asset: editBaseAsset }));
      setIndexOfAssetInPreview(feedAssets.findIndex((feedAsset) => feedAsset.id === editBaseAsset.id));
      openModal();
    } else {
      setCurrentContentItemInPreview(undefined);
      setIndexOfAssetInPreview(-1);
      closeModal();
    }
  }, [editBaseAsset, feedAssets]);

  const setCurrentAssetInPreviewByIndex = (index: number) => {
    setIndexOfAssetInPreview(index);
    if (feedAssets && index >= 0 && index < feedAssets.length) {
      appDispatch(setEditBaseAssetId({ assetId: feedAssets[index].id }));
    } else {
      appDispatch(setEditBaseAssetId({ assetId: undefined }));
    }
  };

  const handleOnCloseModal = () => {
    appDispatch(setEditBaseAssetId({ assetId: undefined }));
  };

  const { previewContext } = usePreview({
    contentItem: currentContentItemInPreview,
    handleReuseSettings: () => {
      if (editBaseAsset) {
        appDispatch(reuseSettingsFromAsset({ asset: editBaseAsset }));
      }
      appDispatch(setEditBaseAssetId({ assetId: undefined }));
    },
    handleSwitchToPrevContentItem: () => {
      const prevIndex = indexOfAssetInPreview;
      const index = prevIndex > 0 ? prevIndex - 1 : (feedAssets.length ?? 0) - 1;
      setCurrentAssetInPreviewByIndex(index);
    },
    handleSwitchToNextContentItem: () => {
      const prevIndex = indexOfAssetInPreview;
      const index = prevIndex < feedAssets.length - 1 ? prevIndex + 1 : feedAssets.length ? 0 : -1;
      setCurrentAssetInPreviewByIndex(index);
    },
  });

  return (
    <>
      <ImageModalContext.Provider value={previewContext}>
        <ImageModal
          currentItem={currentContentItemInPreview}
          isOpen={isOpen}
          currentTab={assetModalView === AssetModalView.EDIT ? 1 : 0}
          closeModal={closeModal}
          onCloseModal={handleOnCloseModal}
        />
      </ImageModalContext.Provider>
    </>
  );
});
AssetModalWrapper.displayName = 'AssetModalWrapper';

function convertFrontendAssetToContentItem({ asset }: { asset: FrontendAsset }): ContentItem {
  return {
    aspectRatio: asset.aspectRatio,
    assetType: asset.type,
    batchId: asset.batchId,
    contentHeadline: getAssetTextPrompt({ asset }),
    contentType: getFeedContentTypeByWorkflowId(asset.workflowId),
    // feedback: '', // TODO: get feed submitted
    imageId: asset.catwalkImageId,
    originalUri: asset.url,
    products: [], // TODO: get products data
    referenceId: asset.id,
    studioInputSettings: convertFrontendAssetUserInputsToStudioInputSettings({ asset }),
    timestamp: `${asset.timestamp}`,
    workflowId: asset.workflowId,
  };
}

function convertFrontendAssetUserInputsToStudioInputSettings({ asset }: { asset: FrontendAsset }): StudioInputSettings | undefined {
  if (!asset.userInputs) return undefined;
  return {
    asin: convertSelectedProductToAsinItem({ product: getAssetProduct({ asset }) }),
    effects: convertSelectedEffectsToSelectedEffectsMap({ effects: getAssetEffects({ asset }) }),
    enablePromptRewriting: false,
    format: getValidFormatForStudioInputSettings(asset.aspectRatio),
    prompt: getAssetTextPrompt({ asset }),
    style: getAssetStyle({ asset }),
    // multiProduct: '', // TODO: get multiProduct data
  };
}

function convertSelectedEffectsToSelectedEffectsMap({ effects }: { effects: SelectedEffects | undefined }): SelectedEffectsMap | undefined {
  if (!effects) return undefined;
  const effectsMap: SelectedEffectsMap = new Map();
  for (let effectType in effects) {
    effectsMap.set(effectType as EffectType, effects[effectType as EffectType] as EffectOption);
  }
  return effectsMap;
}

function convertSelectedProductToAsinItem({ product }: { product: SelectedProductFull | undefined }): ASINItem | undefined {
  if (!product) return undefined;

  switch (product.type) {
    case ProductType.ASIN: {
      return {
        asin: product.asin,
        metadata: product.metadata,
        customImage: undefined,
        customProductImageUrl: product.customProductImageUrl,
        selectedImageIndex: product.selectedImageIndex,
      };
    }
    case ProductType.CUSTOM_IMAGE: {
      return {
        customImage: product.customImage,
      } as ASINItem;
    }
  }

  return undefined;
}
