import { useCallback, useEffect, useState } from 'react';

// Based on @mui
export enum MUIBreakpoints {
  xs = 0,
  sm = 600,
  md = 900,
  lg = 1200,
  xl = 1536,
}

// Based on the Feed UI 2.0 Update
export enum Breakpoints {
  sm = 0,
  md = 988,
  lg = 1388,
  xl = 2008,
}

export function useBreakpoints(...breakpoints: (MUIBreakpoints | Breakpoints)[]) {
  const [matches, setMatches] = useState<boolean[]>([]);

  const checkBreakpoints = useCallback(() => {
    const width = window.innerWidth;
    const newMatches = breakpoints.map((point) => width >= point);

    if (newMatches.length !== matches.length) {
      return setMatches(newMatches);
    }

    for (let index in newMatches) {
      if (newMatches[index] !== matches[index]) {
        return setMatches(newMatches);
      }
    }
  }, [breakpoints]);

  useEffect(() => {
    checkBreakpoints(); // Initial check
    window.addEventListener('resize', checkBreakpoints);

    return () => {
      window.removeEventListener('resize', checkBreakpoints);
    };
  }, [checkBreakpoints]);

  return matches;
}
