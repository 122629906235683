import { Card, Link, Section, Text } from '@amzn/storm-ui';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { EditorContextP1 } from 'src/components/editor/EditorContext';
import ImageViewerOverlay from 'src/components/editor/ImageViewerOverlay';
import Stack from 'src/customUIComponents/Stack';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import { getAspectRatio } from 'src/v2/utils/ImageUtils';

const ImageViewer = (props: { ids: string[]; urls: string[]; children: ReactNode; textUrls: string[] }) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [selectedImage, setSelectedImage] = useState<string>();
  const [selectedText, setSelectedText] = useState<string>();
  const [loaded, setLoaded] = useState(false);
  const { ids, urls, children, textUrls } = props;
  const [computedAspectRatio, setComputedAspectRatio] = useState<string>('');
  const editorContext = useContext(EditorContextP1);
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = isDarkModeLaunched && useAppSelector(isDarkModeActive);

  useEffect(() => {
    if (urls.length > 0 && selectedImage === undefined) {
      setSelectedImage(urls[0]);
    }
  }, [urls]);

  useEffect(() => {
    if (textUrls && textUrls.length > 0) {
      setSelectedText(textUrls[0]);
    }
  }, [textUrls]);

  const handleLoad = () => {
    const width = imageRef.current?.naturalWidth;
    const height = imageRef.current?.naturalHeight;
    if (width && height) {
      const aspectRatio = getAspectRatio({ width, height });
      setComputedAspectRatio(aspectRatio);
    }
    setLoaded(true);
  };

  const handleClickEvent = (event: React.MouseEvent<HTMLImageElement>) => {
    setSelectedImage((event.target as HTMLImageElement).src);
  };

  const clickHandler = () => {
    const controlValues = editorContext.workflowOptions;
    const controlData: Record<string, unknown> = {};
    controlData['prompt'] = { value: selectedText };
    controlData['rewriteUserCustomPrompt'] = { value: 'false' };
    editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
  };

  useEffect(() => {
    const hasResults = urls && urls.length > 0;
    if (!hasResults) {
      return;
    }
    setSelectedImage(urls[0]);
  }, [urls]);

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '100%' }}>
        <Stack style={{ flexDirection: 'column', gap: '20px', flexWrap: 'nowrap', justifyContent: 'center', marginTop: '20px' }}>
          <Section style={{ background: 'transparent', padding: '0px' }}>
            <Card
              paddingBlockEnd="none"
              paddingBlockStart="none"
              paddingInlineEnd="none"
              paddingInlineStart="none"
              style={{
                padding: '0',
                maxHeight: '60vh',
                width: 'auto',
                position: 'relative',
                borderRadius: '10px',
              }}
            >
              {selectedImage ? (
                <>
                  <ImageViewerOverlay url={selectedImage} type="image" prompt={selectedText} computedAspectRatio={computedAspectRatio} />
                  <img
                    ref={imageRef}
                    data-testid={'sandbox-image-viewer'}
                    onLoad={handleLoad}
                    src={selectedImage}
                    style={{ maxHeight: '60vh', width: '100%', display: loaded ? 'block' : 'none', borderRadius: '10px' }}
                  />
                </>
              ) : (
                <></>
              )}
            </Card>
            {selectedText ? (
              <div style={{ maxWidth: '700px', width: 'auto', marginTop: '20px' }}>
                <Text style={{ color: '#232F3F', fontWeight: '400', fontStyle: 'italic' }} size={'base'}>
                  {selectedText}
                  <Link style={{ width: '130px', color: '#4305F4', textDecoration: 'none', cursor: 'pointer' }} onClick={clickHandler}>
                    {' '}
                    Edit this prompt
                  </Link>
                </Text>
              </div>
            ) : (
              <></>
            )}
          </Section>

          {urls && urls.length > 1 && (
            <Section className="results-thumbnails" style={{ background: 'transparent', padding: '0' }}>
              <Stack style={{ gap: '8px', width: 'auto', display: 'flex', flexDirection: 'row', margin: '0 auto' }}>
                {urls.map((item: string, index: number) => {
                  return (
                    <Card
                      key={'imageViewer' + index}
                      paddingBlockEnd="none"
                      paddingBlockStart="none"
                      paddingInlineEnd="none"
                      paddingInlineStart="none"
                      style={{
                        padding: '0',
                        overflow: 'hidden',
                        width: 'auto',
                        borderRadius: '8px',
                        border: `1px solid ${item === selectedImage ? (isDarkMode ? 'var(--interactive-primary)' : '#4305F4') : isDarkMode ? 'var(--border-medium)' : '#B8BFCB'}`,
                        boxShadow: '0px 2px 4px 0px #232F3F26',
                        cursor: 'pointer',
                        backgroundColor: 'var(--surface-tertiary, "unset")',
                      }}
                    >
                      <img
                        data-testid={'sandbox-image-thumbnail'}
                        id={(ids && ids[index]) || 'id' + index}
                        src={item}
                        style={{ display: 'block', padding: '0', objectFit: 'cover', borderRadius: '10px', margin: '6px' }}
                        onClick={(e: React.MouseEvent<HTMLImageElement>) => {
                          handleClickEvent(e);
                        }}
                        width="80"
                        height="80"
                      />
                    </Card>
                  );
                })}
              </Stack>
            </Section>
          )}
          {children}
        </Stack>
      </div>
    </>
  );
};

export default ImageViewer;
