import _merge from 'lodash/merge';
import { GenerateAssetsAction, GenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import {
  handleGenerativeResizingGeneration,
  handleImageEditorGeneration,
  handleImageThemingGeneration,
  handleLifestyleImageGeneration,
  handleLiveImageVideoGeneration,
  handleProductEditGeneration,
  handleProductImageGeneration,
  handleUploadedImageGeneration,
  handleMultipleProductImageGeneration,
} from 'src/v2/contexts/assetGeneration/utils';
import { prepareAssetGenerationActionResults } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { BackendAction } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { NoAssetGenerationConfigHandlerError } from 'src/v2/errors/CustomErrors';
import { addOneAssetGeneration, getAssetGenerationById, updateOneAssetGeneration } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice';
import { AssetGenerationStatus } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice.types';
import { AppStore } from 'src/v2/redux/store';
import { FrontendAsset, DeepPartial, WorkflowIdEnum } from 'src/v2/types';
import { v6 as uuidV6 } from 'uuid';

export function createBackendDispatchContextWrapper({
  backendDispatchContext,
  generationRequestId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationRequestId: string;
}) {
  return (...args: Parameters<BackendDispatchContextType>) => {
    const [backendAction, ...rest] = args;
    const backendActionOptionsOverride: DeepPartial<BackendAction['options']> = { requestGroupId: generationRequestId };
    backendAction.options = _merge({}, backendAction.options, backendActionOptionsOverride);
    return backendDispatchContext(backendAction, ...rest);
  };
}

export async function handleGenerateAssetsAction({
  action,
  appStore,
  backendDispatchContext,
  requestId,
}: {
  action: GenerateAssetsAction;
  appStore: AppStore;
  backendDispatchContext: BackendDispatchContextType;
  requestId?: string;
}) {
  const appDispatch = appStore.dispatch;
  const generationRequestId = requestId ?? uuidV6();

  // Set up a wrapper for the Backend Dispatch Context to automatically apply this generation's request ID
  // to all backend requests that are handled.
  const backendDispatchContextWrapper = createBackendDispatchContextWrapper({
    backendDispatchContext,
    generationRequestId,
  });

  // STEP - Update AppStore state with new generation and notify caller via onInit callback
  appDispatch(
    addOneAssetGeneration({
      id: generationRequestId,
      status: AssetGenerationStatus.PENDING,
      action,
    }),
  );
  action.onInit?.({ id: generationRequestId });

  // STEP - Generate Assets
  const generationResults = await Promise.allSettled(
    action.generationConfigs.map((generationConfig) =>
      handleGenerationConfig({
        backendDispatchContext: backendDispatchContextWrapper,
        generationConfig,
      }),
    ),
  );

  // STEP - Confirm asset generation wasn't aborted before proceeding with the result
  const assetGeneration = getAssetGenerationById(appStore.getState(), generationRequestId);
  if (assetGeneration?.status === AssetGenerationStatus.ABORTED) {
    console.warn(`Generate assets action with id '${generationRequestId}' was aborted.`);
    return;
  }

  // STEP - Aggregate generated assets and errors
  const { generatedAssets, errors } = prepareAssetGenerationActionResults({ generationResults });

  // STEP - Update AppStore state and notify caller via onComplete callback
  appDispatch(
    updateOneAssetGeneration({
      id: generationRequestId,
      changes: {
        status: AssetGenerationStatus.COMPLETE,
        generatedAssets,
        errors: errors.map((error) => error.message),
      },
    }),
  );
  action.onComplete?.({
    generatedAssets,
    errors,
  });
}

async function handleGenerationConfig({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: GenerationConfig;
}): Promise<{
  generatedAssets: FrontendAsset[];
  errors?: Error[];
}> {
  switch (generationConfig.workflowId) {
    case WorkflowIdEnum.APPAREL:
    case WorkflowIdEnum.TEXT_TO_IMAGE: {
      return await handleProductImageGeneration({
        backendDispatchContext,
        generationConfig,
      });
    }
    case WorkflowIdEnum.LIFESTYLE_IMAGERY: {
      return await handleLifestyleImageGeneration({
        backendDispatchContext,
        generationConfig,
      });
    }
    case WorkflowIdEnum.MULTI_PRODUCT: {
      return await handleMultipleProductImageGeneration({
        backendDispatchContext,
        generationConfig,
      });
    }
    case WorkflowIdEnum.GENERATIVE_RESIZING: {
      return await handleGenerativeResizingGeneration({
        backendDispatchContext,
        generationConfig,
      });
    }
    case WorkflowIdEnum.IMAGE_EDITOR: {
      return await handleImageEditorGeneration({
        backendDispatchContext,
        generationConfig,
      });
    }
    case WorkflowIdEnum.IMPORT_IMAGE: {
      return await handleUploadedImageGeneration({
        backendDispatchContext,
        generationConfig,
      });
    }
    case WorkflowIdEnum.IMAGE_THEMING: {
      return await handleImageThemingGeneration({
        backendDispatchContext,
        generationConfig,
      });
    }
    case WorkflowIdEnum.PARALLAX_MOTION: {
      return await handleLiveImageVideoGeneration({
        backendDispatchContext,
        generationConfig,
      });
    }
    case WorkflowIdEnum.PRODUCT_EDIT: {
      return await handleProductEditGeneration({
        backendDispatchContext,
        generationConfig,
      });
    }
    default:
      console.error("A asset generation handler is not available for the given config's workflow ID: ", generationConfig);
      throw new NoAssetGenerationConfigHandlerError();
  }
}
