import { AdvertisingAccountMetadata, Cards, GetUserPermissionsInput, UserDetails } from '@amzn/genaihub-typescript-client';
import { AlertProps } from '@amzn/storm-ui';
import { Theme } from '@mui/material/styles';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { ColorScheme } from 'src/components/common/types';
import { defaultTheme } from 'src/config/themes';
import { AlertTargets, US_MARKETPLACE_ID } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { useMetrics } from 'src/hooks/useMetrics';
import { AccountTypeEnum } from 'src/v2/types';

const AssetLibraryEditPermissionsLookup: { [key: string]: boolean } = {
  campaign_edit: true,
  advertiser_campaign_edit: true,
};

// Global Type
export type AccountType = 'internal' | 'external';

// Global Type
export type AlertEvent = {
  target?: AlertTargets;
  control?: {
    controlName: string;
    position: string;
  };
  type: AlertProps['variant'];
  header?: string;
  text?: string;
  link?: string;
  linkText?: string;
  code?: number;
  show?: boolean;
  resourceKey?: string;
  resourceArgs?: Record<string, unknown> | undefined;
};

export type AppContextOverride = {
  activePage?: string;
  openEditor?: boolean;
  headerDimensions?: { width: number; pageHeaderHeight: number; navHeaderHeight: number };
  footerDimensions?: { width?: number; height?: number };
  authenticated?: boolean;
  activeTheme?: Theme;
  tools?: Cards;
  selectedTool?: string;
  alertEvent?: AlertEvent;
  userDetails?: UserDetails;
  accountType?: AccountType;
  showSwitchAccountModal?: boolean;
  selectedAdvertisingAccount?: AdvertisingAccountMetadata | null;
  colorScheme?: ColorScheme;
};

declare global {
  // Duplicate from above
  type AccountType = 'internal' | 'external';

  type FileUploadEvent = {
    payload: File;
    controlName: string;
  };

  // Duplicate from above
  type AlertEvent = {
    target?: AlertTargets;
    control?: {
      controlName: string;
      position: string;
    };
    type: AlertProps['variant'];
    header?: string;
    text?: string;
    link?: string;
    linkText?: string;
    code?: number;
    show?: boolean;
    resourceKey?: string;
    resourceArgs?: Record<string, unknown> | undefined;
  };
}

export const AppContext = createContext({
  activePage: 'home',
} as AppContextState);

export const useAppContext = (initial?: AppContextOverride) => {
  const [activePage, setActivePage] = useState<string>(initial?.activePage ?? 'home');
  const [openEditor, setOpenEditor] = useState<boolean>(initial?.openEditor ?? false);
  const [headerDimensions, setHeaderDimensions] = useState<{ width: number; pageHeaderHeight: number; navHeaderHeight: number }>(
    initial?.headerDimensions ?? {
      width: 0,
      pageHeaderHeight: 0,
      navHeaderHeight: 0,
    },
  );
  const [footerDimensions, setFooterDimensions] = useState<{ width?: number; height?: number }>(initial?.footerDimensions ?? {});
  const [authenticated, setAuthenticated] = useState<boolean>(initial?.authenticated ?? false);
  const [activeTheme, setActiveTheme] = useState<Theme>(initial?.activeTheme ?? defaultTheme);
  const [colorScheme, setColorScheme] = useState<ColorScheme>(initial?.colorScheme ?? 'primary');
  const [tools, setTools] = useState<Cards>(initial?.tools ?? []);
  const [selectedTool, setSelectedTool] = useState<string>(initial?.selectedTool ?? '');
  const [alertEvent, setAlertEvent] = useState<AlertEvent | undefined>(initial?.alertEvent);
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>(initial?.userDetails);
  const [accountType, setAccountType] = useState<AccountType | undefined>(initial?.accountType);
  const [showSwitchAccountModal, setShowSwitchAccountModal] = useState(initial?.showSwitchAccountModal ?? false);
  const [selectedAdvertisingAccount, setSelectedAdvertisingAccount] = useState<AdvertisingAccountMetadata | null>(
    initial?.selectedAdvertisingAccount ?? null,
  );
  const [isManagerAccount, setIsManagerAccount] = useState(
    checkIsManagerAccount({
      accountType: initial?.accountType,
      entityId: initial?.selectedAdvertisingAccount?.alternateIds?.[0],
    }),
  );
  const [hasWritePermissionToAssetLibrary, setHasWritePermissionToAssetLibrary] = useState(false);
  const metrics = useMetrics({
    pageName: `${activePage}${openEditor ? '/editor' : ''}`,
    userDetails: userDetails,
    toolname: openEditor && selectedTool ? selectedTool : undefined,
    accountType: accountType,
    selectedAccount: selectedAdvertisingAccount,
  });
  const backendClient = useAIBackendHubClient(metrics);

  useErrorHandler(metrics);

  useEffect(() => {
    setIsManagerAccount(
      checkIsManagerAccount({
        accountType,
        entityId: selectedAdvertisingAccount?.alternateIds?.[0],
      }),
    );
  }, [accountType, selectedAdvertisingAccount]);

  const refreshUserPermissions = async (account: AdvertisingAccountMetadata | null) => {
    // Must use the ACI formatted adsAccountId for permission checks to direct Minos towards the correct source of the account.
    const entityId = account?.adsAccountId;
    const marketplaceId = US_MARKETPLACE_ID;
    if (!entityId) {
      console.info('Entity ID not available, defaulting to read-only permissions for Asset Library');
      setHasWritePermissionToAssetLibrary(false);
      return;
    }

    try {
      const payload: GetUserPermissionsInput = { entityId, marketplaceId };
      const result = await backendClient.getUserPermissions(payload);
      if (result.body.isAuthorized) {
        // Search permissions to determine if it's an acceptable edit permission to grant asset library access
        const requiredPermission = result.body.permissions?.find((p) => AssetLibraryEditPermissionsLookup[p]);
        console.info('User authorized to use Asset Library?', !!requiredPermission);
        setHasWritePermissionToAssetLibrary(!!requiredPermission);
      } else {
        console.info("User isn't authorized to use Entity.");
        setHasWritePermissionToAssetLibrary(false);
      }
    } catch (err) {
      console.error('Error while fetching user permissions', err);
      setHasWritePermissionToAssetLibrary(false);
    }
  };

  const appContextState = {
    activePage,
    setActivePage,
    openEditor,
    setOpenEditor,
    headerDimensions,
    setHeaderDimensions,
    footerDimensions,
    setFooterDimensions,
    authenticated,
    setAuthenticated,
    activeTheme,
    setActiveTheme,
    colorScheme,
    setColorScheme,
    tools,
    setTools,
    selectedTool,
    setSelectedTool,
    showLandingPage: true,
    // The function arguments must be defined to ensure correct typing is generated for AppContextState
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setShowLandingPage: (showLandingPage: boolean) => {},
    alertEvent,
    setAlertEvent,
    userDetails,
    setUserDetails,
    metrics,
    accountType,
    setAccountType,
    showSwitchAccountModal,
    setShowSwitchAccountModal,
    selectedAdvertisingAccount,
    setSelectedAdvertisingAccount: (account: AdvertisingAccountMetadata | null) => {
      refreshUserPermissions(account);
      setSelectedAdvertisingAccount(account);
    },
    hasWritePermissionToAssetLibrary,
    isManagerAccount,
    getAdsAccountId: useCallback(() => {
      return selectedAdvertisingAccount?.adsAccountId;
    }, [selectedAdvertisingAccount]),
    getEntityId: useCallback(() => {
      return selectedAdvertisingAccount?.alternateIds?.[0] ?? '';
    }, [selectedAdvertisingAccount]),
  };

  return appContextState;
};

export type AppContextState = ReturnType<typeof useAppContext>;

export const AppContextProvider = ({ context, children }: { context?: AppContextOverride; children: React.ReactNode }) => {
  const appContextState = useAppContext(context);
  return <AppContext.Provider value={appContextState}>{children}</AppContext.Provider>;
};

export function checkIsManagerAccount({ accountType, entityId }: { accountType: AccountType | undefined; entityId: string | undefined }): boolean {
  return accountType === AccountTypeEnum.EXTERNAL && !entityId;
}
