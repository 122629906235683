import { ControlValue } from '@amzn/genaihub-typescript-client';
import { TextAreaFormGroup } from '@amzn/storm-ui';
import { useContext, useEffect, useState } from 'react';
import { EditorContextStateP1 } from 'src/components/editor/EditorContext';
import { EditorContextP1 } from 'src/components/editor/EditorContext';
import { ControlComponentProps } from 'src/components/editor/UiContols/uiGeneratorControls/controlComponentTypes';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import guideHelper from 'src/components/utils/guideHelper';
import { TaskType } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { WorkflowIdEnum } from 'src/v2/types';
import cssStyles from './uiform.module.scss';

const TextPromptBox = (props: ControlComponentProps) => {
  const { control, bulkUpdateCallback, workflowId } = props;
  const editorContext: EditorContextStateP1 = useContext(EditorContextP1);
  const controlValues = editorContext.workflowOptions;
  const controlName = control.controlName;
  const [disabled, setDisabled] = useState<boolean>(true);

  const [opacity, setOpacity] = useState<number>(1);

  useEffect(() => {
    if (editorContext.disableControl) {
      setDisabled(true);
      setOpacity(0.5);
    } else {
      setOpacity(1);
      setDisabled(false);
    }
  }, [editorContext.disableControl]);

  useEffect(() => {
    updateControlValues(editorContext.promptUpdateEvent);
  }, [editorContext.promptUpdateEvent]);

  useEffect(() => {
    const wfo = editorContext.workflowOptions;
    if (wfo.task_type) {
      if (wfo.task_type.value === TaskType.REMOVAL) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [editorContext.workflowOptions]);

  useEffect(() => {
    guideHelper(control.controlValues, editorContext.activateGuide, guideCallback);
  }, [editorContext.activateGuide]);

  const guideCallback = (guide: ControlValue | undefined) => {
    if (guide) {
      const controlData = { [control.controlName]: { value: guide.guideCustomValue } };

      batchStateUpdate(controlData, bulkUpdateCallback);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateControlValues(e.target.value);
  };

  const updateControlValues = (value: string | undefined) => {
    if (value !== undefined) {
      if (value === '') {
        const controlValuesCopy = { ...controlValues };
        delete controlValuesCopy[controlName];
        editorContext.setWorkFlowOptions({ ...controlValuesCopy });
      } else {
        const controlData = { [controlName]: { value: value } };
        editorContext.setWorkFlowOptions({ ...controlValues, ...controlData });
      }
    }
  };
  return (
    <>
      <ControlLabel title={control.controlLabel} />
      <TextAreaFormGroup
        fullWidth
        value={controlValues[controlName]?.value}
        onChange={handleChange}
        id="textarea-basic"
        label=""
        hideLabel
        placeholder={
          workflowId === WorkflowIdEnum.LIFESTYLE_IMAGERY
            ? "Describe the changes you'd like to make."
            : "Describe the changes you'd like to make to your image."
        }
        style={{
          color: 'var(--text-primary, #000000)',
          background: 'var(--surface-tertiary, #f1f3f5)',
          minHeight: '80px',
          opacity: opacity,
          border: 'none',
        }}
        disabled={disabled}
        className={cssStyles.inputFieldStyle}
      />
    </>
  );
};

export default TextPromptBox;
