import { CSSProperties, HTMLAttributes } from 'react';
import { Placeholder } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderType, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';
import { FeedTestIds } from 'src/v2/components/studio/feed/Feed.constants';
import styles from 'src/v2/components/studio/feed/Feed.module.scss';
import { getNormalizedClassNames } from 'src/v2/utils/utils';

interface FeedEmptyPlaceholdersGroupItemProps extends HTMLAttributes<HTMLDivElement> {
  aspectRatio: string;
}

export const FeedEmptyPlaceholdersGroupItem = (props: FeedEmptyPlaceholdersGroupItemProps) => {
  const { aspectRatio, style, ...restProps } = props;
  const rootClassNames = getNormalizedClassNames([styles.feedItemContainer, styles.feedEmptyPlaceholder]);

  const placeholderClassNames = getNormalizedClassNames([styles.placeholder]);

  const mergedStyle: CSSProperties = {
    ...style,
    aspectRatio: aspectRatio.replace(':', ' / '),
  };

  return (
    <div
      className={rootClassNames}
      data-testid={FeedTestIds.feedEmptyPlaceholder.rootContainer}
      data-alt-testid={FeedTestIds.feedEmptyPlaceholder.rootContainerAlt}
      style={mergedStyle}
      {...restProps}
    >
      <Placeholder
        className={placeholderClassNames}
        data-testid={FeedTestIds.feedEmptyPlaceholder.placeholderComponent}
        type={PlaceholderType.EMPTY}
        variant={PlaceholderVariant.DOTTED}
      />
    </div>
  );
};
