import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AssetGenerationStatus } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice.types';
import { EditModeSettings, EditSliceState } from 'src/v2/redux/slices/edit/editSlice.types';
import { getInitialState } from 'src/v2/redux/slices/edit/editSlice.util';
import type { RootState } from 'src/v2/redux/store';
import { DeepPartial, EditMode } from 'src/v2/types';

type EditModeSettingsPartial = DeepPartial<EditModeSettings> & { editMode: EditMode };

export const editSlice = createSlice({
  name: 'edit',
  initialState: getInitialState(),
  reducers: (create) => ({
    resetEditSliceState: () => getInitialState(),
    setAssetModalView: create.reducer<EditSliceState['assetModalView']>((state, action) => {
      state.assetModalView = action.payload;
    }),
    setEditAssetGenerationId: create.reducer<EditSliceState['assetGenerationId']>((state, action) => {
      state.assetGenerationId = action.payload;
    }),
    setEditAssetGenerationSelectedAssetIndex: create.reducer<EditSliceState['assetGenerationSelectedAssetIndex']>((state, action) => {
      state.assetGenerationSelectedAssetIndex = action.payload;
    }),
    setEditBaseAssetId: create.reducer<{ assetId: EditSliceState['baseAssetId']; assetModalView?: EditSliceState['assetModalView'] }>(
      (state, action) => {
        state.baseAssetId = action.payload.assetId;
        if (action.payload.assetModalView) state.assetModalView = action.payload.assetModalView;
      },
    ),
    setEditMode: create.reducer<EditSliceState['editMode']>((state, action) => {
      state.editMode = action.payload;
    }),
    /**
     * Shallow copies the given EditModeSettings properties into the existing EditModeSettings properties for the given EditMode
     */
    setEditModeSettings: create.reducer<EditModeSettingsPartial>((state, action) => {
      // NOTE: TS has an issue with, "The intersection was reduced to 'never' because property 'editMode' has conflicting types in some constituents.", due to the strong typing of 'editMode' key and it's respective settings in the map, and each EditModeSettings not having much overlap.
      // AFAIK the only way to resolve this is to check the edit mode before assigning it, but that defeats the purpose of the simple assigment as-is.
      const currentEditModeSettings = state.settingsByEditMode[action.payload.editMode];
      // @ts-expect-error
      state.settingsByEditMode[action.payload.editMode] = { ...currentEditModeSettings, ...action.payload };
    }),
    setPublishToAssetLibraryBackendRequestId: create.reducer<EditSliceState['publishToAssetLibraryBackendRequestId']>((state, action) => {
      state.publishToAssetLibraryBackendRequestId = action.payload;
    }),
    setPublishToFeedBackendRequestId: create.reducer<EditSliceState['publishToFeedBackendRequestId']>((state, action) => {
      state.publishToFeedBackendRequestId = action.payload;
    }),
  }),
  selectors: {
    getAssetModalView: (editState: EditSliceState) => editState.assetModalView,
    getEditAssetGenerationId: (editState: EditSliceState) => editState.assetGenerationId,
    getEditAssetGenerationSelectedAssetIndex: (editState: EditSliceState) => editState.assetGenerationSelectedAssetIndex,
    getEditBaseAssetId: (editState: EditSliceState) => editState.baseAssetId,
    getEditMode: (editState: EditSliceState) => editState.editMode,
    getPublishToAssetLibraryBackendRequestId: (editState: EditSliceState) => editState.publishToAssetLibraryBackendRequestId,
    getPublishToFeedBackendRequestId: (editState: EditSliceState) => editState.publishToFeedBackendRequestId,
  },
});
export default editSlice.reducer;

/** ACTIONS */
export const {
  resetEditSliceState,
  setAssetModalView,
  setEditAssetGenerationId,
  setEditAssetGenerationSelectedAssetIndex,
  setEditBaseAssetId,
  setEditMode,
  setEditModeSettings,
  setPublishToAssetLibraryBackendRequestId,
  setPublishToFeedBackendRequestId,
} = editSlice.actions;

/** SELECTORS */
export const {
  getAssetModalView,
  getEditAssetGenerationId,
  getEditAssetGenerationSelectedAssetIndex,
  getEditBaseAssetId,
  getEditMode,
  getPublishToAssetLibraryBackendRequestId,
  getPublishToFeedBackendRequestId,
} = editSlice.selectors;

/**
 * Get the asset generation
 */
export const getEditAssetGeneration = createSelector(
  [(state: RootState) => state.assetGenerations.entities, (state: RootState) => state.edit.assetGenerationId],
  (assetGenerations, assetGenerationId) => {
    return assetGenerationId ? assetGenerations[assetGenerationId] : undefined;
  },
);

/**
 * Gets a flag indicating the generation is pending or not
 */
export const isEditAssetGenerationPending = createSelector(
  [(state: RootState) => getEditAssetGeneration(state)],
  (assetGeneration) => assetGeneration?.status === AssetGenerationStatus.PENDING,
);

/**
 * Gets a flag indicating the generation was aborted or not
 */
export const isEditAssetGenerationAborted = createSelector(
  [(state: RootState) => getEditAssetGeneration(state)],
  (assetGeneration) => assetGeneration?.status === AssetGenerationStatus.ABORTED,
);

/**
 * Get the result (generated assets & errors) from a completed asest generation
 */
export const getEditAssetGenerationResult = createSelector([(state: RootState) => getEditAssetGeneration(state)], (assetGeneration) => {
  if (!assetGeneration) return undefined;
  if (assetGeneration.status !== AssetGenerationStatus.COMPLETE) return undefined;
  return {
    generatedAssets: assetGeneration.generatedAssets,
    errors: assetGeneration.errors,
  };
});

/**
 * Get the currently selected asset from the generation's assets
 */
export const getEditAssetGenerationSelectedAsset = createSelector(
  [(state: RootState) => getEditAssetGenerationResult(state), (state: RootState) => state.edit.assetGenerationSelectedAssetIndex],
  (assetGenerationResult, assetGenerationSelectedAssetIndex) => {
    return assetGenerationResult?.generatedAssets?.[assetGenerationSelectedAssetIndex];
  },
);

/**
 * Get the base asset for the edit
 */
export const getEditBaseAsset = createSelector(
  [(state: RootState) => state.feed.assets.entities, (state: RootState) => state.edit.baseAssetId],
  (assets, baseAssetId) => {
    return baseAssetId ? assets[baseAssetId] : undefined;
  },
);

/**
 * Get the EditModeSettings for the EditMode currently set in the Edit Slice
 */
export const getCurrentEditModeSettings = createSelector(
  [(state: RootState) => state.edit.settingsByEditMode, (state: RootState) => state.edit.editMode],
  (settingsByEditMode, editMode) => {
    return settingsByEditMode[editMode];
  },
);

/**
 * Get the EditModeSettings for a given EditMode
 */
export const getSettingsByEditMode = createSelector(
  [(state: RootState) => state.edit.settingsByEditMode, (state: RootState, editMode: EditMode) => editMode],
  (settingsByEditMode, editMode) => {
    return settingsByEditMode[editMode];
  },
);
