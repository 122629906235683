import { Flex, Text } from '@amzn/storm-ui';
import { ChangeEventHandler, useRef, useState } from 'react';
import { Button } from 'src/v2/components/Button/Button';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { SelectedProductFull } from 'src/v2/redux/slices/userInput/userInputSlice.types';
import { AcceptedFileTypesForStudioImageUpload, filterStudioImageUploadFiles } from 'src/v2/utils/ImageUtils';
import styled from 'styled-components';
import { getAllImagesFromFullProduct, getImageFromProduct } from './productSelectorHelpers';
import { ButtonTypes } from '../../Button/types';
import { SelectionItem, SelectionItemProps } from '../../SelectionList/SelectionItem';
import { Thumbnail, ThumbnailContainer } from '../../Thumbnail/Thumbnail';
import { IconPositions, ThumbnailType } from '../../Thumbnail/types';

const ImageSelection = styled.div<{ selected?: boolean }>`
  ${ThumbnailContainer} {
    background-color: #f5f3ef;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid ${(props) => (props.selected ? 'var(--Text-Base, #232f3f)' : ' var(--Studio-Sytem-Gray-Gray-200, #E7E6E4)')};
    box-sizing: border-box;
    cursor: pointer;
  }
`;
const SelectionContainer = styled(Flex)<{
  $display: string;
}>`
  max-width: 100%;
  display: ${(props) => props.$display || 'flex'};
`;
const FileInput = styled.input`
  display: none;
`;

export type ProductDisplayProps = SelectionItemProps & {
  product: SelectedProductFull;
  onSave: (product: SelectedProductFull) => void;
  onUpload: (options: { file: File; filtered: boolean }) => void;
};

export const ProductDisplay = ({ product, onSave, onUpload, ...others }: ProductDisplayProps) => {
  const images = getAllImagesFromFullProduct(product);
  const thumbnail = others.thumbnail || getImageFromProduct(product);
  const [showImages, setShowImages] = useState<boolean>(false);
  const [selectedImageIndex, selectImageByIndex] = useState<number>(images.findIndex((src) => src === thumbnail) || 0);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleFileInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files?.length) {
      const files = Array.from(event.target.files);
      const result = filterStudioImageUploadFiles({ files });
      onUpload?.({ file: result.files?.[0], filtered: result.filtered });
      setShowImages(false);
    }
  };
  return (
    <Flex direction="column" rowGap="base">
      <SelectionItem
        {...others}
        thumbnailType={product.type === ProductType.ASIN ? ThumbnailType.Edit : undefined}
        thumbnail={thumbnail}
        thumbnailIconOptions={{
          iconPosition: IconPositions.BottomRight,
          iconType: 'pencil',
          iconSize: '12px',
        }}
        onClickThumbnail={(e) => {
          e.stopPropagation();
          setShowImages((show) => !show);
        }}
      />

      <SelectionContainer $display={showImages ? 'flex' : 'none'} direction="column" rowGap="small">
        <Text>Choose an image that shows your product against a white background.</Text>
        <Flex wrap="wrap" columnGap="xxsmall" rowGap="xxsmall">
          {images.map((image, index) => (
            <ImageSelection
              selected={selectedImageIndex === index}
              key={index}
              role="radio"
              aria-checked={selectedImageIndex === index}
              onClick={() => {
                selectImageByIndex(index);
              }}
            >
              <Thumbnail
                thumbnailOptions={{
                  width: '75px',
                  height: '75px',
                }}
                src={image}
                alt={`thumbnail image - ${index}`}
              />
            </ImageSelection>
          ))}
        </Flex>
        <Flex columnGap="xxsmall">
          <FileInput
            onChange={handleFileInputChange}
            type="file"
            ref={inputRef}
            data-testid={'upload-input'}
            aria-hidden
            accept={AcceptedFileTypesForStudioImageUpload.join(',')}
          />
          <Button
            label="Upload custom image"
            type={ButtonTypes.Secondary}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.value = '';
                inputRef.current.click();
              }
            }}
          />
          <Button
            label="Save"
            type={ButtonTypes.Primary}
            onClick={() => {
              setShowImages(false);
              if (product.type === ProductType.ASIN)
                onSave({
                  ...product,
                  options: {
                    asinProduct: {
                      ...product.options?.asinProduct,
                      selectedImageIndex: selectedImageIndex,
                    },
                  },
                });
            }}
          />
        </Flex>
      </SelectionContainer>
    </Flex>
  );
};
