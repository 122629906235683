import { SyntheticEvent } from 'react';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import { isWeblabStateManagementInTreatment } from 'src/util/weblab/config';
import { useFeed } from 'src/v2/hooks/useFeed/useFeed';
import { useFeedImages } from 'src/v2/hooks/useFeedImages/useFeedImages';
import { SimpleAsset } from 'src/v2/types';
import { ImageAssetGrid } from './ImageAssetGrid';
import { ImageGrid } from './ImageGrid';

export interface MultiSelectionGridProps {
  disabled: (url: string | undefined) => boolean;
  onAssetSelect: (e: SyntheticEvent, asset: SimpleAsset) => void;
  onContentItemSelect: (e: SyntheticEvent, asset: ContentItem) => void;
  setCheck: (url: string | undefined) => boolean;
  testIdPrefixForAsset: string;
  componentLabelForContentItem: string;
}

export const MultiSelectionGrid = ({
  disabled,
  onAssetSelect,
  onContentItemSelect,
  setCheck,
  testIdPrefixForAsset,
  componentLabelForContentItem,
}: MultiSelectionGridProps) => {
  const { assetsNextToken, feedImages, getAssets, isFeedsCallRunning } = useFeedImages();
  const { feedAssets, feedFetchPlaceholderCount, fetchAssets } = useFeed();
  const isStateManagementEnabled = isWeblabStateManagementInTreatment();

  const handleScroll = (event: React.SyntheticEvent) => {
    const { currentTarget } = event;
    const scrollTotalHeight = currentTarget.scrollHeight;
    const scrollDistance = currentTarget.scrollTop + currentTarget.clientHeight;
    const loadResultsTriggerPoint = scrollTotalHeight - 200;

    if (scrollDistance > loadResultsTriggerPoint) {
      if (isStateManagementEnabled) {
        fetchAssets();
      } else {
        if (!isFeedsCallRunning) getAssets(assetsNextToken);
      }
    }
  };

  return isStateManagementEnabled ? (
    <ImageAssetGrid
      assets={feedAssets}
      placeholderCount={feedFetchPlaceholderCount}
      onSelectAsset={onAssetSelect}
      onScroll={handleScroll}
      testIdPrefix={testIdPrefixForAsset}
      multiSelection={{
        disabled: disabled,
        setCheck: setCheck,
        turnMaxWidthOff: true,
      }}
    />
  ) : (
    <ImageGrid
      scrollHandler={handleScroll}
      componentLabel={componentLabelForContentItem}
      images={feedImages || []}
      onFeedSelect={onContentItemSelect}
      multiSelection={{
        disabled: disabled,
        setCheck: setCheck,
        turnMaxWidthOff: true,
      }}
    />
  );
};
