import { AbortGenerationAction } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { BackendActionType } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getAssetGenerationById, updateOneAssetGeneration } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice';
import { AssetGenerationStatus } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice.types';
import { AppStore } from 'src/v2/redux/store';

export function handleAbortGenerationAction({
  action,
  appStore,
  backendDispatchContext,
}: {
  action: AbortGenerationAction;
  appStore: AppStore;
  backendDispatchContext: BackendDispatchContextType;
}) {
  const { generationRequestId } = action;
  const appDispatch = appStore.dispatch;
  const assetGeneration = getAssetGenerationById(appStore.getState(), generationRequestId);
  if (!assetGeneration) {
    console.warn(`Asset generation with id '${generationRequestId}' does not exist.`);
    return;
  }

  // STEP - abort all related requests in the backend
  backendDispatchContext({
    type: BackendActionType.ABORT_BY_GROUP_ID,
    payload: {
      groupId: generationRequestId,
    },
  });

  // STEP - update AppStore with new status
  appDispatch(
    updateOneAssetGeneration({
      id: generationRequestId,
      changes: {
        status: AssetGenerationStatus.ABORTED,
      },
    }),
  );

  // STEP - notify original caller of abort
  // TODO: clean this up after getting Tyler's fix to store callbacks in the AssetGenerationContext.
  if ('onAbort' in assetGeneration.action && typeof assetGeneration.action.onAbort === 'function') {
    assetGeneration.action.onAbort?.();
  }
}
