import { MouseEvent } from 'react';
import { IconProps } from 'src/v2/components/Icon/types';

export enum ThumbnailType {
  Basic = 'Basic',
  Select = 'Select',
  Edit = 'Edit',
  Play = 'Play',
  Remove = 'Remove',
  Count = 'Count',
  Blank = 'Blank',
}

export enum IconPositions {
  Center = 'center',
  TopRight = 'topRight',
  TopLeft = 'topLeft',
  BottomRight = 'bottomRight',
  BottomLeft = 'bottomLeft',
  OverlapTopRight = 'overlapTopRight',
}

export enum ThumbnailSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Xlarge = 'xlarge',
}

export interface ThumbnailOptions {
  size?: ThumbnailSize;
  width?: string;
  height?: string;
}

export interface ThumbnailProps {
  thumbnailType?: ThumbnailType;
  src?: string;
  alt: string;
  count?: number;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  isSelected?: boolean;
  onSelectedStateChange?: (isSelected: boolean) => void;
  thumbnailOptions?: ThumbnailOptions;
  overlayIconOptions?: {
    iconPosition?: IconPositions;
    iconType?: IconProps['type'];
    iconSize?: string;
  };
}

export const THUMBNAIL_SIZES = {
  [ThumbnailSize.Small]: {
    width: '44px',
    height: '44px',
  },
  [ThumbnailSize.Medium]: {
    width: '60px',
    height: '60px',
  },
  [ThumbnailSize.Large]: {
    width: '75px',
    height: '75px',
  },
  [ThumbnailSize.Xlarge]: {
    width: '85px',
    height: '85px',
  },
};
