import { Slider as StormSlider } from '@amzn/storm-ui-range-slider';
import { SliderProps } from 'src/v2/components/slider/Slider.types';
import styled from 'styled-components';

export const SliderStyled = styled(StormSlider)<SliderProps>`
  && .rc-slider .rc-slider-rail {
    box-shadow: none;
    background-color: ${({ darkMode }) => (darkMode ? 'var(--slider-dark-rail-background, #454A4F)' : 'var(--slider-rail-background, #F5F3EF)')};
  }

  && .rc-slider .rc-slider-track {
    background-color: ${({ darkMode }) => (darkMode ? 'var(--slider-dark-track-background, #B38AFF)' : 'var(--slider-track-background, #4305F4)')};
  }

  && .rc-slider .rc-slider-handle {
    background-color: ${({ darkMode }) => (darkMode ? 'var(--slider-dark-handle-background, #B38AFF)' : 'var(--slider-handle-background, #4305F4)')};
  }

  && .rc-slider .rc-slider-handle:active,
  && .rc-slider .rc-slider-handle:focus {
    outline-color: ${({ darkMode }) => (darkMode ? 'var(--slider-dark-handle-outline, #B38AFF)' : 'var(--slider-handle-outline, #4305F4)')};
  }
`;
