import { RadioGroupProps } from 'src/components/imageModal/components/inputs/RadioGroup';
import { TileSelectorProps } from 'src/components/imageModal/components/inputs/TileSelector';
import { HEADING_TEXT_SIZE } from 'src/constants';
import { CameraPath } from 'src/v2/types';
import { RangeSliderProps } from '../inputs/RangeSlider';

export const LIVE_IMAGE_HEADER = {
  controlLabel: 'Image to video',
  description:
    'To animate your image, use the drawing tools to identify the primary subject of the image. Customize the video duration, motion strength, and camera path for the direction of motion.',
  controlName: 'Image mask',
};

export const LIVE_IMAGE_EDITOR_CONFIG = {
  ...LIVE_IMAGE_HEADER,
  controlName: 'Image mask',
  defaultValue: '1',
  controlValues: [{}],
  titleSize: HEADING_TEXT_SIZE.SMALL,
};

export const LIVE_IMAGE_DURATION_CONFIG: RadioGroupProps = {
  title: 'Video duration',
  defaultValue: 4,
  options: [
    {
      label: '4 seconds',
      value: 4,
    },
    {
      label: '7 seconds',
      value: 7,
    },
  ],
};

export const LIVE_IMAGE_MOTION_CONFIG: RangeSliderProps = {
  title: 'Motion strength',
  min: 1.0,
  minLabel: 'Less motion',
  max: 1.6,
  maxLabel: 'More motion',
  step: 0.1,
  defaultValue: 1.3,
};

export const LIVE_IMAGE_CAMERA_PATH_CONFIG: TileSelectorProps = {
  title: 'Camera path',
  defaultValue: CameraPath.ZOOM_IN,
  options: [
    {
      label: 'Arc shot',
      value: CameraPath.ARC_SHOT,
      thumbnail: 'https://d3buw223hwu7zl.cloudfront.net/static-ui-assets/camera-path%20thumbnails/ArcShot.svg',
    },
    {
      label: 'Right left',
      value: CameraPath.RIGHT_LEFT,
      thumbnail: 'https://d3buw223hwu7zl.cloudfront.net/static-ui-assets/camera-path%20thumbnails/RightLeft.svg',
    },
    {
      label: 'Down up',
      value: CameraPath.DOWN_UP,
      thumbnail: 'https://d3buw223hwu7zl.cloudfront.net/static-ui-assets/camera-path%20thumbnails/DownUp.svg',
    },
    {
      label: 'Zoom in',
      value: CameraPath.ZOOM_IN,
      thumbnail: 'https://d3buw223hwu7zl.cloudfront.net/static-ui-assets/camera-path%20thumbnails/ZoomIn.svg',
    },
    {
      label: 'Zoom out',
      value: CameraPath.ZOOM_OUT,
      thumbnail: 'https://d3buw223hwu7zl.cloudfront.net/static-ui-assets/camera-path%20thumbnails/ZoomOut.svg',
    },
    {
      label: 'Tilt down',
      value: CameraPath.TILT_DOWN,
      thumbnail: 'https://d3buw223hwu7zl.cloudfront.net/static-ui-assets/camera-path%20thumbnails/TiltDown.svg',
    },
    {
      label: 'Rotate zoom',
      value: CameraPath.ROTATE_ZOOM_IN,
      thumbnail: 'https://d3buw223hwu7zl.cloudfront.net/static-ui-assets/camera-path%20thumbnails/RotateZoom.svg',
    },
    {
      label: 'Dolly zoom',
      value: CameraPath.DOLLY_ZOOM_IN,
      thumbnail: 'https://d3buw223hwu7zl.cloudfront.net/static-ui-assets/camera-path%20thumbnails/DollyZoom.svg',
    },
    {
      label: 'Tilt zoom',
      value: CameraPath.TILT_DOWN_ZOOM_IN,
      thumbnail: 'https://d3buw223hwu7zl.cloudfront.net/static-ui-assets/camera-path%20thumbnails/TiltZoom.svg',
    },
  ],
};
