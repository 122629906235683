import Genaihubbackend, { UserDetailsInput, UserDetailsOutput } from '@amzn/genaihub-typescript-client';
import {
  BackendActionBase,
  BackendActionQueryResultBase,
  BackendActionType,
  BackendQueryActionOptions,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type GetUserDetailsAction = BackendActionBase<
  BackendActionType.GET_USER_DETAILS,
  UserDetailsInput,
  UserDetailsOutput,
  BackendQueryActionOptions
>;

export type GetUserDetailsActionQueryResult = BackendActionQueryResultBase<BackendActionType.GET_USER_DETAILS, UserDetailsOutput>;

interface GetUserDetailsQueryFnProps {
  id: Request['id'];
  action: GetUserDetailsAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const getUserDetailsQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: GetUserDetailsQueryFnProps): Promise<GetUserDetailsActionQueryResult> => {
  const type = BackendActionType.GET_USER_DETAILS;
  const response = await backendClient.getUserDetails(action.payload);
  const request = getRequest({ id, requestsLookup });

  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }

  return { type, response, requestStatus: RequestStatus.SUCCESS };
};

interface HandleGetUserDetailsActionProps {
  action: GetUserDetailsAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}

export const handleGetUserDetailsAction = ({ action, backendClient, requestsLookup, setQueries }: HandleGetUserDetailsActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.GET_USER_DETAILS,
    getQueryResult: ({ id }) => {
      return getUserDetailsQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
