import Genaihubbackend, {
  RetrieveResultByWorkflowIdAndBatchIdInput,
  RetrieveResultByWorkflowIdAndBatchIdOutput,
} from '@amzn/genaihub-typescript-client';
import {
  BackendActionType,
  BackendActionBase,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
  BackendActionQueryResultBase,
  BackendQueryActionOptions,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';
import { getJob, isJobFailed, isJobInProgress, isJobCompleted, jobContainsResults } from 'src/v2/contexts/backend/utils/BatchResult.utils';
import { SubmitWorkflowError } from 'src/v2/errors/CustomErrors';

export type RetrieveResultByWorkflowIdAndBatchIdAction = BackendActionBase<
  BackendActionType.RETRIEVE_RESULT_BY_WORKFLOW_ID_AND_BATCH_ID,
  RetrieveResultByWorkflowIdAndBatchIdInput,
  RetrieveResultByWorkflowIdAndBatchIdOutput['body'],
  BackendQueryActionOptions
>;

export type RetrieveResultByWorkflowIdAndBatchIdActionQueryResult = BackendActionQueryResultBase<
  BackendActionType.RETRIEVE_RESULT_BY_WORKFLOW_ID_AND_BATCH_ID,
  RetrieveResultByWorkflowIdAndBatchIdOutput['body']
>;

interface RetrieveResultByWorkflowIdAndBatchIdQueryFnProps {
  id: Request['id'];
  action: RetrieveResultByWorkflowIdAndBatchIdAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const retrieveResultByWorkflowIdAndBatchIdQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: RetrieveResultByWorkflowIdAndBatchIdQueryFnProps): Promise<RetrieveResultByWorkflowIdAndBatchIdActionQueryResult> => {
  const type = BackendActionType.RETRIEVE_RESULT_BY_WORKFLOW_ID_AND_BATCH_ID;
  const response = await backendClient.retrieveResultByWorkflowIdAndBatchId(action.payload);
  const request = getRequest({ id, requestsLookup });

  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }

  const batchResult = response.body;
  const job = getJob({ batchResult });

  if (!job) throw new Error('Job not found');

  if (isJobInProgress({ job })) {
    return { type, response: batchResult, requestStatus: RequestStatus.PENDING };
  } else if (isJobFailed({ job })) {
    throw new SubmitWorkflowError(job.message || `Workflow job failed with status '${job.status}'`);
  } else if (isJobCompleted({ job })) {
    if (jobContainsResults({ job })) {
      return { type, response: batchResult, requestStatus: RequestStatus.SUCCESS };
    } else if (job.message) {
      throw new SubmitWorkflowError(job.message);
    } else {
      throw new SubmitWorkflowError('Workflow job completed without results');
    }
  } else {
    throw new Error(`Unknown job status: '${job.status}'`);
  }
};

interface HandleRetrieveResultByWorkflowIdAndBatchIdActionProps {
  action: RetrieveResultByWorkflowIdAndBatchIdAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}

export const handleRetrieveResultByWorkflowIdAndBatchIdAction = ({
  action,
  backendClient,
  requestsLookup,
  setQueries,
}: HandleRetrieveResultByWorkflowIdAndBatchIdActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.RETRIEVE_RESULT_BY_WORKFLOW_ID_AND_BATCH_ID,
    getQueryResult: ({ id }) => {
      return retrieveResultByWorkflowIdAndBatchIdQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
