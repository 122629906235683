import { Draft } from 'immer';
import { SnackbarState, SnackbarAction, SnackbarActionType, TimeoutRef, ActionHandlerReturn } from 'src/v2/contexts/snackbar/types';

interface HandleActionRemoveNotificationProps {
  action: Extract<SnackbarAction, { type: SnackbarActionType.REMOVE_NOTIFICATION }>;
  state: Draft<SnackbarState>;
  timeoutsRef: TimeoutRef;
}

export const handleActionRemoveNotification = ({ action, state, timeoutsRef }: HandleActionRemoveNotificationProps): ActionHandlerReturn => {
  const notificationId = action.payload;
  state.notifications = state.notifications.filter((n) => n.id !== notificationId);

  if (timeoutsRef[notificationId]) {
    clearTimeout(timeoutsRef[notificationId].id);
    delete timeoutsRef[notificationId];
  }

  if (state.notifications.length === 0) {
    state.expanded = false;
  }
};
