import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { ReferenceImageSelector } from 'src/v2/components/studio/referenceImageSelector/referenceImageSelector';
import styled from 'styled-components';
import { ReferenceWidgetProps, WidgetViewHeaderLabels } from '../types';
import { WidgetHeader } from '../WidgetHeader';

const StyledContainer = styled.div<{ height: number }>`
  min-height: ${(prop) => prop.height}px;
`;

export const ReferenceWidget = ({ closeWidget, dataTestId, height }: ReferenceWidgetProps) => {
  return (
    <StyledContainer height={height}>
      <div data-testid={dataTestId}>
        <WidgetHeader dataTestId={dataTestId} label={WidgetViewHeaderLabels.ReferenceImages} icon={iconTypes.image} closeWidget={closeWidget} />
      </div>
      <div>
        <ReferenceImageSelector />
      </div>
    </StyledContainer>
  );
};
