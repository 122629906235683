import { Theme, ThemeProvider } from '@amzn/storm-ui';
import type Kovna from 'konva';
import Konva from 'konva';
import { useRef, useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Layer, Stage, Image, Rect } from 'react-konva';
import commonStyle from 'src/components/common/common.module.scss';
import { EditorContextP1, EditorContextStateP1 } from 'src/components/editor/EditorContext';
import { ControlComponentProps } from 'src/components/editor/UiContols/uiGeneratorControls/controlComponentTypes';
import BoundedBoxSelection from 'src/components/editor/UiContols/uiGeneratorControls/imageEditingControls/BoundedBoxSelection';
import BrushSelection, { BrushLine } from 'src/components/editor/UiContols/uiGeneratorControls/imageEditingControls/BrushSelection';
import Stack from 'src/customUIComponents/Stack';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import useImage from 'use-image';
import ControlLabel from './UI/ControlLabel';

type KonvaTouchMouseEvent = Kovna.KonvaEventObject<MouseEvent> | Kovna.KonvaEventObject<TouchEvent>;

const overrideMouseLeaveTimeout = (theme: Theme) => {
  return {
    ...theme,
    tooltip: {
      ...theme.tooltip,
      mouseLeaveTimeout: 0,
    },
  };
};

const ImageEditingCanvas = forwardRef((props: ControlComponentProps, forwardRef) => {
  const editorContext: EditorContextStateP1 = useContext(EditorContextP1);

  const control = props.control;
  const [tool, setTool] = useState<string>('brush');
  const brushTool: string = 'pen';
  const [lines, setLines] = useState<BrushLine[]>([]);
  const [shadowLines, setShadowLines] = useState<BrushLine[]>([]);

  const stageRef = useRef(null);
  const shadowRef = useRef<Konva.Stage | null>(null);

  const isDrawing = useRef(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const [img, setImg] = useState<string>('');
  const [image] = useImage(img);

  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({ width: 300, height: 200 });
  const [actualDimensions, setActualDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [scale, setScale] = useState<{ scaleX: number; scaleY: number }>({ scaleX: 0, scaleY: 0 });
  const divRef = useRef<HTMLDivElement>(null);

  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = isDarkModeLaunched && useAppSelector(isDarkModeActive);

  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setImageDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (img === undefined || (typeof img === 'string' && img.length === 0)) {
      setImageDimensions({
        width: divRef.current?.offsetWidth ?? 0,
        height: 200,
      });
      setDisabled(true);
    } else setDisabled(false);
  }, [img]);

  const [boundedBox, setBoundedBox] = useState({
    width: 0,
    height: 0,
    fill: 'rgba(255, 250, 250, 0.1)',
    // opacity: 0.08,
    stroke: 'black',
    strokeWidth: 2,
    x: 0,
    y: 0,
  });
  const [shadowBoundedBox, setShadowBoundedBox] = useState({
    width: 0,
    height: 0,
    fill: 'white',
    // opacity: 0.08,
    stroke: 'white',
    strokeWidth: 2,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (!editorContext.fileUploadEvent) {
      setImg('');
      clearCanvas();
      return;
    }
    const img = document.createElement('img');
    const imageObjUrl = URL.createObjectURL(editorContext.fileUploadEvent.payload);
    img.id = 'canvasImage';
    img.onload = (ev) => {
      if (ev.target === null) {
        return;
      }
      const eventTarget = ev.target as HTMLImageElement;
      updateImage(imageObjUrl, eventTarget.width, eventTarget.height);
    };
    img.src = imageObjUrl;
  }, [editorContext.fileUploadEvent]);

  // used for unit testing
  useImperativeHandle(forwardRef, () => ({
    updateImage,
    setDisabled,
    getLines: () => lines,
    getShadowLines: () => shadowLines,
    shadowRef: () => shadowRef,
    editorContext: () => editorContext,
    getBoundedBox: () => boundedBox,
    getShadowBoundedBox: () => shadowBoundedBox,
  }));

  const updateImage = (url: string, width: number, height: number) => {
    setImg(url);
    const newHeight = (imageDimensions.width * height) / width;
    setImageDimensions({ width: imageDimensions.width, height: newHeight });
    setActualDimensions({ width, height });
    setScale({ scaleX: width / imageDimensions.width, scaleY: height / newHeight });
  };

  const srcToFile = (src: string, fileName: string, mimeType: string) => {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  };

  const updateMask = async () => {
    if (shadowRef.current) {
      const dataUrl = shadowRef.current.toDataURL();
      const fileObj = await srcToFile(dataUrl, 'image_mask.png', 'image/png');
      const controlName = props.control.controlName;
      const controlData = {
        [controlName]: { value: null, file: fileObj },
      };
      editorContext.setWorkFlowOptions({ ...editorContext.workflowOptions, ...controlData });
    }
  };

  const handleMouseUpBrush = () => {
    isDrawing.current = false;
    updateMask();
  };
  const handleMouseMoveBrush = (e: KonvaTouchMouseEvent) => {
    // no drawing - skipping

    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage?.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    let shadowLastLine = shadowLines[shadowLines.length - 1];
    // add point
    const pointX = point?.x ?? 0;
    const pointY = point?.y ?? 0;
    shadowLastLine.points = shadowLastLine.points.concat([pointX, pointY]);
    lastLine.points = lastLine.points.concat([pointX, pointY]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    shadowLines.splice(shadowLines.length - 1, 1, shadowLastLine);
    setLines(lines.concat());
    setShadowLines(shadowLines.concat());
  };
  const handleMouseDownBrush = (e: KonvaTouchMouseEvent) => {
    isDrawing.current = true;
    const pos = e.target.getStage()?.getPointerPosition();
    const x = pos?.x ?? 0;
    const y = pos?.y ?? 0;

    // let tool;
    if (tool === 'brush') {
      setLines([{ tool, points: [x, y] }]);
      setShadowLines([{ tool, points: [x, y] }]);
    } else {
      setLines([...lines, { tool, points: [x, y] }]);
      setShadowLines([...shadowLines, { tool, points: [x, y] }]);
    }
  };

  const handleMouseUpBox = () => {
    isDrawing.current = false;
    updateMask();
  };
  const handleMouseMoveBox = (e: KonvaTouchMouseEvent) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage?.getPointerPosition();
    const pointX = point?.x ?? 0;
    const pointY = point?.y ?? 0;

    const x = pointX - boundedBox.x;
    const y = pointY - boundedBox.y;

    setBoundedBox({ ...boundedBox, width: x, height: y });
    setShadowBoundedBox({ ...shadowBoundedBox, width: x, height: y });
  };

  const handleMouseDownBox = (e: KonvaTouchMouseEvent) => {
    isDrawing.current = true;
    const pos = e.target.getStage()?.getPointerPosition();
    const x = pos?.x ?? 0;
    const y = pos?.y ?? 0;
    setBoundedBox({ ...boundedBox, x, y, width: 5, height: 5 });
    setShadowBoundedBox({ ...shadowBoundedBox, x, y, width: 5, height: 5 });
  };

  const clearCanvas = () => {
    setLines([]);
    setShadowLines([]);
    setBoundedBox({ ...boundedBox, width: 0, height: 0 });
    setShadowBoundedBox({ ...shadowBoundedBox, width: 0, height: 0 });
  };

  const handleToolChange = (tool: string) => {
    if (tool === 'clear') clearCanvas();
    setTool(tool);
  };

  const handleMouseDown = (e: KonvaTouchMouseEvent) => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') handleMouseDownBrush(e);
      else if (tool === 'boundedBox') handleMouseDownBox(e);
    }
  };

  const handleMouseUp = () => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') handleMouseUpBrush();
      else if (tool === 'boundedBox') handleMouseUpBox();
    }
  };

  const handleMouseMove = (e: KonvaTouchMouseEvent) => {
    if (!disabled) {
      if (tool === 'brush' || tool === 'eraser') handleMouseMoveBrush(e);
      else if (tool === 'boundedBox') handleMouseMoveBox(e);
    }
  };

  return (
    <>
      <ControlLabel title={control.controlLabel} subTitle={control.description} />

      <div className={commonStyle.hideTooltipCloseIcon} style={{ width: '100%', display: 'flex', flexDirection: 'column', margin: 'auto' }}>
        <div ref={divRef} style={{ width: '100%', height: imageDimensions.height }}>
          <Stage
            width={imageDimensions.width}
            height={imageDimensions.height}
            style={{ background: 'var(--surface-secondary, #F2F4F6)', marginBottom: '5px', borderRadius: '12px' }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            ref={stageRef}
          >
            <Layer>
              <Image image={image} width={imageDimensions.width} height={imageDimensions.height} />
            </Layer>
            {tool === 'brush' && <BrushSelection brush={brushTool} lines={lines} />}
            {tool === 'boundedBox' && <BoundedBoxSelection boundedBox={boundedBox} />}
            {tool === 'eraser' && <BrushSelection brush={'eraser'} lines={lines} />}
          </Stage>
          <Stage
            ref={shadowRef}
            width={actualDimensions.width}
            height={actualDimensions.height}
            style={{ border: '1px solid grey', marginBottom: '5px', display: 'none' }}
            scaleX={scale.scaleX}
            scaleY={scale.scaleY}
          >
            <Layer>
              <Rect x={0} y={0} width={imageDimensions.width} height={imageDimensions.height} fill="black"></Rect>
            </Layer>
            {tool === 'eraser' && <BrushSelection brush={'eraser'} strokeWidth={30} opacity={1} lines={lines} stroke={'white'} />}
            {tool === 'brush' && <BrushSelection brush={brushTool} strokeWidth={30 * scale.scaleX} opacity={1} lines={lines} stroke={'white'} />}
            {tool === 'boundedBox' && <BoundedBoxSelection boundedBox={shadowBoundedBox} />}
          </Stage>
        </div>
        <div
          style={{
            maxWidth: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '5px',
            marginBottom: '5px',
          }}
        >
          <ThemeProvider theme={overrideMouseLeaveTimeout}>
            <Stack className={commonStyle.stackMenu}>
              <Button
                data-testid="studio-style-container-toggle-button"
                type={ButtonTypes.Secondary}
                icon={iconTypes.boundingBox}
                iconOnly
                disabled={disabled}
                popoverText="Use a bounding box to select the area"
                onClick={() => {
                  handleToolChange('boundedBox');
                }}
                style={{
                  border:
                    tool === 'boundedBox' && !disabled ? (isDarkMode ? 'solid 2px var(--interactive-primary, "#6236FF")' : 'solid 2px #6236FF') : '',
                }}
              />
              <Button
                type={ButtonTypes.Secondary}
                icon={iconTypes.draw}
                iconOnly
                disabled={disabled}
                popoverText="Use the drawing tool to select the area"
                onClick={() => {
                  handleToolChange('brush');
                }}
                style={{
                  border: tool === 'brush' && !disabled ? (isDarkMode ? 'solid 2px var(--interactive-primary, "#6236FF")' : 'solid 2px #6236FF') : '',
                }}
              />
              <Button
                type={ButtonTypes.Secondary}
                icon={iconTypes.erase}
                iconOnly
                popoverText="Refine or remove a selected area"
                disabled={disabled}
                onClick={() => {
                  handleToolChange('eraser');
                }}
                style={{
                  border:
                    tool === 'eraser' && !disabled ? (isDarkMode ? 'solid 2px var(--interactive-primary, "#6236FF")' : 'solid 2px #6236FF') : '',
                }}
              />
              <Button
                type={ButtonTypes.Secondary}
                icon={iconTypes.undo}
                iconOnly
                popoverText="Reset the selection area"
                disabled={disabled}
                onClick={() => {
                  handleToolChange('clear');
                }}
                style={{
                  border: tool === 'clear' && !disabled ? (isDarkMode ? 'solid 2px var(--interactive-primary, "#6236FF")' : 'solid 2px #6236FF') : '',
                }}
              />
            </Stack>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
});
ImageEditingCanvas.displayName = 'ImageEditingCanvas';
export default ImageEditingCanvas;
