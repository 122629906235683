import { produce } from 'immer';
import { createContext, useReducer, useContext, ReactNode, useMemo, FC, JSX } from 'react';
import { BackendDispatchContext } from 'src/v2/contexts/backend/BackendContext';
import { useAppStore } from 'src/v2/redux/hooks';
import { handleFetchFeedAction } from './actions/FetchFeedAction';
import { FeedContextState, FeedAction, FeedActionType } from './FeedContext.types';

export const useFeedContext = () => {
  const appStore = useAppStore();
  const backendDispatchContext = useContext(BackendDispatchContext);
  const [, dispatch] = useReducer(
    produce((state: FeedContextState, action: FeedAction) => {
      switch (action.type) {
        case FeedActionType.FETCH_FEED: {
          handleFetchFeedAction({
            appStore,
            backendDispatchContext,
            action,
          });
          break;
        }
      }
    }),
    {},
  );

  return useMemo(() => ({ dispatch }), []);
};

export type FeedDispatchContextType = ReturnType<typeof useFeedContext>['dispatch'];

export const FeedDispatchContext = createContext<FeedDispatchContextType>(() => {});

interface FeedProviderProps {
  children: ReactNode;
}

export const FeedContextProvider: FC<FeedProviderProps> = ({ children }): JSX.Element => {
  const { dispatch } = useFeedContext();
  return <FeedDispatchContext.Provider value={dispatch}>{children}</FeedDispatchContext.Provider>;
};
