import { IconDefinition } from '@amzn/storm-ui-icons';

const IconNames = [
  'add-brief',
  'add-image',
  'add-product',
  'add-video',
  'audio',
  'bounding-box',
  'chevron-down',
  'chevron-up',
  'close',
  'collections',
  'concepts',
  'effects',
  'erase',
  'favorite',
  'favorite-selected',
  'filter',
  'headline',
  'history',
  'horizontal',
  'image',
  'import-it',
  'insert',
  'live-image',
  'masking',
  'minus',
  'more-horizontal',
  'more-vertical',
  'pause',
  'pencil',
  'play',
  'plus',
  'position',
  'products',
  'reframe',
  'remix',
  'remove',
  'restyle',
  'rotate',
  'search',
  'select',
  'send',
  'sidebar',
  'sparkle',
  'speech-bubble',
  'speed',
  'square',
  'styles',
  'time',
  'trash-can',
  'vertical',
  'video',
  'zoom',
] as const;

export type IconName = (typeof IconNames)[number];

export const addBrief: IconDefinition = {
  iconName: 'add-brief',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M17 8H7v2h10V8ZM7 11h10v2H7v-2Z',
      'm3.75 2.85-.9.9v16.5l.9.9h10.367a5 5 0 1 0 7.033-7.033V3.75l-.9-.9H3.75Zm15.6 10.334A5.003 5.003 0 0 0 18 13a4.978 4.978 0 0 0-3 1H7v2h6.416a5.005 5.005 0 0 0-.232 3.35H4.65V4.65h14.7v8.534ZM17.1 18.9H15v-1.8h2.1V15h1.8v2.1H21v1.8h-2.1V21h-1.8v-2.1Z',
    ],
  ],
};

export const addImage: IconDefinition = {
  iconName: 'add-image',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M14 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z',
      'm2.85 3.75.9-.9h16.5l.9.9v10.367a5 5 0 1 1-7.033 7.033H3.75l-.9-.9V3.75Zm10.334 15.6a4.929 4.929 0 0 1-.05-.193l-4.128-5.308-4.166 5.448a.91.91 0 0 1-.044.053h8.388Zm.08-2.958a5.002 5.002 0 0 1 6.086-3.208V4.65H4.65v11.932l3.635-4.754 1.426-.005 3.553 4.569ZM17.1 18.9V21h1.8v-2.1H21v-1.8h-2.1V15h-1.8v2.1H15v1.8h2.1Z',
    ],
  ],
};

export const addProduct: IconDefinition = {
  iconName: 'add-product',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm3.236 3.787.865-.432 7.903.823 7.903-.823.865.432 2 3.333-.563 1.339-1.809.43v4.724c-.546-.3-1.154-.5-1.8-.577V9.318l-3.978.947L12.9 7.874v10.67l.123-.062a4.98 4.98 0 0 0 .506 1.76L12 21.005l-8.4-4.2V8.888l-1.8-.43-.564-1.338 2-3.333ZM5.4 9.317v6.377l5.7 2.85V7.884l-1.714 2.381L5.4 9.316Zm-.915-4.112L3.412 6.992 8.63 8.235l1.74-2.417-5.885-.613Zm15.038 0-5.885.613 1.74 2.417 5.218-1.243-1.073-1.787Z',
      'M18 23a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm-.9-2v-2.1H15v-1.8h2.1V15h1.8v2.1H21v1.8h-2.1V21h-1.8Z',
    ],
  ],
};

export const addVideo: IconDefinition = {
  iconName: 'add-video',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm4 5.1-.9.9v12l.9.9h9.08a5.026 5.026 0 0 1 0-1.8H4.9V6.9h14.2v6.221a4.975 4.975 0 0 1 1.8.805V6l-.9-.9H4Z',
      'M14.5 12.427v-.86L11.3 9l-.8.642v4.71l.8.648 3.2-2.573ZM18 23a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm-.9-8h1.8v2.1H21v1.8h-2.1V21h-1.8v-2.1H15v-1.8h2.1V15Z',
    ],
  ],
};

export const audio: IconDefinition = {
  iconName: 'audio',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M8.433 6.667a3.567 3.567 0 1 1 7.134 0v4.444a3.567 3.567 0 0 1-7.134 0V6.667ZM12 4.9c-.976 0-1.767.791-1.767 1.767v4.444a1.767 1.767 0 1 0 3.534 0V6.667c0-.976-.791-1.767-1.767-1.767Zm5.322 6.211V8.433h1.8v2.678a7.122 7.122 0 0 1-6.222 7.065V20.9h-1.8v-2.724a7.123 7.123 0 0 1-6.222-7.065V8.433h1.8v2.678a5.322 5.322 0 1 0 10.644 0Z',
  ],
};

export const boundingBox: IconDefinition = {
  iconName: 'bounding-box',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'm2.1 3 .9-.9h4l.9.9v1.1h8.2V3l.9-.9h4l.9.9v4l-.9.9h-1.1v8.2H21l.9.9v4l-.9.9h-4l-.9-.9v-1.1H7.9V21l-.9.9H3l-.9-.9v-4l.9-.9h1.1V7.9H3L2.1 7V3Zm14 15.1V17l.9-.9h1.1V7.9H17l-.9-.9V5.9H7.9V7l-.9.9H5.9v8.2H7l.9.9v1.1h8.2ZM3.9 3.9v2.2h2.2V3.9H3.9Zm14 2.2h2.2V3.9h-2.2v2.2Zm-14 11.8v2.2h2.2v-2.2H3.9Zm14 2.2h2.2v-2.2h-2.2v2.2Z',
  ],
};

export const chevronDown: IconDefinition = {
  iconName: 'chevron-down',
  prefix: 'fas',
  icon: [24, 24, [], '', 'm3.975 6.727 8.32 8 7.679-8 1.299 1.247-8.304 8.65-1.273.025-8.969-8.624 1.248-1.298Z'],
};

export const chevronUp: IconDefinition = {
  iconName: 'chevron-up',
  prefix: 'fas',
  icon: [24, 24, [], '', 'm20.025 17.273-8.32-8-7.679 8-1.298-1.247 8.303-8.65 1.273-.025 8.969 8.624-1.248 1.298Z'],
};

export const close: IconDefinition = {
  iconName: 'close',
  prefix: 'fas',
  icon: [24, 24, [], '', ['M5.003 17.727 17.73 5l1.273 1.273L6.276 19l-1.273-1.273Z', 'm6.276 5 12.728 12.728L17.73 19 5.003 6.273 6.276 5Z']],
};

export const collections: IconDefinition = {
  iconName: 'collections',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm12.368 2.722 8.43 6.18h.008v1.095l-8.43 6.18h-1.17l-8.37-6.135h.007V8.85l8.355-6.128h1.17Zm-.585 2.558L6.03 9.45l5.753 4.17 5.757-4.17-5.757-4.17Z',
      'm20.751 15.442-8.422 6.173h-1.095l-8.43-6.18.735-2.455 8.242 6.04 8.235-6.03.735 2.452Z',
    ],
  ],
};

export const concepts: IconDefinition = {
  iconName: 'concepts',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M7.12 4.121A6.9 6.9 0 0 1 18.9 9c0 1.515-.58 3.062-1.78 4.152-.73.733-1.088 1.277-1.238 2.025l-.176.882-1.765-.353.176-.883c.25-1.247.887-2.1 1.747-2.96l.034-.032C16.685 11.122 17.1 10.078 17.1 9A5.1 5.1 0 1 0 6.9 9c0 .824.145 1.772 1.236 2.864.751.75 1.495 1.704 1.747 2.96l.176.882-1.765.353-.177-.883c-.149-.744-.604-1.39-1.253-2.04C5.355 11.628 5.1 10.176 5.1 9a6.9 6.9 0 0 1 2.02-4.879ZM8.1 17.1h7.8v1.8H8.1v-1.8Zm1 3h5.8v1.8H9.1v-1.8Z',
  ],
};

export const effects: IconDefinition = {
  iconName: 'effects',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M15.864 3h1.272l3.941 3.941v1.273L8.129 21.16H6.856l-3.94-3.94v-1.273L15.864 3Zm.636 1.91-2.666 2.665-1.272 1.273-7.737 7.736 2.668 2.668 7.736-7.736-2.667-2.668 1.272-1.273 2.668 2.668 2.666-2.666L16.5 4.91Zm2.504 11.487.848-1.607h.796l.848 1.607 1.257.856v.744l-1.257.856-.848 1.607h-.796l-.848-1.607-1.257-.856v-.744l1.257-.856ZM3.09 7.24l.705-1.311h.66l.706 1.31 1.047.698v.624l-1.047.698-.706 1.311h-.66l-.706-1.31-1.047-.699v-.624l1.047-.698ZM8.13 3.1l.529-.983h.495l.53.983.785.523v.468l-.786.524-.53.983H8.66l-.53-.983-.785-.524v-.468L8.13 3.1Z',
  ],
};

export const erase: IconDefinition = {
  iconName: 'erase',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'm20.273 9.185-6.458-6.458L1.253 15.292l3.088 3.32L5 18.9h14v-1.8h-6.642l7.915-7.915ZM9.813 17.1h-4.42l-1.64-1.763 4.724-4.725 3.912 3.912L9.812 17.1Z',
  ],
};

export const favoriteSelected: IconDefinition = {
  iconName: 'favorite-selected',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M12 5.724c1.866-1.894 4.926-2.245 7.03-.452 2.364 2.017 2.485 5.625.37 7.808l-6.047 6.244a1.88 1.88 0 0 1-2.708 0L4.598 13.08c-2.112-2.183-1.992-5.79.371-7.807C7.073 3.48 10.137 3.83 12 5.724Z',
  ],
};

export const favorite: IconDefinition = {
  iconName: 'favorite',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M12 5.724c1.866-1.894 4.926-2.245 7.03-.452 2.364 2.017 2.485 5.625.37 7.808l-6.047 6.244a1.88 1.88 0 0 1-2.708 0L4.598 13.08c-2.112-2.183-1.992-5.79.371-7.807C7.073 3.48 10.137 3.83 12 5.724Zm5.863.918c-1.31-1.115-3.32-.955-4.6.367l-.617.634h-1.292l-.616-.635c-1.278-1.32-3.291-1.482-4.6-.366-1.562 1.333-1.648 3.738-.247 5.186l6.046 6.243A.083.083 0 0 0 12 18.1a.08.08 0 0 0 .061-.029l6.047-6.243c1.403-1.448 1.317-3.853-.244-5.186Z',
  ],
};

export const filter: IconDefinition = {
  iconName: 'filter',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M3.1 5.1h17.8v1.8H3.1V5.1Zm0 6h17.8v1.8H3.1v-1.8Zm0 6h17.8v1.8H3.1v-1.8Z',
      'M10.5 6a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm0 12a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Zm8-6a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z',
    ],
  ],
};

export const headline: IconDefinition = {
  iconName: 'headline',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    ['M5.1 4.1h4.8v1.8H8.4v4.7h7.2V5.9h-1.5V4.1h4.8v1.8h-1.5v12.2h1.5v1.8h-4.8v-1.8h1.5v-5.7H8.4v5.7h1.5v1.8H5.1v-1.8h1.5V5.9H5.1V4.1Z'],
  ],
};

export const history: IconDefinition = {
  iconName: 'history',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M6.326 8.175a7.064 7.064 0 0 0-.951 3.422l-.016.9-1.8-.03.016-.9a8.9 8.9 0 1 1 4.45 7.866l-.78-.45.9-1.56.78.45a7.1 7.1 0 1 0-2.599-9.698Z',
      'm8.333 10.666-2.804 3.15-1.271.073-3.149-2.805L2.306 9.74l2.477 2.206 2.206-2.477 1.344 1.197Zm5.067.42V6.5h-1.8v5l.314.683 3.5 3 1.172-1.366-3.186-2.73Z',
    ],
  ],
};

export const horizontal: IconDefinition = {
  iconName: 'horizontal',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M19.35 15.975v-2.85h1.8v3.75l-.9.9H16.5v-1.8h2.85Zm0-7.95v2.85h1.8v-3.75l-.9-.9H16.5v1.8h2.85Zm-14.7 0v2.85h-1.8v-3.75l.9-.9H7.5v1.8H4.65Zm0 7.95v-2.85h-1.8v3.75l.9.9H7.5v-1.8H4.65Z',
    ],
  ],
};

export const image: IconDefinition = {
  iconName: 'image',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm2.85 3.75.9-.9h16.5l.9.9v16.5l-.9.9H3.75l-.9-.9V3.75Zm1.8.9v14.7h14.7V4.65H4.65Z',
      'M16 9a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm-7.715 2.828 1.425-.005 4.498 5.782 2.677-3.867 1.477-.004 2.625 3.75a.9.9 0 0 1-1.474 1.032l-1.884-2.69-2.64 3.811-1.45.04-4.533-5.828-4.166 5.448a.9.9 0 1 1-1.43-1.094l4.875-6.375Z',
    ],
  ],
};

export const importIt: IconDefinition = {
  iconName: 'import-it',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm13.1 14 .9-.9h6l.9.9v6l-.9.9h-6l-.9-.9v-6Zm1.8.9v4.2h4.2v-4.2h-4.2ZM3.1 14l.9-.9h6l.9.9v6l-.9.9H4l-.9-.9v-6Zm1.8.9v4.2h4.2v-4.2H4.9ZM3.1 4l.9-.9h6l.9.9v6l-.9.9H4l-.9-.9V4Zm1.8.9v4.2h4.2V4.9H4.9Zm10 2.927 4.464-4.463 1.272 1.272L16.173 9.1H20v1.8h-6l-.9-.9V4h1.8v3.827Z',
    ],
  ],
};

export const insert: IconDefinition = {
  iconName: 'insert',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm4.1 5 .9-.9h14l.9.9v8.374a5.002 5.002 0 1 1-6.526 6.526H5l-.9-.9V5ZM13 18.1V18a5 5 0 0 1 5.1-4.999V5.9H5.9v12.2H13Zm4.1.8V21h1.8v-2.1H21v-1.8h-2.1V15h-1.8v2.1H15v1.8h2.1Z',
    ],
  ],
};

export const liveImage: IconDefinition = {
  iconName: 'live-image',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm3.1 4 .9-.9h16l.9.9v12l-.9.9H4l-.9-.9V4Zm1.8.9v10.2h14.2V4.9H4.9Z',
      'M14.5 10.427v-.86L11.3 7l-.8.642v4.71l.8.648 3.2-2.573ZM12.9 15.1v5.3h-1.8v-5.3h1.8Z',
      'M7.1 19.1h9.8v1.8H7.1v-1.8Z',
    ],
  ],
};

export const masking: IconDefinition = {
  iconName: 'masking',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm20.273 9.185-6.458-6.458L4.1 12.442V18l.9.9h14v-1.8h-6.642l7.915-7.915ZM5.9 17.1v-3.912l4.656-4.657 3.913 3.912L9.812 17.1H5.9Zm9.842-5.93-3.913-3.912 1.986-1.985 3.912 3.912-1.985 1.986Z',
    ],
  ],
};

export const minus: IconDefinition = {
  iconName: 'minus',
  prefix: 'fas',
  icon: [24, 24, [], '', ['M3 11.1h18v1.8H3v-1.8Z']],
};

export const moreHorizontal: IconDefinition = {
  iconName: 'more-horizontal',
  prefix: 'fas',
  icon: [24, 24, [], '', ['M18 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-6 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-6 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z']],
};

export const moreVertical: IconDefinition = {
  iconName: 'more-vertical',
  prefix: 'fas',
  icon: [24, 24, [], '', ['M14 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm0 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm0 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z']],
};

export const pause: IconDefinition = {
  iconName: 'pause',
  prefix: 'fas',
  icon: [24, 24, [], '', ['M7 5h3v14H7V5Zm7 0h3v14h-3V5Z']],
};

export const pencil: IconDefinition = {
  iconName: 'pencil',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm15.18 2.242 6.576 6.576-9.916 9.916H6.164l-.9-.9v-5.676l9.916-9.916Zm0 2.545-8.116 8.116v4.03h4.03l8.116-8.115-4.03-4.03Z',
      'm12.636 6.364 5.5 5.5-1.272 1.272-5.5-5.5 1.272-1.272Z',
    ],
  ],
};

export const play: IconDefinition = {
  iconName: 'play',
  prefix: 'fas',
  icon: [24, 24, [], '', ['M18 13.138v-2.292L9.2 4 7 5.711v12.561L9.2 20l8.8-6.862Z']],
};

export const plus: IconDefinition = {
  iconName: 'plus',
  prefix: 'fas',
  icon: [24, 24, [], '', ['M11.1 21V3h1.8v18h-1.8Z', 'M3 11.1h18v1.8H3v-1.8Z']],
};

export const position: IconDefinition = {
  iconName: 'position',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm11.173 9.9 3.1 3.1L13 14.273l-3.1-3.1V13.9H8.1V9l.9-.9h4.9v1.8h-2.727Z',
      'm3 2.1-.9.9v4l.9.9h1.1v8.2H3l-.9.9v4l.9.9h4l.9-.9v-1.1h8.2V21l.9.9h4l.9-.9v-4l-.9-.9h-1.1V7.9H21l.9-.9V3l-.9-.9h-4l-.9.9v1.1H7.9V3L7 2.1H3ZM16.1 17v1.1H7.9V17l-.9-.9H5.9V7.9H7l.9-.9V5.9h8.2V7l.9.9h1.1v8.2H17l-.9.9ZM3.9 6.1V3.9h2.2v2.2H3.9Zm14-2.2h2.2v2.2h-2.2V3.9Zm-14 16.2v-2.2h2.2v2.2H3.9Zm14-2.2h2.2v2.2h-2.2v-2.2Z',
    ],
  ],
};

export const products: IconDefinition = {
  iconName: 'products',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm4.101 4.355-.865.432-2 3.333.564 1.34 1.801.428v7.918l.498.249 7.5 3.75.402.201.402-.201 7.5-3.75.498-.249V9.89l1.809-.431.563-1.339-2-3.333-.865-.432-7.902.823L4.1 4.355ZM5.4 16.694v-6.378l3.4.81.586.139.352-.49 1.362-1.89v10.659l-5.7-2.85Zm7.5 2.85V8.874l1.37 1.902.352.49.587-.14 3.391-.808v6.376l-5.7 2.85ZM3.412 7.992l1.073-1.788 5.885.613-1.74 2.418-5.218-1.243Zm17.184 0-5.218 1.243-1.74-2.417 5.885-.613 1.073 1.787Z',
    ],
  ],
};

export const reframe: IconDefinition = {
  iconName: 'reframe',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M9.25 9.9h1.833V8.1H9.25v1.8Zm3.667 0h1.833V8.1h-1.833v1.8Zm-3.667 6h1.833v-1.8H9.25v1.8Zm3.667 0h1.833v-1.8h-1.833v1.8Z',
      'M5.1 21v-2.1H3v-1.8h2.1V6.9H3V5.1h2.1V3h1.8v2.1h10.2V3h1.8v2.1H21v1.8h-2.1v10.2H21v1.8h-2.1V21h-1.8v-2.1H6.9V21H5.1Zm12-3.9v-1.2h-.517v-1.8h.517V9.9h-.517V8.1h.517V6.9H6.9v1.2h.517v1.8H6.9v4.2h.517v1.8H6.9v1.2h10.2Z',
    ],
  ],
};

export const remix: IconDefinition = {
  iconName: 'remix',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm13.1 4 .9-.9h6l.9.9v6l-.9.9h-6l-.9-.9V4Zm1.8.9v4.2h4.2V4.9h-4.2ZM3.1 17a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0ZM7 14.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2ZM3.1 4l.9-.9h6l.9.9v2.025H9.1V4.9H7.9v4.2h1.125v1.8h-4.05V9.1H6.1V4.9H4.9v1.125H3.1V4Zm14.539 9.607 1.101 1.79 1.638.955v1.296l-1.638.955-1.101 1.79H16.36l-1.101-1.79-1.638-.955v-1.296l1.638-.955 1.101-1.79h1.278Z',
    ],
  ],
};

export const remove: IconDefinition = {
  iconName: 'remove',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm4.1 5 .9-.9h.875V5H5.9v.875h-.025V5.9H5v-.025h-.9V5Zm3.525-.9h1.75v1.8h-1.75V4.1Zm3.5 0h1.75v1.8h-1.75V4.1Zm3.5 0h1.75v1.8h-1.75V4.1Zm3.5 0H19l.9.9v.875H19V5.9h-.875v-.025H18.1V5h.025v-.9ZM4.1 9.375v-1.75h1.8v1.75H4.1Zm15.8-1.75v1.75h-1.8v-1.75h1.8Zm-15.8 5.25v-1.75h1.8v1.75H4.1Zm15.8-1.75v1.75h-1.8v-1.75h1.8Zm-15.8 5.25v-1.75h1.8v1.75H4.1ZM5.875 19.9H5l-.9-.9v-.875H5V18.1h.875v.025H5.9V19h-.025v.9Zm3.5 0h-1.75v-1.8h1.75v1.8Zm3.5 0h-1.75v-1.8h1.75v1.8Z',
      'M23 18a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm-2-.9h-6v1.8h6v-1.8Z',
    ],
  ],
};

export const restyle: IconDefinition = {
  iconName: 'restyle',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm8.659 2.117-.53.983-.785.523v.468l.785.524.53.983h.495l.53-.983.785-.524v-.468L9.683 3.1l-.53-.983H8.66ZM3.877 5.973l-.716 1.331-1.067.711v.468l1.067.711.716 1.331h.496l.716-1.33 1.067-.712v-.468l-1.067-.71-.716-1.332h-.496Zm16.124 8.896-.867 1.644-1.292.88v.465l1.292.879L20 20.38h.498l.867-1.644 1.292-.88v-.465l-1.292-.879-.867-1.644H20ZM17.136 3h-1.272L2.916 15.948v1.273l3.94 3.94H8.13l8.974-8.973a.912.912 0 0 0 .071-.071l3.903-3.903V6.94L17.138 3Zm-.634 7.243-2.668-2.668L16.5 4.91l2.668 2.667-2.666 2.666Zm-3.94-1.395 2.667 2.668-7.736 7.736-2.668-2.668 7.737-7.736Z',
    ],
  ],
};

export const rotate: IconDefinition = {
  iconName: 'rotate',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M10.45 4.806c.359.62.534 1.293.546 1.96l.01.6 1.2-.02-.01-.6a5.267 5.267 0 1 0-5.227 5.361l.599-.004-.008-1.2-.6.004a4.067 4.067 0 1 1 3.49-6.1Z',
      'm9.153 6.206 1.843 2.07.847.05 2.07-1.844-.798-.896-1.622 1.444-1.444-1.622-.896.798Z',
    ],
  ],
};

export const search: IconDefinition = {
  iconName: 'search',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M14.96 6.626a5.893 5.893 0 1 0-8.334 8.334 5.893 5.893 0 0 0 8.334-8.334ZM5.353 5.353a7.693 7.693 0 0 1 11.48 10.207l3.803 3.804-1.272 1.272-3.804-3.803A7.694 7.694 0 0 1 5.353 5.353Z',
    ],
  ],
};

export const select: IconDefinition = {
  iconName: 'select',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M17 14.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2ZM13.1 17a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0ZM7 14.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2ZM3.1 17a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0ZM17 4.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2ZM13.1 7a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0ZM7 4.9a2.1 2.1 0 1 0 0 4.2 2.1 2.1 0 0 0 0-4.2ZM3.1 7a3.9 3.9 0 1 1 7.8 0 3.9 3.9 0 0 1-7.8 0Z',
    ],
  ],
};

export const send: IconDefinition = {
  iconName: 'send',
  prefix: 'fas',
  icon: [24, 24, [], '', ['m3.1 4 1.302-.805 16 8v1.61l-16 8L3.1 20v-6l.566-.836L6.576 12l-2.91-1.164L3.1 10V4Z']],
};

export const sidebar: IconDefinition = {
  iconName: 'sidebar',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'm2.85 3.75.9-.9h16.5l.9.9v16.5l-.9.9H3.75l-.9-.9V3.75Zm1.8.9v14.7h14.7V4.65H4.65Z',
      'M11 3.1a.9.9 0 0 1 .9.9v16a.9.9 0 0 1-1.8 0V4a.9.9 0 0 1 .9-.9ZM8.875 7.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm0 5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z',
    ],
  ],
};

export const sparkle: IconDefinition = {
  iconName: 'sparkle',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'm18.124 3.454.94-1.696h.874l.94 1.696 1.394.904v.84l-1.395.904-.94 1.696h-.874l-.94-1.696-1.395-.905v-.839l1.396-.904ZM14.96 9.191 12.652 5.13h-1.304L9.041 9.191 5.6 11.366v1.268l3.44 2.175 2.308 4.061h1.304l2.308-4.061 3.44-2.175v-1.268L14.96 9.19Z',
  ],
};

export const speechBubble: IconDefinition = {
  iconName: 'speech-bubble',
  prefix: 'fas',
  icon: [24, 24, [], '', 'm2.85 3.75.9-.9h16.5l.9.9v13.5l-.9.9H8.168l-3.889 2.828-1.429-.728V3.75Zm1.8.9v13.833l2.932-2.133H19.35V4.65H4.65Z'],
};

export const speed: IconDefinition = {
  iconName: 'speed',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M12 5.9a8.1 8.1 0 0 0-7.465 11.25l.35.829-1.658.7-.35-.829A9.872 9.872 0 0 1 2.1 14c0-5.468 4.432-9.9 9.9-9.9s9.9 4.432 9.9 9.9a9.87 9.87 0 0 1-.777 3.85l-.35.83-1.658-.701.35-.83A8.1 8.1 0 0 0 12 5.9Zm4.763 4.442-4.605 5.921-1.421-1.105 4.605-5.921 1.42 1.105Z',
  ],
};

export const square: IconDefinition = {
  iconName: 'square',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M17.85 17.85V15h1.8v3.75l-.9.9H15v-1.8h2.85Zm0-11.7V9h1.8V5.25l-.9-.9H15v1.8h2.85Zm-11.7 0V9h-1.8V5.25l.9-.9H9v1.8H6.15Zm0 11.7V15h-1.8v3.75l.9.9H9v-1.8H6.15Z',
  ],
};

export const styles: IconDefinition = {
  iconName: 'styles',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'm3.75 2.85-.9.9v6l.9.9h6l.9-.9v-6l-.9-.9h-6Zm.9 6v-4.2h4.2v4.2h-4.2Zm-.9 4.5-.9.9v6l.9.9h6l.9-.9v-6l-.9-.9h-6Zm.9 6v-4.2h4.2v4.2h-4.2Zm8.7-15.6.9-.9h6l.9.9v6l-.9.9h-6l-.9-.9v-6Zm1.8.9v4.2h4.2v-4.2h-4.2Zm.373 10.936 1.29-2.328h.874l1.29 2.328 1.92 1.244v.84l-1.92 1.244-1.29 2.328h-.874l-1.29-2.328-1.92-1.244v-.84l1.92-1.244Z',
  ],
};

export const time: IconDefinition = {
  iconName: 'time',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M6.253 15.147c.364.59 1.134 1.521 2.22 2.3 1.09.782 2.45 1.378 4.002 1.378a7.1 7.1 0 1 0-6.222-3.678Zm-1.565.89a8.9 8.9 0 1 1 7.787 4.587c-2.027.001-3.746-.778-5.051-1.714-1.299-.932-2.235-2.053-2.716-2.839l-.02-.033Z',
      'M13.4 11.086V6.5h-1.8v5l.314.683 3.5 3 1.172-1.366-3.186-2.73Z',
    ],
  ],
};

export const trashCan: IconDefinition = {
  iconName: 'trash-can',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    [
      'M9.1 16v-6h1.8v6H9.1Zm4-6v6h1.8v-6h-1.8Z',
      'm9 3.1-.9.9v2.1H3v1.8h2.172l1.022 12.175.897.825h9.818l.897-.825L18.828 7.9H21V6.1h-5.1V4l-.9-.9H9Zm5.1 3H9.9V4.9h4.2v1.2ZM6.98 7.9h10.04l-.94 11.2H7.92L6.98 7.9Z',
    ],
  ],
};

export const vertical: IconDefinition = {
  iconName: 'vertical',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M17.1 19.35V16.5h1.8v3.75l-.9.9h-3.75v-1.8h2.85Zm0-14.7V7.5h1.8V3.75l-.9-.9h-3.75v1.8h2.85Zm-9.45 0V7.5h-1.8V3.75l.9-.9h3.75v1.8H7.65Zm0 14.7V16.5h-1.8v3.75l.9.9h3.75v-1.8H7.65Z',
  ],
};

export const video: IconDefinition = {
  iconName: 'video',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    ['m3.1 6 .9-.9h16l.9.9v12l-.9.9H4l-.9-.9V6Zm1.8.9v10.2h14.2V6.9H4.9Z', 'M14.5 12.427v-.86L11.3 9l-.8.642v4.71l.8.648 3.2-2.573Z'],
  ],
};

export const zoom: IconDefinition = {
  iconName: 'zoom',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M14.96 6.626a5.893 5.893 0 1 0-8.334 8.334 5.893 5.893 0 0 0 8.334-8.334ZM5.353 5.353a7.693 7.693 0 0 1 11.48 10.207l3.803 3.804-1.272 1.272-3.804-3.803A7.694 7.694 0 0 1 5.353 5.353ZM9.85 9.85v-2.1h1.8v2.1h2.1v1.8h-2.1v2.1h-1.8v-2.1h-2.1v-1.8h2.1Z',
  ],
};

export const undo: IconDefinition = {
  iconName: 'undo',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M4.5 9.5L4 9.5c-.55 0-1-.45-1-1L3 4.5c0-.4.24-.77.62-.93s.8-.07 1.1.22l1.02 1.02c3.65-3.6 9.53-3.59 13.16.04c3.65 3.65 3.65 9.55 0 13.2s-9.55 3.65-13.2 0c-.52-.52-.52-1.37 0-1.89s1.37-.52 1.89 0c2.6 2.6 6.83 2.6 9.43 0s2.6-6.83 0-9.43c-2.59-2.59-6.78-2.6-9.39-.04l1.23 1.23c.29.29.37.72.22 1.1s-.52.62-.93.62L4.5 9.5z',
  ],
};

export const reply: IconDefinition = {
  iconName: 'reply',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M7.7 4.3c.43.19.71.62.71 1.1v2.4h4.2c3.64 0 6.6 2.95 6.6 6.6c0 4.25-3.06 6.15-3.76 6.53c-.09.05-.2.07-.3.07c-.41 0-.74-.33-.74-.74c0-.28.16-.54.37-.73c.35-.33.83-1 .83-2.13c0-1.99-1.61-3.6-3.6-3.6h-3.6v2.4c0 .47-.28.9-.71 1.1s-.94.11-1.29-.2l-6-5.4c-.25-.23-.39-.55-.39-.89s.15-.66.4-.89l6-5.4c.35-.32.86-.4 1.29-.2z',
  ],
};

export const upDown: IconDefinition = {
  iconName: 'up-down',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M13.65 3.36c-.23-.26-.53-.36-.65-.36s-.42.1-.65.36l-4.75 5.5c-.31.36-.37.81-.21 1.23s.57.65 1.06.65l2.75 0 0 4.5-2.75 0c-.49 0-.9.23-1.06.65s-.1.87.21 1.23l4.75 5.5c.22.26.52.36.65.36s.42-.1.65-.36l4.75-5.5c.31-.36.37-.81.21-1.23s-.57-.65-1.06-.65l-2.75 0 0-4.5 2.75 0c.49 0 .9-.23 1.06-.65s.1-.87-.21-1.23l-4.75-5.5z',
  ],
};

export const downLong: IconDefinition = {
  iconName: 'down-long',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M12 22c0.5 0 1-0.2 1.4-0.6l5.3-5.6c0.5-0.6 0.7-1.3 0.3-2s-1-1.1-1.7-1.1h-2.8V3.5c0-0.7-0.6-1.2-1.2-1.2h-2.5c-0.7 0-1.2 0.5-1.2 1.2v9.2H6.8c-0.7 0-1.4 0.4-1.7 1.1s-0.2 1.4 0.3 2l5.3 5.6c0.3 0.4 0.8 0.6 1.3 0.6z',
  ],
};

export const upLong: IconDefinition = {
  iconName: 'up-long',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M12 2c-0.5 0-1 0.2-1.4 0.6l-5.3 5.6c-0.5 0.6-0.7 1.3-0.3 2s1 1.1 1.7 1.1h2.8v9.2c0 0.7 0.6 1.2 1.2 1.2h2.5c0.7 0 1.2-0.5 1.2-1.2v-9.2h2.8c0.7 0 1.4-0.4 1.7-1.1s0.2-1.4-0.3-2l-5.3-5.6C13 2.2 12.5 2 12 2z',
  ],
};

export const circleRing: IconDefinition = {
  iconName: 'circle-ring',
  prefix: 'fas',
  icon: [24, 24, [], '', 'M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20zm0-14a4 4 0 1 1 0 8 4 4 0 0 1 0-8z'],
};

export const rightLong: IconDefinition = {
  iconName: 'right-long',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M22 12c0-0.5-0.2-1-0.6-1.4l-5.3-5.6c-0.6-0.5-1.3-0.7-2-0.3s-1.1 1-1.1 1.7v2.8H3.8c-0.7 0-1.2 0.6-1.2 1.2v2.5c0 0.7 0.5 1.2 1.2 1.2h9.2v2.8c0 0.7 0.4 1.4 1.1 1.7s1.4 0.2 2-0.3l5.3-5.6c0.4-0.4 0.6-0.9 0.6-1.4z',
  ],
};

export const leftLong: IconDefinition = {
  iconName: 'left-long',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M2 12c0-0.5 0.2-1 0.6-1.4l5.3-5.6c0.6-0.5 1.3-0.7 2-0.3s1.1 1 1.1 1.7v2.8h9.2c0.7 0 1.2 0.6 1.2 1.2v2.5c0 0.7-0.5 1.2-1.2 1.2h-9.2v2.8c0 0.7-0.4 1.4-1.1 1.7s-1.4 0.2-2-0.3l-5.3-5.6C2.2 13 2 12.5 2 12z',
  ],
};

export const faceSmile: IconDefinition = {
  iconName: 'face-smile',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20zM8.25 14.75c0.7 0.81 1.89 1.67 3.75 1.67s3.05-0.86 3.75-1.67c0.23-0.26 0.62-0.29 0.88-0.07s0.29 0.62 0.07 0.88c-0.91 1.06-2.53 2.04-4.7 2.04s-3.79-0.98-4.7-2.04c-0.22-0.26-0.2-0.66 0.07-0.88s0.66-0.2 0.88 0.07zM9.5 10.5a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zm7.5 0a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0z',
  ],
};

export const faceMeh: IconDefinition = {
  iconName: 'face-meh',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20zM9.5 10.5a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zm7.5 0a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM8 15.5h8c0.41 0 0.75 0.34 0.75 0.75s-0.34 0.75-0.75 0.75H8c-0.41 0-0.75-0.34-0.75-0.75s0.34-0.75 0.75-0.75z',
  ],
};

export const faceFrown: IconDefinition = {
  iconName: 'face-frown',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20zM8.12 16.93c-0.1 0.33-0.45 0.52-0.78 0.41s-0.52-0.45-0.41-0.78C7.51 14.58 9.5 13 12 13s4.49 1.58 5.07 3.56c0.1 0.33-0.08 0.68-0.41 0.78s-0.68-0.08-0.78-0.41C15.39 15.31 13.83 14.17 12 14.17s-3.39 1.14-3.88 2.76zM9.5 10.5a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zm7.5 0a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0z',
  ],
};

export const chevronLeft: IconDefinition = {
  iconName: 'chevron-left',
  prefix: 'fas',
  icon: [24, 24, [], '', 'M17.273 20.025l-8-8.32 8-7.679-1.247-1.298-8.65 8.303-.025 1.273 8.624 8.969 1.298-1.248Z'],
};

export const chevronRight: IconDefinition = {
  iconName: 'chevron-right',
  prefix: 'fas',
  icon: [24, 24, [], '', 'M6.727 3.975l8 8.32-8 7.679 1.247 1.298 8.65-8.303.025-1.273-8.624-8.969-1.298 1.248Z'],
};

export const draw: IconDefinition = {
  iconName: 'draw',
  prefix: 'fas',
  icon: [
    24,
    24,
    [],
    '',
    'M16.4 11.6l0.4-0.4-1.3-1.3-2.3-2.3-1.3-1.3-0.4 0.4-0.9 0.9-6.6 6.6c-0.4 0.4-0.6 0.9-0.8 1.4l-1.3 4.5c-0.1 0.3 0 0.6 0.2 0.9s0.6 0.3 0.9 0.2l4.5-1.3c0.5-0.2 1-0.4 1.4-0.8l6.6-6.6 1.7-1.7zM7 17.9l-0.3 0.9c-0.2 0.1-0.3 0.2-0.5 0.3L3.4 20l0.9-2.9c0.1-0.2 0.2-0.3 0.3-0.5l0.9-0.3v1.2c0 0.3 0.2 0.6 0.6 0.6h1zM14.6 3.7l-0.5 0.5-0.9 0.9-0.4 0.4 1.3 1.3 2.3 2.3 1.3 1.3 0.4-0.4 0.9-0.9 0.5-0.5c0.9-0.9 0.9-2.5 0-3.4l-1.4-1.4c-0.9-0.9-2.5-0.9-3.4 0zm-1.8 6.2l-5.4 5.4c-0.2 0.2-0.6 0.2-0.9 0s-0.2-0.6 0-0.9l5.4-5.4c0.2-0.2 0.6-0.2 0.9 0s0.2 0.6 0 0.9z',
  ],
};

export const iconTypes = {
  addBrief,
  addImage,
  addProduct,
  addVideo,
  audio,
  boundingBox,
  chevronDown,
  chevronUp,
  close,
  collections,
  concepts,
  effects,
  erase,
  favorite,
  favoriteSelected,
  filter,
  headline,
  history,
  horizontal,
  image,
  importIt,
  insert,
  liveImage,
  masking,
  minus,
  moreHorizontal,
  moreVertical,
  pause,
  pencil,
  play,
  plus,
  position,
  products,
  reframe,
  remix,
  remove,
  restyle,
  rotate,
  search,
  select,
  send,
  sidebar,
  sparkle,
  speechBubble,
  speed,
  square,
  styles,
  time,
  trashCan,
  vertical,
  video,
  zoom,
  reply,
  undo,
  chevronLeft,
  chevronRight,
  downLong,
  upLong,
  upDown,
  leftLong,
  rightLong,
  circleRing,
  faceSmile,
  faceMeh,
  faceFrown,
  draw,
};
