import Genaihubbackend, { SubmitFeedbackSentimentInput, SubmitFeedbackSentimentOutput } from '@amzn/genaihub-typescript-client';
import { UseMutationResult } from '@tanstack/react-query';
import { BackendActionBase, BackendActionType, RequestsLookup } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { handleInit, handleError, handleSuccess } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type SubmitFeedbackSentimentAction = BackendActionBase<
  BackendActionType.SUBMIT_FEEDBACK_SENTIMENT,
  SubmitFeedbackSentimentInput,
  SubmitFeedbackSentimentOutput['body']
>;

interface SubmitFeedbackSentimentMutationProps {
  backendClient: Genaihubbackend;
  payload: SubmitFeedbackSentimentInput;
}

export const SubmitFeedbackSentimentMutationConfig = {
  mutationFn: async ({ backendClient, payload }: SubmitFeedbackSentimentMutationProps) => {
    const response = await backendClient.submitFeedbackSentiment(payload);
    return response.body;
  },
};

interface HandleSubmitFeedbackSentimentActionProps {
  action: SubmitFeedbackSentimentAction;
  backendClient: Genaihubbackend;
  submitFeedbackSentimentMutation: UseMutationResult<SubmitFeedbackSentimentOutput['body'], Error, SubmitFeedbackSentimentMutationProps, unknown>;
  requestsLookup: RequestsLookup;
}

export const handleSubmitFeedbackSentimentAction = async ({
  action,
  backendClient,
  submitFeedbackSentimentMutation,
  requestsLookup,
}: HandleSubmitFeedbackSentimentActionProps) => {
  const id = handleInit({ action, requestsLookup });
  try {
    const response = await submitFeedbackSentimentMutation.mutateAsync({
      backendClient,
      payload: action.payload,
    });
    return handleSuccess({ response, action, id, requestsLookup });
  } catch (err) {
    return handleError({ err, action, id, requestsLookup });
  }
};
