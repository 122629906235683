import Genaihubbackend, { ASINMetadata, Asset, WorkflowId } from '@amzn/genaihub-typescript-client';
import themesConfig from 'src/components/editor/UiContols/uiGeneratorControls/themes.json';
import { EffectLookupByValue } from 'src/components/pages/studio/effectSettings/effectSettings';
import { ContentItem, getValidFormatForStudioInputSettings, isImportedMediaWorkflow } from 'src/components/pages/studio/StudioContext';
import { urlToFile } from 'src/components/utils/base64Encode';
import themeIcons from 'src/config/themes/themesDropdownList';
import { US_MARKETPLACE_ID } from 'src/constants';
import { sanitiseText } from 'src/helpers';
import { upsertProduct } from 'src/v2/redux/slices/product/productSlice';
import { AsinProduct } from 'src/v2/redux/slices/product/productSlice.types';
import { AppDispatch } from 'src/v2/redux/store';
import { ASINItem, AspectRatio, AssetTypeEnum, SelectedEffectsMap } from 'src/v2/types';
import { createAsinProduct } from 'src/v2/utils/Product.utils';
import { getFeedContentTypeByWorkflowId } from 'src/v2/utils/utils';

/**
 * DEPRECATION NOTICE:
 * After State Managmeent becomes the control, the logic in this file will be deprecated by the components and logic in the following:
 * - src/v2/contexts/feed/
 * - src/v2/redux/slices/feed/
 */

export const fetchAsins = async (
  genAIBackendClient: Genaihubbackend,
  dispatch: AppDispatch,
  asinProducts: AsinProduct[],
  assets: Asset[],
): Promise<Record<string, ASINMetadata>> => {
  // Multiple product could have asins nested in products
  const allAsin = assets.flatMap(({ asin, products = [] }) => [asin, ...products.map((product) => product.asin)]);
  const uniqueAsins = [...new Set(allAsin.filter((asin) => asin && asin !== ' '))] as string[];
  const asinMetadata = await Promise.all(
    uniqueAsins.map(async (asin) => {
      try {
        const fetchedAsin = (
          await genAIBackendClient.retrieveASINMetadataByASINId({
            asinId: asin,
            marketplaceId: US_MARKETPLACE_ID,
          })
        ).body;

        return {
          key: asin,
          value: fetchedAsin,
        };
      } catch (e: unknown) {
        console.error(`Failed to fetch asin ${asin} with error: ${e}`);
      }

      return {
        key: asin,
        value: {},
      };
    }),
  );

  // if fetched ASIN does not exist, add it to the redux product state. Do not overwrite exist ASIN product state.
  for (const metadata of asinMetadata) {
    if (!asinProducts.find((product) => product.id === metadata.key)) {
      dispatch(
        upsertProduct(
          createAsinProduct({
            asin: metadata.key,
            metadata: metadata.value,
          }),
        ),
      );
    }
  }

  const asinMap: { [key: string]: ASINMetadata } = asinMetadata.reduce(
    (obj, metadata) => ({
      ...obj,
      [metadata.key]: metadata.value,
    }),
    {},
  );

  return asinMap;
};

export const convertFeed = async (
  genAIBackendClient: Genaihubbackend,
  assets: Asset[],
  asinMap: Record<string, ASINMetadata>,
): Promise<ContentItem[]> => {
  return await Promise.all(
    assets.map(async (item) => {
      let style = undefined;
      if (item.theme) {
        const matchingTheme = themesConfig.find((theme) => theme.controlValue === item.theme || theme.customPrompt === item.theme);
        if (matchingTheme) {
          style = {
            icon: themeIcons[matchingTheme.src as keyof typeof themeIcons],
            value: matchingTheme.controlValue,
            label: matchingTheme.controlLabel,
            prompt: matchingTheme.customPrompt,
          };
        }
      }

      const effects: SelectedEffectsMap = new Map();
      let newPrompt = item.inputPrompt || '';
      if (newPrompt) {
        for (const [key, value] of Object.entries(EffectLookupByValue)) {
          const sanitisedKey = sanitiseText(key);
          if (newPrompt.includes(sanitisedKey)) {
            // Legacy - effects were added after the prompt
            newPrompt = newPrompt.replace(`, ${sanitisedKey}`, '');
            // Current - effects are added before the prompt
            newPrompt = newPrompt.replace(`${sanitisedKey}, `, '');
            effects.set(value.effectType, value.effectOption);
          }
        }
        newPrompt = newPrompt.trim();
      }

      let asinItem: ASINItem = {} as ASINItem;
      const hasAsin = item.asin && item.asin !== ' ';
      if (hasAsin) {
        asinItem = {
          ...asinItem,
          asin: item.asin!,
          metadata: asinMap[item.asin!],
        };
      }

      if (item.inputImageUri) {
        try {
          const file = await urlToFile(item.inputImageUri);
          const blobUrl = URL.createObjectURL(file);

          if (hasAsin) {
            asinItem = {
              ...asinItem,
              customProductImageUrl: blobUrl,
              selectedImageIndex: -1,
            };
          } else {
            asinItem = {
              customImage: {
                url: blobUrl,
                name: file.name,
                size: file.size,
              },
            } as ASINItem;
          }
        } catch (e: unknown) {
          console.error(`Failed to fetch image uri ${item.inputImageUri} with error: ${e}`);
        }
      }

      const asin = Object.keys(asinItem).length ? asinItem : undefined;

      let studioInputSettings;
      const assignStudioInputSettings = !isImportedMediaWorkflow(item.workflowId) && (asin || newPrompt);
      if (assignStudioInputSettings) {
        studioInputSettings = {
          effects,
          style,
          asin,
          prompt: newPrompt,
          // need to update type of item here once 'aspectRatio' is added to Asset in the back-end
          format: getValidFormatForStudioInputSettings(item.aspectRatio as AspectRatio),
          advanced: undefined,
          enablePromptRewriting: false,
          multiProduct: item.products?.filter((product) => product.asin),
        };
      }

      // const contentFile = await urlToFile(item.uri, 'image/png');
      return {
        originalUri: item.uri,
        // Defered loading of the image to ContentTile
        // content: URL.createObjectURL(contentFile),
        referenceId: item.id,
        loading: false,
        contentType: getFeedContentTypeByWorkflowId(item.workflowId),
        contentHeadline: newPrompt,
        aspectRatio: item.aspectRatio ? item.aspectRatio : AspectRatio.HORIZONTAL_191_TO_1,
        studioInputSettings,
        workflowId: item.workflowId as WorkflowId,
        batchId: item.batchId,
        imageId: item.imageId,
        assetType: (item.assetType as AssetTypeEnum) ?? AssetTypeEnum.IMAGE,
        timestamp: item.timestamp,
        products: item.products,
      };
    }),
  );
};
