import { handleSubmitWorkflowByIdDispatch } from 'src/v2/contexts/assetGeneration/utils/common/BackendDispatchContext.utils';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';

export async function submitWorkflowMiddleware({
  backendDispatchContext,
  payload,
}: {
  backendDispatchContext: BackendDispatchContextType;
  payload: StronglyTypedSubmitWorkflowByIdInput;
}) {
  try {
    const result = await handleSubmitWorkflowByIdDispatch({
      backendDispatchContext,
      payload,
    });
    return result.batchId;
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    } else {
      console.error(err);
      throw new Error('Asset generation failed while submitting a workflow');
    }
  }
}
