import { memo, useMemo } from 'react';
import { computeFeedItemLayout } from 'src/v2/components/studio/feed/Feed.utils';
import { AssetGeneration, AssetGenerationStatus } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice.types';
import { WorkflowIdEnum } from 'src/v2/types';
import { getAspectRatioFromUserInputs } from 'src/v2/utils/FrontendAssetUtils';
import { FeedAssetGenerationPlaceholder, FeedAssetGenerationPlaceholdersGroupItem } from './FeedAssetGenerationPlaceholdersGroupItem';

export const FeedAssetGenerationPlaceholdersGroup = memo(
  ({ feedAssetGenerations, numFeedItemsPerRow }: { feedAssetGenerations: AssetGeneration[]; numFeedItemsPerRow: number }) => {
    const memoizedFeedAssetGenerationPlaceholders = useMemo(() => {
      const placeholders: FeedAssetGenerationPlaceholder[] = [];
      feedAssetGenerations.forEach((feedAssetGeneration) => {
        if (feedAssetGeneration.status !== AssetGenerationStatus.PENDING) return;
        let count = 0;
        feedAssetGeneration.action.generationConfigs?.forEach((generationConfig) => {
          const { userInputs, outputCount, workflowId } = generationConfig;
          switch (workflowId) {
            case WorkflowIdEnum.IMPORT_IMAGE: {
              placeholders.push({
                id: `feed-asset-generation-placeholder-${feedAssetGeneration.id}`,
                aspectRatio: userInputs.imageToImport.aspectRatio,
                assetGenerationId: feedAssetGeneration.id,
                url: userInputs.imageToImport.url,
                workflowId,
              });
              break;
            }
            default: {
              const aspectRatio = getAspectRatioFromUserInputs({ userInputs });
              for (let i = 0; i < outputCount; i++) {
                placeholders.push({
                  id: `feed-asset-generation-placeholder-${feedAssetGeneration.id}-${count++}`,
                  aspectRatio,
                  workflowId,
                });
              }
              break;
            }
          }
        });
      });
      return placeholders;
    }, [feedAssetGenerations]);

    const feedAssetGenerationPlaceholderGroupItems = computeFeedItemLayout({
      items: memoizedFeedAssetGenerationPlaceholders,
      numFeedItemsPerRow,
      createFeedItem: ({ item: placeholder, style }) => {
        return <FeedAssetGenerationPlaceholdersGroupItem key={placeholder.id} placeholder={placeholder} style={style} />;
      },
    });

    return <>{feedAssetGenerationPlaceholderGroupItems}</>;
  },
);
FeedAssetGenerationPlaceholdersGroup.displayName = 'FeedAssetGenerationPlaceholdersGroup';
