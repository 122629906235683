import { ControlValue, ControlValues } from '@amzn/genaihub-typescript-client';

export default function guideHelper(
  controlValues: ControlValues | undefined,
  activateGuide: boolean,
  callback: (controlValue: ControlValue | undefined) => void,
) {
  if (activateGuide) {
    const cv = controlValues?.reduce((acc: ControlValue, cv: ControlValue) => {
      if (cv.guide) {
        acc = { ...cv };
      }
      return acc;
    }, {});
    if (cv?.guide) {
      callback(cv);
    } else {
      callback(undefined);
    }
  }
}
