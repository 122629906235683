import { urlToFile } from 'src/components/utils/base64Encode';
import { AssetTypeEnum } from 'src/v2/types';

export async function convertToBlobAndDownload(url: string) {
  if (!url) return;
  const file = await urlToFile(url);
  const urlObj = new URL(url);
  const extension = urlObj.pathname.split('.').pop();
  const downloadProps = { url: URL.createObjectURL(file), fileName: `ailab-${crypto.randomUUID()}.${extension}` };
  downloadFromUrl(downloadProps);
}

interface DownloadFromUrlProps {
  url: string;
  fileName?: string;
}

export function downloadFromUrl(props: DownloadFromUrlProps) {
  if (!props.url) return;
  const link = document.createElement('a');
  link.download = props.fileName || props.url;
  link.href = props.url;
  link.click();
}

export function getFileExtension({ url, assetType }: { url: string; assetType?: AssetTypeEnum }) {
  try {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
    const extension = filename.includes('.') ? filename.substring(filename.lastIndexOf('.') + 1) : '';
    if (extension) return extension;
    if (assetType === AssetTypeEnum.IMAGE) return 'png';
    if (assetType === AssetTypeEnum.VIDEO) return 'mp4';
    return '';
  } catch (error) {
    // Handle cases where the URL is invalid or doesn't have an extension
    return '';
  }
}

interface OpenNewTabProps {
  url: string;
}

export function openNewTab(props: OpenNewTabProps) {
  if (!props.url) return;
  const link = document.createElement('a');
  link.href = props.url;
  link.target = '_blank';
  link.click();
}
