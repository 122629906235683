import { Heading, Menu, MenuButtonTrigger, MenuItemButton, MenuItemGroup, Text } from '@amzn/storm-ui';
import { useCallback, useEffect, useState } from 'react';
import { GenerateOptionType } from 'src/v2/types';
import styled from 'styled-components';
import { GenerateOptions } from '../WidgetContainer';

const StyledMenuButtonTrigger = styled(MenuButtonTrigger)`
  display: flex;
  align-items: center;
  margin-left: -12px;
  padding: 8px 12px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: transparent;
  border: none;
  cursor: pointer;

  && h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  i {
    color: var(--text-primary, 'unset');
  }

  :hover {
    background-color: var(--surface-tertiary, 'unset');
    && h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    i {
      color: var(--text-primary, 'unset');
    }
  }
`;

const StyledMenuItemButton = styled(MenuItemButton)`
  padding: 8px 16px;
  font-size: 14px;
  color: var(--text-primary, #161d26);
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  &&:hover {
    color: #ffffff;
    background-color: #161d26;
  }
`;

interface CreateContentDropdownProps {
  onCreateContentClick: (generationType: GenerateOptionType) => void;
  generateOptions: GenerateOptions;
  selectedGenerateOption: GenerateOptionType | undefined;
}

const CreateContentDropdown = ({ onCreateContentClick, generateOptions, selectedGenerateOption }: CreateContentDropdownProps) => {
  const generateProductContent = generateOptions[GenerateOptionType.GENERATE_PRODUCT_IMAGES];
  const generateLifestyleContent = generateOptions[GenerateOptionType.GENERATE_LIFESTYLE_IMAGES];
  const generateBothProductAndLifestyleContent = generateProductContent && generateLifestyleContent;
  const generateNeitherProductNorLifestyleContent = !generateProductContent && !generateLifestyleContent;

  const dropdownItems = {
    product: (
      <>
        <Heading size="medium">Create</Heading> <Text size="large">product images</Text>
      </>
    ),
    lifestyle: (
      <>
        <Heading size="medium">Create</Heading> <Text size="large">lifestyle images</Text>
      </>
    ),
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<React.ReactNode>();

  useEffect(() => {
    if (selectedGenerateOption) {
      if (selectedGenerateOption === GenerateOptionType.GENERATE_PRODUCT_IMAGES) {
        setSelectedOption(dropdownItems.product);
      }
      if (selectedGenerateOption === GenerateOptionType.GENERATE_LIFESTYLE_IMAGES) {
        setSelectedOption(dropdownItems.lifestyle);
      }
      return;
    }

    if (generateBothProductAndLifestyleContent) {
      setSelectedOption(dropdownItems.product);
    }

    if (generateNeitherProductNorLifestyleContent) {
      setSelectedOption(dropdownItems.lifestyle);
    }

    if (generateOptions[GenerateOptionType.GENERATE_PRODUCT_IMAGES]) {
      setSelectedOption(dropdownItems.product);
    }

    if (generateOptions[GenerateOptionType.GENERATE_LIFESTYLE_IMAGES]) {
      setSelectedOption(dropdownItems.lifestyle);
    }
  }, [generateOptions]);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onMenuItemClick = useCallback((option: GenerateOptionType) => {
    if (option === GenerateOptionType.GENERATE_PRODUCT_IMAGES) {
      setSelectedOption(dropdownItems.product);
    }
    if (option === GenerateOptionType.GENERATE_LIFESTYLE_IMAGES) {
      setSelectedOption(dropdownItems.lifestyle);
    }
    onCreateContentClick(option);
    setIsOpen(false);
  }, []);

  return (
    <div data-testid="dropdown-wrapper">
      <Menu
        id="create-images-menu"
        onClick={toggleMenu}
        onClose={closeMenu}
        isOpen={isOpen}
        data-testid="dropdown-menu"
        trigger={<StyledMenuButtonTrigger data-testid="dropdown-trigger">{selectedOption}</StyledMenuButtonTrigger>}
      >
        <MenuItemGroup data-testid="menu-item-group">
          <StyledMenuItemButton
            data-testid="product-images-option"
            disabled={!generateProductContent}
            onClick={() => onMenuItemClick(GenerateOptionType.GENERATE_PRODUCT_IMAGES)}
            aria-selected={selectedOption === dropdownItems.product}
          >
            {GenerateOptionType.GENERATE_PRODUCT_IMAGES}
          </StyledMenuItemButton>
          <StyledMenuItemButton
            data-testid="lifestyle-images-option"
            disabled={!generateLifestyleContent}
            onClick={() => onMenuItemClick(GenerateOptionType.GENERATE_LIFESTYLE_IMAGES)}
            aria-selected={selectedOption === dropdownItems.lifestyle}
          >
            {GenerateOptionType.GENERATE_LIFESTYLE_IMAGES}
          </StyledMenuItemButton>
        </MenuItemGroup>
      </Menu>
    </div>
  );
};

export default CreateContentDropdown;
