import { retrieveWorkflowResultMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/RetrieveWorkflowResult.middleware';
import { submitWorkflowMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/SubmitWorkflow.middleware';
import { ImageEditorGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { buildAssetsFromBatchResult } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { ImageEditorWorkflowOptions, TaskType } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { AssetTypeEnum, EditedImageAsset, EditMode } from 'src/v2/types';

/**
 * A helper function for the primary handler, handleImageEditorGeneration. This function handles the workflow submission and result.
 */
export async function handleImageEditorGenerationSubmission({
  backendDispatchContext,
  generationConfig,
  inputImageReferenceId,
  maskImageReferenceId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: ImageEditorGenerationConfig;
  inputImageReferenceId: string;
  maskImageReferenceId: string;
}): Promise<EditedImageAsset[]> {
  const { entityId, outputCount, studioRequest, userInputs, workflowId } = generationConfig;
  const { editMode, inputImageAsset } = userInputs;

  const taskType = editMode === EditMode.ADD ? TaskType.INPAINTING : TaskType.REMOVAL;
  const textPrompt = editMode === EditMode.ADD ? userInputs.textPrompt : undefined;

  // STEP - Submit workflow for IMAGE_EDITOR
  const workflowOptions: ImageEditorWorkflowOptions = {
    imageCount: outputCount,
    inputImageRef: inputImageReferenceId,
    maskRef: maskImageReferenceId,
    seed: undefined,
    taskType,
    textPrompt,
    workflowId,
  };
  const submitWorkflowPayload: StronglyTypedSubmitWorkflowByIdInput = {
    body: {
      workflowOptions,
    },
    studioRequest,
    workflowId,
  };
  const batchId = await submitWorkflowMiddleware({ backendDispatchContext, payload: submitWorkflowPayload });

  // STEP - Retrieve result for IMAGE_EDITOR
  return await retrieveWorkflowResultMiddleware<EditedImageAsset[]>({
    backendDispatchContext,
    payload: {
      batchId,
      entityId,
      studioRequest,
      workflowId,
    },
    onSuccessHandler: ({ response }) => {
      return buildAssetsFromBatchResult<EditedImageAsset, ImageEditorGenerationConfig['userInputs'], ImageEditorWorkflowOptions>({
        aspectRatio: inputImageAsset.aspectRatio,
        assetType: AssetTypeEnum.IMAGE,
        batchResult: response,
        userInputs,
        workflowOptions,
      });
    },
  });
}
