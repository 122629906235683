import { createContext, useContext, useMemo, useRef, useState, useLayoutEffect } from 'react';
import { AppContext } from 'src/AppContext';
import Chat from 'src/components/assistant/components/chat';
import ChatsList from 'src/components/assistant/components/chatsList';
import { useConversationsQuery } from 'src/components/assistant/queries/conversationsList';
import { CHAT_THEME } from 'src/components/assistant/theme';
import { useContainerDimensions } from 'src/components/utils/ContainerDimensions';
import styled from 'styled-components';

const ChatPage = styled.div<{ headerHeight: number; pageMargin: number }>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
  margin-inline: ${({ pageMargin }) => pageMargin}px;
  width: calc(100% - ${({ pageMargin }) => pageMargin * 2}px);
  overflow-y: visible;
  overflow-x: hidden;
  position: relative;
`;

interface ConversationContextType {
  isSideNavOpen: boolean;
  setIsSideNavOpen: (value: boolean) => void;
  sideNavWidth: number;
  setSideNavWidth: (width: number) => void;
  messageInputHeight: number;
  setMessageInputHeight: (height: number) => void;
  compactScreen: boolean;
}

export const ConversationContext = createContext<ConversationContextType>({
  isSideNavOpen: false,
  compactScreen: false,
  setIsSideNavOpen: () => {},
  sideNavWidth: 0,
  setSideNavWidth: () => {},
  messageInputHeight: 0,
  setMessageInputHeight: () => {},
});

const ChatPageComponent = () => {
  const { userDetails, headerDimensions } = useContext(AppContext);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [sideNavWidth, setSideNavWidth] = useState(0);
  const [messageInputHeight, setMessageInputHeight] = useState(110);
  const [containerMargin, setContainerMargin] = useState(16);
  const conversationsListQuery = useConversationsQuery(userDetails?.alias);
  const chatPageRef = useRef<HTMLDivElement>(null);
  // while the results from the ListConversations API are sorted, we need to keep this so
  //  when one of the conversations updatedAt value changes the UI will reflect that
  const chats = useMemo(
    () =>
      conversationsListQuery.data?.sort((a, b) => {
        if (a.updatedAt && b.updatedAt) {
          return b.updatedAt.getTime() - a.updatedAt.getTime();
        }
        return -1;
      }) || [],
    [conversationsListQuery.data],
  );

  useLayoutEffect(() => {
    const updateMargin = () => {
      setContainerMargin(window.innerWidth < 2000 ? 16 : Math.max(0, window.innerWidth - 2000) * 0.5);
    };
    window.addEventListener('resize', updateMargin);
    updateMargin();
    return () => window.removeEventListener('resize', updateMargin);
  }, []);

  const { width: chatPageWidth } = useContainerDimensions(chatPageRef);
  const compactScreen = chatPageWidth <= CHAT_THEME.compactScreenBreakPoint;

  return (
    <ConversationContext.Provider
      value={{ isSideNavOpen, setIsSideNavOpen, compactScreen, sideNavWidth, setSideNavWidth, messageInputHeight, setMessageInputHeight }}
    >
      <ChatPage
        ref={chatPageRef}
        headerHeight={headerDimensions.navHeaderHeight + headerDimensions.pageHeaderHeight}
        pageMargin={window.innerWidth < 2000 ? containerMargin : 0}
      >
        <ChatsList chats={chats} isLoading={conversationsListQuery.isLoading} error={!!conversationsListQuery.error} pageMargin={containerMargin} />
        <Chat pageMargin={containerMargin} />
      </ChatPage>
    </ConversationContext.Provider>
  );
};

export default ChatPageComponent;
