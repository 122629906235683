import { ASINMetadata, ControlValue } from '@amzn/genaihub-typescript-client';
import { InlineAlert, Card, Input, InlineAlertProps } from '@amzn/storm-ui';
import { useContext, useEffect, useState } from 'react';
import Spinner from 'src/components/common/storm/Spinner';
import { EditorContextP1, EditorContextStateP1 } from 'src/components/editor/EditorContext';
import { ControlComponentProps } from 'src/components/editor/UiContols/uiGeneratorControls/controlComponentTypes';
import { FAILURE_MESSAGE, SubmitAsinFailureNotification } from 'src/components/snackbar/notifications/ImageGenerationNotifications';
import { urlToFile } from 'src/components/utils/base64Encode';
import guideHelper from 'src/components/utils/guideHelper';
import removePunctuationAndSpecialChars from 'src/components/utils/removePunctuationAndSpecialChars';
import { uploadImage } from 'src/components/utils/uploadImage';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { logger } from 'src/logger';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { CategoryEnum } from 'src/v2/types';
import styled from 'styled-components';
import ControlLabel from './UI/ControlLabel';
import ProductPreview from './UI/ProductPreview';
import cssStyles from './uiform.module.scss';

const alerts: Record<string, InlineAlertProps> = {
  blank: { variant: 'error', message: FAILURE_MESSAGE.BLANK_ASIN },
  notValid: { variant: 'error', message: FAILURE_MESSAGE.NO_ASIN },
  over10: { variant: 'warning', message: FAILURE_MESSAGE.INVALID_ASIN },
};

const AsinPickerView = styled.div`
  text-align: left;
  & label {
    color: var(--text-primary, #000000);
  }
`;

const AsinPicker = ({ control }: ControlComponentProps) => {
  const context: EditorContextStateP1 = useContext(EditorContextP1);
  const genAIBackendClient = useAIBackendHubClient();
  const [asin, setAsin] = useState<string | undefined>();
  const [asinFormValue, setAsinFormValue] = useState<string>();

  const [alert, setAlert] = useState<InlineAlertProps>();
  const [lockAsin, setLockAsin] = useState<boolean>(false);
  const [loadState, setLoadState] = useState<boolean>(false);
  const [asinPreview, setAsinPreview] = useState<ASINMetadata>({});
  const { addFailureNotification } = useNotificationActions();

  useEffect(() => {
    context.setDisableControl(true);
  }, []);

  useEffect(() => {
    guideHelper(control.controlValues, context.activateGuide, guideCallback);
  }, [context.activateGuide]);

  const guideCallback = (guide: ControlValue | undefined) => {
    if (guide && guide.guideCustomValue) {
      setAsin(guide.guideCustomValue.toUpperCase());
      setAsinFormValue(guide.guideCustomValue.toUpperCase());
    }
  };

  const handleSubmitAsin = () => {
    setLockAsin(true);
    setAlert(undefined);
    submitAsin();
  };

  useEffect(() => {
    if (asin?.length == 10) {
      setAsinPreview({});
      handleSubmitAsin();
    }
  }, [asin]);

  useEffect(() => {
    // skipping this set ,as it there is no need to reconfirm the asin choice, this may change in future
    /* if (context.activateGuide && Object.keys(asinPreview).length > 0) {
      publishAsin();
    }*/

    if (Object.keys(asinPreview).length > 0) publishAsin();
    // in the event logic changes. simply re-instate the above condition
  }, [asinPreview]);

  const submitAsin = async () => {
    const request = {
      asinId: asin || '',
      marketplaceId: 'ATVPDKIKX0DER',
    };
    try {
      setLoadState(true);
      const response = await genAIBackendClient.retrieveASINMetadataByASINId(request);
      setAsinPreview(response.body);
      setLockAsin(false);
    } catch (e) {
      logger.error(`Error retreiving ASIN metadata by ASIN Id ${e}`);
      setAlert(alerts.notValid);
      setLockAsin(false);
      setAsin(undefined);
      addFailureNotification({ SnackbarContent: SubmitAsinFailureNotification });
    }
  };

  const publishAsin = async () => {
    const workflowOptions: Record<string, object> = { image_count: { value: 4 } };
    workflowOptions[control.controlName] = { value: asin };
    if (asinPreview.title) workflowOptions.title = { value: removePunctuationAndSpecialChars(asinPreview.title) };
    if (asinPreview.featureBullets) workflowOptions.feature_bullets = { value: asinPreview.featureBullets };
    if (asin) workflowOptions.asin = { value: asin?.toUpperCase() };
    if (asinPreview.asinCategory) workflowOptions.productType = { value: asinPreview.asinCategory };
    const highResUri = asinPreview.mediaCentralAssets ? asinPreview.mediaCentralAssets[0].highResUri : '';
    const lowResUri = asinPreview.mediaCentralAssets ? asinPreview.mediaCentralAssets[0].lowResUri : '';

    if (asinPreview.mediaCentralAssets !== undefined && asinPreview.mediaCentralAssets.length > 0 && (highResUri !== '' || lowResUri !== '')) {
      const asinImageFile = await urlToFile((highResUri || lowResUri) ?? '');
      const asinImageReferenceId = await uploadImage({
        file: asinImageFile,
        backendClient: genAIBackendClient,
        contentCategory: CategoryEnum.PRODUCT_IMAGE,
      });
      workflowOptions.product_image = { value: asinImageReferenceId };
    } else {
      console.error('No mediaCentralAssets in asin preview');
    }
    context.setWorkFlowOptions({ ...context.workflowOptions, ...workflowOptions });
    context.setAsinMetaData(asinPreview);
    context.setDisableControl(false);
    setLoadState(false);
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val: string = event.target.value;
    if (event.target.value.length > 10) {
      setAlert(alerts.notValid);
      setLockAsin(false);
    }
    if (event.target.value.length == 10) {
      setAsin(val.toUpperCase());
    }
    setAsinFormValue(val.toUpperCase());
  };

  const handlePreviewCallback = () => {
    context.setAsinMetaData({});
    setAsinPreview({});
    setAlert(undefined);
    const controlValuesCopy = { ...context.workflowOptions };
    delete controlValuesCopy[control.controlName];
    if (controlValuesCopy.image_count) delete controlValuesCopy.image_count;
    if (controlValuesCopy.title) delete controlValuesCopy.title;
    if (controlValuesCopy.lowResUri) delete controlValuesCopy.lowResUri;
    if (controlValuesCopy.highResUri) delete controlValuesCopy.highResUri;
    if (controlValuesCopy.product_image) delete controlValuesCopy.product_image;
    context.setWorkFlowOptions({ ...controlValuesCopy });

    context.setDisableControl(true);
    context.setActivateGuide(false);
    setAsin(undefined);
    setAsinFormValue('');

    // clear text prompt
    const controlValues = context.workflowOptions;
    const controlData = { prompt: { value: '' } };
    context.setWorkFlowOptions({ ...controlValues, ...controlData });
  };

  const ShowPostSubmit = () => (
    <>
      <Card
        paddingBlockEnd="small"
        paddingBlockStart="small"
        paddingInlineEnd="small"
        paddingInlineStart="small"
        style={{ minHeight: '80px', maxWidth: '100%', marginTop: '5px' }}
      >
        {alert ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <InlineAlert variant={alert.variant} message={alert.message} />
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <Spinner size="lg" />
          </div>
        )}
      </Card>
    </>
  );

  return (
    <>
      {context.asinMetaData?.title && context.asinMetaData?.mediaCentralAssets ? (
        <>
          <ControlLabel title={control.controlLabel} />
          <ProductPreview
            title={context.asinMetaData?.title}
            subTitle={'ASIN:' + asin}
            url={context.asinMetaData?.mediaCentralAssets[0]?.lowResUri || context.asinMetaData?.mediaCentralAssets[0]?.highResUri || ''}
            callback={handlePreviewCallback}
          ></ProductPreview>
        </>
      ) : (
        <>
          <AsinPickerView>
            <Input
              className={cssStyles.inputFieldStyle}
              disabled={lockAsin}
              id="textarea-basic"
              value={asinFormValue}
              label={control.controlLabel}
              placeholder={control.description}
              onChange={handleOnChange}
              fullWidth
              style={{ border: 'none', color: 'var(--text-primary, "unset")' }}
            />
          </AsinPickerView>
          {(loadState || alert) && <ShowPostSubmit />}
        </>
      )}
    </>
  );
};

export default AsinPicker;
