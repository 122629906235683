import { Tools, Controls, WorkflowListResponseDataItemControlsItem, ControlValues } from '@amzn/genaihub-typescript-client';
import { Card, Section, Text } from '@amzn/storm-ui';
import { useState, useEffect, useContext, Fragment, useRef } from 'react';
import { AppContext } from 'src/AppContext';
import { EditorContextP1 } from 'src/components/editor/EditorContext';
import ModuleFeedback from 'src/components/editor/ModuleFeedback';
import * as ControlComponents from 'src/components/editor/UiContols/uiGeneratorControls';
import { TextResults, WorkflowResult } from 'src/components/editor/WorkflowResults';
import * as notifications from 'src/components/snackbar/notifications/ImageGenerationNotifications';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import { getAllQueryStringParams } from 'src/components/utils/getQueryStringParams';
import { runBeforeJobSubmission } from 'src/components/utils/workflowHelpers';
import { WorkflowSubmission } from 'src/components/utils/WorkflowSubmission';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { logger } from 'src/logger';
import { PromptType, TaskType } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { AssetTypeEnum, JobStatusEnum, WorkflowIdEnum } from 'src/v2/types';
import styled from 'styled-components';
import { StyleSettingPlayground } from './components/StyleControls/StyleSetting';
import { AnimatedButton } from './uiGeneratorControls/storm/UI/AnimatedButton';

export interface ControlPanelProps {
  controls: Controls;
  appendGeneratedResults: (results: WorkflowResult) => void;
  appendGeneratedTextResults: (results: TextResults) => void;
  bulkUpdateCallback: (controls: object) => void;
  type: string;
}

const ControlPanelView = styled(Section)`
  background-color: transparent;
  color: #232f3f;
  justify-content: flex-start;
  width: inherit;
  min-height: 60vh;
  max-height: 72vh;
  padding: 0px;
  position: relative;
`;

const ControlTitle = styled(Text)`
  font-family: 'Ember Modern Display Standard';
  letter-spacing: 1px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 800;
  align-self: start;
  margin-bottom: 12px;
  color: var(--text-primary, #000000);
`;

const ControlDescription = styled(Text)`
  font-family: 'Amazon Ember';
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.01em;
  align-self: start;
  text-align: left;
  color: var(--text-primary, #000000);
`;

const ControlPanel = ({ controls, appendGeneratedResults, appendGeneratedTextResults, bulkUpdateCallback, ...rest }: ControlPanelProps) => {
  const appContext = useContext(AppContext);
  const { metrics, selectedTool, tools } = appContext;
  const editorContext = useContext(EditorContextP1);
  const [workflowState, setWorkflowState] = useState<string>(WorkflowSubmission.IDLE);
  const currentWorkflow = useRef<WorkflowSubmission | null>();
  const isMounted = useRef<boolean>(false);
  const controlObject = useRef(controls);
  const { addFailureNotification } = useNotificationActions();

  const [isThemeControlPresent, setIsThemeControlPresent] = useState<boolean>(false);
  const selectedToolWorkflowEnum: WorkflowIdEnum = WorkflowIdEnum[selectedTool as keyof typeof WorkflowIdEnum];

  useEffect(() => {
    // TODO: Override controls, remove this bodge.
    if (controlObject.current) {
      const styleControlIndex = controlObject.current.findIndex((control: { controlName: string }) => control.controlName == 'theme');
      const filteredControls = controlObject.current.filter((control) => control !== controls[styleControlIndex]);
      const restyleSelected = selectedToolWorkflowEnum === WorkflowIdEnum.IMAGE_THEMING;

      controlObject.current = filteredControls;

      setIsThemeControlPresent(styleControlIndex && restyleSelected ? true : false);
    }
  }, []);

  const [controlValidation, setControlValidation] = useState<string[]>([]);
  const [validated, setValidated] = useState<boolean>(false);
  const [controlTitle, setControlTitle] = useState<string>('');
  const [toolDesc, setToolDesc] = useState<string>('');
  const [runNextJob, setRunNextJob] = useState<boolean>(false);
  let controlValues = editorContext.workflowOptions;
  const client = useAIBackendHubClient();
  const urlProps = getAllQueryStringParams();
  const [autoGenerate, setAutoGenerate] = useState(false);
  const [generatedPrompt, setGeneratedPrompt] = useState<string>();
  const [generationTheme, setGenerationTheme] = useState<string>('no_theme');

  const onThemeSelectedHandler = (theme: string) => {
    setGenerationTheme(theme);
  };

  useEffect(() => {
    setControlTitle(tools.filter((tool: Tools) => tool.type === selectedTool).map((item: Tools) => item.title)?.[0]);
    setToolDesc(tools.filter((tool: Tools) => tool.type === selectedTool).map((item: Tools) => item.description.split('|')?.[1])?.[0]);
    isMounted.current = true;

    if (urlProps.generate && urlProps.prompt) {
      const controlData = { prompt: { value: urlProps.prompt } };
      batchStateUpdate(controlData, bulkUpdateCallback, 500);
      setAutoGenerate(true);
    }

    // Unmount clean up for workflows
    return () => {
      // Mark as unmounted so no workflows continue
      isMounted.current = false;

      // If a workflow is currently running; abandon it
      if (currentWorkflow.current?.jobInProgress) {
        currentWorkflow.current.abandon();
      }
    };
  }, []);

  const addControlValidation = (controlName: string) => {
    const index = controlObject.current.findIndex((obj: WorkflowListResponseDataItemControlsItem) => obj.controlName === controlName);
    const control = controlObject.current[index];
    if (control) {
      control.mandatory = true;
    }
  };

  const removeControlValidation = (controlName: string) => {
    const index = controlObject.current.findIndex((obj: WorkflowListResponseDataItemControlsItem) => obj.controlName === controlName);
    const control = controlObject.current[index];
    if (control) {
      control.mandatory = false;
    }
  };

  useEffect(() => {
    validateControls();
  }, [controlValues]);

  useEffect(() => {
    if (workflowState !== WorkflowSubmission.RUNNING) {
      if (controlValidation.length) {
        setValidated(false);
      } else {
        setValidated(true);
        autoGenerateWorkflow();
      }
    } else {
      setValidated(false);
    }
  }, [controlValidation, workflowState]);

  const autoGenerateWorkflow = () => {
    // generate called from outside component to automatically invoke job
    if (autoGenerate) {
      setAutoGenerate(false);
      clickHandler();
    }
  };

  const selectControlComponent = (controlType: string) => {
    if (ControlComponents.isValidComponentName(controlType)) {
      return ControlComponents[controlType];
    }
    return undefined;
  };

  const createControlCard = (item: WorkflowListResponseDataItemControlsItem, index: number) => {
    if (!item) return <>/</>;

    const ControlComponent = selectControlComponent(item.controlType);
    return (
      <Fragment key={index}>
        {item.visible && (
          <>
            <Card
              paddingBlockEnd="none"
              paddingBlockStart="none"
              paddingInlineEnd="none"
              paddingInlineStart="none"
              style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', textAlign: 'left' }}
            >
              {ControlComponent !== undefined && (
                <ControlComponent control={item} bulkUpdateCallback={bulkUpdateCallback} workflowId={selectedToolWorkflowEnum} {...rest} />
              )}
            </Card>
          </>
        )}
      </Fragment>
    );
  };

  const validateControls = () => {
    const keys = Object.keys(controlValues);
    // check if in radio group control - remove object: prompt disable

    if (keys.includes('task_type')) {
      checkRadioGroupValidation(controlValues['task_type'].value);
    }
    const mandatoryControls = controlObject.current
      .filter((item: WorkflowListResponseDataItemControlsItem) => item.mandatory)
      .map((item: WorkflowListResponseDataItemControlsItem) => item.controlName);
    keys.map((key) => {
      const val = mandatoryControls.indexOf(key);
      if (val !== -1) {
        mandatoryControls.splice(val, 1);
      }
    });
    setControlValidation([...mandatoryControls]);
  };

  const checkRadioGroupValidation = (value: string) => {
    if (value === TaskType.REMOVAL) {
      return removeControlValidation('prompt');
    } else {
      return addControlValidation('prompt');
    }
  };

  const clickHandler = async () => {
    if (selectedTool === WorkflowIdEnum.LIFESTYLE_IMAGERY && (controlValues.prompt === undefined || controlValues.prompt.value === '')) {
      // run guided text workflow
      const controlData = { prompt_type: { value: PromptType.PRODUCT_LESS_LIFESTYLE } };
      controlValues = { ...controlValues, ...controlData };
      await runJob(WorkflowIdEnum.GUIDED_TEXT_GENERATION, true);
    } else {
      if (selectedTool === WorkflowIdEnum.LIFESTYLE_IMAGERY && controlValues.prompt && controlValues.prompt.value.length > 0) {
        const controlData = { rewriteUserCustomPrompt: { value: 'false' } };
        controlValues = { ...controlValues, ...controlData };
      }
      await runJob(undefined, false);
    }
  };

  useEffect(() => {
    if (runNextJob) {
      const controlData = { rewriteUserCustomPrompt: { value: 'false' }, prompt: { value: generatedPrompt } };
      controlValues = { ...controlValues, ...controlData };

      runJob(undefined, true);
    }
  }, [runNextJob]);

  const runJob = async (toolToRun: WorkflowIdEnum | undefined, showTextResult: boolean) => {
    if (workflowState !== WorkflowSubmission.RUNNING) {
      // Set the statuses
      setWorkflowState(WorkflowSubmission.RUNNING);
      appendGeneratedResults({ status: WorkflowSubmission.RUNNING });

      // Uploads image for workflow
      await runBeforeJobSubmission(selectedTool ?? '', controlValues, client);

      // If the component unmounted during upload the stop workflow
      if (!isMounted.current) {
        logger.info('Workflow canceled (Unmounted)');
        return;
      }

      // If another job is still currning, abandon it
      if (currentWorkflow.current?.jobInProgress) {
        currentWorkflow.current.abandon();
      }

      // Stores current workflow in a ref incase of unmount during API calls
      if (toolToRun !== undefined) {
        currentWorkflow.current = new WorkflowSubmission({ workflowId: toolToRun, client, metrics }).verbose();
      } else if (selectedTool != undefined) {
        currentWorkflow.current = new WorkflowSubmission({ workflowId: selectedToolWorkflowEnum, client, metrics }).verbose();
      } else {
        throw Error('No workflow Id defined');
      }

      // Then start and wait for workflow to finish
      try {
        const controlValuesWithTheme = {
          ...controlValues,
          theme: {
            value: isThemeControlPresent ? (generationTheme ?? 'autumn') : controlValues.theme ? controlValues.theme : 'no_theme',
          },
        };
        const workflowOptions = convertControlValuesToWorkflowOptions(controlValuesWithTheme);
        const summary = await currentWorkflow.current.submitJob(workflowOptions);
        const {
          status,
          message,
          output,
          output: {
            body: { type },
          },
        } = summary;
        const urls = output.body.jobs?.[0].urls || []; // WorkflowSubmission verifies that a job exists

        // Update statuses with status
        setWorkflowState(status);
        editorContext.setRawJobResponse(output);

        if (toolToRun === WorkflowIdEnum.GUIDED_TEXT_GENERATION) {
          setGeneratedPrompt(urls?.[0]);
          appendGeneratedTextResults({ status, urls, type, message });
        } else {
          // clear text results if rewriteUserCustomPrompt field true
          if (!showTextResult) {
            appendGeneratedTextResults({
              status: JobStatusEnum.COMPLETED,
              urls: [],
              type: '',
              message: '',
            });
          }

          appendGeneratedResults({ status, urls, type, message });
          setRunNextJob(false);
        }

        // If it's not running then clear the current workflow
        if (status !== WorkflowSubmission.RUNNING) {
          if (status === JobStatusEnum.FAILED || (type === AssetTypeEnum.VIDEO && (!urls || urls?.length === 0))) {
            handleFailedJob(notifications.SubmitSystemFailureNotification);
          } else if (!urls || urls?.length === 0) {
            handleFailedJob(notifications.SubmitNoImageNotification);
          } else if (type === AssetTypeEnum.IMAGE && (!urls || urls?.length < 4)) {
            addFailureNotification({ SnackbarContent: notifications.SubmitPartialFailureNotification });
          }

          currentWorkflow.current = null;
          if (toolToRun === WorkflowIdEnum.GUIDED_TEXT_GENERATION) {
            setRunNextJob(true);
          }
        }
      } catch (error) {
        if (toolToRun === WorkflowIdEnum.GUIDED_TEXT_GENERATION) {
          appendGeneratedTextResults({
            status: JobStatusEnum.FAILED,
            message: `The Server responded with error status: ${error || notifications.FAILURE_MESSAGE.SYSTEM_FAILURE}`,
          });
        }
        appendGeneratedResults({
          status: JobStatusEnum.FAILED,
          message: `The Server responded with error status: ${error || notifications.FAILURE_MESSAGE.SYSTEM_FAILURE}`,
        });
        currentWorkflow.current = null;
        setWorkflowState(JobStatusEnum.FAILED);
        handleFailedJob(notifications.SubmitSystemFailureNotification);
      }
    }
  };

  const convertControlValuesToWorkflowOptions = (controlValues: ControlValues) => {
    const workflowOptions: { [key: string]: string } = {};
    for (const key in controlValues) {
      workflowOptions[key] = controlValues[key].value ?? '';
    }
    return workflowOptions;
  };

  const handleFailedJob = (snackbarMessage: React.FC) => {
    addFailureNotification({ SnackbarContent: snackbarMessage });
    setTimeout(() => appendGeneratedResults({}), 4500);
  };

  return (
    <div style={{ width: 'inherit' }} data-testid="control-panel">
      <ControlPanelView>
        <ControlTitle>{controlTitle}</ControlTitle>
        <ControlDescription>{toolDesc}</ControlDescription>
        <Stack style={{ flexDirection: 'column', marginTop: '26px', gap: '44px', marginBottom: '44px' }}>
          {controlObject.current.length === 0 && <></>}
          {controlObject.current.length > 0 &&
            controlObject.current
              .sort((a: WorkflowListResponseDataItemControlsItem, b: WorkflowListResponseDataItemControlsItem) => a.order - b.order)
              .map((item: WorkflowListResponseDataItemControlsItem, index: number) => {
                return createControlCard(item, index);
              })}
        </Stack>

        {isThemeControlPresent && (
          <>
            <StyleSettingPlayground
              onThemeSelected={onThemeSelectedHandler}
              style={{ color: 'var(--text-primary, #000000)', fontSize: '15px', fontWeight: '600' }}
            />
          </>
        )}
        <AnimatedButton
          dataTestId="generate-button"
          style={{ marginTop: '20px' }}
          animate={workflowState == WorkflowSubmission.RUNNING}
          disabled={!validated}
          clickHandler={clickHandler}
          text={workflowState == WorkflowSubmission.RUNNING ? 'Generating' : 'Generate'}
          fullscreen
        ></AnimatedButton>
        <Stack style={{ marginTop: '20px', marginBottom: '30px' }}>
          <ModuleFeedback />
        </Stack>
      </ControlPanelView>
    </div>
  );
};

export default ControlPanel;
