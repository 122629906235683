import { memo } from 'react';
import { FeedAssetsGroupItem } from 'src/v2/components/studio/feed/_partials/FeedAssetsGroupItem';
import { FeedGroupSeparator } from 'src/v2/components/studio/feed/_partials/FeedAssetsGroupSeparator';
import { computeFeedItemLayout } from 'src/v2/components/studio/feed/Feed.utils';
import { FeedAssetsDateGroupType } from 'src/v2/redux/slices/feed/feedSlice.types';

export const FeedAssetsGroup = memo(
  ({ feedAssetsDateGroup, numFeedItemsPerRow }: { feedAssetsDateGroup: FeedAssetsDateGroupType; numFeedItemsPerRow: number }) => {
    const feedAssetsGroupItems = computeFeedItemLayout({
      items: feedAssetsDateGroup.assets,
      numFeedItemsPerRow,
      createFeedItem({ item: asset, style }) {
        return <FeedAssetsGroupItem key={asset.id} style={style} asset={asset} />;
      },
    });

    return (
      <>
        <FeedGroupSeparator>{feedAssetsDateGroup.name}</FeedGroupSeparator>
        {feedAssetsGroupItems}
      </>
    );
  },
);

FeedAssetsGroup.displayName = 'FeedAssetsGroup';
