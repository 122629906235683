import { ProductAsset } from '@amzn/genaihub-typescript-client';
import { useQuery } from '@tanstack/react-query';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { ImageModalImageViewer } from 'src/components/imageModal/components/ImageModalImageViewer';
import { LiveImageControls } from 'src/components/imageModal/components/LiveImageControls/LiveImageControls';
import { ReframeControls } from 'src/components/imageModal/components/ReframeControls/ReframeControls';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import Add from 'src/components/imageModal/ItemImages/insert.svg';
import LiveImage from 'src/components/imageModal/ItemImages/liveImage.svg';
import Position from 'src/components/imageModal/ItemImages/position.svg';
import Reframe from 'src/components/imageModal/ItemImages/reframe.svg';
import Remix from 'src/components/imageModal/ItemImages/remix.svg';
import Remove from 'src/components/imageModal/ItemImages/remove.svg';
import Restyle from 'src/components/imageModal/ItemImages/restyle.svg';
import { SideBarItem } from 'src/components/imageModal/SideBarItem';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { TaskType } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { EditMode, WorkflowIdEnum } from 'src/v2/types';
import { ContentItem } from '../pages/studio/StudioContext';
import { AddRemoveControls } from './components/AddRemoveControls/AddRemoveControls';
import ImageModalStyleSetting from './components/ImageModalStyleSetting';
import { PositionControls } from './components/PositionControls/PositionControls';
import { RemixControls } from './components/RemixControls/RemixControls';
import styles from './ImageModalEditTab.module.scss';

export interface ImageModalEditTabProps {
  handleAcceptEdits: () => void;
  openConfirmModal: (callback: () => void) => void;
  currentItem?: ContentItem;
}

export const ImageModalEditTab = ({ currentItem, handleAcceptEdits, openConfirmModal }: ImageModalEditTabProps) => {
  const [editMode, setEditMode] = useState<string>(EditMode.RESTYLE);
  const imageModalContext = useContext(ImageModalContext);
  const savedItemWorkflowId = imageModalContext.savedEditsWorkflowId;
  const sourceImageUrl = imageModalContext.savedEditsImageUrl || imageModalContext.imageUrl;
  const [reframeAspectRatio, setReframeAspectRatio] = useState<string>();
  const [reframeDirection, setReframeDirection] = useState<string>();

  const batchId = imageModalContext.savedEditsBatchId || currentItem?.batchId;
  const workflowId = savedItemWorkflowId || currentItem?.workflowId;
  const imageId = imageModalContext.savedEditsImageId || currentItem?.imageId;

  const rescaleSupportedWorkflow =
    workflowId === WorkflowIdEnum.TEXT_TO_IMAGE || workflowId === WorkflowIdEnum.PRODUCT_EDIT || workflowId === WorkflowIdEnum.APPAREL;

  // This should be all workflow once the Catwalk implemention is done
  const imageIdSupportedWorkflow = rescaleSupportedWorkflow || workflowId === WorkflowIdEnum.GENERATIVE_RESIZING;

  const enableEditTabPosition = !!batchId && !!imageId && rescaleSupportedWorkflow;
  const enableAssetFetch = !!batchId && !!imageId && imageIdSupportedWorkflow;

  const client = useAIBackendHubClient();

  const getAssetQuery = useQuery({
    enabled: enableAssetFetch,
    queryFn: async () => {
      if (!enableAssetFetch) return;
      const { body } = await client.retrieveResultByWorkflowIdAndBatchId({
        workflowId: workflowId!,
        batchId: batchId!,
      });

      const asset: ProductAsset | undefined = body.jobs?.[0].assets?.find((asset) => {
        return asset.imageId === imageId;
      });
      return asset;
    },
    queryKey: ['retrieveResultByWorkflowIdAndBatchId', batchId],
  });

  useEffect(() => {
    if (getAssetQuery.isSuccess && getAssetQuery.data) {
      imageModalContext.setSavedEditsImageId(getAssetQuery.data.imageId);
      imageModalContext.setSavedEditProductMask(getAssetQuery.data.productMask);
      imageModalContext.setSavedEditProductBoundingBox(getAssetQuery.data.boundingBox);
    }
  }, [getAssetQuery.isSuccess, getAssetQuery.data, imageModalContext.setSavedEditsImageId]);

  const editModes: {
    text: string;
    icon: ReactElement;
    testId: string;
    enabled?: boolean;
  }[] = [
    {
      text: EditMode.RESTYLE,
      icon: (
        <div data-id="restyle-icon">
          <Restyle />
        </div>
      ),
      testId: 'studio-edit-restyle-tab',
      enabled: true,
    },
    {
      text: EditMode.REMIX,
      icon: (
        <div data-id="remix-icon">
          <Remix />
        </div>
      ),
      testId: 'studio-edit-remix-tab',
      enabled: true,
    },
    {
      text: EditMode.SCALE,
      icon: <Position />,
      testId: 'studio-edit-position-tab',
      enabled: enableEditTabPosition,
    },
    { text: EditMode.REFRAME, icon: <Reframe />, testId: 'studio-edit-reframe-tab', enabled: true },
    { text: EditMode.ADD, icon: <Add />, testId: 'studio-edit-add-tab', enabled: true },
    { text: EditMode.REMOVE, icon: <Remove />, testId: 'studio-edit-remove-tab', enabled: true },
    {
      text: EditMode.LIVE,
      icon: (
        <div data-id="live-image-icon" className={styles.liveImageIcon}>
          <LiveImage />
        </div>
      ),
      testId: 'studio-edit-live-image-tab',
      enabled: true,
    },
  ];

  const getSideBarItemControls = () => {
    switch (editMode) {
      case EditMode.RESTYLE:
        return <ImageModalStyleSetting />;
      case EditMode.REFRAME:
        return (
          <ReframeControls
            reframeAspectRatio={(reframeAspectRatio) => setReframeAspectRatio(reframeAspectRatio)}
            reframeDirection={(reframeDirection) => setReframeDirection(reframeDirection)}
          />
        );
      case EditMode.ADD:
        return <AddRemoveControls taskType={TaskType.INPAINTING} />;
      case EditMode.REMOVE:
        return <AddRemoveControls taskType={TaskType.REMOVAL} />;
      case EditMode.REMIX:
        return <RemixControls />;
      case EditMode.SCALE:
        return <PositionControls query={getAssetQuery} />;
      case EditMode.LIVE:
        return <LiveImageControls />;
      default:
        return <div>controls</div>;
    }
  };

  const handleChangeEditMode = (newEditMode: string | undefined) => {
    if (!newEditMode) {
      return;
    }
    if (imageModalContext.unsavedWork) {
      openConfirmModal(() => {
        imageModalContext.clearActiveEdit();
        setEditMode(newEditMode);
      });
    } else {
      imageModalContext.clearActiveEdit();
      setEditMode(newEditMode);
    }
  };

  useEffect(() => {
    imageModalContext.setEditMode(editMode);
  }, [editMode]);

  return (
    <div className={styles.imageEditTab} role={'tabpanel'} data-testid="Edit">
      <div className={styles.container}>
        {editModes
          .filter((mode) => mode.enabled)
          .map((item) => (
            <SideBarItem
              key={item.text}
              text={item.text}
              icon={item.icon}
              testId={item.testId}
              onClick={() => handleChangeEditMode(item.text)}
              active={editMode === item.text}
            />
          ))}
      </div>
      <div className={styles.main}>
        <div className={styles.controlsContainer}>{getSideBarItemControls()}</div>
        <div className={styles.imageContainer}>
          <ImageModalImageViewer
            sourceImageUrl={sourceImageUrl}
            handleAcceptEdits={handleAcceptEdits}
            reframeAspectRatio={reframeAspectRatio}
            reframeDirection={reframeDirection}
          />
        </div>
      </div>
    </div>
  );
};
