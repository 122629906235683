import { Layer, Rect } from 'react-konva';

interface BoundedBox {
  width: number;
  height: number;
  fill: string;
  stroke: string;
  strokeWidth: number;
  x: number;
  y: number;
}

const BoundedBoxSelection = (props: { boundedBox: BoundedBox }) => {
  const boundedBox = props.boundedBox || {};

  return (
    <Layer>
      <Rect
        width={boundedBox.width}
        height={boundedBox.height}
        fill={boundedBox.fill}
        stroke={boundedBox.stroke}
        strokeWidth={boundedBox.strokeWidth}
        x={boundedBox.x}
        y={boundedBox.y}
      />
    </Layer>
  );
};

export default BoundedBoxSelection;
