import { Button, SplitButton, SplitButtonItem, Text } from '@amzn/storm-ui';
import { FC } from 'react';
import './AnimatedButton.scss';
import { AnimatedStars } from 'src/animations/AnimatedStars';
import { GenerateOptions } from 'src/components/pages/studio/UserInputContainer';
import { GenerateOptionType } from 'src/v2/types';

export interface AnimatedButtonProps {
  disabled: boolean;
  clickHandler: (options?: GenerateOptionType) => void;
  text: string;
  animate: boolean;
  className?: string;
  dataTestId?: string;
  width?: number;
  fullscreen?: boolean;
  style?: { [key: string]: string };
  generateOptions?: GenerateOptions;
}

export const AnimatedButton: FC<AnimatedButtonProps> = ({
  disabled,
  clickHandler,
  text,
  dataTestId,
  width,
  fullscreen,
  style,
  animate,
  generateOptions,
}) => {
  const buttonContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        maxHeight: '32px',
        overflow: 'hidden',
        left: '-10px',
      }}
    >
      <AnimatedStars width={50} height={50} animated={animate} />
      <Text style={{ fontSize: '15px', fontWeight: '500', color: 'inherit' }}>{text}</Text>
    </div>
  );

  const isGenerateOptionsProvided = generateOptions && Object.keys(generateOptions).length > 0;

  const splitAnimatedButton = isGenerateOptionsProvided && (
    <SplitButton
      className={animate ? 'active-split-animated-button' : 'split-animated-button'}
      mainButtonLabel={buttonContent}
      mainButtonOnClick={() => clickHandler()}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {Object.entries(generateOptions).map(([option, enabled]) => {
        return (
          <SplitButtonItem
            key={option}
            value={option}
            disabled={!enabled}
            data-testid={`split-button-item-${option.toLowerCase().replace(/ /g, '-')}`}
            onClick={() => clickHandler(option as GenerateOptionType)}
          >
            {option}
          </SplitButtonItem>
        );
      })}
    </SplitButton>
  );

  return (
    <div className="animated-button-container" aria-disabled={disabled} style={{ ...style, width: fullscreen ? '100%' : width }}>
      {isGenerateOptionsProvided ? (
        splitAnimatedButton
      ) : (
        <Button
          className={animate ? 'active-animated-button' : 'animated-button'}
          variant="primary"
          onClick={() => clickHandler()}
          disabled={disabled}
          data-testid={dataTestId}
          style={{
            borderRadius: '999px',
          }}
          aria-label={'Generate button'}
        >
          {buttonContent}
        </Button>
      )}
    </div>
  );
};
