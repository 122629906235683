import Genaihubbackend, { FileUploadPresignInput, FileUploadPresignOutput, FileUploadPresignResult } from '@amzn/genaihub-typescript-client';
import { UseMutationResult } from '@tanstack/react-query';
import { BackendActionBase, BackendActionType, RequestsLookup } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { handleInit, handleError, handleSuccess } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type FileUploadGetPresignAction = BackendActionBase<
  BackendActionType.FILE_UPLOAD_GET_PRESIGN,
  FileUploadPresignInput,
  FileUploadPresignOutput['body']
>;

interface FileUploadGetPresignMutationProps {
  backendClient: Genaihubbackend;
  payload: FileUploadPresignInput;
}

export const FileUploadGetPresignMutationConfig = {
  mutationFn: async ({ backendClient, payload }: FileUploadGetPresignMutationProps) => {
    const response = await backendClient.fileUploadGetPresign(payload);
    return response.body;
  },
};

interface HandleFileUploadGetPresignActionProps {
  action: FileUploadGetPresignAction;
  backendClient: Genaihubbackend;
  fileUploadGetPresignMutation: UseMutationResult<FileUploadPresignResult, Error, FileUploadGetPresignMutationProps, unknown>;
  requestsLookup: RequestsLookup;
}

export const handleFileUploadGetPresignAction = async ({
  action,
  backendClient,
  fileUploadGetPresignMutation,
  requestsLookup,
}: HandleFileUploadGetPresignActionProps) => {
  const id = handleInit({ action, requestsLookup });
  try {
    const response = await fileUploadGetPresignMutation.mutateAsync({
      backendClient,
      payload: action.payload,
    });
    return handleSuccess({ response, action, id, requestsLookup });
  } catch (err) {
    return handleError({ err, action, id, requestsLookup });
  }
};
