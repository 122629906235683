import { Text } from '@amzn/storm-ui';
import React from 'react';
import { AnimatedStars } from 'src/animations/AnimatedStars';
import styles from './LoadingAnimation.module.scss';

export interface LoadingAnimationProps {
  primaryMessage?: string;
  secondaryMessage?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const LoadingAnimation = ({ primaryMessage, secondaryMessage, className, style }: LoadingAnimationProps) => {
  return (
    <div className={`${styles.loadingAnimation} ${className || ''}`} style={style || {}}>
      {(primaryMessage?.length || secondaryMessage?.length) && (
        <div className={styles.messages}>
          {primaryMessage?.length && (
            <div className={styles.primaryMessage}>
              <AnimatedStars width={80} height={80} />

              <Text className={styles.primaryMessageText} size="large" inline={true}>
                {primaryMessage}
              </Text>
            </div>
          )}
          {secondaryMessage?.length && (
            <div className={styles.secondaryMessage}>
              <Text className={styles.secondaryMessageText} size="medium" inline={true}>
                {secondaryMessage}
              </Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
