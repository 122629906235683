import { Button, Icon, Link, Text, TextButton } from '@amzn/storm-ui';
import { angleInlineEnd, close } from '@amzn/storm-ui-icons';
import { Fragment, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from 'src/AppContext';
import Item from 'src/components/assistant/components/chatsList/item';
import { ConversationModel } from 'src/components/assistant/model';
import { CHAT_THEME } from 'src/components/assistant/theme';
import { ConversationContext } from 'src/components/pages/conversational/ChatPage';
import { useContainerDimensions } from 'src/components/utils/ContainerDimensions';
import SpinnerIcon from 'src/icons/spinnerIcon';
import styled from 'styled-components';
import useConversationId from '../../utils/useConversationId';

const ChatListWrapper = styled.div<{
  compactScreen: boolean;
  isSideNavOpen: boolean;
  navHeaderHeight: number;
  pageHeaderHeight: number;
  pageMargin: number;
}>`
  display: ${({ isSideNavOpen }) => (isSideNavOpen ? 'flex' : 'none')};
  position: fixed;
  flex-direction: column;
  gap: 10px;
  padding: 14px 26px;
  top: ${({ navHeaderHeight, pageHeaderHeight }) => navHeaderHeight + pageHeaderHeight}px;
  left: ${({ pageMargin }) => pageMargin}px;
  width: ${({ compactScreen }) => (compactScreen ? '100%' : '340px')};
  height: ${({ navHeaderHeight, pageHeaderHeight }) => `calc(100vh - ${navHeaderHeight}px - ${pageHeaderHeight}px)`};
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 19.15px;
  letter-spacing: 0.01em;
  text-align: left;
  padding-top: 18px;

  && + div span {
    justify-content: center;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;

  & div[data-testid='chat-list-item']:not(:first-child) {
    border-top: thin solid rgb(230, 233, 237);
  }
`;

const ArchiveTextButton = styled(TextButton)`
  color: unset;
  display: flex;
  gap: 6px;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.15px;
  letter-spacing: 0.01em;
  padding-top: 18px;

  &&:hover {
    text-decoration: none;
  }

  && + div span {
    justify-content: center;
  }
`;

const NewChatButton = styled(Button)`
  border-radius: 16px;
  color: #ffffff !important;
  background: ${CHAT_THEME.color.aiPurple};
  box-shadow: none;

  &&:visited,
  &&:active,
  &&:hover {
    color: #ffffff !important;
    background: ${CHAT_THEME.color.aiPurple};
    box-shadow: none;
  }
`;

const ErrorMessage = styled.p`
  font-size: 13px;
  color: var(--color-Gray-700, #5c687c);
  text-align: center;
`;

const CopyRightWrapper = styled.div`
  margin-top: auto;
  padding-bottom: 16px;

  p {
    font-size: 13px;
    color: var(--color-Gray-700, #5c687c);
    line-height: 20px;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
  p:last-child {
    padding-top: 10px;
    font-size: 14px;
  }
`;

const CloseIcon = styled(TextButton)`
  color: unset;
`;

interface Props {
  chats: ConversationModel[];
  isLoading: boolean;
  error: boolean;
  pageMargin: number;
}

export default function ChatList({ chats, isLoading, error, pageMargin }: Props) {
  const [archiveOpen, setArchiveOpen] = useState(false);
  const { setIsSideNavOpen, isSideNavOpen, compactScreen, setSideNavWidth } = useContext(ConversationContext);
  const { headerDimensions } = useContext(AppContext);
  const { setConversationId } = useConversationId();
  const sideNavRef = useRef<HTMLDivElement>(null);
  const { width: sideNavWidth } = useContainerDimensions(sideNavRef);

  const activeChats = useMemo(() => (archiveOpen ? chats.slice(3) : chats.slice(0, 3)), [chats, archiveOpen]);

  const Archive = useCallback(
    ({ label = 'Archive' }: { label: string }) => (
      <ArchiveTextButton data-testid={'archive-button'} disabled={isLoading} onClick={() => setArchiveOpen((prev) => !prev)}>
        <Icon type={angleInlineEnd} />
        {label}
      </ArchiveTextButton>
    ),
    [isLoading, archiveOpen],
  );

  useLayoutEffect(() => {
    setSideNavWidth(sideNavWidth);
  }, [sideNavWidth]);

  return (
    <ChatListWrapper
      ref={sideNavRef}
      isSideNavOpen={isSideNavOpen}
      compactScreen={compactScreen}
      navHeaderHeight={headerDimensions.navHeaderHeight}
      pageHeaderHeight={headerDimensions.pageHeaderHeight}
      pageMargin={pageMargin}
    >
      <Controls>
        <NewChatButton data-testid="chat-list-new-chat" onClick={() => setConversationId()}>
          New chat
        </NewChatButton>
        <CloseIcon onClick={() => setIsSideNavOpen(false)}>
          <Icon type={close} size="lg" />
        </CloseIcon>
      </Controls>
      {!archiveOpen ? <Title>Recent chats</Title> : <Archive label="Recent chats" />}
      {!archiveOpen && (
        <ListWrapper>
          {isLoading && <SpinnerIcon style={{ width: '100%', height: '30px', display: 'flex', justifyContent: 'center' }} />}
          {error && <ErrorMessage>A chat list is empty.</ErrorMessage>}
          {activeChats.map((chat) => (
            <Fragment key={chat.conversationId}>
              <Item summary={chat.summary} chatId={chat.conversationId || ''} updatedAt={chat.updatedAt} asin={chat.asin} assets={chat.assets} />
            </Fragment>
          ))}
        </ListWrapper>
      )}
      {archiveOpen ? <Title>Archive</Title> : <Archive label="Archive" />}
      {archiveOpen && (
        <ListWrapper>
          {error && <ErrorMessage>Archive is empty.</ErrorMessage>}
          {activeChats.map((chat) => (
            <Fragment key={chat.conversationId}>
              <Item summary={chat.summary} chatId={chat.conversationId || ''} updatedAt={chat.updatedAt} />
            </Fragment>
          ))}
        </ListWrapper>
      )}
      <CopyRightWrapper>
        <Text>
          <Link href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ" target="_blank">
            Privacy notice
          </Link>
        </Text>
        <Text>
          <Link href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GLSBYFE9MGKKQXXM" target="_blank">
            Conditions of use
          </Link>
        </Text>
        <Text>
          <Link href="https://advertising.amazon.com/terms" target="_blank">
            Amazon Advertising Agreement
          </Link>
        </Text>
        <Text>© Copyright 2024, Amazon</Text>
      </CopyRightWrapper>
    </ChatListWrapper>
  );
}
