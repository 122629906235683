import { Flex, Heading } from '@amzn/storm-ui';
import { useContext } from 'react';
import { AppContext } from 'src/AppContext';
import { Metrics } from 'src/constants';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { Icon } from 'src/v2/components/Icon/Icon';
import styled from 'styled-components';
import { WidgetHeaderProps, WidgetViewHeaderLabels } from './types';

export const WidgetHeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  width: 100%;
`;

export const WidgetHeader = ({ dataTestId, label, icon, closeWidget, disabled }: WidgetHeaderProps) => {
  const appContext = useContext(AppContext);
  const getCloseMetricName = () => {
    switch (label) {
      case WidgetViewHeaderLabels.Product:
        return Metrics.Counters.CloseProductView;
      case WidgetViewHeaderLabels.Effects:
        return Metrics.Counters.CloseEffectsView;
      case WidgetViewHeaderLabels.ReferenceImages:
        return Metrics.Counters.CloseReferenceImageView;
      case WidgetViewHeaderLabels.Styling:
        return Metrics.Counters.CloseStyleView;
      case WidgetViewHeaderLabels.AspectRatio:
        return Metrics.Counters.CloseAspectRatioView;
      default:
        return 'UNKNOWN';
    }
  };
  return (
    <WidgetHeaderWrapper data-testid={`${dataTestId}-header`}>
      <Flex columnGap={icon ? 'small' : 'none'} rowGap="small" direction="row" justifyContent="space-between" alignItems="center">
        <Flex columnGap="xxsmall" direction="row" alignItems="center">
          {icon && <Icon type={icon} color={`var(--text-primary, 'unset')`} />}
          <Heading renderAs="h4" style={{ color: `${`var(--text-primary, 'unset')`}` }}>
            {label}
          </Heading>
        </Flex>
        <Flex columnGap="xsmall" direction="row" alignItems="center">
          <Button
            type={ButtonTypes.Tertiary}
            label="Cancel"
            dataTestId={`${dataTestId}-cancel`}
            onClick={() => {
              appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [`${getCloseMetricName()}-Cancel`]: 1 });
              closeWidget('cancel');
            }}
            disabled={disabled}
          />
          <Button
            type={ButtonTypes.Primary}
            label="Done"
            dataTestId={`${dataTestId}-done`}
            onClick={() => {
              appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [`${getCloseMetricName()}-Done`]: 1 });
              closeWidget();
            }}
            disabled={disabled}
          />
        </Flex>
      </Flex>
    </WidgetHeaderWrapper>
  );
};
