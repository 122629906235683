import { retrieveWorkflowResultMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/RetrieveWorkflowResult.middleware';
import { submitWorkflowMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/SubmitWorkflow.middleware';
import { ProductEditGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { buildAssetsFromBatchResult } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { ProductEditWorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { AssetTypeEnum, EditedImageAsset } from 'src/v2/types';

export const HandleProductEditGenerationSubmissionErrorMessages = {
  NO_CATWALK_IMAGE_ID: "Catwalk image ID missing in input image's data",
};

/**
 * A helper function for the primary handler, handleProductEditGeneration. This function handles the workflow submission and result.
 */
export async function handleProductEditGenerationSubmission({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: ProductEditGenerationConfig;
}): Promise<EditedImageAsset[]> {
  const { entityId, outputCount, studioRequest, userInputs, workflowId } = generationConfig;
  const { boundingBox, inputImageAsset } = userInputs;

  if (!inputImageAsset.catwalkImageId) {
    throw new Error(HandleProductEditGenerationSubmissionErrorMessages.NO_CATWALK_IMAGE_ID);
  }

  // STEP - Submit workflow for PRODUCT_EDIT
  const workflowOptions: ProductEditWorkflowOptions = {
    boundingBox,
    catwalkImageId: inputImageAsset.catwalkImageId,
    imageCount: outputCount,
    workflowId,
  };
  const submitWorkflowPayload: StronglyTypedSubmitWorkflowByIdInput = {
    body: {
      workflowOptions,
    },
    studioRequest,
    workflowId,
  };
  const batchId = await submitWorkflowMiddleware({ backendDispatchContext, payload: submitWorkflowPayload });

  // STEP - Retrieve result for PRODUCT_EDIT
  return await retrieveWorkflowResultMiddleware<EditedImageAsset[]>({
    backendDispatchContext,
    payload: {
      batchId,
      entityId,
      studioRequest,
      workflowId,
    },
    onSuccessHandler: ({ response }) => {
      return buildAssetsFromBatchResult<EditedImageAsset, ProductEditGenerationConfig['userInputs'], ProductEditWorkflowOptions>({
        aspectRatio: inputImageAsset.aspectRatio,
        assetType: AssetTypeEnum.IMAGE,
        batchResult: response,
        userInputs,
        workflowOptions,
      });
    },
  });
}
