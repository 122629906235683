import Genaihubbackend, { GetUserPermissionsInput, GetUserPermissionsOutput } from '@amzn/genaihub-typescript-client';
import {
  BackendActionBase,
  BackendActionQueryResultBase,
  BackendActionType,
  BackendQueryActionOptions,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type GetUserPermissionsAction = BackendActionBase<
  BackendActionType.GET_USER_PERMISSIONS,
  GetUserPermissionsInput,
  GetUserPermissionsOutput['body'],
  BackendQueryActionOptions
>;

export type GetUserPermissionsActionQueryResult = BackendActionQueryResultBase<
  BackendActionType.GET_USER_PERMISSIONS,
  GetUserPermissionsOutput['body']
>;

interface GetUserPermissionsQueryFnProps {
  id: Request['id'];
  action: GetUserPermissionsAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const getUserPermissionsQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: GetUserPermissionsQueryFnProps): Promise<GetUserPermissionsActionQueryResult> => {
  const type = BackendActionType.GET_USER_PERMISSIONS;
  const response = await backendClient.getUserPermissions(action.payload);
  const request = getRequest({ id, requestsLookup });

  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }

  return { type, response: response.body, requestStatus: RequestStatus.SUCCESS };
};

interface HandleGetUserPermissionsActionProps {
  action: GetUserPermissionsAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}

export const handleGetUserPermissionsAction = ({ action, backendClient, requestsLookup, setQueries }: HandleGetUserPermissionsActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.GET_USER_PERMISSIONS,
    getQueryResult: ({ id }) => {
      return getUserPermissionsQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
