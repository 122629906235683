import { useMemo } from 'react';
import { FeedFetchState } from 'src/v2/redux/slices/feed/feedSlice.types';
import { FeedFetchPlaceholdersGroupItem } from './FeedFetchPlaceholdersGroupItem';

export const FeedFetchPlaceholdersGroup = ({ feedFetchPlaceholderCount }: { feedFetchPlaceholderCount: FeedFetchState['placeholderCount'] }) => {
  const feedFetchPlaceholdersGroupItems = useMemo(() => {
    return Array(feedFetchPlaceholderCount)
      .fill(null)
      .map((value, index) => {
        return <FeedFetchPlaceholdersGroupItem key={`feed-fetch-placeholder-${index}`} />;
      });
  }, [feedFetchPlaceholderCount]);

  return <>{feedFetchPlaceholdersGroupItems}</>;
};
