import Genaihubbackend, { BoundingBox } from '@amzn/genaihub-typescript-client';
import { getSizeWithAspectRatio } from 'src/components/imageModal/components/utils';
import { uploadImage } from 'src/components/utils/uploadImage';
import { logger } from 'src/logger';
import { getAssetCacheInstance } from 'src/v2/hooks/useAssetCache/useAssetCache';
import { Product, ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { ProductLayout, ProductLayoutFull, SelectedProductFull } from 'src/v2/redux/slices/userInput/userInputSlice.types';
import { AspectRatio, AssetTypeEnum, CategoryEnum } from 'src/v2/types';
import { downloadFile } from 'src/v2/utils/File.utils';
import { getAspectRatioFromUrl } from 'src/v2/utils/ImageUtils';
import { getProductImageUrl } from 'src/v2/utils/Product.utils';
import { v4 as uuid } from 'uuid';

// https://code.amazon.com/packages/GENAIHub-Backend/blobs/mainline/--/src/main/java/com/amazon/genaihubbackend/models/AspectRatios.java
export const CanvasSize: Record<AspectRatio, { width: number; height: number }> = {
  [AspectRatio.HORIZONTAL_191_TO_1]: {
    width: 1200,
    height: 628,
  },
  [AspectRatio.HORIZONTAL_3_TO_1]: {
    width: 1920,
    height: 640,
  },
  [AspectRatio.HORIZONTAL_12_TO_5]: {
    width: 1500,
    height: 625,
  },
  [AspectRatio.SQUARE_1_TO_1]: {
    width: 1024,
    height: 1024,
  },
  [AspectRatio.VERTICAL_4_TO_5]: {
    width: 1080,
    height: 1350,
  },
  [AspectRatio.VERTICAL_9_TO_16]: {
    width: 1080,
    height: 1920,
  },
};

export const getAllImagesFromFullProduct = (product: SelectedProductFull): string[] => {
  if (product.type === ProductType.ASIN) {
    const imagesFromMetadata = product.metadata?.mediaCentralAssets?.map((asset) => asset.highResUri || asset.lowResUri) || [];
    const customProductImageUrl = product.options?.asinProduct?.customProductImageUrl;
    return [...imagesFromMetadata, customProductImageUrl].filter((url): url is string => !!url);
  } else {
    return [product.customImage.url];
  }
};

export const getImageFromProduct = (product: SelectedProductFull): string | undefined => {
  // TODO: replace the usages of getImageFromProduct with getProductImageUrl in follow-up CR.
  return getProductImageUrl({ product });
};

export const getProcessedImageFromLayout = (layout: ProductLayoutFull) => {
  const originalProductImageUrl = getImageFromProduct(layout.product);
  if (!originalProductImageUrl) return;
  const processedImage = layout.processedImages?.[originalProductImageUrl];
  return processedImage;
};

export const createNewLayout = async ({
  product,
  setLoading,
  backendClient,
  deadZone = 0,
  aspectRatio,
  offset = 0,
}: {
  product: Product;
  setLoading: (loading: boolean) => void;
  backendClient: Genaihubbackend;
  deadZone: number;
  aspectRatio: AspectRatio;
  offset?: number;
}): Promise<ProductLayout | undefined> => {
  const originalProductImageUrl = getImageFromProduct(product);
  if (!originalProductImageUrl) return Promise.reject(new Error('Original product image URL not available'));

  let cachedProductImageUrls = getCachedProductImageUrls({ idSuffix: originalProductImageUrl });

  try {
    setLoading(true);
    if (!cachedProductImageUrls.cachedImageUrl || !cachedProductImageUrls.cachedMaskImageUrl) {
      cachedProductImageUrls = await getProductImagesWithBackgroundRemoved({ backendClient, originalProductImageUrl });
    }

    const { width, height } = await getAspectRatioFromUrl({ url: cachedProductImageUrls.cachedImageUrl });
    const canvasSize = CanvasSize[aspectRatio];
    const adjustedHeight = Math.round(canvasSize.height * 0.5);
    const adjustedWidth = Math.round((adjustedHeight / height) * width);
    return {
      id: uuid(),
      boundingBox: {
        width: adjustedWidth,
        height: adjustedHeight,
        left: deadZone + offset,
        top: deadZone + offset,
      },
      zIndex: 1,
      product,
      processedImages: {
        [originalProductImageUrl]: {
          image: cachedProductImageUrls.cachedImageUrl,
          mask: cachedProductImageUrls.cachedMaskImageUrl,
        },
      },
    };
  } catch (e) {
    logger.error(JSON.stringify(e));
  } finally {
    setLoading(false);
  }
};

// create new layout without background removal
export const createNewLayoutWithBackground = (options: { product: Product; boundingBox: BoundingBox }): ProductLayout => {
  const { product, boundingBox } = options;
  return {
    id: uuid(),
    product,
    boundingBox,
    zIndex: boundingBox.layer || 1,
  };
};

export const removeImageBackgroundForLayout = async (options: {
  layout: ProductLayoutFull;
  backendClient: Genaihubbackend;
}): Promise<ProductLayout> => {
  const { layout, backendClient } = options;
  const originalProductImageUrl = getImageFromProduct(layout.product);
  if (!originalProductImageUrl) return layout;
  let cachedProductImageUrls = getCachedProductImageUrls({ idSuffix: originalProductImageUrl });
  if (cachedProductImageUrls.cachedImageUrl && cachedProductImageUrls.cachedMaskImageUrl) {
    return {
      ...layout,
      processedImages: {
        ...layout.processedImages,
        [originalProductImageUrl]: {
          image: cachedProductImageUrls.cachedImageUrl,
          mask: cachedProductImageUrls.cachedMaskImageUrl,
        },
      },
    };
  } else {
    try {
      cachedProductImageUrls = await getProductImagesWithBackgroundRemoved({ backendClient, originalProductImageUrl });
      return {
        ...layout,
        processedImages: {
          ...layout.processedImages,
          [originalProductImageUrl]: {
            image: cachedProductImageUrls.cachedImageUrl,
            mask: cachedProductImageUrls.cachedMaskImageUrl,
          },
        },
      };
    } catch (e) {
      logger.error(JSON.stringify(e));
    }
  }

  return {
    ...layout,
  };
};

// find the size of canvas that fit into the container without change aspect ratio
export const getCanvasSize = ({
  width,
  height,
  aspectRatio,
  padding,
}: {
  width: number;
  height: number;
  padding: number;
  aspectRatio: AspectRatio;
}): { width: number; height: number } => {
  // Adjust container dimensions by subtracting padding from both sides
  const availableWidth = width - padding * 2;
  const availableHeight = height - padding * 2;

  // Ensure we have positive dimensions to work with
  if (availableWidth <= 0 || availableHeight <= 0) {
    return {
      width: 0,
      height: 0,
    };
  }

  // Convert aspect ratio to a numeric value
  const result = getSizeWithAspectRatio({ width: 1, aspectRatio });
  const targetRatio = result.width / result.height;

  // Calculate current container ratio using available space
  const containerRatio = availableWidth / availableHeight;

  let finalWidth: number;
  let finalHeight: number;

  if (containerRatio > targetRatio) {
    // Container is wider than target ratio
    // Use height as constraint
    finalHeight = availableHeight;
    finalWidth = availableHeight * targetRatio;
  } else {
    // Container is taller than target ratio
    // Use width as constraint
    finalWidth = availableWidth;
    finalHeight = availableWidth / targetRatio;
  }
  return {
    width: Math.floor(finalWidth),
    height: Math.floor(finalHeight),
  };
};

async function getProductImagesWithBackgroundRemoved({
  backendClient,
  originalProductImageUrl,
}: {
  backendClient: Genaihubbackend;
  originalProductImageUrl: string;
}) {
  // Upload and cache the product image and mask with the background removed
  const productImageFile = await downloadFile({ url: originalProductImageUrl });
  const referenceId = await uploadImage({
    backendClient,
    file: productImageFile,
    contentCategory: CategoryEnum.PRODUCT_IMAGE,
  });

  const response = await backendClient.productImageBackgroundRemoval({
    body: {
      imageUris: [referenceId],
    },
  });

  const result = response.body.preprocessPredictions;
  if (!result?.bgRemovedImages?.[0] || !result?.bgRemovalMasks?.[0]) {
    return Promise.reject('Background removal failed.');
  }

  return await cacheProductImageAndMask({
    idSuffix: originalProductImageUrl,
    imageUrl: result.bgRemovedImages?.[0],
    maskImageUrl: result.bgRemovalMasks?.[0],
  });
}

async function cacheProductImageAndMask({ idSuffix, imageUrl, maskImageUrl }: { idSuffix: string; imageUrl: string; maskImageUrl: string }) {
  const assetCache = getAssetCacheInstance();
  const cachedImageUrl = await assetCache.cacheAsset({
    asset: {
      id: getImageCacheId({ idSuffix }),
      type: AssetTypeEnum.IMAGE,
      url: imageUrl,
    },
  });
  const cachedMaskImageUrl = await assetCache.cacheAsset({
    asset: {
      id: getMaskImageCacheId({ idSuffix }),
      type: AssetTypeEnum.IMAGE,
      url: maskImageUrl,
    },
  });
  return {
    cachedImageUrl,
    cachedMaskImageUrl,
  };
}

function getCachedProductImageUrls({ idSuffix }: { idSuffix: string }) {
  const assetCache = getAssetCacheInstance();
  return {
    cachedImageUrl: assetCache.getCachedAssetUrlById({ id: getImageCacheId({ idSuffix }) }),
    cachedMaskImageUrl: assetCache.getCachedAssetUrlById({ id: getMaskImageCacheId({ idSuffix }) }),
  };
}

function getImageCacheId({ idSuffix }: { idSuffix: string }) {
  return `image-${idSuffix}`;
}

function getMaskImageCacheId({ idSuffix }: { idSuffix: string }) {
  return `mask-${idSuffix}`;
}
