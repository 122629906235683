import { Asset } from '@amzn/genaihub-typescript-client';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { BackendActionType } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { DEFAULT_PAGE_SIZE } from 'src/v2/contexts/feed/constants/FeedContext.constants';
import { AbortError } from 'src/v2/errors/CustomErrors';

export interface HandleRetrieveAssetsResult {
  assets: Asset[];
  nextToken: string;
}

export const handleRetrieveAssets = ({
  backendDispatchContext,
  nextToken,
  entityId,
  pageSize,
  requestGroupId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  nextToken: string;
  entityId: string | undefined;
  requestGroupId: string;
  pageSize?: number;
}): Promise<HandleRetrieveAssetsResult> => {
  return new Promise((resolve, reject) => {
    backendDispatchContext({
      type: BackendActionType.RETRIEVE_ASSETS,
      options: {
        requestGroupId,
      },
      payload: {
        pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
        cursor: nextToken,
        entityId,
      },
      onAbort: () => {
        return reject(new AbortError(`Aborted backend action: '${BackendActionType.RETRIEVE_ASSETS}'`));
      },
      onError: (err) => {
        return reject(err);
      },
      onSuccess: async ({ response }) => {
        return resolve({
          assets: response.assets || [],
          nextToken: response.nextToken || '',
        });
      },
    });
  });
};
