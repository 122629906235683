import {
  ASINMetadata,
  Controls as BackendControls,
  Guide,
  SubmitWorkflowByIdOutput,
  WorkflowId,
  WorkflowListResponse,
} from '@amzn/genaihub-typescript-client';
import { useContext, useEffect, useState, useRef, FC } from 'react';
import { AppContext } from 'src/AppContext';
import { EditorContextP1 as EditorContext, EditorContextStateP1 } from 'src/components/editor/EditorContext';
import ControlPanel from 'src/components/editor/UiContols/ControlPanel';
import { Controls } from 'src/components/editor/UiContols/uiGeneratorControls/controlComponentTypes';
import Stack from 'src/customUIComponents/Stack';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import styled from 'styled-components';
import style from './Editor.module.scss';
import WorkflowResults, { TextResults, WorkflowResult } from './WorkflowResults';
import { FileUploadEventType } from '../common/types';
import UsePrompt from './UiContols/uiGeneratorControls/storm/UsePrompt';

const LeftPanel = styled.div.attrs({ className: style.leftPanel })``;

const RightPanel = styled.div.attrs({
  className: style.rightPanel,
  'data-testid': 'right-panel',
})``;

const Editor = (props: { module: string; type: string }) => {
  const type = props.type || '';

  const genAIBackendClient = useAIBackendHubClient();
  const appContext = useContext(AppContext);
  const { selectedTool } = appContext;

  const [controls, setControls] = useState<BackendControls>([]);
  const [guide, setGuide] = useState<Guide>([]);
  const [activateGuide, setActivateGuide] = useState<boolean>(false);

  const [workflowOptions, setWorkFlowOptions] = useState<Record<string, unknown>>({});
  const [asinMetaData, setAsinMetaData] = useState<ASINMetadata>({});
  const [generatedResults, setGeneratedResults] = useState<WorkflowResult>({});
  const [generatedTextResults, setGeneratedTextResults] = useState<TextResults>({ urls: [] });
  const [fileUploadEvent, setFileUploadEvent] = useState<FileUploadEventType>();
  const [disableControl, setDisableControl] = useState<boolean>(false);
  const [promptUpdateEvent, setPromptUpdateEvent] = useState<string>();
  const [rawJobResponse, setRawJobResponse] = useState<SubmitWorkflowByIdOutput>();
  const rightPanelRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const totalHeight = getPageHeight();
    const container = document.getElementById('wrapper');
    const verticalScroll = window.scrollY || document.documentElement.scrollTop;
    if (container) container.style.height = `${totalHeight}px`;

    if (rightPanelRef.current) {
      if (verticalScroll >= 85) {
        let pageWidth = window.innerWidth >= 2008 ? '2008px' : '100%';
        rightPanelRef.current.style.position = 'fixed';
        rightPanelRef.current.style.top = '85px';
        rightPanelRef.current.style.width = `calc(${pageWidth} - 474px)`;
        rightPanelRef.current.style.height = window.innerHeight - 105 + 'px';
      } else {
        rightPanelRef.current.style.position = 'relative';
        rightPanelRef.current.style.top = '0';
        rightPanelRef.current.style.width = 'auto';
        rightPanelRef.current.style.height = window.innerHeight - 190 + verticalScroll + 'px';
      }
    }
  };

  const getPageHeight = () => {
    const pageHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );

    return pageHeight;
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
      const container = document.getElementById('wrapper');
      if (container) container.style.height = 'auto';
    };
  }, []);

  const editorContextState: EditorContextStateP1 = {
    asinMetaData,
    setAsinMetaData,
    workflowOptions,
    setWorkFlowOptions,
    fileUploadEvent,
    setFileUploadEvent,
    disableControl,
    setDisableControl,
    promptUpdateEvent,
    setPromptUpdateEvent,
    activateGuide,
    setActivateGuide,
    rawJobResponse,
    setRawJobResponse,
  };

  const bulkUpdateCallback = (newState: Controls) => {
    setWorkFlowOptions({ ...workflowOptions, ...newState });
  };

  const appendGeneratedResults = (results: WorkflowResult) => {
    setGeneratedResults(results); //setGeneratedResults({ ...generatedResults, ...results });
  };

  const appendGeneratedTextResults = (results: TextResults) => {
    setGeneratedTextResults({ ...generatedTextResults, ...results });
  };

  const getWorkflows = async (selectedTool: string) => {
    try {
      const response = await genAIBackendClient.retrieveWorkflowsList({
        body: { workflowType: selectedTool as WorkflowId },
      });
      const result: WorkflowListResponse = response.body;

      if (result?.data?.[0]?.controls) {
        const defaults: Controls = {};
        const controls = result.data[0]?.controls;
        controls
          .filter((control) => control.defaultValue !== undefined)
          .map((control) => {
            defaults[control.controlName] = { value: control.defaultValue };
          });
        setControls(controls);
      }

      if (result?.data?.[0]?.guide?.length) {
        setGuide(result?.data?.[0]?.guide);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (!selectedTool) {
      const storedTool = localStorage.getItem('selectedTool');
      if (storedTool) {
        appContext.setSelectedTool(storedTool); // make sure we remember what it is
      }
    } else {
      getWorkflows(selectedTool);
    }
  }, [selectedTool]);

  const workFlowResultsMetaData: Record<
    string,
    {
      children: FC[];
    }
  > = {
    LIFESTYLE_IMAGERY: { children: [UsePrompt] },
  };

  return (
    <EditorContext.Provider value={editorContextState}>
      {controls.length > 0 && (
        <Stack
          className={style.flexContainer}
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'nowrap',
            alignItems: 'flex-start',
            position: 'relative',
          }}
        >
          <LeftPanel>
            <ControlPanel
              controls={controls}
              appendGeneratedResults={appendGeneratedResults}
              appendGeneratedTextResults={appendGeneratedTextResults}
              bulkUpdateCallback={bulkUpdateCallback}
              type={type}
            />
          </LeftPanel>

          <div style={{ width: '100%' }}>
            <RightPanel ref={rightPanelRef}>
              <WorkflowResults result={generatedResults} guide={guide} textResults={generatedTextResults}>
                {selectedTool &&
                  workFlowResultsMetaData[selectedTool] &&
                  workFlowResultsMetaData[selectedTool].children.map((Child: FC<{ result: WorkflowResult }>, index) => (
                    <Child key={`editor${index}`} result={generatedResults} />
                  ))}
              </WorkflowResults>
            </RightPanel>
          </div>
        </Stack>
      )}
    </EditorContext.Provider>
  );
};

export default Editor;
