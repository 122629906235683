import { useEffect } from 'react';
import { useFeedbackContext } from 'src/components/feedback/FeedbackContext';
import { useAssetCache } from 'src/v2/hooks/useAssetCache/useAssetCache';
import { useDeleteAsset } from 'src/v2/hooks/useDeleteAsset/useDeleteAsset';
import { useDownloadAsset } from 'src/v2/hooks/useDownloadAsset/useDownloadAsset';
import { useSaveAsset } from 'src/v2/hooks/useSaveAsset/useSaveAsset';
import { useAppDispatch } from 'src/v2/redux/hooks';
import { setEditBaseAssetId } from 'src/v2/redux/slices/edit/editSlice';
import { AssetModalView } from 'src/v2/redux/slices/edit/editSlice.types';
import { reuseSettingsFromAsset } from 'src/v2/redux/slices/userInput/userInputSlice';
import { FeedbackScopeEnum, FrontendAsset } from 'src/v2/types';
import { getAssetTextPrompt } from 'src/v2/utils/FrontendAssetUtils';

export const useOverlayActions = ({ asset }: { asset: FrontendAsset }) => {
  const appDispatch = useAppDispatch();
  const cachedAssetUrl = useAssetCache({ asset });
  const downloadAssetHook = useDownloadAsset({ asset });
  const deleteAssetHook = useDeleteAsset({ asset });
  const saveAssetHook = useSaveAsset({ asset });
  const feedbackContext = useFeedbackContext({
    feedbackScope: FeedbackScopeEnum.ASSET,
    // TODO: store asset feedback in a new Feedback Slice until backend can support persisting Feedback, store it in Local Storage
    // onSubmittedFeedback: (submittedFeedback) => {},
  });

  // - Update FeedbackContext with Asset details
  useEffect(() => {
    feedbackContext?.setFeedbackMetadata({
      assetUrl: asset.url,
      cachedAssetUrl,
      prompt: getAssetTextPrompt({ asset }),
      workflowId: asset.workflowId,
      referenceId: asset.id,
      assetType: asset.type,
    });
  }, [asset, cachedAssetUrl]);

  const editAsset = () => {
    appDispatch(setEditBaseAssetId({ assetId: asset.id, assetModalView: AssetModalView.EDIT }));
  };

  const previewAsset = () => {
    appDispatch(setEditBaseAssetId({ assetId: asset.id, assetModalView: AssetModalView.PREVIEW }));
  };

  const reuseAssetSettings = () => {
    appDispatch(reuseSettingsFromAsset({ asset }));
  };

  return {
    feedbackContext,
    deleteAssetHook,
    saveAssetHook,
    deleteAsset: deleteAssetHook.deleteAsset,
    downloadAsset: downloadAssetHook.downloadAsset,
    editAsset,
    previewAsset,
    reuseAssetSettings,
    saveAssetToAssetLibrary: saveAssetHook.saveAsset,
  };
};
