import Genaihubbackend, {
  AsinCategory,
  RetrieveResultByWorkflowIdAndBatchIdOutput,
  SubmitWorkflowByIdInput,
  WorkflowId,
} from '@amzn/genaihub-typescript-client';
import { WorkflowSubmission } from 'src/components/utils/WorkflowSubmission';
import { sanitiseText } from 'src/helpers';
import { MetricsProvider } from 'src/metrics';
import { getProcessedImageFromLayout } from 'src/v2/components/studio/productSelector/productSelectorHelpers';
import { MultiProductWorkflowOptions, PromptType } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { normalizeWorkflowOptions } from 'src/v2/contexts/backend/utils/WorkflowOptions.utils';
import type { AsinProduct } from 'src/v2/redux/slices/product/productSlice.types';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { ProductLayoutFull } from 'src/v2/redux/slices/userInput/userInputSlice.types';
import { AsinCategoryEnum, ASINItem, AspectRatio, WorkflowIdEnum } from 'src/v2/types';

export interface WorkflowInvocationJob {
  input: SubmitWorkflowByIdInput;
  prompt?: string;
  output: RetrieveResultByWorkflowIdAndBatchIdOutput;
}

export interface TextToImageWorkflowProps {
  asinItem: ASINItem;
  asinCategory?: AsinCategory;
  client: Genaihubbackend;
  productImage: string;
  aspectRatio?: string;
  effects?: string;
  outputCount?: number;
  prompt?: string;
  theme?: string;
  adsEntityId?: string;
  referenceImages?: string[];
  referenceImagesStrength?: number[];
}

export interface LifestyleImageryWorkflowProps {
  client: Genaihubbackend;
  asinItem?: ASINItem;
  asinCategory?: AsinCategory;
  aspectRatio?: string;
  effects?: string;
  outputCount?: number;
  prompt?: string;
  theme?: string;
  rewriteUserCustomPrompt?: boolean;
  adsEntityId?: string;
  asinImageReferenceId?: string;
}

export interface TextGenerationWorkflowProps {
  client: Genaihubbackend;
  asinItem?: ASINItem;
  outputCount?: number;
  isProductLessLifestyle?: boolean;
  prompt?: string;
  theme?: string;
  asinImageReferenceId?: string;
}

export interface MultiProductWorkflowProps {
  client: Genaihubbackend;
  layouts: ProductLayoutFull[];
  prompt?: string;
  theme?: string;
  aspectRatio: AspectRatio;
  outputCount?: number;
  adsEntityId?: string;
  uploadedAssets: {
    [id: string]: string;
  };
}

export class WorkflowService {
  private readonly metrics?: MetricsProvider;
  private currentSubmissions: WorkflowSubmission[];

  constructor(metrics?: MetricsProvider) {
    this.currentSubmissions = [];
    this.metrics = metrics;
  }

  async invokeTextToImageWorkflow(props: TextToImageWorkflowProps): Promise<WorkflowInvocationJob> {
    const {
      asinItem,
      asinCategory,
      client,
      productImage,
      aspectRatio,
      effects,
      outputCount = 1,
      prompt,
      theme,
      adsEntityId,
      referenceImages,
      referenceImagesStrength,
    } = props;
    const textToImageWorkflowId: WorkflowId = WorkflowIdEnum.TEXT_TO_IMAGE;
    const apparelWorkflowId: WorkflowId = WorkflowIdEnum.APPAREL;
    const workflowId = asinCategory === AsinCategoryEnum.APPAREL ? apparelWorkflowId : textToImageWorkflowId;

    let textPrompt = prompt;

    // If no text prompt then generate one
    if (!textPrompt) {
      const jobs = (
        await this.invokeTextGenerationWorkflow({
          asinItem,
          client,
          asinImageReferenceId: productImage,
        })
      ).output.body.jobs;
      if (jobs && jobs.length > 0 && jobs[0].urls && jobs[0].urls.length > 0) {
        textPrompt = jobs[0].urls[0];
      }
    }
    const sanitisedPrompt = sanitiseText(textPrompt || '');
    const constructedPrompt = this.constructInputPrompt(sanitisedPrompt, effects);
    const workflowOptions = {
      text_prompt: constructedPrompt,
      product_image: productImage,
      reference_image: '',
      themes: theme || 'no_theme',
      aspect_ratio: aspectRatio || AspectRatio.SQUARE_1_TO_1,
      scaling: '',
      rotate: '',
      num_of_images: outputCount,
      asin: asinItem?.asin || '',
      asin_type: asinCategory || AsinCategoryEnum.NONE,
      reference_images: referenceImages?.length ? referenceImages : undefined,
      reference_images_strength: referenceImagesStrength?.[0],
    };

    const input: SubmitWorkflowByIdInput = {
      workflowId,
      studioRequest: true,
      body: {
        workflowOptions,
      },
    };
    const currentSubmission = new WorkflowSubmission({
      workflowId,
      client,
      metrics: this.metrics,
      entityId: adsEntityId,
      studioRequest: true,
    }).verbose();
    this.currentSubmissions.push(currentSubmission);

    try {
      const summary = await currentSubmission.submitJob(workflowOptions);
      this.removeSubmission(currentSubmission);
      return {
        input,
        prompt: textPrompt,
        output: summary.output,
      };
    } catch (error) {
      this.removeSubmission(currentSubmission);
      throw error;
    }
  }

  async invokeLifestyleImageryWorkflow(props: LifestyleImageryWorkflowProps): Promise<WorkflowInvocationJob> {
    const {
      asinItem,
      asinCategory,
      client,
      aspectRatio,
      effects,
      outputCount = 1,
      prompt,
      theme,
      rewriteUserCustomPrompt = true,
      adsEntityId,
      asinImageReferenceId,
    } = props;
    const workflowId: WorkflowId = WorkflowIdEnum.LIFESTYLE_IMAGERY;
    const sanitisedPrompt = prompt && sanitiseText(prompt);
    const workflowOptions = {
      asin: asinItem?.asin || ' ',
      asin_ref: asinItem?.asin || ' ',
      asin_type: asinCategory || AsinCategoryEnum.NONE,
      ...(asinImageReferenceId ? { product_image: asinImageReferenceId } : {}),
      feature_bullets: (asinItem?.metadata?.featureBullets || [' ']).map((item) => sanitiseText(item)),
      image_count: outputCount,
      prompt: this.constructInputPrompt(sanitisedPrompt, effects),
      rewriteUserCustomPrompt: rewriteUserCustomPrompt.toString(),
      theme: theme || 'no_theme',
      aspect_ratio: aspectRatio || AspectRatio.SQUARE_1_TO_1,
      title: sanitiseText(asinItem?.metadata?.title || ' '),
    };
    const input: SubmitWorkflowByIdInput = {
      workflowId,
      studioRequest: true,
      body: {
        workflowOptions,
      },
    };
    const currentSubmission = new WorkflowSubmission({
      workflowId,
      client,
      metrics: this.metrics,
      entityId: adsEntityId,
      studioRequest: true,
    }).verbose();
    this.currentSubmissions.push(currentSubmission);

    try {
      const summary = await currentSubmission.submitJob(workflowOptions);
      this.removeSubmission(currentSubmission);
      return {
        input,
        prompt: sanitisedPrompt,
        output: summary.output,
      };
    } catch (error) {
      this.removeSubmission(currentSubmission);
      throw error;
    }
  }

  async invokeMultiProductGenerationWorkflow(props: MultiProductWorkflowProps): Promise<WorkflowInvocationJob> {
    const { client, layouts, prompt, aspectRatio, adsEntityId, theme = 'no_theme', outputCount, uploadedAssets = {} } = props;
    type ProductInput = MultiProductWorkflowOptions['products'][number];
    const workflowId = WorkflowIdEnum.MULTI_PRODUCT;
    const products = (
      await Promise.all(
        layouts.map(async (layout, index) => {
          const { image, mask } = getProcessedImageFromLayout(layout) || {};
          if (!image || !mask) return;
          const imageId = uploadedAssets[image];
          const maskId = uploadedAssets[mask];
          if (!imageId || !maskId) return;
          const skipDetails = index !== 0;
          if (layout.product.type === ProductType.ASIN) {
            const product = layout.product as AsinProduct;
            return {
              asin: product.asin,
              image: imageId,
              mask: maskId,
              boundingBox: {
                ...layout.boundingBox,
                layer: layout.zIndex,
              },
              prompt: prompt || '',
              features: product.metadata.featureBullets,
              theme,
              description: product.metadata.description,
              title: product.metadata.title || '',

              ...(skipDetails
                ? {
                    prompt: 'Skipped',
                    features: undefined,
                    description: 'Skipped',
                    title: 'Skipped',
                    theme: 'no_theme',
                  }
                : {}),
            } satisfies ProductInput;
          } else {
            return {
              asin: undefined,
              image: imageId,
              mask: maskId,
              boundingBox: {
                ...layout.boundingBox,
                layer: layout.zIndex,
              },
              theme,
              prompt: prompt || '',
              features: [],
              title: '',
              description: '',
            } satisfies ProductInput;
          }
        }),
      )
    ).filter((value: ProductInput | undefined) => !!value) as ProductInput[];
    if (products.length === 0) {
      throw new Error('No products found');
    }
    const workflowOptions: MultiProductWorkflowOptions = {
      workflowId: WorkflowIdEnum.MULTI_PRODUCT,
      numberOfImages: (outputCount || 6) >= 6 ? 6 : outputCount,
      products,
      aspectRatio,
    };
    const body = {
      workflowOptions: normalizeWorkflowOptions({
        workflowOptions,
      }),
    };
    const currentSubmission = new WorkflowSubmission({
      workflowId,
      client,
      metrics: this.metrics,
      studioRequest: true,
      entityId: adsEntityId,
    }).verbose();
    this.currentSubmissions.push(currentSubmission);
    const input: SubmitWorkflowByIdInput = {
      workflowId: workflowId,
      studioRequest: true,
      body,
    };
    try {
      const summary = await currentSubmission.submitJob(body.workflowOptions);
      this.removeSubmission(currentSubmission);
      return {
        input,
        output: summary.output,
      };
    } catch (error) {
      this.removeSubmission(currentSubmission);
      throw error;
    }
  }

  async invokeTextGenerationWorkflow(props: TextGenerationWorkflowProps): Promise<WorkflowInvocationJob> {
    const { asinItem, client, outputCount = 1, prompt = '', theme, isProductLessLifestyle = false, asinImageReferenceId } = props;
    const workflowId: WorkflowId = WorkflowIdEnum.GUIDED_TEXT_GENERATION;
    const workflowOptions = {
      asin: asinItem?.asin,
      asin_ref: asinItem?.asin,
      feature_bullets: asinItem?.metadata?.featureBullets?.map((item) => sanitiseText(item)),
      prompt_count: outputCount,
      product_image: asinImageReferenceId,
      title: sanitiseText(asinItem?.metadata?.title || ''),
      ...(isProductLessLifestyle ? { prompt_type: { value: PromptType.PRODUCT_LESS_LIFESTYLE } } : {}),
      prompt: prompt,
      theme: theme || 'no_theme',
    };
    const input: SubmitWorkflowByIdInput = {
      workflowId: workflowId,
      studioRequest: true,
      body: {
        workflowOptions,
      },
    };
    const currentSubmission = new WorkflowSubmission({ workflowId, client, metrics: this.metrics, studioRequest: true }).verbose();
    this.currentSubmissions.push(currentSubmission);

    try {
      const summary = await currentSubmission.submitJob(workflowOptions);
      this.removeSubmission(currentSubmission);
      return {
        input,
        output: summary.output,
      };
    } catch (error) {
      this.removeSubmission(currentSubmission);
      throw error;
    }
  }

  private constructInputPrompt(textPrompt?: string, effects?: string): string {
    if (textPrompt && effects) {
      return [effects, textPrompt].join(', ');
    } else if (textPrompt) {
      return textPrompt;
    } else if (effects) {
      return effects;
    } else return '';
  }

  private removeSubmission(submission: WorkflowSubmission) {
    const index = this.currentSubmissions.indexOf(submission);
    if (index > -1) {
      this.currentSubmissions.splice(index, 1);
    }
  }

  abandon() {
    if (this.currentSubmissions.length > 0) {
      this.currentSubmissions.forEach((submission) => submission.abandon());
      this.currentSubmissions = [];
      return true;
    } else {
      return false;
    }
  }
}
