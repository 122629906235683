export enum KeyboardKey {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  SPACEBAR = ' ',
}

export enum KeyboardCode {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  SPACEBAR = ' ',
}

export enum KeyboardCharCode {
  ENTER = 13,
  ESCAPE = 27,
  SPACEBAR = 32,
}
