import {
  AddEditModeSettings,
  AssetModalView,
  EditSliceState,
  LiveEditModeSettings,
  ReframeEditModeSettings,
  RemixEditModeSettings,
  RemoveEditModeSettings,
  RestyleEditModeSettings,
  ScaleEditModeSettings,
} from 'src/v2/redux/slices/edit/editSlice.types';
import { CameraPath, EditMode } from 'src/v2/types';

export function getInitialState(): EditSliceState {
  return {
    assetGenerationId: undefined,
    assetGenerationSelectedAssetIndex: -1,
    assetModalView: AssetModalView.PREVIEW,
    baseAssetId: undefined,
    editMode: EditMode.RESTYLE,
    publishToAssetLibraryBackendRequestId: undefined,
    publishToFeedBackendRequestId: undefined,
    settingsByEditMode: {
      [EditMode.ADD]: getInitialAddEditModeSettings(),
      [EditMode.LIVE]: getInitialLiveEditModeSettings(),
      [EditMode.REFRAME]: getInitialReframeEditModeSettings(),
      [EditMode.REMIX]: getInitialRemixEditModeSettings(),
      [EditMode.REMOVE]: getInitialRemoveEditModeSettings(),
      [EditMode.RESTYLE]: getInitialRestyleEditModeSettings(),
      [EditMode.SCALE]: getInitialScaleEditModeSettings(),
    },
  };
}

export function getInitialAddEditModeSettings(): AddEditModeSettings {
  return {
    editMode: EditMode.ADD,
    editSelection: undefined,
    textPrompt: undefined,
  };
}

export function getInitialLiveEditModeSettings(): LiveEditModeSettings {
  return {
    editMode: EditMode.LIVE,
    cameraPath: CameraPath.ZOOM_IN,
    editSelection: undefined,
    motionIntensity: 0.5,
    outDuration: 4,
  };
}

export function getInitialReframeEditModeSettings(): ReframeEditModeSettings {
  return {
    editMode: EditMode.REFRAME,
    aspectRatio: undefined,
    resizeDirection: undefined,
  };
}

export function getInitialRemixEditModeSettings(): RemixEditModeSettings {
  return {
    editMode: EditMode.REMIX,
    textPrompt: undefined,
  };
}

export function getInitialRemoveEditModeSettings(): RemoveEditModeSettings {
  return {
    editMode: EditMode.REMOVE,
    editSelection: undefined,
  };
}

export function getInitialRestyleEditModeSettings(): RestyleEditModeSettings {
  return {
    editMode: EditMode.RESTYLE,
    style: undefined,
    styleStrength: 0.85,
  };
}

export function getInitialScaleEditModeSettings(): ScaleEditModeSettings {
  return {
    editMode: EditMode.SCALE,
    boundingBox: undefined,
    originalBoundingBox: undefined,
  };
}
