/**
 * Q: Why does this file exist?
 *
 * A: The Backend Typescript Client defines all it's enums as Ambient Enums (uses 'declare' keyword), which only provides compile-time safety. This allows the enum object to be undefined at runtime and can cause a TypeError. To minimize churn associated with those Ambient Enums, the relevant enums from the Backend Typescript Client are declared here to minimize any maintenance burden.
 */

// Same as AsinCategoryEnum from Backend Typescript Client
export enum AsinCategoryEnum {
  APPAREL = 'APPAREL',
  NONE = 'NONE',
  RESTRICTED = 'RESTRICTED',
}

// Same as BatchResultJobsItemStatusEnum from Backend Typescript Client
export enum JobStatusEnum {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  HALTED = 'HALTED',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
}

// Same as TypeEnum from Backend Typescript Client
export enum AssetTypeEnum {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
}

// Same as CategoryEnum from Backend Typescript Client
export enum CategoryEnum {
  PRODUCT_IMAGE = 'PRODUCT_IMAGE',
  REFERENCE_IMAGE = 'REFERENCE_IMAGE',
  MASK_IMAGE = 'MASK_IMAGE',
}

// Same as FeedbackEnum from Backend Typescript Client
export enum FeedbackSentimentEnum {
  DISLIKE = 'DISLIKE',
  LIKE = 'LIKE',
  NEUTRAL = 'NEUTRAL',
}

// Same as ScopeEnum from Backend Typescript Client
export enum FeedbackScopeEnum {
  ASSET = 'ASSET',
  MODULE = 'MODULE',
  OTHER = 'OTHER',
}

// Same as WorkflowIdEnum from Backend Typescript Client
export enum WorkflowIdEnum {
  APPAREL = 'APPAREL',
  BRAND_IDENTITY_TEXT_TO_IMAGE = 'BRAND_IDENTITY_TEXT_TO_IMAGE',
  CONVERSATIONAL = 'CONVERSATIONAL',
  GENERATIVE_RESIZING = 'GENERATIVE_RESIZING',
  GUIDED_TEXT_GENERATION = 'GUIDED_TEXT_GENERATION',
  IMAGE_EDITOR = 'IMAGE_EDITOR',
  IMAGE_THEMING = 'IMAGE_THEMING',
  IMPORT_IMAGE = 'IMPORT_IMAGE',
  LIFESTYLE_IMAGERY = 'LIFESTYLE_IMAGERY',
  LIFESTYLE_IMAGERY_PROMPT_ONLY = 'LIFESTYLE_IMAGERY_PROMPT_ONLY',
  MULTI_PRODUCT = 'MULTI_PRODUCT',
  PARALLAX_MOTION = 'PARALLAX_MOTION',
  PRODUCT_EDIT = 'PRODUCT_EDIT',
  STUDIO = 'STUDIO',
  TEXT_TO_IMAGE = 'TEXT_TO_IMAGE',
}
