import { BatchResult, BoundingBox, PublishAssetInput } from '@amzn/genaihub-typescript-client';
import { Modal, TabbedNavigationItem } from '@amzn/storm-ui';
import _cloneDeep from 'lodash/cloneDeep';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { TabbedNavigation } from 'src/components/common/storm/TabbedNavigation';
import { ImageModalCloseConfirmPopup } from 'src/components/imageModal/ImageModalCloseConfirmPopup';
import { SaveImageToAccountButton } from 'src/components/imageModal/SaveImageToAccountButton';
import { SaveImageToAccountDropdown } from 'src/components/imageModal/SaveImageToAccountDropdown';
import { ContentItem, StudioContext, getValidFormatForStudioInputSettings } from 'src/components/pages/studio/StudioContext';
import {
  PublishAssetFailureNotification,
  PublishAssetProcessingNotification,
  PublishAssetSuccessNotification,
} from 'src/components/snackbar/notifications/PublishAssetNotifications';
import { getAssetTypeFromWorkflowId } from 'src/components/utils/assetUtils';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import Arrow from 'src/images/icons/arrow.svg';
import { isWeblabStateManagementInTreatment } from 'src/util/weblab/config';
import { isSponsoredCampaignLaunched } from 'src/util/weblab/weblab';
import { BLOCK_STUDIO_FILE_UPLOAD_DROPZONE_CLASSNAME } from 'src/v2/components/studio/fileUploadDropzone';
import { convertBackendAssetToFrontendAsset } from 'src/v2/contexts/feed/util/asset/Asset.utils';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { setEditBaseAssetId } from 'src/v2/redux/slices/edit/editSlice';
import { prependFeedAssets } from 'src/v2/redux/slices/feed/feedSlice';
import { getAsinMetadataMap } from 'src/v2/redux/slices/product/productSlice';
import { AssetTypeEnum, EditMode, FrontendAsset } from 'src/v2/types';
import { getFeedContentTypeByWorkflowId } from 'src/v2/utils/utils';
import { DownloadImageButton } from './DownloadImageButton';
import { ImageModalContext } from './ImageModalContext';
import { ImageModalEditTab } from './ImageModalEditTab';
import { ImageModalPreviewTab } from './ImageModalPreviewTab';
import styles from './styles.module.scss';

export const TEST_ID_IMAGE_MODAL_PREVIOUS_ARROW = 'image-modal-previous-arrow';
export const TEST_ID_IMAGE_MODAL_NEXT_ARROW = 'image-modal-next-arrow';

export const useImageModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return {
    isOpen,
    closeModal,
    openModal,
  };
};

export const STUDIO_PREVIEW_MODAL_ID = 'studio-preview-modal';

const PREVIEW_TAB_INDEX = 0;

const findUrl = (urls: string[] | undefined, searchUrl: string) => {
  if (!urls || !urls.length || !searchUrl) return;
  const searchUrlObj = new URL(searchUrl);
  const baseSearchUrl = searchUrlObj.origin + searchUrlObj.pathname;
  return urls.find((url) => url?.startsWith(baseSearchUrl));
};

export const findImageId = (url: string): string | undefined => {
  const pattern = /([^/]+\/[^/]+\/[^/]+)\.png/;
  const match = pattern.exec(url);
  if (match) {
    return match[1];
  }
};
export const ImageModal: FC<{
  currentItem?: ContentItem;
  isOpen: boolean;
  currentTab?: number;
  closeModal: () => void;
  onCloseModal?: () => void;
}> = ({ currentItem, isOpen, currentTab, closeModal, onCloseModal }) => {
  const toggleRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<number>(currentTab ?? 0);
  const [imageModalCloseConfirmPopupCallback, setImageModalCloseConfirmPopupCallback] = useState<{ callback: () => void }>();
  const appContext = useContext(AppContext);
  const imageModalContext = useContext(ImageModalContext);
  const studioContext = useContext(StudioContext);
  const genAIBackendClient = useAIBackendHubClient();
  const downloadOrSaveImageUrl = imageModalContext.savedEditsImageUrl || imageModalContext.imageUrl;
  const canSaveAsset = appContext.hasWritePermissionToAssetLibrary;
  const { addProcessingNotification, addFailureNotification, addSuccessNotification } = useNotificationActions();
  const appDispatch = isWeblabStateManagementInTreatment() ? useAppDispatch() : undefined;
  const asinMetadataMap = isWeblabStateManagementInTreatment() ? useAppSelector(getAsinMetadataMap) : undefined;

  const cleanUpEditing = () => {
    // clean up any pending generation or editing state on close
    imageModalContext.setPendingGeneration(false);
    imageModalContext.setIsPublishingAsset(false);
    imageModalContext.setActiveEditResults(undefined);
    imageModalContext.setActiveEditErrorMessage(undefined);
    imageModalContext.setSavedEditsImageUrl(undefined);
    imageModalContext.setSavedEditProductMask(undefined);
    imageModalContext.setSavedEditProductBoundingBox(undefined);
    imageModalContext.setSavedEditsImageReferenceId(undefined);
    imageModalContext.setSavedEditsWorkflowId(undefined);
    imageModalContext.setSavedEditsBatchId(undefined);
    imageModalContext.setSavedEditsImageId(undefined);
    imageModalContext.setSavedEditsImageAspectRatio(undefined);
    imageModalContext.clearActiveEdit();
  };

  const localOnCloseModal = () => {
    setActiveTab(PREVIEW_TAB_INDEX);
    cleanUpEditing();
    closeModal();
    onCloseModal?.();
  };

  useEffect(() => {
    setActiveTab(currentTab ?? 0);
  }, [currentTab, isOpen]);

  const handleDiscardEdits = () => {
    imageModalContext.clearActiveEdit();
    imageModalCloseConfirmPopupCallback?.callback?.();
  };

  const handleAcceptEdits = async () => {
    const activeEditResults = imageModalContext.activeEditResults;
    const editMode = imageModalContext.editMode as EditMode;
    if (currentItem && imageModalContext.activeEditsImageUrl) {
      const workflowId = imageModalContext.activeEditsWorkflowId;
      if (!workflowId) return;

      imageModalContext.setIsPublishingAsset(true);

      const newImageUrl = imageModalContext.activeEditsImageUrl;
      const newImageOriginalUrl = imageModalContext.activeEditsOriginalImageUrl;
      const newImageReferenceId = imageModalContext.activeEditsImageReferenceId;
      const newImageAspectRatio = imageModalContext.activeEditsAspectRatio;
      const savedImageAspectRatio = imageModalContext.savedEditsImageAspectRatio;
      const newImageFeedback = _cloneDeep(imageModalContext.activeEditsImageFeedback);

      let studioInputSettings = _cloneDeep(currentItem.studioInputSettings);
      if (studioInputSettings) {
        studioInputSettings.format = getValidFormatForStudioInputSettings(
          editMode === EditMode.REFRAME ? newImageAspectRatio : (savedImageAspectRatio ?? currentItem?.aspectRatio),
        );
      }

      const newAssetType = getAssetTypeFromWorkflowId(workflowId);
      const newResult: ContentItem = {
        ..._cloneDeep(currentItem),
        content: newImageUrl,
        contentType: getFeedContentTypeByWorkflowId(workflowId),
        workflowId: workflowId,
        originalUri: newImageUrl,
        referenceId: newImageReferenceId,
        feedback: newImageFeedback,
        assetType: newAssetType,
        ...(editMode === EditMode.REFRAME
          ? { aspectRatio: newImageAspectRatio }
          : savedImageAspectRatio
            ? { aspectRatio: savedImageAspectRatio }
            : {}),
        studioInputSettings,
      };

      // Publish Asset
      const batchId = activeEditResults?.batchId;
      const jobId = activeEditResults!.jobs?.[0].jobId;

      const cleanupProcessingNotification = addProcessingNotification({
        SnackbarContent: PublishAssetProcessingNotification,
      });
      let isError = false;
      let imageId: string | undefined;
      let productMask: string | undefined;
      let productBoundingBox: BoundingBox | undefined;
      // NOTE: only used when State Management Weblab on
      let newFrontendAsset: FrontendAsset | undefined = undefined;
      const entityId = appContext.getEntityId();

      if (activeEditResults && newImageReferenceId && newImageOriginalUrl && batchId && jobId) {
        try {
          // Obtain a fresh signed URL from Catwalk
          const response: { body: BatchResult } = await genAIBackendClient.retrieveResultByWorkflowIdAndBatchId({
            workflowId,
            batchId,
            studioRequest: true,
          });
          const job = response.body?.jobs?.find((job) => job?.jobId === jobId);
          const freshImageOriginalUrl = findUrl(job?.urls, newImageOriginalUrl);
          const assetUrl = freshImageOriginalUrl ?? newImageOriginalUrl;
          newResult.originalUri = assetUrl;
          imageId = findImageId(assetUrl);
          productMask = job?.assets?.filter((asset) => asset.imageId === imageId)[0].productMask;
          productBoundingBox = job?.assets?.filter((asset) => asset.imageId === imageId)[0].boundingBox;
          const payload: PublishAssetInput = {
            workflowId,
            entityId,
            body: {
              feedOptions: {
                jobId,
                batchId,
                assetUrl,
                aspectRatio: newResult.aspectRatio,
                imageId,
                prompt: currentItem.studioInputSettings?.prompt,
              },
            },
          };
          const publishRespose = await genAIBackendClient.publishAsset(payload);
          let assetId;
          if (publishRespose.body?.assetId) {
            assetId = publishRespose.body.assetId;
            // @ts-ignore
          } else if (publishRespose.body?.body?.assetId) {
            // @ts-ignore
            assetId = publishRespose.body.body!.assetId;
          } else {
            throw new Error("Publish asset response missing 'assetId'");
          }
          newResult.referenceId = assetId;

          if (isWeblabStateManagementInTreatment()) {
            if (!asinMetadataMap) throw new Error('Expected ASIN metadata map to be defined');

            const result = await genAIBackendClient.retrieveAsset({ id: assetId, entityId });
            if (!result.body.asset) throw new Error(`Recently published asset not found: '${assetId}'`);
            newFrontendAsset = await convertBackendAssetToFrontendAsset({
              asinMetadataMap,
              backendAsset: result.body.asset,
            });
          }
        } catch (error) {
          isError = true;
          console.error('Failed to publish asset', error);
        }
      } else {
        isError = true;
        const missingData = [];
        if (!activeEditResults) missingData.push('Edit Results');
        if (!batchId) missingData.push('Batch ID');
        if (!jobId) missingData.push('Job ID');
        if (!newImageReferenceId) missingData.push('Image ID');
        if (!newImageOriginalUrl) missingData.push('Image URL');
        console.error('Failed to publish asset. Missing the following data:', missingData);
      }

      imageModalContext.setIsPublishingAsset(false);
      imageModalContext.setImageReferenceId(newResult.referenceId || imageModalContext.imageReferenceId);
      cleanupProcessingNotification();
      if (isError) {
        addFailureNotification({ SnackbarContent: PublishAssetFailureNotification });
      } else if (isWeblabStateManagementInTreatment()) {
        if (newFrontendAsset && appDispatch) {
          appDispatch(prependFeedAssets({ assets: [newFrontendAsset], entityId }));
          // Don't change the asset when it's VIDEO. Preserve the original asset to allow further edits.
          if (newFrontendAsset.type !== AssetTypeEnum.VIDEO) {
            // NOTE: there's a small papercut after accepting the asset. The previous asset will momentarily remain while React reconciles the change to "editSlice.baseAssetId"
            appDispatch(setEditBaseAssetId({ assetId: newFrontendAsset.id }));
          }
          imageModalContext.clearActiveEdit();
          imageModalCloseConfirmPopupCallback?.callback?.();
          // Send a success notification to the user
          addSuccessNotification({
            SnackbarContent: () => <PublishAssetSuccessNotification assetType={newAssetType} />,
          });
        } else {
          console.error('Expected newFrontendAsset and appDispatch to be defined');
          addFailureNotification({ SnackbarContent: PublishAssetFailureNotification });
        }
      } else {
        newResult.workflowId = workflowId;
        newResult.batchId = batchId;
        newResult.imageId = imageId;

        // need to overwrite date/time as timestamp is deep cloned from edited asset
        const currentDateTime = new Date();
        const dateTimeInEpochMilliseconds = currentDateTime.getTime();
        newResult.timestamp = `${dateTimeInEpochMilliseconds}`;

        // save edited results to feed
        studioContext.prependResults([newResult]);

        // do not update modal state for video edits. Preserve the input image state and save video into feeds only.
        if (newAssetType === AssetTypeEnum.VIDEO) {
          imageModalContext.clearActiveEdit();
          imageModalCloseConfirmPopupCallback?.callback?.();
        } else {
          imageModalContext.setSavedEditProductMask(productMask);
          imageModalContext.setSavedEditProductBoundingBox(productBoundingBox);
          imageModalContext.setSavedEditsImageUrl(newImageUrl);
          imageModalContext.setSavedEditsAssetType(newAssetType);
          imageModalContext.setSavedEditsImageReferenceId(newImageReferenceId);
          imageModalContext.setSavedEditsWorkflowId(workflowId);
          imageModalContext.setSavedEditsBatchId(batchId);
          imageModalContext.setSavedEditsImageId(imageId);
          // Only update the saved aspect ratio with a new value if edit mode was Reframe.
          // This ensures Reframe uses the correct aspect ratio on successive edits.
          imageModalContext.setSavedEditsImageAspectRatio(editMode === EditMode.REFRAME ? newImageAspectRatio : savedImageAspectRatio);
          imageModalContext.clearActiveEdit();
          imageModalCloseConfirmPopupCallback?.callback?.();
        }

        // Send a success notification to the user
        addSuccessNotification({
          SnackbarContent: () => <PublishAssetSuccessNotification assetType={newAssetType} />,
        });
      }
    } else {
      console.error('Failed to accept edit.');
      addFailureNotification({ SnackbarContent: PublishAssetFailureNotification });
      imageModalContext.clearActiveEdit();
      imageModalCloseConfirmPopupCallback?.callback?.();
    }
  };

  const currentAssetType = imageModalContext.savedEditsAssetType || imageModalContext.assetType;

  const tabs = [
    {
      label: 'Preview',
      child: <ImageModalPreviewTab currentItem={currentItem} />,
    },
    ...(currentAssetType !== AssetTypeEnum.VIDEO
      ? [
          {
            label: 'Edit',
            child: (
              <ImageModalEditTab
                currentItem={currentItem}
                handleAcceptEdits={handleAcceptEdits}
                openConfirmModal={(callback) => setImageModalCloseConfirmPopupCallback({ callback })}
              />
            ),
          },
        ]
      : []),
  ];

  const handleChangeActiveTab = (index: number) => {
    if (imageModalContext.unsavedWork) {
      setImageModalCloseConfirmPopupCallback({
        callback: () => {
          imageModalContext.clearActiveEdit();
          setActiveTab(index);
        },
      });
    } else {
      imageModalContext.clearActiveEdit();
      setActiveTab(index);
    }
  };

  return (
    <>
      <div id={STUDIO_PREVIEW_MODAL_ID} data-testid="studio-preview-modal" className={styles.imageModal}>
        <Modal
          isOpen={isOpen}
          onClose={() => {
            if (imageModalContext.unsavedWork || imageModalContext.pendingGeneration) {
              setImageModalCloseConfirmPopupCallback({ callback: localOnCloseModal });
            } else {
              localOnCloseModal();
            }
          }}
          header={
            <div className={styles.navigationHeader}>
              <TabbedNavigation>
                {tabs.map(({ label }, index) => (
                  <TabbedNavigationItem
                    className={styles.navItem}
                    data-testid={`studio-preview-modal-${label.toLowerCase()}-tab`}
                    role={'tab'}
                    name={label}
                    key={index}
                    label={label}
                    active={activeTab === index}
                    onClick={() => handleChangeActiveTab(index)}
                  />
                ))}
              </TabbedNavigation>
              <div className={styles.buttons}>
                {!!downloadOrSaveImageUrl && (
                  <>
                    <DownloadImageButton url={downloadOrSaveImageUrl} disabled={imageModalContext.isPublishingAsset} />
                    {isSponsoredCampaignLaunched() && (
                      <SaveImageToAccountDropdown assetUrl={downloadOrSaveImageUrl} disabled={!canSaveAsset || imageModalContext.isPublishingAsset} />
                    )}
                    {!isSponsoredCampaignLaunched() && (
                      <SaveImageToAccountButton assetUrl={downloadOrSaveImageUrl} disabled={!canSaveAsset || imageModalContext.isPublishingAsset} />
                    )}
                  </>
                )}
              </div>
            </div>
          }
          modalElementId="studio-preview-modal"
          contentClassName={BLOCK_STUDIO_FILE_UPLOAD_DROPZONE_CLASSNAME}
        >
          {tabs[activeTab].child}
        </Modal>
        {isOpen && activeTab === PREVIEW_TAB_INDEX && (
          <>
            <div
              data-testid={TEST_ID_IMAGE_MODAL_PREVIOUS_ARROW}
              aria-label="Show previous result"
              className={`${styles.arrowNavigation} ${styles.left}`}
              onClick={() => {
                // cleaning up since user leaves original active asset for editing
                cleanUpEditing();
                imageModalContext.handleSwitchToPrevContentItem();
              }}
              role="button"
            >
              <Arrow />
            </div>
            <div
              data-testid={TEST_ID_IMAGE_MODAL_NEXT_ARROW}
              aria-label="Show next result"
              className={`${styles.arrowNavigation} ${styles.right}`}
              onClick={() => {
                // cleaning up since user leaves original active asset for editing
                cleanUpEditing();
                imageModalContext.handleSwitchToNextContentItem();
              }}
              role="button"
            >
              <Arrow />
            </div>
          </>
        )}
      </div>
      <div id={'image-modal-close-confirm'} ref={toggleRef} />
      <ImageModalCloseConfirmPopup
        isOpen={!!imageModalCloseConfirmPopupCallback}
        onSave={() => {
          handleAcceptEdits();
        }}
        onDiscard={() => {
          handleDiscardEdits();
        }}
        onClose={() => {
          setImageModalCloseConfirmPopupCallback(undefined);
        }}
      />
    </>
  );
};
