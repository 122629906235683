import { useEffect } from 'react';
import { ControlComponentProps } from 'src/components/editor/UiContols/uiGeneratorControls/controlComponentTypes';
import batchStateUpdate from 'src/components/utils/batchStateUpdate';
import removePunctuationAndSpecialChars from 'src/components/utils/removePunctuationAndSpecialChars';

const HiddenAsin = ({ control, bulkUpdateCallback }: ControlComponentProps) => {
  useEffect(() => {
    publishAsin();
  }, []);

  const publishAsin = () => {
    const asin = 'B0CC62ZG1M';
    const title =
      'Fitbit Charge 6 Fitness Tracker with Google apps, Heart Rate on Exercise Equipment, 6-Months Premium Membership Included, GPS, Health Tools and More, Obsidian/Black, One Size ';
    const workflowOptions = {
      image_count: { value: 4 },
      title: { value: removePunctuationAndSpecialChars(title) },
      [control.controlName]: { value: asin },
    };
    batchStateUpdate(workflowOptions, bulkUpdateCallback, 500);
  };

  return <></>;
};

export default HiddenAsin;
