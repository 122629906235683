import { handleGenerateAssetsAction } from 'src/v2/contexts/assetGeneration/actions/GenerateAssets.action';
import {
  AssetGenerationActionType,
  GenerateAssetsAction,
  ImportStudioAssetAction,
  UploadedImageGenerationConfig,
} from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { SnackbarDispatchContextType } from 'src/v2/contexts/snackbar/SnackbarContext';
import { SnackbarActionAddNotification, SnackbarActionType, SnackbarType } from 'src/v2/contexts/snackbar/types';
import { updateOneAssetGeneration } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice';
import { addFeedAssetGenerationId, getFeedFetchEntityId, prependFeedAssets, removeFeedAssetGenerationId } from 'src/v2/redux/slices/feed/feedSlice';
import { AppStore } from 'src/v2/redux/store';
import { WorkflowIdEnum } from 'src/v2/types';
import { v6 as uuidV6 } from 'uuid';

export async function handleImportStudioAssetAction({
  action,
  appStore,
  backendDispatchContext,
  snackbarDispatchContext,
}: {
  action: ImportStudioAssetAction;
  appStore: AppStore;
  backendDispatchContext: BackendDispatchContextType;
  snackbarDispatchContext: SnackbarDispatchContextType;
}) {
  const requestId = uuidV6();
  action.onInit?.({ id: requestId });
  const { imageToImport } = action;
  const entityId = action.entityId ?? '';
  const uploadedImageGenerationConfig: UploadedImageGenerationConfig = {
    entityId,
    options: {
      progressCallback: (progress) => {
        appStore.dispatch(
          updateOneAssetGeneration({
            id: requestId,
            changes: {
              progress,
            },
          }),
        );
      },
    },
    outputCount: undefined,
    studioRequest: true,
    userInputs: {
      imageToImport,
    },
    workflowId: WorkflowIdEnum.IMPORT_IMAGE,
  };

  const handleAbort = () => {
    appStore.dispatch(removeFeedAssetGenerationId({ id: requestId }));
    action.onAbort?.();
  };

  const generateAssetsAction: GenerateAssetsAction = {
    type: AssetGenerationActionType.GENERATE_ASSETS,
    generationConfigs: [uploadedImageGenerationConfig],
    onAbort: () => {
      handleAbort();
    },
    onInit: () => {
      appStore.dispatch(addFeedAssetGenerationId({ id: requestId, entityId }));
    },
    onComplete: (props) => {
      const currentFeedEntityId = getFeedFetchEntityId(appStore.getState()) ?? '';
      if (currentFeedEntityId != entityId) {
        // If the account changed between the generation trigger and now, then treat as an abort
        handleAbort();
        return;
      }

      if (props.generatedAssets.length) appStore.dispatch(prependFeedAssets({ assets: props.generatedAssets, entityId }));
      appStore.dispatch(removeFeedAssetGenerationId({ id: requestId }));

      // TODO: create a more user-friendly notification
      props.errors.forEach((error) => {
        const addNotificationAction: SnackbarActionAddNotification = {
          type: SnackbarActionType.ADD_NOTIFICATION,
          payload: {
            id: uuidV6(),
            type: SnackbarType.ERROR,
            SnackbarContent: () => <>{error.message}</>,
          },
        };
        snackbarDispatchContext(addNotificationAction);
      });
      action.onComplete?.(props);
    },
  };

  handleGenerateAssetsAction({ action: generateAssetsAction, appStore, backendDispatchContext, requestId });
}
