import { Flex } from '@amzn/storm-ui';
import { useState } from 'react';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import styled from 'styled-components';
import { ProductWidgetProps, WidgetViewHeaderLabels } from './types';
import { WidgetHeader } from './WidgetHeader';
import { ProductSelector } from '../../productSelector/ProductSelector';

const ProductWidgetContainer = styled(Flex)<{ maxHeight: number }>`
  max-height: ${({ maxHeight }) => maxHeight}px;
`;

export const ProductWidget = ({ closeWidget, height, dataTestId }: ProductWidgetProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <ProductWidgetContainer maxHeight={height} direction="column" data-testid={dataTestId}>
      <WidgetHeader
        disabled={loading}
        dataTestId={dataTestId}
        label={WidgetViewHeaderLabels.Product}
        icon={iconTypes.products}
        closeWidget={closeWidget}
      />
      <ProductSelector setLoading={setLoading} />
    </ProductWidgetContainer>
  );
};
