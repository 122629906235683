import { ContentItem, Message as MessageModel, MessageRole, QuotedMessage, Topic } from '@amzn/red-carpet-service-client';
import { useMutation } from '@tanstack/react-query';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { redCarpetClient } from 'src/components/assistant/client';
import ChatHeader from 'src/components/assistant/components/chat/chatHeader';
import WelcomePage from 'src/components/assistant/components/welcomePage';
import {
  addNewMessages,
  setQueryEnabled,
  updateLoadingMessageConfig,
  useConversationsMessages,
} from 'src/components/assistant/queries/conversationMessages';
import { clearSuggestions, useConversationsQuery } from 'src/components/assistant/queries/conversationsList';
import { CHAT_THEME } from 'src/components/assistant/theme';
import useConversationId, { NEW_CHAT_PARAM_KEY } from 'src/components/assistant/utils/useConversationId';
import usePerConversationState from 'src/components/assistant/utils/usePerConversationState';
import { ConversationContext } from 'src/components/pages/conversational/ChatPage';
import { useContainerDimensions } from 'src/components/utils/ContainerDimensions';
import { Metrics } from 'src/constants';
import SpinnerIcon from 'src/icons/spinnerIcon';
import styled, { css } from 'styled-components';
import { v6 as uuidV6 } from 'uuid';
import MessageInput from '../messageInput';
import ChatMessages from './chatMessages';

const chatContainerStyle = css<{ sideNavWidth: number; isSideNavOpen: boolean; pageMargin: number }>`
  position: fixed;
  display: block;
  right: ${({ pageMargin }) => pageMargin}px;
  width: calc(100% - ${({ sideNavWidth, isSideNavOpen, pageMargin }) => (isSideNavOpen ? sideNavWidth + pageMargin * 2 : pageMargin * 2)}px);
  transition: width 0.3s ease-in;
`;

const ChatBorder = styled.div<{ headerHeight: number; sideNavWidth: number; isSideNavOpen: boolean; pageMargin: number }>`
  ${chatContainerStyle};
  bottom: 10px;
  height: calc(100vh - ${({ headerHeight }) => headerHeight + 10}px);
  background: radial-gradient(50% 57.34% at 50% 50%, #fbfbfc 32.07%, #f8f9fa 100%);
  border-radius: ${CHAT_THEME.border.rounded.radius};
`;

const FloatingWrapper = styled.div<{ headerHeight: number; sideNavWidth: number; isSideNavOpen: boolean; pageMargin: number }>`
  ${chatContainerStyle};
  bottom: 0;
  height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
  background: transparent;
  pointer-events: none;
`;

const FadeShared = css`
  background-color: rgb(248, 249, 250);
  width: 100%;
  position: absolute;
  border-radius: ${CHAT_THEME.border.rounded.radius};
`;

const FadeBottom = styled.div`
  ${FadeShared};
  bottom: 10px;
  height: 8em;
  mask-image: linear-gradient(transparent 0, #000 9rem);
`;

const FadeTop = styled.div`
  ${FadeShared};
  top: 0;
  height: 3em;
  mask-image: linear-gradient(#000 0, transparent 3rem);
`;

const BottomGutter = styled.div`
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 10px;
  background-color: #fff;
`;

const ChatWrapper = styled.div<{ headerHeight: number; sideNavWidth: number; isSideNavOpen: boolean; messageInputHeight: number }>`
  display: flex;
  justify-content: center;
  position: relative;
  width: calc(100% - ${({ sideNavWidth, isSideNavOpen }) => (isSideNavOpen ? sideNavWidth + 32 : 32)}px);
  height: auto;
  right: 0;
  padding: 60px 28px ${({ messageInputHeight }) => messageInputHeight}px 0;
  overflow-y: auto;
  scrollbar-width: thin;
  transition: width 0.5s ease-in;
`;

const MessagesWrapper = styled.div<{ showWelcomePage: boolean }>`
  overflow-y: ${({ showWelcomePage }) => (showWelcomePage ? 'none' : 'visible')};
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
`;

const WelcomePageWrapper = styled.div<{ headerHeight: number; sideNavWidth: number; isSideNavOpen: boolean; pageMargin: number }>`
  ${chatContainerStyle};
  bottom: 10px;
  height: calc(100vh - ${({ headerHeight }) => headerHeight + 10}px);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
`;

interface MessageMutationProps {
  messageContent: string;
  quotedMessage?: QuotedMessage;
}

export default function Chat({ pageMargin = 16 }: { pageMargin: number }) {
  const { conversationId, setConversationId } = useConversationId();
  const appContext = useContext(AppContext);
  const {
    metrics,
    userDetails,
    headerDimensions: { pageHeaderHeight, navHeaderHeight },
  } = useContext(AppContext);
  const { sideNavWidth, isSideNavOpen, messageInputHeight } = useContext(ConversationContext);
  const [quotedMessage, setQuotedMessage] = usePerConversationState<QuotedMessage>(conversationId);
  const [messageInput, setMessageInput] = usePerConversationState<string>(conversationId, '');
  const [snapToBottom, setSnapToBottom] = useState(true);
  const [isErrorMessagePresented, setIsErrorMessagePresented] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const chatWrapperRef = React.useRef<HTMLDivElement>(null);
  const { height: chatWrapperHeight } = useContainerDimensions(chatWrapperRef);
  // const showChat = !compactScreen || !isSideNavOpen;

  const createUserMessage = (messageContent: string): MessageModel => ({
    role: MessageRole.USER,
    content: [{ text: { content: messageContent } } as ContentItem],
    messageId: uuidV6(),
    conversationId: conversationId,
    createdAt: new Date(),
  });

  const createQuotedMessage = (message: QuotedMessage): MessageModel => ({
    role: MessageRole.ASSISTANT,
    content: message.content,
    messageId: uuidV6(),
    conversationId: conversationId,
    createdAt: new Date(),
    quotedMessage: message.quotedMessageId,
  });

  const conversationMessagesQuery = useConversationsMessages(conversationId, userDetails?.alias);
  const conversationsListQuery = useConversationsQuery(userDetails?.alias);
  const conversationMetadata = useMemo(
    () => conversationsListQuery.data?.find((item) => item.conversationId === conversationId),
    [conversationsListQuery.data, conversationId],
  );

  const messageMutation = useMutation({
    mutationFn: ({ messageContent, quotedMessage }: MessageMutationProps) => {
      const entityId = appContext?.selectedAdvertisingAccount?.alternateIds?.[0];
      return redCarpetClient.sendMessage([{ text: { content: messageContent } }], conversationId, userDetails?.alias, quotedMessage, entityId);
    },
    onMutate: ({ messageContent, quotedMessage }: MessageMutationProps) => {
      const newMessages: MessageModel[] = [];
      if (quotedMessage) newMessages.push(createQuotedMessage(quotedMessage));
      newMessages.push(createUserMessage(messageContent));

      addNewMessages(conversationId, newMessages);
      updateLoadingMessageConfig(conversationId, { active: true, persona: undefined, text: 'Thinking' });

      setMessageInput('');
      setQuotedMessage(undefined);
      clearSuggestions(conversationId);
      setSnapToBottom(true);
    },
    onSuccess: ({ imagesCount, textMessagesCount, newConversation }) => {
      setQueryEnabled(conversationId, true);
      metrics.trackMetrics(
        Metrics.Methods.ChatMessages,
        {},
        {
          ...(newConversation ? { [Metrics.Counters.Conversations]: 1 } : {}),
          [Metrics.Counters.ImagesGenerated]: imagesCount,
          [Metrics.Counters.UserMessages]: 1,
          [Metrics.Counters.AgentMessages]: textMessagesCount,
        },
        { [Metrics.Dimensions.ConversationId]: conversationId || '' },
      );
    },
    onError: () => {
      addNewMessages(conversationId, [
        {
          messageId: `${Date.now()}`,
          conversationId,
          createdAt: new Date(),
          role: 'assistant',
          content: [
            {
              text: {
                content: 'The service is presently unavailable, please try again later.',
              },
            },
          ],
        },
      ]);
    },
  });

  const sendMessage = (messageText?: string, quote?: QuotedMessage) => {
    const messageContent = messageText ? messageText : messageInput;
    if (!messageContent) return;
    quote = quote ? quote : quotedMessage;
    messageMutation.mutate({ messageContent: messageContent, quotedMessage: quote });
  };

  const sendReplyMessage = (quotedMessage: QuotedMessage, messageText: string) => {
    sendMessage(messageText, quotedMessage);
  };

  const showLoadingMessage = messageMutation.isPending && conversationMessagesQuery.data?.loadingMessageConfig?.active;

  useEffect(() => {
    // focus on the input after the final message, this can't be done as a call back in the client file as the input won't be active yet
    if (!conversationMessagesQuery.data?.loadingMessageConfig?.active) {
      inputRef.current?.focus();
    }
  }, [showLoadingMessage]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [messageInput]);

  useEffect(() => {
    if (conversationMessagesQuery.error && !isErrorMessagePresented) {
      setIsErrorMessagePresented(true);
      addNewMessages(conversationId, [
        {
          messageId: `${Date.now()}`,
          conversationId,
          createdAt: new Date(),
          role: 'assistant',
          content: [
            {
              text: {
                content: 'An error ocurred while fetching the conversation history, please refresh the page or try again later!',
              },
            },
          ],
        },
      ]);
    }
  }, [conversationMessagesQuery.error]);

  useEffect(() => {
    if (snapToBottom) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [chatWrapperHeight, messageInputHeight]);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [isSideNavOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const pageEnd = document.body.offsetHeight;

      if (pageEnd - scrollPosition < 10) {
        setSnapToBottom(true);
      } else {
        setSnapToBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const startConversationMutation = useMutation({
    mutationFn: ({ topic }: { topic: Topic }) => {
      if (userDetails?.alias == undefined) throw new Error('User not found');
      return redCarpetClient.startConversation(userDetails.alias, topic);
    },
    onSuccess: ({ conversationId, messages }) => {
      if (conversationId) {
        // TODO: start conversation metric tracker here
        metrics.trackMetrics(
          Metrics.Methods.ChatMessages,
          {},
          { [Metrics.Counters.Conversations]: 1 },
          { [Metrics.Dimensions.ConversationId]: conversationId },
        );
        setConversationId(conversationId);
        if (messages) addNewMessages(conversationId, messages);
      }
    },
  });

  const startConversation = (topic: Topic) => {
    startConversationMutation.mutate({ topic });
  };

  const showWelcomePage =
    conversationId === NEW_CHAT_PARAM_KEY && conversationMessagesQuery.isFetched && conversationMessagesQuery.data?.messages?.length === 0;

  return (
    <>
      <ChatBorder
        headerHeight={pageHeaderHeight + navHeaderHeight}
        sideNavWidth={sideNavWidth}
        isSideNavOpen={isSideNavOpen}
        pageMargin={pageMargin}
      />
      {showWelcomePage && (
        <WelcomePageWrapper
          headerHeight={pageHeaderHeight + navHeaderHeight}
          sideNavWidth={sideNavWidth}
          isSideNavOpen={isSideNavOpen}
          pageMargin={pageMargin}
        >
          <WelcomePage startConversation={startConversation} loading={startConversationMutation.isPending} />
        </WelcomePageWrapper>
      )}
      {
        <ChatWrapper
          ref={chatWrapperRef}
          sideNavWidth={sideNavWidth}
          isSideNavOpen={isSideNavOpen}
          headerHeight={pageHeaderHeight + navHeaderHeight}
          messageInputHeight={messageInputHeight}
        >
          {(conversationMessagesQuery.isLoading && (
            <SpinnerIcon style={{ width: '50px', height: '50px', marginBottom: '10%' }} strokeWidth={14} />
          )) || (
            <MessagesWrapper showWelcomePage={showWelcomePage}>
              {conversationMessagesQuery.data?.messages && (
                <ChatMessages
                  messages={conversationMessagesQuery.data?.messages}
                  showLoadingMessage={showLoadingMessage}
                  setReplyMessage={setQuotedMessage}
                  sendReplyMessage={sendReplyMessage}
                />
              )}
            </MessagesWrapper>
          )}
        </ChatWrapper>
      }

      <FloatingWrapper
        headerHeight={pageHeaderHeight + navHeaderHeight}
        sideNavWidth={sideNavWidth}
        isSideNavOpen={isSideNavOpen}
        pageMargin={pageMargin}
      >
        <BottomGutter />
        <FadeTop />
        <FadeBottom />
        <ChatHeader />
        <MessageInput
          disabled={showLoadingMessage || startConversationMutation.isPending}
          messageText={messageInput}
          setMessageText={setMessageInput}
          sendMessage={sendMessage}
          suggestions={conversationMetadata?.suggestions}
          replyMessage={quotedMessage}
          clearQuoteMessage={() => setQuotedMessage(undefined)}
          inputRef={inputRef}
        />
      </FloatingWrapper>
    </>
  );
}
