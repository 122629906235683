import { Flex } from '@amzn/storm-ui';
import { Update } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/merge';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'src/AppContext';
import {
  ContentItem,
  GenerationJobStatusEnum,
  getProductImageDetails,
  StudioContext,
  StudioInputSettings,
} from 'src/components/pages/studio/StudioContext';
import { WorkflowInvocationJob, WorkflowService } from 'src/components/pages/studio/WorkflowService';

import * as notifications from 'src/components/snackbar/notifications/ImageGenerationNotifications';
import { urlToFile } from 'src/components/utils/base64Encode';
import { uploadImage } from 'src/components/utils/uploadImage';
import { generateLifestyleImageryWorkflowProps, generateTextToImageWorkflowProps } from 'src/components/utils/workflowHelpers';
import { Metrics } from 'src/constants';
import { waitUntilImagesLoaded } from 'src/helpers';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import {
  AICS_WEBLAB_DARK_MODE,
  isWeblabStateManagementInTreatment,
  AICS_WEBLAB_STUDIO_REFERENCE_IMAGES,
  WeblabTreatment,
} from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { GenerateButton } from 'src/v2/components/GenerateButton/GenerateButton';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { ReferenceWidget } from 'src/v2/components/studio/WidgetContainer/Widgets/Reference/Reference';
import { Thumbnail } from 'src/v2/components/Thumbnail/Thumbnail';
import { IconPositions, ThumbnailType } from 'src/v2/components/Thumbnail/types';
import { AssetGenerationDispatchContext } from 'src/v2/contexts/assetGeneration/AssetGenerationContext';
import { AssetGenerationActionType, GenerateStudioAssetsAction } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { MultiProductWorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { SnackbarNotification } from 'src/v2/contexts/snackbar/types';
import { useWindowSize } from 'src/v2/hooks/useWindowSize/useWindowSize';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { AssetGenerationStatus } from 'src/v2/redux/slices/assetGenerations/assetGenerationsSlice.types';
import { getFeedAssetGenerations } from 'src/v2/redux/slices/feed/feedSlice';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import {
  getAllFullProductLayout,
  getAspectRatio,
  getAutoLayoutEnabled,
  getEffectSelections,
  getReferenceImages,
  getStyleSelection,
  getTextPrompt,
  setSelectedProduct,
  setTextPrompt,
  updateManyProductLayout,
} from 'src/v2/redux/slices/userInput/userInputSlice';
import { ProductLayout } from 'src/v2/redux/slices/userInput/userInputSlice.types';
import { AssetTypeEnum, CategoryEnum, ContentType, GenerateOptionType, WorkflowIdEnum } from 'src/v2/types';
import { BouncingDiv, duration, FadingDiv } from 'src/v2/utils/animation';
import { getProductImageUrl, getProductTitle } from 'src/v2/utils/Product.utils';
import { getFeedContentTypeByWorkflowId } from 'src/v2/utils/utils';
import CreateContentDropdown from './CreateContentDropdown/CreateContentDropdown';
import { getAspectRatioIcon, getGenerateBlockedReason } from './helpers';
import {
  BannerAlertView,
  DividerVertical,
  DividerView,
  InputField,
  InputFieldArea,
  InputFieldWrapper,
  TooltipWrapper,
  WidgetContainer,
  WidgetContainerBody,
  WidgetContainerFooter,
  WidgetControlAspectRatioText,
  WidgetControlWrapper,
} from './WidgetContainer.styles';
import { AspectRatioWidget } from './Widgets/AspectRatio/AspectRatio';
import { EffectsWidget } from './Widgets/Effects/Effects';
import { ProductWidget } from './Widgets/Product';
import { StylingWidget } from './Widgets/Styling/Styling';
import { WidgetTypes, WidgetViews } from './Widgets/types';
import { MultiThumbnailDisplayProps } from '../../MultiThumbnailDisplay/MultiThumbnailDisplay';
import { getProcessedImageFromLayout, removeImageBackgroundForLayout } from '../productSelector/productSelectorHelpers';
import { SelectedItemTypes, SelectedInputsList } from './SelectedInputsList/SelectedInputsList';

export const STUDIO_GENERATE_BUTTON = 'studio-generate-button';

// Arrays for placeholder generation
const PRODUCT_IMAGE_CONTENT_TYPE = 'product image';
const LIFESTYLE_IMAGE_CONTENT_TYPE = 'lifestyle image';
const ASIN_CONTENT_TYPE = 'ASIN';
const SIX_ALL_LIFESTYLE_CONTENT_TYPES = [...Array(6)].fill(LIFESTYLE_IMAGE_CONTENT_TYPE);
const SIX_ALL_PRODUCT_CONTENT_TYPES = [...Array(6)].fill(PRODUCT_IMAGE_CONTENT_TYPE);
const SIX_ASIN_CONTENT_TYPE = [...Array(6)].fill(ASIN_CONTENT_TYPE);

export type GenerateOptions = {
  [key in GenerateOptionType]?: boolean;
};

const METRIC_NAME_MAP = {
  [GenerateOptionType.GENERATE_LIFESTYLE_IMAGES]: 'Lifestyle',
  [GenerateOptionType.GENERATE_PRODUCT_IMAGES]: 'Product',
} as const;

export const prepareMultipleProductGenerationResultsForRender = async ({ result }: { result: WorkflowInvocationJob }): Promise<ContentItem[]> => {
  const contentType = ContentType.PRODUCT_IMAGE;
  const generationPrompt = result.prompt;
  const output = result.output;
  const input = result.input;
  const workflowOptions = input.body?.workflowOptions;
  if (!workflowOptions) return [];
  const jobs = output.body.jobs || [];
  const promises: Promise<ContentItem>[] = jobs.flatMap((job) => {
    return job.urls
      ? job.urls.map(async (url, index) => {
          const contentFile = await urlToFile(url, 'image/png');
          return {
            originalUri: url,
            content: URL.createObjectURL(contentFile),
            referenceId: job.ids?.[index],
            aspectRatio: (workflowOptions as MultiProductWorkflowOptions).aspectRatio,
            loading: false,
            contentHeadline: generationPrompt,
            contentType,
            studioInputSettings: {},
            workflowId: result.input.workflowId,
            imageId: job.assets?.[index].imageId,
            batchId: output.body.batchId,
            assetType: AssetTypeEnum.IMAGE,
          } as ContentItem;
        })
      : [];
  });
  return await Promise.all(promises);
};

const WidgetContainerView = () => {
  const studioContext = useContext(StudioContext);
  const inputPanelCollapsedHeight = 0;
  const inputPanelExpandedHeight = 260;

  const [currentWindowWidth, currentWindowHeight] = useWindowSize();
  const [windowWidth, setWindowWidth] = useState<number>(currentWindowWidth || 0);
  const [windowHeight, setWindowHeight] = useState<number>(currentWindowHeight || 0);
  const [containerHeight, setContainerHeight] = useState<number>(inputPanelCollapsedHeight);
  const [startingHeight, setStartingHeight] = useState<number>(inputPanelCollapsedHeight);
  const [activeWidget, setActiveWidget] = useState<WidgetTypes | null>(null);
  const [widgetView, setWidgetView] = useState<WidgetViews>(WidgetViews.Collapsed);
  const [containerFocused, setContainerFocused] = useState<boolean>(false);
  const [textInputLengthError, setTextInputLengthError] = useState(false);
  const [inputTextPrompt, setInputTextPrompt] = useState<string>();
  const [selectedInputItems, setSelectedInputItems] = useState<SelectedItemTypes[]>([]);
  const [selectedInputItemsHeight, setSelectedInputItemsHeight] = useState<number>(0);

  const textPrompt = useAppSelector(getTextPrompt);
  const effects = useAppSelector(getEffectSelections);
  const style = useAppSelector(getStyleSelection);
  const aspectRatio = useAppSelector(getAspectRatio);
  const fullLayouts = useAppSelector(getAllFullProductLayout);
  const referenceImages = useAppSelector(getReferenceImages);
  const autoLayoutEnabled = useAppSelector(getAutoLayoutEnabled);
  const feedAssetGenerations = useAppSelector(getFeedAssetGenerations);

  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = (isDarkModeLaunched && useAppSelector(isDarkModeActive)) || false;

  const isCollapsedView = widgetView === WidgetViews.Collapsed;
  const isExpandedView = widgetView === WidgetViews.Expanded;
  const isFullView = widgetView === WidgetViews.Full;

  const inputPanelFullHeight = windowHeight - Math.floor(windowHeight * 0.3); // max panel height = viewport minus 30%
  const actionBarHeight = 42;
  const containerAnimationSpeed = duration.deliberate;
  const fadingSpeed = duration.fast;
  const isMobile = windowWidth < 1100;

  const textInputRef = useRef<HTMLInputElement>(null);
  const processingNotificationTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const selectedAsin = studioContext.asin;
  const productImageDetails = selectedAsin ? getProductImageDetails(selectedAsin) : undefined;

  const promptPlaceholderText = isMobile
    ? 'Add a product or prompt'
    : isCollapsedView
      ? 'To start, add a product or prompt'
      : 'Add a product or descriptive prompt';

  /** Generation related **/
  const selectedWidgetRef = useRef<HTMLDivElement>(null);
  const backendClient = useAIBackendHubClient();
  const assetGenerationDispatchContext = useContext(AssetGenerationDispatchContext);
  const currentWorkflowService = useRef<WorkflowService | null>(null);
  const appContext = useContext(AppContext);
  const { addFailureNotification } = useNotificationActions();

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [selectedGenerateOption, setSelectedGenerateOption] = useState<GenerateOptionType>(GenerateOptionType.GENERATE_PRODUCT_IMAGES);
  const generateOptions = {
    [GenerateOptionType.GENERATE_PRODUCT_IMAGES]: true,
    [GenerateOptionType.GENERATE_LIFESTYLE_IMAGES]: true,
  };
  const isGenerationRunning = isWeblabStateManagementInTreatment()
    ? // For single generation, we just need to find one generation that's PENDING and not an IMPORT_IMAGE workflow
      !!feedAssetGenerations.find(
        (feedAssetGeneration) =>
          feedAssetGeneration.status === AssetGenerationStatus.PENDING &&
          feedAssetGeneration.action.generationConfigs.find((generationConfig) => generationConfig.workflowId !== WorkflowIdEnum.IMPORT_IMAGE),
      )
    : studioContext.generationJob?.status === GenerationJobStatusEnum.RUNNING ||
      studioContext.generationJob?.status === GenerationJobStatusEnum.LOADING_ASIN;

  const shouldShowReferenceImagery =
    isWeblabInTreatment(AICS_WEBLAB_STUDIO_REFERENCE_IMAGES, WeblabTreatment.T1) &&
    selectedGenerateOption == GenerateOptionType.GENERATE_PRODUCT_IMAGES;
  const disabledReferenceImagery = fullLayouts.length !== 1 || !autoLayoutEnabled; // since multiple product doesn't work with reference imagery yet

  // listens for clicks outside of Widget container and collapses it
  const handleGlobalClickEvent = useCallback(() => {
    if (!isCollapsedView && !containerFocused) {
      closeWidget();
    }
  }, [containerFocused]);

  useEffect(() => {
    if (fullLayouts.length > 0) {
      const product = fullLayouts[0].product;
      if (product && product.id !== studioContext.asin?.asin) {
        if (product.type === ProductType.ASIN) {
          studioContext?.setAsin(product);
        } else if (product.type === ProductType.CUSTOM_IMAGE) {
          studioContext?.setAsin?.({
            asin: '',
            customImage: product.customImage,
            customProductImageUrl: product.customImage.url,
            metadata: {},
          });
        }
        dispatch(setSelectedProduct(product));
      }
    } else {
      studioContext?.setAsin(undefined);
      dispatch(setSelectedProduct(undefined));
    }
  }, [fullLayouts]);

  // adds global Widget container focus event listener
  useEffect(() => {
    document.addEventListener('click', handleGlobalClickEvent);
    return () => {
      document.removeEventListener('click', handleGlobalClickEvent);
    };
  });

  const calculateContainerHeight = (value: number) => {
    const singleSelectedItemsRowHeight = 48; // TODO: move to global container constants (based off SelectedInputList view)
    return inputPanelExpandedHeight + value - singleSelectedItemsRowHeight;
  };

  // checks and updates window size state for height calculations
  useEffect(() => {
    if (windowWidth !== currentWindowWidth) {
      setWindowWidth(currentWindowWidth);
    }
    if (windowHeight !== currentWindowHeight) {
      setWindowHeight(currentWindowHeight);
    }
  }, [currentWindowWidth, currentWindowHeight]);

  // handles selected inputs list height changes
  useEffect(() => {
    const calculatedInputHeight = calculateContainerHeight(selectedInputItemsHeight);
    if (widgetView === WidgetViews.Full) {
      setStartingHeight(containerHeight);
    }

    if (containerHeight > calculatedInputHeight) {
      setStartingHeight(containerHeight);
    }

    if (widgetView === WidgetViews.Expanded) {
      if (startingHeight <= calculatedInputHeight && containerHeight !== calculatedInputHeight) {
        setStartingHeight(containerHeight);
      }
      setContainerHeight(calculatedInputHeight);
    }
  }, [selectedInputItemsHeight]);

  // handle background removal for reuse settings
  useEffect(() => {
    const layoutsWithProductBackground = fullLayouts.filter((layout) => !getProcessedImageFromLayout(layout));

    const removeAllBackgrounds = async () => {
      const layoutChanges: Update<ProductLayout, ProductLayout['id']>[] = [];
      for await (const layout of layoutsWithProductBackground) {
        const updateLayout = await removeImageBackgroundForLayout({ layout, backendClient });
        if (updateLayout) {
          layoutChanges.push({
            id: layout.id,
            changes: {
              processedImages: {
                ...updateLayout.processedImages,
              },
            },
          });
        }
      }
      if (layoutChanges.length === 0) return;
      dispatch(updateManyProductLayout(layoutChanges));
    };

    removeAllBackgrounds();
  }, [fullLayouts]);

  // apply selected items to the widget container
  useEffect(() => {
    const products: MultiThumbnailDisplayProps['images'] = fullLayouts
      .map((layout) => {
        const { image } = getProcessedImageFromLayout(layout) || {};
        return {
          src: image || 'loading',
          id: layout.id,
          title: getProductTitle({ product: layout.product }).split(' ').slice(0, 4).join(' '),
        };
      })
      .filter((obj): obj is MultiThumbnailDisplayProps['images'][number] => !!obj);

    let savedSelections = [];
    if (style && !_isEmpty(style)) {
      savedSelections.push({ style });
    }
    if (effects && !_isEmpty(effects)) {
      savedSelections.push({ effects });
    }

    if (products && !_isEmpty(products)) {
      savedSelections.push({ products });
    }

    const referenceImagesList = referenceImages.map((referenceImage) => ({
      id: referenceImage.id,
      strength: referenceImage.strength?.toString() || 'not selected',
      src: referenceImage.url,
    }));

    if (referenceImagesList && !_isEmpty(referenceImagesList)) {
      savedSelections.push({ referenceImages: referenceImagesList });
    }

    // Hide incompatible inputs when generation type is lifestyle but still allow removals
    if (selectedInputItems && selectedGenerateOption === GenerateOptionType.GENERATE_LIFESTYLE_IMAGES) {
      const selectedEffects = selectedInputItems.filter((item) => 'effects' in item).map((item) => (item as { effects: unknown }).effects)[0];
      const selectedProducts = selectedInputItems.filter((item) => 'products' in item).map((item) => (item as { products: unknown[] }).products)[0];

      // handle removals in filtered view
      if ((selectedProducts && !_isEqual(products.length, selectedProducts.length)) || !_isEqual(selectedEffects, effects)) {
        setSelectedInputItems(savedSelections);
      }

      const filteredSelectedItems = (items: SelectedItemTypes[]) => {
        return items.filter((item) => !('style' in item) && !('referenceImages' in item));
      };
      setSelectedInputItems(filteredSelectedItems);
    } else {
      setSelectedInputItems(savedSelections);
    }
  }, [effects, style, fullLayouts, referenceImages, selectedGenerateOption]);

  // clears the product data if layouts is empty
  useEffect(() => {
    if (fullLayouts.length === 0) {
      studioContext.setAsin(undefined);
    }
  }, [fullLayouts]);

  // sets input text to redux state or surfaces error if over max characters.
  useEffect(() => {
    if (inputTextPrompt && inputTextPrompt.length > 0) {
      if (inputTextPrompt.length >= 1000) {
        setTextInputLengthError(true);
      } else {
        setTextInputLengthError(false);
        studioContext.setTextPrompt(inputTextPrompt);
        dispatch(setTextPrompt(inputTextPrompt));
      }
    }
  }, [inputTextPrompt]);

  // listens for when re-use settings is triggered and populates text input field
  useEffect(() => {
    if (textPrompt && textPrompt.length > 0) {
      setInputTextPrompt(textPrompt);
    }
  }, [textPrompt]);

  // used to determine whether assets can be generated or not (button enablement)
  useEffect(() => {
    if (shouldSubmit) {
      submitGenerationRequest(selectedGenerateOption);
      setShouldSubmit(false);
    }
  }, [shouldSubmit, studioContext.textPrompt, selectedGenerateOption, fullLayouts]);

  // resize the main widget container view
  const openWidget = (height: number) => {
    let panelHeight = height;
    if (widgetView === WidgetViews.Full) {
      setStartingHeight(containerHeight);
    }

    if (widgetView === WidgetViews.Expanded) {
      panelHeight = isMobile ? windowHeight - actionBarHeight : height;
      setStartingHeight(containerHeight + actionBarHeight);
    }

    if (widgetView === WidgetViews.Collapsed) {
      setStartingHeight(inputPanelCollapsedHeight);
    }
    setContainerHeight(panelHeight);
  };

  // closes main widget view
  const closeWidget = () => {
    if (widgetView === WidgetViews.Full) {
      setWidgetView(WidgetViews.Expanded);
      setStartingHeight(isMobile ? inputPanelFullHeight : containerHeight - actionBarHeight);

      if (selectedInputItems.length > 0 && selectedInputItemsHeight > 0) {
        setContainerHeight(calculateContainerHeight(selectedInputItemsHeight));
      } else {
        setContainerHeight(inputPanelExpandedHeight);
      }
    }

    if (widgetView === WidgetViews.Expanded) {
      setStartingHeight(inputPanelExpandedHeight);
      setContainerHeight(inputPanelCollapsedHeight);
      setWidgetView(WidgetViews.Collapsed);
    }
  };

  // the actual widget view that is rendered when a control icon is clicked
  const getWidgetView = () => {
    switch (activeWidget) {
      case WidgetTypes.Product:
        return <ProductWidget closeWidget={closeWidget} dataTestId="product-widget-view" height={containerHeight} openWidget={openWidget} />;
      case WidgetTypes.AspectRatio:
        return <AspectRatioWidget closeWidget={closeWidget} dataTestId="aspect-ratio-widget-view" height={containerHeight} openWidget={openWidget} />;
      case WidgetTypes.Reference:
        return <ReferenceWidget closeWidget={closeWidget} dataTestId="reference-widget-view" height={containerHeight} />;
      case WidgetTypes.Styling:
        return <StylingWidget closeWidget={closeWidget} dataTestId="styles-widget-view" height={containerHeight} openWidget={openWidget} />;
      case WidgetTypes.Effects:
        return <EffectsWidget closeWidget={closeWidget} dataTestId="effects-widget-view" height={containerHeight} openWidget={openWidget} />;
      default:
        return <ProductWidget closeWidget={closeWidget} dataTestId="product-widget-view" height={containerHeight} openWidget={openWidget} />;
    }
  };

  const getContainerTestId = () => {
    if (WidgetViews.Full) {
      return 'widget-container-full-view';
    }
    if (WidgetViews.Expanded) {
      return 'widget-container-expanded-view';
    }
    return 'widget-container-collapsed-view';
  };

  // the list of icons along the bottom of the input widget container
  const getExpandedViewControls = () => {
    return (
      <>
        <WidgetControlWrapper isExpanding={true} speed={fadingSpeed}>
          <Button
            dataTestId="product-widget-control"
            icon={iconTypes.products}
            iconOnly
            iconOnlyNoBackground
            popoverText="Add a product"
            onClick={() => {
              setActiveWidget(WidgetTypes.Product);
              setWidgetView(WidgetViews.Full);
              openWidget(inputPanelFullHeight);
              appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.OpenProductView]: 1 });
            }}
          />
        </WidgetControlWrapper>
        <WidgetControlWrapper isExpanding={true} speed={fadingSpeed}>
          <DividerVertical />
        </WidgetControlWrapper>
        {shouldShowReferenceImagery && (
          <WidgetControlWrapper isExpanding={true} speed={fadingSpeed}>
            <Button
              dataTestId="reference-widget-control"
              icon={iconTypes.image}
              iconOnly
              iconOnlyNoBackground
              disabled={disabledReferenceImagery}
              popoverText={`Add reference imagery${disabledReferenceImagery ? ' (requires one product)' : ''}`}
              onClick={() => {
                setActiveWidget(WidgetTypes.Reference);
                setWidgetView(WidgetViews.Full);
                openWidget(inputPanelFullHeight);
                appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.OpenReferenceImageView]: 1 });
              }}
            />
          </WidgetControlWrapper>
        )}
        {selectedGenerateOption === GenerateOptionType.GENERATE_PRODUCT_IMAGES && (
          <WidgetControlWrapper isExpanding={true} speed={fadingSpeed}>
            <Button
              dataTestId="styles-widget-control"
              icon={iconTypes.styles}
              iconOnly
              iconOnlyNoBackground
              popoverText={`Add a style`}
              onClick={() => {
                setActiveWidget(WidgetTypes.Styling);
                setWidgetView(WidgetViews.Full);
                openWidget(inputPanelFullHeight);
                appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.OpenStyleView]: 1 });
              }}
            />
          </WidgetControlWrapper>
        )}
        <WidgetControlWrapper isExpanding={true} speed={fadingSpeed}>
          <Button
            dataTestId="effects-widget-control"
            icon={iconTypes.effects}
            iconOnly
            iconOnlyNoBackground
            popoverText={`Add effects`}
            onClick={() => {
              setActiveWidget(WidgetTypes.Effects);
              setWidgetView(WidgetViews.Full);
              openWidget(inputPanelFullHeight); // initial desktop height
              appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.OpenEffectsView]: 1 });
            }}
          />
        </WidgetControlWrapper>
        <WidgetControlWrapper isExpanding={true} speed={fadingSpeed}>
          <DividerVertical />
        </WidgetControlWrapper>
        <WidgetControlWrapper
          data-testid="aspect-ratio-widget-control"
          isExpanding={true}
          speed={fadingSpeed}
          onClick={() => {
            setActiveWidget(WidgetTypes.AspectRatio);
            setWidgetView(WidgetViews.Full);
            openWidget(350); // initial desktop height
            appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.OpenAspectRatioView]: 1 });
          }}
        >
          <Button
            icon={getAspectRatioIcon(aspectRatio)}
            iconOnly
            iconOnlyNoBackground
            popoverText="Select aspect ratio"
            onClick={() => {
              setActiveWidget(WidgetTypes.AspectRatio);
              setWidgetView(WidgetViews.Full);
              openWidget(350); // initial desktop height
              appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.OpenAspectRatioView]: 1 });
            }}
          />
          <WidgetControlAspectRatioText>{aspectRatio}</WidgetControlAspectRatioText>
        </WidgetControlWrapper>
      </>
    );
  };

  const isGenerateEnabled = () => {
    // Check if generation is already active
    if (isGenerationRunning) return false;

    // Check if every layout has background removed
    const isAnyLayoutNotReady = fullLayouts.filter((layout) => !getProcessedImageFromLayout(layout)).length > 0;
    if (isAnyLayoutNotReady) return false;

    let generationReady = false;

    if (selectedGenerateOption === GenerateOptionType.GENERATE_PRODUCT_IMAGES) {
      if (fullLayouts.length === 0) {
        generationReady = false;
      }

      if (fullLayouts.length > 0) {
        generationReady = true;
      }
    }

    if (selectedGenerateOption === GenerateOptionType.GENERATE_LIFESTYLE_IMAGES) {
      if (inputTextPrompt && inputTextPrompt.length > 0) {
        generationReady = true;
      }
    }

    return generationReady;
  };

  const handleGenerateAction = () => {
    // set all old studio context state until fully switched over to new redux central state management
    studioContext.setFormat(aspectRatio);
    studioContext.setTextPrompt(inputTextPrompt);
    studioContext.setStyle(style);
    studioContext.setAsin(selectedAsin);
    const effectsMap = effects ? new Map(Object.entries(effects)) : new Map();
    studioContext.setEffects(effectsMap);

    // handle metrics tracking for generate requests
    const metricName = METRIC_NAME_MAP[selectedGenerateOption];
    appContext.metrics.trackMetrics(Metrics.Methods.GenerateButton, { [Metrics.Names.GenerationType]: metricName }, { [Metrics.Counters.Count]: 1 });

    setShouldSubmit(true);
  };

  /********************* GENERATION CODE ************************/
  const submitGenerationRequestWithStateManagement = async (generateOptionOverride?: GenerateOptionType) => {
    const generateStudioAssetsAction: GenerateStudioAssetsAction = {
      type: AssetGenerationActionType.GENERATE_STUDIO_ASSETS,
      accountType: appContext.accountType,
      entityId: appContext.getEntityId(),
      generateOptionOverride,
    };
    await assetGenerationDispatchContext(generateStudioAssetsAction);
  };

  const submitGenerationRequestWithoutStateManagement = async (generateOptionOverride?: GenerateOptionType) => {
    if (!isGeneratedEnabled()) {
      return;
    }

    studioContext.setGenerationJob({ status: GenerationJobStatusEnum.RUNNING });

    const generateMultipleProductImage =
      fullLayouts.length >= 1 && !autoLayoutEnabled && selectedGenerateOption === GenerateOptionType.GENERATE_PRODUCT_IMAGES;

    const baseStudioInputSettings: StudioInputSettings = {
      asin: _cloneDeep(studioContext.asin),
      effects: _cloneDeep(studioContext.effects),
      enablePromptRewriting: studioContext.enablePromptRewriting,
      format: studioContext.format,
      prompt: '',
      style: _cloneDeep(studioContext.style),
      // Only capture advanced mode state if it's enabled
      advanced: studioContext.enableAdvancedMode
        ? {
            negativePrompt: studioContext.negativePrompt,
            seed: studioContext.seed,
            temperature: studioContext.temperature,
          }
        : undefined,
    };

    const workflowService = (currentWorkflowService.current = new WorkflowService(appContext.metrics));
    const results: WorkflowInvocationJob[] = [];
    let asinImageReferenceId = '';
    let isJobRunning = true;
    let referenceImagesId: string[] = [];
    let referenceImagesStrength: number[] = [];

    // timeout increased for the case where we run both text generation and image generation workflows in sequence.
    const delayForTimeout = selectedAsin && !studioContext.textPrompt ? 150000 : 90000;
    const processingNotificationTimeout = (processingNotificationTimeoutRef.current = setTimeout(() => {
      if (isJobRunning) {
        isJobRunning = false;
        setFailedJobStatus(notifications.SubmitTimeoutNotification, notifications.FAILURE_MESSAGE.TIME_OUT);
      }
    }, delayForTimeout)); // Clear this when leaving page
    const uploadedAssets: Record<string, string> = {};
    try {
      if (productImageDetails && selectedGenerateOption === GenerateOptionType.GENERATE_PRODUCT_IMAGES) {
        studioContext.clearGenerationPlaceholders();
        studioContext.setGenerationPlaceholders(
          SIX_ASIN_CONTENT_TYPE.map((contentType) => ({ contentType, loading: true, aspectRatio: studioContext.format })),
        );
        const asinImageFile = await urlToFile(productImageDetails.url);
        asinImageReferenceId = await uploadImage({
          file: asinImageFile,
          backendClient,
          contentCategory: CategoryEnum.PRODUCT_IMAGE,
        });
        uploadedAssets[productImageDetails?.url] = asinImageReferenceId;
      }
      if (generateMultipleProductImage) {
        const imageSet = new Set<string>();
        const maskSet = new Set<string>();
        for (const layout of fullLayouts) {
          const { image, mask } = getProcessedImageFromLayout(layout) || {};
          if (!image || !mask) continue;
          imageSet.add(image);
          maskSet.add(mask);
        }
        const imagesUploadPromises = Array.from(imageSet.values()).map(async (image) => {
          uploadedAssets[image] = await uploadImage({
            file: await urlToFile(image, 'image/png'),
            backendClient,
            contentCategory: 'PRODUCT_IMAGE',
          });
        });
        const masksUploadPromises = Array.from(maskSet.values()).map(async (mask) => {
          uploadedAssets[mask] = await uploadImage({
            file: await urlToFile(mask, 'image/png'),
            backendClient,
            contentCategory: 'MASK_IMAGE',
          });
        });

        await Promise.all([...imagesUploadPromises, ...masksUploadPromises]);
      }

      if (referenceImages) {
        for (const referenceImage of referenceImages) {
          const url = referenceImage.url;
          const file = await urlToFile(url);
          const refId = await uploadImage({
            file: file,
            backendClient,
            contentCategory: CategoryEnum.REFERENCE_IMAGE,
          });
          referenceImagesId.push(refId);
          if (referenceImage.strength) {
            referenceImagesStrength.push(referenceImage.strength);
          }
        }
      }
      const effects = Array.from(studioContext.effects?.values() || [])
        .map((opt) => opt.value)
        .join(', ');
      const entityId = appContext.selectedAdvertisingAccount?.alternateIds?.[0];
      const textGenerationInputs = {
        asinItem: selectedAsin,
        asinImageReferenceId: asinImageReferenceId,
        client: backendClient,
        theme: studioContext.style?.customThemePrompt,
        outputCount: 6,
      };

      const invokeMultiProductGeneration = async (prompt: string, outputCount: number, theme: string = 'no_theme') => {
        return await workflowService.invokeMultiProductGenerationWorkflow({
          client: backendClient,
          layouts: fullLayouts,
          prompt,
          aspectRatio,
          theme,
          outputCount,
          adsEntityId: entityId,
          uploadedAssets,
        });
      };
      // Overrides for if generate options are used
      if (generateOptionOverride) {
        const placeholderType =
          generateOptionOverride === GenerateOptionType.GENERATE_LIFESTYLE_IMAGES ? SIX_ALL_LIFESTYLE_CONTENT_TYPES : SIX_ALL_PRODUCT_CONTENT_TYPES;

        studioContext.clearGenerationPlaceholders();
        studioContext.setGenerationPlaceholders(
          placeholderType.map((contentType) => ({
            contentType,
            loading: true,
            aspectRatio: baseStudioInputSettings.format,
          })),
        );

        let prompts: string[] = [];
        if (studioContext.textPrompt) {
          prompts = [studioContext.textPrompt];
        } else {
          const textGenerationResponses = await workflowService.invokeTextGenerationWorkflow({
            ...textGenerationInputs,
            isProductLessLifestyle: generateOptionOverride === GenerateOptionType.GENERATE_LIFESTYLE_IMAGES,
          });
          prompts = textGenerationResponses.output.body.jobs?.[0].urls || [];
        }

        let generationPromises = [];
        const outputCount = prompts.length === 1 ? 6 : 1;

        if (generateOptionOverride === GenerateOptionType.GENERATE_PRODUCT_IMAGES) {
          generationPromises = prompts.map((prompt) => {
            if (generateMultipleProductImage) {
              return invokeMultiProductGeneration(prompt, outputCount);
            }
            return workflowService.invokeTextToImageWorkflow(
              generateTextToImageWorkflowProps({
                backendClient,
                studioContext,
                effects,
                asinImageReferenceId,
                entityId,
                prompt,
                outputCount,
                referenceImagesStrength,
                referenceImages: referenceImagesId,
              }),
            );
          });
        } else {
          generationPromises = prompts.map((prompt) => {
            return workflowService.invokeLifestyleImageryWorkflow(
              generateLifestyleImageryWorkflowProps({
                backendClient,
                studioContext,
                effects,
                entityId,
                outputCount,
                asinImageReferenceId,
                prompt,
              }),
            );
          });
        }

        const imageGenerationResponses = await Promise.allSettled(generationPromises);
        results.push(
          ...(imageGenerationResponses
            .map((res) => (res.status === 'rejected' ? undefined : res.value))
            .filter((e) => e !== undefined) as WorkflowInvocationJob[]),
        );
      } else if (selectedAsin && studioContext.style) {
        // Generate 6 PlaceHolders for Product images
        studioContext.setGenerationPlaceholders(
          SIX_ALL_PRODUCT_CONTENT_TYPES.map((contentType) => ({ contentType, loading: true, aspectRatio: baseStudioInputSettings.format })),
        );
        if (generateMultipleProductImage) {
          results.push(await invokeMultiProductGeneration(studioContext.textPrompt || '', 6));
        } else {
          results.push(
            await workflowService.invokeTextToImageWorkflow(
              generateTextToImageWorkflowProps({ backendClient, studioContext, effects, asinImageReferenceId, entityId }),
            ),
          );
        }
      } else if (selectedAsin) {
        const outputCounts: number[] = [2, 3, 1];
        const textGenerationInputs = {
          asinItem: selectedAsin,
          asinImageReferenceId: asinImageReferenceId,
          client: backendClient,
        };
        // Generate 6 prompts for ASIN
        const textGenerationResponses = await Promise.allSettled([
          // 2 with product no theme
          workflowService.invokeTextGenerationWorkflow({
            ...textGenerationInputs,
            theme: studioContext.style?.customThemePrompt,
            outputCount: outputCounts[0],
          }),
          // 3 product-less
          workflowService.invokeTextGenerationWorkflow({
            ...textGenerationInputs,
            theme: studioContext.style?.value,
            outputCount: outputCounts[1],
            isProductLessLifestyle: true,
          }),
          // 1 with product abstract theme
          workflowService.invokeTextGenerationWorkflow({
            ...textGenerationInputs,
            theme: 'abstract',
            outputCount: outputCounts[3],
          }),
        ]);

        const guidedTextPrompts = textGenerationResponses.map((res, inx) => {
          if (res.status === 'rejected') return [];
          else return res.value?.output?.body?.jobs?.[0].urls?.slice(0, outputCounts[inx]) || [];
        });

        const imageGenerationResponses = await Promise.allSettled([
          // 2 Product Images
          ...guidedTextPrompts[0].map((prompt: string) =>
            generateMultipleProductImage
              ? invokeMultiProductGeneration(prompt, 2)
              : workflowService.invokeTextToImageWorkflow({
                  asinItem: selectedAsin,
                  aspectRatio: studioContext.format,
                  asinCategory: selectedAsin.metadata.asinCategory,
                  client: backendClient,
                  effects,
                  outputCount: guidedTextPrompts[0].length === 1 ? 2 : 1,
                  productImage: asinImageReferenceId,
                  prompt,
                  theme: studioContext.style?.customThemePrompt,
                  adsEntityId: entityId,
                }),
          ),
          // 3 Lifestyle Images
          ...guidedTextPrompts[1].map((prompt: string, index: number) =>
            workflowService.invokeLifestyleImageryWorkflow({
              asinItem: selectedAsin,
              asinCategory: selectedAsin.metadata.asinCategory,
              client: backendClient,
              effects,
              aspectRatio: studioContext.format,
              outputCount: guidedTextPrompts[1].length < outputCounts[1] && index === 0 ? outputCounts[1] - guidedTextPrompts[1].length : 1,
              prompt,
              theme: studioContext.style?.value,
              rewriteUserCustomPrompt: studioContext.enablePromptRewriting,
              adsEntityId: entityId,
              asinImageReferenceId,
            }),
          ),
          // 1 product with theme
          ...guidedTextPrompts[2].map((prompt: string) =>
            generateMultipleProductImage
              ? invokeMultiProductGeneration(prompt, 1, 'abstract')
              : workflowService.invokeTextToImageWorkflow({
                  asinItem: selectedAsin,
                  aspectRatio: studioContext.format,
                  asinCategory: selectedAsin.metadata.asinCategory,
                  client: backendClient,
                  effects,
                  outputCount: 1,
                  productImage: asinImageReferenceId,
                  prompt,
                  theme: 'abstract',
                  adsEntityId: entityId,
                  referenceImagesStrength,
                  referenceImages: referenceImagesId,
                }),
          ),
        ]);
        results.push(
          ...(imageGenerationResponses
            .map((res) => (res.status === 'rejected' ? undefined : res.value))
            .filter((e) => e !== undefined) as WorkflowInvocationJob[]),
        );
      } else {
        // Generate 6 Placeholders for lifestyle images
        studioContext.setGenerationPlaceholders(
          SIX_ALL_LIFESTYLE_CONTENT_TYPES.map((contentType) => ({ contentType, loading: true, aspectRatio: baseStudioInputSettings.format })),
        );
        results.push(
          await workflowService.invokeLifestyleImageryWorkflow(
            generateLifestyleImageryWorkflowProps({ backendClient, studioContext, effects, entityId, outputCount: 6, asinImageReferenceId }),
          ),
        );
      }

      clearTimeout(processingNotificationTimeout);

      if (!isJobRunning) return;

      const contentItems = await prepareContentItemsForRender(baseStudioInputSettings, results);

      appContext.metrics.trackMetrics(Metrics.Methods.StudioWorkflow, {}, { [Metrics.Names.Effects]: effects !== '' ? 1 : 0 });

      await waitUntilImagesLoaded(contentItems.map((item) => item.content));
      studioContext.clearGenerationPlaceholders();

      if (contentItems.length === 0) return setFailedJobStatus(notifications.SubmitNoImageNotification, notifications.FAILURE_MESSAGE.NO_IMAGE);

      studioContext.prependResults(contentItems);
      studioContext.setGenerationJob({ status: GenerationJobStatusEnum.COMPLETED, results: contentItems });
      if (contentItems.length < 6) {
        addFailureNotification({ SnackbarContent: notifications.SubmitPartialFailureNotification });
      }
    } catch (error) {
      console.error(error);
      clearTimeout(processingNotificationTimeout);
      if (isJobRunning) {
        isJobRunning = false;
        setFailedJobStatus(notifications.SubmitSystemFailureNotification, error as string);
      }
    }
  };

  const submitGenerationRequest = async (generateOptionOverride?: GenerateOptionType) => {
    if (isWeblabStateManagementInTreatment()) {
      submitGenerationRequestWithStateManagement(generateOptionOverride);
    } else {
      submitGenerationRequestWithoutStateManagement(generateOptionOverride);
    }
  };

  const prepareContentItemsForRender = async (
    baseStudioInputSettings: StudioInputSettings,
    completedJobs: WorkflowInvocationJob[],
  ): Promise<ContentItem[]> => {
    const results: ContentItem[] = [];
    for (const completedJob of completedJobs) {
      const contentType = getFeedContentTypeByWorkflowId(completedJob.input.workflowId);
      const products =
        completedJob.input.workflowId === WorkflowIdEnum.MULTI_PRODUCT
          ? (completedJob.input.body?.workflowOptions as MultiProductWorkflowOptions).products
          : [];
      const prompt = completedJob.prompt || products?.[0].prompt;
      const studioInputSettings = _merge(_cloneDeep(baseStudioInputSettings), {
        prompt,
      }) as StudioInputSettings;

      if (completedJob.output.body.jobs && completedJob.output.body.jobs.length > 0) {
        for (const job of completedJob.output.body.jobs) {
          if (job.urls) {
            for (let i = 0; i < job.urls.length; i++) {
              const contentFile = await urlToFile(job.urls[i], 'image/png');
              results.push({
                originalUri: job.urls[i],
                content: URL.createObjectURL(contentFile),
                referenceId: job.ids?.[i],
                aspectRatio: studioInputSettings.format,
                loading: false,
                contentHeadline: prompt,
                contentType,
                studioInputSettings,
                workflowId: completedJob.input.workflowId,
                imageId: job.assets?.[i].imageId,
                batchId: completedJob.output.body.batchId,
                assetType: completedJob.output.body.type as AssetTypeEnum,
                products: products,
              });
            }
          }
        }
      }
    }

    return results;
  };

  const setFailedJobStatus = (snackbarMessage: SnackbarNotification['SnackbarContent'], tooltipMessage: string = '') => {
    const dummyPlaceholders =
      studioContext.textPrompt || (studioContext.textPrompt && fullLayouts.length < 1) ? SIX_ALL_LIFESTYLE_CONTENT_TYPES : SIX_ASIN_CONTENT_TYPE;
    studioContext.setGenerationPlaceholders(
      dummyPlaceholders.map((contentType) => ({ contentType, loading: false, message: '' + tooltipMessage, aspectRatio: studioContext.format })),
    );
    studioContext.setGenerationJob({ status: GenerationJobStatusEnum.FAILED, message: '' + tooltipMessage });
    addFailureNotification({ SnackbarContent: snackbarMessage });
    setTimeout(() => studioContext.clearGenerationPlaceholders(), 4500);
  };

  const isGeneratedEnabled = () => {
    if (studioContext.generationJob?.status === GenerationJobStatusEnum.RUNNING) return false;
    if (studioContext.asin?.customImage) {
      return studioContext.textPrompt;
    } else return studioContext.asin || studioContext.textPrompt;
  };
  /************************************************************** */

  const dispatch = useAppDispatch();

  const renderWidgetFooter = () => (
    <>
      {isExpandedView && (
        <FadingDiv isExpanding={true} speed={fadingSpeed}>
          <DividerView />
        </FadingDiv>
      )}
      <WidgetContainerFooter>
        <Flex
          columnGap="small"
          rowGap="small"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          data-testid={`widget-container-${isCollapsedView ? 'collapsed' : 'expanded'}-view`}
        >
          <Flex
            columnGap="small"
            rowGap="small"
            direction="row"
            alignItems="center"
            grow={1}
            onClick={() => {
              if (isCollapsedView) {
                setWidgetView(WidgetViews.Expanded);
                openWidget(selectedInputItems.length !== 0 ? calculateContainerHeight(selectedInputItemsHeight) : inputPanelExpandedHeight);
                appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.CustomizeSettings]: 1 });
              }
            }}
          >
            {isCollapsedView &&
              (fullLayouts.length === 0 ? (
                <Button
                  dataTestId="product-widget-control-collapsed"
                  icon={iconTypes.addProduct}
                  iconOnly
                  iconOnlyRounded
                  iconOnlyColor='var(--text-primary, "unset")'
                  onClick={() => {
                    setWidgetView(WidgetViews.Expanded);
                    openWidget(selectedInputItems.length !== 0 ? calculateContainerHeight(selectedInputItemsHeight) : inputPanelExpandedHeight);
                    appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.CustomizeSettings]: 1 });
                  }}
                  type={ButtonTypes.Primary}
                />
              ) : (
                <div style={{ cursor: 'pointer' }}>
                  <Thumbnail
                    src={getProductImageUrl(fullLayouts[0])}
                    alt="Product thumbnail"
                    thumbnailType={ThumbnailType.Count}
                    overlayIconOptions={{
                      iconPosition: IconPositions.OverlapTopRight,
                    }}
                    count={fullLayouts.length > 1 ? fullLayouts.length : undefined}
                    thumbnailOptions={{}}
                  />
                </div>
              ))}

            {isExpandedView && getExpandedViewControls()}
            {!isFullView && !isExpandedView && (
              <InputFieldWrapper>
                <InputField
                  id="InputPanel"
                  data-testid={'studio-text-prompt-input'}
                  type="text"
                  value={inputTextPrompt}
                  maxLength={1000}
                  inputRef={textInputRef}
                  readOnly
                  onChange={(e) => {
                    setInputTextPrompt(e.target.value);
                    if (textInputRef.current) {
                      textInputRef.current.value = e.target.value;
                    }

                    if ((textInputRef.current?.value.length || 0) >= 1000) {
                      setTextInputLengthError(true);
                    } else {
                      setTextInputLengthError(false);
                      if (inputTextPrompt) {
                        dispatch(setTextPrompt(inputTextPrompt));
                      }
                    }
                  }}
                  placeholder={promptPlaceholderText}
                  fullWidth
                  style={isMobile ? { width: '100px', cursor: 'pointer', outline: 'none' } : { cursor: 'pointer', outline: 'none' }}
                />
              </InputFieldWrapper>
            )}
          </Flex>
          {(isCollapsedView || isExpandedView) && (
            <TooltipWrapper
              darkMode={isDarkMode}
              inert={isGenerationRunning || isGenerateEnabled()}
              message={getGenerateBlockedReason({
                generationType: selectedGenerateOption,
                hasProducts: fullLayouts.length > 0,
                hasPrompt: inputTextPrompt && inputTextPrompt.length > 0,
              })}
              trigger={
                <div style={{ pointerEvents: 'all' }}>
                  <GenerateButton
                    disabled={!isGenerateEnabled()}
                    animate={isGenerationRunning}
                    clickHandler={() => handleGenerateAction()}
                    text={isGenerationRunning ? 'Generating' : 'Generate'}
                    dataTestId={STUDIO_GENERATE_BUTTON}
                    hideLabel={isMobile}
                  />
                </div>
              }
            ></TooltipWrapper>
          )}
        </Flex>
      </WidgetContainerFooter>
    </>
  );

  return (
    <WidgetContainer
      data-testid={getContainerTestId()}
      onMouseEnter={() => setContainerFocused(true)}
      onMouseLeave={() => setContainerFocused(false)}
      isDarkMode={isDarkMode}
    >
      <BouncingDiv initH={startingHeight} destH={containerHeight} speed={containerAnimationSpeed}>
        <WidgetContainerBody>
          {isFullView && (
            <div data-test-id="widget-container-full-view" className="bodyPadding">
              <FadingDiv isExpanding={true} speed={fadingSpeed}>
                <div ref={selectedWidgetRef}>{getWidgetView()}</div>
              </FadingDiv>
            </div>
          )}
          {isExpandedView && (
            <FadingDiv isExpanding={true} speed={fadingSpeed}>
              <Flex rowGap="xxsmall" direction="column" alignItems="flex-start" grow={1}>
                <CreateContentDropdown
                  selectedGenerateOption={selectedGenerateOption}
                  generateOptions={generateOptions}
                  onCreateContentClick={(option) => {
                    setSelectedGenerateOption(option);
                  }}
                />
                <SelectedInputsList
                  items={selectedInputItems}
                  height={containerHeight}
                  setSelectedInputsHeight={(value: number) => setSelectedInputItemsHeight(value)}
                  isMobile={isMobile}
                  onClick={() => {
                    if (selectedInputItems.length === 0 || fullLayouts.length === 0) {
                      setActiveWidget(WidgetTypes.Product);
                      setWidgetView(WidgetViews.Full);
                      openWidget(inputPanelFullHeight);
                      appContext.metrics.trackMetrics(Metrics.Methods.InputContainer, {}, { [Metrics.Counters.OpenProductView]: 1 });
                    }
                  }}
                />
                <InputFieldWrapper>
                  <InputFieldArea
                    id="InputPanel"
                    data-testid={'studio-text-prompt-input'}
                    inputRef={(textInputRef) => {
                      if (textInputRef != null) {
                        setTimeout(
                          () => {
                            textInputRef.focus();
                          },
                          parseInt(containerAnimationSpeed.split('ms')[0]),
                        );
                      }
                    }}
                    rows={3}
                    label=""
                    placeholder={promptPlaceholderText}
                    fullWidth
                    value={inputTextPrompt}
                    maxLength={1000}
                    onChange={(e) => {
                      setInputTextPrompt(e.target.value);
                      if (textInputRef.current) {
                        textInputRef.current.value = e.target.value;
                      }

                      if ((textInputRef.current?.value.length || 0) >= 1000) {
                        setTextInputLengthError(true);
                      } else {
                        setTextInputLengthError(false);
                        if (inputTextPrompt) {
                          dispatch(setTextPrompt(inputTextPrompt));
                        }
                      }
                    }}
                  />
                  {textInputLengthError && (
                    <BannerAlertView variant="warning" withCloseButton={false}>
                      Character limit reached (1000).
                    </BannerAlertView>
                  )}
                </InputFieldWrapper>
              </Flex>
            </FadingDiv>
          )}
        </WidgetContainerBody>
      </BouncingDiv>
      {renderWidgetFooter()}
    </WidgetContainer>
  );
};

export default WidgetContainerView;
