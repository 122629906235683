import stars from 'src/animations/star.webp';
import staticStar from 'src/images/static_stars.png';
import styled from 'styled-components';

const StyledImg = styled.img``;

export const AnimatedStars = ({ width = 100, height = 100, animated = true }: { width?: number; height?: number; animated?: boolean }) => {
  return animated ? (
    <StyledImg src={stars} alt="Animated Star" width={width} height={height} />
  ) : (
    <StyledImg src={staticStar} alt="Animated Star" width={width} height={height} />
  );
};
