import { BoundingBox } from '@amzn/genaihub-typescript-client';
import type Konva from 'konva';
import { AspectRatio } from 'src/v2/types';
export const getSizeWithAspectRatio: (
  option:
    | { width: number; aspectRatio: AspectRatio }
    | {
        height: number;
        aspectRatio: AspectRatio;
      },
) => { width: number; height: number } = ({ aspectRatio: aspectRatios, ...option }) => {
  const [w, h] = aspectRatios.split(':').map((str: string) => Number.parseFloat(str));
  if (!w || !h) {
    throw new Error('Invalid aspects ratio');
  }
  if ('width' in option) {
    const { width } = option;
    return {
      width,
      height: width * (h / w),
    };
  }

  if ('height' in option) {
    const { height } = option;
    return {
      height,
      width: (height * w) / h,
    };
  }

  throw new Error('Size must have width or height.');
};
export const adjustBoundingBox = (boundingBox: BoundingBox, multiplier: number, rounded = true): BoundingBox => {
  const { width, height, top, left, rotateAngle } = boundingBox;
  const digits = 0;
  const pow = Math.pow(10, digits);
  const formatter = rounded ? (x: number) => Math.round(x * pow) / pow : (x: number) => x;
  return {
    width: formatter(width * multiplier),
    height: formatter(height * multiplier),
    top: formatter(top * multiplier),
    left: formatter(left * multiplier),
    rotateAngle,
  } as BoundingBox;
};

export const boundProductImage = (
  vec: Konva.Vector2d,
  canvas: Konva.Stage | null,
  target: Konva.Node | null,
  deadZone: number = 0,
): Konva.Vector2d => {
  if (!target || !canvas) {
    return vec;
  }

  const {
    attrs: { width, height, scaleX, scaleY, rotation },
  } = target;

  const rotationInRadians = (rotation * Math.PI) / 180.0;

  const actualWidth = width * scaleX;
  const actualHeight = height * scaleY;

  // Find the 4 coordinates (x,y) of the boudning box with rotation
  const corners = [
    { x: 0, y: 0 },
    { x: actualWidth, y: 0 },
    { x: actualWidth, y: actualHeight },
    { x: 0, y: actualHeight },
  ].map((corner) => {
    const x = corner.x * Math.cos(rotationInRadians) - corner.y * Math.sin(rotationInRadians) + vec.x;
    const y = corner.x * Math.sin(rotationInRadians) + corner.y * Math.cos(rotationInRadians) + vec.y;
    return { x, y };
  });

  const minX = Math.min(...corners.map((corner) => corner.x));
  const maxX = Math.max(...corners.map((corner) => corner.x));
  const minY = Math.min(...corners.map((corner) => corner.y));
  const maxY = Math.max(...corners.map((corner) => corner.y));

  const newVec = { ...vec };

  // Prevent the position of the bounding box from exceeding the max width and height of the canvas
  if (minX < deadZone) newVec.x += -minX + deadZone;
  if (maxX > canvas.width() - deadZone) newVec.x -= maxX - canvas.width() + deadZone;

  if (minY < 0) newVec.y += -minY + deadZone;
  if (maxY > canvas.height()) newVec.y -= maxY - canvas.height() + deadZone;

  return newVec;
};
