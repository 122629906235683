import ASINPICKER from './storm/AsinPicker';
import BASIC_TEXT_INPUT from './storm/BasicTextInput';
import BRANDSTYLERADIOGROUP from './storm/BrandStyleRadioGroup';
import BRANDSTYLETEXTBOX from './storm/BrandStyleTextPromptBox';
import DROPZONE from './storm/DropZone';
import HIDDENASIN from './storm/HiddenAsin';
import IMAGEEDITOR from './storm/ImageEditingCanvas';
import IMAGEEXPANSION from './storm/ImageExpansionCanvas';
import IMAGEUPLOAD from './storm/ImageUpload';
import SOURCEFILEUPLOAD from './storm/ImageUpload';
import RADIOGROUP from './storm/RadioGroup';
import RANGESLIDER from './storm/RangeSlider';
import TEXTBOX from './storm/TextPromptBox';
import TILELIST from './storm/TileList';

const componentMap = {
  IMAGEEDITOR,
  TEXTBOX,
  BRANDSTYLETEXTBOX,
  RADIOGROUP,
  BRANDSTYLERADIOGROUP,
  IMAGEUPLOAD,
  SOURCEFILEUPLOAD,
  BASIC_TEXT_INPUT,
  RANGESLIDER,
  TILELIST,
  DROPZONE,
  ASINPICKER,
  IMAGEEXPANSION,
  HIDDENASIN,
};

export {
  IMAGEEDITOR,
  TEXTBOX,
  BRANDSTYLETEXTBOX,
  RADIOGROUP,
  BRANDSTYLERADIOGROUP,
  IMAGEUPLOAD,
  SOURCEFILEUPLOAD,
  BASIC_TEXT_INPUT,
  RANGESLIDER,
  TILELIST,
  DROPZONE,
  ASINPICKER,
  IMAGEEXPANSION,
  HIDDENASIN,
};

type ComponentName = keyof typeof componentMap;
export function isValidComponentName(name: string): name is ComponentName {
  return name in componentMap;
}
