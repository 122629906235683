import { Asset as BackendAsset } from '@amzn/genaihub-typescript-client';
import { ApparelWorkflowUserInputs } from 'src/v2/contexts/assetGeneration/types/WorkflowUserInputs.types';
import { ASINMetadataMap } from 'src/v2/contexts/feed/FeedContext.types';
import { getProductFromBackendAsset } from 'src/v2/contexts/feed/util/product/Product.utils';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { AssetTypeEnum, BasicAssetProperties, DEFAULT_ASPECT_RATIO, ApparelProductImageAsset, WorkflowIdEnum } from 'src/v2/types';
import {
  getAspectRatioEnumFromString,
  getEffectsAndTextPromptFromConstructedTextPrompt,
  getStyleOptionFromTheme,
} from 'src/v2/utils/UserInputs.utils';

export async function buildApparelProductImageAsset({
  asinMetadataMap,
  backendAsset,
  basicAssetProperties,
}: {
  asinMetadataMap: ASINMetadataMap;
  backendAsset: BackendAsset;
  basicAssetProperties: BasicAssetProperties;
}): Promise<ApparelProductImageAsset> {
  const userInputs = await buildApparelWorkflowUserInputs({ asinMetadataMap, backendAsset, basicAssetProperties });
  return {
    ...basicAssetProperties,
    type: AssetTypeEnum.IMAGE,
    userInputs,
    workflowId: WorkflowIdEnum.APPAREL,
    workflowOptions: undefined,
    productData: undefined,
  };
}

export async function buildApparelWorkflowUserInputs({
  asinMetadataMap,
  backendAsset,
  basicAssetProperties,
}: {
  asinMetadataMap: ASINMetadataMap;
  backendAsset: BackendAsset;
  basicAssetProperties: BasicAssetProperties;
}): Promise<ApparelWorkflowUserInputs | undefined> {
  const aspectRatio = getAspectRatioEnumFromString({ aspectRatio: basicAssetProperties.aspectRatio }) ?? DEFAULT_ASPECT_RATIO;
  const { effects, textPrompt } = getEffectsAndTextPromptFromConstructedTextPrompt({ constructedTextPrompt: backendAsset.inputPrompt });
  const style = getStyleOptionFromTheme({ theme: backendAsset.theme });
  const product = await getProductFromBackendAsset({ asinMetadataMap, backendAsset });
  // Type guarding
  if (product) {
    if (textPrompt) {
      // With a text prompt, the product can be any type
      return { aspectRatio, effects, product, style, textPrompt, referenceImages: undefined, referenceImagesStrength: undefined };
    } else if (product.type === ProductType.ASIN) {
      // Without a text prompt, the product must be an AsinProduct
      return { aspectRatio, effects, product, style, textPrompt: undefined, referenceImages: undefined, referenceImagesStrength: undefined };
    } else {
      console.error('APPAREL workflow expects text prompt to be defined when using custom product image');
    }
  } else {
    console.error('APPAREL workflow expects product to be defined');
  }

  console.error(`Failed to build valid APPAREL workflow user inputs for asset: '${basicAssetProperties.id}'`);
  return undefined;
}
