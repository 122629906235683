import { BoundingBox, WorkflowId } from '@amzn/genaihub-typescript-client';
import {
  ApparelWorkflowUserInputs,
  GenerativeResizingWorkflowUserInputs,
  ImageEditorWorkflowUserInputs,
  ImageThemingWorkflowUserInputs,
  LifestyleImageryWorkflowUserInputs,
  MultipleProductWorkflowUserInputs,
  ParallaxMotionWorkflowUserInputs,
  ProductEditWorkflowUserInputs,
  TextToImageWorkflowUserInputs,
  WorkflowUserInputs,
} from 'src/v2/contexts/assetGeneration/types/WorkflowUserInputs.types';
import {
  ApparelWorkflowOptions,
  GenerativeResizingWorkflowOptions,
  ImageEditorWorkflowOptions,
  ImageThemingWorkflowOptions,
  LifestyleImageryWorkflowOptions,
  MultiProductWorkflowOptions,
  ParallaxMotionWorkflowOptions,
  ProductEditWorkflowOptions,
  TextToImageWorkflowOptions,
  WorkflowOptions,
} from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { AssetTypeEnum, WorkflowIdEnum } from 'src/v2/types/_partials/Backend.types';
import { AspectRatio } from 'src/v2/types/_partials/UserInputs.types';

/** ENUMS **/
export enum ContentType {
  EDITED_IMAGE = 'Edited image',
  LIFESTYLE_IMAGE = 'Lifestyle image',
  LIVE_IMAGE = 'Live image',
  PRODUCT_IMAGE = 'Product image',
  UPLOADED_IMAGE = 'Uploaded image',
}

export enum FeedPlaceholderType {
  ASSET_FETCH = 'assetFetch',
  ASSET_GENERATION = 'assetGeneration',
  EMPTY_FEED = 'emptyFeed',
}

export type ProductData = {
  boundingBox: BoundingBox;
  imageUrl: string;
  maskUrl: string;
};

/** INTERFACES **/
export interface BatchResultAsset {
  batchId: string;
  id: string;
  catwalkImageId: string | undefined;
  jobId: string;
  url: string;
  // product data
  productData: ProductData | undefined;
}

export type BasicAssetProperties = {
  aspectRatio: string;
  batchId: string;
  // The backend calls this 'imageId' in Assets
  catwalkImageId: string | undefined;
  id: string;
  jobId: string;
  timestamp: number;
  // The Presigned-URL from the Batch Result
  url: string;
};

type AssetBase<
  TAssetType extends AssetTypeEnum,
  TWorkflowId extends WorkflowId,
  // Backend assets don't have sufficient information to populate the workflow user inputs
  TWorkflowUserInputs extends WorkflowUserInputs | undefined,
  // Backend assets don't have sufficient information to populate the workfllow options
  TWorkflowOptions extends WorkflowOptions | undefined,
> = BasicAssetProperties & {
  type: TAssetType;
  userInputs: TWorkflowUserInputs | undefined;
  workflowId: TWorkflowId;
  workflowOptions: TWorkflowOptions | undefined;
  productData: ProductData | undefined;
};

/** Edited Image Asset Types */
export type GenerativeResizingEditedImageAsset = AssetBase<
  AssetTypeEnum.IMAGE,
  WorkflowIdEnum.GENERATIVE_RESIZING,
  GenerativeResizingWorkflowUserInputs,
  GenerativeResizingWorkflowOptions
>;
export type ImageEditorEditedImageAsset = AssetBase<
  AssetTypeEnum.IMAGE,
  WorkflowIdEnum.IMAGE_EDITOR,
  ImageEditorWorkflowUserInputs,
  ImageEditorWorkflowOptions
>;
export type ImageThemingEditedImageAsset = AssetBase<
  AssetTypeEnum.IMAGE,
  WorkflowIdEnum.IMAGE_THEMING,
  ImageThemingWorkflowUserInputs,
  ImageThemingWorkflowOptions
>;
export type ProductEditEditedImageAsset = AssetBase<
  AssetTypeEnum.IMAGE,
  WorkflowIdEnum.PRODUCT_EDIT,
  ProductEditWorkflowUserInputs,
  ProductEditWorkflowOptions
>;

/** Lifestyle Image Asset Types */
export type LifestyleImageAsset = AssetBase<
  AssetTypeEnum.IMAGE,
  WorkflowIdEnum.LIFESTYLE_IMAGERY,
  LifestyleImageryWorkflowUserInputs,
  LifestyleImageryWorkflowOptions
>;

/** Multiple Product Image Asset Types */
export type MultipleProductImageAsset = AssetBase<
  AssetTypeEnum.IMAGE,
  WorkflowIdEnum.MULTI_PRODUCT,
  MultipleProductWorkflowUserInputs,
  MultiProductWorkflowOptions
>;

/** Product Image Asset Types */
export type TextToImageProductImageAsset = AssetBase<
  AssetTypeEnum.IMAGE,
  WorkflowIdEnum.TEXT_TO_IMAGE,
  TextToImageWorkflowUserInputs,
  TextToImageWorkflowOptions
>;
export type ApparelProductImageAsset = AssetBase<AssetTypeEnum.IMAGE, WorkflowIdEnum.APPAREL, ApparelWorkflowUserInputs, ApparelWorkflowOptions>;

export type ProductImageAsset = TextToImageProductImageAsset | ApparelProductImageAsset;

/** Uploaded Image Asset Types */
export type UploadedImageAsset = AssetBase<AssetTypeEnum.IMAGE, WorkflowIdEnum.IMPORT_IMAGE, undefined, undefined>;

/** Video Asset Types */
export type LiveImageVideoAsset = AssetBase<
  AssetTypeEnum.VIDEO,
  WorkflowIdEnum.PARALLAX_MOTION,
  ParallaxMotionWorkflowUserInputs,
  ParallaxMotionWorkflowOptions
>;

/** Asset Type Unions */
export type ImageAsset =
  | GenerativeResizingEditedImageAsset
  | ImageEditorEditedImageAsset
  | ImageThemingEditedImageAsset
  | ProductEditEditedImageAsset
  | LifestyleImageAsset
  | ProductImageAsset
  | UploadedImageAsset
  | MultipleProductImageAsset;

export type EditedImageAsset =
  | GenerativeResizingEditedImageAsset
  | ImageEditorEditedImageAsset
  | ImageThemingEditedImageAsset
  | ProductEditEditedImageAsset;

export type EditedAsset = EditedImageAsset | LiveImageVideoAsset;

export type VideoAsset = LiveImageVideoAsset;

// This will replace the Asset declared above
export type FrontendAsset = ImageAsset | VideoAsset;
export type SimpleAsset = Pick<FrontendAsset, 'id' | 'url' | 'type'>;

/** FEED PLACEHOLDERS **/
type FeedPlaceholderBase<TPlaceholderType extends FeedPlaceholderType> = {
  aspectRatio: string | AspectRatio; // use AspectRatio when possible
  type: TPlaceholderType;
};

export type AssetFetchPlaceholder = FeedPlaceholderBase<FeedPlaceholderType.ASSET_FETCH>;

export type EmptyFeedPlaceholder = FeedPlaceholderBase<FeedPlaceholderType.EMPTY_FEED>;
