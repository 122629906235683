import { ReactElement, useState } from 'react';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import useAssetImageCache from 'src/hooks/useAssetImageCache';
import { Placeholder } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderAnimation, PlaceholderType, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';

interface ImageProps {
  item: ContentItem;
  componentLabel: string;
}

export const TEST_ID_IMAGE_GRID_ITEM = 'image-grid-item';

export const Image = ({ item, componentLabel }: ImageProps): ReactElement => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const assetUrl = useAssetImageCache(item);

  return (
    <>
      <img
        data-testid={`${TEST_ID_IMAGE_GRID_ITEM}-${item.imageId}`}
        id={`${componentLabel}-${TEST_ID_IMAGE_GRID_ITEM}-${item.imageId}`}
        src={assetUrl}
        alt={item.contentHeadline}
        onLoad={() => setImageLoaded(true)}
        style={{ opacity: !imageLoaded ? 0 : 1 }}
        loading="lazy"
      />
      {!imageLoaded && (
        <Placeholder
          data-testid={`${TEST_ID_IMAGE_GRID_ITEM}-placeholder-${item.imageId}`}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
          type={PlaceholderType.ASSET}
          variant={PlaceholderVariant.ROUNDED}
          animation={PlaceholderAnimation.WAVE}
        />
      )}
    </>
  );
};
