import Genaihubbackend, { SubmitReportingReportInput, SubmitReportingReportOutput } from '@amzn/genaihub-typescript-client';
import { UseMutationResult } from '@tanstack/react-query';
import { BackendActionBase, BackendActionType, RequestsLookup } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { handleInit, handleError, handleSuccess } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type SubmitReportingReportAction = BackendActionBase<
  BackendActionType.SUBMIT_REPORTING_REPORT,
  SubmitReportingReportInput,
  SubmitReportingReportOutput['body']
>;

interface SubmitReportingReportMutationProps {
  backendClient: Genaihubbackend;
  payload: SubmitReportingReportInput;
}

export const SubmitReportingReportMutationConfig = {
  mutationFn: async ({ backendClient, payload }: SubmitReportingReportMutationProps) => {
    const response = await backendClient.submitReportingReport(payload);
    return response.body;
  },
};

interface HandleSubmitReportingReportActionProps {
  action: SubmitReportingReportAction;
  backendClient: Genaihubbackend;
  submitReportingReportMutation: UseMutationResult<SubmitReportingReportOutput['body'], Error, SubmitReportingReportMutationProps, unknown>;
  requestsLookup: RequestsLookup;
}

export const handleSubmitReportingReportAction = async ({
  action,
  backendClient,
  submitReportingReportMutation,
  requestsLookup,
}: HandleSubmitReportingReportActionProps) => {
  const id = handleInit({ action, requestsLookup });
  try {
    const response = await submitReportingReportMutation.mutateAsync({
      backendClient,
      payload: action.payload,
    });
    return handleSuccess({ response, action, id, requestsLookup });
  } catch (err) {
    return handleError({ err, action, id, requestsLookup });
  }
};
