import { CSSProperties, JSX } from 'react';
import { chunkIntoGroups } from 'src/components/utils/chunkIntoGroups';
import { FEED_GAP } from 'src/v2/components/studio/feed/Feed.constants';
import { AspectRatio } from 'src/v2/types';

interface FeedItem {
  aspectRatio: string;
}

export function computeFeedItemLayout<TFeedItem extends FeedItem>({
  items,
  numFeedItemsPerRow,
  createFeedItem,
}: {
  items: TFeedItem[];
  numFeedItemsPerRow: number;
  createFeedItem: (props: { item: TFeedItem; style: CSSProperties }) => JSX.Element;
}) {
  return chunkIntoGroups<TFeedItem>(items, numFeedItemsPerRow).map((row) => {
    const ratioDenominator = row.reduce((sum, item) => {
      const [height, width] = (item.aspectRatio || AspectRatio.SQUARE_1_TO_1).split(':');
      return sum + parseFloat(height) / parseFloat(width);
    }, 0);

    // Create tiles for this row with calculated flex basis
    return row.map((item) => {
      const [height, width] = (item.aspectRatio || AspectRatio.SQUARE_1_TO_1).split(':');
      const ratio = parseFloat(height) / parseFloat(width);
      const aspectRatio = item.aspectRatio.replace(':', ' / ');
      const flexBasis = `calc(${((ratio / ratioDenominator) * 100) / (numFeedItemsPerRow / row.length)}% - ${(FEED_GAP * (numFeedItemsPerRow - 1)) / numFeedItemsPerRow}px)`;

      return createFeedItem({ item, style: { aspectRatio, flexBasis } });
    });
  });
}
