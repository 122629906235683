import { GenerateOptionType } from 'src/v2/types';
import { iconTypes } from '../../Icon/iconTypes';

// get the icon used on the widget input container
export const getAspectRatioIcon = (ratio: string) => {
  switch (ratio) {
    case '1:1':
      return iconTypes.square;
    case '4:5':
      return iconTypes.vertical;
    case '9:16':
      return iconTypes.vertical;
    case '3:1':
      return iconTypes.horizontal;
    case '1.91:1':
      return iconTypes.horizontal;
    case '12:5':
      return iconTypes.horizontal;
    default:
      return iconTypes.horizontal;
  }
};

interface GenerateBlockedReason {
  generationType: GenerateOptionType | undefined;
  hasProducts: boolean;
  hasPrompt: '' | boolean | undefined;
}

export const getGenerateBlockedReason = ({ hasProducts, hasPrompt, generationType }: GenerateBlockedReason): string | undefined => {
  if (!hasProducts && !hasPrompt && generationType === GenerateOptionType.GENERATE_PRODUCT_IMAGES) {
    return 'Product or prompt required';
  }
  if (generationType === GenerateOptionType.GENERATE_PRODUCT_IMAGES) {
    if (!hasProducts) {
      return 'Add a product to generate images';
    }
  }
  if (generationType === GenerateOptionType.GENERATE_LIFESTYLE_IMAGES) {
    if (!hasPrompt) {
      return 'Add a prompt to generate images';
    }
  }
  return undefined;
};
