import { IconDefinition } from '@amzn/storm-ui-icons';
import { CSSProperties, MouseEvent } from 'react';
import { IconName } from 'src/v2/components/Icon/iconTypes';

export enum ButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export enum ButtonSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface ButtonProps {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  borderRadius?: string;
  className?: string;
  colorOverride?: boolean;
  darkMode?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: IconDefinition | IconName;
  iconOnly?: boolean;
  iconOnlyBackgroundColor?: string;
  iconOnlyColor?: string;
  iconOnlyNoBackground?: boolean;
  iconOnlyPaddingPercent?: number;
  iconOnlyRounded?: boolean;
  iconSize?: string;
  label?: string;
  loading?: boolean;
  size?: ButtonSizes;
  style?: CSSProperties;
  type?: ButtonTypes;
  popoverText?: string;
}

export enum ButtonStyleClasses {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  small = 'small',
  medium = 'medium',
  large = 'large',
  disabled = 'disabled',
  loading = 'loading',
  fullWidth = 'fullWidth',
  darkMode = 'darkMode',
  colorOverride = 'colorOverride',
  iconOnly = 'iconOnly',
  iconOnlyNoBackground = 'iconOnlyNoBackground',
  iconOnlyRounded = 'iconOnlyRounded',
  iconDisabled = 'iconDisabled',
  iconLight = 'iconLight',
  iconDark = 'iconDark',
  iconAccent = 'iconAccent',
  iconOnlyDarkMode = 'iconOnlyDarkMode',
}
