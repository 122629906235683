import React, { useContext, useRef } from 'react';
import { AppContext } from 'src/AppContext';
import { Metrics } from 'src/constants';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { AcceptedFileTypesForStudioImageUpload, filterStudioImageUploadFiles } from 'src/v2/utils/ImageUtils';
import styles from './StudioImportImageButton.module.scss';

export const TEST_ID_STUDIO_IMPORT_IMAGE_BUTTON = 'studio-import-image-button';
export const TEST_ID_STUDIO_IMPORT_IMAGE_BUTTON_ICON = 'studio-import-image-button-icon';
export const TEST_ID_STUDIO_IMPORT_IMAGE_FILE_INPUT = 'studio-import-image-file-input';

interface StudioImportImageButtonProps {
  disabled?: boolean;
  /**
   * Callback called after user selects files
   * @param props
   * @param props.files - list of files selected by user
   * @param props.filtered - flag indicating if the dropped set of files was filtered
   * @returns
   */
  onSelectFiles?: (props: { files: File[]; filtered: boolean }) => void;
}

const AcceptedFileTypes = AcceptedFileTypesForStudioImageUpload.join(',');

export const StudioImportImageButton = ({ disabled, onSelectFiles }: StudioImportImageButtonProps) => {
  const appContext = useContext(AppContext);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files?.length) {
      const files = Array.from(event.target.files);
      const result = filterStudioImageUploadFiles({ files });
      onSelectFiles?.({ files: result.files, filtered: result.filtered });

      appContext.metrics.trackMetrics(Metrics.Methods.ImportImage, {}, { [Metrics.Counters.Count]: 1 });
    }
  };

  return (
    <>
      <input
        accept={AcceptedFileTypes}
        aria-hidden="true"
        className={styles.studioImportImageFileInput}
        multiple={true}
        onChange={handleFileInputChange}
        ref={inputRef}
        tabIndex={-1}
        type="file"
        data-testid={TEST_ID_STUDIO_IMPORT_IMAGE_FILE_INPUT}
        style={{ display: 'none' }}
      />
      <Button
        label="Import Image"
        type={ButtonTypes.Secondary}
        icon={iconTypes.addImage}
        disabled={disabled}
        onClick={handleButtonClick}
        dataTestId={TEST_ID_STUDIO_IMPORT_IMAGE_BUTTON}
      />
    </>
  );
};
