import { Button, Checkbox } from '@amzn/storm-ui';
import { KeyboardEvent, memo, ReactElement, SyntheticEvent } from 'react';
import { Placeholder } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderAnimation, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';
import { SimpleAsset, AssetTypeEnum, KeyboardKey } from 'src/v2/types';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import { ImageAssetGridItem } from './ImageAssetGridItem';
import styles from './ImageGrid.module.scss';

interface ImageAssetGridProps {
  assets: SimpleAsset[];
  onSelectAsset: (e: SyntheticEvent, asset: SimpleAsset) => void;
  onScroll?: (e: SyntheticEvent) => void;
  testIdPrefix?: string;
  placeholderCount?: number;
  multiSelection?: MultiSelectionProps;
}
interface MultiSelectionProps {
  disabled: (assetUrl: string | undefined) => boolean;
  setCheck: (assetUrl: string | undefined) => boolean;
  turnMaxWidthOff?: boolean;
}

export const getImageAssetGridItemButtonTestId = (assetId: string) => `image-asset-grid-item-button-${assetId}`;
export const getImageAssetGridTestId = (testIdPrefix?: string) => [testIdPrefix ?? '', 'image-asset-grid'].join('-');
export const getImageAssetGridCheckBoxWithImageTestId = (assetId: string) => `checkbox-with-image-${assetId}`;
export const getImageAssetGridCheckBoxTestId = (assetId: string) => `checkbox-${assetId}`;

export const ImageAssetGrid = memo(
  ({ assets, placeholderCount, testIdPrefix, onSelectAsset, onScroll, multiSelection }: ImageAssetGridProps): ReactElement => {
    const handleMouseDown = (e: SyntheticEvent, asset: SimpleAsset) => {
      onSelectAsset(e, asset);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement> | KeyboardEvent<HTMLAnchorElement>, asset: SimpleAsset) => {
      if (e.key === KeyboardKey.ENTER || e.key === KeyboardKey.SPACEBAR) {
        onSelectAsset(e, asset);
      }
    };
    const rootClassNames = getNormalizedClassNames([styles.imageGrid, multiSelection?.turnMaxWidthOff ? styles.turnoffMaxWidth : undefined]);

    // TODO: integration tests look for 'image-grid-component-studio-feed' (legacy test ID from ImageGrid.tsx), which is the root element of this component.
    // ACTION: update integration tests to query for the new test ID when State Management T1
    return (
      <div className={rootClassNames} data-testid={getImageAssetGridTestId(testIdPrefix)}>
        <div onScroll={onScroll} className={styles.images}>
          {assets.map((asset) => {
            if (asset.type !== AssetTypeEnum.IMAGE) return null;
            return multiSelection ? (
              <div className={styles.buttonWrapper}>
                <div data-testid={getImageAssetGridCheckBoxWithImageTestId(asset.id)}>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      data-testid={getImageAssetGridCheckBoxTestId(asset.id)}
                      checked={multiSelection.setCheck(asset.id)}
                      onChange={(e) => onSelectAsset(e, asset)}
                      disabled={multiSelection.disabled(asset.id)}
                    />
                  </div>
                  <ImageAssetGridItem asset={asset} />
                </div>
              </div>
            ) : (
              <Button
                data-testid={getImageAssetGridItemButtonTestId(asset.id)}
                key={`image-asset-grid-item-${asset.id}`}
                className={styles.buttonWrapper}
                onKeyDownCapture={(e) => handleKeyDown(e, asset)}
                onMouseDown={(e) => handleMouseDown(e, asset)}
              >
                <ImageAssetGridItem asset={asset} />
              </Button>
            );
          })}
          {Array(placeholderCount)
            .fill(null)
            .map((value, index) => {
              return (
                <Placeholder
                  key={`image-asset-grid-feed-fetch-placeholder-${index}`}
                  variant={PlaceholderVariant.ROUNDED}
                  animation={PlaceholderAnimation.WAVE}
                  className={styles.feedFetchPlaceholder}
                />
              );
            })}
          {
            /* Adds a 1px element to adjust the scroll end and allow the user to scroll down again. Sometimes the user may scroll to the bottom and the new assets don't cause the container to grow past the scrollend, which requires the user to up, then down. This 1px element helps mititgate that issue. */
            placeholderCount === 0 && <div style={{ height: '1px', width: '100%' }} />
          }
        </div>
      </div>
    );
  },
);

ImageAssetGrid.displayName = 'ImageAssetGrid';
