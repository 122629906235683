import { retrieveWorkflowResultMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/RetrieveWorkflowResult.middleware';
import { submitWorkflowMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/SubmitWorkflow.middleware';
import { ImageThemingGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { buildAssetsFromBatchResult } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { ImageThemingWorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { AssetTypeEnum, EditedImageAsset, EditMode } from 'src/v2/types';
import { getThemeFromStyleOption } from 'src/v2/utils/UserInputs.utils';

/**
 * A helper function for the primary handler, handleImageThemingGeneration. This function handles the workflow submission and result.
 */
export async function handleImageThemingGenerationSubmission({
  backendDispatchContext,
  generationConfig,
  inputImageReferenceId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: ImageThemingGenerationConfig;
  inputImageReferenceId: string;
}): Promise<EditedImageAsset[]> {
  const { entityId, studioRequest, userInputs, workflowId } = generationConfig;
  const { inputImageAsset } = userInputs;
  let theme;
  let strength = undefined;
  if (userInputs.editMode === EditMode.RESTYLE) {
    theme = getThemeFromStyleOption({ style: userInputs.style, workflowId });
    strength = userInputs.styleStrength;
  } else {
    theme = userInputs.textPrompt;
  }

  // STEP - Submit workflow for IMAGE_THEMING
  const workflowOptions: ImageThemingWorkflowOptions = {
    strength,
    inputImageRef: inputImageReferenceId,
    seed: undefined,
    theme,
    workflowId,
  };
  const submitWorkflowPayload: StronglyTypedSubmitWorkflowByIdInput = {
    body: {
      workflowOptions,
    },
    studioRequest,
    workflowId,
  };
  const batchId = await submitWorkflowMiddleware({ backendDispatchContext, payload: submitWorkflowPayload });

  // STEP - Retrieve result for IMAGE_THEMING
  return await retrieveWorkflowResultMiddleware<EditedImageAsset[]>({
    backendDispatchContext,
    payload: {
      batchId,
      entityId,
      studioRequest,
      workflowId,
    },
    onSuccessHandler: ({ response }) => {
      return buildAssetsFromBatchResult<EditedImageAsset, ImageThemingGenerationConfig['userInputs'], ImageThemingWorkflowOptions>({
        aspectRatio: inputImageAsset.aspectRatio,
        assetType: AssetTypeEnum.IMAGE,
        batchResult: response,
        userInputs,
        workflowOptions,
      });
    },
  });
}
