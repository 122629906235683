import { HTMLAttributes } from 'react';
import { Placeholder } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderAnimation, PlaceholderType, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';
import { FeedTestIds } from 'src/v2/components/studio/feed/Feed.constants';
import styles from 'src/v2/components/studio/feed/Feed.module.scss';
import { getNormalizedClassNames } from 'src/v2/utils/utils';

interface FeedFetchPlaceholdersGroupItemProps extends HTMLAttributes<HTMLDivElement> {}

export const FeedFetchPlaceholdersGroupItem = (props: FeedFetchPlaceholdersGroupItemProps) => {
  const rootClassNames = getNormalizedClassNames([styles.feedItemContainer, styles.feedFetchPlaceholder]);

  const placeholderClassNames = getNormalizedClassNames([styles.placeholder]);

  return (
    <div
      className={rootClassNames}
      data-testid={FeedTestIds.feedFetchPlaceholder.rootContainer}
      data-alt-testid={FeedTestIds.feedFetchPlaceholder.rootContainerAlt}
      {...props}
    >
      <Placeholder
        className={placeholderClassNames}
        data-testid={FeedTestIds.feedFetchPlaceholder.placeholderComponent}
        animation={PlaceholderAnimation.WAVE}
        type={PlaceholderType.ASSET}
        variant={PlaceholderVariant.ROUNDED}
      />
    </div>
  );
};
