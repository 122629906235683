import Genaihubbackend from '@amzn/genaihub-typescript-client';
import { useContext, useEffect, useState } from 'react';
import { AppContext, AppContextState } from 'src/AppContext';
import { GenAIHubClientProvider } from 'src/client/GenAIHubClientProvider';

export const useAIBackendHubClient = (metrics?: AppContextState['metrics']): Genaihubbackend => {
  const appContext = useContext(AppContext);
  const _metrics = metrics ?? appContext.metrics;

  const [instance, setInstance] = useState<Genaihubbackend>(GenAIHubClientProvider.getInstance(_metrics).getGenAIHubBackendClient());

  useEffect(() => {
    setInstance(GenAIHubClientProvider.getInstance(_metrics).getGenAIHubBackendClient());
  }, [_metrics]);

  return instance;
};
