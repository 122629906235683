import { Flex } from '@amzn/storm-ui';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SelectionItem, SelectionItemProps } from './SelectionItem';
import { ThumbnailOptions } from '../Thumbnail/types';

export type SelectionListProps<T = unknown> = {
  list: (Pick<SelectionItemProps, 'title' | 'subtitle' | 'thumbnail'> & T)[];
  type: SelectionItemProps['type'];
  onClick?: (item: Pick<SelectionItemProps, 'title' | 'subtitle' | 'thumbnail'> & T) => void;
  onSelectionChange?: (selectedItemIndexes: number[]) => void;
  selectedIndex?: number[];
  mode?: 'add' | 'remove';
  doubleColumns?: boolean;
  dataTestId?: string;
  thumbnailOptions?: ThumbnailOptions;
  showEmptyThumbnails?: boolean;
  disabled?: boolean;
};

const SelectionListWrapper = styled(Flex)<Partial<SelectionListProps>>`
  display: ${({ doubleColumns }) => (doubleColumns ? 'grid' : 'flex')};
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    color: var(--text-primary, 'unset');
  }

  & input:active,
  input:checked,
  input:hover {
    background-color: var(--interactive-primary, 'unset');
  }

  @media (max-width: 700px) {
    display: flex;
  }
`;

export const SelectionList = <T = unknown,>({
  list,
  onClick = () => {},
  onSelectionChange = () => {},
  type = 'radio',
  selectedIndex,
  // for some use cases in multiple selection, we need all items to be removed or to be added, we can use this to force it;
  mode,
  doubleColumns,
  dataTestId,
  thumbnailOptions,
  showEmptyThumbnails = false,
  disabled = false,
}: SelectionListProps<T>) => {
  const [selected, setSelected] = useState<number[]>(selectedIndex || []);
  useEffect(() => {
    if (selectedIndex) {
      setSelected(() => [...selectedIndex]);
    }
  }, [selectedIndex]);

  useEffect(() => {
    onSelectionChange(selected);
  }, [selected, onSelectionChange]);
  return (
    <SelectionListWrapper doubleColumns={doubleColumns} direction="column" rowGap="xsmall" data-testid={dataTestId}>
      {list.map((item, itemIndex) => {
        const index = selected.findIndex((selection) => selection === itemIndex);
        const forceMode = mode === 'add' ? false : mode === 'remove';
        const itemSelected = mode !== undefined ? forceMode : index >= 0;
        return (
          <SelectionItem
            {...item}
            selected={itemSelected}
            type={type}
            key={`${item.title} ${item.subtitle}`}
            showEmptyThumbnails={showEmptyThumbnails}
            thumbnailOptions={thumbnailOptions}
            selectionId={itemIndex}
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              onClick(item);
              if (index >= 0) {
                if (type === 'radio') {
                  setSelected([]);
                } else if (type === 'multiple' && !mode) {
                  setSelected(selected.filter((value) => value !== itemIndex));
                }
              } else {
                if (type === 'radio') {
                  setSelected([itemIndex]);
                } else if (type === 'multiple' && !mode) {
                  setSelected([...selected, itemIndex]);
                }
              }
            }}
          />
        );
      })}
    </SelectionListWrapper>
  );
};
