import Genaihubbackend from '@amzn/genaihub-typescript-client';
import { uploadImage } from 'src/components/utils/uploadImage';
import { CategoryEnum, WorkflowIdEnum } from 'src/v2/types';
import { StudioContextType } from '../pages/studio/StudioContext';
import { LifestyleImageryWorkflowProps, TextToImageWorkflowProps } from '../pages/studio/WorkflowService';

export const generateLifestyleImageryWorkflowProps: (options: {
  backendClient: Genaihubbackend;
  studioContext: StudioContextType;
  effects: string;
  entityId: string | undefined;
  outputCount: number;
  asinImageReferenceId?: string;
  prompt?: string;
}) => LifestyleImageryWorkflowProps = ({ backendClient, studioContext, effects, entityId, outputCount, asinImageReferenceId, prompt }) => {
  return {
    client: backendClient,
    aspectRatio: studioContext.format,
    effects,
    outputCount: outputCount || 6,
    prompt: prompt || studioContext.textPrompt,
    theme: studioContext.style?.value,
    rewriteUserCustomPrompt: studioContext.enablePromptRewriting,
    adsEntityId: entityId,
    asinImageReferenceId,
    asinCategory: studioContext.asin?.metadata?.asinCategory,
    asinItem: studioContext.asin,
  } as LifestyleImageryWorkflowProps;
};

export const generateTextToImageWorkflowProps: (options: {
  backendClient: Genaihubbackend;
  studioContext: StudioContextType;
  effects: string;
  asinImageReferenceId: string;
  entityId?: string;
  outputCount?: number;
  prompt?: string;
  referenceImages?: string[];
  referenceImagesStrength?: number[];
}) => TextToImageWorkflowProps = ({
  referenceImagesStrength,
  referenceImages,
  prompt,
  backendClient,
  studioContext,
  effects,
  asinImageReferenceId,
  entityId,
  outputCount,
}) => {
  return {
    asinItem: studioContext.asin,
    asinCategory: studioContext.asin?.metadata?.asinCategory,
    aspectRatio: studioContext.format,
    client: backendClient,
    effects,
    outputCount: outputCount || 6,
    productImage: asinImageReferenceId,
    prompt: prompt || studioContext.textPrompt,
    theme: studioContext.style?.value,
    adsEntityId: entityId,
    referenceImages: referenceImages,
    referenceImagesStrength: referenceImagesStrength,
  } as TextToImageWorkflowProps;
};

export const runBeforeJobSubmission = async (
  workflowId: string,
  workflowOptions: Record<string, { value: number | string; file?: File }>,
  backendClient: Genaihubbackend,
) => {
  if (!workflowId) {
    throw new Error('Workflow ID is required');
  }

  if (workflowId === WorkflowIdEnum.IMAGE_EDITOR || workflowId === WorkflowIdEnum.PARALLAX_MOTION) {
    if (workflowOptions['mask_ref']) {
      workflowOptions['mask_ref'].value = await uploadImage({
        file: workflowOptions['mask_ref'].file as File,
        backendClient,
        contentCategory: CategoryEnum.MASK_IMAGE,
      });
    }
    workflowOptions['image_count'] = {
      value: 4,
    };
  }
};
