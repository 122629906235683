import { retrieveWorkflowResultMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/RetrieveWorkflowResult.middleware';
import { submitWorkflowMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/SubmitWorkflow.middleware';
import { ProductImageGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { buildAssetsFromBatchResult } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { StronglyTypedSubmitWorkflowByIdInput } from 'src/v2/contexts/backend/actions/SubmitWorkflowById';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { ApparelWorkflowOptions, TextToImageWorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { AsinCategoryEnum, AssetTypeEnum, ProductImageAsset } from 'src/v2/types';
import { constructTextPrompt, getThemeFromStyleOption, sanitizeText } from 'src/v2/utils/UserInputs.utils';

type ProductImageWorkflowOptions = TextToImageWorkflowOptions | ApparelWorkflowOptions;

/**
 * A helper function for the primary handler, handleProductImageGeneration. This function handles the workflow submission and result.
 */
export async function handleProductImageGenerationSubmission({
  backendDispatchContext,
  generationConfig,
  productImageReferenceId,
  referenceImagesReferenceIds,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: ProductImageGenerationConfig;
  productImageReferenceId: string;
  referenceImagesReferenceIds: string[] | undefined;
}): Promise<ProductImageAsset[]> {
  const { entityId, outputCount, studioRequest, userInputs, workflowId } = generationConfig;
  const { aspectRatio, effects, product, referenceImagesStrength, style } = userInputs;
  const textPrompt = constructTextPrompt({
    effects,
    textPrompt: sanitizeText({ text: userInputs.textPrompt }),
  });
  const asin = product.type === ProductType.ASIN ? product.asin : undefined;
  const theme = getThemeFromStyleOption({ style, workflowId });

  // STEP - Submit workflow for TEXT_TO_IMAGE / APPAREL
  const workflowOptions: ProductImageWorkflowOptions = {
    asin,
    aspectRatio,
    asinCategory: AsinCategoryEnum.NONE,
    imageCount: outputCount,
    productImageRef: productImageReferenceId,
    referenceImageRef: undefined,
    textPrompt,
    theme,
    workflowId,
    referenceImagesRef: referenceImagesReferenceIds,
    referenceImagesStrength,
  };
  const submitWorkflowPayload: StronglyTypedSubmitWorkflowByIdInput = {
    body: {
      workflowOptions,
    },
    studioRequest,
    workflowId,
  };
  const batchId = await submitWorkflowMiddleware({ backendDispatchContext, payload: submitWorkflowPayload });

  // STEP - Retrieve result for TEXT_TO_IMAGE / APPAREL
  return await retrieveWorkflowResultMiddleware<ProductImageAsset[]>({
    backendDispatchContext,
    payload: {
      batchId,
      entityId,
      studioRequest,
      workflowId,
    },
    onSuccessHandler: ({ response }) => {
      return buildAssetsFromBatchResult<ProductImageAsset, ProductImageGenerationConfig['userInputs'], ProductImageWorkflowOptions>({
        aspectRatio,
        assetType: AssetTypeEnum.IMAGE,
        batchResult: response,
        userInputs,
        workflowOptions,
      });
    },
  });
}
