import Genaihubbackend, { DeleteAssetInput } from '@amzn/genaihub-typescript-client';
import { UseMutationResult } from '@tanstack/react-query';
import { BackendActionBase, BackendActionType, RequestsLookup } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { handleInit, handleError, handleSuccess } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export interface DeleteAssetResponse {}

export type DeleteAssetAction = BackendActionBase<BackendActionType.DELETE_ASSET, DeleteAssetInput, DeleteAssetResponse>;

interface DeleteAssetMutationProps {
  backendClient: Genaihubbackend;
  payload: DeleteAssetInput;
}

export const DeleteAssetMutationConfig = {
  mutationFn: async ({ backendClient, payload }: DeleteAssetMutationProps) => {
    await backendClient.deleteAsset(payload);
    return {};
  },
};

interface HandleDeleteAssetActionProps {
  action: DeleteAssetAction;
  backendClient: Genaihubbackend;
  deleteAssetMutation: UseMutationResult<DeleteAssetResponse, Error, DeleteAssetMutationProps, unknown>;
  requestsLookup: RequestsLookup;
}

export const handleDeleteAssetAction = async ({ action, backendClient, deleteAssetMutation, requestsLookup }: HandleDeleteAssetActionProps) => {
  const id = handleInit({ action, requestsLookup });
  try {
    const response = await deleteAssetMutation.mutateAsync({
      backendClient,
      payload: action.payload,
    });
    return handleSuccess({ response, action, id, requestsLookup });
  } catch (err) {
    return handleError({ err, action, id, requestsLookup });
  }
};
