import { Button, Flex, Input, Text, useIsMobile, Icon } from '@amzn/storm-ui';
import { ChangeEventHandler, useRef, useState } from 'react';
import AddImage from 'src/icons/addImage.svg';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { AcceptedFileTypesForStudioImageUpload, filterStudioImageUploadFiles } from 'src/v2/utils/ImageUtils';
import styled from 'styled-components';

export type ImportProductCardProps = {
  searchClickHandler: (asin: string) => void;
  loading?: boolean;
  onSelectFiles?: (options: { files: File[]; filtered: boolean }) => void;
  disabled?: boolean;
};

export const TEST_ID_IMPORT_PRODUCT_CARD_INPUT = 'studio-input-box-asin-input';
export const TEST_ID_IMPORT_PRODUCT_CARD_UPLOAD_BUTTON = 'studio-input-box-upload-product-button';
export const TEST_ID_IMPORT_PRODUCT_CARD_SEARCH_ASIN = 'studio-input-box-search-asin';
export const TEST_ID_IMPORT_PRODUCT_CARD_UPLOAD_ICON = 'studio-input-box-upload-product-icon';
const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.small.default};
  border-radius: 8px;
  background: var(--surface-tertiary, #f5f3ef);

  & p {
    color: var(--text-primary, 'unset');
  }

  #input-container {
    position: relative;
    display: flex;
    align-items: stretch;
  }
`;

const InputBox = styled(Input)<{ disabled: boolean }>`
  div {
    border: none;
    box-shadow: none;
  }

  div:hover {
    box-shadow: none;
  }

  div {
    background: ${({ disabled }) => disabled && 'var(--button-default-unavailable-background)'};
  }

  input:disabled::placeholder,
  button:disabled {
    background: var(--button-default-unavailable-background, 'unset');
  }

  input:placeholder-shown {
    opacity: 0.95;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 32px);
  }

  max-width: 100%;
  mini-height: 100%;
  position: relative;
`;

const SearchIcon = styled(Icon)`
  color: black;
  position: absolute;
  padding: 0 ${({ theme }) => theme.spacing.xxsmall.default};
  right: 0;
  align-self: center;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

const UploadButton = styled(Button)<{ disabled: boolean | undefined }>`
  min-width: 69px;
  border: 1px dashed var(--outline-boarder-primary, #aaabac);
  border-radius: 6px;
  align-self: stretch;
  padding-top: ${({ theme }) => theme.spacing.xxsmall.default};
  padding-bottom: ${({ theme }) => theme.spacing.xxsmall.default};

  & p {
    color: ${({ disabled }) => (disabled ? 'var(--button-default-unavailable-background)' : 'black')};
  }

  && {
    & p {
      color: ${({ disabled }) => (disabled ? 'var(--button-default-unavailable-text)' : 'black')};
    }
    background: ${({ disabled }) => (disabled ? 'var(--button-default-unavailable-background)' : 'var(--text-ui-primary-reverse, #fff)')};
    path {
      fill: ${({ disabled }) => (disabled ? 'var(--button-default-unavailable-text)' : 'black')};
    }
  }
`;

const FileInput = styled.input`
  display: none;
`;

export const ImportProductCard = ({ searchClickHandler, loading, onSelectFiles, disabled = false }: ImportProductCardProps) => {
  const [asin, setAsin] = useState<string>();

  const isMobile = useIsMobile();
  const handleFileInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files?.length) {
      const files = Array.from(event.target.files);
      const result = filterStudioImageUploadFiles({ files });

      onSelectFiles?.({ files: result.files, filtered: result.filtered });
    }
  };
  const ref = useRef<HTMLInputElement>(null);
  const onUploadButtonClick = () => {
    if (ref.current) {
      ref.current.value = '';
      ref.current.click();
    }
  };
  return (
    <Container>
      <Flex direction="column" rowGap="xsmall" alignItems="stretch">
        <Text>Import product</Text>
        <Flex direction="row" alignItems="stretch" columnGap="xxsmall">
          <Flex grow={1} id="input-container">
            <InputBox
              disabled={loading || disabled}
              value={asin}
              onChange={(e) => setAsin(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  asin && searchClickHandler(asin.toUpperCase().trim());
                }
              }}
              placeholder="Enter an ASIN or product URL"
              label="ASIN or Product URL"
              hideLabel
              id="studio-input-box-asin-input"
              data-testid={TEST_ID_IMPORT_PRODUCT_CARD_INPUT}
            />
            <SearchIcon
              role="button"
              aira-disabled={loading}
              data-testid={TEST_ID_IMPORT_PRODUCT_CARD_SEARCH_ASIN}
              onClick={() => asin && searchClickHandler(asin.toUpperCase().trim())}
              type={iconTypes.search}
            />
          </Flex>
          <FileInput
            ref={ref}
            type="file"
            multiple={true}
            onChange={handleFileInputChange}
            accept={AcceptedFileTypesForStudioImageUpload.join(',')}
            aria-hidden="true"
          />
          <UploadButton data-testid={TEST_ID_IMPORT_PRODUCT_CARD_UPLOAD_BUTTON} onClick={onUploadButtonClick} disabled={disabled || loading}>
            {isMobile ? (
              <Text>Upload</Text>
            ) : (
              <>
                <AddImage data-testid={TEST_ID_IMPORT_PRODUCT_CARD_UPLOAD_ICON} /> <Text>Upload product image</Text>
              </>
            )}
          </UploadButton>
        </Flex>
      </Flex>
    </Container>
  );
};
