import { Divider, FileUploadDropZone, Flex, Heading, Text } from '@amzn/storm-ui';
import { SyntheticEvent } from 'react';
import RangeSlider from 'src/components/imageModal/components/inputs/RangeSlider';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import { logger } from 'src/logger';
import { isWeblabStateManagementInTreatment } from 'src/util/weblab/config';
import { Icon, IconWrapper } from 'src/v2/components/Icon/Icon';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import { MultiSelectionGrid, MultiSelectionGridProps } from 'src/v2/components/ImageGrid/MultiSelectionGrid';
import { MultiThumbnailDisplay } from 'src/v2/components/MultiThumbnailDisplay/MultiThumbnailDisplay';
import { BLOCK_STUDIO_FILE_UPLOAD_DROPZONE_CLASSNAME } from 'src/v2/components/studio/fileUploadDropzone';
import styles from 'src/v2/components/studio/referenceImageSelector/reference.module.scss';
import { getAssetCacheInstance } from 'src/v2/hooks/useAssetCache/useAssetCache';
import { useFeed } from 'src/v2/hooks/useFeed/useFeed';
import { useFeedImages } from 'src/v2/hooks/useFeedImages/useFeedImages';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { addReferenceImage, getReferenceImages, removeReferenceImage, setReferenceImageStrength } from 'src/v2/redux/slices/userInput/userInputSlice';
import { AssetReferenceImage, ReferenceImageType, UserUploadReferenceImage } from 'src/v2/redux/slices/userInput/userInputSlice.types';
import { AssetTypeEnum } from 'src/v2/types';
import { SimpleAsset } from 'src/v2/types/_partials/Asset.types';
import styled from 'styled-components';
import { v6 as uuidV6 } from 'uuid';

export const referenceImageTestId = {
  DROP_ZONE_ID: 'referenceImageDropZoneId',
  CONTAINER_DATA_TEST_ID: 'referenceImageContainerDataTestId',
  UPLOADER_DATA_TEST_ID: 'referenceImageUploaderDataTestId',
  THUMBNAIL_UPLOADER_DATA_TEST_ID: 'referenceImageThumbnailUploaderDataTestId',
  THUMBNAIL_UPLOADER_DROP_ZONE_ID: 'referenceImageThumbnailUploaderDropZoneId',
  THUMBNAIL_UPLOADER_KEY: 'referenceImageThumbnailKey',
  IMAGE_ASSET_GRID_TEST_ID_PREFIX: 'reference-image-multi',
  MULTI_CONTENT_ITEM_GRID: 'reference-image-content-item-grid',
  DIVIDER_DATA_TEST_ID: 'referenceImageDividerDataTestId',
};

export const referenceImageText = {
  THUMBNAIL_UPLOADER: 'Upload images',
  DROP_ZONE: 'Upload files or drag and drop',
  CONTAINER: 'Selected images (max 3)',
  SLIDER_STRENGTH_TITLE: 'Strength',
  SLIDER_STRENGTH_SUBTITLE: 'How much should the new images look like these images ?',
  SLIDER_STRENGTH_LABEL_MAX: 'More',
  SLIDER_STRENGTH_LABEL_MIN: 'Less',
  MULTI_IMAGE_GRID: 'Add from your feed',
};

export const MAX_REFERENCE_IMAGES_COUNT = 3;
export const DEFAULT_REFERENCE_IMAGE_STRENGTH = 0.8;

const THUMBNAIL_SIZE = {
  DESKTOP: '71px',
  MOBILE: '36px',
} as const;

// Constants
const GRADIENT_BG = `radial-gradient(
  50% 50% at 50% 50%,
  rgba(245, 244, 242, 0) 0%,
  rgba(245, 244, 242, 0.29) 29.5%,
  #f5f4f2 100%
)`;

const DesktopThumbnailContainer = styled.div`
  width: ${THUMBNAIL_SIZE.DESKTOP};
  height: ${THUMBNAIL_SIZE.DESKTOP};
  flex-shrink: 0;
  border-radius: 8px;
  background: ${GRADIENT_BG};

  ${IconWrapper} {
    border-radius: 90px;

    i {
      background: rgba(22, 29, 38, 0.7) !important;

      path {
        fill: white;
      }

      cursor: pointer;
    }
  }
`;

const addImageHeading = (headingProps: { styles: string; headingText: string }) => (
  <Heading className={headingProps.styles}>
    <Icon type={iconTypes.addImage} />
    {headingProps.headingText}
  </Heading>
);
const referenceImageHandler = (
  previouslySelectedStrength: number | undefined,
  headingProps: { styles: string; headingText: string },
  fileUploaderProps: { styles: string; dataTestId: string; dropZoneId: string },
) => {
  const dispatch = useAppDispatch();

  const handleFileUpload = (e: SyntheticEvent, files: File[]) => {
    files.forEach((file) => {
      const url = URL.createObjectURL(file); // fixed the presigend URL expiring issue
      const referenceImage = {
        id: uuidV6(),
        type: ReferenceImageType.USER_UPLOAD,
        url: url,
        // this is to make sure strength is the same for all reference imagery since currently science model can't customize strength per image
        strength: previouslySelectedStrength ?? DEFAULT_REFERENCE_IMAGE_STRENGTH,
      } as UserUploadReferenceImage;
      dispatch(addReferenceImage(referenceImage));
    });
  };
  return (
    <div className={fileUploaderProps.styles}>
      <FileUploadDropZone
        data-testid={fileUploaderProps.dataTestId}
        accept={['image/png', 'image/jpeg']}
        dropzoneId={fileUploaderProps.dropZoneId}
        inputId="file-uploader"
        heading={addImageHeading(headingProps)}
        onChange={handleFileUpload}
        onError={(err) => {
          console.error('File Upload ERROR', err);
          throw new Error('File Upload ERROR');
        }}
      />
    </div>
  );
};

const thumbnailUploader = (previouslySelectedStrength: number | undefined) => {
  const headingProps = {
    styles: styles.thumbnailHeading,
    headingText: referenceImageText.THUMBNAIL_UPLOADER,
  };
  const fileUploaderProps = {
    styles: styles.referenceImageThumbnailUploader,
    dataTestId: referenceImageTestId.THUMBNAIL_UPLOADER_DATA_TEST_ID,
    dropZoneId: referenceImageTestId.THUMBNAIL_UPLOADER_DROP_ZONE_ID,
  };
  return (
    <DesktopThumbnailContainer key={referenceImageTestId.THUMBNAIL_UPLOADER_KEY}>
      {referenceImageHandler(previouslySelectedStrength, headingProps, fileUploaderProps)}
    </DesktopThumbnailContainer>
  );
};

export const ReferenceImageUploader = () => {
  const referenceImages = useAppSelector(getReferenceImages);
  const previouslySelectedStrength = referenceImages?.[0]?.strength;

  const headingProps = {
    styles: styles.heading,
    headingText: referenceImageText.DROP_ZONE,
  };
  const fileUploaderProps = {
    styles: styles.referenceImageUploader,
    dataTestId: referenceImageTestId.UPLOADER_DATA_TEST_ID,
    dropZoneId: referenceImageTestId.DROP_ZONE_ID,
  };
  return referenceImageHandler(previouslySelectedStrength, headingProps, fileUploaderProps);
};

export const ReferenceImageContainer = () => {
  const dispatch = useAppDispatch();
  const referenceImages = useAppSelector(getReferenceImages);
  const previouslySelectedStrength = referenceImages?.[0]?.strength;
  const constructImageToDisplay = () => {
    const imagesToDisplay = [];
    for (const referenceImage of referenceImages) {
      imagesToDisplay.push({
        src: referenceImage.url,
        id: referenceImage.id,
        title: '',
      });
    }
    return imagesToDisplay;
  };

  const shouldShowThumbnailUploader = MAX_REFERENCE_IMAGES_COUNT != referenceImages.length;

  const handleStrengthSelection = (value: number) => {
    referenceImages.forEach((referenceImage) => {
      dispatch(setReferenceImageStrength({ id: referenceImage.id, strength: value }));
    });
  };

  return (
    <div className={styles.referenceImageContainer} data-testid={referenceImageTestId.CONTAINER_DATA_TEST_ID}>
      <div className={styles.referenceImagePanel}>
        <div className={styles.title}>
          <Text>{referenceImageText.CONTAINER}</Text>
        </div>
        <MultiThumbnailDisplay
          images={constructImageToDisplay()}
          onClick={(id: string) => {
            dispatch(removeReferenceImage(id));
          }}
        >
          {shouldShowThumbnailUploader && thumbnailUploader(previouslySelectedStrength)}
        </MultiThumbnailDisplay>
      </div>
      <div className={styles.referenceStrengthPanel}>
        <div className={styles.titles}>
          <Text className={styles.title}>{referenceImageText.SLIDER_STRENGTH_TITLE}</Text>
          <Text className={styles.subtitle}>{referenceImageText.SLIDER_STRENGTH_SUBTITLE}</Text>
        </div>
        <div>
          <RangeSlider
            max={1}
            maxLabel={referenceImageText.SLIDER_STRENGTH_LABEL_MAX}
            min={0}
            minLabel={referenceImageText.SLIDER_STRENGTH_LABEL_MIN}
            step={0.2}
            title={''}
            onChange={handleStrengthSelection}
            defaultValue={DEFAULT_REFERENCE_IMAGE_STRENGTH}
          />
        </div>
      </div>
    </div>
  );
};

export const ReferenceImageryMultiSelectionFeedGrid = () => {
  const dispatch = useAppDispatch();
  const referenceImages = useAppSelector(getReferenceImages);

  const handleDeselect = (referenceImageId: string) => {
    dispatch(removeReferenceImage(referenceImageId));
  };

  const handleSimpleAssetSelect = async (asset: SimpleAsset) => {
    const cacheInstance = getAssetCacheInstance();
    const cachedURL = await cacheInstance.getCachedAssetUrl({ asset });
    if (asset.url && asset.url.startsWith('http')) {
      const referenceImage = {
        id: asset.id,
        type: ReferenceImageType.ASSET,
        url: cachedURL,
        strength: referenceImages?.[0]?.strength ?? DEFAULT_REFERENCE_IMAGE_STRENGTH,
      } as AssetReferenceImage;
      dispatch(addReferenceImage(referenceImage));
    } else {
      logger.error(`${asset} has invalid file format selected for generation`);
    }
  };

  const onSimpleAssetSelect = async (e: SyntheticEvent, asset: SimpleAsset) => {
    setCheck(asset.id) ? handleDeselect(asset.id) : handleSimpleAssetSelect(asset);
  };

  const handleContentItemSelect = async (contentItem: ContentItem) => {
    if (!contentItem.referenceId) return logger.error(`${contentItem} is missing reference ID`);
    if (!contentItem.originalUri || !contentItem.originalUri.startsWith('http'))
      return logger.error(`${contentItem} has invalid file format selected for generation`);
    const cacheInstance = getAssetCacheInstance();
    const cachedAssetUrl = await cacheInstance.getCachedAssetUrl({
      asset: {
        id: contentItem.referenceId,
        type: (contentItem.assetType ?? AssetTypeEnum.IMAGE) as SimpleAsset['type'],
        url: contentItem.originalUri,
      },
    });
    const referenceImage: AssetReferenceImage = {
      id: contentItem.referenceId,
      type: ReferenceImageType.ASSET,
      url: cachedAssetUrl,
      strength: referenceImages?.[0]?.strength ?? DEFAULT_REFERENCE_IMAGE_STRENGTH,
    };
    dispatch(addReferenceImage(referenceImage));
  };

  const onContentItemSelect = (e: SyntheticEvent, contentItem: ContentItem) => {
    setCheck(contentItem.referenceId) ? handleDeselect(contentItem.referenceId ?? '') : handleContentItemSelect(contentItem);
  };

  const setCheck = (referenceImageId: string | undefined) => {
    return referenceImages.some((referenceImage) => referenceImage.type === ReferenceImageType.ASSET && referenceImageId === referenceImage.id);
  };

  const disabled = (referenceImageId: string | undefined) => {
    const remainsCount = MAX_REFERENCE_IMAGES_COUNT - referenceImages.length;
    return remainsCount === 0 && !setCheck(referenceImageId);
  };

  const multiSelectionGridProps: MultiSelectionGridProps = {
    disabled: disabled,
    onAssetSelect: onSimpleAssetSelect,
    onContentItemSelect: onContentItemSelect,
    setCheck: setCheck,
    testIdPrefixForAsset: referenceImageTestId.IMAGE_ASSET_GRID_TEST_ID_PREFIX,
    componentLabelForContentItem: referenceImageTestId.MULTI_CONTENT_ITEM_GRID,
  };

  return (
    <div className={styles.multiImageGrid}>
      <Divider marginBlockStart="large" marginBlockEnd="large" data-testid={referenceImageTestId.DIVIDER_DATA_TEST_ID} />
      <Heading size="small" renderAs="h3" color="default" className={styles.feedHeader}>
        {referenceImageText.MULTI_IMAGE_GRID}
      </Heading>
      <MultiSelectionGrid {...multiSelectionGridProps} />
    </div>
  );
};

export const ReferenceImageSelector = () => {
  const referenceImages = useAppSelector(getReferenceImages);
  const isStateManagementEnabled = isWeblabStateManagementInTreatment();
  const { feedImages } = useFeedImages();
  const { feedAssets } = useFeed();
  const shouldShowFeed = isStateManagementEnabled ? feedAssets.length !== 0 : feedImages.length !== 0;

  return (
    <Flex grow={1} direction="column" className={BLOCK_STUDIO_FILE_UPLOAD_DROPZONE_CLASSNAME}>
      {referenceImages.length === 0 ? <ReferenceImageUploader /> : <ReferenceImageContainer />}
      {shouldShowFeed ? <ReferenceImageryMultiSelectionFeedGrid /> : null}
    </Flex>
  );
};
