import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import { uploadProductMiddleware, uploadReferenceImageMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/ImageUpload.middleware';
import { generateTextPromptsForProductImagesMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/TextPromptGeneration.middleware';
import { ProductImageGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { prepareGenerationResults } from 'src/v2/contexts/assetGeneration/utils/common/GenerationResults.utils';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { DeepPartial, ProductImageAsset } from 'src/v2/types';
import { handleProductImageGenerationSubmission } from './handleProductImageGenerationSubmission';

/**
 * The primary handler for generating a list of ProductImageAssets.
 */
export async function handleProductImageGeneration({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: ProductImageGenerationConfig;
}): Promise<{
  generatedAssets: ProductImageAsset[];
  errors: Error[];
}> {
  const { outputCount, userInputs } = generationConfig;
  const { product, textPrompt, referenceImages } = userInputs;

  // STEP - Get product reference ID
  const productImageReferenceId = await uploadProductMiddleware({ backendDispatchContext, product });

  const referenceImagesReferenceIds = referenceImages
    ? await Promise.all(
        referenceImages.map(async (referenceImage) => {
          return await uploadReferenceImageMiddleware({ backendDispatchContext, urlOrFileOrRefId: referenceImage.url });
        }),
      )
    : undefined;

  // STEP - Get guided text prompts if textPrompt not given
  const guidedTextPrompts = await generateTextPromptsForProductImagesMiddleware({
    backendDispatchContext,
    generationConfig,
    productImageReferenceId,
  });

  // STEP - Run parallel generations
  const generations = guidedTextPrompts ? guidedTextPrompts.map((textPrompt) => ({ outputCount: 1, textPrompt })) : [{ outputCount, textPrompt }];

  const generationConfigs: ProductImageGenerationConfig[] = generations.map(({ outputCount, textPrompt }) => {
    const generationConfigOverrides: DeepPartial<typeof generationConfig> = {
      outputCount,
      userInputs: {
        textPrompt,
      },
    };
    return _merge(_cloneDeep(generationConfig), generationConfigOverrides);
  });

  const generationResults = await Promise.allSettled(
    generationConfigs.map((generationConfig) =>
      handleProductImageGenerationSubmission({ backendDispatchContext, generationConfig, productImageReferenceId, referenceImagesReferenceIds }),
    ),
  );

  return prepareGenerationResults<ProductImageAsset>({ generationResults });
}
