import { HelpTip, Icon, Menu, MenuItemButton, Text } from '@amzn/storm-ui';
import { ellipsisH } from '@amzn/storm-ui-icons';
import { useMutation } from '@tanstack/react-query';
import { CSSProperties, memo, useCallback, useContext, useEffect, useState } from 'react';
import { AnimatedStars } from 'src/animations/AnimatedStars';
import { AppContext } from 'src/AppContext';
import AssetContentCard from 'src/components/common/assetGallery/AssetContentCard';
import { FeedbackContext, useFeedbackContext } from 'src/components/feedback/FeedbackContext';
import { FeedbackThumbsControl } from 'src/components/feedback/FeedbackThumbsControl';
import { ContentItem, StudioContext, isImportedMediaWorkflow } from 'src/components/pages/studio/StudioContext';
import { DeleteAssetFailureNotification, DeleteAssetProcessingNotification } from 'src/components/snackbar/notifications/DeleteAssetNotifications';
import { downloadFromUrl } from 'src/components/utils/LinkUtils';
import { Metrics } from 'src/constants';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import useAssetImageCache from 'src/hooks/useAssetImageCache';
import useAssetLibrary from 'src/hooks/useAssetLibrary';
import PlayIcon from 'src/images/icons/playIcon.svg';
import { AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment } from 'src/util/weblab/config';
import { isSponsoredCampaignLaunched, isWeblabInTreatment } from 'src/util/weblab/weblab';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';
import {
  GenerationPlaceholder,
  PLACEHOLDER_HEADER_GENERATING,
  PLACEHOLDER_HEADER_LOADING,
} from 'src/v2/components/placeholder/GenerationPlaceholder';
import { Placeholder } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderAnimation, PlaceholderType, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { useReuseInputs } from 'src/v2/hooks/useInputSettingsReuse/useInputSettingsReuse';
import { AssetTypeEnum, FeedbackScopeEnum } from 'src/v2/types';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import styled from 'styled-components';
import styles from './ContentTile.module.scss';
import { ImageUploadPlaceholder } from './ImageUploadPlaceholder';

const MenuWrapperStyles = styled(Menu)`
  & {
    background-color: var(--surface-secondary, #ffffff);
    border: 1px solid var(--border-medium, #ffffff);

    && button {
      color: var(--text-primary, #000000);
    }

    && button:focus-visible,
    button:focus-visible {
      outline: none;
      border: none;
    }
  }
`;

/**
 * DEPRECATION NOTICE:
 * After State Managmeent becomes the control, the logic in this file will be deprecated by the components and logic in the following:
 * - src/v2/components/studio/feed/
 * - src/v2/contexts/feed/
 * - src/v2/redux/slices/feed/
 * - src/v2/hooks/useAssetLibrary/
 */

export const CONTENT_TILE_PLACEHOLDER = 'content-tile-placeholder';
export const UPLOAD_PLACEHOLDER_FADE_OUT_TIMEOUT = 4000;
export const FADE_OUT_TRANSITION_TIME = 500;
export const UPLOAD_PLACEHOLDER_TOTAL_ANIMATION_TIME = UPLOAD_PLACEHOLDER_FADE_OUT_TIMEOUT + FADE_OUT_TRANSITION_TIME;

export interface ContentTileProps {
  className?: string;
  contentItem: ContentItem;
  index?: number;
  onClick?: (index: number, currentTab?: number) => void;
  style?: CSSProperties;
}

const ContentTile = (props: ContentTileProps) => {
  const { className, contentItem, index, style } = props;
  const {
    aspectRatio,
    contentHeadline,
    contentType,
    loading,
    message,
    originalUri,
    referenceId,
    studioInputSettings,
    width,
    workflowId,
    assetType,
    transitionOut,
    placeholderType,
  } = contentItem;
  const containerInlineStyle: CSSProperties = {};
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFadeOut, setIsFadeOut] = useState<boolean>(false);
  const [isPendingAction, setIsPendingAction] = useState<boolean>(false);
  const studioContext = useContext(StudioContext);
  const useAssetLibraryHook = useAssetLibrary();
  const content = useAssetImageCache(contentItem);
  const client = useAIBackendHubClient();
  const { addProcessingNotification, addFailureNotification } = useNotificationActions();
  const { handleReuseInputs } = useReuseInputs();

  const isInputPanelRefresh = isWeblabInTreatment(AICS_WEBLAB_STUDIO_INPUT_REFRESH, WeblabTreatment.T1);

  const feedbackContext = useFeedbackContext({
    feedbackScope: FeedbackScopeEnum.ASSET,
    onSubmittedFeedback: (submittedFeedback) => {
      const updatedContentItem: ContentItem = {
        ...props.contentItem,
        feedback: submittedFeedback.feedback,
      };
      studioContext.updateResult(updatedContentItem);
    },
  });
  const appContext = useContext(AppContext);
  const entityId = appContext?.selectedAdvertisingAccount?.alternateIds?.[0];
  const canSaveAsset = appContext.hasWritePermissionToAssetLibrary;
  const isFeedEnabled = appContext.accountType === 'internal' || canSaveAsset;

  useEffect(() => {
    feedbackContext.setFeedbackSentiment(props.contentItem.feedback?.sentiment);
    feedbackContext.setFeedbackComment(props.contentItem.feedback?.comment);
    feedbackContext.setFeedbackMetadata({
      assetUrl: originalUri || '',
      cachedAssetUrl: content,
      // NOTE: temporarily falling back on contentHeadline for prompt since Feed assets include an incomplete model
      // TODO: only use studioInputSettings.prompt after Feed is fixed to provide studioInputSettings
      prompt: studioInputSettings?.prompt || contentHeadline || '',
      workflowId,
      referenceId,
      assetType: assetType || AssetTypeEnum.IMAGE,
    });
  }, [props.contentItem, content]);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onClickReuseSettingsMenuItem = () => {
    closeMenu();
    if (studioInputSettings) {
      studioContext.handleReuseSettings({
        studioInputSettings,
      });

      if (isInputPanelRefresh) {
        // new way for redux state management
        handleReuseInputs(studioInputSettings);
      }
    }
  };

  const onClickAddToAssetLibrary = async () => {
    closeMenu();
    if (!referenceId) return;
    const response = await client.retrieveAsset({
      id: referenceId,
      entityId: entityId,
    });
    const url = response?.body.asset?.uri || '';
    await useAssetLibraryHook.saveAsset({ assetUrl: url, asin: studioInputSettings?.asin?.asin });
  };

  const onClickEdit = (assetType?: AssetTypeEnum) => {
    closeMenu();
    handleOpenPreview(assetType === AssetTypeEnum.VIDEO ? 0 : 1);
  };

  const onClickDeleteImageMenuItem = () => {
    setIsPendingAction(true);
    if (isFeedEnabled) {
      const cleanupDeleteProcessingNotification = addProcessingNotification({
        SnackbarContent: DeleteAssetProcessingNotification,
      });
      setIsOpen(false);
      deleteAssetMutation.mutate({ cleanupDeleteProcessingNotification });
    } else {
      setIsOpen(false);
      studioContext.removeResult(referenceId || '');
    }
  };

  const handleOpenPreview = useCallback(
    (currentTab?: number) => {
      if (content && index !== undefined) {
        props.onClick?.(index, currentTab);
      }
    },
    [props.onClick, content, index],
  );

  const contentCardClick = useCallback(() => handleOpenPreview(), [handleOpenPreview]);

  const deleteAssetMutation = useMutation({
    mutationFn: () => {
      return client.deleteAsset({
        id: referenceId || '',
        entityId: entityId,
      });
    },
    onSuccess: (_data, { cleanupDeleteProcessingNotification }: { cleanupDeleteProcessingNotification: () => void }) => {
      if (referenceId !== undefined) {
        studioContext.removeResult(referenceId);
      }
      cleanupDeleteProcessingNotification();
      setIsPendingAction(false);
    },
    onError: () => {
      addFailureNotification({ SnackbarContent: DeleteAssetFailureNotification });
      setIsPendingAction(false);
    },
  });

  if (width) {
    containerInlineStyle.width = width;
  }

  if (isFadeOut) {
    containerInlineStyle.opacity = '0';
    containerInlineStyle.aspectRatio = '1/0.05';
    containerInlineStyle.transition = `opacity 0.3s ease-in-out, aspect-ratio ${FADE_OUT_TRANSITION_TIME}ms ease-in-out`;
  } else if (aspectRatio) {
    containerInlineStyle.aspectRatio = aspectRatio.replace(':', ' / ');
  } else {
    containerInlineStyle.aspectRatio = '1.91/1';
  }

  const downloadAsset = async () => {
    if (content) {
      downloadFromUrl({ url: content, fileName: `ailab-${crypto.randomUUID()}` });
      appContext.metrics.trackMetrics(Metrics.Methods.ButtonClick, {}, { [Metrics.Counters.Download]: 1 });
      closeMenu();
    }
  };

  const isImportedMediaWorkflowResult = isImportedMediaWorkflow(contentItem.workflowId);

  const getContentWithImage = () => {
    return (
      <AssetContentCard
        asset={{
          uri: content || '',
          type: assetType || AssetTypeEnum.IMAGE,
        }}
        className={styles.displayImageContent}
        clickHandler={contentCardClick}
        containerTestId="studio-feed-item-image"
        overlay={
          <div className={styles.overlay} data-testid="studio-feed-item-overlay">
            <div className={styles.shadow} />
            <div className={styles.contentMetaContainer}>
              <div className={styles.contentCategory}>
                <div className={styles.contentCategoryTag} data-testid="studio-feed-item-content-type-text">
                  {contentType}
                </div>
              </div>
              {contentHeadline && (
                <div className={styles.contentMetaDescription} data-testid="studio-feed-item-headline-text">
                  {contentHeadline}
                </div>
              )}
            </div>
            <div className={styles.topBar}>
              <div className={styles.topBarLeft}>
                {!isImportedMediaWorkflowResult && (
                  <FeedbackContext.Provider value={feedbackContext}>
                    <FeedbackThumbsControl />
                  </FeedbackContext.Provider>
                )}
                {/* Enable this and configure when favorite functionality is ready
                  <Button
                    dataTestId={iconTypes.favorite.iconName}
                    icon={iconTypes.favorite}
                    iconOnly
                    iconOnlyNoBackground
                    darkMode
                    onClick={() => {
                      // todo: add handler
                    }}
                  />
                */}
                <Button
                  className={styles.trashCan}
                  dataTestId={iconTypes.trashCan.iconName}
                  icon={iconTypes.trashCan}
                  iconOnly
                  iconOnlyNoBackground
                  iconOnlyColor="#ffffff" // intentionally force always white
                  darkMode
                  onClick={() => {
                    if (!isPendingAction) {
                      onClickDeleteImageMenuItem();
                    }
                  }}
                  style={!isImportedMediaWorkflowResult ? { paddingLeft: '10px' } : {}}
                />
              </div>
              <div className={styles.topBarRight}>
                {assetType === AssetTypeEnum.IMAGE && (
                  <Button
                    label="Edit"
                    borderRadius="999px"
                    colorOverride
                    iconOnlyColor="#ffffff"
                    iconOnlyBackgroundColor="transparent"
                    dataTestId="overlay-edit-button"
                    type={ButtonTypes.Secondary}
                    icon={iconTypes.pencil}
                    onClick={() => {
                      if (!isPendingAction) {
                        onClickEdit(assetType);
                      }
                    }}
                  />
                )}
                <span className={styles.menu}>
                  <MenuWrapperStyles
                    id="example-menu"
                    onClick={toggleMenu}
                    onClose={closeMenu}
                    isOpen={isOpen}
                    trigger={<Icon type={ellipsisH} />}
                    className={styles.menuWrapper}
                    data-testid="studio-feed-item-menu-toggle"
                  >
                    <MenuItemButton onClick={onClickAddToAssetLibrary} disabled={!canSaveAsset} data-testid="studio-feed-item-menu-save-button">
                      Save to creative assets
                    </MenuItemButton>
                    <MenuItemButton onClick={downloadAsset} data-testid="studio-feed-item-menu-download-button">
                      Download
                    </MenuItemButton>
                    {!!studioInputSettings && (
                      <MenuItemButton onClick={onClickReuseSettingsMenuItem} data-testid="studio-feed-item-menu-reuse-settings-button">
                        Reuse settings
                      </MenuItemButton>
                    )}
                    <MenuItemButton className={styles.edit} onClick={() => onClickEdit(assetType)} data-testid="studio-feed-item-menu-edit-button">
                      {assetType === AssetTypeEnum.VIDEO ? 'Preview' : 'Edit'}
                    </MenuItemButton>
                    <MenuItemButton onClick={onClickDeleteImageMenuItem} data-testid="studio-feed-item-menu-delete-button">
                      Delete
                    </MenuItemButton>
                    {isSponsoredCampaignLaunched() && (
                      <MenuItemButton onClick={() => {}} data-testid="studio-feed-item-menu-start-a-sb-campaign">
                        Start a Sponsored Brands campaign
                      </MenuItemButton>
                    )}
                  </MenuWrapperStyles>
                </span>
              </div>
            </div>
          </div>
        }
        referenceId={referenceId}
        hover
      >
        <div className={styles.overlayNoHover} data-testid="studio-feed-item-overlay-no-hover">
          {isImportedMediaWorkflowResult && (
            <div className={styles.uploadBadge}>
              <Text renderAs="span">Uploaded</Text>
            </div>
          )}
          {assetType === AssetTypeEnum.VIDEO && (
            <div className={styles.videoIcon}>
              <PlayIcon />
            </div>
          )}
        </div>
      </AssetContentCard>
    );
  };

  const getPlaceholder = () => {
    // Legacy Error Placeholder
    if (message) {
      const icon = loading ? <AnimatedStars width={40} height={50} /> : message ? <HelpTip>{message}</HelpTip> : '';
      const texts = loading ? (
        contentType === 'ASIN' ? (
          <>
            <span>Loading </span> product data
          </>
        ) : (
          <>
            <span>Generating </span> {contentType}
          </>
        )
      ) : message ? (
        'Sorry, something went wrong'
      ) : (
        ''
      );

      if (!isFadeOut) {
        setTimeout(() => setIsFadeOut(true), 4000);
        setTimeout(() => setIsFadeOut(false), 4500);
      }

      return (
        <>
          {(loading || message) && (
            <div className={`${styles.loading} ${contentType === 'lifestyle image' ? styles.lifestyleType : styles.productType}`} />
          )}
          <div className={styles.text} data-testid="studio-feed-item-status-message">
            {icon}
            {texts}
          </div>
        </>
      );
    }

    // UploadPlaceholder
    if (placeholderType === PlaceholderType.UPLOAD) {
      return getUploadPlaceholder();
    }

    // GenerationPlaceholder
    if (placeholderType === PlaceholderType.GENERATION) {
      const header = contentType === 'ASIN' ? PLACEHOLDER_HEADER_LOADING : PLACEHOLDER_HEADER_GENERATING;
      const subHeader = contentType === 'ASIN' ? 'product data' : contentType;
      return <GenerationPlaceholder data-testid={CONTENT_TILE_PLACEHOLDER} className={styles.placeholder} header={header} message={subHeader} />;
    }

    // Normal Placeholders
    return (
      <Placeholder
        data-testid={CONTENT_TILE_PLACEHOLDER}
        className={styles.placeholder}
        type={placeholderType}
        variant={placeholderType === PlaceholderType.EMPTY ? PlaceholderVariant.DOTTED : PlaceholderVariant.ROUNDED}
        animation={placeholderType === PlaceholderType.EMPTY ? PlaceholderAnimation.NONE : PlaceholderAnimation.WAVE}
      />
    );
  };

  const getUploadPlaceholder = () => {
    if (transitionOut && !isFadeOut) {
      setTimeout(() => setIsFadeOut(true), UPLOAD_PLACEHOLDER_FADE_OUT_TIMEOUT);
    }
    return <ImageUploadPlaceholder placeholderId={contentItem.placeholderId} placeholderUrl={contentItem.placeholderUrl} />;
  };

  return (
    <div
      style={{ ...containerInlineStyle, ...style }}
      className={getNormalizedClassNames([
        styles.container,
        styles[`as_${aspectRatio}`] ?? styles.as_fallback,
        isOpen ? styles.kebabMenuActive : '',
        feedbackContext.isFeedbackPopoverOpen ? styles.feedbackPopoverOpen : '',
        className,
      ])}
      data-testid={content ? 'studio-feed-item-asset' : 'studio-feed-item-non-asset'}
      data-test-aspect-ratio={aspectRatio}
    >
      {content ? getContentWithImage() : getPlaceholder()}
    </div>
  );
};

export default memo(ContentTile);
