import { Asset } from '@amzn/genaihub-typescript-client';
import { MouseEventHandler, ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import VideoIcon from 'src/images/icons/videoicon.svg';
import ViewIcon from 'src/images/icons/viewicon.svg';
import { Placeholder } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderAnimation, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';
import { AssetTypeEnum } from 'src/v2/types';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import styles from './AssetContentCard.module.scss';

export const TEST_ID_ASSET_CONTENT_CARD = 'asset-content-card';
export const TEST_ID_ASSET_CONTENT_CARD_CLICKBOX = 'asset-content-card-click-box';
export const TEST_ID_ASSET_CONTENT_CARD_OVERLAY = 'asset-content-card-overlay';
export const TEST_ID_ASSET_CONTENT_CARD_NO_OVERLAY = 'asset-content-card-no-overlay';
export const TEST_ID_ASSET_CONTENT_CARD_ICON_VIDEO = 'asset-content-card-icon-video';
export const TEST_ID_ASSET_CONTENT_CARD_ICON_VIEW = 'asset-content-card-icon-view';

export interface AssetGalleryProps extends React.HTMLAttributes<HTMLDivElement> {
  asset?: Asset;
  autoPlayTime?: number;
  clickHandler?: MouseEventHandler<HTMLDivElement>;
  containerTestId?: string;
  controls?: boolean;
  hover?: boolean;
  loadedCallback?: (dimensions?: { width?: number; height?: number }) => void;
  overlay?: ReactNode;
  referenceId?: string;
  viewIcon?: boolean;
}

export default function AssetContentCard(props: AssetGalleryProps) {
  const { asset, autoPlayTime, children, className, clickHandler, controls, hover, loadedCallback, overlay, style, viewIcon } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const videoTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const video = useRef<HTMLVideoElement>(null);
  const previousAsset = useRef(asset);

  const onImageLoad = (event: SyntheticEvent) => {
    const { naturalWidth: width, naturalHeight: height } = event.target as HTMLImageElement;
    onLoad({ width, height });
  };

  const onVideoLoad = (event: SyntheticEvent) => {
    const { videoWidth: width, videoHeight: height } = event.target as HTMLVideoElement;
    onLoad({ width, height });
  };

  const onLoad = async (dimensions?: { width?: number; height?: number }) => {
    setIsLoaded(true);
    loadedCallback?.(dimensions);
  };

  const onMouseEnter = () => {
    if (!controls && video.current) {
      if (autoPlayTime) {
        video.current.currentTime = 0;
      }
      // play is asyncronous and can throw an error
      video.current.play().catch((e) => console.error(e));

      // set up a time out to pause the video
      if (autoPlayTime) {
        if (videoTimeout.current) {
          clearTimeout(videoTimeout.current);
        }
        videoTimeout.current = setTimeout(() => {
          if (video.current) {
            video.current.pause();
            video.current.currentTime = 0;
          }
        }, autoPlayTime);
      }
    }
  };

  const onMouseLeave = () => {
    if (!controls && video.current) {
      video.current.pause();

      if (autoPlayTime) {
        video.current.currentTime = 0;
        if (videoTimeout.current) {
          clearTimeout(videoTimeout.current);
        }
      }
    }
  };

  useEffect(() => {
    preLoadVideo();

    return () => {
      if (videoTimeout.current) {
        clearTimeout(videoTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    // update the current previous asset with the current one
    if (previousAsset.current?.uri !== asset?.uri) {
      previousAsset.current = asset;
      // if the asset changes and it had loaded reload the video and update state
      if (isLoaded) {
        preLoadVideo();
        setIsLoaded(false);
      }
    }
  }, [isLoaded, asset]);

  function preLoadVideo() {
    if (asset?.type === AssetTypeEnum.VIDEO) {
      try {
        video.current?.load();
      } catch (err) {
        console.error('Error pre-loading AssetContentCard video', asset?.uri);
      }
    }
  }

  return (
    <div
      className={getNormalizedClassNames([styles['asset-card'], isLoaded && styles.loaded, hover && styles.hover, className])}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      data-testid={props.containerTestId ?? `${TEST_ID_ASSET_CONTENT_CARD}${asset?.uri ? `-${asset?.uri}` : ''}`}
    >
      <div className={styles.assetContainer}>
        {asset?.type === AssetTypeEnum.IMAGE ? (
          <img
            className={`${styles.content} ${styles.image}`}
            srcSet={asset.uri}
            src={asset.uri}
            alt={asset.uri}
            data-testid={props.referenceId ?? `${TEST_ID_ASSET_CONTENT_CARD}-image-${asset?.uri}`}
            onLoad={onImageLoad}
            loading="lazy"
          />
        ) : asset?.type === AssetTypeEnum.VIDEO ? (
          <video
            ref={video}
            className={`${styles.content} ${styles.video}`}
            onLoadStart={onVideoLoad}
            preload={'auto'}
            controls={controls}
            playsInline={!controls}
            data-testid={props.referenceId ?? `${TEST_ID_ASSET_CONTENT_CARD}-video-${asset?.uri}`}
            muted
            loop
          >
            <source src={asset.uri} type="video/mp4" />
          </video>
        ) : (
          <Placeholder
            data-testid={`${TEST_ID_ASSET_CONTENT_CARD}-placeholder`}
            className={styles.placeholder}
            variant={PlaceholderVariant.ROUNDED}
            animation={PlaceholderAnimation.PULSATE}
          />
        )}
        {asset && asset.type && !isLoaded && (
          <Placeholder
            data-testid={`${TEST_ID_ASSET_CONTENT_CARD}-loading`}
            className={`${styles.placeholder} ${styles.loading}`}
            variant={PlaceholderVariant.ROUNDED}
            animation={PlaceholderAnimation.WAVE}
          />
        )}
      </div>
      {asset && isLoaded && (
        <>
          <div data-testid={TEST_ID_ASSET_CONTENT_CARD_NO_OVERLAY} className={styles.overlayNoHover}>
            {!children && (
              <>
                {asset?.type === AssetTypeEnum.VIDEO && (
                  <div className={`${styles.icon} ${styles.video}`}>
                    <VideoIcon data-testid={TEST_ID_ASSET_CONTENT_CARD_ICON_VIDEO} />
                  </div>
                )}
                {viewIcon && (
                  <div className={`${styles.icon} ${styles.view}`}>
                    <ViewIcon data-testid={TEST_ID_ASSET_CONTENT_CARD_ICON_VIEW} />
                  </div>
                )}
              </>
            )}
            {children}
          </div>

          <div data-testid={TEST_ID_ASSET_CONTENT_CARD_OVERLAY} className={styles.overlay}>
            <div data-testid={TEST_ID_ASSET_CONTENT_CARD_CLICKBOX} className={styles.clickBox} onClick={clickHandler} />
            {overlay}
          </div>
        </>
      )}
    </div>
  );
}
