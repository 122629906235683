import { StudioInputSettings } from 'src/components/pages/studio/StudioContext';
import { createNewLayoutWithBackground } from 'src/v2/components/studio/productSelector/productSelectorHelpers';
import { useAppDispatch, useAppSelector } from 'src/v2/redux/hooks';
import { selectProductEntities } from 'src/v2/redux/slices/product/productSlice';
import {
  appendManyProductLayout,
  clearEffectSelection,
  getEffectSelections,
  removeAllProductLayouts,
  setEffectSelection,
  setStyleSelection,
  setTextPrompt,
  setUserInputAspectRatio,
} from 'src/v2/redux/slices/userInput/userInputSlice';
import { ProductLayout } from 'src/v2/redux/slices/userInput/userInputSlice.types';
import { AspectRatio, EffectOption, EffectType } from 'src/v2/types';

export const useReuseInputs = () => {
  const dispatch = useAppDispatch();
  const existingEffects = useAppSelector(getEffectSelections);
  const productMap = useAppSelector(selectProductEntities);

  const handleReuseInputs = async (itemInputs: StudioInputSettings) => {
    if (itemInputs) {
      const { multiProduct, prompt, format, style, effects } = itemInputs;

      // re-use text prompt from selection
      if (prompt && prompt.length > 0) {
        dispatch(setTextPrompt(prompt));
      }

      // re-use aspect ratio from selection
      if (format) {
        dispatch(setUserInputAspectRatio(format as AspectRatio));
      }

      // re-use style from selection
      if (style) {
        dispatch(setStyleSelection(style));
      }

      // re-use effects from selection
      if (effects) {
        if (existingEffects) {
          // clear existing effects first since they can be appended to and not just replaced
          Object.entries(existingEffects).forEach(([type]) => dispatch(clearEffectSelection(type as EffectType)));
        }
        Array.from(effects).forEach(([type, value]) => {
          const effect: { type: EffectType; value?: EffectOption } = {
            type: type as EffectType,
          };
          if (value !== undefined) {
            effect.value = value as EffectOption;
          }
          dispatch(setEffectSelection(effect));
        });
      }

      if (multiProduct) {
        dispatch(removeAllProductLayouts());
        const newLayouts: ProductLayout[] = [];
        for await (const product of multiProduct) {
          if (!product) continue;
          const { boundingBox, asin } = product;
          if (!boundingBox || !asin) continue;
          const asinProduct = productMap[asin];
          if (!asinProduct) continue;
          const layout = createNewLayoutWithBackground({
            product: asinProduct,
            boundingBox,
          });
          newLayouts.push(layout);
        }
        newLayouts.length > 0 && dispatch(appendManyProductLayout(newLayouts));
      }
    }
  };

  return { handleReuseInputs };
};
