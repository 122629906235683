import { Flex, useIsMobile } from '@amzn/storm-ui';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Icon, IconWrapper } from '../Icon/Icon';
import { Thumbnail } from '../Thumbnail/Thumbnail';
import ThumbnailStyle from '../Thumbnail/Thumbnail.module.scss';
import { IconPositions, ThumbnailType } from '../Thumbnail/types';

// Constants
const GRADIENT_BG = `radial-gradient(
  50% 50% at 50% 50%,
  rgba(245, 244, 242, 0) 0%,
  rgba(245, 244, 242, 0.29) 29.5%,
  #f5f4f2 100%
)`;

const THUMBNAIL_SIZE = {
  DESKTOP: '71px',
  MOBILE: '36px',
} as const;

const ICON_SIZE = {
  DESKTOP: '12px',
  MOBILE: '16px',
} as const;

const THUMBNAIL_CONFIG = {
  desktop: {
    width: '100%',
    height: '100%',
  },
  mobile: {
    width: THUMBNAIL_SIZE.MOBILE,
    height: THUMBNAIL_SIZE.MOBILE,
  },
} as const;

// Styled Components
const DesktopThumbnailContainer = styled.div`
  width: ${THUMBNAIL_SIZE.DESKTOP};
  height: ${THUMBNAIL_SIZE.DESKTOP};
  flex-shrink: 0;
  border-radius: 8px;
  background: ${GRADIENT_BG};

  ${IconWrapper} {
    border-radius: 90px;

    i {
      background: rgba(22, 29, 38, 0.7) !important;

      path {
        fill: white;
      }

      cursor: pointer;
    }
  }
`;

const MobileContainer = styled(Flex)`
  padding: var(--spacing-125, 5px) 8px var(--spacing-125, 5px) var(--spacing-125, 5px);
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--outline-boarder-secondary, #edeae3);

  & .${ThumbnailStyle.thumbnailContainer} {
    background: ${GRADIENT_BG};
    border-radius: 8px;
  }
`;

// Types
interface ImageItem {
  src: string;
  id: string;
  title: string;
}

export interface MultiThumbnailDisplayProps {
  images: ImageItem[];
  onClick: (id: string) => void;
  children?: ReactNode;
}

// Component
export const MultiThumbnailDisplay = ({ images, onClick, children }: MultiThumbnailDisplayProps) => {
  const isMobile = useIsMobile();

  const renderDesktopThumbnail = (image: ImageItem) => (
    <DesktopThumbnailContainer key={image.id}>
      <Thumbnail
        thumbnailOptions={THUMBNAIL_CONFIG.desktop}
        src={image.src}
        alt="Product thumbnail"
        thumbnailType={ThumbnailType.Remove}
        overlayIconOptions={{
          iconPosition: IconPositions.TopRight,
          iconType: 'close',
          iconSize: ICON_SIZE.DESKTOP,
        }}
        onClick={() => onClick(image.id)}
      />
    </DesktopThumbnailContainer>
  );

  const renderMobileThumbnail = (image: ImageItem) => (
    <MobileContainer key={image.id} alignItems="center">
      <Thumbnail thumbnailOptions={THUMBNAIL_CONFIG.mobile} src={image.src} alt="Product thumbnail" onClick={() => onClick(image.id)} />
      <Icon type="close" size={ICON_SIZE.MOBILE} />
    </MobileContainer>
  );

  const renderThumbnail = (image: ImageItem) => {
    if (!image.src) return null;
    return isMobile ? renderMobileThumbnail(image) : renderDesktopThumbnail(image);
  };

  return (
    <Flex columnGap="xxxsmall">
      {images.map(renderThumbnail)}
      {children}
    </Flex>
  );
};
