import { download } from '@amzn/storm-ui-icons';
import { useContext } from 'react';
import { AppContext } from 'src/AppContext';
import { downloadFromUrl } from 'src/components/utils/LinkUtils';
import { Metrics } from 'src/constants';
import { Button } from 'src/v2/components/Button/Button';
import { ButtonTypes } from 'src/v2/components/Button/types';

export const DOWNLOAD_IMAGE_BUTTON_TEST_ID = 'download-image-button';
export interface DownloadImageButtonProps {
  url: string;
  disabled?: boolean;
}

export const DownloadImageButton = ({ url, disabled }: DownloadImageButtonProps) => {
  const appContext = useContext(AppContext);
  const clickHandler = () => {
    if (!disabled) {
      downloadFromUrl({ url, fileName: `ailab-${crypto.randomUUID()}` });
      appContext.metrics.trackMetrics(Metrics.Methods.ButtonClick, {}, { [Metrics.Counters.Download]: 1 });
    }
  };

  return (
    <Button
      dataTestId={DOWNLOAD_IMAGE_BUTTON_TEST_ID}
      label="Download"
      type={ButtonTypes.Primary}
      icon={download}
      iconSize="14px"
      onClick={clickHandler}
      disabled={disabled}
      iconOnlyColor="#000000"
    />
  );
};
