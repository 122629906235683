import { Heading, Text } from '@amzn/storm-ui';
import React, { useContext, useEffect, useState } from 'react';
import { EditorContextP1 } from 'src/components/editor/EditorContext';
import { HEADING_TEXT_SIZE } from 'src/constants';
import styled from 'styled-components';

export interface ControlLabelProps {
  title: string | React.ReactElement;
  subTitle?: string;
  defaultValue?: string;
  titleSize?: HEADING_TEXT_SIZE;
}

const ControlLabelWrapper = styled.div`
  margin-bottom: 12px;
`;

const ControlLabelHeading = styled(Heading)<Partial<{ paddingBottom?: string; opacity?: number }>>`
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? '8px' : 'unset')};
  opacity: ${({ opacity }) => opacity ?? 'unset'};
  color: var(--text-primary, #000000);
`;

const ControlLabelText = styled(Text)<Partial<{ paddingBottom?: string; opacity?: number }>>`
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? '8px' : 'unset')};
  opacity: ${({ opacity }) => opacity ?? 'unset'};
  font-size: 15px;
  font-weight: 600;
  color: var(--text-primary, #000000);

  p {
    color: var(--text-primary, 'unset');
  }
`;

const ControlLabelSubText = styled(Text)<Partial<{ opacity?: number }>>`
  opacity: ${({ opacity }) => opacity ?? 'unset'};
  font-size: 15px;
  font-weight: 600;
  font-style: 'italic';
  color: var(--text-primary, #000000);
`;

const ControlLabel = (props: ControlLabelProps) => {
  const { title, subTitle, defaultValue, titleSize } = props;
  const editorContext = useContext(EditorContextP1);
  const [opacity, setOpacity] = useState<number>(1);

  useEffect(() => {
    if (editorContext.disableControl && defaultValue !== 'HEAD_CONTROL') {
      setOpacity(0.5);
    } else setOpacity(1);
  }, [editorContext.disableControl]);
  return (
    <ControlLabelWrapper>
      {titleSize ? (
        <ControlLabelHeading renderAs="h2" size={titleSize || 'small'} paddingBottom={subTitle} opacity={opacity}>
          {title}
        </ControlLabelHeading>
      ) : (
        <ControlLabelText paddingBottom={subTitle} opacity={opacity}>
          {title}
        </ControlLabelText>
      )}
      {subTitle && (
        <ControlLabelSubText renderAs="p" size="small">
          {subTitle}
        </ControlLabelSubText>
      )}
    </ControlLabelWrapper>
  );
};

export default ControlLabel;
