import { useContext, useState } from 'react';
import { AppContext } from 'src/AppContext';
import { DeleteAssetFailureNotification } from 'src/components/snackbar/notifications/DeleteAssetNotifications';
import { logger } from 'src/logger';
import { BackendDispatchContext } from 'src/v2/contexts/backend/BackendContext';
import { BackendActionType } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { useNotificationActions } from 'src/v2/contexts/snackbar/actions/useNotificationActions';
import { useAppDispatch } from 'src/v2/redux/hooks';
import { removeFeedAssets } from 'src/v2/redux/slices/feed/feedSlice';
import { SimpleAsset } from 'src/v2/types';

export const useDeleteAsset = ({ asset }: { asset: SimpleAsset }) => {
  const appDispatch = useAppDispatch();
  const appContext = useContext(AppContext);
  const backendDispatchContext = useContext(BackendDispatchContext);
  const [deleteAssetRequestId, setDeleteAssetRequestId] = useState<string | undefined>(undefined);
  const { addFailureNotification } = useNotificationActions();

  const deleteAsset = async () => {
    if (deleteAssetRequestId) return;
    if (appContext.isManagerAccount) {
      appDispatch(removeFeedAssets({ assetIds: [asset.id] }));
      return;
    }
    backendDispatchContext({
      type: BackendActionType.DELETE_ASSET,
      payload: {
        id: asset.id,
        entityId: appContext.getEntityId(),
      },
      onInit: ({ id }) => {
        setDeleteAssetRequestId(id);
      },
      onAbort: () => {
        setDeleteAssetRequestId(undefined);
      },
      onError: (err) => {
        logger.error(`Error while deleting asset`, { assetId: asset.id, Error: err });
        addFailureNotification({ SnackbarContent: DeleteAssetFailureNotification });
        setDeleteAssetRequestId(undefined);
      },
      onSuccess: () => {
        setDeleteAssetRequestId(undefined);
        appDispatch(removeFeedAssets({ assetIds: [asset.id] }));
      },
    });
  };

  return {
    isDeletingAsset: !!deleteAssetRequestId,
    deleteAsset,
  };
};
