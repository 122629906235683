import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import { StringAble } from '@amzn/katal-metrics/lib/metricObject/KatalMetricString';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import { KatalMonitoringDriver, KatalMonitoringDriverOptions } from '@amzn/katal-monitoring-aws-driver';
import { Metrics, METRICS_DOMAINS, METRICS_SITE, METRICS_SERVICE_NAME, METRICS_REALM } from 'src/constants';
import { STAGE } from 'src/util/env';
import { isE2eTesting } from 'src/util/isE2eTesting';

declare global {
  interface Window {
    metricsDriver: KatalMetrics.MetricsDriver;
  }
}

interface StringMetrics {
  [key: string]: StringAble;
}

interface CounterMetrics {
  [key: string]: number;
}

interface ContextDimension {
  [key: string]: string;
}

interface KatalDimension {
  name: string;
  value: string;
}

interface MetricsProvider {
  trackMetricsWithPublisher: typeof trackMetricsWithPublisher;
  trackMetrics: (name: string, strings?: StringMetrics, counters?: CounterMetrics, context?: ContextDimension) => KatalMetrics.Publisher;
  getMetrics: () => KatalMetrics.Publisher;
}

export type { StringMetrics, CounterMetrics, ContextDimension, KatalDimension, MetricsProvider };

export const SESSION_ID: string = crypto.randomUUID();

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  // Note that if we are in production or SERVER_MODE 'mons' we auto-configure before running this function.
  // So for Mons apps these configurations are just for unit tests and local server tests.
  if (process.env.NODE_ENV === 'test') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as Window).metricsDriver = metricsDriver;
    return metricsDriver;
  } else if (process.env.NODE_ENV !== 'production' || isE2eTesting()) {
    return new KatalMetricsDriverConsoleLogJson();
  } else {
    const sushiDriver = new KatalMetricsDriverSushi.Builder()
      .withDomainRealm(METRICS_DOMAINS[STAGE], METRICS_REALM)
      .withErrorHandler(metricsConsoleErrorHandler)
      .build();
    const monitoringConfig: KatalMonitoringDriverOptions = {
      // publish to two locations: Katal/Andes and CloudWatch
      metricsSushiDriver: sushiDriver,
      url: 'https://api.monitoring.ops.ailab.amazon/v1/monitoring',
    };
    return new KatalMonitoringDriver(monitoringConfig);
  }
};

export const makeProdPublisher = (dimensions?: Array<KatalDimension>): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();
  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite(METRICS_SITE)
    .withServiceName(METRICS_SERVICE_NAME)
    // add conext like user information when available
    .withCloudWatchDimensions([
      ...(dimensions?.map((dimension) => {
        return new KatalMetrics.Metric.String(dimension.name, dimension.value);
      }) ?? []),
    ])
    .build();
  return new KatalMetrics.Publisher(metricsDriver, metricsConsoleErrorHandler, initialMetricsContext);
};

export const userMetricsPublisher = (pageName: string, userAlias?: string, toolname?: string, additionalContext?: KatalDimension[]) => {
  return makeProdPublisher([
    { name: Metrics.Dimensions.Stage, value: STAGE },
    { name: Metrics.Dimensions.SessionId, value: SESSION_ID },
    { name: Metrics.Dimensions.PageName, value: pageName },
    ...(userAlias ? [{ name: Metrics.Dimensions.UserAlias, value: userAlias }] : []),
    ...(toolname ? [{ name: Metrics.Dimensions.ToolName, value: toolname }] : []),
    ...(additionalContext ? additionalContext : []),
  ]);
};

export function trackNewActionMetrics(
  metrics: KatalMetrics.Publisher,
  name: string,
  strings?: StringMetrics,
  counters?: CounterMetrics,
  context?: ContextDimension,
): KatalMetrics.Publisher {
  let additionalContext = undefined;
  if (context) {
    additionalContext = new KatalMetrics.Context({
      cloudWatchDimensions: Object.keys(context).map((key) => new KatalMetrics.Metric.String(key, context[key])),
    });
  }
  const publisher = metrics.newChildActionPublisherForMethod(name, additionalContext);
  return trackMetricsWithPublisher(publisher, strings, counters);
}

export function trackMetricsWithPublisher(
  publisher: KatalMetrics.Publisher,
  strings?: StringMetrics,
  counters?: CounterMetrics,
): KatalMetrics.Publisher {
  for (let key in strings) {
    publisher.publishStringTruncate(key, strings[key]);
  }
  for (let key in counters) {
    publisher.publishCounterMonitor(key, counters[key]);
  }
  return publisher;
}
