import styled from 'styled-components';

export const FeedGroupSeparator = styled.div`
  width: 100%;
  height: 45px;
  flex-basis: 100%;

  font-family: 'Amazon Ember Monospace';
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;
`;
