import { BatchResult } from '@amzn/genaihub-typescript-client';
import { WorkflowUserInputs } from 'src/v2/contexts/assetGeneration/types/WorkflowUserInputs.types';
import { WorkflowOptions } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { getBatchResultAssets } from 'src/v2/contexts/backend/utils/BatchResult.utils';
import { FrontendAsset, AssetTypeEnum } from 'src/v2/types';

export function buildAssetsFromBatchResult<
  TResultAsset extends FrontendAsset,
  TUserInputs extends WorkflowUserInputs,
  TWorkflowOptions extends WorkflowOptions,
>({
  aspectRatio,
  assetType,
  batchResult,
  userInputs,
  workflowOptions,
}: {
  aspectRatio: string;
  assetType: AssetTypeEnum;
  batchResult: BatchResult;
  userInputs: TUserInputs;
  workflowOptions: TWorkflowOptions;
}): TResultAsset[] | Error {
  const workflowId = workflowOptions.workflowId;
  const result = getBatchResultAssets({ batchResult });
  if (result instanceof Error) {
    return result;
  }

  if (!result?.length) {
    return new Error(`Asset generation (batchId: '${batchResult.batchId}', workflowId: '${workflowId}') failed due to no assets in the batch result`);
  }

  return result.map((batchResultAsset): TResultAsset => {
    const { batchId, catwalkImageId, id, jobId, url } = batchResultAsset;
    return {
      aspectRatio,
      batchId,
      catwalkImageId,
      id,
      jobId,
      // Batch results don't return a timestamp
      timestamp: Date.now(),
      type: assetType,
      url,
      userInputs,
      workflowId,
      workflowOptions,
    } as TResultAsset;
  });
}

/**
 * Helper function that processes the generation results, aggregating the generated assets and errors from the set of workflow results.
 */
export function prepareGenerationResults<TAsset extends FrontendAsset>({
  generationResults,
}: {
  generationResults: PromiseSettledResult<TAsset[]>[];
}): {
  generatedAssets: TAsset[];
  errors: Error[];
} {
  const generatedAssets: TAsset[] = [];
  const errors: Error[] = [];

  generationResults.map((result) => {
    if (result.status === 'fulfilled') {
      generatedAssets.push(...result.value);
    } else if (result.reason instanceof Error) {
      errors.push(result.reason);
    } else {
      errors.push(new Error(`Failed to generate some asset(s) due to the following reason: ${result.reason}`));
    }
  });

  // STEP - Output final result
  if (!generatedAssets.length) {
    console.error(errors);
    throw new Error('Asset generation failed to produce any assets');
  }

  return {
    generatedAssets,
    errors,
  };
}

export function prepareAssetGenerationActionResults({
  generationResults,
}: {
  generationResults: PromiseSettledResult<{
    generatedAssets: FrontendAsset[];
    errors?: Error[];
  }>[];
}): {
  generatedAssets: FrontendAsset[];
  errors: Error[];
} {
  const generatedAssets: FrontendAsset[] = [];
  const errors: Error[] = [];

  generationResults.map((result) => {
    if (result.status === 'fulfilled') {
      generatedAssets.push(...result.value.generatedAssets);
      if (result.value.errors) errors.push(...result.value.errors);
    } else if (result.reason instanceof Error) {
      errors.push(result.reason);
    } else {
      errors.push(new Error(`Failed to generate some asset(s) due to the following reason: ${result.reason}`));
    }
  });

  return {
    generatedAssets,
    errors,
  };
}
