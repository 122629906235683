import { uploadMaskImageMiddleware, uploadInputImageAssetMiddleware } from 'src/v2/contexts/assetGeneration/middlewares/ImageUpload.middleware';
import { ImageEditorGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { EditedImageAsset } from 'src/v2/types';
import { handleImageEditorGenerationSubmission } from './handleImageEditorGenerationSubmission';

/**
 * The primary handler for generating a list of edited images using the IMAGE_EDITOR workflow.
 */
export async function handleImageEditorGeneration({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: ImageEditorGenerationConfig;
}): Promise<{
  generatedAssets: EditedImageAsset[];
}> {
  const { userInputs } = generationConfig;
  const { inputImageAsset, maskImageUrlOrFileOrRefId } = userInputs;

  // STEP - Get input image reference ID
  const inputImageReferenceId = await uploadInputImageAssetMiddleware({ backendDispatchContext, inputImageAsset });

  // STEP - Get mask image reference ID
  const maskImageReferenceId = await uploadMaskImageMiddleware({ backendDispatchContext, urlOrFileOrRefId: maskImageUrlOrFileOrRefId });

  const generatedAssets = await handleImageEditorGenerationSubmission({
    backendDispatchContext,
    generationConfig,
    inputImageReferenceId,
    maskImageReferenceId,
  });

  return { generatedAssets };
}
