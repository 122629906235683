import Genaihubbackend, { RetrieveWorkflowsListInput, RetrieveWorkflowsListOutput } from '@amzn/genaihub-typescript-client';
import {
  BackendActionBase,
  BackendActionQueryResultBase,
  BackendActionType,
  BackendQueryActionOptions,
  Request,
  RequestsLookup,
  RequestStatus,
  SetQueriesType,
} from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { getRequest, handleQueryAction } from 'src/v2/contexts/backend/utils/BackendContextActions.utils';

export type RetrieveWorkflowsListAction = BackendActionBase<
  BackendActionType.RETRIEVE_WORKFLOWS_LIST,
  RetrieveWorkflowsListInput,
  RetrieveWorkflowsListOutput['body'],
  BackendQueryActionOptions
>;

export type RetrieveWorkflowsListActionQueryResult = BackendActionQueryResultBase<
  BackendActionType.RETRIEVE_WORKFLOWS_LIST,
  RetrieveWorkflowsListOutput['body']
>;

interface RetrieveWorkflowsListQueryFnProps {
  id: Request['id'];
  action: RetrieveWorkflowsListAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
}

export const retrieveWorkflowsListQueryFn = async ({
  id,
  action,
  backendClient,
  requestsLookup,
}: RetrieveWorkflowsListQueryFnProps): Promise<RetrieveWorkflowsListActionQueryResult> => {
  const type = BackendActionType.RETRIEVE_WORKFLOWS_LIST;
  const response = await backendClient.retrieveWorkflowsList(action.payload);
  const request = getRequest({ id, requestsLookup });

  if (request.status === RequestStatus.ABORTED) {
    return { type, response: undefined, requestStatus: RequestStatus.ABORTED };
  }

  return { type, response: response.body, requestStatus: RequestStatus.SUCCESS };
};

interface HandleRetrieveWorkflowsListActionProps {
  action: RetrieveWorkflowsListAction;
  backendClient: Genaihubbackend;
  requestsLookup: RequestsLookup;
  setQueries: SetQueriesType;
}

export const handleRetrieveWorkflowsListAction = ({
  action,
  backendClient,
  requestsLookup,
  setQueries,
}: HandleRetrieveWorkflowsListActionProps): void => {
  handleQueryAction({
    action,
    requestsLookup,
    type: BackendActionType.RETRIEVE_WORKFLOWS_LIST,
    getQueryResult: ({ id }) => {
      return retrieveWorkflowsListQueryFn({
        id,
        action,
        backendClient,
        requestsLookup,
      });
    },
    setQueries,
  });
};
