import { Asset as BackendAsset } from '@amzn/genaihub-typescript-client';
import { ASINMetadataMap } from 'src/v2/contexts/feed/FeedContext.types';
import { buildApparelProductImageAsset } from 'src/v2/contexts/feed/util/asset/_partials/Apparel.Asset.utils';
import { buildLifestyleImageAsset } from 'src/v2/contexts/feed/util/asset/_partials/LifestyleImagery.Asset.utils';
import { buildTextToImageProductImageAsset } from 'src/v2/contexts/feed/util/asset/_partials/TextToImage.Asset.utils';
import {
  AspectRatio,
  AssetFetchPlaceholder,
  FrontendAsset,
  FeedPlaceholderType,
  BasicAssetProperties,
  AssetTypeEnum,
  WorkflowIdEnum,
} from 'src/v2/types';
import { getAspectRatioFromUrl } from 'src/v2/utils/ImageUtils';

export const MissingAssetPropertyErrorMessages = {
  NO_BATCH_ID: 'Backend asset missing Batch ID',
  NO_ID: 'Backend asset missing ID',
  NO_JOB_ID: 'Backend asset missing Job ID',
  NO_URL: 'Backend asset missing URL',
  NO_WORKFLOW_ID: 'Backend asset missing Workflow ID',
};

/**
 * Converts a list of Backend Assets into Frontend Assets
 */
export async function convertBackendAssetsToFrontendAssets({
  asinMetadataMap,
  backendAssets,
}: {
  asinMetadataMap: ASINMetadataMap;
  backendAssets: BackendAsset[];
}): Promise<{ assets: FrontendAsset[]; errors: Error[] }> {
  const assets: FrontendAsset[] = [];
  const errors: Error[] = [];

  const results = await Promise.allSettled(
    backendAssets.map((backendAsset) => convertBackendAssetToFrontendAsset({ asinMetadataMap, backendAsset })),
  );

  results.map((result) => {
    if (result.status === 'fulfilled') {
      assets.push(result.value);
    } else if (result.reason instanceof Error) {
      errors.push(result.reason);
    } else {
      errors.push(new Error(`Failed to convert asset due to the following reason: ${result.reason}`));
    }
  });

  return { assets, errors };
}

/**
 * Converts a Backend Asset into a Frontend Asset
 */
export async function convertBackendAssetToFrontendAsset({
  asinMetadataMap,
  backendAsset,
}: {
  asinMetadataMap: ASINMetadataMap;
  backendAsset: BackendAsset;
}): Promise<FrontendAsset> {
  const basicAssetProperties = await getBasicFrontendAssetPropertiesFromBackendAsset({ backendAsset });
  const workflowId = backendAsset.workflowId;

  if (!workflowId) throw new Error(MissingAssetPropertyErrorMessages.NO_WORKFLOW_ID);

  switch (workflowId) {
    case WorkflowIdEnum.APPAREL:
      return buildApparelProductImageAsset({ asinMetadataMap, backendAsset, basicAssetProperties });
    case WorkflowIdEnum.TEXT_TO_IMAGE:
      return buildTextToImageProductImageAsset({ asinMetadataMap, backendAsset, basicAssetProperties });
    case WorkflowIdEnum.LIFESTYLE_IMAGERY:
      return buildLifestyleImageAsset({ asinMetadataMap, backendAsset, basicAssetProperties });
    case WorkflowIdEnum.PARALLAX_MOTION:
      return {
        ...basicAssetProperties,
        type: AssetTypeEnum.VIDEO,
        workflowId,
        // Backend doesn't provide sufficient data to populate userInputs and workflowOptions
        userInputs: undefined,
        workflowOptions: undefined,
        productData: undefined,
      };
    case WorkflowIdEnum.GENERATIVE_RESIZING:
    case WorkflowIdEnum.IMAGE_EDITOR:
    case WorkflowIdEnum.IMAGE_THEMING:
    case WorkflowIdEnum.IMPORT_IMAGE:
    case WorkflowIdEnum.PRODUCT_EDIT:
      return {
        ...basicAssetProperties,
        type: AssetTypeEnum.IMAGE,
        workflowId,
        // Backend doesn't provide sufficient data to populate userInputs and workflowOptions
        userInputs: undefined,
        workflowOptions: undefined,
        productData: undefined,
      };
    default:
      throw new Error(`Cannot convert backend asset to frontend asset for unsupported workflow: '${workflowId}'.`);
  }
}

export async function getBasicFrontendAssetPropertiesFromBackendAsset({ backendAsset }: { backendAsset: BackendAsset }) {
  if (!backendAsset.batchId) throw new Error(MissingAssetPropertyErrorMessages.NO_BATCH_ID);
  if (!backendAsset.id) throw new Error(MissingAssetPropertyErrorMessages.NO_ID);
  if (!backendAsset.jobId) throw new Error(MissingAssetPropertyErrorMessages.NO_JOB_ID);
  if (!backendAsset.uri) throw new Error(MissingAssetPropertyErrorMessages.NO_URL);

  let aspectRatio = backendAsset.aspectRatio;
  if (!aspectRatio) {
    if (backendAsset.assetType === AssetTypeEnum.IMAGE) {
      const aspectRatioData = await getAspectRatioFromUrl({ url: backendAsset.uri });
      aspectRatio = aspectRatioData.aspectRatio;
    } else {
      // Videos from PARALLAX_MOTION don't store an aspect ratio
      // TODO: add support for getting aspect ratio of a video
      aspectRatio = '';
    }
  }

  const timestamp = getBackendAssetTimestampAsNumber({ timestamp: backendAsset.timestamp });

  const basicAssetProperties: BasicAssetProperties = {
    aspectRatio,
    batchId: backendAsset.batchId,
    catwalkImageId: backendAsset.imageId,
    id: backendAsset.id,
    jobId: backendAsset.jobId,
    timestamp,
    url: backendAsset.uri,
  };

  return basicAssetProperties;
}

export function generateAssetFetchPlaceholders({ aspectRatio, count }: { aspectRatio: AspectRatio; count: number }) {
  return Array<AssetFetchPlaceholder>(count).fill({
    aspectRatio,
    type: FeedPlaceholderType.ASSET_FETCH,
  });
}

export function getBackendAssetTimestampAsNumber({ timestamp }: { timestamp: string | undefined }) {
  let result: number;
  result = timestamp ? parseInt(timestamp) : Date.now();
  if (isNaN(result)) {
    result = Date.now();
  }
  return result;
}
