import { UploadedImageGenerationConfig } from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { UploadedImageAsset } from 'src/v2/types';
import { getNormalizedError } from 'src/v2/utils/Error.utils';
import { handleUploadedImageGenerationSubmission } from './handleUploadedImageGenerationSubmission';

export async function handleUploadedImageGeneration({
  backendDispatchContext,
  generationConfig,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: UploadedImageGenerationConfig;
}): Promise<{
  generatedAssets: UploadedImageAsset[];
  errors: Error[];
}> {
  const generatedAssets: UploadedImageAsset[] = [];
  const errors: Error[] = [];
  const { entityId, options, userInputs } = generationConfig;
  const { imageToImport } = userInputs;
  const { progressCallback } = options;

  // STEP - Submit image to backend
  try {
    const uploadedImageAsset = await handleUploadedImageGenerationSubmission({
      backendDispatchContext,
      entityId,
      imageToImport,
      progressCallback,
    });
    generatedAssets.push(uploadedImageAsset);
  } catch (err) {
    console.error(`Error while importing image ${imageToImport.name}:`, err);
    const error = getNormalizedError({ error: err, fallbackErrorMessage: `Error while importing image ${imageToImport.name}` });
    errors.push(error);
  }

  return { generatedAssets, errors };
}
