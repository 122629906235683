import { Icon } from '@amzn/storm-ui';
import { iconTypes } from 'src/v2/components/Icon/iconTypes';

const style = {
  width: '70px',
  height: '70px',
  gap: '0px',
  opacity: '0.6',
  fontSize: '40px',
  color: '#232F3F',
  cursor: 'pointer',
  background: 'lightgrey',
  alignContent: 'center',
  borderRadius: '50px',
  textAlign: 'center' as const,
  margin: '10px',
};

type Direction = 'left' | 'right';

interface ArrowNavProps {
  dir: Direction;
  visible: boolean;
  clickHandler: (direction: Direction) => void;
}

const ArrowNav = (props: ArrowNavProps) => {
  const { dir, visible } = props;
  return (
    <>
      <>
        {dir == 'left' ? (
          <div style={{ ...style, visibility: visible ? 'visible' : 'hidden' }} onClick={() => props.clickHandler('left')}>
            <Icon size={'lg'} type={iconTypes.chevronLeft} />
          </div>
        ) : (
          <div style={{ ...style, visibility: visible ? 'visible' : 'hidden' }} onClick={() => props.clickHandler('right')}>
            <Icon size={'lg'} type={iconTypes.chevronRight} />
          </div>
        )}
      </>
    </>
  );
};

export default ArrowNav;
