import { useEffect, useState } from 'react';
import { ContentItem } from 'src/components/pages/studio/StudioContext';
import { useAssetCache } from 'src/v2/hooks/useAssetCache/useAssetCache';
import { AssetTypeEnum, SimpleAsset } from 'src/v2/types';

// Forwarding `useAssetImageCache` consumers to `useAssetCache`
// TODO: after ContentItem is deprecated, this should be cleaned up
const useAssetImageCache = (contentItem?: ContentItem) => {
  const [asset, setAsset] = useState<SimpleAsset | undefined>();

  useEffect(() => {
    if (contentItem && contentItem.originalUri) {
      const asset: SimpleAsset = {
        id: contentItem.referenceId ?? contentItem.originalUri,
        type: (contentItem.assetType ?? AssetTypeEnum.IMAGE) as SimpleAsset['type'],
        url: contentItem.originalUri,
      };
      setAsset(asset);
    } else {
      setAsset(undefined);
    }
  }, [contentItem]);

  return useAssetCache({ asset });
};

export default useAssetImageCache;
