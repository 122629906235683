import { useBundle } from '@amzn/react-arb-tools';
import { Flex, Button, isMobileStyleMixin } from '@amzn/storm-ui';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from 'src/AppContext';
import { Metrics } from 'src/constants';
import { ALERTS_MESSAGE_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import styled, { css } from 'styled-components';
import { getPageName } from '../utils/getPageName';

const ErrorPageContainer = styled(Flex)`
  width: 100%;
  padding: 54px 24px;
  border-radius: 8px;
  margin: 3em 0;
  max-width: 1536px;
  box-shadow:
    0 0 2px 0 rgba(35, 47, 63, 0.3),
    0 1px 4px 0 rgba(35, 47, 63, 0.15);
  text-align: center;
  background-color: var(--surface-secondary);
  row-gap: 10px;
  ${isMobileStyleMixin(css`
    padding: 24px;
  `)}
`;

const IconFlex = styled(Flex)`
  position: relative;
  width: 100%;
`;
const Icon = () => (
  <IconFlex justifyContent={'center'}>
    <div
      style={{
        borderRadius: '50%',
        background: 'linear-gradient(180deg, #6236FF 50%, #FFFFFF 100%)',
        width: '31px',
        height: '31px',
        position: 'relative',
        left: '0.4%',
      }}
    ></div>
    <div
      style={{
        borderRadius: '50%',
        border: '2px solid red',
        backgroundColor: 'transparent',
        width: '31px',
        height: '31px',
        position: 'relative',
        right: '0.4%',
      }}
    ></div>
  </IconFlex>
);

const ContainedButton = styled(Button)`
  padding: 10px;
  && {
    color: white;
  }
  &&:hover {
    color: white;
  }
  margin-bottom: 10px;
`;

const ErrorPage = () => {
  const [bundle] = useBundle(ALERTS_MESSAGE_BUNDLE_NAME);
  const context = useContext(AppContext);
  const { metrics } = context;
  const linkTo = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const handleClickEvent = (page: string) => {
    metrics.trackMetrics(
      Metrics.Methods.ErrorPage,
      { [Metrics.Names.ButtonId]: Metrics.Buttons.GoBack, [Metrics.Names.Destination]: getPageName(page) },
      { [Metrics.Counters.Clicks]: 1 },
    );

    linkTo(page);
  };
  const nullSafeState = state || {};
  return (
    <ErrorPageContainer alignItems="center" id="container" direction="column">
      <Icon />
      <h2>{nullSafeState['message'] || t(bundle, 'generic_error_text')}</h2>
      <Flex alignItems="center">
        {context.authenticated && (
          <ContainedButton
            style={{
              fontFamily: 'Amazon Ember',
              padding: '10px 18px 10px 18px',
              background: 'linear-gradient(90deg, #5E65FF 37.5%, #976AFF 100%)',
              borderRadius: '100px',
            }}
            color="primary"
            onClick={() => {
              handleClickEvent(nullSafeState['route'] || '/');
            }}
          >
            {nullSafeState['name'] || 'Refresh page'}
          </ContainedButton>
        )}
      </Flex>
      <a style={{ textDecoration: 'none', fontSize: '0.95rem', color: '#6236FF' }} href={window.location.origin}>
        Back to homepage
      </a>
    </ErrorPageContainer>
  );
};

export default ErrorPage;
