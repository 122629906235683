import { MbmProvider } from '@amzn/react-arb-tools';
import { MobileThemeProvider, ThemeProvider as StormUIThemeProvider } from '@amzn/storm-ui';
import { ThemeProvider } from '@mui/material/styles'; // to be removed once text to image workflow is changed
import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Navigate, RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.scss';
import { AppContext, useAppContext } from 'src/AppContext';
import RedirectToAuthPortal from 'src/components/common/RedirectToAuthPortal';
import { queryClient } from 'src/components/QueryClient';
import { getMbmOptions } from 'src/helpers';
import arbManifest from 'src/i18n/translations/arbManifest.js';
import { AssetGenerationContextProvider } from 'src/v2/contexts/assetGeneration/AssetGenerationContext';
import { BackendContextProvider } from 'src/v2/contexts/backend/BackendContext';
import { FeedContextProvider } from 'src/v2/contexts/feed/FeedContext';
import { SnackbarContextProvider } from 'src/v2/contexts/snackbar/SnackbarContext';
import { setupStore } from 'src/v2/redux/store';
import App from './components/App';
import { isCreativeAgentLaunched } from './util/weblab/weblab';

const routes = [
  /** UNAUTHENTICATED ROUTES **/
  {
    path: '/',
    element: <App page="home" unAuthenticated />,
    errorElement: <App page="notfounderror" />,
  },
  {
    path: '/gallery',
    element: <App page="gallery" unAuthenticated authenticatedPath="/browse" />,
  },
  {
    path: '/home',
    element: <Navigate to="/" />,
  },
  // TODO: remove this route after cleaning out the editor state from the Home page compoent and cleaning up references to this route elsewhere in the code.
  {
    path: '/home/editor',
    element: <Navigate to="/playground/editor" />,
  },
  /** AUTHENTICATED ROUTES **/
  // - Authentication redirects
  {
    path: 'ap/return',
    element: <RedirectToAuthPortal />,
  },
  {
    path: '/signin',
    element: <Navigate to="/" />,
  },
  // - Creative Agent
  ...(isCreativeAgentLaunched()
    ? [
        {
          path: '/chat',
          element: <App page="chat" />,
        },
      ]
    : []),
  // - Gallery
  {
    path: '/browse',
    element: <App page="browse" />,
  },
  // - Sandbox
  {
    path: '/playground',
    element: <App page="playground" />,
  },
  {
    path: '/playground/editor',
    element: <App page="playground" editor={true} />,
  },
  // - Studio
  {
    path: '/studio',
    element: <App page="studio" />,
  },
  // - 404
  {
    path: '/404',
    element: <App page="notfounderror" unAuthenticated />,
  },
].filter((routes) => routes != null) as RouteObject[];

export const router = createBrowserRouter(routes, {
  basename: window.location.pathname.includes('genai') ? '/genai' : '',
});

document.body.dataset.theme = 'main';
const container = document.getElementById('root');
const root = createRoot(container!);
const store = setupStore();
const mbmOptions = getMbmOptions(arbManifest);

const AppRoot = () => {
  const appContext = useAppContext();
  return (
    <Provider store={store}>
      <MbmProvider {...mbmOptions}>
        <QueryClientProvider client={queryClient}>
          <AppContext.Provider value={appContext}>
            <BackendContextProvider>
              <FeedContextProvider>
                <ThemeProvider theme={appContext.activeTheme}>
                  <StormUIThemeProvider>
                    <MobileThemeProvider>
                      <SnackbarContextProvider>
                        <AssetGenerationContextProvider>
                          <RouterProvider router={router} />
                        </AssetGenerationContextProvider>
                      </SnackbarContextProvider>
                    </MobileThemeProvider>
                  </StormUIThemeProvider>
                </ThemeProvider>
              </FeedContextProvider>
            </BackendContextProvider>
          </AppContext.Provider>
        </QueryClientProvider>
      </MbmProvider>
    </Provider>
  );
};

root.render(<AppRoot />);
