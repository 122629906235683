import { checkIsManagerAccount } from 'src/AppContext';
import { isWeblabFeedsDeactivationInTreatment, isWeblabStateManagementInTreatment } from 'src/util/weblab/config';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { BackendActionType } from 'src/v2/contexts/backend/types/BackendContextActions.types';
import { FetchFeedAction } from 'src/v2/contexts/feed/FeedContext.types';
import { resetFeed, setFeedFetchErrorState } from 'src/v2/redux/slices/feed/feedSlice';
import { AppStore } from 'src/v2/redux/store';
import { AccountTypeEnum } from 'src/v2/types';
import { getNormalizedError } from 'src/v2/utils/Error.utils';
import { handleFetchFeedActionHelper } from './FetchFeedAction.helpers';

export const handleFetchFeedAction = async ({
  action,
  appStore,
  backendDispatchContext,
}: {
  action: FetchFeedAction;
  appStore: AppStore;
  backendDispatchContext: BackendDispatchContextType;
}): Promise<void> => {
  try {
    const { accountType, entityId } = action.payload;
    // Manager accounts don't store assets in the backend
    const isManagerAccount = checkIsManagerAccount({ accountType, entityId });
    if (
      isWeblabFeedsDeactivationInTreatment() ||
      !isWeblabStateManagementInTreatment() ||
      // manager accounts don't have a feed
      isManagerAccount ||
      // covers the case when an external account is logged in but the user hasn't chosen an account from the list, since the accountType will be unset
      (accountType !== AccountTypeEnum.INTERNAL && !entityId)
    ) {
      return;
    }

    const appState = appStore.getState();
    const currentFetchState = appState.feed.fetchState;

    if (currentFetchState.entityId !== undefined && entityId !== currentFetchState.entityId) {
      // Entity Changed
      // - Abort active fetches
      if (currentFetchState.requestGroupId) {
        backendDispatchContext({
          type: BackendActionType.ABORT_BY_GROUP_ID,
          payload: {
            groupId: currentFetchState.requestGroupId,
          },
        });
      }
      // - Reset Feed slice state
      appStore.dispatch(resetFeed());
    } else if (
      // - Exit early if fetching already or if backend has no more assets to fetch
      currentFetchState.isFetching ||
      currentFetchState.nextToken === ''
    ) {
      return;
    }
    return await handleFetchFeedActionHelper({ appStore, backendDispatchContext, entityId });
  } catch (error) {
    console.error('Error while fetching feed assets from server: ', error);
    const normalizedError = getNormalizedError({ error, fallbackErrorMessage: 'Error while fetching feed assets from server' });
    appStore.dispatch(setFeedFetchErrorState({ error: normalizedError.message }));
  }
};
