import { Text } from '@amzn/storm-ui';
import Stack from 'src/customUIComponents/Stack';
import Circle from 'src/icons/circle';
import adsLogoX2 from 'src/images/Amazon_Ads_Horizontal_SquidInk_RGB.webp';
import adsLogoDarkMode from 'src/images/Amazon_Ads_Horizontal_White_RGB.webp';
import { AICS_WEBLAB_DARK_MODE, WeblabTreatment } from 'src/util/weblab/config';
import { isWeblabInTreatment } from 'src/util/weblab/weblab';
import { useAppSelector } from 'src/v2/redux/hooks';
import { isDarkModeActive } from 'src/v2/redux/slices/theme/themeSlice';
import style from './Footer.module.scss';

const textStyle = { fontSize: '14px', fontWeight: '400', lineHeight: '15px', cursor: 'pointer' };

export default function Footer() {
  const isDarkModeLaunched = isWeblabInTreatment(AICS_WEBLAB_DARK_MODE, WeblabTreatment.T1);
  const isDarkMode = isDarkModeLaunched && useAppSelector(isDarkModeActive);
  return (
    <div className={style.footerContainer}>
      <Stack className={style.footerContent}>
        <img className={style.logo} src={isDarkMode ? adsLogoDarkMode : adsLogoX2} width="80px" />

        <Stack className={style.footerLinks} data-testid="footer-nav-container">
          <Text style={textStyle}>
            <a
              className={style.footerLink}
              href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ"
              target="_blank"
              data-testid="footer-link-privacy-notice"
              rel="noreferrer"
            >
              Privacy notice
            </a>
          </Text>
          <Circle style={{ marginLeft: '10px', marginRight: '10px', width: '5px' }} />
          <Text style={textStyle}>
            <a
              className={style.footerLink}
              href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GLSBYFE9MGKKQXXM"
              target="_blank"
              data-testid="footer-link-conditions-of-use"
              rel="noreferrer"
            >
              Conditions of use
            </a>
          </Text>
          <Circle style={{ marginLeft: '10px', marginRight: '10px', width: '5px' }} />
          <Text style={textStyle}>
            <a
              className={style.footerLink}
              href="https://advertising.amazon.com/terms"
              target="_blank"
              data-testid="footer-link-amazon-advertising-agreement"
              rel="noreferrer"
            >
              Amazon Advertising Agreement
            </a>
          </Text>
        </Stack>
        <Text className={style.copyright} style={{ ...textStyle, paddingBottom: '5px' }}>
          © Copyright 2024, Amazon
        </Text>
      </Stack>
      <div className="bottomGutter"></div>
    </div>
  );
}
