import {
  LifestyleImageGenerationConfig,
  MultipleProductImageGenerationConfig,
  ProductImageGenerationConfig,
} from 'src/v2/contexts/assetGeneration/types/AssetGenerationContext.types';
import { handleTextPromptGeneration } from 'src/v2/contexts/assetGeneration/utils/common/TextPromptGeneration.utils';
import { BackendDispatchContextType } from 'src/v2/contexts/backend/BackendContext';
import { PromptType } from 'src/v2/contexts/backend/types/WorkflowOptions.types';
import { ProductType } from 'src/v2/redux/slices/product/productSlice.types';
import { WorkflowIdEnum } from 'src/v2/types';

export const GenerateTextPromptsMiddlewareErrorMessages = {
  DEFAULT: 'Asset generation failed while generating guided text prompts',
  NO_ASIN_PRODUCT: 'Guided text generation requires an ASIN product',
};

export async function generateTextPromptsForLifestyleImagesMiddleware({
  backendDispatchContext,
  generationConfig,
  productImageReferenceId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: LifestyleImageGenerationConfig;
  productImageReferenceId: string | undefined;
}): Promise<string[] | undefined> {
  return generateTextPromptsMiddleware({
    backendDispatchContext,
    generationConfig,
    productImageReferenceId,
    promptType: PromptType.PRODUCT_LESS_LIFESTYLE,
  });
}

export async function generateTextPromptsForProductImagesMiddleware({
  backendDispatchContext,
  generationConfig,
  productImageReferenceId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: ProductImageGenerationConfig;
  productImageReferenceId: string | undefined;
}): Promise<string[] | undefined> {
  return generateTextPromptsMiddleware({
    backendDispatchContext,
    generationConfig,
    productImageReferenceId,
    promptType: PromptType.PRODUCT_LIFESTYLE,
  });
}

export async function generateTextPromptsForMultipleProductImagesMiddleware({
  backendDispatchContext,
  generationConfig,
  productImageReferenceId,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: MultipleProductImageGenerationConfig;
  productImageReferenceId: string | undefined;
}): Promise<string[] | undefined> {
  const { entityId, outputCount, studioRequest, userInputs } = generationConfig;

  if (userInputs.textPrompt) return undefined;
  const product = userInputs.products[0].product;

  try {
    if (product.type === ProductType.ASIN) {
      return await handleTextPromptGeneration({
        backendDispatchContext,
        generationConfig: {
          entityId,
          options: {
            productImageReferenceId,
            promptType: PromptType.PRODUCT_LIFESTYLE,
            relatedWorkflowId: generationConfig.workflowId,
          },
          outputCount,
          studioRequest,
          userInputs: {
            product: product,
            style: undefined,
          },
          workflowId: WorkflowIdEnum.GUIDED_TEXT_GENERATION,
        },
      });
    } else {
      throw new Error(GenerateTextPromptsMiddlewareErrorMessages.NO_ASIN_PRODUCT);
    }
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    } else {
      console.error(err);
      throw new Error(GenerateTextPromptsMiddlewareErrorMessages.DEFAULT);
    }
  }
}

/**
 * Local helper function
 */
async function generateTextPromptsMiddleware({
  backendDispatchContext,
  generationConfig,
  productImageReferenceId,
  promptType,
}: {
  backendDispatchContext: BackendDispatchContextType;
  generationConfig: LifestyleImageGenerationConfig | ProductImageGenerationConfig;
  productImageReferenceId: string | undefined;
  promptType: PromptType;
}): Promise<string[] | undefined> {
  const { entityId, outputCount, studioRequest, userInputs } = generationConfig;

  if (userInputs.textPrompt) return undefined;

  try {
    if (userInputs.product?.type === ProductType.ASIN) {
      return await handleTextPromptGeneration({
        backendDispatchContext,
        generationConfig: {
          entityId,
          options: {
            productImageReferenceId,
            promptType,
            relatedWorkflowId: generationConfig.workflowId,
          },
          outputCount,
          studioRequest,
          userInputs: {
            product: userInputs.product,
            style: undefined,
          },
          workflowId: WorkflowIdEnum.GUIDED_TEXT_GENERATION,
        },
      });
    } else {
      throw new Error(GenerateTextPromptsMiddlewareErrorMessages.NO_ASIN_PRODUCT);
    }
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    } else {
      console.error(err);
      throw new Error(GenerateTextPromptsMiddlewareErrorMessages.DEFAULT);
    }
  }
}
