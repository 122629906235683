import { memo, SyntheticEvent, useCallback, useState } from 'react';
import { AssetProps } from 'src/v2/components/common/asset/Asset.types';
import { Placeholder } from 'src/v2/components/placeholder/Placeholder';
import { PlaceholderAnimation, PlaceholderType, PlaceholderVariant } from 'src/v2/components/placeholder/Placeholder.types';
import { useAssetCache } from 'src/v2/hooks/useAssetCache/useAssetCache';
import { getNormalizedClassNames } from 'src/v2/utils/utils';
import { AssetComponentTestIds } from './Asset.constant';
import styles from './Asset.module.scss';

export const AssetImage = memo((props: AssetProps) => {
  const { asset, className, options, onLoad, ...restProps } = props;

  const [imageLoaded, setImageLoaded] = useState(false);
  const cachedAssetUrl = useAssetCache({ asset });

  const onLoadImage = useCallback(
    (event: SyntheticEvent<HTMLImageElement>) => {
      setImageLoaded(true);
      onLoad?.(event);
    },
    [cachedAssetUrl, setImageLoaded],
  );

  const rootClassNames = getNormalizedClassNames([styles.assetImage, imageLoaded ? styles.assetLoaded : undefined, className]);
  const loadingPlaceholderClassNames = getNormalizedClassNames([
    styles.assetLoadingPlaceholder,
    imageLoaded ? styles.assetLoaded : undefined,
    options?.loadingPlaceholder?.className,
  ]);

  return (
    <>
      <img
        data-testid={options?.testId ?? AssetComponentTestIds.imageEl(asset)}
        className={rootClassNames}
        loading="lazy"
        onLoad={onLoadImage}
        src={cachedAssetUrl}
        {...restProps}
      />
      <Placeholder
        data-testid={options?.loadingPlaceholder?.testId ?? AssetComponentTestIds.loadingPlaceholder}
        className={loadingPlaceholderClassNames}
        animation={PlaceholderAnimation.PULSATE}
        type={PlaceholderType.ASSET}
        variant={PlaceholderVariant.ROUNDED}
      />
    </>
  );
});

AssetImage.displayName = 'Asset Image';
