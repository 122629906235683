import { BatchResult, BatchResultJobsItem, ProductAsset, RetrieveResultByWorkflowIdAndBatchIdOutput } from '@amzn/genaihub-typescript-client';
import { BatchResultAsset, JobStatusEnum } from 'src/v2/types';

/** GET JOB **/
export const getJob = ({ batchResult }: { batchResult: RetrieveResultByWorkflowIdAndBatchIdOutput['body'] | undefined }) => {
  return batchResult?.jobs?.[0];
};

/** GET BATCH RESULT ASSETS **/
export const GetBatchResultAssetsErrorMessages = {
  NO_BATCH_ID: 'Batch ID missing in BatchResult',
  NO_JOB: 'Job not available in BatchResult',
  NO_JOB_ID: 'Job ID not available in BatchResult job',
  NO_ASSET_IDS: 'Asset ids not available in BatchResult job',
  NO_ASSET_URLS: 'Asset urls not available in BatchResult job',
  MISMATCHED_LENGTHS: 'Mismatched number of items in ids and urls from BatchResult job',
};

export function getBatchResultAssets({ batchResult }: { batchResult: BatchResult }): BatchResultAsset[] | Error {
  // Validate the Batch Result contains all of the necessary information
  const batchId = batchResult.batchId;
  if (!batchId) return new Error(GetBatchResultAssetsErrorMessages.NO_BATCH_ID);
  const job = getJob({ batchResult });
  if (!job) return new Error(GetBatchResultAssetsErrorMessages.NO_JOB);
  const jobId = job.jobId;
  if (!jobId) return new Error(GetBatchResultAssetsErrorMessages.NO_JOB_ID);
  const ids = job.ids as Array<string>;
  if (!Array.isArray(ids)) return new Error(GetBatchResultAssetsErrorMessages.NO_ASSET_IDS);
  const urls = job.urls as Array<string>;
  if (!Array.isArray(urls)) return new Error(GetBatchResultAssetsErrorMessages.NO_ASSET_URLS);
  if (ids.length !== urls.length) return new Error(GetBatchResultAssetsErrorMessages.MISMATCHED_LENGTHS);
  const productAssets = job.assets;
  return urls.map((url, index) => {
    const productAsset = productAssets?.[index];
    const id = ids[index];
    const productData = extractProductDataFromProductAsset({ productAsset });
    return {
      batchId,
      // Asset's reference ID
      id,
      jobId,
      url,
      ...productData,
    };
  });
}

export function extractProductDataFromProductAsset({
  productAsset,
}: {
  productAsset?: ProductAsset;
}): Pick<BatchResultAsset, 'catwalkImageId' | 'productData'> {
  const noProductData = { catwalkImageId: undefined, productData: undefined };
  if (!productAsset || !productAsset.imageId || !productAsset.boundingBox || !productAsset.productImage || !productAsset.productMask) {
    return noProductData;
  }

  return {
    catwalkImageId: productAsset.imageId,
    productData: {
      boundingBox: productAsset.boundingBox,
      imageUrl: productAsset.productImage,
      maskUrl: productAsset.productMask,
    },
  };
}

/** JOB STATUS UTILS */
export const isJobFailed = ({ job }: { job: BatchResultJobsItem | undefined }) => {
  // HALTED job status results from Catwalk Andon Cord being pulled, which stops all traffic to the model workflow
  // -- see this document for details: https://quip-amazon.com/CZpuAxa0iZiq#JER9BAHNxBh
  return job?.status === JobStatusEnum.FAILED || job?.status === JobStatusEnum.HALTED;
};

export const isJobInProgress = ({ job }: { job: BatchResultJobsItem | undefined }) => {
  return job?.status === JobStatusEnum.RUNNING || job?.status === JobStatusEnum.PENDING;
};

export const isJobCompleted = ({ job }: { job: BatchResultJobsItem | undefined }) => {
  return job?.status === JobStatusEnum.COMPLETED;
};

/** JOB RESULTS **/
export const jobContainsResults = ({ job }: { job: BatchResultJobsItem | undefined }) => {
  return (job?.urls?.length ?? 0) > 0;
};
